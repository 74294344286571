import { PayloadAction } from '@reduxjs/toolkit'
import { addDays, differenceInMonths, format } from 'date-fns'

import { actions } from '../../dimensions'
import { ReportFilterState, FORMAT } from '../'
import { Granularity } from '../filterTypes'
import { invalidateAllReportData } from '../invalidateAllReportData'

function autoAdjustGranularity (granularity: Granularity, [start, end]: [Date, Date]) {
  if (granularity === Granularity.Day) {
    const MONTH_THRESHOLD = 3
    const canCauseExcessiveAmountOfReportData
      = differenceInMonths(addDays(end, 1), start) >= MONTH_THRESHOLD

    return canCauseExcessiveAmountOfReportData
      ? Granularity.Month
      : Granularity.Day
  }

  return granularity
}

export const updateDateRange = {
  prepare: (dateRange: [Date, Date]) => ({
    payload: dateRange,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (filters: ReportFilterState, action: PayloadAction<[Date, Date]>) => {
    filters.dateRange = [format(action.payload[0], FORMAT), format(action.payload[1], FORMAT)]
    filters.granularity = autoAdjustGranularity(filters.granularity, action.payload)
  }
}

export const updateGranularity = {
  prepare: (granularity: Granularity) => ({
    payload: granularity,
    meta: {
      extraActions: [
        actions.invalidateData('accounts'),
        actions.invalidateData('services'),
        actions.invalidateData('instances'),
        actions.invalidateData('summaryServices'),
        actions.invalidateData('summaryInstances')
      ]
    }
  }),
  reducer: (filters: ReportFilterState, action: PayloadAction<Granularity>) => {
    filters.granularity = action.payload
  }
}

export const updateLimit = (filters: ReportFilterState, action: PayloadAction<number>) => {
  filters.limit = action.payload
}
