import React from 'react'
import { Field, Label, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'
import compose from 'lodash/fp/compose'

import { CurrencyAdornment } from '../../../../../../components/atoms/CurrencyAdornment'
import { RateModel, ServiceModel, ServiceChargeType } from '../../../../../../data/types'

interface RateProps {
  service: ServiceModel | null
  rate: number | null
  activeRate?: RateModel
  onChangeRate: (rate: number) => void
}

export function Rate ({ service, activeRate, rate, onChangeRate }: RateProps) {
  const chargeIsManual = service?.attributes.charge_type === ServiceChargeType.Manual

  return (
    <Field data-testid='rate'>
      <Label.Group>
        <Label>{translate('Rate')}</Label>
        <Label.Sub>
          {translate('Inherits value from the current active rate when the billing type of the service is manual, otherwise it is mandatory to modify this field.')}
        </Label.Sub>
      </Label.Group>
      <CurrencyAdornment>
        <Input
          type='number'
          disabled={chargeIsManual}
          value={chargeIsManual
            ? activeRate?.attributes.rate
            : rate || activeRate?.attributes.rate || 0
          }
          onChange={compose(onChangeRate, Number)} />
      </CurrencyAdornment>
    </Field>
  )
}
