import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { configurationSelectors } from '../../../domains/administration/state/configuration'
import { workSelectors } from '../../work/state'

export function useAppState () {
  const apiRoot = useSelector(configurationSelectors.getApiRoot)
  const isLoading = useSelector(workSelectors.isAnyChannelBusy)

  return useMemo(() => ({ apiRoot, isLoading }), [apiRoot, isLoading])
}
