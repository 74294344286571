import React, { ErrorInfo } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { askForUserFeedback, sentryEnabled } from '../../../../utils/log'
import { configurationSelectors } from '../../../../domains/administration/state/configuration'
import config from '../../../../application/config'

import { ResumeOptions } from './ResumeOptions'

export interface ErrorMessageProps {
  error: Error
  info: ErrorInfo
}

function DebugMessage ({ error, info }: ErrorMessageProps) {
  return (
    <div>
      <p>
        {translate('Unfortunately, we don\'t know how to fix this yet. You\'ve enabled debug mode, so\n here\'s some extra info:')}
      </p>
      <pre style={{ maxHeight: 300 }}>{error.toString()}
        {info.componentStack}
      </pre>
      <ResumeOptions />
    </div>
  )
}

function DefaultMessage () {
  return (
    <div>
      {sentryEnabled() && (
        <p>
          {translate('Unfortunately, we don\'t know how to fix this yet, but our engineers have been\n notified, and will start working on this problem to prevent it from happening again in\n the future.\n If you want, you may provide us with additional information or context as to what\n might have caused this problem.')}
        </p>
      )}
      {sentryEnabled() && !config.sentry.enableAutoUserFeedback && (
        <p>
          <Button
            onClick={() => askForUserFeedback(true)}>Provide additional information
          </Button>
        </p>
      )}
      <ResumeOptions />
    </div>
  )
}

export function ErrorMessage ({ error, info }: ErrorMessageProps) {
  return useSelector(configurationSelectors.isDebugMode)
    ? <DebugMessage error={error} info={info} />
    : <DefaultMessage />
}
