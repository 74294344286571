export function passedValueArg(argumentsLength, arg2) {
    return argumentsLength === 2;
}
export class SetProperty {
    setProperty(property, value) {
        const update = (value) => {
            this.updateFn((object) => {
                object[property] = value;
            });
            return this;
        };
        if (passedValueArg(arguments.length, value)) {
            return update(value);
        }
        else {
            return update;
        }
    }
}
