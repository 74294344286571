import React from 'react'
import { Field, Label, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'
import compose from 'lodash/fp/compose'

import { CurrencyAdornment } from '../../../../../../components/atoms/CurrencyAdornment'
import { RateModel, ServiceCogsType, ServiceModel } from '../../../../../../data/types'

interface CogsProps {
  service: ServiceModel | null
  cogs: number | null
  activeRate?: RateModel
  onChangeCogs: (cogs: number) => void
}

export function Cogs ({ service, activeRate, cogs, onChangeCogs }: CogsProps) {
  const cogsIsManual = service?.attributes.cogs_type === ServiceCogsType.Manual

  return (
    <Field data-testid='cogs'>
      <Label.Group>
        <Label>{translate('Cogs')}</Label>
        <Label.Sub>
          {translate('Inherits value from the current active rate when the cogs type of the service is manual.')}
        </Label.Sub>
      </Label.Group>
      <CurrencyAdornment>
        <Input
          type='number'
          disabled={cogsIsManual}
          value={
            cogsIsManual
              ? activeRate?.attributes.cogs_rate
              : cogs || activeRate?.attributes.cogs_rate || 0
          }
          onChange={compose(onChangeCogs, Number)} />
      </CurrencyAdornment>
    </Field>
  )
}
