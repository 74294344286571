import React from 'react'
import PropTypes from 'prop-types'

import './index.css'
import { classNames } from '../../../utils/misc'

// Component uses onMouseDown instead of onClick as a solution
// to a problem we had with the datepicker component
/**
 * @type {React.ComponentType<any>}
 */
const Icon = ({ material, children, subIcon, className, onClick }) => (
  <>
    <span
      className={classNames({
        'ex-icon--material': material,
        'ex-icon__sup': subIcon,
        'ex-icon__sup--empty': !children && subIcon
      }, 'ex-icon', className)}
      data-uk-icon={material ? undefined : `icon: ${children}; ratio: 1`}
      onMouseDown={onClick}>
      {material ? children : undefined}
    </span>
    {subIcon && (
      <span className={classNames({ 'ex-icon--material': material }, 'ex-icon__sub')}
        data-uk-icon={material ? undefined : `icon: ${subIcon}; ratio: 1`}
        onMouseDown={onClick}>
        {material ? subIcon : undefined}
      </span>
    )}
  </>
)

Icon.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  material: PropTypes.bool,
  onClick: PropTypes.func,
  subIcon: PropTypes.string
}

export default Icon
