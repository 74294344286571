import { useEffect, useRef, useState } from 'react';
// @todo remove this component
export function usePrevWhileLoading(prop) {
    var prev = useRef(prop);
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    useEffect(function () {
        if (!loading) {
            prev.current = prop;
        }
    }, [loading, prop]);
    return [loading ? prev.current : prop, setLoading, loading];
}
