import { UpdateFlagsData } from '../../actions/system/flags'

export interface FlagsState {
  SUPPORTS_PDF_EXPORT?: boolean
  SUPPORTS_SENDING_MAIL?: boolean
}

const initialState: FlagsState = {}

const flags = (state = initialState, action: UpdateFlagsData): FlagsState => {
  switch (action.type) {
    case UpdateFlagsData.type:
      return { ...state, ...action.data }

    default:
      return state
  }
}

export default flags
