"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.composeAsync = void 0;
var composeAsync = function () {
    var list = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        list[_i] = arguments[_i];
    }
    return function (args) { return list.reduceRight(function (acc, fn) { return acc.then(fn); }, Promise.resolve(args)); };
};
exports.composeAsync = composeAsync;
