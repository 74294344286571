import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GroupBox, Button } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { queries, Resources, useCacheQuery } from '@exivity/data-layer'
import { NEW_PARAM, useParams } from '@exivity/routing'

import { authSelectors } from '../../../auth/state'
import { createNotificationSubscription, UserModel } from '../../../../data/types'
import { NotificationParams } from '../../pages/MyNotifications'

import { Notification } from './Notification'

function populateNotification (user: { type: string; id: string }) {
  return createNotificationSubscription(
    undefined,
    undefined,
    { user: { data: user } }
  ) as any as Resources['notificationsubscription']
}

function useNotifications (user: UserModel) {
  const notificationRef = useRef(populateNotification(user))

  useEffect(() => {
    if (user) {
      notificationRef.current = populateNotification(user)
    }
  }, [user])

  return [
    notificationRef.current,
    useCacheQuery(
      queries
        .relationshipOf('user', user, 'notificationsubscriptions')
        .sortByAttribute('name', 'ascending')
    )
  ] as const
}

// This component is used in the Profile domain as well as UserManagement domain
// In the UserManagement domain it gets assigned the selected user
// while in the profile domain its the currentUser who is logged in.

function Notifications ({ user }: { user?: UserModel }) {
  const [{ notification_id }, setParams] = useParams<NotificationParams>()

  const currentUser = useSelector(authSelectors.getCurrentUser)
  const [notification, notifications] = useNotifications(user || currentUser)

  if (notification_id === NEW_PARAM) {
    return (
      <Notification notification={notification} user={user || currentUser} />
    )
  }

  return (
    <>
      {notification_id !== NEW_PARAM && (
        <Button small success onClick={() => setParams('notification_id', NEW_PARAM)}>
          {translate('Add Notification')}
        </Button>
      )}

      {!notifications.length && (
        <GroupBox>
          <GroupBox.Header>
            <GroupBox.Title>{translate('No notifications configured.')}</GroupBox.Title>
          </GroupBox.Header>
        </GroupBox>
      )}

      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          user={user || currentUser} />
      ))}

    </>
  )
}

export function NotificationsProfile () {
  return <Notifications />
}

export function NotificationsUserManagement ({ user }: { user: UserModel }) {
  return <Notifications user={user} />
}
