var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
import { AppBarButton } from './AppBarButton';
var StyledDropdown = styled(Dropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .dropdown__drop {\n    padding: ", " ", ";\n  }\n"], ["\n  .dropdown__drop {\n    padding: ", " ", ";\n  }\n"])), themeSpace(1), themeSpace(2));
var UnorderedList = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  \n  li {\n    margin-bottom: 5.6px;\n  }\n"], ["\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  \n  li {\n    margin-bottom: 5.6px;\n  }\n"])));
export function AppBarDropdown(_a) {
    var label = _a.label, children = _a.children, rest = __rest(_a, ["label", "children"]);
    return (_jsx(StyledDropdown, __assign({ placement: 'bottom-end', reference: _jsx(AppBarButton, __assign({ type: 'button' }, rest, { children: label }), void 0) }, { children: _jsx(UnorderedList, { children: children.map(function (child, index) { return (_jsx("li", { children: child }, index)); }) }, void 0) }), void 0));
}
var templateObject_1, templateObject_2;
