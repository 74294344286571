interface Action {
  type: string

  [key: string]: any
}

export default class ClassAction implements Action {
  type = 'UNDEFINED_ACTION_TYPE_DISPATCHED'
}

const toPlainObject = (action: Action) => {
  const plainObject: any = {}
  for (const x in action) {
    if (x === 'constructor') {
      continue
    }
    plainObject[x] = action[x]
  }
  return plainObject
}

const createUseClassAction = function () {
  return () => (next: any) => (action: any) => {
    if (action instanceof ClassAction) {
      return next(toPlainObject(action))
    }
    return next(action)
  }
}

export const useClassAction = createUseClassAction()

export function createClassAction<C extends new (...args: any) => any>(Action: C) {
  return (...args: ConstructorParameters<C>) => new Action(...args) as ClassAction
}
