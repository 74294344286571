import React from 'react'
import { CrudRecord } from 'react-crud-hook'
import { Link } from '@exivity/routing'
import { UserGroupPermission } from '@exivity/data-layer'

import { components } from '../../../'
import { getRelationship } from '../../../../utils/records'
import { WrapIf } from '../../../../components/atoms/WrapIf'
import { ServiceModel } from '../../../../data/types'
import { useAllowPermissions } from '../../../../components/organisms/Permission'

import { MetadataProps } from './'

const DEFAULT_ID = { id: undefined }

function renderPlaceholder (service: CrudRecord<ServiceModel>, hasPermission: boolean) {
  if (!service.attributes.dset) return 'First choose a data source at details'

  return (
    <>
      No metadata assigned to this&nbsp;
      <WrapIf
        condition={hasPermission}
        wrapper={(node) => (
          <Link to={`../../data-pipelines/datasets/${service.attributes.dset}`}>
            {node}
          </Link>
        )}>
        dataset
      </WrapIf>
    </>
  )
}

export function Metadata ({ service, dataset, onRecord }: MetadataProps) {
  const definitionId = getRelationship(dataset, 'metadatadefinition', DEFAULT_ID).id
  const hasPermission = useAllowPermissions(UserGroupPermission.ManageDatasets)

  return (
    <components.metadata.MetadataForm
      model={service}
      definitionId={definitionId}
      placeholder={renderPlaceholder(service, hasPermission)}
      onRecord={onRecord} />
  )
}
