import ReactGA from 'react-ga'

import config from '../application/config'
import { configurationSelectors } from '../domains/administration/state/configuration'
import store from '../store'

let initialized = false
const trackerNames = ['default']

const initAnalytics = () => {
  initialized = true

  const properties = [
    {
      trackingId: config.analytics.defaultProperty,
      gaOptions: { name: 'default' }
    }
  ]

  const extraAnalyticsPoperty = configurationSelectors.getExtraAnalyticsProperty(store.getState())

  if (extraAnalyticsPoperty) {
    properties.push({
      trackingId: extraAnalyticsPoperty,
      gaOptions: { name: 'extra' }
    })
    trackerNames.push('extra')
  }

  ReactGA.initialize(properties, { alwaysSendToDefaultTracker: false })
  ReactGA.set({ appName: config.app.name }, trackerNames)
}

export function trackPageview (page) {
  if (!configurationSelectors.isAnalyticsEnabled(store.getState())) {
    return
  }

  initialized || initAnalytics()
  ReactGA.pageview(page, trackerNames)
}
