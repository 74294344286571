import { Find } from './find';
import { FindAll } from './findAll';
import { RelationshipOf } from './relationshipOf';
import { applyMixins } from './utils';
class QueryImpl {
    constructor(schema, queryExpression) {
        this.schema = schema;
        this.queryExpression = queryExpression;
    }
    toQuery() {
        return this.queryExpression;
    }
}
applyMixins(QueryImpl, [Find, FindAll, RelationshipOf]);
export function createQuery(schema, queryExpression) {
    return new QueryImpl(schema, queryExpression);
}
