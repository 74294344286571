/* eslint-disable no-restricted-globals */

export function isExivityNetHostname () {
  return typeof self !== 'undefined' && self.location.hostname.endsWith('.exivity.net')
}

export function isDevPreviewHostname () {
  return typeof self !== 'undefined' && self.location.hostname.endsWith('.web.app')
}

export function isDevHostname () {
  return isExivityNetHostname() || isDevPreviewHostname()
}

export function isDevEnvironment () {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV !== 'production' || isDevHostname()
}
