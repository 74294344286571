import { translate } from '@exivity/translations'
// eslint-disable-next-line no-restricted-imports
import { createAsyncThunk as rtkCreateAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction, Action } from 'redux'
import { mapValues } from 'lodash'
import { compose } from 'lodash/fp'

import type { RootState } from '../reducers'

import ClassAction from './middleware/ClassAction'

export type Extensions = {
  translate: typeof translate
}

export type ActionDispatcher = ThunkAction<void, RootState, Extensions, ClassAction | AnyAction>
export type Dispatch = ThunkDispatch<RootState, Extensions, ClassAction | AnyAction>

interface ThunkConfig { state: RootState; dispatch: Dispatch; extra: Extensions }

export type ActionWithExtraActions = Action & { meta: { extraActions: Action[] }}

export function hasExtraActions (action: any): action is ActionWithExtraActions {
  return !!action?.meta?.extraActions
}

export const createAsyncThunk = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>,
  options?: Parameters<typeof rtkCreateAsyncThunk>[2]
) => {
  return rtkCreateAsyncThunk(typePrefix, payloadCreator, options)
}

export const createSelector = <
  Slice,
  Selector extends ((slice: Slice) => any)
> (selectSlice: (state: RootState) => Slice) => (
    selector: Selector
  ) => (state: RootState) => selector(selectSlice(state))

export function createSelectors<
  Slice,
  Selectors extends { [key: string]: (slice: Slice) => any }
  >(
  selectSlice: (state: RootState) => Slice,
  selectors: Selectors
): { [K in keyof Selectors]: (state: RootState) => ReturnType<Selectors[K]> } {
  return mapValues(selectors, (selector) => {
    return compose(selector, selectSlice)
  })
}
