import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from '@exivity/routing'

import Footer from '../../../../components/structural/Footer'
import { useDomainActions } from '../../components/useDomainActions'
import { useAppState } from '../../components/useAppState'
import { RequestResetForm } from '../../components/ResetPasswordForms/RequestResetForm'
import { ResetPasswordForm } from '../../components/ResetPasswordForms/ResetPasswordForm'
import { Center } from '../Login/Login'
import { configurationThunks } from '../../../administration/state/configuration'

import './PasswordResetter.css'

export default function ResetPassword () {
  const dispatch = useDispatch()
  const { updateDomain } = useDomainActions()
  const { isLoading, apiRoot } = useAppState()
  const [{ token }] = useParams()
  const navigate = useNavigate()

  const onDomainChanged = (apiRoot: string) => {
    updateDomain(apiRoot)
    dispatch(configurationThunks.fetchConfig())
  }

  useEffect(() => {
    dispatch(configurationThunks.bootstrapAppConfig())
  }, [dispatch])

  return (
    <Center>
      <div style={{ width: 420 }} className='uk-margin-top'>
        {token
          ? (
            <ResetPasswordForm token={token}
              apiRoot={apiRoot}
              loading={isLoading}
              onDomainChanged={onDomainChanged}
              onRedirectToLoginPage={() => navigate('/login')} />
          )
          : (
            <RequestResetForm apiRoot={apiRoot}
              loading={isLoading}
              onDomainChanged={onDomainChanged}
              onRedirectToLoginPage={() => navigate('/login')} />
          )}
      </div>
      <Footer />
    </Center>
  )
}
