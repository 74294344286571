import { lazy } from 'react'
import { translate } from '@exivity/translations'

const MyNotifications = lazy(() => import(
  /* webpackChunkName: 'notifications' */ '../domains/myNotifications/pages'))

export const myNotifications = {
  path: '/notifications/:entity?/:notification_id?',
  meta: {
    title: () => translate('My notifications')
  },
  component: MyNotifications
}
