export function injectIf(property, css) {
    return function (props) { return function () { return props[property] === true
        ? css
        : ''; }; };
}
export function injectUnless(property, css) {
    return function (props) { return function () { return props[property] === true
        ? ''
        : css; }; };
}
