var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useFileReader } from '@exivity/hooks';
import { MdAdd, MdDelete } from 'react-icons/md';
import { fromTheme } from '../Theme';
import { ProgressBar } from '../Progress';
import { Icon } from '../Icon';
import { useFieldContext } from '../Field/context';
var UploadBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center; \n     \n  height: 100px;\n  width: 100px;\n  padding: 10px;\n  \n  background-color: ", ";\n  border: 1px dashed ", ";\n  border-radius: 3px;\n  transition: border-color 0.3s ease;\n  cursor: pointer;\n   \n  &:hover {\n    border-color: ", ";\n  }\n  \n  ", " {\n    color: ", ";\n    height: 28px;\n  }\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center; \n     \n  height: 100px;\n  width: 100px;\n  padding: 10px;\n  \n  background-color: ", ";\n  border: 1px dashed ", ";\n  border-radius: 3px;\n  transition: border-color 0.3s ease;\n  cursor: pointer;\n   \n  &:hover {\n    border-color: ", ";\n  }\n  \n  ", " {\n    color: ", ";\n    height: 28px;\n  }\n"])), fromTheme(function (theme) { return theme.colors.lightGray; }), function (_a) {
    var error = _a.error;
    return error
        ? fromTheme(function (theme) { return theme.global.purposes.danger; })
        : fromTheme(function (theme) { return theme.colors.gray; });
}, fromTheme(function (theme) { return theme.global.purposes.primary; }), Icon, fromTheme(function (theme) { return theme.colors.gray; }));
var Overlay = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  opacity: 0;\n  transition: .3s ease;\n  background-color: ", ";\n  \n  height: calc(100% - 20px);\n  width: calc(100% - 20px);\n"], ["\n  position: absolute;\n  opacity: 0;\n  transition: .3s ease;\n  background-color: ", ";\n  \n  height: calc(100% - 20px);\n  width: calc(100% - 20px);\n"])), fromTheme(function (theme) { return theme.global.purposes.primary; }));
var StyledPreviewer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center; \n  \n  height: 100px;\n  width: 100px;\n  padding: 10px;\n  \n  border: 1px solid ", ";;\n  border-radius: 5px;\n  \n  ", " {\n    display: flex;\n    color: #FFF;\n    align-items: center;\n    justify-content: center; \n    position: absolute;\n    height: 100%;\n    width: 100%;\n    visibility: hidden;\n    cursor: pointer;\n  }\n    \n  &:hover {\n    background: transparent;\n    border-color: ", ";\n    \n    ", " {\n      opacity: 0.5;\n    }\n    \n    ", " {\n      visibility: visible;\n      opacity: 1;\n    }\n  }\n  \n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n  \n  img {\n    height: 100%;\n    width: 100%;\n  }\n"], ["\n  box-sizing: border-box;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center; \n  \n  height: 100px;\n  width: 100px;\n  padding: 10px;\n  \n  border: 1px solid ", ";;\n  border-radius: 5px;\n  \n  ", " {\n    display: flex;\n    color: #FFF;\n    align-items: center;\n    justify-content: center; \n    position: absolute;\n    height: 100%;\n    width: 100%;\n    visibility: hidden;\n    cursor: pointer;\n  }\n    \n  &:hover {\n    background: transparent;\n    border-color: ", ";\n    \n    ", " {\n      opacity: 0.5;\n    }\n    \n    ", " {\n      visibility: visible;\n      opacity: 1;\n    }\n  }\n  \n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n  \n  img {\n    height: 100%;\n    width: 100%;\n  }\n"])), fromTheme(function (theme) { return theme.colors.lightGray; }), Icon, fromTheme(function (theme) { return theme.global.purposes.primary; }), Overlay, Icon, fromTheme(function (theme) { return theme.global.purposes.primary; }));
function Previewer(_a) {
    var src = _a.src, onClear = _a.onClear;
    return (_jsxs(StyledPreviewer, __assign({ tabIndex: 0 }, { children: [_jsx(Overlay, {}, void 0), _jsx("img", { src: src, alt: 'preview' }, void 0), _jsx(Icon, __assign({ onClick: onClear }, { children: _jsx(MdDelete, { size: 28 }, void 0) }), void 0)] }), void 0));
}
export function Upload(_a) {
    var onChange = _a.onChange, value = _a.value, rest = __rest(_a, ["onChange", "value"]);
    var fieldContext = useFieldContext();
    var inputRef = useRef(null);
    var _b = useFileReader(), inputOnChange = _b.onChange, onResult = _b.onResult, loading = _b.loading, progress = _b.progress, error = _b.error;
    var onUploadClick = useCallback(function () {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);
    onResult(onChange);
    return (_jsxs("div", { children: [_jsx("input", __assign({ ref: inputRef, type: 'file', id: fieldContext.id, name: fieldContext.id, "data-testid": 'upload-input', hidden: true, onChange: inputOnChange }, rest), void 0), !value
                ? (_jsx(UploadBox, __assign({ error: error, onClick: onUploadClick }, { children: loading
                        ? _jsx(ProgressBar, { percentage: progress }, void 0)
                        : (_jsx(Icon, { children: _jsx(MdAdd, { size: 28 }, void 0) }, void 0)) }), void 0))
                : (_jsx(Previewer, { src: value, onClear: function () {
                        onChange(null);
                        if (inputRef.current) {
                            inputRef.current.value = '';
                        }
                    } }, void 0))] }, void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
