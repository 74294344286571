import { singleLine } from '@exivity/translations'

import { workThunks } from '../../work/thunks'
import { patch } from '../../../API/index'
import { ServiceCategoryModel, ServiceModel } from '../../../data/types'
import { updateRecord } from '../../../utils/data'
import orbit from '../../../data/store'
import { ActionDispatcher } from '../../../store/utils'

export function bulkDelete (serviceIds: string[]): ActionDispatcher {
  return async function thunk (dispatch, _, { translate }) {
    try {
      let deleted = await orbit.update(t => serviceIds
        .map((id) => t.removeRecord({ id, type: 'service' }))
      )

      if (!Array.isArray(deleted)) {
        deleted = [deleted]
      }

      await patch('/services', {}, {
        operations: deleted.map((service: ServiceModel) => ({
          op: 'delete',
          data: service
        }))
      })

      dispatch(workThunks.showSuccessMessage(translate('Services deleted.')))
    } catch (error: any) {
      dispatch(workThunks.showErrorMessage(
        singleLine(
          error.message || translate('Deleting services failed') + '.',
          translate('Please reload the page.')
        ))
      )
    }
  }
}

export function updateCategory (category: ServiceCategoryModel): ActionDispatcher {
  const { id, type, attributes } = category

  return async function thunk (dispatch, _, { translate }) {
    try {
      await updateRecord(type, id, attributes)
      dispatch(workThunks.showSuccessMessage(translate('Updated servicecategory.')))
    } catch (error) { }
  }
}
