var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Icon } from '../Icon';
import { themeGlobal, themeSpace } from '../Theme';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  height: 60px;\n  background-color: transparent;\n  padding: 0 ", ";\n  font-family: ", ";\n  font-size: 0.765625em;\n  color: ", ";\n  visibility: ", ";\n\n  text-transform: uppercase;\n  display: inline-flex;\n  position: relative;\n  appearance: none;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border: none;\n  flex: none;\n  user-select: none;\n  white-space: nowrap;\n  text-decoration: none;\n  outline: none;\n  \n  &:hover, &:focus {\n    box-shadow: inset 10px 10px 999em rgba(0,0,0,", ");\n  }\n  \n  ", " {\n    font-size: 1.5em;\n  }\n"], ["\n  display: flex;\n  gap: ", ";\n  height: 60px;\n  background-color: transparent;\n  padding: 0 ", ";\n  font-family: ", ";\n  font-size: 0.765625em;\n  color: ", ";\n  visibility: ", ";\n\n  text-transform: uppercase;\n  display: inline-flex;\n  position: relative;\n  appearance: none;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border: none;\n  flex: none;\n  user-select: none;\n  white-space: nowrap;\n  text-decoration: none;\n  outline: none;\n  \n  &:hover, &:focus {\n    box-shadow: inset 10px 10px 999em rgba(0,0,0,", ");\n  }\n  \n  ", " {\n    font-size: 1.5em;\n  }\n"])), themeSpace(1), themeSpace(2), themeGlobal('fontFamily'), themeGlobal('inverseBrandTextColor'), function (_a) {
    var visible = _a.visible;
    return visible ? 'visible' : 'hidden';
}, themeGlobal('shadowAlpha'), Icon);
export function AppBarButton(_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, icon = _a.icon, children = _a.children, _c = _a.visible, visible = _c === void 0 ? true : _c, rest = __rest(_a, ["type", "icon", "children", "visible"]);
    var isSmallScreen = false;
    return (_jsxs(StyledButton, __assign({ visible: visible, type: type }, rest, { children: [_jsx(Icon, { children: icon }, void 0), isSmallScreen
                ? null
                : children] }), void 0));
}
var templateObject_1;
