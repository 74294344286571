import React from 'react'
import { queries, useCacheQuery } from '@exivity/data-layer'
import { Field, Label, Text, Select, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { compose } from '@exivity/fp'

interface OffsetsProps {
  fromDate?: number
  toDate?: number
  onChangeFromDate: (fromDate: number) => void
  onChangeToDate: (fromDate: number) => void
}

function Offsets ({ fromDate, toDate, onChangeFromDate, onChangeToDate }: OffsetsProps) {
  return (
    <>
      <Field>
        <Label.Group>
          <Label>{translate('Start offset')}</Label>
          <Label.Sub>
            {translate('Used to derive start date according to the following formula: CurrentDate + StartOffset = StartDate.')}
          </Label.Sub>
        </Label.Group>
        <Field.Group>
          <Input
            required
            type='number'
            step={1}
            placeholder={translate('Start offset')}
            value={fromDate ?? ''}
            onChange={compose(onChangeFromDate, Number)} />
          <Text>{translate('Days')}</Text>
        </Field.Group>
      </Field>

      <Field>
        <Label.Group>
          <Label>{translate('End offset')}</Label>
          <Label.Sub>
            {translate('Used to derive end date according to the following formula: CurrentDate + EndOffset = EndDate.')}
          </Label.Sub>
        </Label.Group>
        <Field.Group>
          <Input
            required
            type='number'
            step={1}
            placeholder={translate('End offset')}
            value={toDate ?? ''}
            onChange={compose(onChangeToDate, Number)} />
          <Text>{translate('Days')}</Text>
        </Field.Group>
      </Field>
    </>
  )
}

interface EnvironmentProps {
  environmentId?: string
  onChangeEnvironmentId: (environmentId: string) => void
}

function Environment ({ environmentId, onChangeEnvironmentId }: EnvironmentProps) {
  const environments = useCacheQuery(queries.findAll('environment'))

  return (
    <Field>
      <Label>{translate('Environment')}</Label>
      <Select
        data={environments}
        value={environmentId}
        valueAccessor={environment => environment.id}
        labelAccessor={environment => environment.attributes.name}
        placeholder={translate('Select an environment')}
        onChange={onChangeEnvironmentId} />
    </Field>
  )
}

export const Shared = {
  Environment,
  Offsets
}
