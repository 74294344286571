import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { compose } from 'lodash/fp'

import { RootState } from '../../../reducers'
import { PreferencesState, profileActions } from '../state'

type SetPreference = (key: keyof PreferencesState, value: any) => void

type Preferences<T extends keyof PreferencesState = any> = [
  Partial<PreferencesState>| PreferencesState[T],
  SetPreference
]

export function useUserPreferences(): Preferences
export function useUserPreferences<T>(
  selector: (preferences: PreferencesState) => T
): Preferences

export function useUserPreferences (
  selector?: (preferences: PreferencesState) => Partial<PreferencesState>
): Preferences {
  const dispatch = useDispatch()

  const preferences = useSelector((state: RootState) => selector
    ? selector(state.profile.preferences)
    : state.profile.preferences,
  shallowEqual)

  return useMemo(() => [
    preferences,
    compose(dispatch, profileActions.updateUserPreferences)
  ], [preferences])
}
