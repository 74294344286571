import React, { PureComponent } from 'react'
import { CrudRecord } from 'react-crud-hook'
import { Label, Input, Select, TextArea, Heading, MetaTable, Form } from '@exivity/ui'
import { translate } from '@exivity/translations'

import Collapser from '../../../../../components/atoms/Collapser'
import Underline from '../../../../../components/atoms/Underline'
import {
  METADATA_TYPES, MetadataDefinitionModel, ReportModel, DsetModel
} from '../../../../../data/types'
import { pureSplice } from '../../../../../utils/array'
import ExpandableTable, {
  Header,
  Row as ExpandingRow,
  ValuesCallback,
  Cell,
  ValueRow
} from '../../../../../components/molecules/ExpandableTable'
import { Create, Remove, Update } from '../../../../../components/atoms/Button/Crud'
import store from '../../../../../data/store'

import { getDefinitionUsageText } from './helpers'
import './index.css'

interface MetadataDetailsProps {
  definition: CrudRecord<MetadataDefinitionModel>
  entries: number
  reports: ReportModel[]
  datasets: DsetModel[]
}

interface MetadataDetailsState {
  valueRows: ValueRow[]
}

const COLUMN_WIDTHS = ['35%', '35%', '30%']
const WIDTH_SELECT_CELL = { width: 180 }

const NAME = 'name'
const FIELD_LABEL = 'name'
const TYPE_COLUMN = 'type'
const VALIDATION = 'validate'
const OPTIONS = 'list'
const COLLAPSED = 'collapsed[]'

const INITIAL_VALUES: ValueRow[] = [{
  [FIELD_LABEL]: '',
  [TYPE_COLUMN]: '',
  [VALIDATION]: '',
  [OPTIONS]: '',
  [COLLAPSED]: false
}]

const MORE_OPTIONS = [
  METADATA_TYPES.TEXT,
  METADATA_TYPES.LIST
]

const TYPES = [
  { value: METADATA_TYPES.TEXT, label: METADATA_TYPES.TEXT },
  { value: METADATA_TYPES.NUMERIC, label: METADATA_TYPES.NUMERIC },
  { value: METADATA_TYPES.DATE, label: METADATA_TYPES.DATE },
  { value: METADATA_TYPES.LIST, label: METADATA_TYPES.LIST }
]

const onCreate = (record: any, { router }: any) => {
  router.push(`data-pipelines/metadata/${record.id}`)
}

const onDelete = (record: any, { router }: any) => {
  router.push('data-pipelines/metadata')
  // report attribute needs to be updated - metadatadefinition id.
  store.query(q => q.findRecords('report')
    .page({ offset: 0, limit: -1 }))
}

export class MetadataDetails extends PureComponent<MetadataDetailsProps, MetadataDetailsState> {
  constructor (props: MetadataDetailsProps) {
    super(props)

    this.state = {
      valueRows: props.definition.id
        ? props.definition.attributes.fields.map((item) => ({ ...item, [COLLAPSED]: true }))
        : INITIAL_VALUES
    }
  }

  filterValues = (valueRows: ValueRow[]): ValueRow[] => valueRows.map(row => (
    Object.keys(row).reduce((obj: any, value) => {
      if (row[value] && value !== COLLAPSED) {
        obj[value] = row[value]
      }

      return obj
    }, {})
  ))

  onChangeValues = (valueRows: ValueRow[]) => {
    this.setState({ valueRows })
    const valuesWithoutCollapsedAndUndefined = this.filterValues(valueRows)
    this.props.definition.setAttribute('fields', valuesWithoutCollapsedAndUndefined)
  }

  setValue = (lineItem: number) => (value: string | string[] | boolean, option: string): void => {
    const { valueRows } = this.state
    const newValue: ValueRow = { ...valueRows[lineItem] }

    newValue[option] = value
    const newValues = pureSplice(valueRows, lineItem, 1, newValue)
    this.onChangeValues(newValues)
  }

  renderSelect = (lineItem: number, valueRow: ValueRow) => {
    const handleChange = this.setValue(lineItem)

    return (
      <Cell>
        <div style={WIDTH_SELECT_CELL}>
          <Select
            placeholder={translate('Choose option')}
            labelAccessor={item => item.label}
            valueAccessor={item => item.value}
            value={valueRow[TYPE_COLUMN]}
            data={TYPES}
            onChange={(value) => handleChange(value, TYPE_COLUMN)} />
        </div>
      </Cell>
    )
  }

  renderExpansion = (lineItem: number, valueRow: ValueRow) => {
    const handleOnChange = this.setValue(lineItem)
    const validation = valueRow[VALIDATION]
    const options = valueRow[OPTIONS]

    if (valueRow[COLLAPSED] === false) {
      switch (valueRow[TYPE_COLUMN]) {
        case METADATA_TYPES.TEXT:
          return (
            <ExpandingRow noBorder>
              <Cell>
                <Label.Group>
                  <Label>{translate('Validation')}</Label>
                  <Label.Sub>{translate('Use regex to validate field input')}</Label.Sub>
                </Label.Group>
              </Cell>
              <Cell>
                <Input name={VALIDATION}
                  value={validation}
                  placeholder={translate('Validation')}
                  data-test='validation-field'
                  onChange={(value: string) => handleOnChange(value, VALIDATION)} />
              </Cell>
              <Cell />
            </ExpandingRow>
          )

        case METADATA_TYPES.LIST:
          return (
            <ExpandingRow noBorder>
              <Cell>
                <Label.Group>
                  <Label>{translate('List of options')}</Label>
                  <Label.Sub>{translate('Each option on a new line')}</Label.Sub>
                </Label.Group>
              </Cell>
              <Cell>
                <TextArea name={OPTIONS}
                  rows={5}
                  data-test='list-field'
                  value={options && options.join('\n')}
                  onBlur={() => {
                    if (options) {
                      handleOnChange(
                        // filter out options containing only whitespace
                        options.filter((option: string) => !/^\s*$/.test(option)),
                        OPTIONS
                      )
                    }
                  }}
                  onChange={(value: string) => {
                    handleOnChange(value.split('\n'), OPTIONS)
                  }} />
              </Cell>
              <Cell />
            </ExpandingRow>
          )

        case METADATA_TYPES.NUMERIC:
        case METADATA_TYPES.DATE:
          return null

        default:
          return null
      }
    }

    return null
  }

  renderCollapser = (lineItem: number, valueRow: ValueRow) => {
    const handleCollapse = this.setValue(lineItem)

    if (MORE_OPTIONS.includes(valueRow[TYPE_COLUMN])) {
      return (
        <Cell align='right'>
          <Collapser
            value={valueRow[COLLAPSED]}
            onChange={(value) => handleCollapse(value, COLLAPSED)}>
            <Underline>
              {valueRow[COLLAPSED]
                ? translate('More options')
                : translate('Hide options')}
            </Underline>
          </Collapser>
        </Cell>
      )
    }

    return <Cell />
  }

  addRow = () => this.setState({ valueRows: this.state.valueRows.concat(INITIAL_VALUES) })

  removeRow = (lineItem: number) => {
    const valueRows = this.state.valueRows.filter((item, index) => index !== lineItem)
    const saveValueRows = !valueRows.length
      ? INITIAL_VALUES
      : valueRows

    this.setState({
      valueRows: saveValueRows
    })

    this.props.definition.setAttribute('fields', saveValueRows)
  }

  render () {
    const { definition, entries, reports, datasets } = this.props
    const usageText = getDefinitionUsageText(definition.id, { reports, datasets })

    return (
      <Form className='ex-metadata-details'>
        <Heading.Input
          label={translate('Name')}
          placeholder={translate('Metadata name')}
          autoFocus={!definition.id}
          value={definition.attributes.name}
          onChange={definition.setAttribute(NAME)} />
        <MetaTable>
          <MetaTable.Item label={translate('Reports')} value={usageText} />
          <MetaTable.Item label={translate('Entries')} value={translate('{nbOfEntries} entries', entries)} />
        </MetaTable>
        <ExpandableTable columnWidths={COLUMN_WIDTHS} valueRows={this.state.valueRows}
          onAdd={this.addRow}
          onRemove={this.removeRow}
          onChange={this.onChangeValues}>
          <Header>{translate('Field label')}</Header>
          <Header>{translate('Type')}</Header>
          <Header />
          <ExpandingRow>
            <Cell>
              <Input name={FIELD_LABEL} placeholder={translate('Field label')} data-test='label-field' />
            </Cell>
            <ValuesCallback>
              {this.renderSelect}
            </ValuesCallback>
            <ValuesCallback>
              {this.renderCollapser}
            </ValuesCallback>
          </ExpandingRow>
          <ValuesCallback>
            {this.renderExpansion}
          </ValuesCallback>
        </ExpandableTable>
        <Form.Toolbar>
          {!definition.id
            ? <Create onClick={() => definition.save({ onCreate })} />
            : (
              <>
                <Remove onClick={() => definition.delete({
                  beforeDelete: (record: any, { confirm }: any) => (
                    confirm(translate('Do you want to delete this definition?'))
                  ),
                  onDelete
                })} />
                <Update onClick={definition.save} />
              </>
            )
          }
        </Form.Toolbar>
      </Form>
    )
  }
}
