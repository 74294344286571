import React from 'react'
import { Field, Label, Select } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { ServiceModel } from '../../../../../../data/types'

interface ServiceProps {
  service: ServiceModel | null
  services: ServiceModel[]
  onChangeService: (service: ServiceModel | null) => void
}

export function Service ({ service, services, onChangeService }: ServiceProps) {
  return (
    <Field data-testid='service'>
      <Label>{translate('Service')}</Label>
      <Select
        searchable
        placeholder={translate('Select a service')}
        value={service ?? undefined}
        labelAccessor={(service) => service.attributes.description}
        data={services}
        onChange={onChangeService} />
    </Field>
  )
}
