import { createSelector } from 'reselect'

import { ReportModel } from '../data/types'
import { memory } from '../data/sources'

const getReport = (selectedReport: string | null) => {
  if (!selectedReport) return null

  return memory.cache.query(q => q.findRecord({
    type: 'report',
    id: selectedReport
  })
  ) as ReportModel | null
}

export const getMaxAccountLevel = createSelector(
  getReport,
  (report: ReportModel | null) => {
    return report ? report.attributes.depth : null
  }
)
