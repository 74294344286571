import React, { TdHTMLAttributes } from 'react'

type HeaderAttr = TdHTMLAttributes<HTMLTableHeaderCellElement>

type HeaderType = Omit<HeaderAttr, 'defaultValue'>

export function Header ({ children, ...rest }: HeaderType) {
  return (
    <th {...rest}>{children}</th>
  )
}
// Line 155 relies on this assignment
Header.displayName = 'Header'

export default Header
