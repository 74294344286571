import { copy } from '../utils/copy';
import { copyArray } from '../utils/copyArray';
import { createUpdater } from '../updater';
export class Select {
    select(selector) {
        const slice = selector(this.data);
        if (slice === undefined) {
            throw new Error("Selected slice is undefined. Can't select items added in this chain.");
        }
        return createUpdater(slice, (updateState) => {
            this.updateFn((state) => {
                const target = selector(state);
                const updates = updateState(target);
                if (Array.isArray(updates)) {
                    copyArray(target, updates);
                }
                else if (updates) {
                    copy(target, updates);
                }
            });
        });
    }
}
