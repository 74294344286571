import { replace } from 'ramda'

import { isDevPreviewHostname } from '../../../../utils/system'

import { configurationReducer } from './'

export enum SsoLoginMethod {
  LocalOnly = 'local_user_only',
  LocalOrLdap = 'local_user_or_ldap',
  LocalOrSaml = 'local_user_or_saml',
  SamlOnly = 'saml_only',
  LocalOrLdapOrSaml = 'local_user_or_ldap_or_saml'
}

export type FiscalOffset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const DEV_SERVER_HOSTNAME = 'dev.exivity.net'

export const removeTrailingSlash = replace(/\/$/, '')

function getInitialHostName () {
  if (typeof self !== 'undefined') {
    // If we're running on netlify or in local development,
    // default to our dev server for convenience.
    return isDevPreviewHostname() || process.env.NODE_ENV === 'development'
      ? DEV_SERVER_HOSTNAME
      : self.location.hostname
  }
  return 'example.com'
}

export const initialProtocol = 'https://'
export const initialHostName = getInitialHostName()

// @todo get from api so this is always in sync
export const PUBLIC_CONFIG_KEYS: (keyof ReturnType<typeof configurationReducer>)[] = [
  'APP_DEBUG',
  'APP_NAME',
  'APP_LOGO',
  'APP_ICON',
  'APP_FAVICON',
  'APP_COLOUR',
  'APP_CSS',
  'APP_DOCUMENTATION',
  'APP_LANGUAGE',
  'SSO_LOGIN_METHOD',
  'ANALYTICS',
  'ANALYTICS_EXTRA_PROPERTY',
  'ERROR_TRACKING',
  'BETA_FEATURES',
  'TOKEN_TTL',
  'ALLOW_PERSISTENT_TOKENS',
  'APP_WHITE_LABEL',
  'API_ROOT'
]
