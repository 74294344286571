import config from '../../../../application/config'
import { createAsyncThunk, ActionDispatcher } from '../../../../store/utils'
import { post, patch } from '../../../../API'
import { authActions } from '../'
import { reportsActions } from '../../../reports/state'
import { profileActions } from '../../../myProfile/state'
import { licenseThunks } from '../../../administration/state'
import { registerUserWithSentry } from '../../../../utils/log'
import { getUserSessionStorage } from '../../../../store/storage'
import { AuthToken } from '../../../../API/ResponseSchema'
import { configurationThunks } from '../../../administration/state/configuration'

type GetTokenArgs = {
  username: string
  password: string
  onAbortController?: (abortController: AbortController) => void
}

function currentUserInStorageForSentry (persistent: boolean, user: any) {
  // until better solution we do this for sentry
  const storage = getUserSessionStorage.getStorage(persistent)
  storage.setItem('session', {
    ...(storage.getItem('session') || {}),
    user
  })
}

const initializeUser = createAsyncThunk(
  'auth/initializeUser',
  ({ token, permissions, state, user }: AuthToken['response'], { getState, dispatch }) => {
    registerUserWithSentry(user.username)
    dispatch(authActions.updateToken(token))
    dispatch(authActions.updatePermissions(permissions))
    dispatch(authActions.updateIsAuthenticated(true))

    // Fetch private config
    // Now that we are authenticated it will return us public AND private config
    dispatch(configurationThunks.fetchConfig())

    const { id, ...attributes } = user

    currentUserInStorageForSentry(getState().auth.persistent, user)

    dispatch(authActions.updateCurrentUserId(id))
    dispatch(authActions.updateCurrentUser({ type: 'user', id, attributes }))
    dispatch(profileActions.loadSlice(state.user))
    dispatch(reportsActions.filters.loadSlice(state.reportsFilters))
    dispatch(reportsActions.gui.loadSlice(state.reportsGui))

    // @todo consider putting somewhere else
    dispatch(licenseThunks.fetchLicense())
  }
)

export const loginWithCredentials = createAsyncThunk<void, GetTokenArgs>(
  'auth/loginWithCredentials',
  ({
    username,
    password,
    onAbortController
  }, { dispatch }
  ) => {
    post(
      '/auth/token',
      { app: config.app.name },
      { username, password },
      {},
      onAbortController
    ).then((response) => dispatch(initializeUser(response)))
  }
)

export const loginWithToken = createAsyncThunk(
  'auth/loginWithToken',
  (token: string, { dispatch }) => {
    dispatch(authActions.updateToken(token))

    return patch('/auth/token', { app: config.app.name })
      .then((response) => dispatch(initializeUser(response)))
  }
)

export function refreshToken (): ActionDispatcher {
  return (dispatch) => {
    patch('/auth/token', { app: config.app.name })
      .then(({ token }) => dispatch(authActions.updateToken(token)))
  }
}
