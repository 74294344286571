import reduce from 'lodash/reduce'
import reduceRight from 'lodash/reduceRight'

interface MakeBreadcrumbOptions<T extends any> {
  propAccessor: (item: T) => string
  separator?: string
  direction?: 'left' | 'right'
}

export function makeBreadcrumb<T extends any> (
  items: T[] = [],
  options: MakeBreadcrumbOptions<T>
) {
  const reduceFn = options.direction === 'right'
    ? reduceRight
    : reduce

  return reduceFn(
    items,
    (breadcrumb, item) => breadcrumb.length === 0
      ? options.propAccessor(item)
      : breadcrumb + options.separator + options.propAccessor(item),
    '')
}
