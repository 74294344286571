import { translate } from '@exivity/translations'

import { getMetadataDefinitionIds, inheritsFromId } from '../../../utils'
import { ReportModel, DsetModel } from '../../../../../data/types'

interface ReportAccumulator {
  counter: number
  levels: number[]
}

const getReportDefinitionIds = (reports: ReportModel[]) => (
  reports.map(report => getMetadataDefinitionIds(report))
)

const getDatasetDefinitionIds = (datasets: DsetModel[]) => datasets
  .map(dataset => dataset.relationships?.metadatadefinition?.data?.id)
  .filter(item => typeof item !== 'undefined') as string[]

const countDefinitions = (id: string, ids: string[]) => ids
  .reduce((acc: ReportAccumulator, next: string, index: number, arr: string[]) => {
    if (String(next) === id) {
      return {
        counter: acc.counter + 1,
        levels: acc.levels.concat([index + 1])
      }
    }

    if (inheritsFromId(index, arr) === String(id)) {
      return {
        counter: acc.counter + 1,
        levels: acc.levels.concat([index + 1])
      }
    }

    return acc
  }, {
    counter: 0,
    levels: []
  })

interface UsageAccumulator {
  numberOfReports: number
  levels: number[]
}

const getDefinitionUsage = (id: string, allIds: string[][]) => allIds
  .reduce((acc: UsageAccumulator, reportDefinitionIds: any) => {
    const definition = countDefinitions(id, reportDefinitionIds)

    if (definition.counter > 0) {
      const levels = acc.levels.concat(definition.levels)
      return {
        numberOfReports: acc.numberOfReports + 1,
        levels: Array.from(new Set(levels))
      }
    }
    return acc
  }, {
    numberOfReports: 0,
    levels: []
  })

/**
 * Get a descriptive text for showing to a user to get a sense where this definition is
 * being used. Not perfect, as it will only show either usage in reports _or_ datasets,
 * never both.
 */
export const getDefinitionUsageText = (definitionId?: string, { reports, datasets }: {
  reports: ReportModel[]
  datasets: DsetModel[]
} = { reports: [], datasets: [] }) => {
  if (!definitionId || definitionId === 'new') {
    return translate('This metadata is not in use')
  }

  const definitionIdsPerReport = getReportDefinitionIds(reports)
  const {
    numberOfReports,
    levels
  } = getDefinitionUsage(definitionId, definitionIdsPerReport)

  if (levels.length) {
    return translate('{0} levels across {1} reports use this metadata', levels.length, numberOfReports)
  }

  const numberOfDatasets = getDatasetDefinitionIds(datasets).filter(id => (
    id === definitionId
  )).length

  if (numberOfDatasets) {
    return translate('{0} datasets use this metadata', numberOfDatasets)
  }

  return translate('This metadata is not in use')
}
