import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from '../../../../API/types'

import {
  CSVDecimalSeparatorKeys,
  CSVDelimiterKeys,
  DateFormatKeys,
  DecimalSeparator,
  Precision,
  ThousandsSeparator
} from './options'
import {
  FiscalOffset,
  SsoLoginMethod,
  initialHostName,
  initialProtocol,
  removeTrailingSlash
} from './utils'
import { configurationThunks as thunks } from './thunks'

export const initialState = {
  // means whether basic config was loaded from server (API_ROOT)
  hydrated: false,
  status: FetchStatus.Idle,
  // Interface
  APP_NAME: null as string|null,
  APP_LOGO: null as string|null,
  APP_ICON: null as string|null,
  APP_FAVICON: null as string|null,
  APP_COLOUR: null as string|null,
  APP_DOCUMENTATION: null as boolean|null,
  APP_LANGUAGE: null as string|null,
  APP_WHITE_LABEL: null as boolean|null,
  DATE_FORMAT: null as DateFormatKeys|null,
  APP_CSS: null as string|null,
  PUBLIC_ROOT: null as string|null,

  ANALYTICS: null as boolean|null,
  ANALYTICS_EXTRA_PROPERTY: null as string|null,
  ERROR_TRACKING: null as boolean|null,
  BETA_FEATURES: null as boolean|null,
  APP_DEBUG: null as boolean|null,
  USE_CACHE: null as boolean|null,

  // Formatting
  CURRENCY: null as string|null,
  CURRENCY_FORMAT: null as string|null,
  DECIMAL_SEPARATOR: null as DecimalSeparator|null,
  THOUSAND_SEPARATOR: null as ThousandsSeparator|null,
  CSV_DELIMITER: null as CSVDelimiterKeys|null,
  CSV_DECIMAL_SEPARATOR: null as CSVDecimalSeparatorKeys|null,
  RATE_PRECISION: null as Precision|null,
  REPORT_PRECISION: null as Precision|null,
  SUMMARY_PRECISION: null as Precision|null,
  QUANTITY_PRECISION: null as Precision|null,
  PERCENTAGE_PRECISION: null as Precision|null,

  SUMMARY_TITLE: null as string|null,
  SUMMARY_ADDRESS: null as string|null,
  SUMMARY_IMAGE: null as string|null,
  SUMMARY_EXTRA: null as string|null,
  SUMMARY_MIN_COMMIT: null as string|null,

  REPORT_START_MONTH: null as FiscalOffset|null,

  ADDITIONAL_CORS_ORIGINS: null as string|null,
  SSO_LOGIN_METHOD: null as SsoLoginMethod|null,
  API_ROOT: initialProtocol + initialHostName,
  TOKEN_TTL: null as string|null,
  ALLOW_PERSISTENT_TOKENS: null as boolean|null,

  DISCLAIMER_ENABLED: null as boolean|null,
  DISCLAIMER_TITLE: null as string|null,
  DISCLAIMER_TEXT: null as string|null,
  DISCLAIMER_AGREE_BUTTON_TEXT: null as string|null
}

const { actions, reducer } = createSlice({
  name: 'administration/configuration',
  initialState,
  reducers: {
    updateConfig (state, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    updateHydrated (state, action) {
      state.hydrated = action.payload
    },
    updateApiRoot (state, action) {
      return {
        ...initialState,
        API_ROOT: removeTrailingSlash(action.payload)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.bootstrapAppConfig.rejected, (state) => {
        state.status = FetchStatus.Failed
      })
      .addCase(thunks.bootstrapAppConfig.pending, (state) => {
        state.status = FetchStatus.Loading
      })
      .addCase(thunks.bootstrapAppConfig.fulfilled, (state, action) => {
        state.status = FetchStatus.Succeeded
        state.APP_WHITE_LABEL = action.payload.APP_WHITE_LABEL
        state.API_ROOT = action.payload.API_ROOT
      })
      .addCase(thunks.fetchConfig.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
  }
})

export const configurationThunks = thunks
export const configurationActions = actions
export const configurationReducer = reducer
export { configurationSelectors } from './selector'
