import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { configurationSelectors } from '../../domains/administration/state/configuration'
import { DateFormatter } from '../../utils/formatters/DateFormatter'

export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const ISO_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssX"
export const ISO_YEAR_MONTH_FORMAT = 'yyyy-MM'

export enum Formats {
  // ISO formats
  IsoDate = 'ISO_DATE_FORMAT',
  IsoDateTime = 'ISO_DATE_TIME_FORMAT',
  IsoYearMonth = 'ISO_YEAR_MONTH_FORMAT',

  // Display formats
  GuiDate = 'DATE_FORMAT',
  GuiDateTime = 'DATE_TIME_FORMAT',
  GuiYear = 'YEAR_FORMAT',
  GuiYearQuarter = 'YEAR_QUARTER_FORMAT',
  GuiYearMonth = 'YEAR_MONTH_FORMAT',
  GuiTime = 'TIME_FORMAT',
  GuiMinutes = 'MINUTES_FORMAT'
}

export function useDateFormatter () {
  const guiDateFormat = useSelector(configurationSelectors.getDateFormat)

  return useMemo(() => new DateFormatter({
    [Formats.IsoDate]: ISO_DATE_FORMAT,
    [Formats.IsoDateTime]: ISO_DATE_TIME_FORMAT,
    [Formats.IsoYearMonth]: ISO_YEAR_MONTH_FORMAT,

    [Formats.GuiDate]: guiDateFormat,
    [Formats.GuiDateTime]: `${guiDateFormat} HH:mm`,
    [Formats.GuiYear]: 'yyyy',
    [Formats.GuiYearQuarter]: 'yyyy Qo',
    [Formats.GuiYearMonth]: 'MMMM yyyy',
    [Formats.GuiTime]: 'HH:mm',
    [Formats.GuiMinutes]: 'mm'
  }), [guiDateFormat])
}
