import { append } from 'ramda';
import { createQueryModifier } from './queryModifier';
import { getQueryType, isRelationshipQuery } from './utils';
export class FilterByRelationship {
    filterByRelationship(relation, op, record) {
        var _a, _b;
        const queryType = getQueryType(this.queryExpression);
        const type = isRelationshipQuery(this.queryExpression)
            ? (_a = this.schema.models[queryType].relationships) === null || _a === void 0 ? void 0 : _a[this.queryExpression.relationship].model
            : queryType;
        const kind = ((_b = this.schema
            .models[type]
            .relationships) === null || _b === void 0 ? void 0 : _b[relation].type) === 'hasOne'
            ? 'relatedRecord'
            : 'relatedRecords';
        const filter = kind === 'relatedRecords'
            ? { kind: 'relatedRecords', relation, op, records: record }
            : { kind: 'relatedRecord', relation, op, record };
        return createQueryModifier(this.schema, Object.assign(Object.assign({}, this.queryExpression), { filter: append(filter, this.queryExpression.filter || []) }));
    }
}
