import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { _Table, Variations } from '../Table';
function toFunction(prop) {
    return typeof prop === 'function'
        ? prop
        : function () { return prop; };
}
export function _ActionList(_a) {
    var data = _a.data, itemActions = _a.itemActions, listActions = _a.listActions, keyExtractor = _a.keyExtractor, children = _a.children;
    return (_jsx(Variations.ActionList, { children: _jsx(_Table, { data: data, keyExtractor: keyExtractor, columns: [
                {
                    content: children,
                    header: '',
                    width: '90%'
                },
                {
                    content: toFunction(itemActions),
                    footer: toFunction(listActions),
                    header: '',
                    width: '10%'
                }
            ] }, void 0) }, void 0));
}
export var ActionList = React.memo(_ActionList);
