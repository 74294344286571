import { compose, pickBy, innerJoin, keys, equals } from 'ramda';
import { hexOpacity } from '../utils/colorUtils';
import { defaultTheme, PURPOSES } from './';
var fromTheme = function (themeResolver) { return function (props) {
    var _a;
    return (themeResolver(((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global)
        ? props.theme
        : defaultTheme));
}; };
var themeColor = function (color) { return fromTheme(function (theme) { return theme.colors[color]; }); };
var themeSpace = function (index) { return fromTheme(function (theme) { return theme.space[index]; }); };
var themeFontSize = function (index) { return fromTheme(function (theme) { return theme.fontSizes[index]; }); };
var themeFontFamily = fromTheme(function (theme) { return theme.global.fontFamily; });
var themePalette = function (color, value) {
    if (value === void 0) { value = 500; }
    return (fromTheme(function (theme) { return theme.colors[color][value]; }));
};
var themePurpose = function (purpose) { return fromTheme(function (theme) { return theme.global.purposes[purpose]; }); };
var themePriority = function (priority) { return fromTheme(function (theme) { return theme.global.zPriority[priority]; }); };
var themeGlobal = function (global) { return fromTheme(function (theme) { return theme.global[global]; }); };
var matchThemeProp = function (themeResolver, options) {
    if (options === void 0) { options = {}; }
    return function (props) {
        var _a, _b, _c, _d, _e;
        var themeObject = themeResolver(((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global)
            ? props.theme
            : defaultTheme);
        var match = Object.keys(props)
            .find(function (propKey) { return props[propKey] && themeObject[propKey]; });
        if (!match && options.defaultValue) {
            return (_c = (_b = options === null || options === void 0 ? void 0 : options.modifier) === null || _b === void 0 ? void 0 : _b.call(options, options.defaultValue)) !== null && _c !== void 0 ? _c : options.defaultValue;
        }
        if (!match && themeObject._default) {
            match = themeObject._default;
        }
        if (!match || !themeObject[match]) {
            return null;
        }
        return (_e = (_d = options === null || options === void 0 ? void 0 : options.modifier) === null || _d === void 0 ? void 0 : _d.call(options, themeObject[match])) !== null && _e !== void 0 ? _e : themeObject[match];
    };
};
var matchPurpose = matchThemeProp(function (theme) { return theme.global.purposes; });
var matchButtonHeight = matchThemeProp(function (theme) { return theme.button.heights; });
var matchSizes = matchThemeProp(function (theme) { return theme.global.sizes; });
/**
 * Get a theme color from purpose props in a styled component
 * @param colorValue 100 - 900, color variants from the theme
 * @param opacity 0 - 1
 * @param defaultPurpose Purpose
 * @returns hex or empty string
 */
var matchColorFromPurpose = function (colorValue, opacity, defaultPurpose) {
    if (opacity === void 0) { opacity = 1; }
    return function (props) {
        var _a;
        var themeColors = ((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global)
            ? props.theme.colors
            : defaultTheme.colors;
        var getTrueKeys = compose(keys, pickBy(equals(true)));
        var selectedPurpose = innerJoin(equals, PURPOSES, getTrueKeys(props))[0];
        var colorKey = !selectedPurpose
            ? defaultPurpose || 'primary'
            : selectedPurpose === 'secondary'
                ? 'neutral'
                : selectedPurpose;
        return hexOpacity(themeColors[colorKey][colorValue], opacity);
    };
};
var colorFromPurpose = function (purpose, colorValue, opacity) {
    if (opacity === void 0) { opacity = 1; }
    return function (props) {
        var _a;
        var themeColors = ((_a = props.theme) === null || _a === void 0 ? void 0 : _a.global)
            ? props.theme.colors
            : defaultTheme.colors;
        return hexOpacity(themeColors[purpose][colorValue], opacity);
    };
};
export { themePalette, themeColor, themeSpace, themePurpose, themePriority, themeGlobal, themeFontSize, themeFontFamily, matchThemeProp, matchPurpose, matchButtonHeight, matchSizes, matchColorFromPurpose, colorFromPurpose, fromTheme };
