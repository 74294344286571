import { Button as OrigButton } from './Button';
import { Select, SelectOption } from './Select';
import { Create, Delete, Update, Cancel, Clear, Add, Edit } from './Variations';
import { ButtonGroup } from './Group';
var Button = OrigButton;
Button.Select = Select;
Button.SelectOption = SelectOption;
Button.Group = ButtonGroup;
Button.Add = Add;
Button.Create = Create;
Button.Delete = Delete;
Button.Edit = Edit;
Button.Update = Update;
Button.Cancel = Cancel;
Button.Clear = Clear;
export { Button };
