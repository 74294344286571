var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { globalFont, injectIf, preciseEm } from '../utils';
import { fromTheme, themeColor, themeGlobal, themeSpace } from '../Theme';
import { Multiple } from './Multiple';
var MAX_WIDTH = 50; // em
var Annotation = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  font-size: ", "em;\n  color: ", ";\n"], ["\n  ", "\n  font-size: ", "em;\n  color: ", ";\n"])), globalFont, fromTheme(function (theme) { return theme.global.sizes.small; }), themeColor('gray'));
var StyledField = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  width: 100%;\n  \n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: minmax(40px, max-content) minmax(min-content, 2fr);\n  grid-template-areas:\n    \"a b\"\n    \"a c\";\n\n  ", "\n\n  grid-column-gap: ", "em;\n  >:nth-child(1) {\n    grid-area: a;\n    align-self: center;\n  }\n  \n  >:nth-child(2) {\n    grid-area: b;\n    align-self: end;\n    padding-bottom: 5px;\n  }\n\n  >:nth-child(3) {\n    grid-area: c;\n    align-self: start;\n  }\n"], ["\n  display: grid;\n  width: 100%;\n  \n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: minmax(40px, max-content) minmax(min-content, 2fr);\n  grid-template-areas:\n    \"a b\"\n    \"a c\";\n\n  ", "\n\n  grid-column-gap: ", "em;\n  >:nth-child(1) {\n    grid-area: a;\n    align-self: center;\n  }\n  \n  >:nth-child(2) {\n    grid-area: b;\n    align-self: end;\n    padding-bottom: 5px;\n  }\n\n  >:nth-child(3) {\n    grid-area: c;\n    align-self: start;\n  }\n"])), injectIf('stacked', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n    grid-template-areas:\n      \"a\"\n      \"b\"\n      \"c\";\n  "], ["\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n    grid-template-areas:\n      \"a\"\n      \"b\"\n      \"c\";\n  "])))), themeGlobal('baseSpacing'));
var Container = styled.section(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: ", "em;\n  \n  ", ":not(:last-child) {\n    margin-bottom: ", ";\n  }\n  \n  ", ":last-child {\n    margin-bottom: ", ";\n  }\n  \n  ", ":not(:last-child) {\n    margin-bottom: ", ";\n  }\n  \n  ", ":last-child {\n    margin-bottom: ", ";\n  }\n"], ["\n  max-width: ", "em;\n  \n  ", ":not(:last-child) {\n    margin-bottom: ", ";\n  }\n  \n  ", ":last-child {\n    margin-bottom: ", ";\n  }\n  \n  ", ":not(:last-child) {\n    margin-bottom: ", ";\n  }\n  \n  ", ":last-child {\n    margin-bottom: ", ";\n  }\n"])), preciseEm(MAX_WIDTH), StyledField, themeSpace(3), StyledField, themeSpace(2), Multiple, themeSpace(3), Multiple, themeSpace(2));
Container.displayName = 'FieldContainer';
export { Container, Annotation, StyledField };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
