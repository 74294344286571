import { Insights } from './Insights';
import { Detail } from './Detail';
import { ToolbarDetail } from './ToolbarDetail';
import { MasterDetail } from './MasterDetail';
import { MasterMasterDetail } from './MasterMasterDetail';
export var Layout = {
    Insights: Insights,
    Detail: Detail,
    ToolbarDetail: ToolbarDetail,
    MasterDetail: MasterDetail,
    MasterMasterDetail: MasterMasterDetail
};
