import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { SelectFilter, Tooltip } from '@exivity/ui'
import { Resources, useCacheQuery, queries } from '@exivity/data-layer'

import { reportsActions, reportSelectors } from '../../state'

import { useReportAccounts } from './ParentAccountFilter'

const getAccountName = (account: Resources['account']) => account.attributes.name
const getAccountId = (account: Resources['account']) => account.id

export function AccountFilter () {
  const dispatch = useDispatch()
  const selectedAccountId = useSelector(reportSelectors.getSelectedAccount)
  const selectedAccount = useCacheQuery(queries.find('account', selectedAccountId))
  const selectedReport = useSelector(reportSelectors.getSelectedReportId)
  const accounts = useReportAccounts()

  const disabledTooltip = selectedReport
    ? undefined
    : translate('Please choose a report first')

  return (
    <Tooltip content={disabledTooltip} placement='bottom'>
      <SelectFilter
        searchable
        disabled={!!disabledTooltip}
        placeholder={translate('Account')}
        data={accounts}
        inputValue={selectedAccount?.attributes?.name ?? undefined}
        value={selectedAccountId || undefined}
        labelAccessor={getAccountName}
        valueAccessor={getAccountId}
        clearText={translate('Clear filter')}
        onClear={
          selectedAccountId
            ? () => {
              dispatch(reportsActions.filters.updateAccount(null))
            }
            : undefined
        }
        onChange={(account) => {
          dispatch(reportsActions.filters.updateAccount(account))
        }} />
    </Tooltip>
  )
}
