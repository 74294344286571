import React from 'react'
import { translate } from '@exivity/translations'
import { useCrud } from 'react-crud-hook'
import { MdDelete } from 'react-icons/md'
import { enums, Resources, UserGroupPermission, useOperationListener } from '@exivity/data-layer'
import { NEW_PARAM, useParams } from '@exivity/routing'
import {
  Field,
  Checkbox,
  Select,
  Label,
  Group,
  GroupBox,
  Form,
  Button,
  useModal,
  Input
} from '@exivity/ui'

import { UserModel } from '../../../../data/types'
import { NotificationParams } from '../../pages/MyNotifications'
import { useAllowPermissionsFn } from '../../../../components/organisms/Permission'

import { BudgetEvaluated } from './BudgetEvaluated'
import { ReportPublished } from './ReportPublished'
import { WorkflowEnded } from './WorkflowEnded'
import { Channels } from './Channels'

interface NotificationProps {
  notification: Resources['notificationsubscription']
  user: UserModel
}

function nullable (str: string|null) {
  if (str === '') {
    return null
  }

  return str
}

const NOTIFICATION_TYPES = Object.values(enums.NotificationSubscriptionType)
const CONFIGURATIONS = {
  [enums.NotificationSubscriptionType.BudgetEvaluated]: {
    label: () => translate('Budget evaluated'),
    requiredPermissions: [UserGroupPermission.ViewBudgets, UserGroupPermission.ManageAccounts],
    Component: BudgetEvaluated
  },
  [enums.NotificationSubscriptionType.ReportPublished]: {
    label: () => translate('Report published'),
    requiredPermissions: [UserGroupPermission.ViewReports],
    Component: ReportPublished
  },
  [enums.NotificationSubscriptionType.WorkflowEnded]: {
    label: () => translate('Workflow ended'),
    requiredPermissions: [UserGroupPermission.ManageWorkflows],
    Component: WorkflowEnded
  }
}

export const Notification = ({ notification, user }: NotificationProps) => {
  const [{ notification_id }, setParams] = useParams<NotificationParams>()
  const isAllowed = useAllowPermissionsFn()

  useOperationListener('notificationsubscription', {
    addRecord: () => setParams('notification_id', null),
    removeRecord: () => setParams('notification_id', null)
  })

  const { warning } = useModal()

  const _notification = useCrud<Resources['notificationsubscription']>(notification)

  const selectedConfig = _notification.attributes.type
    ? CONFIGURATIONS[_notification.attributes.type]
    : null

  const isSelectedNotification = notification_id === _notification.id

  return (
    <Form onSubmit={_notification.save}>
      <GroupBox
        collapsed={
          notification_id !== NEW_PARAM
            ? !isSelectedNotification
        : undefined}
        onCollapseChange={(collapsed) => {
          if (collapsed) {
            _notification.reset()
            setParams('notification_id', null)
          } else {
            setParams('notification_id', _notification.id)
          }
        }}>

        {notification_id !== NEW_PARAM
          ? (
            <GroupBox.Header>
              <Checkbox
                disabled={isSelectedNotification}
                checked={notification.attributes.enabled}
                tooltip={
                  !isSelectedNotification
                    ? translate('Enable or disable this notification.')
                    : undefined
                }
                tooltipPlacement='top-start'
                label={
                  // We don't want the checkbox to toggle when the label is clicked!
                  <span style={{ pointerEvents: 'none' }}>
                    <Label.Group>
                      <Label>
                        {notification.attributes.name}
                      </Label>
                      {selectedConfig && (
                        <Label.Sub>
                          {selectedConfig.label()}
                        </Label.Sub>
                      )}
                    </Label.Group>
                  </span>
                }
                onChange={(enabled) => {
                  _notification
                    .setAttribute('enabled', enabled)
                    .save({})
                }}
                // We don't want the box to open/close when the checkbox is clicked!
                onClick={(e) => e.stopPropagation()} />
              <GroupBox.Join>
                <GroupBox.Actions>
                  <Button round transparent icon={<MdDelete />} small onClick={() => {
                    warning({ body: translate('Are you sure you want delete this notification?') })
                      .then(() => _notification.delete())
                  }} />
                </GroupBox.Actions>
                <GroupBox.Collapser />
              </GroupBox.Join>
            </GroupBox.Header>
          )
          : (
            <GroupBox.Header>
              <GroupBox.Title>{translate('Notification')}</GroupBox.Title>
            </GroupBox.Header>
          )}

        <GroupBox.Content>
          <Field.Container>
            <Field>
              <Label>{translate('Active')}</Label>
              <Checkbox
                label={translate('Enabled')}
                checked={_notification.attributes.enabled}
                onChange={_notification.setAttribute('enabled')} />
            </Field>
            {(!selectedConfig || isAllowed(...selectedConfig.requiredPermissions))
            && (
              <>
                <Field>
                  <Label>{translate('Name')}</Label>
                  <Input
                    required
                    placeholder={translate('Name')}
                    value={_notification.attributes.name}
                    onChange={_notification.setAttribute('name')} />
                </Field>

                <Field>
                  <Label.Group>
                    <Label>{translate('Title')}</Label>
                    <Label.Sub>{translate('Optional. The title of the sent message.')}</Label.Sub>
                  </Label.Group>
                  <Input
                    placeholder={translate('Title')}
                    value={_notification.attributes.title || ''}
                    onChange={(val) => _notification.setAttribute('title', nullable(val))} />
                </Field>

                <Field>
                  <Label.Group>
                    <Label>{translate('Description')}</Label>
                    <Label.Sub>{translate('Optional. The description of the sent message.')}</Label.Sub>
                  </Label.Group>
                  <Input
                    placeholder={translate('Description')}
                    value={_notification.attributes.description || ''}
                    onChange={(val) => {
                      _notification.setAttribute('description', nullable(val))
                    }} />
                </Field>

                {notification_id === NEW_PARAM && (
                  <Field>
                    <Label>{translate('Trigger')}</Label>
                    <Select
                      required
                      data={
                        NOTIFICATION_TYPES
                          .filter((type) => (
                            isAllowed(...CONFIGURATIONS[type].requiredPermissions)
                          ))
                      }
                      labelAccessor={type => CONFIGURATIONS[type].label()}
                      value={_notification.attributes.type || undefined}
                      onChange={_notification.setAttribute('type')} />
                  </Field>
                )}

                {selectedConfig && (
                  <Group title={translate('Configuration')}>
                    <selectedConfig.Component notification={_notification as any} />
                  </Group>
                )}
              </>
            )}
          </Field.Container>

          <Group title={translate('Channels')}>
            <Channels
              notification={_notification}
              user={user} />
          </Group>

          <Form.Toolbar>
            <Button.Cancel onClick={() => setParams('notification_id', null)} />
            {notification_id === NEW_PARAM
              ? <Button.Create />
              : <Button.Update />
            }
          </Form.Toolbar>

        </GroupBox.Content>
      </GroupBox>
    </Form>
  )
}
