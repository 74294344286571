import React, { useEffect } from 'react'
import { translate } from '@exivity/translations'
import { Tabs, Layout } from '@exivity/ui'
import { useAPIQuery, queries, UserGroupPermission } from '@exivity/data-layer'
import { useRedirectFrom, getPathname, useParams } from '@exivity/routing'
import { equals, findIndex } from '@exivity/fp'

import { useAllowPermissions } from '../../../components/organisms/Permission'
import { NotificationsProfile, ChannelsProfile } from '../components'
import { app } from '../../../routes'

const SUBSCRIPTIONS_INCLUDE = {
  sources: {
    server: {
      include: ['user', 'channels']
    }
  }
}

const CHANNELS_INCLUDE = {
  sources: {
    server: {
      include: ['user']
    }
  }
}

const BUDGETS_INCLUDE = {
  sources: {
    server: {
      include: ['report']
    }
  }
}

export const NOTIFICATON_ID_PARAM = 'notification_id'
export const NOTIFICATION_PARAMS = ['tab', NOTIFICATON_ID_PARAM] as const

export type NotificationParams = typeof NOTIFICATION_PARAMS[number]

export function usePopulateNotifications () {
  const canManageWorkflows = useAllowPermissions(UserGroupPermission.ManageWorkflows)
  const canViewBudgets = useAllowPermissions(
    UserGroupPermission.ViewBudgets,
    UserGroupPermission.ManageAccounts
  )

  const queryAPI = useAPIQuery()

  useEffect(() => {
    if (canManageWorkflows) {
      queryAPI(queries.findAll('workflow'))
    }

    if (canViewBudgets) {
      queryAPI(queries.findAll('budget'), BUDGETS_INCLUDE)
    }

    queryAPI(queries.findAll('notificationchannel'), CHANNELS_INCLUDE)
    queryAPI(queries.findAll('notificationsubscription'), SUBSCRIPTIONS_INCLUDE)
  }, [canManageWorkflows, canViewBudgets])
}

const TABS = [
  {
    key: 'notifications',
    label: () => translate('Notifications'),
    Component: NotificationsProfile
  },
  {
    key: 'channels',
    label: () => translate('Channels'),
    Component: ChannelsProfile
  }
] as const

const TAB_VALUES = TABS.map(tab => tab.key)

export function MyNotifications () {
  usePopulateNotifications()
  const redirectFrom = useRedirectFrom()
  const [{ entity }, setParams] = useParams()

  useEffect(() => {
    const notifications = getPathname(app.routes.myNotifications)
    redirectFrom(
      notifications,
      notifications + '/' + TAB_VALUES[0]
    )
  })

  return (
    <Layout.Detail>
      <Tabs
        activeIndex={findIndex(equals(entity), TAB_VALUES)}
        onActiveIndexChange={(index) => {
          setParams({ notification_id: null, entity: TAB_VALUES[index] })
        }}>
        <Tabs.TabList>
          {TABS.map((tab, i) => <Tabs.Tab key={i}>{tab.label()}</Tabs.Tab>)}
        </Tabs.TabList>
        <Tabs.TabPanels>
          {TABS.map((tab, i) => <Tabs.TabPanel key={i}><tab.Component /></Tabs.TabPanel>)}
        </Tabs.TabPanels>
      </Tabs>
    </Layout.Detail>
  )
}
