import { loginWithCredentials, loginWithToken, refreshToken } from './login'
import { logout } from './logout'
import { updateCurrentUser } from './updateCurrentUser'
import { resetPassword, requestResetPassword } from './resetPassword'

export const thunks = {
  loginWithCredentials,
  loginWithToken,
  refreshToken,
  logout,
  updateCurrentUser,
  resetPassword,
  requestResetPassword
}
