import React from 'react'
import { Field, Label, Placeholder, Select } from '@exivity/ui'
import { queries, useCacheQuery } from '@exivity/data-layer'
import { translate } from '@exivity/translations'
import { Link, getPathname } from '@exivity/routing'

import { app } from '../../../../../../routes'

import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

function PublishReportHeader ({ options }: HeaderProps) {
  const budget = useCacheQuery(queries.find('report', options.report_id))

  return <>{budget?.attributes.name}</>
}

function PublishReportOptions ({ options, updater }: OptionsProps) {
  const reports = useCacheQuery(
    queries
      .findAll('report')
      .sortByAttribute('name', 'ascending')
  )

  if (!reports.length) {
    return (
      <Placeholder>
        {translate("Unable to configure 'Publish report' step. No reports defined.")}{' '}
        <Link to={getPathname(app.routes.datapipelines.routes.reportDefinitions) + '/new'}>
          {translate('Create a new report.')}
        </Link>
      </Placeholder>
    )
  }

  return (
    <Field>
      <Label>{translate('Report')}</Label>
      <Select
        required
        searchable
        data={reports}
        value={options.report_id}
        valueAccessor={report => report.id}
        labelAccessor={report => report.attributes.name}
        onChange={updater.setProperty('report_id')} />
    </Field>
  )
}

export const PublishReport = {
  Header: PublishReportHeader,
  Options: PublishReportOptions
} as WorkflowStepVariation
