import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

/**
 * @type {React.ComponentType<any>}
 */
const Loader = ({ color = 'black', className }) => (
  <div className={`ex-loader ex-loader--${color} ${className || ''}`}>
    <div className='uk-spinner uk-icon'>
      <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
        <circle fill='none' stroke='#000' cx='15' cy='15' r='14' />
      </svg>
    </div>
  </div>
)

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
}

export default Loader
