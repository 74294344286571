import { schema, queries, useCacheQueryFn, Resources } from '@exivity/data-layer'
import { Record, RecordRelationship } from '@orbit/data'
import produce from 'immer'
import { pick } from 'lodash'
import { compose, isEqual } from 'lodash/fp'

import crudFunctions from '../CrudUtils/crud'

import { listRelatedRecords, mapRelatedRecords } from './utils'

async function cascadeOperations (
  record: Record,
  cb: (record: Record, parent: Record | null) => Promise<Record>,
  initParent: Record | null = null
): Promise<any> {
  const parent = await cb(record, initParent)

  return Promise.all(
    listRelatedRecords(record)
      // Get rid of all identities
      .filter(record => record.attributes || record.relationships)
      .map((record) => cascadeOperations(record, cb, parent))
  )
}

function addParentAsRelationship (parent: Record | null) {
  return produce((record: Record) => {
    if (parent) {
      const inverse = schema.getInverseRelation(parent.type, record.type)
      const inverseDefinition = schema.getRelationship(record.type, inverse)

      // Make sure the relationship exists
      record.relationships = {
        ...(record.relationships || {}),
        [inverse]: { data: record.relationships?.[inverse]?.data }
      } as { [key: string]: RecordRelationship }

      if (inverseDefinition.type === 'hasOne') {
        record.relationships[inverse].data = { id: parent.id, type: parent.type }
      } else {
        /* hasMany case has no usecase yet */
      }
    }
  })
}

/**
  * We omit unsaved related records because they don't have ids.
  * Next to that we make sure the relationships only contain record identities
  */
const cleanupRelationships = (cascadeRelationships: string[]) => {
  return produce((record: Record) => {
    record.relationships = pick(record.relationships || {}, cascadeRelationships)
    record.relationships = mapRelatedRecords(record, (record) => (
      record && record.id
        ? { type: record.type, id: record.id }
        : null
    ))
  })
}

const shouldUpdate = (record: Record) => record.attributes || record.relationships

interface SaveOptions {
  /**
   * When a relationship gets removed from these models, delete the previously related resource.
   * */
  cascadeModels?: {
    [K in keyof Resources]?: Resources[K]['relationships'] extends infer Relationships | undefined
     ? (keyof Relationships)[]
     : never
  }
}

export function useSave (options: SaveOptions = {}) {
  const query = useCacheQueryFn()
  return function saveRecord (record: Record) {
    cascadeOperations(record, async (record, parent) => {
      if (shouldUpdate(record)) {
        const cascadeRelationships = options?.cascadeModels?.[record.type as keyof Resources] || []

        const preparedRecord = compose(
          addParentAsRelationship(parent),
          cleanupRelationships(cascadeRelationships)
        )(record)

        const originalRecord = query(queries.find(record.type as any, record))

        if (!isEqual(originalRecord, record)) {
          return record.id
            ? crudFunctions.updateRecord(preparedRecord as any,
              cascadeRelationships.length
                ? {
                  sources: {
                    server: {
                      settings: {
                        params: {
                          cascade: true
                        }
                      }
                    }
                  }
                }
                : undefined
            )
            : crudFunctions.createRecord(preparedRecord as any)
        }

        return record
      }
    })
  }
}
