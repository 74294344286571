import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider as UIThemeProvider } from '@exivity/ui'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

// We also import fonts in app.js
// They are located there so we can import this file for testing

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <UIThemeProvider brandColor={useSelector(configurationSelectors.getAppColour)}>
      {children}
    </UIThemeProvider>
  )
}
