import React from 'react'
import PropTypes from 'prop-types'

import { classNames } from '../../../utils/misc'

/**
 * @type {React.ComponentType<any>}
 */
const BasicTable = ({
  children,
  className,
  size,
  hover,
  divider,
  responsive,
  alignMiddle,
  noOuterPadding
}) => {
  const classes = classNames({
    'uk-table-small': size === 'small',
    'ex-table--tiny': size === 'tiny',
    'uk-table-hover': hover,
    'uk-table-divider': divider,
    'uk-table-responsive-screen': responsive,
    'uk-table-middle': alignMiddle,
    'ex-table--no-outer-padding': noOuterPadding
  }, 'ex-table', 'ex-table--basic', 'uk-table', className)

  return (
    <table className={classes}>
      {children}
    </table>
  )
}

BasicTable.propTypes = {
  alignMiddle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  divider: PropTypes.bool,
  hover: PropTypes.bool,
  noOuterPadding: PropTypes.bool,
  responsive: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small', 'tiny']).isRequired
}

BasicTable.defaultProps = { size: 'normal' }

export default BasicTable
