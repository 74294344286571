import { createSlice } from '@reduxjs/toolkit'

import { workThunks } from '../../work/thunks'
import { get, patch } from '../../../API/fetch'
import { License } from '../../../API/ResponseSchema'
import { createAsyncThunk } from '../../../store/utils'
import { RootState } from '../../../reducers'

export const fetchLicense = createAsyncThunk('fetchLicense', () => {
  return get('/system/license', undefined, { flashMessage: false })
})

export const updateLicense = createAsyncThunk('updateLicense', (license: string, { dispatch }) => {
  return patch('/system/license', undefined, { license }, { flashMessage: false })
    .then((license) => {
      if (license.status === 'valid') {
        dispatch(workThunks.showSuccessMessage(license.message))
      } else {
        dispatch(workThunks.showErrorMessage(license.message))
      }

      return license
    })
})

const { actions, reducer } = createSlice({
  name: 'administration/license',
  initialState: {
    license: null as null | License
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicense.fulfilled, (state, action) => {
        state.license = action.payload
      })
      .addCase(updateLicense.fulfilled, (state, action) => {
        state.license = action.payload
      })
  }
})

export const licenseSelectors = {
  getLicense: (state: RootState) => state.license.license
}

export const licenseActions = actions

export const licenseThunks = {
  fetchLicense,
  updateLicense
}

export const license = reducer
