import { UpdateTemplatesData } from '../../actions/datasources/templates'

export type TemplateExtension = 'use' | 'trs'
export type TemplateType = 'blob' | 'tree'

interface TemplateAttributes {
  path: string
  mode: string
  type: TemplateType
  sha: string
  size?: number
  url: string
}

export interface Template {
  key: string
  type: TemplateType
  parent: string | null
  attributes: TemplateAttributes
}

export interface TemplatesState {
  use: Template[]
  trs: Template[]
}

const initialState: TemplatesState = {
  use: [],
  trs: []
}

const templates = (state = initialState, action: UpdateTemplatesData): TemplatesState => {
  switch (action.type) {
    case UpdateTemplatesData.type:
      return {
        ...state,
        [action.extension]: action.data
      }

    default:
      return state
  }
}

export default templates
