import {
  Record,
  RecordHasManyRelationship,
  RecordRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

import { EnumLiteralsOf } from '.'

export const USER_GROUP_TYPE = 'usergroup'

export interface UserGroupModel extends Record {
  type: typeof USER_GROUP_TYPE
  attributes: UserGroupAttributes
  relationships?: UserGroupRelationships
}

export const UserGroupPermission = Object.freeze({
  All: '*' as 'ALL',
  ViewAudit: 'view_audit' as 'VIEW_AUDIT',
  ViewBudgets: 'view_budgets' as 'VIEW_BUDGETS',
  ViewCogs: 'view_cogs' as 'VIEW_COGS',
  ViewLogs: 'view_logs' as 'VIEW_LOGS',
  ViewReports: 'view_reports' as 'VIEW_REPORTS',
  ManageAccounts: 'manage_accounts' as 'MANAGE_ACCOUNTS',
  ManageDatasets: 'manage_datasets' as 'MANAGE_DATASETS',
  ManageDataSources: 'manage_data_sources' as 'MANAGE_DATA_SOURCES',
  ManageMetadataDefinitions: 'manage_metadata_definitions' as 'MANAGE_METADATA_DEFINITIONS',
  ManageFiles: 'manage_files' as 'MANAGE_FILES',
  ManageReports: 'manage_reports' as 'MANAGE_REPORTS',
  ManageServices: 'manage_catalogue' as 'MANAGE_CATALOGUE',
  ManageSettings: 'manage_settings' as 'MANAGE_SETTINGS',
  ManageUsers: 'manage_users' as 'MANAGE_USERS',
  ManageWorkflows: 'manage_workflows' as 'MANAGE_WORKFLOWS'
})

export type UserGroupPermission = Readonly<EnumLiteralsOf<typeof UserGroupPermission>>

export const USE_DEFAULT_SAML_ATTRIBUTE = null

export interface UserGroupSamlProvisioning {
  attribute: string | typeof USE_DEFAULT_SAML_ATTRIBUTE
  value: string
}

export interface UserGroupAttributes {
  name: string
  permissions: UserGroupPermission[]
  saml_provisioning: UserGroupSamlProvisioning | null
}

export interface UserGroupRelationships extends Dict<RecordRelationship> {
  users: RecordHasManyRelationship
}

export const DEFAULT_USER_GROUP: UserGroupModel = {
  id: '',
  type: USER_GROUP_TYPE,
  attributes: {
    name: '',
    permissions: [],
    saml_provisioning: null
  }
}

export const createUserGroup = makeCreateModel<UserGroupModel>(
  USER_GROUP_TYPE,
  DEFAULT_USER_GROUP.attributes
)
