import React from 'react'
import PropTypes from 'prop-types'

import Overlay from '../../atoms/Overlay'
import Loader from '../Loader'

import './index.css'
/**
 * @type {React.ComponentType<any>}
 */
const LoadingOverlay = ({ position, children }) => (
  <Overlay className='ex-loading-overlay' position={position}>
    <div className='ex-loading-overlay__childcontainer'>
      <Loader />
      {children}
    </div>
  </Overlay>
)

LoadingOverlay.propTypes = {
  children: PropTypes.object,
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['center', 'viewport'])
  ])
}

LoadingOverlay.defaultProps = { position: 'center' }

export default LoadingOverlay
