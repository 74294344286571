import { lazy } from 'react'
import { translate } from '@exivity/translations'

const Forbidden = lazy(() => import(
  /* webpackChunkName: 'forbidden' */ '../components/pages/Forbidden/index'))

export const forbidden = {
  path: '/forbidden',
  meta: {
    title: () => translate('Forbidden'),
    excludeFromSearchIndex: true
  },
  component: Forbidden
}
