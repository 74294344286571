import {
  Record as JSONAPIRecord,
  RecordRelationship,
  RecordHasManyRelationship,
  RecordHasOneRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

export interface ReportModel extends JSONAPIRecord {
  type: 'report'
  attributes: ReportAttributes
  relationships?: ReportRelationships
}

export interface ReportAttributes {
  name: string
  dset: string
  created: Date
  last_updated: Date
  lvl1_key_col: string
  lvl1_name_col: string
  lvl1_label: string
  lvl1_metadata_definition_id: number | null
  lvl2_key_col: string | null
  lvl2_name_col: string | null
  lvl2_label: string | null
  lvl2_metadata_definition_id: number | null
  lvl3_key_col: string | null
  lvl3_name_col: string | null
  lvl3_label: string | null
  lvl3_metadata_definition_id: number | null
  lvl4_key_col: string | null
  lvl4_name_col: string | null
  lvl4_label: string | null
  lvl4_metadata_definition_id: number | null
  lvl5_key_col: string | null
  lvl5_name_col: string | null
  lvl5_label: string | null
  lvl5_metadata_definition_id: number | null
  depth: number
  data_status: Record<string, unknown>
  schedule: Record<string, unknown> | boolean | null
}

export interface ReportRelationships extends Dict<RecordRelationship> {
  accounts: RecordHasManyRelationship
  dset: RecordHasOneRelationship
  budgets: RecordHasManyRelationship
}

export const DEFAULT_REPORT = {
  id: '',
  type: 'report',
  attributes: {
    name: '',
    dset: '',
    lvl1_key_col: '',
    lvl1_name_col: '',
    lvl1_label: '',
    lvl1_metadata_definition_id: null,
    lvl2_key_col: null,
    lvl2_name_col: null,
    lvl2_label: null,
    lvl2_metadata_definition_id: null,
    lvl3_key_col: null,
    lvl3_name_col: null,
    lvl3_label: null,
    lvl3_metadata_definition_id: null,
    lvl4_key_col: null,
    lvl4_name_col: null,
    lvl4_label: null,
    lvl4_metadata_definition_id: null,
    lvl5_key_col: null,
    lvl5_name_col: null,
    lvl5_label: null,
    lvl5_metadata_definition_id: null
  }
} as ReportModel

export const createReport = makeCreateModel<ReportModel>('report', DEFAULT_REPORT.attributes)
