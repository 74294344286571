import { useEffect } from 'react'
import { translate } from '@exivity/translations'
import { useModal } from '@exivity/ui'

import { useServiceWorkers } from './useServiceWorker'

export function useLoadNewVersion () {
  const { updateAvailable, refuseUpdate } = useServiceWorkers()
  const { confirm } = useModal()

  useEffect(() => {
    if (updateAvailable) {
      confirm({
        title: translate('New version'),
        body: translate('A new version of the app is available, reload now to start using it?')
      })
        .then(() => window.location.reload())
        .catch(() => refuseUpdate())
    }
  })
}
