import { useEffect } from 'react'

import store from '../../data/store'

const PAGE = { offset: 0, limit: -1 }

export function fetchRecords (recordType: string, opts?: Record<string, unknown>) {
  store.query(q => q.findRecords(recordType)
    .page(PAGE), opts)
}

export function useFetchAllRecords (recordType: string, opts?: Record<string, unknown>) {
  useEffect(() => {
    fetchRecords(recordType, opts)
  }, [recordType, opts])
}
