import React from 'react'
import { useCrud } from 'react-crud-hook'
import { Heading, Tabs, Placeholder } from '@exivity/ui'
import { compose, findIndex, prop, equals } from '@exivity/fp'
import { translate } from '@exivity/translations'
import { useParams } from '@exivity/routing'

import { UserModel, createUser, UserSource } from '../../../../data/types'
import {
  NotificationsUserManagement,
  ChannelsUserManagement
} from '../../../myNotifications/components'

import { Profile } from './Profile'

interface UserProps {
  user?: UserModel
  currentUser?: UserModel
}

const defaultUser = createUser()

const TABS = [
  {
    key: 'profile',
    label: () => translate('Profile'),
    Component: Profile
  },
  {
    key: 'notifications',
    label: () => translate('Notifications'),
    Component: ((props) => {
      return !props.user.id
        ? <Placeholder>{translate('Notifications become available after saving the profile.')}</Placeholder>
        : <NotificationsUserManagement {...props} />
    }) as typeof NotificationsUserManagement
  },
  {
    key: 'channels',
    label: () => translate('Channels'),
    Component: ((props) => {
      return !props.user.id
        ? <Placeholder>{translate('Channels become available after saving the profile.')}</Placeholder>
        : <ChannelsUserManagement {...props} />
    }) as typeof ChannelsUserManagement
  }
] as const

const getIndex = (key: string) => findIndex(
  compose(
    equals(key),
    prop('key')
  ),
  TABS
)

export function User ({ user, currentUser }: UserProps) {
  const _user = useCrud<UserModel>(user || defaultUser)
  const [{ entity }, setParams] = useParams()

  return (
    <>
      <Heading.Input
        required
        disabled={_user.attributes.source !== UserSource.LOCAL}
        label={translate('Username')}
        autoFocus={!!_user.id}
        placeholder={translate('Username')}
        value={_user.attributes.username}
        onChange={_user.setAttribute('username')} />
      <Tabs activeIndex={getIndex(entity)}
        onActiveIndexChange={(index) => {
          setParams({ notification_id: null, entity: TABS[index].key })
        }}>
        <Tabs.TabList>
          {TABS.map((tab) => <Tabs.Tab key={tab.key}>{tab.label()}</Tabs.Tab>)}
        </Tabs.TabList>
        <Tabs.TabPanels>
          {TABS.map((tab) => (
            <Tabs.TabPanel key={tab.key}>
              <tab.Component user={_user} currentUser={currentUser} />
            </Tabs.TabPanel>
          ))}
        </Tabs.TabPanels>
      </Tabs>
    </>
  )
}
