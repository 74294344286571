import React, { useMemo } from 'react';
import { useLocation } from '../useLocation';
import { useParams } from '../useParams';
import { useHistory } from '../useHistory';
export function withRouter(Component) {
    return function RouterWrapper(ownProps) {
        const location = useLocation();
        const [params] = useParams();
        const history = useHistory();
        const props = useMemo(() => ({
            router: {
                push: history.push,
                replace: history.replace,
                location,
                isActive(path, exact) {
                    return location.pathname === path && exact;
                }
            },
            params
        }), [location, params, history]);
        return (React.createElement(Component, Object.assign({}, props, ownProps)));
    };
}
