var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from 'styled-components';
import { Block } from '../Block';
import { themePriority } from '../Theme';
export var Overlay = styled(Block)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: ", ";\n  ", "\n"], ["\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: ", ";\n  ", "\n"])), function (_a) {
    var centerContent = _a.centerContent;
    return centerContent ? 'center' : 'stretch';
}, function (_a) {
    var inLocalArea = _a.inLocalArea, rest = __rest(_a, ["inLocalArea"]);
    return inLocalArea
        ? "\n      position: absolute;\n      bottom: 0;\n      right: 0;\n      background-color: hsla(0, 0%, 100%, .8);\n      z-index: " + themePriority(2)(rest) + "\n    "
        : "\n      position: fixed;\n      width: 100vw;\n      height: 100vh;\n      background-color: rgba(0, 0, 0, 0.1);\n      z-index: " + themePriority(5)(rest) + "\n    ";
});
Overlay.LocalArea = styled(Block)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var templateObject_1, templateObject_2;
