import { ChangeComponent, ChangeFilter, ChangeLogfile } from '../actions/logs'
import { LogComponentKeys } from '../components/pages/Administration/componentKeys'

export interface LogsState {
  selectedComponent: LogComponentKeys | null
  selectedFilter: number | null
  selectedLogfile: number | null
}

const initialState: LogsState = {
  selectedComponent: null,
  selectedFilter: null,
  selectedLogfile: null
}

type LogsAction = ChangeComponent | ChangeFilter | ChangeLogfile

const logs = (state = initialState, action: LogsAction): LogsState => {
  switch (action.type) {
    case ChangeComponent.CHANGE_COMPONENT:
      return {
        ...state,
        selectedComponent: action.component,
        selectedFilter: null,
        selectedLogfile: null
      }
    case ChangeFilter.CHANGE_FILTER:
      return { ...state, selectedFilter: action.filter }

    case ChangeLogfile.CHANGE_LOGFILE:
      return { ...state, selectedLogfile: action.logfile }

    default:
      return state
  }
}

export default logs
