import { UpdateExtractorsData, SetRunStatus } from '../../actions/datasources/extractors'
import { Schedule } from '../../components/pages/DataPipelines/scheduleTypes'

type ExtractorVariableType = 'normal' | 'encrypted'

interface ExtractorVariable {
  name: string
  value: string
  type: ExtractorVariableType
  line: number
  comment: string
}

export interface Extractor {
  name: string
  contents: string
  variables: ExtractorVariable[]
  hash: string
  last_modified: string
  schedule: false | Schedule
}

export interface RunStatus {
  status: string
  start_time: string
  end_time: string
}

export interface ExtractorsState {
  data: Extractor[]
  runStatus: null | string | false | RunStatus
}

const initialState: ExtractorsState = {
  data: [],
  runStatus: null
}

type ExtractorsAction = UpdateExtractorsData | SetRunStatus

const extractors = (state = initialState, action: ExtractorsAction): ExtractorsState => {
  switch (action.type) {
    case UpdateExtractorsData.type:
      return { ...state, data: action.data }

    case SetRunStatus.type:
      return { ...state, runStatus: action.data }

    default:
      return state
  }
}

export default extractors
