import {
  setPreviewError,
  updateTransformersPreview
} from '../../actions/datasources/transformers'
import { HookContext, HookType } from '../fetchWithHooks/fetchWithHooks'
import store from '../../store'

export function handleTranscriptError (ctx: HookContext<HookType.Error>) {
  const { response, error, path } = ctx

  if (path.startsWith('transformers') && typeof response !== 'undefined') {
    response.clone().text().then((text: string) => {
      const json = JSON.parse(text)
      const stack = json.meta && json.meta.stack

      if (stack && stack.length) {
        store.dispatch(
          setPreviewError({ message: error.message, line: stack[stack.length - 1].line })
        )
      }
      store.dispatch(updateTransformersPreview([], [], {}))
    })
  }
}
