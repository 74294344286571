import times from 'lodash/times'

export const getMetadataDefinitionIds = (report: any) => {
  if (!report) {
    return []
  }
  return times(5, (index: number) => report.attributes[`lvl${index + 1}_metadata_definition_id`])
}

export const inheritsFromId = (depth: number, arr: (number|string)[]): any => {
  if (arr[depth - 1] === -1) return inheritsFromId(depth - 1, arr)

  return arr[depth - 1]
}
