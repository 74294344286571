import {
  Record,
  RecordRelationship,
  RecordHasOneRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'
import { format } from 'date-fns'
import { ServiceSubscriptionAltInterval } from '@exivity/data-layer'

import { makeCreateModel } from '../../utils/model'

export const SUBSCRIPTION_TYPE = 'servicesubscription'

export interface SubscriptionModel extends Record {
  type: typeof SUBSCRIPTION_TYPE
  attributes: SubscriptionAttributes
  relationships?: SubscriptionRelationships
}

export enum SubscriptionType {
  Recurring = 'recurring',
  OneOff = 'one_off'
}

export const CHARGE_DAY_RANGE = {
  min: 1,
  max: 28
}

export interface SubscriptionAttributes {
  name: string
  quantity: number
  rate: number
  cogs: number
  start_date: string
  end_date: string | null
  charge_day: number | null
  alt_interval: ServiceSubscriptionAltInterval | null
  type: SubscriptionType
  instance: string
}

export interface SubscriptionRelationships extends Dict<RecordRelationship> {
  account: RecordHasOneRelationship
  service: RecordHasOneRelationship
}

export const DEFAULT_SUBSCRIPTION: SubscriptionModel = {
  id: '',
  type: SUBSCRIPTION_TYPE,
  attributes: {
    name: '',
    quantity: 0,
    rate: 0,
    cogs: 0,
    start_date: '',
    end_date: null,
    charge_day: null,
    alt_interval: null,
    type: SubscriptionType.Recurring,
    instance: ''
  }
}

export const createSubscription = makeCreateModel<SubscriptionModel>(
  SUBSCRIPTION_TYPE,
  () => ({
    ...DEFAULT_SUBSCRIPTION.attributes,
    start_date: format(new Date(), 'yyyy-MM-dd')
  })
)
