var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { themeColor, themeFontFamily, themeFontSize, themeSpace } from '../Theme';
import { StyledButton } from '../Button/Button';
export var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: ", ";\n  color: ", ";\n  position: absolute;\n  background-color: ", ";\n  width: 600px;\n  left: calc((100vw - 600px) / 2);\n  top: 50px;\n"], ["\n  font-family: ", ";\n  font-size: ", ";\n  color: ", ";\n  position: absolute;\n  background-color: ", ";\n  width: 600px;\n  left: calc((100vw - 600px) / 2);\n  top: 50px;\n"])), themeFontFamily, themeFontSize(2), themeColor('darkGray'), themeColor('white'));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", " ", ";\n"], ["\n  padding: ", " ", ";\n"])), themeSpace(2), themeSpace(3));
export var Body = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 30px ", ";\n  max-height: 400px;\n  border-top: solid 1px ", ";\n  border-bottom: solid 1px ", ";\n  overflow-y: auto;\n"], ["\n  padding: 30px ", ";\n  max-height: 400px;\n  border-top: solid 1px ", ";\n  border-bottom: solid 1px ", ";\n  overflow-y: auto;\n"])), themeSpace(3), themeColor('lightGray'), themeColor('lightGray'));
export var Footer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding: ", " ", ";\n  ", " {\n    margin-left: 20px;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding: ", " ", ";\n  ", " {\n    margin-left: 20px;\n  }\n"])), themeSpace(2), themeSpace(3), StyledButton);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
