var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { complement, compose, isEmpty, equals, identity, always, juxt } from '@exivity/fp';
import { useToggle } from '@exivity/hooks';
import { SelectInput } from '../Input';
import { useFieldContext } from '../Field/context';
import { findActiveNode } from '../SelectList/utils';
import { renderSelectList, isMultiple } from '../SelectList';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
export var SelectDropdown = styled(Dropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  \n  .dropdown__drop {\n    width: 100%;\n    padding: ", " 0;\n    \n    .select__searchbar {\n      margin-top: 0;\n    }\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  \n  .dropdown__drop {\n    width: 100%;\n    padding: ", " 0;\n    \n    .select__searchbar {\n      margin-top: 0;\n    }\n  }\n"])), themeSpace(1));
var isNotEmpty = complement(isEmpty);
var alwaysArray = always([]);
export function Select(_a) {
    var name = _a.name, value = _a.value, className = _a.className, _b = _a.data, data = _b === void 0 ? [] : _b, onChange = _a.onChange, required = _a.required, disabled = _a.disabled, _c = _a.multiple, multiple = _c === void 0 ? false : _c, _d = _a.searchable, searchable = _d === void 0 ? false : _d, _e = _a.placeholder, placeholder = _e === void 0 ? translate('Choose an option') : _e, _f = _a.labelAccessor, labelAccessor = _f === void 0 ? identity : _f, _g = _a.valueAccessor, valueAccessor = _g === void 0 ? identity : _g, renderIcon = _a.renderIcon, getChildNodes = _a.getChildNodes, _h = _a.normalize, normalize = _h === void 0 ? identity : _h, onClear = _a.onClear, clearText = _a.clearText, warning = _a.warning;
    var _j = useToggle(false), isOpen = _j[0], toggleOpen = _j[1];
    var id = useFieldContext().id;
    var activeItem = value !== undefined
        ? findActiveNode(getChildNodes || alwaysArray, compose(equals(value), valueAccessor), data)
        : undefined;
    return (_jsx(SelectDropdown, __assign({ className: className, disabled: disabled, reference: _jsx(SelectInput, { id: id, required: required, name: name, warning: warning, value: multiple && isMultiple(value) && isNotEmpty(value)
                ? translate('{count} selected', value.length)
                : activeItem
                    ? labelAccessor(activeItem)
                    : '', placeholder: placeholder, disabled: disabled }, void 0), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: renderSelectList({
            multiple: multiple,
            searchable: searchable,
            value: value,
            data: data,
            valueAccessor: valueAccessor,
            labelAccessor: labelAccessor,
            renderIcon: renderIcon,
            getChildNodes: getChildNodes,
            normalize: normalize,
            clearText: clearText,
            onChange: function (value) {
                onChange && onChange(value);
                !multiple && toggleOpen();
            },
            onClear: onClear
                ? juxt([onClear, toggleOpen])
                : undefined
        }) }), void 0));
}
var templateObject_1;
