import { useState, useCallback } from 'react'
import { CrudRecord } from 'react-crud-hook'

import { MetadataModel } from '../../../../data/types'

export function useMetadata () {
  const [record, setRecord] = useState<CrudRecord<MetadataModel>|null>(null)
  const onRecord = useCallback((record: CrudRecord<MetadataModel>) => {
    setRecord(record)
  }, [setRecord])

  return [record, onRecord] as const
}
