var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { PARENT } from './data';
import { getChildren } from './traverse';
import { listToTreeMap } from './map';
export function treeMapToTreeList(map) {
    return Object.values(map).reduce(function (list, item) {
        return item[PARENT]
            ? list
            : list.concat(__spreadArray([item], getChildren(item)));
    }, []);
}
export function listToTreeList(list, keyAccessor, parentKeyAccessor) {
    var treeMap = listToTreeMap(list, keyAccessor, parentKeyAccessor);
    return treeMapToTreeList(treeMap);
}
