var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { themeSpace } from '../Theme';
var Left = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: calc(", " / 2);\n\n  > *:not(:last-child) {\n    margin-right: ", ";\n  }\n"], ["\n  margin-right: calc(", " / 2);\n\n  > *:not(:last-child) {\n    margin-right: ", ";\n  }\n"])), themeSpace(1), themeSpace(1));
var Right = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: calc(", " / 2);\n\n  > *:not(:first-child) {\n    margin-left: ", ";\n  }\n"], ["\n  margin-left: calc(", " / 2);\n\n  > *:not(:first-child) {\n    margin-left: ", ";\n  }\n"])), themeSpace(1), themeSpace(1));
var _Toolbar = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: ", ";\n\n  display: flex;\n  direction: row;\n  justify-content: space-between;\n\n  ", ", ", " {\n    display: flex;\n    direction: row;\n\n    align-items: center;\n  }\n"], ["\n  margin-bottom: ", ";\n\n  display: flex;\n  direction: row;\n  justify-content: space-between;\n\n  ", ", ", " {\n    display: flex;\n    direction: row;\n\n    align-items: center;\n  }\n"])), themeSpace(1), Left, Right);
export var Toolbar = _Toolbar;
Toolbar.Left = Left;
Toolbar.Right = Right;
var templateObject_1, templateObject_2, templateObject_3;
