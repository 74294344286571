import { Record, RecordRelationship, RecordHasOneRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

export interface VariableAttributes {
  name: string
  value: string
  encrypted: boolean
}

export interface VariableRelationships extends Dict<RecordRelationship> {
  environment: RecordHasOneRelationship
}

const VARIABLE = 'variable'

export interface VariableModel extends Record {
  type: typeof VARIABLE
  attributes: VariableAttributes
  relationships: VariableRelationships
}

export const DEFAULT_VARIABLE: VariableModel = {
  id: '',
  type: VARIABLE,
  attributes: {
    name: '',
    value: '',
    encrypted: false
  },
  relationships: {
    environment: {
      data: null
    }
  }
}
