import React from 'react'
import { useCrud } from 'react-crud-hook'

import {
  DEFAULT_METADATA_DEFINITION,
  MetadataDefinitionModel
} from '../../../../../data/types/metadatadefinition'

import { MetadataDetails } from './MetadataDetails'

export const MetadataView = ({
  definition = DEFAULT_METADATA_DEFINITION,
  metadata = [],
  reports = [],
  datasets = []
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
  const definitionRecord = useCrud<MetadataDefinitionModel>(definition)

  return (
    <MetadataDetails
      definition={definitionRecord}
      entries={metadata.length}
      reports={reports}
      datasets={datasets} />
  )
}
