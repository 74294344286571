import ClassAction, { createClassAction } from '../store/middleware/ClassAction'

export class EnableSearch extends ClassAction {
  static readonly type = 'ENABLE_SEARCH'
  readonly type = EnableSearch.type
}

export class DisableSearch extends ClassAction {
  static readonly type = 'DISABLE_SEARCH'
  readonly type = DisableSearch.type
}

export const enableSearch = createClassAction(EnableSearch)
export const disableSearch = createClassAction(DisableSearch)
