import React from 'react'
import { Layout, Button, Placeholder } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { NEW_PARAM, useParams } from '@exivity/routing'

import AdvancedSelect, {
  Column as AdvancedSelectColumn
} from '../../../../components/molecules/AdvancedSelect'
import { listUsergroups } from '../../../../selectors/usergroups'
import { UserGroupModel } from '../../../../data/types'
import { UserGroup } from '../../components'

interface UserGroupsProps {
  userGroups?: UserGroupModel[]
}

export function UserGroups ({ userGroups = [] }: UserGroupsProps) {
  const [{ id }, setParams] = useParams()

  return (
    <Layout.MasterDetail>
      <AdvancedSelect
        data={listUsergroups(userGroups)}
        expandHeight
        toolbar={
          <Button.Create small onClick={() => setParams('id', NEW_PARAM)}>
            {translate('Create group')}
          </Button.Create>
        }
        sortBy='name'
        selected={id}
        highlightSearches
        onClick={({ id }: any) => setParams('id', id)}>
        <AdvancedSelectColumn
          field='name'
          label={translate('Name')}
          width={300}
          searchable />
      </AdvancedSelect>
      {id
        ? <UserGroup id={id !== NEW_PARAM ? id : undefined} />
        : <Placeholder>{translate('Select a usergroup')}</Placeholder>}
    </Layout.MasterDetail>
  )
}
