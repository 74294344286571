import { createSelector } from 'reselect'

import { SubscriptionModel } from '../../../../data/types'

export const listSubscriptions = createSelector(
  (subscriptions: SubscriptionModel[]) => subscriptions,
  (subscriptions) => subscriptions.map((subscription) => ({
    ...subscription,
    key: subscription.id
  }))
)
