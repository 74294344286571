import { useState } from 'react'

const isSecure = (domain: string): boolean => domain.indexOf('https://') === 0

interface ChildrenProps {
  isSecureDomain: boolean
  handleDomainChange: (v: string) => void
  handleDomainFocus: () => void
  handleDomainBlur: (v: string) => void
}

export interface ContainerProps {
  apiRoot?: string
  showDomain?: boolean
  onDomainChanged?: (apiRoot: string) => void
  children?: (props: ChildrenProps) => JSX.Element
}

export function Container ({
  apiRoot = '',
  onDomainChanged,
  showDomain = false,
  children
}: ContainerProps) {
  const [domain, setDomain] = useState({ secure: isSecure(apiRoot), changed: false })

  if (!showDomain || !children) {
    return null
  }

  return children({
    isSecureDomain: domain.secure,

    handleDomainChange: (value: string) => {
      setDomain({ secure: isSecure(value), changed: true })
    },

    handleDomainFocus: () => setDomain({ ...domain, changed: false }),

    handleDomainBlur: (value: string) => {
      if (domain.changed) {
        onDomainChanged && onDomainChanged(value)
      }
    }
  })
}
