import { lazy } from 'react'
import { translate } from '@exivity/translations'

const NotFound = lazy(() => import(
  /* webpackChunkName: 'notfound' */ '../components/pages/NotFound/index'))

export const notfound = {
  path: '/(.*)',
  meta: {
    title: () => translate('Not found'),
    excludeFromSearchIndex: true
  },
  component: NotFound,
  requiresAuth: false
}
