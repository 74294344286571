import { useSelector } from 'react-redux'
import { Resources } from '@exivity/data-layer'

import { authSelectors } from '../../../domains/auth/state'

import { hasPermission } from './helpers'

type UserGroupPermission = Resources['usergroup']['attributes']['permissions']

export function useAllowPermissions (...permissions: UserGroupPermission) {
  const userPermissions = useSelector(authSelectors.getUserPermissions)
  return permissions.length > 0
    ? hasPermission(userPermissions, { allow: permissions })
    : true
}

export function useAllowPermissionsFn () {
  const userPermissions = useSelector(authSelectors.getUserPermissions)

  return (...permissions: UserGroupPermission) => permissions.length > 0
    ? hasPermission(userPermissions, { allow: permissions })
    : true
}
