import React from 'react'
import { translate } from '@exivity/translations'
import { Field, Label, Input, Checkbox } from '@exivity/ui'

import { USER_EMAIL } from '../../../../data/types'

import { ChannelConfigurationProps } from './'

export function MailConfiguration ({ channel }: ChannelConfigurationProps) {
  return (
    <>
      <Field>
        <Label.Group>
          <Label>
            {translate('Use personal e-mail')}
          </Label>
          <Label.Sub>
            {translate('Link your profile e-mail with this channel.')}
          </Label.Sub>
        </Label.Group>
        <Checkbox
          label={translate('Enabled')}
          checked={channel.attributes.info?.recipient === USER_EMAIL}
          onChange={(usePersonal) => {
            channel.setAttribute('info', {
              recipient: usePersonal
                ? USER_EMAIL
                : ''
            })
          }} />
      </Field>

      {channel.attributes.type && channel.attributes.info?.recipient !== USER_EMAIL && (
        <>
          <Field>
            <Label.Group>
              <Label>
                {translate('E-mail address')}
              </Label>
              <Label.Sub>
                {translate('An email can be sent to this email address.')}
              </Label.Sub>
            </Label.Group>
            <Input
              placeholder={translate('E-mail address')}
              required
              value={channel.attributes.info?.recipient || ''}
              onChange={(recipient) => channel.setAttribute('info', { recipient })} />
          </Field>
        </>
      )}
    </>
  )
}
