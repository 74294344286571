import { pipe, forEach } from '@exivity/fp';
import { useStoreListener } from './useStoreListener';
import { transformSelectors } from './transformSelectors';
export function useOperationListener(resourceType, { addRecord, removeRecord }) {
    useStoreListener('transform', pipe(transformSelectors.getOperationsProp, transformSelectors.filterResources(resourceType), forEach((operation) => {
        if (operation.record.type !== resourceType)
            return;
        if (operation.op === 'addRecord') {
            addRecord && addRecord(operation.record);
        }
        if (operation.op === 'removeRecord') {
            removeRecord && removeRecord(operation.record);
        }
    })), [resourceType, addRecord, removeRecord]);
}
