import React from 'react'
import { translate } from '@exivity/translations'

import AdvancedSelect, {
  Column as AdvancedSelectColumn
} from '../../../../components/molecules/AdvancedSelect'
import intervalLabels from '../intervalLabels'
import Icon from '../../../../components/atoms/Icon'
import { ServiceInterval } from '../../../../data/types'
import { useRenderKey } from '../../../../components/hooks/useRenderKey'
import { useKeyColumnSelector } from '../KeyColumnSelector'

import Toolbar from './Toolbar'
import { listServices, ServiceWithCategory } from './listServices'
import { useBulkDelete } from './useBulkDelete'
import { useUpdateCategoryName } from './useUpdateCategoryName'

import { ServiceSelectListProps } from './'

function renderInterval ({ cellData }: { cellData: ServiceInterval }) {
  const item = intervalLabels.find(item => item.value === cellData)
  return item ? item.value : '(unknown)'
}

function renderOrphanWarning ({ rowData }: { rowData: ServiceWithCategory }) {
  if (rowData.attributes.orphan) {
    return (
      <span data-uk-tooltip title='Orphaned service'>
        <Icon className='uk-text-danger'>warning</Icon>
      </span>
    )
  }
}

export function ServiceSelectList ({
  header,
  services = [],
  serviceCategories = [],
  useEditToggle,
  params,
  router
}: ServiceSelectListProps) {
  const { selectOption, selectedOption, options, isKey } = useKeyColumnSelector('Description')
  const [editing, toggleEdit] = useEditToggle
  const list = listServices({ services, serviceCategories })
  const renderKey = useRenderKey(list)

  const updateCategoryName = useUpdateCategoryName()

  const {
    selected,
    setSelected,
    ...bulkDeletion
  } = useBulkDelete(list, router, params)

  return (
    <AdvancedSelect
      key={renderKey}
      sortBy='description'
      data={list}
      checked={selected}
      selected={params.id}
      header={editing ? header : null}
      toolbar={
        <Toolbar
          columnSelectData={options}
          columnSelected={selectedOption}
          editing={editing}
          toggleEdit={toggleEdit}
          selected={selected}
          onChangeColumnSelection={selectOption}
          {...bulkDeletion} />
      }
      groupBy='serviceCategoryName'
      expandHeight
      width={420}
      highlightSearches
      autoCheckChildren
      checkableGroups={editing}
      editGroupHeaders={editing}
      onChecked={setSelected}
      onClick={({ key }: { key: string }) => router.push(`services/overview/${key}`)}
      onSaveGroupHeader={updateCategoryName}>
      {isKey
        ? (
          <AdvancedSelectColumn field='key'
            label={translate('Key')}
            searchable
            width={290} />
        )
        : (
          <AdvancedSelectColumn field='description'
            label={translate('Description')}
            width={290}
            flexGrow={1}
            searchable
            checkable={editing}
            autoCheckChildren />
        )
      }
      <AdvancedSelectColumn field='interval'
        label={translate('Interval')}
        width={100}
        cellRenderer={renderInterval} />
      <AdvancedSelectColumn field='orphan'
        label=''
        width={30}
        cellRenderer={renderOrphanWarning} />
    </AdvancedSelect>
  )
}
