import { Schema } from '@orbit/data';
import pluralize from 'pluralize';
import pick from 'lodash/pick';
const parseToType = {
    object: (value) => value ? JSON.parse(value) : null,
    json: (value) => value ? JSON.parse(value) : null,
    array: (value) => {
        return value
            ? JSON.parse(value)
            : [];
    },
    float: (value) => parseFloat(value),
    integer: (value) => {
        if (value === '')
            return null;
        return Number(value);
    }
};
// Create our own Schema class
export class ExivitySchema extends Schema {
    constructor(schema) {
        super(schema);
        // Transform attributes with respect to their type declaration
        const needsTransformation = {};
        Object.keys(this.models).forEach((type) => {
            var _a;
            needsTransformation[type] = {
                skip: true,
                attributes: {}
            };
            Object.keys(((_a = this.models[type]) === null || _a === void 0 ? void 0 : _a.attributes) || {}).forEach((attribute) => {
                var _a, _b, _c;
                const attributeType = (_c = (_b = (_a = this.models[type]) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b[attribute]) === null || _c === void 0 ? void 0 : _c.type;
                if (attributeType === 'object') {
                    needsTransformation[type].skip = false;
                    needsTransformation[type].attributes[attribute] = 'object';
                }
                if (attributeType === 'array') {
                    needsTransformation[type].skip = false;
                    needsTransformation[type].attributes[attribute] = 'array';
                }
                if (attributeType === 'integer') {
                    needsTransformation[type].skip = false;
                    needsTransformation[type].attributes[attribute] = 'integer';
                }
                if (attributeType === 'float') {
                    needsTransformation[type].skip = false;
                    needsTransformation[type].attributes[attribute] = 'float';
                }
            }, false);
        });
        this.needsTransformation = needsTransformation;
    }
    pluralize(word) {
        return pluralize.plural(word);
    }
    singularize(word) {
        return pluralize.singular(word);
    }
    initializeRecord() {
        // Do not generate local ids
    }
    removeReadOnlyAttributes(type, attributes) {
        // @ts-ignore
        const model = this.models[type];
        const allowedAttributes = Object
            .keys(model.attributes)
            .filter(key => !model.attributes[key].readOnly);
        return pick(attributes, allowedAttributes);
    }
    parseAttributeValue(type, attributes) {
        if (this.needsTransformation[type].skip === false) {
            Object.keys(this.needsTransformation[type].attributes).forEach((attribute) => {
                attributes[attribute]
                    = parseToType[this.needsTransformation[type].attributes[attribute]](attributes[attribute]);
            });
        }
        // used parsing dump which includes relation keys
        return pick(attributes, Object.keys(this.models[type].attributes));
    }
    getInverseRelation(type, inverseType) {
        const model = this.models[type];
        if (!model.relationships)
            throw new Error("Inverse relation doesn't exist");
        const relation = Object.keys(model.relationships)
            .find((relation) => { var _a; return ((_a = model.relationships) === null || _a === void 0 ? void 0 : _a[relation].model) === inverseType; });
        const inverse = relation
            ? model.relationships[relation].inverse
            : null;
        if (inverse) {
            return inverse;
        }
        throw new Error('Inverse relation doesn\'t exists');
    }
}
export default ExivitySchema;
