var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from 'react';
import { juxt, thunkify, unless, always } from '@exivity/fp';
import { useFieldContext } from '../Field/context';
import { scientificToDecimal } from './scientificToDecimal';
import { StyledInput } from './StyledInput';
export function useValidState(value) {
    var prevValue = useRef(value);
    var _a = useState(true), valid = _a[0], setValid = _a[1];
    var setValidity = thunkify(setValid);
    useEffect(function () {
        if (prevValue.current !== value) {
            setValid(true);
        }
        prevValue.current = value;
    }, [value]);
    return {
        danger: !valid,
        onChange: unless(always(valid), setValidity(true)),
        onInvalid: setValidity(false)
    };
}
var getStringValue = function (value) { return typeof value === 'number'
    ? scientificToDecimal(value)
    : value || ''; };
/** Because we often convert string numbers to number type on the onChange handler
 * without this function it's not possible to input values with
 * a leading zero behind the comma.
 * eg: Number(1,0) will be parsed to 1 making it impossible to write 1,01
*/
function useValue(value) {
    var _a = useState(getStringValue(value) || ''), val = _a[0], setValue = _a[1];
    return {
        value: Number(val) === Number(getStringValue(value))
            ? val
            : getStringValue(value),
        onChange: function (event) {
            if (val !== event.target.value) {
                setValue(event.target.value);
            }
        }
    };
}
export var Input = React.forwardRef(function (_a, ref) {
    var _b = _a.type, type = _b === void 0 ? 'text' : _b, value = _a.value, onChange = _a.onChange, rest = __rest(_a, ["type", "value", "onChange"]);
    var id = useFieldContext().id;
    var validityProps = useValidState(value);
    var valueProps = useValue(value);
    var inputOnChange = function (e) {
        onChange && onChange(e.target.value, e);
    };
    return (_jsx(StyledInput, __assign({ ref: ref, id: id, type: type, value: valueProps.value }, validityProps, { onChange: juxt([
            inputOnChange,
            validityProps.onChange,
            valueProps.onChange
        ]) }, rest), void 0));
});
