import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { translate } from '@exivity/translations'

import { classNames } from '../../../utils/misc'
import { WrapIf } from '../../atoms/WrapIf'

import Table from './VirtualTable'
import propTypes from './propTypes'

import './index.css'

// AdvancedSelect needs this to work with layout template
const HeightWrapper = styled.div`
  height: calc(100vh - 100px);
`

/**
 * @type {React.ComponentType<any>}
 */
class AdvancedSelect extends Component {
  virtualTable

  forceUpdateGrid = () => this.virtualTable.forceUpdateGrid()

  componentDidMount () {
    this.props.onTable && this.props.onTable(this.virtualTable)
  }

  render () {
    const { header, toolbar, highlightSearches, data, noDataText, height, className } = this.props

    return (
      <WrapIf
        wrapper={(node) => <HeightWrapper>{node}</HeightWrapper>}
        condition={this.props.expandHeight}>
        <div data-testid={this.props.testId} className={classNames({
          'ex-advanced-select': true,
          'ex-advanced-select--with-header': header,
          'ex-advanced-select--with-toolbar': toolbar,
          'ex-advanced-select--height-auto': height === 'auto',
          'ex-advanced-select--highlight-searches': highlightSearches
        }, className)}>
          {header && (
            <header className='ex-advanced-select__header uk-flex uk-flex-middle'>
              {header}
            </header>
          )}
          <div className='ex-advanced_select__table'>
            <Table
              ref={(ref) => { this.virtualTable = ref }}
              {...this.props}
              data={
                !data.length
                  ? [{ key: 'placeholder', placeholder: noDataText }]
                  : data
              } />
          </div>
        </div>
      </WrapIf>
    )
  }
}

AdvancedSelect.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  onTable: PropTypes.func,
  testId: PropTypes.string
}

AdvancedSelect.defaultProps = {
  noDataText: translate('No items in this list'),
  noSearchResultText: translate('No search results'),
  autoExpandSelected: true,
  autoCheckChildren: false,
  noHeaders: false,
  height: 'auto',
  highlightSearches: true,
  filterSearches: true,
  expanded: false,
  onChecked: () => null,
  data: [],
  testId: 'advanced-select'
}

export default AdvancedSelect
