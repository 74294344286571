const createAccountFinder = (reportItem) => (acc) => (
  String(reportItem.account_id) === String(acc.key) && acc.key !== 'other'
)

export default (report, topAccounts, breakDownBy = 'day', valueType) => {
  if (!report) {
    return null
  }

  // To get right xAxis rendered, with monthly granularity take middle of month
  const monthlyGranularitySelected = breakDownBy === 'month'
    ? '15'
    : ''

  const data = {}
  // Loop over all top accounts, as to include only the
  // top series in the timeline limited by MAX_GRAPHS.
  report.forEach((reportItem) => {
    // If we didn't see this time-unit before, create an entry in the data list
    if (!data[reportItem[breakDownBy]]) {
      data[reportItem[breakDownBy]] = {
        date: reportItem[breakDownBy] + monthlyGranularitySelected,
        other: 0
      }
    }
    // precision for timeline should be 2
    if (topAccounts.find(createAccountFinder(reportItem))) {
      data[reportItem[breakDownBy]][reportItem.account_id] = reportItem[valueType].toFixed(2)
    } else {
      data[reportItem[breakDownBy]].other += reportItem[valueType].toFixed(2)
    }
  })

  return {
    series: topAccounts.map(item => ({ ref: item.key, title: item.title })),
    granularity: breakDownBy,
    data: Object.values(data)
  }
}
