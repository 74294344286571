import ClassAction, { createClassAction } from '../../store/middleware/ClassAction'
import { del, get, put } from '../../API/index'
import { FileEntry, formatListLookups } from '../../utils/formatters/lookups'
import { fromDoubleArraytoBlob, fromTextToDoubleArray } from '../../utils/helpers/csv'
import { ActionDispatcher } from '../../store/utils'
import { workThunks } from '../../domains/work/thunks'

export class UpdateLookupItems extends ClassAction {
  static readonly type = 'DATASOURCES_LOOKUPS_UPDATE_DATA'
  readonly type = UpdateLookupItems.type
  constructor (public items: any) {
    super()
  }
}

export class UpdateSelectedLookupData extends ClassAction {
  static readonly type = 'DATASOURCES__SELECTED_LOOKUP_UPDATE_DATA'
  readonly type = UpdateSelectedLookupData.type
  constructor (public data: any) {
    super()
  }
}

export class ExchangeLookup extends ClassAction {
  static readonly type = 'DATASOURCES__EXCHANGE_LOOKUP'
  readonly type = ExchangeLookup.type
  constructor (public oldName: string, public newName: string) {
    super()
  }
}

export const updateLookupItems = createClassAction(UpdateLookupItems)
export const updateSelectedLookupData = createClassAction(UpdateSelectedLookupData)
export const exchangeLookup = createClassAction(ExchangeLookup)

export function fetchLookups (): ActionDispatcher {
  return dispatch => {
    get('/file')
      .then(({ data }: { data: FileEntry[] }) => {
        const folder = data.find(folder => folder.name === 'lookup')

        if (folder && folder.children) {
          dispatch(updateLookupItems(formatListLookups(folder.children)))
        }
      })
  }
}

export function insertLookup (
  name: string,
  contents: Record<string, unknown>,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    get(`/file/lookup/${name}`, {}, { handleRejections: false })
      .then(() => {
        dispatch(
          workThunks.showErrorMessage(translate('Lookup with name {name} already exists.', name))
        )
      })
      .catch((error) => {
        if (error.message !== 'File not found') {
          return dispatch(workThunks.showErrorMessage(translate('System error.')))
        }
        const data = fromDoubleArraytoBlob(contents)
        put(`/file/lookup/${name}`, {}, data, { isBinary: true })
          .then(() => {
            dispatch(fetchLookups())
            dispatch(workThunks.showSuccessMessage(translate('Lookup inserted.')))
            dispatch(updateSelectedLookupData(contents))
            onFinished && onFinished()
          })
          .catch(() => {
            dispatch(workThunks.showErrorMessage(translate('Unable to create lookup.')))
          })
      })
  }
}

export function fetchLookupByName (name: string, onFinished?: () => void): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    get(`/file/lookup/${name}`)
      .then(data => {
        const parsedCsv = fromTextToDoubleArray(data)
        if (parsedCsv.errors.length) {
          throw new Error('Invalid file format')
        }
        dispatch(updateSelectedLookupData(parsedCsv.data))
        onFinished && onFinished()
      })
      .catch(() => {
        dispatch(workThunks.showErrorMessage(translate('Unable to fetch lookup with name {name}.', name)))
        dispatch(updateSelectedLookupData(null))
      })
  }
}

export function updateLookup (
  name: string,
  contents: Record<string, unknown>,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    const data = fromDoubleArraytoBlob(contents)
    put(`/file/lookup/${name}`, {}, data, { isBinary: true })
      .then(() => {
        dispatch(workThunks.showSuccessMessage(translate('Lookup updated.')))
        onFinished && onFinished()
      })
      .catch(() => {
        dispatch(workThunks.showErrorMessage(translate('Unable to update lookup.')))
      })
  }
}

export function renameLookup (
  oldName: string,
  newName: string,
  contents: Record<string, unknown>,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    const data = fromDoubleArraytoBlob(contents)
    put(`/file/lookup/${newName}`, {}, data, { isBinary: true })
      .then(() => {
        dispatch(exchangeLookup(oldName, newName))
        return del(`/file/lookup/${oldName}`)
      })
      .then(() => {
        dispatch(workThunks.showSuccessMessage(translate('Lookup updated.')))
        onFinished && onFinished()
      })
      .catch(() => {
        dispatch(workThunks.showErrorMessage(translate('Unable to update lookup.')))
      })
  }
}

export function deleteLookup (name: string, onFinish?: () => void): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    del(`/file/lookup/${name}`)
      .then(() => {
        dispatch(updateSelectedLookupData(null))
        dispatch(fetchLookups())
        dispatch(workThunks.showSuccessMessage(translate('Lookup deleted.')))
        onFinish && onFinish()
      })
      .catch(() => {
        dispatch(workThunks.showErrorMessage(translate('Unable to remove lookup.')))
      })
  }
}
