import { listToMap } from '../map';
import { PARENT, CHILDREN } from './data';
import { getParents } from './traverse';
export function listToTreeMap(data, keyAccessor, parentKeyAccessor) {
    var map = listToMap(data, keyAccessor);
    Object.values(map).forEach(function (item) {
        var parentKey = parentKeyAccessor(item);
        var parent = parentKey && map[parentKey];
        if (parent) {
            item[PARENT] = parent;
            var parentChildren = parent[CHILDREN];
            if (!parentChildren) {
                parent[CHILDREN] = [item];
            }
            else if (!parentChildren.includes(item)) {
                parentChildren.push(item);
            }
        }
    });
    Object.values(map).forEach(function (item) {
        item.level = getParents(item).length + 1;
        item.getPlainData = function () {
            return data.find(function (plainItem) { return keyAccessor(item) === keyAccessor(plainItem); });
        };
    });
    return map;
}
