import { HookContext } from '../fetchWithHooks/fetchWithHooks'

function parse (json: string) {
  try {
    // If json is empty, return an empty string
    return JSON.parse(json || '""')
  } catch (error) {
    throw new Error('Couldn\'t parse JSON from proxy.')
  }
}

export function parseJson (ctx: HookContext) {
  if (ctx.result) {
    ctx.result = parse(ctx.result)
  }

  return ctx
}
