import ClassAction, { createClassAction } from '../../store/middleware/ClassAction'
import { TemplateExtension, Template } from '../../reducers/datasources/templates'

export class UpdateTemplatesData extends ClassAction {
  static readonly type = 'DATASOURCES_TEMPLATES_UPDATE_DATA'
  readonly type = UpdateTemplatesData.type
  constructor (public extension: TemplateExtension, public data: Template[]) {
    super()
  }
}

export const updateTemplatesData = createClassAction(UpdateTemplatesData)
