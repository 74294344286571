import { createSelector } from 'reselect'
import { QueryBuilder } from '@orbit/data'

import {
  ServiceAttributes,
  ServiceCategoryModel,
  ServiceModel,
  ReportModel
} from '../../../../data/types'

export interface ServiceWithCategory {
  key: string
  attributes: ServiceAttributes & {
    serviceCategoryName: string | null
    serviceCategory: ServiceCategoryModel | undefined
  }
}

interface ServiceAndCategories {
  services: ServiceModel[]
  serviceCategories: ServiceCategoryModel[]
}

export const listServices = createSelector(
  ({ services }: ServiceAndCategories) => services,
  ({ serviceCategories }: ServiceAndCategories) => serviceCategories,
  (services, serviceCategories): ServiceWithCategory[] => {
    return services
      .map(service => {
        const servicecategory = serviceCategories
          .find(category => {
            if (
              service.relationships
              && service.relationships.servicecategory
              && service.relationships.servicecategory.data
            ) {
              return service.relationships.servicecategory.data.id === category.id
            }
            return false
          })

        return {
          key: service.id,
          attributes: {
            serviceCategoryName: servicecategory
              ? servicecategory.attributes.name
              : null,
            serviceCategory: servicecategory,
            ...service.attributes
          },
          relationships: { ...service.relationships }
        }
      })
  })

export enum ShowServices {
  all = 'all',
  report = 'report',
  orphans = 'orphans'
}

type SelectServices = {
  show: ShowServices
  report: ReportModel
  editing: boolean
}

export function selectServices ({ show, report, editing }: SelectServices) {
  if (show === ShowServices.report || !editing) {
    return (q: QueryBuilder) => q.findRelatedRecords({
      type: 'dset',
      id: report.attributes.dset
    }, 'services')
  }

  if (show === ShowServices.orphans) {
    return (q: QueryBuilder) => q.findRecords('service')
      .filter({ attribute: 'orphan', value: true })
  }

  return (q: QueryBuilder) => q.findRecords('service')
}
