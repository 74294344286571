import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { samlThunks, samlSelectors } from '../state'

export type AccountProvisioningMapping = {
  SAML_ATTRIBUTE: string
  MAP_TO: string
}

export const SAML_MAP_TO_ACCOUNT_KEY = 'ACCOUNT_KEY'

export interface SamlConfiguration {
  SAML_ENTITY_ID: string | null
  SAML_SSO_ENDPOINT: string | null
  SAML_SLO_ENDPOINT: string | null
  SAML_X509_CERTIFICATE: string | null
  SAML_ADVANCED: string | null
  SAML_DEFAULT_USERGROUP_ID: string | null
  SAML_ATTRIBUTE_USERGROUP: string | null
  SAML_ACCOUNT_PROVISIONING: null | AccountProvisioningMapping[]
  SAML_ATTRIBUTE_DISPLAY_NAME: string | null
  SAML_ATTRIBUTE_EMAIL: string | null
  SAML_ATTRIBUTE_USERNAME: string | null
}

export function useSamlConfig () {
  const dispatch = useDispatch()

  useEffect(() => { dispatch(samlThunks.fetchConfiguration()) }, [])

  return useSelector(samlSelectors.getConfiguration)
}
