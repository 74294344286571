import { caf, raf } from './animationFrame';
export var cancelAnimationTimeout = function (frame) { return caf(frame.id); };
/**
 * Recursively calls requestAnimationFrame until a specified delay has been met or exceeded.
 * When the delay time has been reached the function you're timing out will be called.
 *
 * Credit: Joe Lambert (https://gist.github.com/joelambert/1002116#file-requesttimeout-js)
 */
export var requestAnimationTimeout = function (callback, delay) {
    var start;
    // wait for end of processing current event handler, because event handler may be long
    Promise.resolve().then(function () {
        start = Date.now();
    });
    var timeout = function () {
        if (Date.now() - start >= delay) {
            // eslint-disable-next-line no-useless-call
            callback.call(undefined);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            frame.id = raf(timeout);
        }
    };
    var frame = {
        id: raf(timeout)
    };
    return frame;
};
