import { EnableSearch, DisableSearch } from '../actions/gui'

export interface GUIState {
  collapse: boolean
  searchEnabled: boolean
}

const initialState: GUIState = {
  collapse: false,
  searchEnabled: true
}

type GUIAction = EnableSearch | DisableSearch

const gui = (state = initialState, action: GUIAction): GUIState => {
  switch (action.type) {
    case EnableSearch.type:
      return { ...state, searchEnabled: true }

    case DisableSearch.type:
      return { ...state, searchEnabled: false }

    default:
      return state
  }
}

export default gui
