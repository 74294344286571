import React, { useState, useCallback, useEffect } from 'react'

import LoadingOverlay from '../../../../components/molecules/LoadingOverlay'
import Form from '../../../../components/molecules/Form'
import Button from '../../../../components/atoms/Button'
import { DomainChanger } from '../DomainChanger/DomainChanger'

import './Forms.css'

interface SsoRequestProps {
  apiRoot: string
  loading?: boolean
  samlOnly?: boolean
  onDomainChanged: (apiRoot: string) => void
  onSubmit: (apiRoot: string) => void
  loginWithPasswordButton: React.ReactElement|null
}

export function SsoRequest ({
  apiRoot,
  loading = false,
  samlOnly = false,
  onDomainChanged,
  onSubmit,
  loginWithPasswordButton
}: SsoRequestProps) {
  const [showDomain, setShowDomain] = useState(false)

  useEffect(() => {
    if (samlOnly) {
      onSubmit(apiRoot)
    }
  }, [])

  const toggleApiRootInput = useCallback(() => {
    if (!apiRoot) {
      setShowDomain(true)
    } else {
      setShowDomain(!showDomain)
    }
  }, [apiRoot, showDomain])

  return (
    <Form className='ex-login-form uk-background-default uk-position-relative uk-form-stacked'
      onSubmit={() => onSubmit(apiRoot)}>
      {loading && <LoadingOverlay />}

      <div className='uk-margin-bottom'>
        { samlOnly && loading
          ? 'Redirecting...'
          : (
            <Button className='uk-width-1-1'
              size='large'
              submit
              icon='chevron-right'
              iconPosition='right'
              variation='secondary'>Login
            </Button>
          )}
      </div>
      <div className='uk-margin-bottom'>
        <DomainChanger showDomain={showDomain}
          apiRoot={apiRoot} onDomainChanged={onDomainChanged} />
      </div>
      <div className='uk-flex uk-flex-between uk-grid-collapse uk-child-width-auto@s'
        data-uk-grid>
        {((samlOnly && !loading) || !samlOnly) && (
          <div>
            <Button variation='text' onClick={toggleApiRootInput}>
              Change domain
            </Button>
            <div className='uk-margin-bottom uk-hidden@s' />
          </div>
        )}
        {loginWithPasswordButton}
      </div>
    </Form>
  )
}
