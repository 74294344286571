import React from 'react'
import { Field, Label, Select } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { ServiceCategoryModel, ServiceModel } from '../../../../../../data/types'

interface ServiceCategoryProps {
  serviceCategory: ServiceCategoryModel | null
  serviceCategories: ServiceCategoryModel[]
  onChangeServiceCategory: (serviceCategory: ServiceCategoryModel) => void
  onChangeService: (service: ServiceModel | null) => void
}

export function ServiceCategory ({
  serviceCategory,
  serviceCategories,
  onChangeServiceCategory,
  onChangeService
}: ServiceCategoryProps) {
  return (
    <Field data-testid='service-category'>
      <Label>{translate('Category filter')}</Label>
      <Select
        searchable
        placeholder={translate('Select a category')}
        value={serviceCategory || undefined}
        data={serviceCategories}
        labelAccessor={(serviceCategory) => serviceCategory.attributes.name}
        onChange={(category) => {
          onChangeServiceCategory(category)
          onChangeService(null)
        }} />
    </Field>
  )
}
