import React from 'react'

import './index.css'

interface Props {
  children: string
}

export const Underline: React.FC<Props> = (props) => (
  <span className='ex-underline'>
    {props.children}
  </span>
)

export default Underline
