var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import { translate } from '@exivity/translations';
import { prop, isNil, divide, unless, equals, forEach, compose, arrayFrom, querySelectorAll, getBoundingClientRect } from '@exivity/fp';
import { useEventListener } from '@exivity/hooks';
import { renderSelectList } from '../SelectList';
import { Dropdown } from '../Dropdown';
import { themeGlobal, themeSpace, useIsSmallScreen } from '../Theme';
import { globalFont } from '../utils';
var MIN_WIDTH = 250;
var fadeInAnimation = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @keyframes fadeIn {\n    0% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n  animation: fadeIn linear 0.2s;\n"], ["\n  @keyframes fadeIn {\n    0% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n  animation: fadeIn linear 0.2s;\n"])));
var StyledDropdown = styled(Dropdown)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n  width: 100%;\n  min-width: ", "px;\n  max-width: 650px;\n  transform-origin: right;\n  transition: transform .1s ease-in-out;\n  background-color: rgba(255, 255, 255, 0.1);\n  \n  &:hover {\n    background-color: rgba(255, 255, 255, 0.3);\n  }\n  \n  .dropdown__drop {\n    padding: ", " 0;\n    box-sizing: border-box;\n    display: none;\n    width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: black;\n    ", "\n  }\n  \n  &:focus-within {\n    transition: transform .1s ease-in-out;\n    transform: scaleX(1) !important;\n    \n    .dropdown__drop {\n      display: inherit;\n      width: 100%;\n    }\n    \n    input {\n      box-sizing: box-content;\n      transition: transform .06s linear;\n      transform: unset !important;\n    }\n  }\n  \n  .dropdown__reference {\n    overflow: hidden;\n  }\n"], ["\n  position: relative;\n  height: 100%;\n  width: 100%;\n  min-width: ", "px;\n  max-width: 650px;\n  transform-origin: right;\n  transition: transform .1s ease-in-out;\n  background-color: rgba(255, 255, 255, 0.1);\n  \n  &:hover {\n    background-color: rgba(255, 255, 255, 0.3);\n  }\n  \n  .dropdown__drop {\n    padding: ", " 0;\n    box-sizing: border-box;\n    display: none;\n    width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: black;\n    ", "\n  }\n  \n  &:focus-within {\n    transition: transform .1s ease-in-out;\n    transform: scaleX(1) !important;\n    \n    .dropdown__drop {\n      display: inherit;\n      width: 100%;\n    }\n    \n    input {\n      box-sizing: box-content;\n      transition: transform .06s linear;\n      transform: unset !important;\n    }\n  }\n  \n  .dropdown__reference {\n    overflow: hidden;\n  }\n"])), MIN_WIDTH, themeSpace(1), fadeInAnimation);
var MeasureBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  max-width: 550px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  max-width: 550px;\n"])));
var StyledInput = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  height: 60px;\n  width: 100%;\n  background-color: transparent;\n  border: none;\n  padding-left: ", ";\n  outline: none;\n  transform-origin: left;\n  transition: transform .1s cubic-bezier(.63,.01,.65,.95);\n  font-size: 1rem;\n  color: ", ";\n  \n  &::placeholder {\n    color: ", ";\n    opacity: .5;\n  }\n"], ["\n  ", ";\n  height: 60px;\n  width: 100%;\n  background-color: transparent;\n  border: none;\n  padding-left: ", ";\n  outline: none;\n  transform-origin: left;\n  transition: transform .1s cubic-bezier(.63,.01,.65,.95);\n  font-size: 1rem;\n  color: ", ";\n  \n  &::placeholder {\n    color: ", ";\n    opacity: .5;\n  }\n"])), globalFont, themeSpace(2), themeGlobal('inverseBrandTextColor'), themeGlobal('inverseBrandTextColor'));
var calcScaleRatio = divide(MIN_WIDTH);
var calcCounterScaleRatio = divide(1);
var getScaleRatio = compose(calcScaleRatio, prop('width'), getBoundingClientRect);
var getMeasureBox = function (fn) { return forEach(fn, arrayFrom(querySelectorAll('.AppBarSearch__measurer', document))); };
var debouncedMeasurer = debounce(getMeasureBox, 200);
var offsetModifier = [{
        name: 'offset',
        options: {
            offset: [20, -2]
        }
    }];
export function AppBarSearch(_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, onChange = _a.onChange, props = __rest(_a, ["data", "onChange"]);
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useState(''), searchTerm = _d[0], setSearchTerm = _d[1];
    var _e = useState(1), ratioX = _e[0], setRatioX = _e[1];
    var isSmallScreen = useIsSmallScreen();
    useEventListener('resize', function () { return debouncedMeasurer(compose(setRatioX, getScaleRatio)); });
    if (isSmallScreen)
        return null;
    var noAnimation = ratioX > 0.6;
    var containerStyle = noAnimation
        ? { width: 250, paddingRight: 20 }
        : {
            transform: "scaleX(" + ratioX + ")",
            paddingRight: isOpen
                ? 20
                : 20 * calcCounterScaleRatio(ratioX)
        };
    var inputStyle = noAnimation
        ? undefined
        : {
            transform: "scaleX(" + calcCounterScaleRatio(ratioX) + ")"
        };
    var getScale = unless(isNil, compose(unless(equals(ratioX), setRatioX), getScaleRatio));
    return (_jsx(MeasureBox, __assign({ ref: getScale, className: 'AppBarSearch__measurer', tabIndex: -1 }, { children: _jsx(StyledDropdown, __assign({ tabIndex: -1, style: containerStyle, placement: 'bottom-end', modifiers: offsetModifier, reference: _jsx(StyledInput, { type: 'text', autoComplete: 'off', style: inputStyle, placeholder: translate('search...'), value: searchTerm, onFocus: function () { return setIsOpen(true); }, onChange: function (e) { return setSearchTerm(e.target.value); } }, void 0), onFocus: function (e) {
                var _a;
                (_a = e.target.querySelector('input')) === null || _a === void 0 ? void 0 : _a.focus();
            }, isOpen: isOpen }, { children: renderSelectList(__assign(__assign({ searchTerm: searchTerm, noDataText: translate('Start typing to search...') }, props), { onChange: function (value) {
                    setIsOpen(false);
                    onChange && onChange(value);
                }, data: searchTerm ? data : [] })) }), void 0) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
