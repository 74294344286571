import { createSelector } from 'reselect'

export const listUsergroups = createSelector(
  (usergroup) => usergroup,
  (usergroup) => {
    return usergroup.map(usergroup => {
      return {
        ...usergroup,
        key: usergroup.id
      }
    }
    )
  }
)
