/* eslint-disable no-restricted-globals */
import Raven from 'raven-js'

import { getUserSessionStorage } from '../store/storage'
import config from '../application/config'
import store from '../store'
import { configurationSelectors } from '../domains/administration/state/configuration'

const LEVEL_DEBUG = 'debug'
const LEVEL_INFO = 'info'
const LEVEL_WARNING = 'warning'
const LEVEL_ERROR = 'error'
const LEVEL_CRITICAL = 'critical'

let askingForUserFeedback = false
let ravenInstalled = false

// eslint-disable-next-line no-undef
const makeRelease = (version) => process.env.NODE_ENV === 'production'
  ? `glass-${version}`
  : 'glass-develop'

export function sentryEnabled (bypassInstallationCheck = false) {
  return Raven && (ravenInstalled || bypassInstallationCheck) && config.sentry.enabled
}

export function askForUserFeedback (force = false) {
  if (sentryEnabled() && (!askingForUserFeedback || force)) {
    // no user in this storage
    const user = getUserSessionStorage.fromEitherStorage('session')?.user

    Raven.showReportDialog({
      eventId: Raven.lastEventId(),
      user: {
        name: user ? user.fullname : 'unknown',
        email: user ? user.email_address : 'unknown'
      }
    })

    askingForUserFeedback = true

    // Cooldown period of 5 minutes
    setTimeout(() => {
      askingForUserFeedback = false
    }, 5 * 60 * 1000)
  }
}

export function registerUserWithSentry (username) {
  sentryEnabled() && Raven.setUserContext({ username })
}

export function unregisterUserWithSentry () {
  sentryEnabled() && Raven.setUserContext()
}

export function setUserContext () {
  const user = getUserSessionStorage.fromEitherStorage('session')?.user

  if (getUserSessionStorage.fromEitherStorage('session')?.user) {
    registerUserWithSentry(user ? user.fullname : 'unknown')
  } else {
    unregisterUserWithSentry()
  }
}

export function captureException (error, context) {
  setUserContext()
  sentryEnabled() && Raven.captureException(error, { extra: context })
}

export function captureMessage (message, level = LEVEL_INFO, context) {
  setUserContext()
  sentryEnabled() && Raven.captureMessage(message, {
    extra: context,
    level
  })
}

export function setRelease (release) {
  sentryEnabled() && Raven.setRelease(makeRelease(release))
}

export function installRaven (appId, key) {
  if (sentryEnabled(true)) {
    const state = store.getState()

    Raven.config(`https://${key}@sentry.io/${appId}`, {
      serverName: self.location.hostname,
      // eslint-disable-next-line no-undef
      environment: process.env.NODE_ENV,
      ignoreErrors: [
        'Only secure origins are allowed',
        'An SSL certificate error occurred when fetching the script'
      ],
      tags: {
        app_name: configurationSelectors.getAppName(state),
        api_root: configurationSelectors.getApiRoot(state)
      },
      release: makeRelease(config.app.version)
    })
    Raven.install()
    document.addEventListener('ravenSuccess', function () {
      // This will ask for user feedback for any captured exceptions which were not raised
      // through the logError method (i.e. uncaught exceptions). We have to be careful though,
      // since
      // exceptions thrown by the user-agent (i.e. Chromes new warnings about the page not being
      // secure) will also be trapped by Sentry, and will trigger the user feedback dialogue.
      config.sentry.enableAutoUserFeedback && askForUserFeedback()
    }, false)
    ravenInstalled = true
    setUserContext()
  }
}

function createLoggers () {
  let SHOULD_DEBUG = false
  const timers = {}

  return {
    setDebug (debug) {
      SHOULD_DEBUG = debug
    },
    debug (...args) {
      if (
        // eslint-disable-next-line no-undef
        (SHOULD_DEBUG === true || process.env.NODE_ENV !== 'production')
        // eslint-disable-next-line no-undef
        && process.env.NODE_ENV !== 'test'
      ) {
        self.console
        && console.debug
        && console.debug('%c DEBUG ', 'background-color: silver; color: white;', ...args)
      }
    },
    log (...args) {
      if (SHOULD_DEBUG === true) {
        self.console
        && console.info
        && console.info('%c LOG ', 'background-color: deepskyblue; color: white;', ...args)
      }
    },
    warn (message, context) {
      if (SHOULD_DEBUG === true) {
        self.console && console.warn
        && console.warn(
          '%c WARN ', 'background-color: lightsalmon; color: white;', message, context
        )
      } else {
        config.sentry.captureWarnings && captureMessage(message, LEVEL_WARNING, context)
      }
    },
    logError (error, context) {
      if (SHOULD_DEBUG === true) {
        self.console && console.error
        && console.error('%c ERROR ', 'background-color: tomato; color: white;', error, context)
      } else {
        captureException(error, context)

        // We should be able to call this immediately,
        // as Raven.lastEventId() should return an event_id
        // right after calling the Raven.captureException call. (i.e. it's synchronous to get an
        // event_id after calling the captureException method)
        config.sentry.enableAutoUserFeedback && askForUserFeedback()
      }
    },
    time (label = '') {
      if (SHOULD_DEBUG === true) {
        if (timers[label]) {
          console.log('%c TIMER ', 'background-color: mediumslateblue; color: white;', ' ')
          console.timeEnd(label)
          timers[label] = false
        } else {
          console.time(label)
          timers[label] = true
        }
      }
    }
  }
}

const loggers = createLoggers()

export const setDebug = loggers.setDebug
export const debug = loggers.debug
export const log = loggers.log
export const warn = loggers.warn
export const logError = loggers.logError
export const time = loggers.time

export const levels = {
  LEVEL_DEBUG,
  LEVEL_INFO,
  LEVEL_WARNING,
  LEVEL_ERROR,
  LEVEL_CRITICAL
}

export default log
