/* eslint-disable */
/** Resource types generated from the schema */
export var UserAccountAccessType;
(function (UserAccountAccessType) {
    UserAccountAccessType["All"] = "all";
    UserAccountAccessType["Custom"] = "custom";
})(UserAccountAccessType || (UserAccountAccessType = {}));
export var UserSource;
(function (UserSource) {
    UserSource["LOCAL"] = "local";
    UserSource["LDAP"] = "ldap";
    UserSource["SAML"] = "saml";
})(UserSource || (UserSource = {}));
export var UserGroupPermission;
(function (UserGroupPermission) {
    UserGroupPermission["All"] = "*";
    UserGroupPermission["ViewAudit"] = "view_audit";
    UserGroupPermission["ViewBudgets"] = "view_budgets";
    UserGroupPermission["ViewCogs"] = "view_cogs";
    UserGroupPermission["ViewLogs"] = "view_logs";
    UserGroupPermission["ViewReports"] = "view_reports";
    UserGroupPermission["ManageAccounts"] = "manage_accounts";
    UserGroupPermission["ManageDatasets"] = "manage_datasets";
    UserGroupPermission["ManageDataSources"] = "manage_data_sources";
    UserGroupPermission["ManageMetadataDefinitions"] = "manage_metadata_definitions";
    UserGroupPermission["ManageFiles"] = "manage_files";
    UserGroupPermission["ManageReports"] = "manage_reports";
    UserGroupPermission["ManageServices"] = "manage_catalogue";
    UserGroupPermission["ManageSettings"] = "manage_settings";
    UserGroupPermission["ManageUsers"] = "manage_users";
    UserGroupPermission["ManageWorkflows"] = "manage_workflows";
})(UserGroupPermission || (UserGroupPermission = {}));
export var ServiceType;
(function (ServiceType) {
    ServiceType["ServiceNameInHeader"] = "service_name_in_header";
    ServiceType["ServiceNameInData"] = "service_name_in_data";
})(ServiceType || (ServiceType = {}));
export var ServiceInterval;
(function (ServiceInterval) {
    ServiceInterval["Individually"] = "individually";
    ServiceInterval["Day"] = "day";
    ServiceInterval["Month"] = "month";
})(ServiceInterval || (ServiceInterval = {}));
export var ServiceChargeType;
(function (ServiceChargeType) {
    ServiceChargeType["DeprecatedManual"] = "manual";
    ServiceChargeType["DeprecatedAutomatic"] = "automatic";
    ServiceChargeType["DeprecatedManualPerInterval"] = "manual_per_interval";
    ServiceChargeType["DeprecatedAutomaticPerInterval"] = "automatic_per_interval";
    ServiceChargeType["Manual"] = "manual_per_unit";
    ServiceChargeType["Automatic"] = "automatic_per_unit";
    ServiceChargeType["ManualTieredStandard"] = "manual_tiered_standard";
    ServiceChargeType["ManualTieredInherited"] = "manual_tiered_inherited";
    ServiceChargeType["None"] = "none";
    ServiceChargeType["Other"] = "other";
})(ServiceChargeType || (ServiceChargeType = {}));
export var ServiceChargeModel;
(function (ServiceChargeModel) {
    ServiceChargeModel["Peak"] = "peak";
    ServiceChargeModel["Average"] = "average";
    ServiceChargeModel["SpecificDay"] = "specific_day";
    ServiceChargeModel["LastDay"] = "last_day";
})(ServiceChargeModel || (ServiceChargeModel = {}));
export var ServiceCogsType;
(function (ServiceCogsType) {
    ServiceCogsType["DeprecatedManualPerInterval"] = "manual_per_interval";
    ServiceCogsType["DeprecatedAutomaticPerInterval"] = "automatic_per_interval";
    ServiceCogsType["None"] = "none";
    ServiceCogsType["Manual"] = "manual_per_unit";
    ServiceCogsType["Automatic"] = "automatic_per_unit";
    ServiceCogsType["ManualTieredStandard"] = "manual_tiered_standard";
    ServiceCogsType["ManualTieredInherited"] = "manual_tiered_inherited";
})(ServiceCogsType || (ServiceCogsType = {}));
export var ServiceProrationType;
(function (ServiceProrationType) {
    ServiceProrationType["Full"] = "full";
    ServiceProrationType["None"] = "none";
})(ServiceProrationType || (ServiceProrationType = {}));
export var AdjustmentType;
(function (AdjustmentType) {
    AdjustmentType["Absolute"] = "absolute";
    AdjustmentType["Relative"] = "relative";
})(AdjustmentType || (AdjustmentType = {}));
export var AdjustmentTarget;
(function (AdjustmentTarget) {
    AdjustmentTarget["Charge"] = "charge";
    AdjustmentTarget["Quantity"] = "quantity";
})(AdjustmentTarget || (AdjustmentTarget = {}));
export var WorkflowRunStatus;
(function (WorkflowRunStatus) {
    WorkflowRunStatus["Started"] = "started";
    WorkflowRunStatus["Success"] = "success";
    WorkflowRunStatus["Failed"] = "failed";
    WorkflowRunStatus["TimedOut"] = "timed_out";
    WorkflowRunStatus["Invalid"] = "invalid";
    WorkflowRunStatus["InternalError"] = "internal_error";
})(WorkflowRunStatus || (WorkflowRunStatus = {}));
export var WorkflowStepLogStatus;
(function (WorkflowStepLogStatus) {
    WorkflowStepLogStatus["Started"] = "started";
    WorkflowStepLogStatus["Success"] = "success";
    WorkflowStepLogStatus["Failed"] = "failed";
    WorkflowStepLogStatus["TimedOut"] = "timed_out";
    WorkflowStepLogStatus["Invalid"] = "invalid";
    WorkflowStepLogStatus["InternalError"] = "internal_error";
})(WorkflowStepLogStatus || (WorkflowStepLogStatus = {}));
export var WorkflowStepType;
(function (WorkflowStepType) {
    WorkflowStepType["Core"] = "proximity";
    WorkflowStepType["RunExtractor"] = "use";
    WorkflowStepType["RunTransformer"] = "transcript";
    WorkflowStepType["PrepareReport"] = "edify";
    WorkflowStepType["PublishReport"] = "publish_report";
    WorkflowStepType["EvaluateBudget"] = "evaluate_budget";
    WorkflowStepType["Execute"] = "execute";
})(WorkflowStepType || (WorkflowStepType = {}));
export var WorkflowScheduleFrequency;
(function (WorkflowScheduleFrequency) {
    WorkflowScheduleFrequency["Hourly"] = "hourly";
    WorkflowScheduleFrequency["Daily"] = "daily";
    WorkflowScheduleFrequency["Monthly"] = "monthly";
})(WorkflowScheduleFrequency || (WorkflowScheduleFrequency = {}));
export var NotificationSubscriptionType;
(function (NotificationSubscriptionType) {
    NotificationSubscriptionType["BudgetEvaluated"] = "budget_evaluated";
    NotificationSubscriptionType["ReportPublished"] = "report_published";
    NotificationSubscriptionType["WorkflowEnded"] = "workflow_ended";
})(NotificationSubscriptionType || (NotificationSubscriptionType = {}));
export var NotificationChannelType;
(function (NotificationChannelType) {
    NotificationChannelType["mail"] = "mail";
    NotificationChannelType["slack"] = "slack";
    NotificationChannelType["nexmo"] = "nexmo";
    NotificationChannelType["webhook"] = "webhook";
})(NotificationChannelType || (NotificationChannelType = {}));
export var BudgetInterval;
(function (BudgetInterval) {
    BudgetInterval["Monthly"] = "month";
    BudgetInterval["Quarterly"] = "quarter";
    BudgetInterval["Yearly"] = "year";
})(BudgetInterval || (BudgetInterval = {}));
export var BudgetMetric;
(function (BudgetMetric) {
    BudgetMetric["Charge"] = "charge";
    BudgetMetric["Cogs"] = "cogs";
})(BudgetMetric || (BudgetMetric = {}));
export var BudgetItemKind;
(function (BudgetItemKind) {
    BudgetItemKind["Account"] = "account";
})(BudgetItemKind || (BudgetItemKind = {}));
export var BudgetItemStatus;
(function (BudgetItemStatus) {
    BudgetItemStatus["Regular"] = "regular";
    BudgetItemStatus["Excluded"] = "excluded";
    BudgetItemStatus["Remainder"] = "remainder";
})(BudgetItemStatus || (BudgetItemStatus = {}));
export var BudgetItemDistribution;
(function (BudgetItemDistribution) {
    BudgetItemDistribution["None"] = "none";
    BudgetItemDistribution["Shared"] = "shared";
    BudgetItemDistribution["Even"] = "even";
})(BudgetItemDistribution || (BudgetItemDistribution = {}));
export var BudgetItemFilter;
(function (BudgetItemFilter) {
    BudgetItemFilter["None"] = "none";
    BudgetItemFilter["Category"] = "servicecategory";
    BudgetItemFilter["Service"] = "service";
})(BudgetItemFilter || (BudgetItemFilter = {}));
export var ServiceSubscriptionAltInterval;
(function (ServiceSubscriptionAltInterval) {
    ServiceSubscriptionAltInterval["Individually"] = "individually";
    ServiceSubscriptionAltInterval["Day"] = "day";
    ServiceSubscriptionAltInterval["Month"] = "month";
    ServiceSubscriptionAltInterval["Year"] = "year";
})(ServiceSubscriptionAltInterval || (ServiceSubscriptionAltInterval = {}));
export var ServiceSubscriptionType;
(function (ServiceSubscriptionType) {
    ServiceSubscriptionType["Recurring"] = "recurring";
    ServiceSubscriptionType["OneOff"] = "one_off";
})(ServiceSubscriptionType || (ServiceSubscriptionType = {}));
export const enums = {
    UserAccountAccessType,
    UserSource,
    ServiceType,
    ServiceInterval,
    ServiceChargeType,
    ServiceCogsType,
    ServiceProrationType,
    AdjustmentType,
    AdjustmentTarget,
    WorkflowRunStatus,
    WorkflowStepLogStatus,
    WorkflowStepType,
    WorkflowScheduleFrequency,
    NotificationSubscriptionType,
    NotificationChannelType,
    BudgetInterval,
    BudgetMetric,
    BudgetItemKind,
    BudgetItemStatus,
    BudgetItemDistribution,
    BudgetItemFilter,
    ServiceSubscriptionType
};
