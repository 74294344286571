import React from 'react'
import { Button, Field, Form, Group, Input, Label } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { UserSource } from '../../../data/types'
import { useLogout } from '../../auth/hooks'
import { RevokeTokens } from '../../auth/state/thunks/logout'

interface BasicProps {
  source: UserSource

  username: string
  email: string
  newPassword: string
  verifyNewPassword: string

  onChangeUsername: (username: string) => void
  onChangeEmail: (email: string) => void
  onChangeNewPassword: (password: string) => void
  onChangeVerifyNewPassword: (password: string) => void

  onSave: () => void
}

function Personal (props: BasicProps) {
  const logoutAndRevokeAllTokens = useLogout(RevokeTokens.All)

  return (
    <Form onSubmit={props.onSave}>
      <Group title={translate('Personal Information')}>
        <Field.Container>
          <Field>
            <Label.Group>
              <Label>
                {translate('Username')}
              </Label>
              <Label.Sub>
                {translate('Used for logging in, case insensitive.')}
              </Label.Sub>
            </Label.Group>
            <Input
              required
              placeholder={translate('Username')}
              value={props.username}
              disabled={props.source !== UserSource.LOCAL}
              autoComplete='username'
              onChange={props.onChangeUsername} />
          </Field>
          <Field>
            <Label.Group>
              <Label>
                {translate('E-mail address')}
              </Label>
              <Label.Sub>
                {translate('Set for password reset.')}
              </Label.Sub>
            </Label.Group>
            <Input
              placeholder={translate('E-mail')}
              disabled={props.source === UserSource.SAML}
              value={props.email}
              autoComplete='email'
              onChange={props.onChangeEmail} />
          </Field>
        </Field.Container>
      </Group>

      {props.source === UserSource.LOCAL && (
        <Group title={translate('Change password')}>
          <Field.Container>
            <Field>
              <Label.Group>
                <Label>
                  {translate('Password')}
                </Label>
                <Label.Sub>
                  {translate('Must be at least 8 characters.')}
                </Label.Sub>
              </Label.Group>
              <Input
                type='password'
                placeholder={translate('Password')}
                value={props.newPassword}
                autoComplete='new-password'
                data-test='password'
                onChange={props.onChangeNewPassword} />
            </Field>
            <Field>
              <Label>
                {translate('Verify Password')}
              </Label>
              <Input
                type='password'
                placeholder={translate('Verify password')}
                value={props.verifyNewPassword}
                autoComplete='new-password'
                data-test='repeat-password'
                onChange={props.onChangeVerifyNewPassword} />
            </Field>
          </Field.Container>
        </Group>
      )}

      <Group title={translate('Account security')}>
        <Field.Container>
          <Field>
            <Label.Group>
              <Label>
                {translate('Sessions')}
              </Label>
              <Label.Sub>
                {translate('Invalidate all authenticated clients simultaneously.')}
              </Label.Sub>
            </Label.Group>
            <Field.Group>
              <Button secondary onClick={logoutAndRevokeAllTokens}>
                {translate('Sign out of all devices')}
              </Button>
            </Field.Group>
          </Field>
        </Field.Container>
      </Group>

      <Form.Toolbar>
        <Button.Update />
      </Form.Toolbar>
    </Form>
  )
}

export default React.memo(Personal)
