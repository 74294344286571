import { any, add, map, prop, last, none, isNil, chain, ifElse, always, isEmpty, compose, zipWith, subtract, arrayFrom, unlessIsNil, composeAsync, querySelector, addElementClass, getElementChild, querySelectorAll, elementContainsClass, getBoundingClientRect, getElementAnimations, removeElementClass, animateElement } from '@exivity/fp';
var getElementTop = compose(ifElse(isNil, always(0), compose(prop('top'), getBoundingClientRect)));
var getChildren = function (el) { return arrayFrom(el.children); };
var getOpenGroups = compose(arrayFrom, querySelectorAll('.group--expand'));
var getListHeight = compose(ifElse(isNil, always(0), compose(prop('height'), getBoundingClientRect)), 
// @ts-ignore - isNil check
querySelector('ul'));
export var getCoverHeight = compose(getListHeight, last, arrayFrom, querySelectorAll('.navbar__group'));
var anyNotZero = any(function (x) { return x !== 0; });
var allAnimationsDone = compose(isEmpty, chain(getElementAnimations), getChildren);
export var measurePositions = compose(map(getElementTop), getChildren);
function getTranslateY(element) {
    var style = window.getComputedStyle(element);
    var matrix = new WebKitCSSMatrix(style.transform);
    return matrix.m42;
}
export var getTranslatesYChildren = compose(map(getTranslateY), getChildren);
export var openGroup = function (el, index) { return unlessIsNil(addElementClass('group--expand'), getElementChild(index, el)); };
var openGroups = map(addElementClass('group--expand'));
var closeAllGroups = compose(map(removeElementClass('group--expand')), getChildren);
var allGroupsAreClosed = compose(none(elementContainsClass('group--expand')), getChildren);
var subtractLists = zipWith(subtract);
var addLists = zipWith(add);
var getFromToOpenGroup = function (navElement, clickedIndex) {
    var current = measurePositions(navElement);
    openGroup(navElement, clickedIndex);
    var end = measurePositions(navElement);
    var currentTranslates = getTranslatesYChildren(navElement);
    var from = anyNotZero(currentTranslates)
        ? currentTranslates
        : subtractLists(current, end);
    return map(function (from) { return [from, 0]; }, from);
};
var getFromToCloseGroup = function (navElement) {
    var current = measurePositions(navElement);
    var openedGroups = getOpenGroups(navElement);
    closeAllGroups(navElement);
    var end = measurePositions(navElement);
    openGroups(openedGroups);
    return zipWith(function (from, to) { return [from, to]; }, getTranslatesYChildren(navElement), subtractLists(end, current));
};
var getFromToOpenAndCloseGroup = function (navElement, clickedIndex) {
    var current = measurePositions(navElement);
    openGroup(navElement, clickedIndex);
    var between = measurePositions(navElement);
    var openedGroups = getOpenGroups(navElement);
    closeAllGroups(navElement);
    openGroup(navElement, clickedIndex);
    var end = measurePositions(navElement);
    openGroups(openedGroups);
    var startOffset = subtractLists(current, between);
    var from = addLists(startOffset, getTranslatesYChildren(navElement));
    var to = addLists(startOffset, subtractLists(end, current));
    return zipWith(function (from, to) { return [from, to]; }, from, to);
};
var animate = function (el, _a) {
    var from = _a[0], to = _a[1];
    return animateElement({
        duration: 200,
        easing: 'ease-in-out'
    }, [
        { transform: "translateY(" + from + "px)" },
        { transform: "translateY(" + to + "px)" }
    ], el);
};
export var animateNavElements = function (navElement, openIndex) {
    var fromTo = !openIndex
        ? getFromToCloseGroup(navElement)
        : allGroupsAreClosed(navElement)
            ? getFromToOpenGroup(navElement, openIndex)
            : getFromToOpenAndCloseGroup(navElement, openIndex);
    var animations = zipWith(animate, getChildren(navElement), fromTo);
    var switchToFinalStateWhenFinished = composeAsync(function () {
        if (allAnimationsDone(navElement)) {
            closeAllGroups(navElement);
            openIndex && openGroup(navElement, openIndex);
        }
    }, Promise.all.bind(Promise), map(prop('finished')));
    switchToFinalStateWhenFinished(animations);
};
