import { fetchWithHooks } from '../../fetchWithHooks/fetchWithHooks'
import { fetchWithTimeout } from '../../fetch/fetchWithTimeout'
import { handleResponseErrors, handleWorkerThreads, iff } from '../../hooks'

export const prepare = (
  endpoint: string,
  fetchOptions: RequestInit,
  otherOptions: any,
  { isPrepare } = { isPrepare: false }
) => fetchWithHooks({
  before: [
    handleWorkerThreads(),
    iff(() => isPrepare, (ctx) => {
      ctx.params.query = {
        ...ctx.params.query,
        start: otherOptions.start,
        end: otherOptions.end
      }

      return ctx
    })
  ],
  after: [
    handleResponseErrors,
    handleWorkerThreads()
  ],
  error: [
    handleResponseErrors,
    handleWorkerThreads()
  ]
}, fetchWithTimeout)(endpoint, fetchOptions, otherOptions)
