import React, { useCallback } from 'react'
import { Group, Alert, Field, Label, Radio, Select, renderIcon } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { RecordIdentity } from '@orbit/data'
import { useSelector } from 'react-redux'
import { queries, Resources, useCacheQuery, useCacheQueryFn } from '@exivity/data-layer'
import { viewAttribute, pick } from '@exivity/fp'

import { UserAccountAccessType } from '../../../../../data/types'
import { reportSelectors } from '../../../../reports/state'

interface AccountAccessGroupProps {
  isCurrentUser: boolean
  type: UserAccountAccessType
  accounts: RecordIdentity[]
  onChangeType: (type: UserAccountAccessType) => void
  onChangeAccounts: (accounts: RecordIdentity[]) => void
}

export function useGetChildAccounts () {
  const query = useCacheQueryFn()
  return useCallback((account: Resources['account']) => (
    query(
      queries
        .relationshipOf('account', account, 'children')
        .sortByAttribute('name', 'ascending')
    )
  ), [query])
}

export function AccountAccessGroup ({
  isCurrentUser,
  type,
  accounts,
  onChangeType,
  onChangeAccounts
}: AccountAccessGroupProps) {
  const reportId = useSelector(reportSelectors.getSelectedReportId)
  const allAccounts = useCacheQuery(
    queries
      .relationshipOf('report', reportId, 'accounts')
      .filterByAttribute('level', 'equal', 1)
      .sortByAttribute('name', 'ascending')
  )

  const getChildAccounts = useGetChildAccounts()

  return (
    <Group title={translate('Account visibility')}>
      {isCurrentUser && (
        <Alert data-testid='logout-warning' warning>
          {translate('Changes will only be visible throughout the application after you sign out and log in again.')}
        </Alert>
      )}

      <Field.Container>
        <Field data-testid='account-access-type'>
          <Label>{translate('Visibility type')}</Label>
          <Radio.Group value={type}
            onChange={onChangeType}>
            <Radio
              label={translate('Show all accounts')}
              value={UserAccountAccessType.All} />
            <Radio
              label={translate('Show only specific accounts')}
              value={UserAccountAccessType.Custom} />
          </Radio.Group>
        </Field>

        {type !== UserAccountAccessType.All && (
          <Field data-testid='accounts'>
            <Label>{translate('Visible accounts')}</Label>
            <Select
              multiple
              searchable
              renderIcon={renderIcon.hasActiveChildren}
              labelAccessor={viewAttribute('name')}
              valueAccessor={pick(['type', 'id'])}
              placeholder={translate('Select accounts')}
              value={accounts}
              data={allAccounts}
              getChildNodes={getChildAccounts}
              onChange={onChangeAccounts} />
          </Field>
        )}
      </Field.Container>
    </Group>
  )
}
