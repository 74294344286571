import { withData } from 'react-orbitjs'
import { withRouter } from '@exivity/routing'

import { Metadata } from './Metadata'

const mapRecordsToProps = () => ({
  definitions: (q) => q.findRecords('metadatadefinition')
})

export default withRouter(withData(mapRecordsToProps)(Metadata))
