import { configurationSelectors } from '../domains/administration/state/configuration'
import store from '../store'

function getState () {
  return store.getState()
}

/** @deprecated */
const configuration = {
  ERROR_TRACKING: () => configurationSelectors.isErrorTrackingEnabled(getState())
}

const loadedQueue: any[] = []
let loaded = false

export const configurationLoaded = () => {
  // Execute queue only once
  if (loaded === false) {
    loadedQueue.forEach(cb => {
      cb(configuration)
    })
  }

  loaded = true
}

export const onConfigurationLoaded = (cb: (conf: typeof configuration) => any) => {
  if (loaded === false) {
    loadedQueue.push(cb)
  } else {
    cb(configuration)
  }
}

export default configuration
