function removeUndefined(arr) {
    const len = arr.length;
    for (let i = 0; i < len; i++) {
        arr[i] !== undefined && arr.push(arr[i]);
    }
    arr.splice(0, len);
}
export function copyArray(arr1, arr2) {
    const length = Math.max(arr1.length, arr2.length);
    for (let i = 0; i <= length; i++) {
        arr1[i] = arr2[i];
    }
    removeUndefined(arr1);
}
