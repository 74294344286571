import React from 'react'
import styled from 'styled-components'
import { SelectFilter } from '@exivity/ui'
import { useSelector } from 'react-redux'
import { queries, Resources, useCacheQueryFn } from '@exivity/data-layer'
import { exists } from '@exivity/fp'
import { useParams } from '@exivity/routing'

import { SummaryParams } from '../../pages/Summary'
import { reportSelectors } from '../../state'
import { stringSortRecordsBy } from '../../../../utils/array'

function getAccountName (account: Resources['account']) {
  return account.attributes.name
}

function getAccountId (account: Resources['account']) {
  return account.id
}

interface SummaryAccountFilterProps {
  accountId?: string
}

const ConstrainWidth = styled.div`
  width: 200px;
`

export function SummaryAccountFilter ({ accountId }: SummaryAccountFilterProps) {
  const [{ id }, setParams] = useParams<SummaryParams>()
  const consolidated = useSelector(reportSelectors.shouldConsolidateSummary)
  const accountIds = useSelector(reportSelectors.getSummaryReportAccountIds)
  const cacheQueryFn = useCacheQueryFn()

  if (consolidated) return null

  const queryAccount = (id: string) => cacheQueryFn(queries.find('account', id))

  const accounts = accountIds
    .map(queryAccount)
    .filter(exists)
    .sort(stringSortRecordsBy('name')) as Resources['account'][]

  return (
    <ConstrainWidth className='summary-account-filter'>
      <SelectFilter
        searchable
        inputValue={accountId || id
          ? queryAccount(accountId || id)?.attributes?.name ?? undefined
          : undefined}
        value={accountId || id || undefined}
        data={accounts}
        labelAccessor={getAccountName}
        valueAccessor={getAccountId}
        onChange={setParams('id')} />
    </ConstrainWidth>
  )
}
