import { Record as JsonApiRecord, RecordHasOneRelationship } from '@orbit/data'

import { makeCreateModel } from '../../utils/model'

const WORKFLOW_STEP_TYPE = 'workflowstep'

export enum WorkflowStepType {
  Core = 'proximity',
  RunExtractor = 'use',
  RunTransformer = 'transcript',
  PrepareReport = 'edify',
  PublishReport = 'publish_report',
  EvaluateBudget = 'evaluate_budget',
  Execute = 'execute',
}

type WorkflowStepOffsets = {
  from_date?: number
  to_date?: number
}

export const WORKFLOW_STEP_ALL_BUDGETS_TOKEN = '*'

export interface WorkflowStepOptions {
  [WorkflowStepType.Core]: {
    command?: string
    arguments?: string
  }
  [WorkflowStepType.RunExtractor]: {
    name?: string
    arguments?: string
    environment_id?: string
  } & WorkflowStepOffsets
  [WorkflowStepType.RunTransformer]: {
    name?: string
    environment_id?: string
  } & WorkflowStepOffsets
  [WorkflowStepType.PrepareReport]: {
    report_id?: string
  } & WorkflowStepOffsets
  [WorkflowStepType.PublishReport]: {
    report_id?: string
  }
  [WorkflowStepType.EvaluateBudget]: {
    budget_id?: string | typeof WORKFLOW_STEP_ALL_BUDGETS_TOKEN
  }
  [WorkflowStepType.Execute]: {
    command?: string
  }
}

export interface WorkflowStepModel<T extends WorkflowStepType|undefined = undefined>
  extends JsonApiRecord {
  type: typeof WORKFLOW_STEP_TYPE
  attributes: {
    type: T extends WorkflowStepType ? T : WorkflowStepType | undefined
    options: T extends WorkflowStepType ? WorkflowStepOptions[T] : Record<string, any>
    sort: number
    timeout: number
    wait: boolean
  }
  relationships: {
    workflow: RecordHasOneRelationship
  }
}

export const DEFAULT_WORKFLOW_STEP: WorkflowStepModel = {
  id: '',
  type: WORKFLOW_STEP_TYPE,
  attributes: {
    type: undefined,
    options: {},
    sort: 0,
    wait: true,
    timeout: 3600
  },
  relationships: {
    workflow: { data: null }
  }
}

export const createWorkflowStep = makeCreateModel<WorkflowStepModel>(
  WORKFLOW_STEP_TYPE,
  DEFAULT_WORKFLOW_STEP.attributes
)
