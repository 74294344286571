import React from 'react'
import { Button, ButtonProps, Icon } from '@exivity/ui'
import { MdRefresh } from 'react-icons/md'

export function RefreshButton (props: ButtonProps) {
  return (
    <Button secondary transparent round {...props} outlined>
      <Icon>
        <MdRefresh />
      </Icon>
    </Button>
  )
}
