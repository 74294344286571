import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Router,
  getPathname,
  useRedirect,
  useLocation,
  RenderRoutes,
  browserHistory,
  getActiveRoutes,
  useRedirectRoute,
  getAllRequiredPermissions
} from '@exivity/routing'
import { useUserHasPermissionFn } from '@exivity/data-layer'

import { trackPageview } from '../../utils/ga'
import { useSAML } from '../../domains/auth/SingleSignOn/SAML/authCycle'
import { app } from '../../routes'
import { isDevEnvironment } from '../../utils/system'
import { authSelectors } from '../../domains/auth/state'

browserHistory.listen(({ location }) => {
  if (!isDevEnvironment()) {
    trackPageview(location.pathname)
  }
})

function useRoutePermissions () {
  const { pathname } = useLocation()
  const replaceRoute = useRedirectRoute()
  const required = getAllRequiredPermissions(getActiveRoutes(pathname, routes))
  const userHasPermission = useUserHasPermissionFn()

  useEffect(() => {
    if (!userHasPermission(required)) {
      replaceRoute(app.routes.forbidden)
    }
  })
}

function useRouteAuthentication () {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const { pathname, state } = useLocation<{ referrer?: string }>()
  const replaceRoute = useRedirectRoute()
  const replace = useRedirect()

  useEffect(() => {
    // redirect to home when on app path
    if (getPathname(app) === pathname) {
      replaceRoute(app.routes.home)
    }

    if (isAuthenticated && pathname === getPathname(app.routes.login)) {
      state?.referrer
        ? replace(state.referrer)
        : replaceRoute(app.routes.home)
    } else if (
      !isAuthenticated
      && getPathname(app.routes.login) !== pathname
      && !pathname.includes(getPathname(app.routes.resetPassword))) {
      replaceRoute(app.routes.logout, pathname)
    }
  })
}

const useHandleSigningOut = () => {
  const { pathname } = useLocation()
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const signingOut = useSelector(authSelectors.isSigningOut)
  const replaceRoute = useRedirectRoute()

  useEffect(() => {
    if (!isAuthenticated && !signingOut && pathname === getPathname(app.routes.logout)) {
      replaceRoute(app.routes.login)
    }
  })
}

const routes = {
  routes: app
} as any

const Routes = () => {
  // If we ever remove useSAML, be sure to check if there is no other
  // api related code relying on the gui taking the token from the url to refresh token state
  useSAML()
  useRouteAuthentication()
  useRoutePermissions()
  useHandleSigningOut()

  return (
    <RenderRoutes routes={routes} />
  )
}

const Dispatcher = () => (
  <Router>
    <Routes />
  </Router>
)

export default Dispatcher
