import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { get, patch } from '../../API'
import type { RootState } from '../../reducers'
import { createAsyncThunk } from '../../store/utils'

import type { SamlConfiguration } from './utils/configuration'

export const samlSelectors = {
  getConfiguration: (state: RootState) => state.saml.configuration
}

const fetchConfiguration = createAsyncThunk('saml/fetch', () => {
  return get('/system/saml').then(data => data.configuration)
})

const persistConfiguration = createAsyncThunk('saml/persist', (_, { getState }) => {
  return patch('/system/saml', {}, { configuration: samlSelectors.getConfiguration(getState()) })
    .then(data => data.configuration)
})

export const { actions: samlActions, reducer: saml } = createSlice({
  name: 'saml',
  initialState: {
    configuration: {
      SAML_ENTITY_ID: null,
      SAML_SSO_ENDPOINT: null,
      SAML_SLO_ENDPOINT: null,
      SAML_X509_CERTIFICATE: null,
      SAML_ADVANCED: null,
      SAML_DEFAULT_USERGROUP_ID: null,
      SAML_ATTRIBUTE_USERGROUP: null,
      SAML_ACCOUNT_PROVISIONING: null,
      SAML_ATTRIBUTE_DISPLAY_NAME: null,
      SAML_ATTRIBUTE_EMAIL: null,
      SAML_ATTRIBUTE_USERNAME: null
    } as SamlConfiguration
  },
  reducers: {
    updateConfiguration: (state, action: PayloadAction<SamlConfiguration>) => {
      state.configuration = { ...state.configuration, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.configuration = { ...state.configuration, ...action.payload }
      })
      .addCase(persistConfiguration.fulfilled, (state, action) => {
        state.configuration = { ...state.configuration, ...action.payload }
      })
  }
})

export const samlThunks = {
  fetchConfiguration: fetchConfiguration,
  persistConfiguration: persistConfiguration
}
