import { post, patch, del } from './'

export type Datasource = 'extractors' | 'transformers'

interface InsertDatasourceBody {
  name: string
  contents: string
}

export function insertDatasource (
  datasource: Datasource,
  body: InsertDatasourceBody
) {
  return post(`/${datasource}`, {}, body)
}

interface UpdateDatasourceBody {
  contents?: string
  variables?: any
}

export function updateDatasource (
  datasource: Datasource,
  name: string,
  body: UpdateDatasourceBody
) {
  return patch(`/${datasource}/${name}`, {}, body)
}

export function deleteDatasource (datasource: Datasource, name: string) {
  return del(`/${datasource}/${name}`)
}
