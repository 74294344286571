import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'
import { invalidateAllReportData } from '../invalidateAllReportData'

export const updateReport = {
  prepare: (id: string | null) => ({
    payload: id,
    meta: {
      extraActions: invalidateAllReportData
    }
  }),
  reducer: (filters: ReportFilterState, action: PayloadAction<string | null>) => {
    filters.report = action.payload
    filters.account = null
    filters.category = null
    filters.service = null
    filters.accounts.pinned = []
    filters.services.pinned = []
    filters.instances.pinned = []
  }
}
