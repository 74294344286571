import React from 'react'
import { Button, useModal } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { KeyColumnSelector, KeyColumnSelectorOptionType } from '../../KeyColumnSelector'

interface ToolbarProps {
  editing: boolean
  selected: string[]
  columnSelectData: KeyColumnSelectorOptionType[]
  columnSelected: string
  onChangeColumnSelection: (option: string) => void
  toggleEdit: () => void
  selectAll: () => void
  selectNone: () => void
  bulkDelete: () => void
  router: any
}

export function Toolbar ({
  editing,
  toggleEdit,
  selected,
  selectAll,
  selectNone,
  bulkDelete,
  router,
  columnSelectData,
  columnSelected,
  onChangeColumnSelection
}: ToolbarProps) {
  const confirm = useModal().confirm

  return (
    <>
      {!editing && (
        <Button outlined secondary small
          data-testid='services-edit-mode-button'
          onClick={() => {
            confirm({
              body: translate('Are you sure you want to enable edit mode? After making changes, all reports must be re-prepared')
            }).then(toggleEdit)
          }
          }>
          {translate('Edit')}
        </Button>
      )}
      <KeyColumnSelector
        visible={!editing}
        options={columnSelectData}
        selectedOption={columnSelected}
        selectOption={onChangeColumnSelection} />
      {editing && (
        <>
          <Button.Group secondary small>
            <Button onClick={() => toggleEdit()}>
              {translate('Edit')}
            </Button>
            <Button.Select mr={10}>
              <Button.SelectOption onClick={selectAll}>
                {translate('Select all')}
              </Button.SelectOption>
              <Button.SelectOption onClick={selectNone}>
                {translate('Select none')}
              </Button.SelectOption>
            </Button.Select>
          </Button.Group>
          {selected.length > 0 && (
            <Button.Delete small mr={10} onClick={bulkDelete}>
              {translate('Delete')}
            </Button.Delete>
          )}
          <Button success small disabled={selected.length > 0}
            data-testid='service-create-button'
            onClick={() => router.push('services/overview/new')}>
            {translate('Create')}
          </Button>
        </>
      )}
    </>
  )
}
