import { useState, useRef, useEffect } from 'react';
import { pipe, equals } from '@exivity/fp';
import { transformSelectors, useTransformListener } from '../listeners';
import { useCacheQueryFn } from './useCacheQueryFn';
import { operationObservers } from './operationObservers';
function usePrevIsEqual(obj) {
    const ref = useRef(obj);
    useEffect(() => {
        ref.current = obj;
    }, [obj]);
    return equals(ref.current, obj);
}
export function useCacheQuery(queryBuilder, listener) {
    const queryCache = useCacheQueryFn();
    const queryExpression = queryBuilder.toQuery();
    const isEqual = usePrevIsEqual(queryExpression);
    const [result, setResult] = useState(() => {
        const res = queryCache(queryBuilder);
        listener && listener(res);
        return res;
    });
    useEffect(() => {
        if (!isEqual) {
            const res = queryCache(queryBuilder);
            listener && listener(res);
            setResult(res);
        }
    });
    useTransformListener(pipe(transformSelectors.getOperationsProp, (operations) => {
        const shouldUpdate = operationObservers[queryExpression.op](queryExpression);
        if (shouldUpdate(operations)) {
            const res = queryCache(queryBuilder);
            listener && listener(res);
            setResult(res);
        }
    }));
    return result;
}
export function useQueryListener(queryBuilder, listener) {
    const queryExpression = queryBuilder.toQuery();
    const isEqual = usePrevIsEqual(queryExpression);
    useEffect(() => {
        if (!isEqual) {
            listener();
        }
    });
    useTransformListener(pipe(transformSelectors.getOperationsProp, (operations) => {
        const shouldUpdate = operationObservers[queryExpression.op](queryExpression);
        if (shouldUpdate(operations)) {
            listener();
        }
    }));
}
