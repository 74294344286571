import { extractId } from './utils';
import { createQueryModifier } from './queryModifier';
export class Find {
    find(type, identifier) {
        return createQueryModifier(this.schema, {
            op: 'findRecord',
            record: {
                type,
                id: extractId(identifier)
            }
        });
    }
}
