var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { fromTheme, matchThemeProp } from '../Theme';
var StyledLi = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  ", "\n\n  font-family: ", ";\n  font-size: ", "em;\n\n  outline: none;\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n\n  ", "\n\n  font-family: ", ";\n  font-size: ", "em;\n\n  outline: none;\n\n  ", "\n"])), function (props) { return props.active
    ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), fromTheme(function (theme) { return theme.global.purposes.primary; })) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), props.noDataPlaceholder ? fromTheme(function (theme) { return theme.colors.gray; }) : 'inherit'); }, fromTheme(function (theme) { return theme.global.fontFamily; }), matchThemeProp(function (theme) { return theme.global.sizes; }, {
    modifier: function (em) { return em / 16 * 14; }
}), function (props) { return props.focusable && !props.noDataPlaceholder
    ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      &:hover, :focus {\n        background-color: ", ";\n      }\n      cursor: pointer;\n    "], ["\n      &:hover, :focus {\n        background-color: ", ";\n      }\n      cursor: pointer;\n    "])), fromTheme(function (theme) { return theme.colors.lightGray; })) : ''; });
var StyledInnerItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n  width: 100%;\n  ", "\n"], ["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n  width: 100%;\n  ", "\n"])), space);
export var focusElement = function (event) { return event.currentTarget.focus(); };
export function ListItem(_a) {
    var children = _a.children, _b = _a.focusable, focusable = _b === void 0 ? true : _b, _c = _a.px, px = _c === void 0 ? 2 : _c, rest = __rest(_a, ["children", "focusable", "px"]);
    return (_jsx(StyledLi, __assign({ "data-testid": 'list-item' }, rest, { focusable: focusable, tabIndex: focusable ? -1 : undefined, onMouseOver: focusElement }, { children: _jsx(StyledInnerItem, __assign({ px: px }, { children: children }), void 0) }), void 0));
}
ListItem.displayName = 'ListItem';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
