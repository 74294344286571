import { createSelector } from 'reselect'

import { stringSortObjectBy } from '../utils/array'
import { DsetModel } from '../data/types'

export const memoizedListDsets = createSelector(
  (dsets: DsetModel[]) => dsets,
  (dsets) => dsets.map(item => ({
    value: item.id,
    // Key becomes redundant once there are no more
    // legacy Select components that depend on this function.
    key: item.id,
    label: item.id,
    columns: item.attributes.columns
  })).sort(stringSortObjectBy('key'))
)

export const memoizedGetDsetColumns = createSelector(
  (obj: {
    dsetId: string
    dsets: DsetModel[]
  }) => obj.dsetId,
  (obj) => obj.dsets,
  (dsetId, dsets) => {
    if (dsetId) {
      if (!dsets.length) return []

      const dset = dsets.find(dset => String(dset.id) === String(dsetId))

      if (!dset || !Array.isArray(dset.attributes.columns)) {
        return []
      }

      return dset.attributes.columns.map(column => ({
        value: column,
        // Key becomes redundant once there are no more
        // legacy Select components that depend on this function.
        key: column,
        label: column
      })).sort(stringSortObjectBy('key'))
    }

    return []
  }
)
