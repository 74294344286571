import React from 'react'
import { withData } from 'react-orbitjs'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { QueryBuilder } from '@orbit/data'
import { withRouter } from '@exivity/routing'

import { RootState } from '../../../../reducers'
import { ServiceCategoryModel, ServiceModel, ReportModel } from '../../../../data/types'

import { ServiceSelectList } from './ServiceSelectList'
import { selectServices, ShowServices } from './listServices'

export type ServiceSelectListProps =
  OwnProps
  & ReportProp
  & RecordProps
  & StateProps
  & {
    router: any
    params: Record<string, string>
  }

function mapStateToProps (state: RootState) {
  return {
    selectedReport: state.reports.filters.report
  }
}

export type OwnProps = {
  header: React.ReactElement
  show: ShowServices
  useEditToggle: [boolean, () => void, (toggle: boolean) => void]
}

type StateProps = ReturnType<typeof mapStateToProps>

export type ReportProp = {
  report: ReportModel
}

type RecordProps = {
  services: ServiceModel[]
  serviceCategories: ServiceCategoryModel[]
}

function mapReportToProps ({ selectedReport }: StateProps) {
  if (selectedReport) {
    return {
      report: (q: QueryBuilder) => q.findRecord({ type: 'report', id: selectedReport })
    }
  }

  return {}
}

function mapRecordsToProps ({ show, report, useEditToggle }: OwnProps & StateProps & ReportProp) {
  const [editing] = useEditToggle

  if (!report) {
    return {}
  }

  return {
    services: selectServices({ show, report, editing }),
    serviceCategories: (q: QueryBuilder) => q.findRecords('servicecategory')
      .sort('name')
  }
}

export default compose(
  connect(mapStateToProps),
  withData(mapReportToProps),
  withData(mapRecordsToProps),
  withRouter
)(ServiceSelectList) as unknown as React.FC<OwnProps>
