var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { MdRemove, MdAdd } from 'react-icons/md';
import { ListItem } from '../ListItem';
import { BranchSpacer } from '../BranchSpacer';
import { classNames } from '../utils';
import { fromTheme } from '../Theme';
import { Icon } from '../Icon';
import { CHILDREN } from '../utils/tree';
export var EXPAND_ICON = MdAdd;
export var COLLAPSE_ICON = MdRemove;
export var ToggleExpandedButton = styled.button.attrs({ type: 'button' })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  border-radius: 0;\n  border: none;\n\n  padding: 0;\n  cursor: pointer;\n"], ["\n  width: 20px;\n  height: 20px;\n  border-radius: 0;\n  border: none;\n\n  padding: 0;\n  cursor: pointer;\n"])));
var StyledValue = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 10px;\n"], ["\n  margin-left: 10px;\n"])));
var ButtonIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  svg {\n    width: 100%;\n    height: 100%;\n    color: ", ";\n  }\n"], ["\n  svg {\n    width: 100%;\n    height: 100%;\n    color: ", ";\n  }\n"])), fromTheme(function (theme) { return theme.colors.gray; }));
export function DefaultItem(_a) {
    var data = _a.data, index = _a.index, _b = _a.style, style = _b === void 0 ? {} : _b, _c = _a.test, test = _c === void 0 ? 'treelist-item' : _c, rest = __rest(_a, ["data", "index", "style", "test"]);
    var items = data.items, onChange = data.onChange, labelAccessor = data.labelAccessor;
    var item = items[index];
    function handleChange(e) {
        onChange && onChange(item.getPlainData(), e);
    }
    return (_jsx(ListItem, __assign({ "data-testid": test, style: style, onClick: handleChange }, rest, { children: _jsxs(BranchSpacer, __assign({ spacing: 20, padding: item[CHILDREN] ? 5 : 0, index: index, data: items }, { children: [_jsx("div", __assign({ className: classNames({
                        'treelist__expand--invisible': !item[CHILDREN]
                    }) }, { children: _jsx(ToggleExpandedButton, __assign({ "data-testid": test + "-toggle-expand-button", onClick: function (e) {
                            e.stopPropagation();
                            item.expand();
                        } }, { children: _jsx(ButtonIcon, { children: item.expanded
                                ? _jsx(COLLAPSE_ICON, { "data-testid": test + "-toggle-expand-button-collapse-icon" }, void 0)
                                : _jsx(EXPAND_ICON, { "data-testid": test + "-toggle-expand-button-expand-icon" }, void 0) }, void 0) }), void 0) }), void 0), _jsx(StyledValue, { children: labelAccessor(item) }, void 0)] }), void 0) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
