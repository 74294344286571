import { v4 as uuidv4 } from 'uuid';
import { uniq, view, path, concat, equals, append, compose, lensProp, lensIndex, assocPath } from 'ramda';
import { getRelatedDefinition } from '../../schema/utils';
import { toAtomicDoc } from '../useAtomic';
const isAddOperation = equals('add');
const viewOpFirstElement = view(compose(lensIndex(0), lensProp('op')));
const firstElementIsAddOperation = compose(isAddOperation, 
// @ts-ignore
viewOpFirstElement);
// @ts-ignore
export const relate = (...relatedSequence) => (otherAtomicDocs) => {
    // @ts-ignore
    const atomicDocs = toAtomicDoc(...relatedSequence);
    function addRelatedIds(lid) {
        const identifier = atomicDocs[0].op === 'add'
            ? { lid, type: atomicDocs[0].data.type }
            : { id: atomicDocs[0].data.id, type: atomicDocs[0].data.type };
        return atomicDocs.map((doc, i) => {
            if (i === 0) {
                return lid
                    ? assocPath(['data', 'lid'], lid, doc)
                    : doc;
            }
            const { inverse } = getRelatedDefinition(atomicDocs[0].data.type, doc.data.type);
            const relationPath = ['data', 'relationships', inverse, 'data'];
            const addHasMany = compose(uniq, 
            // @ts-ignore
            append(identifier));
            const { type } = getRelatedDefinition(doc.data.type, atomicDocs[0].data.type);
            const relatedRecord = type === 'hasOne'
                ? identifier
                : addHasMany(path(relationPath, doc));
            return assocPath(relationPath, relatedRecord, doc);
        });
    }
    const result = addRelatedIds(firstElementIsAddOperation(atomicDocs)
        ? uuidv4()
        : undefined);
    return concat(otherAtomicDocs, result);
};
