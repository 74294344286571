var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { toRgbString } from '../utils';
import { Block } from '../Block';
import { fromTheme } from '../Theme';
export var StyledWidget = styled(Block)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: white;\n  box-shadow: 1px 1px 0 rgba(", ",0.4);\n  box-sizing: border-box;\n\n  ", "\n"], ["\n  background: white;\n  box-shadow: 1px 1px 0 rgba(", ",0.4);\n  box-sizing: border-box;\n\n  ", "\n"])), fromTheme(function (theme) { return toRgbString(theme.global.purposes.primary); }), function (_a) {
    var sticky = _a.sticky, _b = _a.stickyOffset, stickyOffset = _b === void 0 ? 0 : _b;
    return sticky && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    top: ", "px;\n    position: sticky;\n  "], ["\n    top: ", "px;\n    position: sticky;\n  "])), stickyOffset);
});
export var Widget = function (props) { return (_jsx(StyledWidget, __assign({ borderRadius: 3, p: 2 }, props), void 0)); };
Widget.displayName = 'Widget';
var templateObject_1, templateObject_2;
