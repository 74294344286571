var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import TextHighlighter from 'react-highlight-words';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
var _Columns = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding-left: ", "em;\n  ", ";\n  font-size: ", "em;\n  \n  &:hover, :focus {\n    background-color: ", ";\n  }\n  \n  ", "\n  \n  ", "\n  \n  ", "\n"], ["\n  display: flex;\n  padding-left: ", "em;\n  ", ";\n  font-size: ", "em;\n  \n  &:hover, :focus {\n    background-color: ", ";\n  }\n  \n  ", "\n  \n  ", "\n  \n  ", "\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), globalFont, fromTheme(function (theme) { return theme.global.sizes.base / 16 * 14; }), fromTheme(function (theme) { return theme.colors.lightGray; }), function (_a) {
    var maxWidth = _a.maxWidth;
    return maxWidth && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: ", "px;\n "], ["\n    max-width: ", "px;\n "])), maxWidth);
}, function (_a) {
    var flex = _a.flex;
    return flex && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    > * {\n       flex: ", ";\n    }\n  "], ["\n    > * {\n       flex: ", ";\n    }\n  "])), flex);
}, function (_a) {
    var width = _a.width;
    return width && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    > * {\n       width: ", ";\n    }\n  "], ["\n    > * {\n       width: ", ";\n    }\n  "])), typeof width === 'number' ? width + "px" : width);
});
var _Column = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  ", "\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  ", "\n  ", "\n"])), function (_a) {
    var flex = _a.flex;
    return flex && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["flex: ", ";"], ["flex: ", ";"])), flex);
}, function (_a) {
    var width = _a.width;
    return width && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["width: ", ";"], ["width: ", ";"])), typeof width === 'number' ? width + "px" : width);
});
var Column = function (_a) {
    var searchTerm = _a.searchTerm, children = _a.children, columnProps = __rest(_a, ["searchTerm", "children"]);
    var child = searchTerm && typeof children === 'string'
        ? _jsx(TextHighlighter, { autoEscape: true, searchWords: searchTerm, textToHighlight: children }, void 0)
        : children;
    return (_jsx(_Column, __assign({}, columnProps, { children: child }), void 0));
};
var Columns = _Columns;
Columns.Column = Column;
export { Columns };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
