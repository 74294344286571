import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Footer from '../../../../components/structural/Footer'
import Button from '../../../../components/atoms/Button'
import {
  configurationSelectors,
  configurationThunks
} from '../../../../domains/administration/state/configuration'

import { Sso } from './Sso'
import { UsernamePassword } from './UsernamePassword'

function useBootstrapConfiguration () {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(configurationThunks.bootstrapAppConfig())
  }, [])
}

export const Center = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

function Login () {
  useBootstrapConfiguration()

  const isSamlEnabled = useSelector(configurationSelectors.isSamlEnabled)
  const isLocalLoginEnabled = useSelector(configurationSelectors.isLocalLoginEnabled)

  const [ssoSelected, selectSso] = useState(true)

  const loginButton = isSamlEnabled && isLocalLoginEnabled
    ? (
      <div>
        <Button variation='text' onClick={() => selectSso(false)}>
          Login with password
        </Button>
      </div>
    )
    : null

  const button = isSamlEnabled && isLocalLoginEnabled && !ssoSelected
    ? (
      <div className='uk-margin-top'>
        <Button variation='text' onClick={() => selectSso(true)}>
          Back to SSO login
        </Button>
      </div>
    )
    : loginButton

  return (
    <Center>
      <div style={{ width: 420 }} className='uk-margin-top' data-test='loginscreen'>
        {isSamlEnabled && (ssoSelected || !isLocalLoginEnabled)
          ? <Sso loginWithPasswordButton={button} />
          : <UsernamePassword backToSSOButton={button} />
        }
      </div>
      <Footer />
    </Center>
  )
}

export default Login
