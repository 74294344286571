import React from 'react'
import { CrudRecord } from 'react-crud-hook'
import { Group, Label, Field, Select, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { MdLockOpen } from 'react-icons/md'
import { queries, useCacheQuery } from '@exivity/data-layer'

import { ServiceModel } from '../../../../data/types'
import { getRelationship } from '../../../../utils/records'

interface KeyProps {
  service: CrudRecord<ServiceModel>
}

function Key ({ service, ...rest }: KeyProps) {
  return (
    <Field data-testid='service-key' {...rest}>
      <Label>
        {translate('Key')}
      </Label>
      <Input
        required
        value={service.attributes.key || ''}
        placeholder={translate('Unique key for the service')}
        onChange={service.setAttribute('key')} />
    </Field>
  )
}

interface CategoryProps {
  service: CrudRecord<ServiceModel>
  disabled: boolean
}

function Category ({ service, disabled }: CategoryProps) {
  const serviceCategories = useCacheQuery(
    queries
      .findAll('servicecategory')
      .sortByAttribute('name', 'ascending')
  )

  const { id } = getRelationship(service, 'servicecategory', { id: undefined })

  return (
    <Field data-testid='service-category'>
      <Label>
        {translate('Category')}
      </Label>
      <Select
        required
        searchable
        disabled={disabled}
        placeholder={translate('Choose category')}
        value={
          id
            ? serviceCategories.find(category => category.id === id)
            : undefined
        }
        data={serviceCategories}
        labelAccessor={(category) => category.attributes.name}
        onChange={service.addHasOne('servicecategory')} />
    </Field>
  )
}

interface ServiceGroupProps {
  service: CrudRecord<ServiceModel>
  editing?: boolean
}

export default function ServiceGroup ({ service, editing = true }: ServiceGroupProps) {
  return (
    <Group title={translate('Service')} Icon={MdLockOpen}>
      <Field.Container>
        {!service.id && <Key service={service} />}
        <Category service={service} disabled={!editing} />
      </Field.Container>
    </Group>
  )
}
