import React from 'react'
import { Field, Input, Label, Select } from '@exivity/ui'
import { prop, invoker } from '@exivity/fp'
import { translate } from '@exivity/translations'

import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

const getLabel = invoker(0, 'label')

export const coreKeys = [
  { value: 'exivity:gc', label: () => translate('Run garbage collector') },
  { value: 'exivity:purge-cache', label: () => translate('Purge cache') },
  { value: 'exivity:send-heartbeat', label: () => translate('Send heartbeat') }
]

function CoreHeader ({ options }: HeaderProps) {
  return (
    <>
      {options.command
        ? coreKeys.find(({ value }) => value === options.command)?.label() ?? ''
        : ''
      }
    </>
  )
}

function CoreOptions ({ options, updater }: OptionsProps) {
  return (
    <>
      <Field>
        <Label>{translate('Command')}</Label>
        <Select
          required
          disabled
          data={coreKeys}
          labelAccessor={getLabel}
          valueAccessor={prop('value')}
          placeholder={translate('Command')}
          value={options.command || ''}
          onChange={updater.setProperty('command')} />
      </Field>
      <Field>
        <Label>{translate('Arguments')}</Label>
        <Input
          required
          disabled
          placeholder={translate('Arguments')}
          value={options.arguments || ''}
          onChange={updater.setProperty('arguments')} />
      </Field>
    </>
  )
}

export const Core = {
  Header: CoreHeader,
  Options: CoreOptions
} as WorkflowStepVariation
