const SEPARATOR = ':'

function getStorage (persistent: boolean) {
  // eslint-disable-next-line glass/no-direct-web-storage
  return persistent ? localStorage : sessionStorage
}

function cleanStorage (persistent: boolean) {
  const storage = getStorage((persistent))

  function forEachKey (callback: (key: string|null) => void) {
    for (let i = 0; i < storage.length; i++) {
      callback(storage.key(i))
    }
  }

  forEachKey((key) => {
    if (key && mustBeCleaned.some((clean) => key.includes(clean))) {
      storage.removeItem(key)
    }
  })
}

function createStorage <K extends string> (name: string) {
  return {
    getStorage (persistent: boolean) {
      // clean opposite
      cleanStorage(!persistent)

      return {
        setItem (key: K, state: Record<string, any> | string | boolean) {
          getStorage(persistent).setItem(name + SEPARATOR + key, JSON.stringify(state))
        },
        getItem (key: K) {
          const serializedState = getStorage(persistent).getItem(name + SEPARATOR + key)

          return serializedState ? JSON.parse(serializedState) : null
        },
        clear () {
          getStorage(persistent).clear()
        }
      }
    },
    fromEitherStorage (key: K) {
      // Try loading state from localStorage, with a fallback to sessionStorage.
      let serializedState: string | null = getStorage(true).getItem(name + SEPARATOR + key)

      if (serializedState === null) {
        serializedState = getStorage(false).getItem(name + SEPARATOR + key)

        if (serializedState === null) {
          return undefined
        }
      }

      try {
        return JSON.parse(serializedState)
      } catch (e) {
        return serializedState
      }
    }
  }
}

const mustBeCleaned = ['state', 'session']
export const getStateStorage = createStorage('state')
export const getUserSessionStorage = createStorage<'session'>('session')
const supportStorage = createStorage('viewUnsupportedBrowser').getStorage(true)

export const viewUnsupportedBrowser = () => supportStorage.getItem('viewUnsupported') ?? false
export const proceedToUnsupportedBrowser = () => supportStorage.setItem('viewUnsupported', true)
