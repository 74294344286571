import React from 'react'
import { Group, Field, Input, Label, Tooltip, Button } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { MdReplay } from 'react-icons/md'

import { UserGroupSamlProvisioning, USE_DEFAULT_SAML_ATTRIBUTE } from '../../../../../data/types'
import { useSamlConfig } from '../../../../saml/utils/configuration'

const DEFAULT_PROVISIONING: UserGroupSamlProvisioning = {
  attribute: USE_DEFAULT_SAML_ATTRIBUTE,
  value: ''
}

interface SamlProvisioningTabProps {
  provisioning: UserGroupSamlProvisioning | null
  onChangeProvisioning: (provisioning: UserGroupSamlProvisioning | null) => void
}

export function SamlProvisioningTab ({
  provisioning,
  onChangeProvisioning
}: SamlProvisioningTabProps) {
  const samlConfig = useSamlConfig()

  function handleChangeProvisioning<K extends keyof UserGroupSamlProvisioning> (
    key: K,
    value: UserGroupSamlProvisioning[K]
  ) {
    const newProvisioning = {
      ...(provisioning || DEFAULT_PROVISIONING),
      [key]: value
    }

    if (!newProvisioning.attribute && !newProvisioning.value) {
      onChangeProvisioning(null)
    } else {
      onChangeProvisioning(newProvisioning)
    }
  }

  return (
    <Group title={translate('Saml Provisioning')}>
      <Field.Container>
        <Field id='saml-attribute'>
          <Label.Group>
            <Label>{translate('SAML attribute')}</Label>
            <Label.Sub>
              {translate('This user group will be provisioned to a user if this attribute on the the SAML response matches the SAML value field.')}
            </Label.Sub>
          </Label.Group>
          <Field.Group>
            <Input
              placeholder={translate('SAML attribute')}
              value={provisioning?.attribute || samlConfig.SAML_ATTRIBUTE_USERGROUP || ''}
              onChange={(value) => handleChangeProvisioning('attribute', value)} />
            {!!samlConfig.SAML_ATTRIBUTE_USERGROUP && (
              <Tooltip
                content={
                  provisioning?.attribute !== USE_DEFAULT_SAML_ATTRIBUTE
                    ? translate('Reset to default: `{attribute}`', samlConfig.SAML_ATTRIBUTE_USERGROUP)
                    : ''
                }>
                <Button.Cancel small round
                  data-testid='reset-saml-attribute-button'
                  disabled={provisioning?.attribute === USE_DEFAULT_SAML_ATTRIBUTE}
                  icon={<MdReplay />}
                  onClick={() => {
                    handleChangeProvisioning('attribute', USE_DEFAULT_SAML_ATTRIBUTE)
                  }} />
              </Tooltip>
            )}
          </Field.Group>
        </Field>
        <Field id='saml-value'>
          <Label.Group>
            <Label>{translate('SAML value')}</Label>
          </Label.Group>
          <Input
            placeholder={translate('SAML value')}
            value={provisioning?.value || ''}
            onChange={(value) => handleChangeProvisioning('value', value)} />
        </Field>
      </Field.Container>
    </Group>
  )
}
