import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../../../reducers'
import { configurationSelectors } from '../../../../domains/administration/state/configuration'
import {
  UsernamePassword as UsernamePasswordForm
} from '../../components/LoginForm/UsernamePassword'
import { useAppState } from '../../components/useAppState'
import { useDomainActions } from '../../components/useDomainActions'
import { authActions, authThunks } from '../../state'

interface NamePassProps {
  backToSSOButton: React.ReactElement|null
}

export function UsernamePassword ({ backToSSOButton }: NamePassProps) {
  const dispatch = useDispatch()
  const abort = useRef<() => void>()
  const { apiRoot, isLoading } = useAppState()
  const resetPasswordSupported = useSelector<RootState, boolean>(
    state => !!state.system.flags.SUPPORTS_SENDING_MAIL
  )
  const persistentAllowed = useSelector(configurationSelectors.arePersistentTokensAllowed)
  const persistenceInterval = useSelector(configurationSelectors.getTokenTTL)
  const { updateDomain, onDomainChanged } = useDomainActions()

  function setPersistentSession (persistent: boolean) {
    dispatch(authActions.updatePersistent(persistent))
  }

  function onAbortController (abortController: AbortController) {
    abort.current = abortController.abort.bind(abortController)
  }

  function handleSubmit (username: string, password: string, apiRoot: string) {
    updateDomain(apiRoot)
    dispatch(authThunks.loginWithCredentials({ username, password, onAbortController }))
  }

  return (
    <UsernamePasswordForm
      apiRoot={apiRoot}
      persistentAllowed={persistentAllowed}
      persistenceInterval={persistenceInterval}
      setPersistentSession={setPersistentSession}
      resetPasswordSupported={resetPasswordSupported}
      isLoading={isLoading}
      backToSSOButton={backToSSOButton}
      onCancelLogin={abort.current}
      onSubmit={handleSubmit}
      onDomainChanged={onDomainChanged} />
  )
}
