import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { HotKeys } from 'react-hotkeys'
import omit from 'lodash/omit'

const withHotKeys = (keyMap, hotKeysProps) => (WrappedComponent) => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'test') {
    return WrappedComponent
  }

  class HotKeysWrapper extends Component {
    constructor (props) {
      super(props)

      this.state = { handlers: {} }
    }

    componentDidMount () {
      this.setState({ handlers: this._ref.hotKeyHandlers })
    }

    _setRef = (node) => {
      this._ref = node
    }

    render () {
      const { handlers } = this.state
      const { focused, attach } = this.props

      return (
        <HotKeys component='div'
          {...hotKeysProps}
          keyMap={keyMap}
          handlers={handlers}
          focused={focused}
          attach={attach}>
          <WrappedComponent
            ref={this._setRef}
            {...omit(this.props, [focused, attach])} />
        </HotKeys>
      )
    }
  }

  HotKeysWrapper.propTypes = {
    attach: PropTypes.instanceOf(Element),
    focused: PropTypes.bool
  }

  return HotKeysWrapper
}

export default withHotKeys
