import React, { createContext, useEffect, useMemo } from 'react'
import { DataProvider as NativeData } from 'react-orbitjs'
import { DataLayerProvider, AtomicContext } from '@exivity/data-layer'
import { useSelector } from 'react-redux'

import { memory } from '../../../data/sources'
import { authSelectors } from '../../../domains/auth/state'
import { atomicFetch } from '../../../API'

export const QueryContext = createContext(memory)

type DataLayerProps = {
  children: React.ReactNode
}

export function DataProvider ({ children }: DataLayerProps) {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const userPermissions = useSelector(authSelectors.getUserPermissions)

  useEffect(() => {
    if (!isAuthenticated) {
      memory.cache.reset()
    }
  }, [isAuthenticated])

  return (
    <NativeData dataStore={memory}>
      <DataLayerProvider store={memory} userPermissions={userPermissions}>
        <QueryContext.Provider value={memory}>
          <AtomicContext.Provider
            value={useMemo(() => atomicFetch(memory), [])}>
            {children}
          </AtomicContext.Provider>
        </QueryContext.Provider>
      </DataLayerProvider>
    </NativeData>
  )
}
