import { insertIf } from '../../../utils/misc'
import auth from '../../../utils/auth'
import { UserGroupPermission } from '../../../data/types'

export const summarizeReport = (report) => {
  if (!report) {
    return null
  }

  const permission = auth.can(UserGroupPermission.ViewCogs)

  const cogsAndNet = {
    cogs: 0,
    net: 0
  }

  const summary = {
    charge: 0,
    ...insertIf(permission, cogsAndNet)
  }

  return report.reduce((sum, item) => {
    sum.charge += item.total_charge
    if (permission) {
      sum.cogs += item.total_cogs
      sum.net += item.total_net
    }
    return sum
  }, summary)
}

export const summarizeSearched = (data) => {
  if (!data) {
    return []
  }

  const permission = auth.can(UserGroupPermission.ViewCogs)

  const cogsAndNet = {
    cogs: 0,
    net: 0
  }

  const summary = {
    charge: 0,
    ...insertIf(permission, cogsAndNet)
  }

  return data.reduce((sum, item) => {
    sum.charge += item.charge
    if (permission) {
      sum.cogs += item.cogs
      sum.net += item.net
    }
    return sum
  }, summary)
}
