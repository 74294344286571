import { useState, useEffect } from 'react';
export function useMediaQuery(query, listener) {
    var _a = useState(false), matches = _a[0], setMatches = _a[1];
    useEffect(function () {
        if (!window || !window.matchMedia) {
            return;
        }
        var media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        var _listener = function () {
            setMatches(media.matches);
            listener && listener(media.matches);
        };
        media.addEventListener('change', _listener);
        return function () {
            if (!window || !window.matchMedia) {
                return;
            }
            media.removeEventListener('change', _listener);
        };
    }, [matches, query, listener]);
    return matches;
}
