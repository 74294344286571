import React from 'react'
import { Provider } from 'react-redux'

import store from '../../../store'

export function StateProvider ({ children }: any) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}
