import { format, parse } from 'date-fns'

import { MetadataModel, MetadataValue } from '../../data/types'

export const METADATA_COLUMN_PREFIX = 'metadata_'

export function spreadMetadataValues (metadataValue: MetadataValue = {}) {
  return Object.entries(metadataValue).reduce((formattedValue, [key, val]) => ({
    ...formattedValue,
    [METADATA_COLUMN_PREFIX + key]: val
  }), {})
}

export function getMetadataValues<T extends { name: string; type: string; list?: string[] }> (
  metadatas: MetadataModel[],
  fields: T[],
  dateFormat: string
) {
  const values = metadatas
    .reduce((acc, { attributes }) => ({ ...acc, ...attributes.values }), {} as MetadataValue)

  return fields
    .map((field) => {
      let value = values[field.name] || null

      if (value && field.type === 'date') {
        value = format(parse(value, 'yyyy-MM-dd', new Date()), dateFormat)
      }

      return {
        [field.name]: value || '(undefined)'
      }
    })
    .reduce((values, field) => ({
      ...values,
      ...field
    }), {}) as MetadataValue
}
