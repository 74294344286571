import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import Table, { Column, SortIndicator } from 'react-virtualized/dist/commonjs/Table'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

import withHotKeys from '../../hocs/HotKeys/withHotKeys'
import Filter from '../../atoms/Filter'

import { classNames } from './../../../utils/misc'
import { GroupHeader } from './GroupHeader'
import Cell from './Cell'
import normalizeFn from './normalize'
import propTypes from './propTypes'

/*
* Please let me be clear, when editing the code below the entire app might become unstable and you
* will be responsible for fixing it right away or risk eternal flame wars. It will feel exactly
* like back in 1998, when The Undertaker threw Mankind off Hell In A Cell, and plummeted 16 ft
* through a Virtual announcer’s Table.
*/

const HEADER_HEIGHT = 50
const ROW_HEIGHT = 27
const TOOLBAR_HEIGHT = 50
const GRID_PADDING = 20
const GRID_PADDING_WITH_TOOLBAR = 10
const HEADER_ROW_HEIGHT = 50
const MIN_SEARCH_CHARS = 2

function totalHeight (data) {
  return data.length * ROW_HEIGHT
}

function headerRowHeight () {
  return HEADER_ROW_HEIGHT
}

function isRootDepth (item) {
  return item.meta.depth === 0
}

function areAllParentsExpanded (item) {
  if (item.parent) {
    return item.parent.meta.expanded && areAllParentsExpanded(item.parent)
  }

  return true
}

function isItemOrAnyParentChecked (item) {
  if (item.meta.checked === false && item.parent) {
    return isItemOrAnyParentChecked(item.parent)
  }

  return item.meta.checked
}

export function iterateAllParents (item, callback) {
  if (item.parent) {
    callback(item.parent)
    iterateAllParents(item.parent, callback)
  }
}

export function iterateAllChildren (item, callback) {
  if (item.children) {
    item.children.map(child => {
      callback(child)
      return iterateAllChildren(child, callback)
    })
  }
}

// eslint-disable-next-line no-unused-vars
function expandAllParents (item) {
  iterateAllParents(item, parent => {
    parent.meta.expanded = true
  })
}

function searchTermMatches (item, column, term) {
  if (typeof item.attributes[column] === 'string') {
    return item.attributes[column].toUpperCase().indexOf(term.toUpperCase()) > -1
  }

  if (typeof item.meta.origValue === 'string') {
    return item.meta.origValue.toUpperCase().indexOf(term.toUpperCase()) > -1
  }

  return false
}

const KEY_MAP = {
  previous: 'up',
  next: 'down',
  select: 'enter',
  expand: 'right',
  collapse: 'left'
}

const hotKeysProps = {
  style: {
    height: '100%',
    width: '100%'
  }
}

class VirtualTable extends Component {
  table

  constructor (props) {
    super(props)

    const normalized = this.normalize(
      props.data,
      props.groupBy,
      props.sortBy,
      props.expanded,
      props.onNormalize
    )
    props.autoExpandSelected && this.autoExpandSelected(normalized, props.selected)
    const filtered = this.filterVisibleRows(normalized)
    const list = this.addToolbar(props.toolbar, filtered)
    const { selected, checked = [] } = props

    const found = normalized.find(item => String(item.key) === String(selected))

    found && expandAllParents(found)

    this.state = {
      selected,
      checked, // Used with checkboxes
      normalized,
      filtered,
      filters: {},
      list
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    let normalized = this.state.normalized
    let dataNeedsUpdate = false

    // Properties groupBy or data have changed
    if (
      nextProps.groupBy !== this.props.groupBy
      || (this.props.pure && nextProps.data !== this.props.data)
      || (!this.props.pure && !isEqual(nextProps.data, this.props.data))
    ) {
      normalized = this.normalize(
        nextProps.data,
        nextProps.groupBy,
        nextProps.sortBy,
        nextProps.expanded,
        nextProps.onNormalize
      )
      dataNeedsUpdate = true

      this.setState({ normalized })
    }

    // Selected has changed, and we need to expand it
    if (nextProps.autoExpandSelected && nextProps.selected) {
      this.autoExpandSelected(normalized, nextProps.selected)
      dataNeedsUpdate = true
    }

    // Data has changed, filter and add toolbar now
    if (dataNeedsUpdate === true) {
      const filtered = this.filterVisibleRows(normalized)
      const list = this.addToolbar(nextProps.toolbar, filtered)

      this.setState({
        filtered,
        list
      })
    }

    // Update state.selected
    if (nextProps.selected !== this.props.selected) {
      this.setState({ selected: nextProps.selected })
    }

    if (nextProps.checked !== this.props.checked) {
      this.setState({ checked: nextProps.checked })
    }
  }

  autoExpandSelected = (data, selected) => {
    if (!selected) {
      return
    }

    const selectedItem = data.find(item => String(item.key) === String(selected))

    if (!selectedItem) {
      return
    }

    expandAllParents(selectedItem)
  }

  normalize = (data, groupBy, sortBy, expanded, cb) => {
    const normalized = normalizeFn(cloneDeep(data), groupBy, sortBy, expanded)
    cb && cb(normalized)
    return normalized
  }

  filterVisibleRows = data => {
    const { filters } = this.state || { filters: {} }
    let items = data

    // Determine if we should search at all
    const willSearch = Object.keys(filters).length
      && Object.keys(filters).filter(key => filters[key].length > (MIN_SEARCH_CHARS - 1)).length

    // Reset all search hit flags
    items.forEach(item => {
      item.meta.searchHit = undefined
      item.meta.searchHitParent = undefined
    })

    // Filter on search columns
    if (willSearch) {
      // Loop over all defined filters
      Object.keys(filters).forEach(searchColumn => {
        const searchTerm = filters[searchColumn]

        // Only filter if search term is more than 1 character
        if (searchTerm.length > (MIN_SEARCH_CHARS - 1)) {
          items.forEach(item => {
            if (searchTermMatches(item, searchColumn, searchTerm)) {
              item.meta.searchHit = true
              iterateAllParents(item, parent => {
                parent.meta.searchHitParent = true
              })
            }
          })
        }
      })
    }

    if (willSearch && this.props.filterSearches) {
      // Filter items if filterSearches is true
      items = items.filter(item => item.meta.searchHit || item.meta.searchHitParent)

      // Add placeholder if no results
      if (!items.length) {
        items.push({
          key: 'placeholder',
          placeholder: this.props.noSearchResultText,
          meta: {}
        })
      }
    } else {
      // Filter expanded items
      items = items.filter(item => isRootDepth(item) || areAllParentsExpanded(item))
    }

    return items
  }

  addToolbar = (toolbar, data) => {
    if (toolbar) {
      data.unshift({
        key: 'toolbar',
        toolbar: true,
        meta: {}
      })
    }

    return data
  }

  selectItem = (rowData, autoCheckChildren) => {
    this.setState({ selected: rowData.key })

    rowData.key !== 'placeholder'
    && this.props.onClick
    && this.props.onClick(rowData)

    if (this.props.useOnClickToCheck) {
      rowData.meta.checked = !rowData.meta.checked
      this.onChecked(rowData, autoCheckChildren)
    }
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style
  }) => {
    const a11yProps = {}

    if (
      onRowClick
      || onRowDoubleClick
      || onRowMouseOut
      || onRowMouseOver
      || onRowRightClick
    ) {
      a11yProps['aria-label'] = 'row'
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = event => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = event => onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = event => onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = event => onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = event => onRowRightClick({ event, index, rowData })
      }
    }

    const isGroup = !!rowData.group
    const isPlaceholder = !!rowData.placeholder
    const isSearchHit = !!rowData.meta.searchHit
    const isSearchHitParent = !!rowData.meta.searchHitParent
    const isToolbar = !!rowData.toolbar
    const isLastChild = rowData.parent
      && rowData.parent.children[rowData.parent.children.length - 1].key === rowData.key

    let row

    if (isGroup) {
      row = (
        <div role='gridcell' className='ReactVirtualized__Table__rowColumn'>
          <GroupHeader
            key={rowData.group}
            editable={this.props.editGroupHeaders}
            onSave={(newValue) => {
              this.props.onSaveGroupHeader(
                newValue,
                this.state.list.find(({ key }) => key === rowData.key)
              )
            }}>
            {rowData.group}
          </GroupHeader>
        </div>
      )
    } else if (isPlaceholder) {
      row = (
        <div role='gridcell'
          className='ReactVirtualized__Table__rowColumn'>{rowData.placeholder}
        </div>
      )
    } else if (isToolbar) {
      row = this.props.toolbar
    } else {
      row = columns
    }

    const DragHandle = SortableHandle(() => (
      <span className='uk-margin-right'
        style={{ cursor: 'move' }}>☰
      </span>
    ))

    const SortableItem = SortableElement(({ children }) => (
      <div
        className={classNames({
          'ex-advanced-select__toolbar': isToolbar,
          'ex-advanced-select__group': isGroup,
          'ex-advanced-select__placeholder': isPlaceholder,
          'ex-advanced-select__row--last-child': isLastChild,
          'ex-advanced-select__row--active': rowData.key === this.state.selected,
          'ex-advanced-select__row--search-hit': isSearchHit,
          'ex-advanced-select__row--search-hit-parent': isSearchHitParent
        }, 'ex-advanced-select__sortable--dragged', className)}
        style={style}
        role='row'
        tabIndex={isGroup.toString() ? 0 : -1}
        onClick={() => {
          !isToolbar && !isGroup
              && this.selectItem(rowData, this.props.autoCheckChildren)
        }}>
        {children}
        <DragHandle />
      </div>
    ))

    if (this.props.sortable) {
      return (
        <SortableItem key={index} style={style} index={index}>
          {row}
        </SortableItem>
      )
    }

    return (
      <div
        {...a11yProps}
        key={key}
        className={classNames({
          'ex-advanced-select__toolbar': isToolbar,
          'ex-advanced-select__group': isGroup,
          'ex-advanced-select__placeholder': isPlaceholder,
          'ex-advanced-select__row--last-child': isLastChild,
          'ex-advanced-select__row--active': rowData.key === this.state.selected,
          'ex-advanced-select__row--search-hit': isSearchHit,
          'ex-advanced-select__row--search-hit-parent': isSearchHitParent
        }, className)}
        id={rowData.id}
        role='row'
        style={style}
        tabIndex={isGroup.toString()
          ? 0
          : -1}
        onClick={() => {
          !isToolbar && !isGroup
        && this.selectItem(rowData, this.props.autoCheckChildren)
        }}>
        {row}
      </div>
    )
  }

  headerRenderer = ({
    columnData,
    dataKey,
    label,
    sortBy,
    sortDirection
  }) => {
    const showSortIndicator = (sortBy && dataKey) && sortBy === dataKey

    const children = []

    if (columnData.searchable) {
      children.push(<Filter key='search'
        placeholder={label}
        onFilterChange={event => {
          // Save search term in components state
          const { filters } = this.state
          filters[dataKey] = event.target.value
          this.setState({ filters })

          // Filter items with new state
          const filtered = this.filterVisibleRows(this.state.normalized)
          const list = this.addToolbar(this.props.toolbar, filtered)

          // Save filtered items in components state
          this.setState({
            filtered,
            list
          })
        }} />)
    } else {
      children.push(
        <span
          key='label'
          className='ReactVirtualized__Table__headerTruncatedText'
          title={label}>
          {label}
        </span>
      )
    }

    if (showSortIndicator) {
      children.push(
        <SortIndicator key='SortIndicator' sortDirection={sortDirection} />
      )
    }

    return children
  }

  cellRenderer = ({
    columnData,
    dataKey,
    rowData
  }) => {
    // Get searchTerm for this column, if any
    const { filters, checked } = this.state
    const term = filters[dataKey] && filters[dataKey].length > (MIN_SEARCH_CHARS - 1)
      ? filters[dataKey]
      : undefined

    rowData.meta.checked = checked.includes(rowData.key)

    if (rowData.meta.checked === false && this.props.autoCheckChildren) {
      rowData.meta.checked = isItemOrAnyParentChecked(rowData)
    }

    return (
      <Cell item={rowData}
        field={dataKey}
        expanded={!!this.props.expanded}
        grouped={!!this.props.groupBy}
        searchTerm={term}
        checked={!!rowData.meta.checked}
        muted={!!rowData.meta.muted}
        useOnClickToCheck={this.props.useOnClickToCheck}
        autoCheckChildren={this.props.autoCheckChildren}
        cellFull={this.props.cellFull}
        onExpandOrCollapse={this.onExpandOrCollapse}
        onChecked={this.onChecked}
        {...columnData} />
    )
  }

  toggleCheckbox = (item, autoCheckChildren) => {
    const { checked } = this.state

    const newChecked = item.meta.checked
      ? checked.concat(item.key)
      : checked.filter(key => item.key !== key)

    this.setState({ checked: newChecked }, () => {
      this.props.onChecked(
        this.state.checked,
        { key: item.key, checked: !!item.meta.checked }
      )
    })

    if (autoCheckChildren) {
      iterateAllChildren(item, child => {
        !checked.includes(child.key)
        && (child.meta.checked = item.meta.checked)
      })
    }
  }

  onChecked = (item, autoCheckChildren) => {
    this.props.onChange && this.props.onChange(item.key)
    this.toggleCheckbox(item, autoCheckChildren)

    this.table.forceUpdateGrid()
  }

  onExpandOrCollapse = () => {
    const filtered = this.filterVisibleRows(this.state.normalized)
    const list = this.addToolbar(this.props.toolbar, filtered)

    this.setState({
      filtered,
      list
    })
  }

  forceUpdateGrid = () => this.table.forceUpdateGrid()

  hotKeyHandlers = {
    previous: (event) => this.previous(event),
    next: (event) => this.next(event),
    select: (event) => this.select(event),
    expand: (event) => this.expand(event, true),
    collapse: (event) => this.expand(event, false)
  }

  next = (event) => {
    event.preventDefault()
    let next = event.currentTarget.querySelector(':focus').nextSibling
    if (next) {
      if (next.getAttribute('tabindex') === -1) {
        next = next.nextSibling
      }
      next && next.focus()
    }
  }

  previous = (event) => {
    event.preventDefault()
    let previous = event.currentTarget.querySelector(':focus').previousSibling
    if (previous) {
      if (previous.getAttribute('tabindex') === -1) {
        previous = previous.previousSibling
      }
      previous && previous.focus()
    }
  }

  select = (event) => {
    event.preventDefault()
    event.currentTarget.querySelector(':focus').click()
  }

  expand = (event, expand) => {
    event.stopPropagation()
    let expandable = false

    React.Children.forEach(this.props.children, column => {
      if (column.props.expandable) {
        expandable = true
      }
    })

    if (expandable) {
      const item = this.state.list.find(item => item.id === event.target.id)

      if (item && item.children.length > 0) {
        item.meta.expanded = expand
        this.onExpandOrCollapse(item)
      }
    }
  }

  getHeight (height, calculatedHeight) {
    const { header } = this.props
    if (calculatedHeight === 'auto') {
      return header ? height - HEADER_HEIGHT : height
    }
    return calculatedHeight
  }

  render () {
    const { height, maxHeight, children, noHeaders, toolbar } = this.props
    const { list } = this.state

    let _height = height
    if (_height === 'exact') {
      let _totalHeightPlusHeaderAndToolbar = totalHeight(list) + headerRowHeight()
      if (toolbar) {
        _totalHeightPlusHeaderAndToolbar += TOOLBAR_HEIGHT - ROW_HEIGHT + GRID_PADDING_WITH_TOOLBAR
      } else {
        _totalHeightPlusHeaderAndToolbar += GRID_PADDING
      }
      _height
        = maxHeight
          ? Math.min(_totalHeightPlusHeaderAndToolbar, maxHeight)
          : _totalHeightPlusHeaderAndToolbar
    }

    return (
      <AutoSizer disableHeight={_height !== 'auto'}>
        {({ height, width }) => (
          <Table
            ref={ref => (this.table = ref)}
            height={this.getHeight(height, _height)}
            disableHeader={noHeaders}
            headerHeight={headerRowHeight()}
            headerClassName='ex-advanced-select__row--header'
            width={width}
            rowClassName='ex-advanced-select__row'
            rowGetter={({ index }) => list[index]}
            rowHeight={({ index }) => index === 0 && toolbar ? TOOLBAR_HEIGHT : ROW_HEIGHT}
            rowCount={list.length}
            rowRenderer={this.rowRenderer}
            tabIndex={-1}>
            {React.Children.map(children, column => {
              if (!column) {
                return null
              }

              const {
                field,
                label,
                width,
                flexGrow,
                expandable,
                searchable,
                filterable,
                checkable,
                className,
                headerClassName,
                cellRenderer
              } = column.props

              return (
                <Column dataKey={field}
                  label={label}
                  width={width}
                  flexGrow={flexGrow}
                  className={className}
                  headerClassName={headerClassName}
                  columnData={{
                    expandable,
                    searchable,
                    filterable,
                    checkable,
                    cellRenderer
                  }}
                  headerRenderer={this.headerRenderer}
                  cellRenderer={this.cellRenderer} />
              )
            })}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

VirtualTable.propTypes = { ...propTypes }

export default withHotKeys(KEY_MAP, hotKeysProps)(VirtualTable)
