import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'

import { NavigationTabs } from './NavigationTabs'

const Users = lazy(() => import(
  /* webpackChunkName: 'administration.usermanagement' */
  '../domains/users/pages/Users'
))

const Groups = lazy(() => import(
  /* webpackChunkName: 'administration.usermanagement' */
  '../domains/users/pages/UserGroups'
))

// const Interface = lazy(() => import(
//   /* webpackChunkName: 'administration.interface' */
//   '../domains/administration/pages/Settings/Interface'
// ))

const Settings = lazy(() => import(
  /* webpackChunkName: 'administration.configuration' */ '../components/pages/Administration/index')
)

const AuditTrail = lazy(() => import(
  /* webpackChunkName: 'administration.audittrail' */
  '../domains/administration/pages'
))

const LogViewer = lazy(() => import(
  /* webpackChunkName: 'administration.logviewer' */ '../components/pages/Administration/LogViewer')
)

export const administration = {
  path: '/administration',
  routes: {
    // newSettings: {
    //   path: '/newsettings',
    //   requiresPermissions: [UserGroupPermission.ManageSettings],
    //   meta: {
    //     title: () => translate('Settings'),
    //     documentation: 'https://docs.exivity.com/administration/settings/'
    //   },
    //   component: NavigationTabs,
    //   routes: {
    //     user: {
    //       path: '/interface',
    //       meta: {
    //         title: () => translate('Interface')
    //       },
    //       component: Interface
    //     }
    //   }
    // },
    settings: {
      path: '/settings/:category?/:id?',
      requiresPermissions: [UserGroupPermission.ManageSettings],
      meta: {
        title: () => translate('Settings'),
        documentation: 'https://docs.exivity.com/administration/settings/'
      },
      component: Settings
    },
    userManagement: {
      path: '/user-management',
      requiresPermissions: [UserGroupPermission.ManageUsers],
      meta: {
        title: () => translate('User management'),
        documentation: 'https://docs.exivity.com/administration/user-management/'
      },
      component: NavigationTabs,
      routes: {
        user: {
          path: '/users/:id?/:entity(profile|notifications|channels)?/:notification_id?',
          meta: {
            title: () => translate('Users')
          },
          component: Users
        },
        usergroup: {
          path: '/usergroups/:id?',
          meta: {
            title: () => translate('Groups')
          },
          component: Groups
        }
      }
    },
    auditTrail: {
      path: '/audittrail',
      requiresPermissions: [UserGroupPermission.ViewAudit],
      meta: {
        title: () => translate('Audit trail')
      },
      component: AuditTrail
    },
    logViewer: {
      path: '/logviewer',
      requiresPermissions: [UserGroupPermission.ViewLogs],
      meta: {
        title: () => translate('Log viewer')
      },
      component: LogViewer
    }
  }
}
