var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useLayoutEffect, createContext } from 'react';
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { themePriority, themeColor, useIsSmallScreen } from '../Theme';
import { classNames, globalFont } from '../utils';
import { NavLink } from './NavLink';
import { NavFilter } from './NavFilter';
import { NavGroup } from './NavGroup';
import { Separator } from './Separator';
import { Footer } from './Footer';
import { animateNavElements, getCoverHeight, openGroup } from './animations';
import { horizontalAnimations } from './horizontalAnimations';
var Nav = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 60px;\n  left: -200px;\n  width: 270px;\n  height: calc(100vh - 60px);\n  z-index: ", ";\n  overflow-y: auto;\n  overflow-x: hidden;\n  ", ";\n  ", ";\n  \n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  \n  ::-webkit-scrollbar { width: 8px; height: 3px;}\n  ::-webkit-scrollbar-button {  background-color: #666; }\n  ::-webkit-scrollbar-track {  background-color: #646464;}\n  ::-webkit-scrollbar-track-piece { background-color: #000;}\n  ::-webkit-scrollbar-thumb { height: 5px; background-color: #666;}\n  ::-webkit-scrollbar-corner { background-color: #646464;}}\n  ::-webkit-resizer { background-color: #666;}\n"], ["\n  position: fixed;\n  top: 60px;\n  left: -200px;\n  width: 270px;\n  height: calc(100vh - 60px);\n  z-index: ", ";\n  overflow-y: auto;\n  overflow-x: hidden;\n  ", ";\n  ", ";\n  \n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  \n  ::-webkit-scrollbar { width: 8px; height: 3px;}\n  ::-webkit-scrollbar-button {  background-color: #666; }\n  ::-webkit-scrollbar-track {  background-color: #646464;}\n  ::-webkit-scrollbar-track-piece { background-color: #000;}\n  ::-webkit-scrollbar-thumb { height: 5px; background-color: #666;}\n  ::-webkit-scrollbar-corner { background-color: #646464;}}\n  ::-webkit-resizer { background-color: #666;}\n"])), themePriority(3), globalFont, horizontalAnimations, themeColor('dark'));
var Cover = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", "px;\n  background-color: ", ";\n"], ["\n  height: ", "px;\n  background-color: ", ";\n"])), prop('height'), themeColor('dark'));
var StyledNavList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  width: 270px;\n"], ["\n  margin: 0;\n  padding: 0;\n  width: 270px;\n"])));
export var NavGroupContext = createContext({
    isOpen: false,
    onClick: function () { }
});
function NavBar(_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.collapsed, collapsed = _c === void 0 ? false : _c, onCollapse = _a.onCollapse, footer = _a.footer, _d = _a.progress, progress = _d === void 0 ? 0 : _d, progressIsActive = _a.progressIsActive, progressText = _a.progressText, children = _a.children;
    var _e = useState(null), openAtIndex = _e[0], setOpenAtIndex = _e[1];
    var _f = useState(collapsed), internalCollapsed = _f[0], setInternalCollapsed = _f[1];
    var _g = useState(null), element = _g[0], setElement = _g[1];
    var isSmallScreen = useIsSmallScreen(function (isSmall) {
        element && !collapsed && animateNavElements(element, isSmall
            ? null
            : openAtIndex);
    });
    var isCollapsed = isSmallScreen || collapsed;
    useEffect(function () {
        setInternalCollapsed(isCollapsed);
    }, [isCollapsed]);
    useLayoutEffect(function () {
        if (element && openAtIndex && !internalCollapsed) {
            openGroup(element, openAtIndex);
        }
    });
    var expandSideBarClassName = classNames({
        'nav__sidebar--expanded': !internalCollapsed
    }, className);
    return (_jsxs(Nav, __assign({ className: expandSideBarClassName, onMouseEnter: function () {
            setInternalCollapsed(false);
            if (isCollapsed && element) {
                animateNavElements(element, openAtIndex);
            }
        }, onMouseLeave: function () {
            setInternalCollapsed(isCollapsed);
            if (isCollapsed && element) {
                animateNavElements(element, null);
            }
        } }, { children: [_jsxs(StyledNavList, __assign({ ref: setElement }, { children: [React.Children.map(children, function (child, clickedIndex) { return (_jsx(NavGroupContext.Provider, __assign({ value: {
                            isOpen: !internalCollapsed && clickedIndex === openAtIndex,
                            onClick: function () {
                                if (element) {
                                    var openIndex = clickedIndex === openAtIndex
                                        ? null
                                        : clickedIndex;
                                    animateNavElements(element, openIndex);
                                    setOpenAtIndex(openIndex);
                                }
                            }
                        } }, { children: child }), void 0)); }), _jsx(Cover, { height: element
                            ? getCoverHeight(element)
                            : 0 }, void 0)] }), void 0), _jsx(Footer, { footer: footer, progress: progress, progressIsActive: progressIsActive, progressText: progressText, collapsed: collapsed, onCollapse: isSmallScreen
                    ? undefined
                    : onCollapse }, void 0)] }), void 0));
}
NavBar.Link = NavLink;
NavBar.Filter = NavFilter;
NavBar.Group = NavGroup;
NavBar.Separator = Separator;
export { NavBar };
var templateObject_1, templateObject_2, templateObject_3;
