var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { LAYOUT_TOOLBAR_HEIGHT, themeSpace } from '../Theme';
import { Container, Widget } from './StyledComponents';
var ToolBarDetail = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  \n  & > ", " {\n    min-height: ", ";\n    padding: ", ";\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  \n  & > ", " {\n    min-height: ", ";\n    padding: ", ";\n  }\n"])), Widget, function () { return LAYOUT_TOOLBAR_HEIGHT; }, themeSpace(1));
export function ToolbarDetail(_a) {
    var children = _a.children;
    return (_jsxs(Container, __assign({ style: { flexDirection: 'column' } }, { children: [children[0], _jsx(ToolBarDetail, { children: _jsx(Widget, { children: children[1] }, void 0) }, void 0)] }), void 0));
}
var templateObject_1;
