var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { fromTheme, themeColor, themeGlobal, themeSpace } from '../Theme';
import { toRgbString } from '../utils';
var rgbColor = fromTheme(function (theme) { return toRgbString(theme.global.purposes.brand); });
var Layout = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 300px 300px minmax(0, 1fr);\n  grid-template-rows: auto;\n  grid-gap: ", ";\n\n  & > * {\n    background-color: white;\n    box-shadow: 1px 1px 0 rgba(", ",0.4);\n\n    &:nth-child(1), &:nth-child(2) {\n      display: flex; \n      flex-direction: column;\n      top: calc(", "px + ", ");\n      position: sticky;\n      max-height: ", ";\n    }\n    \n    &:nth-child(3) {\n      box-sizing: border-box;\n      min-height: ", ";\n      padding: ", ";\n    }\n  }\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 300px 300px minmax(0, 1fr);\n  grid-template-rows: auto;\n  grid-gap: ", ";\n\n  & > * {\n    background-color: white;\n    box-shadow: 1px 1px 0 rgba(", ",0.4);\n\n    &:nth-child(1), &:nth-child(2) {\n      display: flex; \n      flex-direction: column;\n      top: calc(", "px + ", ");\n      position: sticky;\n      max-height: ", ";\n    }\n    \n    &:nth-child(3) {\n      box-sizing: border-box;\n      min-height: ", ";\n      padding: ", ";\n    }\n  }\n"])), themeSpace(1), rgbColor, themeGlobal('headerHeight'), themeSpace(2), themeGlobal('contentHeight'), themeGlobal('contentHeight'), themeSpace(2));
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  height: 50px;\n  flex-shrink: 0;\n  border-bottom: 1px solid ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  height: 50px;\n  flex-shrink: 0;\n  border-bottom: 1px solid ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), themeSpace(2), themeColor('lightGray'), themeColor('gray'));
export function MasterMasterDetail(_a) {
    var _b = _a.title, title = _b === void 0 ? [] : _b, children = _a.children;
    return (_jsxs(Layout, { children: [_jsxs("div", { children: [!!title[0] && _jsx(Title, { children: title[0] }, void 0), children[0]] }, void 0), _jsxs("div", { children: [!!title[1] && _jsx(Title, { children: title[1] }, void 0), children[1]] }, void 0), _jsx("div", { children: children[2] }, void 0)] }, void 0));
}
var templateObject_1, templateObject_2;
