export const EXTRACTORS = 'use'
export const TRANSFORMERS = 'transcript'
export const REPORTS = 'edify'
export const CORE = 'proximity'
export const BUDGETS = 'horizon'
export const SCHEDULER = 'chronos'
export const NODE_MANAGER = 'merlin'
export const JOB_MANAGER = 'griffon'
export const NOTIFICATIONS = 'pigeon'

export type LogComponentKeys =
  | typeof EXTRACTORS
  | typeof TRANSFORMERS
  | typeof REPORTS
  | typeof CORE
  | typeof NOTIFICATIONS
  | typeof BUDGETS
  | typeof SCHEDULER
  | typeof NODE_MANAGER
  | typeof JOB_MANAGER
