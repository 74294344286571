var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdSearch } from 'react-icons/md';
import { animated } from '@react-spring/web';
import { Input } from '../Input';
import { toRgbString } from '../utils';
import { fromTheme, themeColor } from '../Theme';
import { Adornment } from '../Adornment';
var AnimatedStyledInput = animated(Input);
function getLightgray(props) {
    return fromTheme(function (theme) { return toRgbString(theme.colors.lightGray); })(props);
}
var StyledInput = styled(AnimatedStyledInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-color: transparent;\n  background-color: ", ";\n\n  &:hover, &:focus {\n    border-color: transparent;\n    background-color: ", ";\n  }\n\n  ", "\n"], ["\n  border-color: transparent;\n  background-color: ", ";\n\n  &:hover, &:focus {\n    border-color: transparent;\n    background-color: ", ";\n  }\n\n  ", "\n"])), function (props) { return props.dark
    ? "rgba(" + getLightgray(props) + ", 0.7)"
    : '#fff'; }, themeColor('lightGray'), function (props) { return props.animated && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    will-change: padding-left;\n    transition: padding-left .1s ease;\n\n    &:not(:hover):not(:focus) {\n      background-color: transparent;\n      padding-left: 0px !important;\n      border-color: transparent;\n    }\n\n    &:hover, &:focus {\n      padding-left: 0.5em !important;\n    }\n  "], ["\n    will-change: padding-left;\n    transition: padding-left .1s ease;\n\n    &:not(:hover):not(:focus) {\n      background-color: transparent;\n      padding-left: 0px !important;\n      border-color: transparent;\n    }\n\n    &:hover, &:focus {\n      padding-left: 0.5em !important;\n    }\n  "]))); });
export function Searchbar(props) {
    return (_jsx(Adornment, __assign({ right: _jsx(MdSearch, {}, void 0) }, { children: _jsx(StyledInput, __assign({ animated: !props.dark }, props), void 0) }), void 0));
}
var templateObject_1, templateObject_2;
