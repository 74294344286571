import { curry, keys, find, equals } from 'ramda';
import resourceSchema from './schema.json';
const models = resourceSchema.models;
export const getRelatedDefinition = curry((type, relatedType) => {
    const isRelatedType = equals(relatedType);
    const relatedKey = find((relation) => isRelatedType(models[type].relationships[relation].model), keys(models[type].relationships));
    if (!relatedKey) {
        throw Error(`Couldn't find ${relatedType} relation in ${type}`);
    }
    return models[type].relationships[relatedKey];
});
export const getRelatedInverse = curry((type, relatedType) => {
    return getRelatedDefinition(type, relatedType).inverse;
});
