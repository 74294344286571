import { isDevEnvironment } from '../../utils/system'

export type FeatureNames =
  | 'widgetWorkflowStatus'
  | 'budgets'
  | 'ldap'
  | 'variables'
  | 'dataset'
  | 'chargeModelSpecificDay'
  | 'subscriptions'
  | 'rateTiering'
  | 'translations'
  | 'enhancedUserProvisioning'
  | 'claimsBasedAccessProvisioning'

export type FeatureFlagValue = 'alpha' | 'beta' | 'released'

export type FeatureFlags<T extends string = FeatureNames> = {
  [key in T]?: FeatureFlagValue
}

export const featureFlags: FeatureFlags = {
  budgets: 'released',
  ldap: 'released',
  variables: 'released',
  dataset: 'released',
  chargeModelSpecificDay: 'released',
  subscriptions: 'released',
  rateTiering: 'beta',
  translations: 'beta',
  claimsBasedAccessProvisioning: 'beta',
  enhancedUserProvisioning: 'beta'
}

export let alphaEnabled = isDevEnvironment()

export function setAlphaEnabled (state: boolean) {
  alphaEnabled = state
}
