var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { preciseEm } from '../utils';
import { themeSpace } from '../Theme';
var MAX_WIDTH = 50; // em
var MIN_HEIGHT = 2.5; // em
export var Multiple = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  gap: ", ";\n  flex-wrap: wrap;\n  \n  > * {\n    flex: 1;\n  }\n\n  max-width: ", "em;\n  min-height: ", "em;\n"], ["\n  display: flex;\n  gap: ", ";\n  flex-wrap: wrap;\n  \n  > * {\n    flex: 1;\n  }\n\n  max-width: ", "em;\n  min-height: ", "em;\n"])), themeSpace(2), preciseEm(MAX_WIDTH), preciseEm(MIN_HEIGHT));
var templateObject_1;
