var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { startOfDay, startOfMonth, startOfQuarter, startOfYear, endOfDay, endOfMonth, endOfQuarter, endOfYear } from 'date-fns';
import { CalendarMode, getCalendar } from '../CalendarModes';
import { CalendarBrowser } from '../CalendarBrowser';
import { Button } from '../../Button';
import { globalFont } from '../../utils';
import { CalendarHeader } from '../CalendarHeader';
import { withExtendableProps } from '../../ExtendProps';
var StyledDateCalendar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  min-width: 300px;\n \n  .header, .arrow {\n    font-size: 20px;\n  }\n  \n  .active, .time-unit:hover {\n    border-radius: 180px;\n  }\n\n  button {\n    width: 100%;\n  }\n"], ["\n  ", "\n\n  min-width: 300px;\n \n  .header, .arrow {\n    font-size: 20px;\n  }\n  \n  .active, .time-unit:hover {\n    border-radius: 180px;\n  }\n\n  button {\n    width: 100%;\n  }\n"])), globalFont);
function _DateCalendar(_a) {
    var _b = _a.mode, mode = _b === void 0 ? CalendarMode.Days : _b, className = _a.className, value = _a.value, _c = _a.onChange, onChange = _c === void 0 ? function () { return null; } : _c, _d = _a.offset, offset = _d === void 0 ? 0 : _d, onClear = _a.onClear;
    var _e = useState(value || new Date()), browserDate = _e[0], setBrowserDate = _e[1];
    var Calendar = getCalendar(mode).Calendar;
    return (_jsx(StyledDateCalendar, __assign({ "data-testid": 'date-calendar', className: className }, { children: _jsxs(CalendarBrowser, __assign({ value: browserDate, mode: mode, onChange: setBrowserDate }, { children: [_jsx(CalendarHeader, { date: browserDate, mode: mode, offset: offset }, void 0), _jsx(Calendar, { value: [value || null, null], browserDate: browserDate, offset: offset, onChange: function (date) { return onChange && onChange(date, mode); } }, void 0), onClear && (_jsx(Button.Clear, __assign({ onClick: onClear }, { children: "Clear date" }), void 0))] }), void 0) }), void 0));
}
export var DateCalendarV2 = withExtendableProps('DateCalendar')(_DateCalendar);
DateCalendarV2.startOfRange = function (date, mode) {
    var _a;
    return ((_a = {},
        _a[CalendarMode.Days] = startOfDay,
        _a[CalendarMode.Months] = startOfMonth,
        _a[CalendarMode.Quarters] = startOfQuarter,
        _a[CalendarMode.Years] = startOfYear,
        _a)[mode](date));
};
DateCalendarV2.endOfRange = function (date, mode) {
    var _a;
    return ((_a = {},
        _a[CalendarMode.Days] = endOfDay,
        _a[CalendarMode.Months] = endOfMonth,
        _a[CalendarMode.Quarters] = endOfQuarter,
        _a[CalendarMode.Years] = endOfYear,
        _a)[mode](date));
};
var templateObject_1;
