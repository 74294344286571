import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'

import { Users } from './Users'

const mapRecordsToProps = {
  users: (q: QueryBuilder) => q.findRecords('user').sort({
    attribute: 'username',
    order: 'ascending'
  })
}

export default withData(mapRecordsToProps)(Users)
