import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MdDelete } from 'react-icons/md'
import { useCrud } from 'react-crud-hook'
import {
  Form,
  Field,
  Label,
  Select,
  Group,
  GroupBox,
  Input,
  Button,
  useModal
} from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useCacheQuery, queries, useOperationListener } from '@exivity/data-layer'
import { NEW_PARAM, useParams } from '@exivity/routing'

import { authSelectors } from '../../auth/state'
import {
  NotificationChannelModel,
  createChannel,
  UserModel,
  ChannelType
} from '../../../data/types'
import { NotificationParams } from '../pages/MyNotifications'

import { CHANNEL_CONFIGURATIONS, TRANSLATIONS_RECIPIENT_CHANNEL } from './Configuration'

interface ChannelProps {
  channel: NotificationChannelModel
}

function Channel ({ channel }: ChannelProps) {
  const [{ notification_id }, setParams] = useParams<NotificationParams>()

  const { warning } = useModal()

  const _channel = useCrud<NotificationChannelModel>(channel)

  const Configuration = _channel.attributes.type
    ? CHANNEL_CONFIGURATIONS[_channel.attributes.type]
    : () => null

  return (
    <Form onSubmit={() => _channel.save()}>

      <GroupBox
        collapsed={
          notification_id !== NEW_PARAM
            ? notification_id !== _channel.id
            : undefined}
        onCollapseChange={(collapsed) => {
          if (collapsed) {
            _channel.reset()
            setParams('notification_id', null)
          } else {
            setParams('notification_id', _channel.id)
          }
        }}>

        {notification_id !== NEW_PARAM
          ? (
            <GroupBox.Header>
              <GroupBox.Title subTitle={
                _channel.attributes.type
                  ? TRANSLATIONS_RECIPIENT_CHANNEL[_channel.attributes.type].title()
                  : undefined
              }>
                {_channel.attributes.name}
              </GroupBox.Title>
              <GroupBox.Join>
                <GroupBox.Actions>
                  <Button round transparent icon={<MdDelete />} small onClick={() => {
                    warning({ body: translate('Are you sure you want delete this channel?') })
                      .then(() => _channel.delete())
                  }} />
                </GroupBox.Actions>
                <GroupBox.Collapser />
              </GroupBox.Join>
            </GroupBox.Header>
          )
          : (
            <GroupBox.Header>
              <GroupBox.Title>
                {translate('Channel')}
              </GroupBox.Title>
            </GroupBox.Header>
          )}
        <GroupBox.Content>
          <Group>
            <Field.Container>
              <Field>
                <Label>
                  {translate('Channel name')}
                </Label>
                <Input
                  required
                  placeholder={translate('Name')}
                  value={_channel.attributes.name}
                  onChange={_channel.setAttribute('name')} />
              </Field>

              {notification_id === NEW_PARAM && (
                <Field>
                  <Label>
                    {translate('Channel type')}
                  </Label>
                  <Select
                    required
                    data={Object.values(ChannelType)}
                    labelAccessor={(key) => TRANSLATIONS_RECIPIENT_CHANNEL[key].option()}
                    value={_channel.attributes.type}
                    onChange={(type) => _channel
                      .setAttribute('info', {})
                      .setAttribute('type', type)
                    } />
                </Field>
              )}
            </Field.Container>
          </Group>

          {_channel.attributes.type && (
            <Group title={translate('Configuration')}>
              <Field.Container>
                <Configuration channel={_channel} />
              </Field.Container>
            </Group>
          )}

          <Form.Toolbar>
            <Button.Cancel onClick={() => setParams('notification_id', null)} />
            {notification_id === NEW_PARAM
              ? <Button.Create />
              : <Button.Update />
            }
          </Form.Toolbar>

        </GroupBox.Content>
      </GroupBox>

    </Form>
  )
}

function populateChannel (user: { type: string; id: string }) {
  return createChannel(
    undefined,
    undefined,
    { user: { data: user } }
  )
}

function useChannels (user: UserModel) {
  const channelRef = useRef(populateChannel(user))

  useEffect(() => {
    if (user) {
      channelRef.current = populateChannel(user)
    }
  }, [user])

  return [
    channelRef.current,
    useCacheQuery(
      queries
        .relationshipOf('user', user, 'channels')
        .sortByAttribute('name', 'ascending')
    ) as unknown as NotificationChannelModel[]
  ] as const
}

// This component is used in the Profile domain as well as UserManagement domain
// In the UserManagement domain it gets assigned the selected user
// while in the profile domain its the currentUser who is logged in.

function Channels ({ user }: { user?: UserModel }) {
  const [{ notification_id }, setParams] = useParams<NotificationParams>()
  useOperationListener('notificationchannel', {
    addRecord: () => setParams('notification_id', null),
    removeRecord: () => setParams('notification_id', null)
  })

  const currentUser = useSelector(authSelectors.getCurrentUser)
  const [channel, channels] = useChannels(user || currentUser)

  if (notification_id === NEW_PARAM) {
    return <Channel channel={channel} />
  }

  return (
    <>
      {notification_id !== NEW_PARAM && (
        <Button small success onClick={() => setParams('notification_id', NEW_PARAM)}>
          {translate('Add Channel')}
        </Button>
      )}

      {channels.length === 0 && (
        <GroupBox>
          <GroupBox.Header>
            <GroupBox.Title>{translate('No channels configured.')}</GroupBox.Title>
          </GroupBox.Header>
        </GroupBox>
      )}

      {channels.map((channel) => (
        <Channel key={channel.id} channel={channel} />
      ))}
    </>
  )
}

export function ChannelsProfile () {
  return <Channels />
}

export function ChannelsUserManagement ({ user }: { user: UserModel }) {
  return <Channels user={user} />
}
