import { get } from '../../API/index'
import ClassAction, { createClassAction } from '../../store/middleware/ClassAction'
import { ReleaseState } from '../../reducers/system/release'
import { ActionDispatcher } from '../../store/utils'

export class UpdateReleaseData extends ClassAction {
  static readonly type = 'SYSTEM_RELEASE_SET_VERSION'
  readonly type = UpdateReleaseData.type
  constructor (public data: ReleaseState) {
    super()
  }
}

export const updateReleaseData = createClassAction(UpdateReleaseData)

export function fetchReleaseData (): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    get(
      '/system/version',
      {},
      {
        background: true,
        workStatusMessage: translate('Updating release information')
      }
    ).then((data: { data: ReleaseState }) => {
      dispatch(updateReleaseData(data.data))
    })
  }
}
