import React, { useEffect } from 'react'
import { useCrud, CrudRecord } from 'react-crud-hook'
import { Field, Select, Input, Label, Placeholder, CalendarMode, DatePicker } from '@exivity/ui'
import { schema } from '@exivity/data-layer'

import {
  DEFAULT_METADATA,
  MetadataDefinitionField,
  MetadataModel,
  METADATA_TYPE,
  DEFAULT_METADATA_DEFINITION
} from '../../../../data/types'
import { useDateFormatter, Formats } from '../../../../components/hooks/useDateFormatter'

import { mergeValues } from './helpers'

import { MetadataFormProps } from './'

function renderInputField (
  metadataRecord: CrudRecord<MetadataModel>,
  formatter: ReturnType<typeof useDateFormatter>
) {
  return function (field: MetadataDefinitionField) {
    const value = metadataRecord.attributes.values && metadataRecord.attributes.values[field.name]

    switch (field.type) {
      case 'string':
        return (
          <>
            <Label>
              {field.name}
            </Label>
            <Input name={field.name}
              placeholder='Text'
              value={value || ''}
              onChange={(value: string) => mergeValues(field.name, value, metadataRecord)} />
          </>
        )

      case 'numeric':
        return (
          <>
            <Label>
              {field.name}
            </Label>
            <Input type='number' name={field.name}
              placeholder='Number'
              value={value || ''}
              onChange={(value: string) => mergeValues(field.name, value, metadataRecord)} />
          </>
        )

      case 'date': {
        return (
          <>
            <Label>
              {field.name}
            </Label>
            <DatePicker
              placeholder='Select date'
              mode={CalendarMode.Days}
              format={formatter.getFormat(Formats.GuiDate)}
              value={value ? formatter.parse(Formats.IsoDate, value) : undefined}
              onChange={(date) => {
                mergeValues(
                  field.name,
                  date ? formatter.format(Formats.IsoDate, date) : null,
                  metadataRecord
                )
              }} />
          </>
        )
      }

      case 'list':
        return (
          <>
            <Label>
              {field.name}
            </Label>
            <Select name={field.name}
              placeholder='Choose option'
              value={value}
              data={Object.values(field.list)}
              onChange={(value) => mergeValues(field.name, value, metadataRecord)} />
          </>
        )

      default:
        return null
    }
  }
}

export function MetadataForm ({
  metadata = DEFAULT_METADATA,
  definitionId,
  model,
  definition = DEFAULT_METADATA_DEFINITION,
  placeholder,
  onRecord
}: MetadataFormProps) {
  const metadataRecord = useCrud(metadata) as CrudRecord<MetadataModel>
  const formatter = useDateFormatter()
  const renderField = renderInputField(metadataRecord, formatter)
  const { type, id } = model

  useEffect(() => {
    onRecord && onRecord(metadataRecord)
  }, [metadataRecord, onRecord])

  useEffect(() => {
    metadataRecord.addHasOne('definition', { type: 'metadatadefinition', id: definitionId })
  }, [definitionId, metadata])

  useEffect(() => {
    metadataRecord.addHasMany(
      schema.getInverseRelation(type, METADATA_TYPE),
      { type, id }
    )
  }, [model, metadata])

  if (!definitionId) {
    return (
      <Placeholder>
        {placeholder}
      </Placeholder>
    )
  }

  return (
    <Field.Container>
      {definition.attributes.fields.map((value, index) => (
        <Field key={index}>
          {renderField(value)}
        </Field>
      ))}
    </Field.Container>
  )
}
