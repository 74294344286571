import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { workThunks } from '../../../work/thunks'
import LoadingOverlay from '../../../../components/molecules/LoadingOverlay'
import Form, { TextInput } from '../../../../components/molecules/Form'
import Button from '../../../../components/atoms/Button'
import { DomainChanger } from '../DomainChanger/DomainChanger'
import { authThunks } from '../../state'

interface ResetPasswordFormProps {
  apiRoot: string
  loading?: boolean
  onDomainChanged: (apiRoot: string) => void
  onRedirectToLoginPage: () => void
  token: string
}

export function ResetPasswordForm ({
  apiRoot,
  loading = false,
  onDomainChanged,
  onRedirectToLoginPage,
  token
}: ResetPasswordFormProps) {
  const dispatch = useDispatch()
  const [showDomain, setShowDomain] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')

  const handleFormSubmit = useCallback(() => {
    if (password === verifyPassword) {
      dispatch(authThunks.resetPassword({ token, username, password, verifyPassword }))
    } else {
      dispatch(workThunks.showErrorMessage('Passwords do not match.'))
    }
  }, [token, username, password, verifyPassword])

  return (
    <Form className='ex-login-form uk-background-default uk-position-relative uk-form-stacked'
      onSubmit={handleFormSubmit}>
      {loading && <LoadingOverlay />}
      <TextInput required
        autoComplete='username'
        label='Username'
        size='large'
        name='username'
        placeholder='Username'
        controlled
        onChange={(name: string) => setUsername(name)}>
        {username}
      </TextInput>
      <TextInput required
        autoComplete='new-password'
        label='Password'
        size='large'
        name='password'
        placeholder='password'
        controlled
        masked
        onChange={(p: string) => setPassword(p)}>
        {password}
      </TextInput>
      <TextInput required
        autoComplete='new-password'
        label='Verify password'
        size='large'
        name='verify_password'
        placeholder='password'
        controlled
        masked
        onChange={(v: string) => setVerifyPassword(v)}>
        {verifyPassword}
      </TextInput>
      <DomainChanger showDomain={showDomain} apiRoot={apiRoot}
        onDomainChanged={onDomainChanged} />
      <div>
        <Button submit icon='chevron-right' iconPosition='right' variation='secondary'>
          Reset
        </Button>
        <div className='ex-reset-form__links uk-float-right'>
          <Button
            variation='text'
            className='uk-float-right'
            onClick={() => setShowDomain(!showDomain)}>
            Change domain
          </Button>
          <Button variation='text' className='uk-float-right' onClick={onRedirectToLoginPage}>
            Login
          </Button>
        </div>
      </div>
    </Form>
  )
}
