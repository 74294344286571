var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import union from 'lodash/union';
import diff from 'lodash/difference';
import { PARENT, getParents, getChildren } from '../utils/tree';
import { makeGetAccessValues } from '../utils/accessor';
import { compose } from '../utils/compose';
export function hasCollapsedParents(item, keyAccessor, expanded) {
    return getParents(item).some(function (parent) { return !expanded.includes(keyAccessor(parent)); });
}
export function toggleExpansion(key, expanded) {
    return expanded.includes(key)
        ? diff(expanded, [key])
        : union(expanded, [key]);
}
export function createHelpers(keyAccessor, expanded, setExpanded) {
    var getKeys = makeGetAccessValues(keyAccessor);
    var getParentKeys = function (item) { return getKeys(__spreadArray([item], getParents(item))); };
    var getChildKeys = function (item) { return getKeys(__spreadArray([item], getChildren(item))); };
    return {
        expand: {
            children: compose(setExpanded, function (keys) { return union(expanded, keys); }, getChildKeys)
        },
        collapse: {
            parents: compose(setExpanded, function (keys) { return diff(expanded, keys); }, getParentKeys),
            children: compose(setExpanded, function (keys) { return diff(expanded, keys); }, getChildKeys)
        }
    };
}
export function enrichTreeItems(list, keyAccessor, expanded, setExpanded) {
    return list.map(function (item) {
        var key = keyAccessor(item);
        return __assign(__assign({}, item), { expanded: expanded.includes(key), expand: function () { return setExpanded(toggleExpansion(key, expanded)); } });
    });
}
export function getVisibleItems(data, keyAccessor, expanded) {
    return data.filter(function (item) { return !item[PARENT] || !hasCollapsedParents(item, keyAccessor, expanded); });
}
