import MemorySource from '@orbit/memory'

import {
  ServiceReportItem,
  ServiceReportItemAPI
} from '../../../API/APIResponseTypes/reports'
import {
  ServiceModel,
  ServiceCategoryModel,
  DsetModel,
  MetadataDefinitionModel,
  MetadataDefinitionField,
  MetadataModel
} from '../../../data/types'
import { RootState } from '../../../reducers'
import { getRelationship } from '../../../utils/records'
import { getMetadataValues } from '../metadata'
import { configurationSelectors } from '../../../domains/administration/state/configuration'

function getStore () {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require('../../../data/store').default as MemorySource
}

async function fetchMetadataFields (reportId: string) {
  try {
    const dset = getStore()
      .cache
      .query(q => q.findRelatedRecord({
        type: 'report',
        id: reportId
      }, 'dset')) as DsetModel

    const metadataDefinition = getStore()
      .cache
      .query(q => (
        q.findRecord(getRelationship(dset, 'metadatadefinition', {}))
      )) as MetadataDefinitionModel

    return metadataDefinition.attributes.fields
  } catch {
    return []
  }
}

export default async (state: RootState, data: ServiceReportItemAPI[]) => {
  const selectedReport = state.reports.filters.report
  let metadataFields: MetadataDefinitionField[] = []

  if (selectedReport) {
    // This slows us down
    metadataFields = await fetchMetadataFields(selectedReport)
  }

  return data.map(item => {
    let service: ServiceModel
    let servicecategory: ServiceCategoryModel
    const newItem = item as ServiceReportItem

    // Format numbers
    const toParse = [
      'total_quantity',
      'unit_based_subtotal_charge',
      'interval_based_subtotal_charge',
      'total_charge',
      'total_cogs',
      'total_net'
    ] as const
    toParse.forEach(field => {
      newItem[field] = parseFloat(item[field] as string)
    })

    // Add service name & category
    try {
      service = getStore().cache.query(query => query
        .findRecord({
          type: 'service',
          id: String(newItem.service_id)
        })
      ) as ServiceModel
      servicecategory = getStore().cache.query(query => query
        .findRelatedRecord({
          type: 'service',
          id: String(newItem.service_id)
        }, 'servicecategory')
      ) as ServiceCategoryModel

      const metadatas = getStore().cache
        .query(q => q.findRelatedRecords(
          service,
          'metadata'
        )) as MetadataModel[]

      newItem.metadata = getMetadataValues(
        metadatas,
        metadataFields,
        configurationSelectors.getDateFormat(state)
      )

      newItem.key = service.attributes.key
      newItem.service_description = service.attributes.description
      newItem.servicecategory_id = servicecategory.id
      newItem.servicecategory_name = servicecategory.attributes.name
      newItem.service_unit_label = service.attributes.unit_label
      newItem.service_charge_type = service.attributes.charge_type
      newItem.service_cogs_type = service.attributes.cogs_type
      newItem.proration_type = service.attributes.proration_type
      newItem.interval = service.attributes.interval
    } catch (error) {
      newItem.service_description = '(unknown service)'
      newItem.servicecategory_id = null
      newItem.servicecategory_name = '(unknown servicecategory)'
      newItem.service_unit_label = null
    }

    return newItem
  })
}
