var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Block } from '../Block';
export var ExpandHeight = styled(Block)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: calc(100vh - ", "px);\n  \n  ", "\n\n  & > * {\n    height: 100%;\n  }\n"], ["\n  min-height: calc(100vh - ", "px);\n  \n  ", "\n\n  & > * {\n    height: 100%;\n  }\n"])), function (_a) {
    var _b = _a.heightOffset, heightOffset = _b === void 0 ? 100 : _b;
    return heightOffset;
}, function (_a) {
    var sticky = _a.sticky;
    return sticky && "\n    position: sticky;\n    top: 80px;\n    display: flex;\n    flex-direction: column;\n  ";
});
var templateObject_1;
