import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavBar } from '@exivity/ui'
import { useCacheQuery, queries } from '@exivity/data-layer'
import { prop, viewAttribute, compose } from '@exivity/fp'

import { reportsActions, reportSelectors } from '../../state'

export function ReportFilter () {
  const reports = useCacheQuery(queries.findAll('report'))
  const selectedReport = useSelector(reportSelectors.getSelectedReportId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedReport && reports.length) {
      dispatch(reportsActions.filters.updateReport(reports[0].id))
    }
  }, [dispatch, selectedReport, reports])

  return (
    <NavBar.Filter
      value={selectedReport}
      labelAccessor={viewAttribute('name')}
      valueAccessor={prop('id')}
      data={reports}
      onChange={compose(
        dispatch,
        reportsActions.filters.updateReport
      )} />
  )
}
