import { useRef, useMemo } from 'react'

export function useRenderKey<T> (trigger: T) {
  const key = useRef('1')

  return useMemo(() => {
    if (key.current === '1') {
      key.current = '2'
      return '1'
    } else {
      key.current = '1'
      return '2'
    }
  }, [trigger])
}
