import { range, map, mergeAll } from '@exivity/fp'
import { Resources } from '@exivity/data-layer'

import { memory } from '../../../../data/sources'

const getReport = (selectedReport: string | null) => {
  if (!selectedReport) return null

  return memory.cache.query(q => q.findRecord({
    type: 'report',
    id: selectedReport
  })) as Resources['report'] | null
}

export const getDepthLabels = (
  reportId: null|string,
  reportMinAccessibleDepth: number
) => {
  const report = getReport(reportId)

  if (report && reportMinAccessibleDepth && report.attributes.depth) {
    const accessibleLevels = range(
      reportMinAccessibleDepth,
      report.attributes.depth + 1
    )

    const availableLevels = map(
      (level) => ({
        // @ts-ignore
        [level]: report.attributes[`lvl${level}_label`] as string
      }),
      accessibleLevels
    )

    return mergeAll(availableLevels)
  }

  return {}
}
