import React from 'react'
import { translate } from '@exivity/translations'
import { Resources, WithPopulatedRelationships, enums } from '@exivity/data-layer'
import { Updater } from '@exivity/use-data-structure'
import { Field, Group, Label, Input, Button } from '@exivity/ui'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { Schedule } from './Schedule'
import { Step } from './Step'

const { WorkflowScheduleFrequency, WorkflowStepType } = enums

const SortableStep = SortableElement(Step)
const DragHandle = SortableHandle(() => <span style={{ cursor: 'move' }}>☰</span>)

interface SortableStepListProps {
  steps: Resources['workflowstep'][]
  updater: Updater<Resources['workflowstep'][]>
}

type Workflow = WithPopulatedRelationships<'workflow', 'steps'|'schedules'>

const SortableStepList = SortableContainer(
  function StepList ({ steps, updater }: SortableStepListProps) {
    return (
      <span>
        {steps.map((step, index) => (
          <SortableStep
            key={index}
            renderDragHandle={() => <DragHandle />}
            index={index}
            step={step}
            updater={updater.selectByIndex(index)}
            onDelete={() => updater.removeByIndex(index)} />
        ))}
      </span>
    )
  }
)

interface ConfigurationProps {
  workflow: Workflow
  updater: Updater<Workflow>
}

export function Configuration ({ workflow, updater }: ConfigurationProps) {
  const schedulesUpdater = updater.select(workflow => workflow.relationships.schedules.data)
  const stepsUpdater = updater.select(workflow => workflow.relationships.steps.data)

  return (
    <>
      <Group title={translate('General')}>
        <Field.Container>
          <Field>
            <Label>{translate('Description')}</Label>
            <Input
              placeholder={translate('Description')}
              value={workflow.attributes.description}
              onChange={updater.setAttribute('description')} />
          </Field>
        </Field.Container>
      </Group>
      <Group title={translate('Schedules')}>
        {workflow.relationships.schedules.data.map((schedule, index) => (
          <Schedule
            key={schedule.id || index}
            schedule={schedule}
            updater={schedulesUpdater.selectByIndex(index)}
            onDelete={() => schedulesUpdater.removeByIndex(index)} />
        ))}
        <Button success small onClick={() => schedulesUpdater.add({
          id: '',
          type: 'workflowschedule',
          attributes: {
            start_time: '',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            adjust_for_dst: false,
            frequency: WorkflowScheduleFrequency.Monthly,
            frequency_modifier: 1
          },
          relationships: {
            workflow: { data: null }
          }
        })}>
          {translate('Add schedule')}
        </Button>
      </Group>
      <Group title={translate('Steps')}>
        <SortableStepList
          useDragHandle
          transitionDuration={150}
          lockAxis='y'
          steps={workflow.relationships?.steps.data || []}
          updater={stepsUpdater}
          onSortEnd={({ oldIndex, newIndex }) => {
            stepsUpdater
              .move(oldIndex, newIndex)
              .each((step, index) => {
                step.attributes.sort = index
              })
          }} />

        <Button success small onClick={() => {
          stepsUpdater.add({
            id: '',
            type: 'workflowstep',
            attributes: {
              type: WorkflowStepType.RunExtractor,
              options: {},
              sort: workflow.relationships.steps.data.length,
              wait: true,
              timeout: 3600
            },
            relationships: {
              workflow: { data: null }
            } as any
          })
        }}>
          {translate('Add step')}
        </Button>
      </Group>
    </>
  )
}
