var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { format as dateFormat } from 'date-fns';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { translate } from '@exivity/translations';
import { SelectInput } from '../Input/SelectInput';
import { ModePicker } from '../Calendar/ModePicker';
import { DateCalendarV2 } from '../Calendar/DateCalendarV2';
import { CalendarMode } from '../Calendar/CalendarModes';
import { useFieldContext } from '../Field/context';
import { withExtendableProps } from '../ExtendProps';
import { DatePickerDropDown } from './DatePickerDropdown';
export function _DatePicker(_a) {
    var name = _a.name, _b = _a.format, format = _b === void 0 ? 'yyyyMMdd' : _b, _c = _a.placeholder, placeholder = _c === void 0 ? translate('Choose date') : _c, required = _a.required, disabled = _a.disabled, _d = _a.mode, mode = _d === void 0 ? CalendarMode.Days : _d, value = _a.value, _e = _a.onChange, onChange = _e === void 0 ? identity : _e, onClear = _a.onClear;
    var _f = useState(CalendarMode.Days), currentMode = _f[0], setMode = _f[1];
    var _g = useToggle(false), isOpen = _g[0], toggleOpen = _g[1];
    var id = useFieldContext().id;
    var calendar = (_jsx(DateCalendarV2, { mode: mode, value: value, onChange: function (date, mode) {
            onChange(date, mode);
            toggleOpen();
        }, onClear: onClear
            ? juxt([onClear, toggleOpen])
            : undefined }, void 0));
    return (_jsx(DatePickerDropDown, __assign({ disabled: disabled, reference: _jsx(SelectInput, { id: id, name: name, value: value
                ? dateFormat(value, format)
                : '', placeholder: placeholder, required: required, disabled: disabled }, void 0), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: !mode
            ? (_jsx(ModePicker, __assign({ value: currentMode, onChange: setMode }, { children: calendar }), void 0))
            : calendar }), void 0));
}
export var DatePicker = withExtendableProps('DatePicker')(_DatePicker);
