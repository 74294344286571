import React, { FC } from 'react'
import compose from 'lodash/fp/compose'
import { Group, Field } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { QueryBuilder } from '@orbit/data'
import { queries, Resources, useCacheQueryFn } from '@exivity/data-layer'
import { curry } from '@exivity/fp'
import { connect } from 'react-redux'
import { withData } from 'react-orbitjs'

import {
  ServiceModel,
  ReportModel,
  RateModel,
  ServiceCategoryModel
} from '../../../../../../data/types'
import { RootState } from '../../../../../../reducers'
import { insertIf } from '../../../../../../utils/misc'

import { ServiceCategory } from './ServiceCategory'
import { Service } from './Service'
import { RateCogs, useHasActiveRate } from './RateCogs'

interface ServiceGroupProps {
  account: Resources['account']
  serviceCategory: ServiceCategoryModel | null
  service: ServiceModel | null
  rate: number | null
  cogs: number | null
  onChangeServiceCategory: (serviceCategory: ServiceCategoryModel) => void
  onChangeService: (service: ServiceModel | null) => void
  onChangeRate: (rate: number) => void
  onChangeCogs: (cogs: number) => void

  serviceCategories?: ServiceCategoryModel[]
  services?: ServiceModel[]
  accountRates?: RateModel[]
  allRates?: RateModel[]
}

const queryAccountRates = curry((
  queryCache: any,
  service: any,
  account: Resources['account']
) => queryCache(
  queries
    .relationshipOf('service', service, 'rates')
    .filterByRelationship('account', 'equal', account)
))

const getAccount = (queryCache: any, accountId: any) => queryCache(
  queries.find('account', accountId)
)

function useAccountRates (
  service?: ServiceModel|null,
  account?: Resources['account']
) {
  const queryCache = useCacheQueryFn()
  const hasActiveRate = useHasActiveRate()

  const getAccountRates = queryAccountRates(queryCache, service)

  const getRelevantRates = (account: Resources['account']): RateModel[] => {
    const hasActive = service && hasActiveRate(getAccountRates(account))

    return service && account && hasActive
      ? getAccountRates(account)
      : !account
      ? []
      : account?.relationships?.parent?.data?.id
      ? getRelevantRates(getAccount(queryCache, account?.relationships?.parent?.data?.id))
      : []
  }

  return account
    ? getRelevantRates(account)
    : []
}

function PlainServiceGroup ({
  serviceCategory,
  account,
  service,
  rate,
  cogs,
  onChangeServiceCategory,
  onChangeService,
  onChangeRate,
  onChangeCogs,
  serviceCategories = [],
  services = [],
  allRates = []
}: ServiceGroupProps) {
  const globalRates = allRates.filter(rate => (
    !rate.relationships.account || rate.relationships.account.data === null
  ))

  const accountRates = useAccountRates(service, account)

  return (
    <Group title={translate('Service')}>
      <Field.Container>
        <ServiceCategory
          serviceCategories={serviceCategories}
          serviceCategory={serviceCategory}
          onChangeServiceCategory={onChangeServiceCategory}
          onChangeService={onChangeService} />
        <Service service={service} services={services} onChangeService={onChangeService} />
        <RateCogs
          rate={rate}
          cogs={cogs}
          service={service}
          rates={accountRates.length > 0 ? accountRates : globalRates}
          onChangeRate={onChangeRate}
          onChangeCogs={onChangeCogs} />
      </Field.Container>
    </Group>
  )
}

const mapStateToProps = (state: RootState) => ({
  reportId: state.reports.filters.report
})

const mapReportToProps = (props: ReturnType<typeof mapStateToProps>) => ({
  ...insertIf(props.reportId, {
    report: (q: QueryBuilder) => q.findRecord({ type: 'report', id: props.reportId as string })
  })
})

const getReportServices = (props: {report?: ReportModel}) => (
  props.report
    ? ({
      reportServices: (q: QueryBuilder) => (
        q.findRelatedRecords(
          { type: 'dset', id: props.report?.attributes.dset as string },
          'services'
        ).sort('description')
      )
    })
    : {}
)

const getReportCategoriesAndCategoryServices = ({
  report,
  service,
  serviceCategory,
  reportServices = []
}: {
  account: Resources['account']
  report?: ReportModel
  service?: ServiceModel
  serviceCategory?: ServiceCategoryModel
  reportServices?: ServiceModel[]
}) => ({
  ...insertIf(reportServices, {
    serviceCategories: (q: QueryBuilder) => q.findRecords('servicecategory')
      .filter({ relation: 'services', records: reportServices, op: 'some' })
      .sort('name')
  }),
  ...insertIf(serviceCategory && report, {
    services: (q: QueryBuilder) => (
      q.findRelatedRecords(
        { type: 'dset', id: report?.attributes.dset as string },
        'services'
      )
        .filter({
          relation: 'servicecategory',
          record: serviceCategory as ServiceCategoryModel
        })
        .sort('description')
    )
  }),
  ...insertIf(service, {
    allRates: (q: QueryBuilder) => q.findRelatedRecords(service as ServiceModel, 'rates')
  })
})

export const ServiceGroup = compose(
  connect(mapStateToProps),
  withData(mapReportToProps),
  withData(getReportServices),
  withData(getReportCategoriesAndCategoryServices)
)(PlainServiceGroup) as FC<Omit<ServiceGroupProps, 'services' | 'rates'>>
