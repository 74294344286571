import { translate } from '@exivity/translations'

import { pages } from '../../domains'

export const resetPassword = {
  path: '/resetpassword/:token?',
  meta: {
    title: () => translate('Reset password'),
    excludeFromSearchIndex: true
  },
  component: pages.auth.ResetPassword,
  requiresAuth: false
}
