import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { MdDashboard } from 'react-icons/md'

const Dashboard = lazy(() => import(
  /* webpackChunkName: 'dashboard' */ '../domains/dashboard/pages')
)

export const home = {
  path: '/home',
  meta: {
    title: () => translate('Dashboard'),
    icon: MdDashboard
  },
  component: Dashboard
}
