import {
  Record,
  RecordRelationship,
  RecordHasOneRelationship,
  RecordHasManyRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

import { EnumLiteralsOf } from './index'

const SERVICE_TYPE = 'service'

export const ServiceType = Object.freeze({
  ServiceNameInHeader: 'service_name_in_header' as 'SERVICE_NAME_IN_HEADER',
  ServiceNameInData: 'service_name_in_data' as 'SERVICE_NAME_IN_DATA'
})

export type ServiceType = EnumLiteralsOf<typeof ServiceType>

export const ServiceChargeModel = Object.freeze({
  Peak: 'peak' as 'PEAK',
  Average: 'average' as 'AVERAGE',
  SpecificDay: 'specific_day' as 'SPECIFIC_DAY',
  LastDay: 'last_day' as 'LAST_DAY'
})

export type ServiceChargeModel = EnumLiteralsOf<typeof ServiceChargeModel>

export const ServiceInterval = Object.freeze({
  Individually: 'individually' as 'INDIVIDUALLY',
  Day: 'day' as 'DAY',
  Month: 'month' as 'MONTH'
})

export type ServiceInterval = EnumLiteralsOf<typeof ServiceInterval>

export const ServiceChargeType = Object.freeze({
  DeprecatedManual: 'manual' as 'MANUAL',
  DeprecatedAutomatic: 'automatic' as 'AUTOMATIC',
  DeprecatedManualPerInterval: 'manual_per_interval' as 'MANUAL_PER_INTERVAL',
  DeprecatedAutomaticPerInterval: 'automatic_per_interval' as 'AUTOMATIC_PER_INTERVAL',
  Manual: 'manual_per_unit' as 'MANUAL_PER_UNIT',
  Automatic: 'automatic_per_unit' as 'AUTOMATIC_PER_UNIT',
  ManualTieredStandard: 'manual_tiered_standard' as 'MANUAL_TIERED_STANDARD',
  ManualTieredInherited: 'manual_tiered_inherited' as 'MANUAL_TIERED_INHERITED',
  None: 'none' as 'NONE',
  Other: 'other' as 'OTHER'
})

export type ServiceChargeType = EnumLiteralsOf<typeof ServiceChargeType>

export function hasTieredChargeType (service: ServiceModel) {
  return service.attributes.charge_type === ServiceChargeType.ManualTieredInherited
    || service.attributes.charge_type === ServiceChargeType.ManualTieredStandard
}

export const ServiceCogsType = Object.freeze({
  DeprecatedManualPerInterval: 'manual_per_interval' as 'MANUAL_PER_INTERVAL',
  DeprecatedAutomaticPerInterval: 'automatic_per_interval' as 'AUTOMATIC_PER_INTERVAL',
  None: 'none' as 'NONE',
  Manual: 'manual_per_unit' as 'MANUAL_PER_UNIT',
  Automatic: 'automatic_per_unit' as 'AUTOMATIC_PER_UNIT',
  ManualTieredStandard: 'manual_tiered_standard' as 'MANUAL_TIERED_STANDARD',
  ManualTieredInherited: 'manual_tiered_inherited' as 'MANUAL_TIERED_INHERITED'
})

export type ServiceCogsType = EnumLiteralsOf<typeof ServiceCogsType>

export function hasTieredCogsType (service: ServiceModel) {
  return service.attributes.cogs_type === ServiceCogsType.ManualTieredInherited
    || service.attributes.cogs_type === ServiceCogsType.ManualTieredStandard
}

export function isServiceTiered (service: ServiceModel) {
  return hasTieredChargeType(service) || hasTieredCogsType(service)
}

export const ServiceProrationType = Object.freeze({
  Full: 'full' as 'FULL',
  None: 'none' as 'NONE'
})

export type ServiceProrationType = EnumLiteralsOf<typeof ServiceProrationType>

export interface ServiceModel extends Record {
  type: typeof SERVICE_TYPE
  attributes: ServiceAttributes
  relationships?: ServiceRelationships
}

export interface ServiceAttributes {
  key: string
  category_id: string
  description: string
  unit_label: string
  dset: string
  type: ServiceType
  usage_col: string
  consumption_col: string
  instance_col: string
  interval: ServiceInterval
  charge_model: ServiceChargeModel
  charge_type: ServiceChargeType
  cogs_type: ServiceCogsType
  proration_type: ServiceProrationType
  readonly created_at?: string
  readonly updated_at?: string
  readonly seen_at?: string
  readonly orphan?: boolean
}

export interface ServiceRelationships extends Dict<RecordRelationship> {
  servicecategory: RecordHasOneRelationship
  rates: RecordHasManyRelationship
  adjustments: RecordHasManyRelationship
  dset: RecordHasOneRelationship
  items: RecordHasManyRelationship
}

export const DEFAULT_SERVICE: ServiceModel = {
  id: '',
  type: SERVICE_TYPE,
  attributes: {
    key: '',
    category_id: '',
    description: '',
    unit_label: '',
    dset: '',
    type: ServiceType.ServiceNameInHeader,
    usage_col: '',
    consumption_col: '',
    instance_col: '',
    interval: ServiceInterval.Individually,
    charge_model: ServiceChargeModel.Peak,
    charge_type: ServiceChargeType.Automatic,
    cogs_type: ServiceCogsType.Automatic,
    proration_type: ServiceProrationType.Full
  }
}

export const createService = makeCreateModel<ServiceModel>(
  SERVICE_TYPE,
  DEFAULT_SERVICE.attributes
)
