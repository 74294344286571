"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.of = void 0;
function of(data) {
    return {
        data: data,
        children: []
    };
}
exports.of = of;
