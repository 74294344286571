import { PartialResources } from '@exivity/data-layer'

import { makeCreateModel } from '../../utils/model'

const ACCOUNT_TYPE = 'account'

export type LevelKey =
  | 'lvl1_key'
  | 'lvl2_key'
  | 'lvl3_key'
  | 'lvl4_key'
  | 'lvl5_key'

export const accountLevelKeys: { [key: number]: LevelKey } = {
  1: 'lvl1_key',
  2: 'lvl2_key',
  3: 'lvl3_key',
  4: 'lvl4_key',
  5: 'lvl5_key'
} as const

export const DEFAULT_ACCOUNT: PartialResources['account'] = {
  id: '',
  type: ACCOUNT_TYPE,
  attributes: {
    name: '',
    level: 1,
    lvl1_key: '',
    lvl2_key: '',
    lvl3_key: '',
    lvl4_key: '',
    lvl5_key: ''
  }
}

export const createAccount = makeCreateModel<PartialResources['account']>(
  ACCOUNT_TYPE,
  DEFAULT_ACCOUNT.attributes
)
