import { createContext, useContext } from 'react';
export var TabsContext = createContext(null);
export var useTabsContext = function () {
    var context = useContext(TabsContext);
    if (!context) {
        throw new Error('useTabsContext should only be called within a child of a Tabs component');
    }
    var activeIndex = context.activeIndex, setActiveIndex = context.setActiveIndex;
    function next(tabAmount, disabledTabs) {
        var nextIndex = activeIndex + 1;
        while (true) {
            if (disabledTabs.includes(nextIndex))
                nextIndex++;
            else if (nextIndex >= tabAmount)
                nextIndex = 0;
            else
                break;
        }
        setActiveIndex(nextIndex);
    }
    function prev(tabAmount, disabledTabs) {
        var prevIndex = activeIndex - 1;
        while (true) {
            if (disabledTabs.includes(prevIndex))
                prevIndex--;
            else if (prevIndex < 0)
                prevIndex = tabAmount - 1;
            else
                break;
        }
        setActiveIndex(prevIndex);
    }
    return {
        activeIndex: activeIndex,
        setActiveIndex: setActiveIndex,
        next: next,
        prev: prev
    };
};
