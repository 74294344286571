import { get } from '../../../API/index'
import ClassAction, { createClassAction } from '../../../store/middleware/ClassAction'
import { LogComponentKeys } from '../../../components/pages/Administration/componentKeys'
import { Log } from '../../../reducers/data/logs'
import { ActionDispatcher } from '../../../store/utils'
import { hasPermission } from '../../../components/organisms/Permission/helpers'
import { UserGroupPermission } from '../../../data/types'

export class UpdateAllLogs extends ClassAction {
  static readonly type = 'DATA_LOGS_UPDATE_LOGS'
  readonly type = UpdateAllLogs.type
  constructor (public component: LogComponentKeys, public data: Log[]) {
    super()
  }
}

export class UpdateShortLogs extends ClassAction {
  static readonly type = 'DATA_LOGS_UPDATE_SHORT_LOGS'
  readonly type = UpdateShortLogs.type
  constructor (public component: LogComponentKeys, public name: string, public data: Log) {
    super()
  }
}

export class DeleteShortLog extends ClassAction {
  static readonly type = 'DATA_LOGS_DELETE_SHORT_LOG'
  readonly type = DeleteShortLog.type
  constructor (public component: LogComponentKeys, public name: string) {
    super()
  }
}

export const updateAllLogs = createClassAction(UpdateAllLogs)
export const deleteShortLog = createClassAction(DeleteShortLog)
export const updateShortLogs = createClassAction(UpdateShortLogs)

export interface FetchLogsOptions {
  component: string
  limit: number
  filter: string
  truncate: number
}

const defaultOptions = {
  limit: 100,
  filter: '',
  truncate: 1000
}

export function fetchLogs (
  componentName: LogComponentKeys,
  options = defaultOptions
): ActionDispatcher {
  return (dispatch, getState) => {
    const component = componentName || getState().logs.selectedComponent
    options = Object.assign({}, { component }, options)
    get('/log', options).then(data => dispatch(updateAllLogs(component, data.logfiles)))
  }
}

export function fetchShortLog (
  componentName: LogComponentKeys,
  options: Partial<FetchLogsOptions>
): ActionDispatcher {
  return (dispatch, getState) => {
    const state = getState()
    const component = componentName || state.logs.selectedComponent
    const finalOptions = { ...defaultOptions, ...options, truncate: 25, component }
    const permissions = state.auth.permissions

    if (hasPermission(permissions, { allow: [UserGroupPermission.ViewLogs] })) {
      get('/log', finalOptions)
        .then(data => dispatch(updateShortLogs(component, finalOptions.filter, data.logfiles[0])))
    }
  }
}
