var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fromTheme } from '../Theme';
export var JoinChildren = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: ", "px;\n\n  @media (max-width: ", ") {\n    > {\n      flex-grow: 1;\n    }\n  }\n\n  > * {\n    &:not(:first-child):not(:last-child) {\n      margin-left: 0;\n      border-radius: 0;\n\n      & {\n        border-radius: 0;\n      }\n    }\n  \n    &:not(:only-child):first-child {\n      border-top-right-radius: 0;\n      border-bottom-right-radius: 0;\n\n      & * {\n        border-top-right-radius: 0;\n        border-bottom-right-radius: 0;\n      }\n    }\n  \n    &:not(:only-child):last-child {\n      margin-left: 0;\n      border-top-left-radius: 0;\n      border-bottom-left-radius: 0;\n      & * {\n        border-top-left-radius: 0;\n        border-bottom-left-radius: 0;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: ", "px;\n\n  @media (max-width: ", ") {\n    > {\n      flex-grow: 1;\n    }\n  }\n\n  > * {\n    &:not(:first-child):not(:last-child) {\n      margin-left: 0;\n      border-radius: 0;\n\n      & {\n        border-radius: 0;\n      }\n    }\n  \n    &:not(:only-child):first-child {\n      border-top-right-radius: 0;\n      border-bottom-right-radius: 0;\n\n      & * {\n        border-top-right-radius: 0;\n        border-bottom-right-radius: 0;\n      }\n    }\n  \n    &:not(:only-child):last-child {\n      margin-left: 0;\n      border-top-left-radius: 0;\n      border-bottom-left-radius: 0;\n      & * {\n        border-top-left-radius: 0;\n        border-bottom-left-radius: 0;\n      }\n    }\n  }\n"])), function (_a) {
    var _b = _a.gap, gap = _b === void 0 ? 0 : _b;
    return gap;
}, fromTheme(function (theme) { return theme.breakpoints[0]; }));
var templateObject_1;
