import { JSONAPIRequestProcessor } from '@orbit/jsonapi'
import { schema } from '@exivity/data-layer'

import ExivityJSONAPISource from '../lib/ExivityJSONAPISource'
import ExivityJSONAPISerializer from '../lib/ExivityJSONAPISerializer'
import { ExivityJSONAPIURLBuilder } from '../lib/ExivityJSONAPIURLBuilder'
import { debug } from '../../utils/log'
import { getUserSessionStorage } from '../../store/storage'
import config from '../../application/config'

const defaultTimeout = 1000 * 60 * 10 // Set to 10m, which emulates Proximity and NGINX.

function createServer () {
  const server = new ExivityJSONAPISource({
    schema,
    name: 'server',
    namespace: 'v' + config.app.apiVersion,
    SerializerClass: ExivityJSONAPISerializer,
    URLBuilderClass: ExivityJSONAPIURLBuilder
  })

  let currentSettings = {
    apiRoot: null,
    token: null
  }

  function updateServerSettings (apiRoot) {
    currentSettings = Object.assign({}, currentSettings, {
      apiRoot,
      token: getUserSessionStorage.fromEitherStorage('session')?.token ?? ''
    })

    server.requestProcessor = new JSONAPIRequestProcessor({
      sourceName: server.name,
      SerializerClass: ExivityJSONAPISerializer,
      URLBuilderClass: ExivityJSONAPIURLBuilder,
      allowedContentTypes: server.requestProcessor.allowedContentTypes,
      defaultFetchSettings: {
        ...server.requestProcessor.defaultFetchSettings,
        timeout: defaultTimeout,
        headers: {
          ...server.requestProcessor.defaultFetchSettings.headers,
          Authorization: `Bearer ${currentSettings.token}`
        }
      },
      namespace: 'v1',
      host: currentSettings.apiRoot,
      schema,
      keyMap: server.requestProcessor.keyMap
    })

    server.host = currentSettings.apiRoot

    debug('Configuration for remote store changed')
  }

  return {
    server,
    updateServerSettings
  }
}

export const { server, updateServerSettings } = createServer()
