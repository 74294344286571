import React, { useEffect } from 'react'
import { Layout } from '@exivity/ui'
import { queries, useAPIQuery } from '@exivity/data-layer'
import { useDispatch } from 'react-redux'

import { fetchExtractors } from '../../../actions/datasources/extractors'
import { fetchTransformers } from '../../../actions/datasources/transformers'
import { WorkflowList, Workflow } from '../components'

const INCLUDE_LATEST_RUN = {
  include: ['runs'],
  settings: {
    params: {
      'related[runs][limit]': 1,
      'related[runs][sort]': '-start_ts'
    }
  }
}

export default function Workflows () {
  const queryAPI = useAPIQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    // needed for the extractor and transformer workflow steps
    dispatch(fetchExtractors())
    dispatch(fetchTransformers())

    queryAPI(queries.findAll('environment'))
    queryAPI(queries.findAll('budget'))

    /*
    * There are a few things happening here:
    * 1. all workflows are fetched to make sure the list is up to date.
    * 2. the last run is fetched for every workflow so a status icon can be shown in
    *    the workflow list.
    * 3. steps and schedules for all workflows are included so a user can navigate between workflows
    *    without having to refetch its steps and schedules every time.
    */
    queryAPI(
      queries.findAll('workflow'),
      {
        sources: {
          server: {
            include: ['steps', 'schedules', ...INCLUDE_LATEST_RUN.include],
            settings: {
              params: {
                ...INCLUDE_LATEST_RUN.settings.params
              }
            }
          }
        }
      })
  }, [])

  return (
    <Layout.MasterDetail>
      <WorkflowList />
      <Workflow />
    </Layout.MasterDetail>
  )
}
