import { Record, RecordIdentity, RecordRelationship } from '@orbit/data'
import { mapValues } from 'lodash'

function iterateRelatedRecords (record: Record, cb: (record: Record) => void) {
  Object.values(record.relationships || {}).forEach((relationship) => {
    const data = relationship?.data

    if (Array.isArray(data)) {
      data.forEach(cb)
    } else if (data) {
      cb(data)
    }
  })
}

export function listRelatedRecords (record: Record) {
  const list: Record[] = []
  iterateRelatedRecords(record, (record) => list.push(record))
  return list
}

export function mapRelatedRecords (record: Record, cb: (record: Record) => RecordIdentity | null) {
  return mapValues(record.relationships || {}, (relationship) => {
    if (relationship?.data === undefined) return relationship

    if (Array.isArray(relationship.data)) {
      return {
        ...relationship,
        data: relationship.data.map(cb).filter(record => record !== null)
      } as RecordRelationship
    }

    return {
      ...relationship,
      data: (relationship.data && cb(relationship.data)) || null
    } as RecordRelationship
  })
}
