import React from 'react'

interface Props {
  children: any
}

export const ValuesCallback: React.FC<Props> = ({ children }) => {
  return children
}

ValuesCallback.displayName = 'ValuesCallback'

export default ValuesCallback
