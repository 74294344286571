var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { globalSectionSpacing } from '../utils';
import { Text } from '../Text';
export var Paragraph = styled(Text).attrs({
    as: 'p'
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), globalSectionSpacing);
var templateObject_1;
