import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectFilter } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { queries, Resources, useCacheQueryFn, useCacheQuery } from '@exivity/data-layer'
import { exists } from '@exivity/fp'

import { reportSelectors, reportsActions } from '../../state'
import { stringSortRecordsBy } from '../../../../utils/array'

const getServiceId = (service: Resources['service']) => service.id
const getServiceDescription = (service: Resources['service']) => service.attributes.description

export const useReportServices = () => {
  const serviceIds = useSelector(reportSelectors.getReportServiceIds)
  const queryFn = useCacheQueryFn()

  return useMemo(() => {
    const queryService = (id: string) => queryFn(queries.find('service', id))

    return serviceIds
      .map(queryService)
      .filter(exists)
      .sort(stringSortRecordsBy('description')) as Resources['service'][]
  }, [serviceIds, queryFn])
}

export function ServiceFilter () {
  const selectedServiceId = useSelector(reportSelectors.getSelectedServiceId)
  const selectedService = useCacheQuery(queries.find('service', selectedServiceId))
  const services = useReportServices()
  const dispatch = useDispatch()

  return (
    <SelectFilter
      searchable
      placeholder={translate('Service')}
      data={services}
      valueAccessor={getServiceId}
      labelAccessor={getServiceDescription}
      inputValue={selectedService?.attributes?.description ?? undefined}
      value={selectedServiceId || undefined}
      clearText={translate('Clear filter')}
      onChange={(id) => {
        dispatch(reportsActions.filters.updateService(id))
      }}
      onClear={
        selectedServiceId
          ? () => dispatch(reportsActions.filters.updateService(null))
          : undefined} />
  )
}
