import React from 'react'
import { CrudRecord } from 'react-crud-hook'
import { Group, Field, Label, Select, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { prop } from '@exivity/fp'

import chargeModels from '../chargeModels'
import { ServiceModel, ServiceChargeModel, ServiceInterval } from '../../../../data/types'
import intervalLabels from '../intervalLabels'
import { useCanRenderFeature } from '../../../../components/organisms/Feature/Feature'

import { groupIcon } from './groupIcon'

interface IntervalGroupProps {
  editing?: boolean
  service: CrudRecord<ServiceModel>
}

export default function IntervalGroup ({ service, editing = true }: IntervalGroupProps) {
  const canRenderFeature = useCanRenderFeature()

  return (
    <Group title={translate('Interval')} Icon={groupIcon(editing)}>
      <Field.Container>
        <Field>
          <Label>
            {translate('Interval')}
          </Label>
          <Select
            placeholder={translate('Choose interval')}
            labelAccessor={prop('label')}
            valueAccessor={prop('value')}
            disabled={!editing}
            value={service.attributes.interval}
            data={intervalLabels}
            onChange={(interval) => {
              service.setAttribute('interval', interval)

              if (interval !== ServiceInterval.Month) {
                service.setAttribute('charge_model', null)
              }
            }} />
        </Field>
        {service.attributes.interval === ServiceInterval.Month
          && (
            <Field>
              <Label>
                {translate('Charge model')}
              </Label>
              <Select
                labelAccessor={prop('label')}
                valueAccessor={prop('value')}
                placeholder={translate('Choose model')}
                disabled={!editing}
                value={service.attributes.charge_model}
                data={chargeModels.filter((model) => (
                  canRenderFeature('chargeModelSpecificDay')
                  || model.value !== ServiceChargeModel.SpecificDay
                ))}
                onChange={service.setAttribute('charge_model')} />
            </Field>
          )}
        {service.attributes.charge_model === ServiceChargeModel.SpecificDay && (
          <Field>
            <Label.Group htmlFor='charge_model_specific_day'>
              <Label>{translate('Charge model day')}</Label>
              <Label.Sub>
                {translate('Select from range between')} 1 - 28
              </Label.Sub>
            </Label.Group>
            <Input
              type='number'
              placeholder={
                translate('Day of the month')
              }
              min={1}
              max={28}
              disabled={!editing}
              value={service.attributes.charge_model_specific_day}
              onChange={service.setAttribute('charge_model_specific_day')} />
          </Field>
        )}
      </Field.Container>
    </Group>
  )
}
