import { parsePath } from 'history';
const trimTrailingSlashes = (path) => path.replace(/\/+$/, '');
const normalizeSlashes = (path) => path.replace(/\/\/+/g, '/');
const joinPaths = (paths) => normalizeSlashes(paths.join('/'));
const splitPath = (path) => normalizeSlashes(path).split('/');
function resolvePathname(toPathname, fromPathname) {
    const segments = splitPath(trimTrailingSlashes(fromPathname));
    const relativeSegments = splitPath(toPathname);
    relativeSegments.forEach(segment => {
        if (segment === '..') {
            // Keep the root "" segment so the pathname starts at /
            if (segments.length > 1)
                segments.pop();
        }
        else if (segment !== '.') {
            segments.push(segment);
        }
    });
    return segments.length > 1 ? joinPaths(segments) : '/';
}
export function resolvePath(to, fromPathname = '/') {
    const { pathname: toPathname, search = '', hash = '' } = typeof to === 'string'
        ? parsePath(to)
        : to;
    const pathname = toPathname
        ? resolvePathname(toPathname, toPathname.startsWith('/') ? '/' : fromPathname)
        : fromPathname;
    return { pathname, search, hash };
}
