import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { CalendarMode } from '@exivity/ui'

import { API_DATE_FORMAT } from '../../../API/formats'
import { RootState } from '../../../reducers'
import { createAsyncThunk } from '../../../store/utils'
import { get } from '../../../API/fetch'

export enum AuditActionKeys {
 LOGIN = 'login',
 ACCESS = 'access',
 CREATE = 'create',
 MODIFY = 'modify',
 DELETE = 'delete',
 OTHER = 'other'
}

export enum AuditCategoryKeys {
 ACCOUNT = 'account',
 ADJUSTMENT = 'adjustment',
 BUDGET = 'budget',
 EXTRACTOR = 'extractor',
 RATE = 'rate',
 RDF = 'rdf',
 REPORT = 'report',
 SERVICE = 'service',
 SUBSCRIPTION = 'subscription',
 SYSTEM = 'system',
 TRANSFORMER = 'transformer',
 USER = 'user',
 USER_GROUP = 'usergroup',
 WORKFLOW = 'workflow'
}

export interface AuditItem {
  id: number
  date: string
  user_id: string
  user_name: string
  component: string
  category: AuditCategoryKeys
  action: AuditActionKeys
  message: string
  created_at: number
}

export interface AuditState {
  data: AuditItem[]
  dateRange: [string, string]
  mode: CalendarMode
  selectedUsername: null | string
  selectedCategory: null | AuditCategoryKeys
  selectedAction: null | AuditActionKeys
}

const fetchAuditTrail = createAsyncThunk(
  'administration/audit/fetch',
  (_, { getState }) => {
    const [start, end] = getState().audit.dateRange
    return get('/audit', { start, end })
  })

const initialState: AuditState = {
  data: [],
  dateRange: [
    format(startOfMonth(new Date()), API_DATE_FORMAT),
    format(endOfMonth(new Date()), API_DATE_FORMAT)
  ],
  mode: CalendarMode.Days,
  selectedUsername: null,
  selectedCategory: null,
  selectedAction: null
}

export const { actions, reducer } = createSlice({
  name: 'administration/audit',
  initialState,
  reducers: {
    updateData (state, action: PayloadAction<AuditItem[]>) {
      state.data = action.payload
    },
    updateDateRange (state, action: PayloadAction<[string, string]>) {
      state.selectedUsername = null
      state.dateRange = action.payload
    },
    updateCalendarMode (state, action: PayloadAction<CalendarMode>) {
      state.mode = action.payload
    },
    updateAction (state, action: PayloadAction<AuditState['selectedAction']>) {
      state.selectedAction = action.payload
    },
    updateCategory (state, action: PayloadAction<AuditState['selectedCategory']>) {
      state.selectedCategory = action.payload
    },
    updateUsername (state, action: PayloadAction<AuditState['selectedUsername']>) {
      state.selectedUsername = action.payload
    }
  },
  extraReducers (builder) {
    builder
      .addCase(fetchAuditTrail.fulfilled, (state, action) => {
        state.data = action.payload.audit
      })
      .addCase(fetchAuditTrail.rejected, (state) => {
        state.data = []
      })
  }
})

export const audit = reducer

export const auditActions = actions

export const auditSelectors = {
  getData: (state: RootState) => state.audit.data,
  getDateRange: (state: RootState) => state.audit.dateRange,
  getCalendarMode: (state: RootState) => state.audit.mode,
  getSelectedUsername: (state: RootState) => state.audit.selectedUsername,
  getSelectedCategory: (state: RootState) => state.audit.selectedCategory,
  getSelectedAction: (state: RootState) => state.audit.selectedAction
}

export const auditThunks = {
  fetchAuditTrail
}
