import React, { useEffect, useState } from 'react'
import { Tabs, Heading, useModal, Description, Input, Layout } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { UserSource } from '../../../data/types'
import Personal from '../components/Personal'
import { Preferences } from '../components/Preferences'
import { useCanRenderFeature } from '../../../components/organisms/Feature/Feature'
import { useCurrentUser } from '../../auth/hooks/useCurrentUser'

function CurrentPassword ({ setCurrentPassword }: { setCurrentPassword: (val: string) => void }) {
  const [password, setPassword] = useState('')

  useEffect(() => {
    setCurrentPassword(password)
  }, [password])

  return (
    <>
      <Description>
        {translate('Please enter your current password to make these changes to your profile.')}
      </Description>
      <Input
        type='password'
        name='current_password'
        value={password}
        autoComplete='password'
        placeholder={translate('Password')}
        onChange={setPassword} />
    </>
  )
}

export function MyProfile () {
  const user = useCurrentUser()
  const canRenderFeature = useCanRenderFeature()
  const { confirm } = useModal()

  if (!user) return null

  return (
    <Layout.Detail>
      {canRenderFeature('enhancedUserProvisioning')
        ? (
          <>
            {user.source === UserSource.SAML
              ? <Heading>{user.display_name}</Heading>
              : (
                <Heading.Input key={user.id}
                  label={translate('display name')}
                  placeholder={translate('Display name')}
                  autoFocus={!user.id}
                  value={user.display_name}
                  onChange={user.setDisplayName} />
              )}
          </>
        )
        : (
          <>
            {user.source !== UserSource.LOCAL
              ? <Heading>{user.username}</Heading>
              : (
                <Heading.Input key={user.id}
                  label={translate('Username')}
                  placeholder={translate('Username')}
                  autoFocus={!user.id}
                  value={user.username}
                  onChange={user.setUsername} />
              )
            }
          </>
        )
      }
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab>{translate('Personal')}</Tabs.Tab>
          {canRenderFeature('translations') && (
            <Tabs.Tab>{translate('Preferences')}</Tabs.Tab>
           ) as any}
        </Tabs.TabList>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <Personal
              source={user.source}
              username={user.username}
              email={user.email_address}
              newPassword={user.password}
              verifyNewPassword={user.verifyPassword}
              onChangeUsername={user.setUsername}
              onChangeEmail={user.setEmail}
              onChangeNewPassword={user.setPassword}
              onChangeVerifyNewPassword={user.setVerifyPassword}
              onSave={() => {
                confirm({
                  title: translate('Password verification'),
                  body: <CurrentPassword setCurrentPassword={user.setCurrentPassword} />
                })
                  .then(user.save)
              }} />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Preferences />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>
    </Layout.Detail>
  )
}
