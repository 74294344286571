import { schema } from '@exivity/data-layer'
import Store from '@orbit/memory'

interface Enhanced extends Store {
  reset: () => void
  disableProcessors: () => void
  enableProcessors: () => void
}

export function createMemory () {
  const store: Store = new Store({
    name: 'memory',
    schema
  }) as Enhanced

  (store as Enhanced).reset = () => {
    store.cache.reset()
  }

  (store as Enhanced).disableProcessors = () => {
    // @ts-ignore
    store.cache._disabledProcessors = store.cache._processors
    // @ts-ignore
    store.cache._processors = []
  }

  (store as Enhanced).enableProcessors = () => {
    // @ts-ignore
    store.cache._processors = store.cache._disabledProcessors || store.cache._processors
    // @ts-ignore
    delete store.cache._disabledProcessors
  }

  return store
}
