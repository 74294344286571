import React from 'react'
import PropTypes from 'prop-types'

const List = ({ files, selected, onSelectFile }) => (
  <ul className='uk-nav uk-nav-default'>
    {files.map((file, index) => (
      <li key={file} className={selected === index ? 'uk-active' : ''}>
        <a href='.' onClick={(e) => {
          e.preventDefault()
          onSelectFile(index)
        }}>{file}
        </a>
      </li>
    ))}
  </ul>
)

List.propTypes = {
  files: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  selected: PropTypes.number
}

export default List
