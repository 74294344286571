import React from 'react'
import { ModalProvider } from '@exivity/ui'

import { ThemeProvider } from './ThemeProvider'
import { CrudProvider } from './CrudProvider'
import { DataProvider } from './DataProvider'
import { UIExtensionsProvider } from './UIExtensionsProvider'
import { StateProvider } from './StateProvider'

export function AllProviders ({ children }: any) {
  return (
    <StateProvider>
      <ThemeProvider>
        <CrudProvider>
          <DataProvider>
            <UIExtensionsProvider>
              <ModalProvider>
                {children}
              </ModalProvider>
            </UIExtensionsProvider>
          </DataProvider>
        </CrudProvider>
      </ThemeProvider>
    </StateProvider>
  )
}
