import { translate } from '@exivity/translations'

import syncer from '../data/syncer'
import { fetchSystemData } from '../actions/system'
import { workThunks } from '../domains/work/thunks'
import { configurationThunks } from '../domains/administration/state/configuration'

import reduxStore from './../store'

const defaultOptions = {
  config: true,
  models: [],
  handleRejections: true
}

export const sync = (options = {}) => (
  new Promise((resolve, reject) => {
    const syncOptions = { ...defaultOptions, ...options }

    if (syncOptions.config) {
      reduxStore.dispatch(fetchSystemData())
      reduxStore.dispatch(configurationThunks.fetchConfig())
    }

    syncer(syncOptions)
      .then(() => {
        reduxStore.dispatch(workThunks.showSuccessMessage(translate('Data synchronization successful')))
        resolve()
      })
      .catch(error => {
        if (syncOptions.handleRejections) {
          reduxStore.dispatch(
            error.message
              ? workThunks.showErrorMessage(error.message)
              : workThunks.showDefaultApiErrorMessage()
          )
        } else {
          reject(error)
        }
      })
  })
)
