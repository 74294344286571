import React, { useEffect } from 'react'
import { translate } from '@exivity/translations'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Markdown, useModal } from '@exivity/ui'

import { profileActions, profileSelectors } from '../state'
import { configurationSelectors } from '../../../domains/administration/state/configuration'
import { useLogout } from '../../auth/hooks'

export function useDisclaimer () {
  const dispatch = useDispatch()
  const logout = useLogout()

  const { confirm } = useModal()

  const userAcceptanceIsPopulated = useSelector(profileSelectors.isPopulatedByExternalSource)
  const disclaimer = useSelector(configurationSelectors.getDisclaimer, shallowEqual)
  const isAccepted = useSelector(profileSelectors.disclaimerIsAccepted(disclaimer))
  const isEnabled = useSelector(configurationSelectors.isDisclaimerEnabled)

  useEffect(() => {
    if (isEnabled && userAcceptanceIsPopulated && !isAccepted) {
      confirm({
        title: disclaimer.title || '',
        body: <Markdown>{disclaimer.text || ''}</Markdown>,
        acceptLabel: disclaimer.agreeButtonText || '',
        declineLabel: translate('Logout')
      })
        .then(() => {
          dispatch(profileActions.updateAcceptedDisclaimer(disclaimer))
        })
        .catch(() => {
          logout()
        })
    }
  })
}
