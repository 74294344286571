import { withData } from 'react-orbitjs'
import { compose } from 'redux'
import { withRouter } from '@exivity/routing'

import { Datasets } from './Datasets'

export default compose(
  withData({ datasets: q => q.findRecords('dset') }),
  withRouter
)(Datasets)
