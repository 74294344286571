import { fetchWithHooks } from '../../fetchWithHooks/fetchWithHooks'
import {
  handleResponseErrors,
  handleJsonResponse,
  handleWorkerThreads,
  parseJson,
  showErrorToUser
} from '../../hooks'
import { fetchWithTimeout } from '../../fetch/fetchWithTimeout'

import {
  checkIncludedResourcesResult,
  addIncludedResourcesParams
} from './reportHooks'

export const fetchIncludedResources = (
  endpoint: string,
  fetchOptions: RequestInit,
  otherOptions: any
) => fetchWithHooks({
  before: [
    handleWorkerThreads('foreground'),
    addIncludedResourcesParams
  ],
  after: [
    handleResponseErrors,
    handleJsonResponse,
    parseJson,
    checkIncludedResourcesResult,
    handleWorkerThreads('foreground')
  ],
  error: [
    handleResponseErrors,
    showErrorToUser,
    handleWorkerThreads('foreground')
  ]
}, fetchWithTimeout)(endpoint, fetchOptions, otherOptions)
