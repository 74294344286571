var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { globalFont, classNames } from '../utils';
import { themeColor } from '../Theme';
var insideStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input {\n    padding-top: 14px;\n    padding-bottom: 0px;\n  }\n  \n  i {\n    padding-top: 8px; \n  }\n  \n  .floatinglabel {\n    &__label {\n      &--floating {\n        top: 1px;\n      }\n      \n      &--inline {\n        top: 22px;\n      }\n    }\n  }\n"], ["\n  input {\n    padding-top: 14px;\n    padding-bottom: 0px;\n  }\n  \n  i {\n    padding-top: 8px; \n  }\n  \n  .floatinglabel {\n    &__label {\n      &--floating {\n        top: 1px;\n      }\n      \n      &--inline {\n        top: 22px;\n      }\n    }\n  }\n"])));
var floatinglabelStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  box-sizing: border-box;\n  width: 100%;\n  \n  &.floatinglabel__container--inline {\n    i {\n      visibility: hidden;\n    }\n  }\n\n  .floatinglabel {\n    &__label {\n      ", ";\n      position: absolute;\n      left: 8px;\n      transition: .1s;\n      color: ", ";\n      pointer-events: none;\n      z-index: 2;\n\n        &--floating {\n          font-size: .8em;\n          top: -20px;\n        }\n\n        &--inline {\n          font-size: 1em;\n          top: 20px;\n          transform: translateY(-50%);\n        }\n    }\n  }\n  \n  &.floatinglabel__container--inside {\n    ", "\n  }\n"], ["\n  position: relative;\n  box-sizing: border-box;\n  width: 100%;\n  \n  &.floatinglabel__container--inline {\n    i {\n      visibility: hidden;\n    }\n  }\n\n  .floatinglabel {\n    &__label {\n      ", ";\n      position: absolute;\n      left: 8px;\n      transition: .1s;\n      color: ", ";\n      pointer-events: none;\n      z-index: 2;\n\n        &--floating {\n          font-size: .8em;\n          top: -20px;\n        }\n\n        &--inline {\n          font-size: 1em;\n          top: 20px;\n          transform: translateY(-50%);\n        }\n    }\n  }\n  \n  &.floatinglabel__container--inside {\n    ", "\n  }\n"])), globalFont, themeColor('gray'), insideStyle);
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), floatinglabelStyle);
export function FloatingLabel(_a) {
    var label = _a.label, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.outside, outside = _c === void 0 ? false : _c, children = _a.children;
    var _d = useState(null), element = _d[0], ref = _d[1];
    var _e = useState(false), isFocussed = _e[0], setIsFocussed = _e[1];
    var _f = useState(false), hasValue = _f[0], setHasValue = _f[1];
    var id = useMemo(function () { return uuidv4(); }, []);
    useEffect(function () {
        var setFocusOn = function () { return setIsFocussed(true); };
        var setFocusOff = function () { return setIsFocussed(false); };
        if (element) {
            element.addEventListener('focusin', setFocusOn);
            element.addEventListener('focusout', setFocusOff);
        }
        return function () {
            if (element) {
                element.removeEventListener('focusin', setFocusOn);
                element.removeEventListener('focusout', setFocusOff);
            }
        };
    }, [element]);
    useEffect(function () {
        if (!!(element === null || element === void 0 ? void 0 : element.value) !== hasValue) {
            setHasValue(!!(element === null || element === void 0 ? void 0 : element.value));
        }
    });
    return (_jsxs(Container, __assign({ className: classNames({
            'floatinglabel__container--inline': !isFocussed && !hasValue,
            'floatinglabel__container--inside': !outside
        }, className) }, { children: [_jsx("label", __assign({ htmlFor: id, className: classNames({
                    'floatinglabel__label--inline': !isFocussed && !hasValue,
                    'floatinglabel__label--floating': isFocussed || hasValue
                }, 'floatinglabel__label') }, { children: label }), void 0), typeof children === 'function'
                ? children({ id: id, ref: ref })
                : React.cloneElement(children, { id: id, ref: ref })] }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
