import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRoute, getTitleBreadcrumb } from '@exivity/routing'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

export function usePageTitle () {
  const appName = useSelector(configurationSelectors.getAppName)
  const pageTitle = getTitleBreadcrumb(useRoute().activeRoutes)

  useEffect(() => {
    document.title = pageTitle + ' | ' + appName
  }, [appName, pageTitle])

  return pageTitle
}
