import { fetchWithHooks } from '../fetchWithHooks/fetchWithHooks'
import {
  handleResponseErrors,
  handleWorkerThreads,
  notifyUserOnResponse,
  showErrorToUser
} from '../hooks'

export const JSONAPIResources = fetchWithHooks({
  before: [
    handleWorkerThreads('foreground')
  ],
  after: [
    handleResponseErrors,
    handleWorkerThreads('foreground'),
    notifyUserOnResponse
  ],
  error: [
    handleWorkerThreads('foreground'),
    handleResponseErrors,
    showErrorToUser
  ]
})
