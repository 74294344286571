import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  configurationActions,
  configurationThunks
} from '../../administration/state/configuration'

export function useDomainActions () {
  const dispatch = useDispatch()

  const updateDomain = useCallback((apiRoot: string) => {
    dispatch(configurationActions.updateConfig({ API_ROOT: apiRoot.replace(/\/$/, '') }))
  }, [])

  const onDomainChanged = useCallback((apiRoot: string) => {
    updateDomain(apiRoot)
    dispatch(configurationThunks.fetchConfig())
  }, [])

  return { updateDomain, onDomainChanged }
}
