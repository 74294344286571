var _a;
import { CalendarMode } from './CalendarMode';
import { dayRangeReducer, Days, StyledDays } from './Days';
import { Months, StyledMonths, monthRangeReducer } from './Months';
import { Quarters, StyledQuarters, quarterRangeReducer } from './Quarters';
import { Years, StyledYears, yearRangeReducer } from './Years';
var TIME_UNITS = (_a = {},
    _a[CalendarMode.Days] = {
        Calendar: Days,
        rangeReducer: dayRangeReducer
    },
    _a[CalendarMode.Months] = {
        Calendar: Months,
        rangeReducer: monthRangeReducer
    },
    _a[CalendarMode.Quarters] = {
        Calendar: Quarters,
        rangeReducer: quarterRangeReducer
    },
    _a[CalendarMode.Years] = {
        Calendar: Years,
        rangeReducer: yearRangeReducer
    },
    _a);
export function getCalendar(mode) {
    return TIME_UNITS[mode];
}
export { CalendarMode, StyledDays, StyledMonths, StyledQuarters, StyledYears };
