import { compose } from '@exivity/fp';
import { compile } from 'path-to-regexp';
import { createPath } from 'history';
import { useNavigate } from '../useNavigate';
import { useRoute } from '../useRoute';
export const NEW_PARAM = 'new';
export function useParams() {
    const { search, params: currentParams, route } = useRoute();
    const navigate = useNavigate();
    const toPath = compile(route.pathname);
    function setParams(param, value) {
        const path = (params) => createPath({
            pathname: toPath(Object.assign(Object.assign({}, currentParams), params)),
            search
        });
        const go = compose(navigate, path);
        if (typeof param !== 'string') {
            go(param);
        }
        else if (value === undefined) {
            return (value) => go({ [param]: value });
        }
        else {
            go({ [param]: value });
        }
    }
    return [currentParams, setParams];
}
