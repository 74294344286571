var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { globalFont, injectIf } from '../utils';
import { themePurpose } from '../Theme';
import { Label } from './Label';
import { Highlight } from './Highlight';
var _Text = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), globalFont, injectIf('active', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", " !important;\n  "], ["\n    color: ", " !important;\n  "])), themePurpose('brand'))));
var Text = _Text;
Text.displayName = 'Text';
Text.Label = Label;
Text.Highlight = Highlight;
export { Text };
var templateObject_1, templateObject_2;
