"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepend = void 0;
var ramda_1 = require("ramda");
/**
 * @description a → [a] → [a]
 */
exports.prepend = ramda_1.curry(function (a, fa) { return __spreadArray([a], fa); });
