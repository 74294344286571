const DEFAULT_PRECISION = 2
const DEFAULT_SEPARATOR = ''

interface CurrencyAdornment {
  type: 'currency'
  symbol: string
}

interface PercentageAdornment {
  type: 'percentage'
}

type Adornment = CurrencyAdornment | PercentageAdornment

export interface FormatNumberOptions {
  precision?: number
  thousandsSeparator?: string
  decimalSeparator?: string
  adornment?: Adornment
}

export function formatNumber (
  amount = 0,
  {
    precision = DEFAULT_PRECISION,
    thousandsSeparator = DEFAULT_SEPARATOR,
    decimalSeparator = DEFAULT_SEPARATOR,
    adornment
  }: FormatNumberOptions = {}
) {
  const regex = '\\d(?=(\\d{' + 3 + '})+' + (precision > 0 ? '\\D' : '$') + ')'

  const strNum = amount.toFixed(Math.max(0, precision))
  const withSeparators = (decimalSeparator ? strNum.replace('.', decimalSeparator) : strNum)
    .replace(new RegExp(regex, 'g'), '$&' + (thousandsSeparator || ''))

  if (!adornment) {
    return withSeparators
  }
  switch (adornment.type) {
    case 'currency':
      return `${adornment.symbol} ${withSeparators}`
    case 'percentage':
      return `${withSeparators} %`
  }
}
