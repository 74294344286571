var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { globalFont, isBrightColor } from '../utils';
import { useTheme, themeGlobal, themeSpace, useIsSmallScreen } from '../Theme';
export var Content = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: block;\n  float: right;\n  position: relative; \n  top: ", "px;\n  min-height: ", ";\n  width: ", ";\n  padding: ", ";\n  \n  @media print {\n    float: none;\n    top: 0;\n    left: 0;\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    background-color: white;\n  }\n"], ["\n  box-sizing: border-box;\n  display: block;\n  float: right;\n  position: relative; \n  top: ", "px;\n  min-height: ", ";\n  width: ", ";\n  padding: ", ";\n  \n  @media print {\n    float: none;\n    top: 0;\n    left: 0;\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    background-color: white;\n  }\n"])), themeGlobal('headerHeight'), themeGlobal('contentHeight'), prop('width'), themeSpace(2));
var rgbComponents = function (hex) { return parseInt(hex.substr(1, 2), 16) + ",\n   " + parseInt(hex.substr(3, 2), 16) + ",\n   " + parseInt(hex.substr(5, 2), 16); };
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background-color: #f4f4f4;\n  ", ";\n"], ["\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background-color: #f4f4f4;\n  ", ";\n"])), globalFont);
export function Shell(_a) {
    var _b, _c;
    var className = _a.className, appBar = _a.appBar, navBar = _a.navBar, appCss = _a.appCss, _d = _a.navCollapsed, navCollapsed = _d === void 0 ? false : _d, children = _a.children;
    var appColour = (_c = (_b = useTheme()) === null || _b === void 0 ? void 0 : _b.colors.brand[500]) !== null && _c !== void 0 ? _c : null;
    var isSmallScreen = useIsSmallScreen();
    return (_jsxs(_Fragment, { children: [appColour && (_jsx("style", { children: "\n          html,\n          body,\n          #root {\n            position: relative;\n            margin: 0 !important;\n            padding: 0 !important;\n            min-height: 100%;\n            min-width: 100%;\n          }\n          \n          :root {\n            --app-colour: " + appColour + ";\n            --app-colour-components: " + rgbComponents(appColour) + ";\n            --app-colour-inverse: " + (isBrightColor(appColour) ? 'black' : 'white') + ";\n            " + (isBrightColor(appColour) ? '--app-colour-override: black;' : '') + ";\n        }" }, void 0)), appCss && (_jsx("style", { children: appCss }, void 0)), _jsxs(Container, __assign({ className: className }, { children: [appBar, navBar ? navBar : null, _jsx(Content, __assign({ width: !navBar
                            ? '100%'
                            : navCollapsed || isSmallScreen
                                ? 'calc(100% - 70px)'
                                : 'calc(100% - 270px)' }, { children: children }), void 0)] }), void 0)] }, void 0));
}
var templateObject_1, templateObject_2;
