import {
  SetPreviewLimit,
  UpdateTransformersPreview,
  SetRunStatus,
  UpdateTransformersData,
  SetPreviewError,
  UpdateTransformerPreviewerDate,
  UpdateRunTransformerDateRange
} from '../../actions/datasources/transformers'
import { TransformerModel } from '../../data/types/transformer'
import { createSelectors } from '../../store/utils'

export type PreviewData = { [key: string]: any }[]
export type PreviewColumnWidths = { [key: string]: number }
export type PreviewerColumn = { key: string; width: number }

export interface RunStatus {
  status: string
  start_time: string
  end_time: string
}

export interface PreviewError {
  message: string
  line: number
}

export interface TransformersState {
  data: TransformerModel[]
  runStatus: null | string | false | RunStatus
  previewColumns: PreviewerColumn[]
  previewData: PreviewData
  previewDate: string | null
  runNowDateRange: [string, string] | null
  previewLimit: number
  error: PreviewError | null
}

const initialState: TransformersState = {
  data: [],
  runStatus: null,
  previewColumns: [],
  previewData: [],
  previewLimit: 1000,
  previewDate: null,
  runNowDateRange: null,
  error: null
}

type TransformersAction =
  | UpdateTransformersData
  | SetRunStatus
  | UpdateTransformersPreview
  | SetPreviewLimit
  | SetPreviewError
  | UpdateTransformerPreviewerDate
  | UpdateRunTransformerDateRange

const transformers = (state = initialState, action: TransformersAction): TransformersState => {
  switch (action.type) {
    case UpdateTransformersData.type:
      return { ...state, data: action.data }

    case SetRunStatus.type:
      return { ...state, runStatus: action.status }

    case UpdateTransformersPreview.type:
      return {
        ...state,
        previewColumns: action.previewColumns.map((column) => ({
          key: column,
          width: action.columnWidths[column]
        })),
        previewData: action.previewData
      }

    case UpdateTransformerPreviewerDate.type:
      return {
        ...state,
        previewDate: action.date
      }

    case UpdateRunTransformerDateRange.type:
      return {
        ...state,
        runNowDateRange: action.range
      }

    case SetPreviewLimit.type:
      return {
        ...state,
        previewLimit: action.limit
      }

    case SetPreviewError.type:
      return {
        ...state,
        error: action.previewError
      }

    default:
      return state
  }
}

export const transformersSelectors = createSelectors((state) => state.datasources.transformers, {
  getPreviewerDate: (state) => state.previewDate,
  getRunNowDateRange: (state) => state.runNowDateRange,
  getPreviewerData: (state) => state.previewData,
  getPreviewerOutputRows: (state) => state.previewData.length,
  getPreviewerColumns: (state) => state.previewColumns,
  getPreviewLimit: (state) => state.previewLimit,
  getPreviewError: (state) => state.error
})

export default transformers
