import groupBy from 'lodash/groupBy'
import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'

import {
  AccountReportItem,
  InstanceReportItem,
  ServiceReportItem
} from '../../../../API/APIResponseTypes/reports'
import { hasExtraActions } from '../../../../store/utils'
import { FetchStatus } from '../../../../API/types'
import { EnrichedRunReportsItem } from '../../../../API/requests/reports/enrichSummary'

import { fetchReportDataAndIncludedResources } from './thunks/fetchReportDataAndIncludedResources'

export type Dimension
  = 'accounts'
  | 'services'
  | 'instances'
  | 'summaryServices'
  | 'summaryInstances'
  | 'resources'

export type SummaryReportType = 'services'|'instances'

const NAME = 'reports/data'

function createInitArr<T> () {
  return {
    data: [] as T[],
    status: FetchStatus.Idle
  }
}

function createInit<T> () {
  return {
    data: null as T|null,
    status: FetchStatus.Idle
  }
}

interface Resources {
  account_ids: string[]
  service_ids: string[]
  servicecategory_ids: string[]
}

export type SummaryReportByAccount = {
  [account_id: string]: EnrichedRunReportsItem[]
}

export function groupReportByAccount (data: EnrichedRunReportsItem[]) {
  return data.length ? groupBy(data, 'account_id') : null
}

const initialState = {
  accounts: createInitArr<AccountReportItem>(),
  services: createInitArr<ServiceReportItem>(),
  instances: createInitArr<InstanceReportItem>(),
  summaryServices: createInit<SummaryReportByAccount>(),
  summaryInstances: createInit<SummaryReportByAccount>(),
  resources: createInit<Resources>()
}

export type ReportData = typeof initialState

function isInvalidateDataAction (
  args: any
): args is { payload: Dimension } {
  return args.type.endsWith('invalidateData')
}

function invalidateDataReducer<T extends Dimension> (state: ReportData, action: PayloadAction<T>) {
  state[action.payload] = initialState[action.payload]
}

export const { actions, reducer } = createSlice({
  name: NAME,
  initialState,
  reducers: {
    invalidateData: <T extends Dimension> (state: ReportData, action: PayloadAction<T>) => {
      state[action.payload] = initialState[action.payload]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportDataAndIncludedResources.pending, (state, action) => {
        state.resources.data = initialState.resources.data
        state[action.meta.arg].data = initialState[action.meta.arg].data
        state[action.meta.arg].status = FetchStatus.Loading
      })

      .addCase(fetchReportDataAndIncludedResources.fulfilled, (state, action) => {
        const [resources, data] = action.payload

        state.resources.data = resources
        state[action.meta.arg].data = data
        state[action.meta.arg].status = FetchStatus.Succeeded
      })
      .addCase(fetchReportDataAndIncludedResources.rejected, (state, action) => {
        state[action.meta.arg].status = FetchStatus.Failed
      })

    builder.addMatcher(hasExtraActions, (state, action) => {
      action.meta.extraActions.forEach(extraAction => {
        if (isInvalidateDataAction(extraAction)) {
          invalidateDataReducer(state, extraAction)
        }
      })
    })
  }
})

export const thunks = {
  fetchReportData: fetchReportDataAndIncludedResources
}
