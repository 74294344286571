import { translate } from '@exivity/translations'
import { CrudRecord } from 'react-crud-hook'

import { ChannelType, NotificationChannelModel } from '../../../../data/types'

import { SlackConfiguration } from './Slack'
import { MailConfiguration } from './Mail'
import { NexmoConfiguration } from './Nexmo'
import { WebhookConfiguration } from './Webhook'

export type ChannelConfigurationProps = {
  channel: CrudRecord<NotificationChannelModel>
}

export const CHANNEL_CONFIGURATIONS = {
  [ChannelType.slack]: SlackConfiguration,
  [ChannelType.nexmo]: NexmoConfiguration,
  [ChannelType.mail]: MailConfiguration,
  [ChannelType.webhook]: WebhookConfiguration
}

export const TRANSLATIONS_RECIPIENT_CHANNEL = {
  [ChannelType.mail]: {
    title: () => translate('Mail Channel'),
    option: () => translate('E-mail address')
  },
  [ChannelType.slack]: {
    title: () => translate('Slack Channel'),
    option: () => translate('Slack')
  },
  [ChannelType.nexmo]: {
    title: () => translate('SMS Channel'),
    option: () => translate('SMS')
  },
  [ChannelType.webhook]: {
    title: () => translate('Webhook Channel'),
    option: () => translate('Webhook')
  }
}
