var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { themePriority } from '../Theme';
var fadeInGrow = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @keyframes fadeInGrow {\n    from {\n      opacity: 0;\n      transform: scale(0.3);\n    }\n    to {\n      opacity: 1;\n      transform: scale(1);\n    }\n  }\n  animation: fadeInGrow linear .1s;\n"], ["\n  @keyframes fadeInGrow {\n    from {\n      opacity: 0;\n      transform: scale(0.3);\n    }\n    to {\n      opacity: 1;\n      transform: scale(1);\n    }\n  }\n  animation: fadeInGrow linear .1s;\n"])));
export var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: ", ";\n"], ["\n  z-index: ", ";\n"])), themePriority(3));
export var TooltipContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #666666;\n  color: white;\n  box-shadow: 0 5px 15px rgba(0,0,0,0.08);\n  font-family: \"Fira Sans\";\n  font-size: 12px;\n  border-radius: 2px;\n  padding: 3px 6px;\n  ", ";\n"], ["\n  background-color: #666666;\n  color: white;\n  box-shadow: 0 5px 15px rgba(0,0,0,0.08);\n  font-family: \"Fira Sans\";\n  font-size: 12px;\n  border-radius: 2px;\n  padding: 3px 6px;\n  ", ";\n"])), fadeInGrow);
var templateObject_1, templateObject_2, templateObject_3;
