import { translate } from '@exivity/translations'

import { pages } from '../../domains'

export const logout = {
  path: '/logout',
  meta: {
    title: () => translate('Logout')
  },
  component: pages.auth.Logout,
  requiresAuth: false
}
