import React from 'react'
import { Alert } from '@exivity/ui'

import { ServiceChargeType, ServiceCogsType } from '../data/types'
import { useSelectedService } from '../domains/rates/components/useServiceBusinessModels'

export const deprecatedChargeTypes = [
  ServiceChargeType.DeprecatedAutomatic,
  ServiceChargeType.DeprecatedManual,
  ServiceChargeType.DeprecatedAutomaticPerInterval,
  ServiceChargeType.DeprecatedManualPerInterval
]

export const deprecatedCogsTypes = [
  ServiceCogsType.DeprecatedAutomaticPerInterval,
  ServiceCogsType.DeprecatedManualPerInterval
]

function ListDeprecationChanges ({ header, isBillingWarning = false }: {
  header: string
  isBillingWarning?: boolean
}) {
  return (
    <section>
      {header}
      <br />
      <ul>
        {isBillingWarning
        && (
          <li>
            The old manual/automatic definition
            was a combination of the per unit and per interval type.
          </li>
        )}
        <li>The per interval types are deprecated and will not be supported in future versions.</li>
        <li>
          The currently available manual/automatic options
          represent a per unit type, from this point forward.
        </li>
      </ul>
    </section>
  )
}

function showRateTypeDeprecationMessageForService (chargeType: ServiceChargeType) {
  if (
    chargeType === ServiceChargeType.DeprecatedManual
    || chargeType === ServiceChargeType.DeprecatedAutomatic
  ) {
    const header = `This service has 
      a ${chargeType} type assigned that corresponds to the old definition of ${chargeType}.`
    return <ListDeprecationChanges header={header} isBillingWarning />
  }

  if (
    chargeType === ServiceChargeType.DeprecatedAutomaticPerInterval
    || chargeType === ServiceChargeType.DeprecatedManualPerInterval
  ) {
    const header = `This service has a ${chargeType} type assigned that has been deprecated.`
    return <ListDeprecationChanges header={header} isBillingWarning />
  }

  return ''
}

function showCogsTypeDeprecationMessageForService (cogsType: ServiceCogsType) {
  if (
    cogsType === ServiceCogsType.DeprecatedAutomaticPerInterval
    || cogsType === ServiceCogsType.DeprecatedManualPerInterval
  ) {
    const header = `This service has a ${cogsType} type assigned that has been deprecated.`
    return <ListDeprecationChanges header={header} />
  }

  return ''
}

function showRateTypeDeprecationMessageForRate (chargeType: ServiceChargeType) {
  if (
    chargeType === ServiceChargeType.DeprecatedManual
    || chargeType === ServiceChargeType.DeprecatedAutomatic
  ) {
    const header = `This rate is linked to a service that has 
      a ${chargeType} type assigned that corresponds to the old definition of ${chargeType}.`
    return <ListDeprecationChanges header={header} isBillingWarning />
  }

  if (
    chargeType === ServiceChargeType.DeprecatedAutomaticPerInterval
    || chargeType === ServiceChargeType.DeprecatedManualPerInterval
  ) {
    const header = `This service has a ${chargeType} type assigned that has been deprecated.`
    return <ListDeprecationChanges header={header} isBillingWarning />
  }

  return ''
}

function showCogsTypeDeprecationMessageForRate (cogsType: ServiceCogsType) {
  if (
    cogsType === ServiceCogsType.DeprecatedAutomaticPerInterval
    || cogsType === ServiceCogsType.DeprecatedManualPerInterval
  ) {
    const header = `This rate is linked to a service that has a ${
      cogsType
    } type assigned that has been deprecated.`
    return <ListDeprecationChanges header={header} />
  }

  return ''
}

export function DeprecatePerIntervalRateTypeWarning ({
  chargeType
}: {
  chargeType: ServiceChargeType
}) {
  return (
    <>
      {deprecatedChargeTypes.some((type) => type === chargeType)
      && (
        <Alert title='Deprecation warning' warning>
          {showRateTypeDeprecationMessageForService(chargeType)}
        </Alert>
      )}
    </>
  )
}

export function DeprecatePerIntervalCogsTypeWarning ({
  cogsType
}: {
  cogsType: ServiceCogsType
}) {
  return (
    <>
      {deprecatedCogsTypes.some((type) => type === cogsType)
      && (
        <Alert title='Deprecation warning' warning>
          {showCogsTypeDeprecationMessageForService(cogsType)}
        </Alert>
      )}
    </>
  )
}

export function DeprecateChargeIntervalRateWarning () {
  const service = useSelectedService()

  if (!service) return null

  return (
    <>
      {
        // @ts-ignore
        deprecatedChargeTypes.some((type) => type === service.attributes.charge_type)
      && (
        <Alert title='Deprecation warning' warning>
          {
            // @ts-ignore
            showRateTypeDeprecationMessageForRate(service.attributes.charge_type)}
        </Alert>
      )}
    </>
  )
}

export function DeprecateCogsIntervalRateWarning () {
  const service = useSelectedService()

  if (!service) return null

  return (
    <>
      {
        // @ts-ignore
        deprecatedCogsTypes.some((type) => type === service.attributes.cogs_type)
      && (
        <Alert title='Deprecation warning' warning>
          {
            // @ts-ignore
            showCogsTypeDeprecationMessageForRate(service.attributes.cogs_type)}
        </Alert>
      )}
    </>
  )
}
