import sortBy from 'lodash/sortBy';
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "asc";
    OrderDirection["Desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
export function orderBy(options, data) {
    var orderedData = sortBy(data, options.by);
    return !options.direction || options.direction === OrderDirection.Asc
        ? orderedData
        : orderedData.reverse();
}
