"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateRelationship = exports.viewRelationship = exports.viewAttribute = exports.updateAttribute = exports.viewType = exports.viewId = exports.cleanRecord = exports.omitLinks = exports.omitId = exports.equalId = void 0;
var ramda_1 = require("ramda");
var update_1 = require("./update");
exports.equalId = ramda_1.propEq('id');
var omitId = function (record) { return ((record === null || record === void 0 ? void 0 : record.id)
    ? ramda_1.omit(['id'], record)
    : record); };
exports.omitId = omitId;
var omitLinks = function (record) { return (record.links
    ? ramda_1.omit(['links'], record)
    : record); };
exports.omitLinks = omitLinks;
var cleanRecord = function (record) { return ramda_1.omit(['links', 'id'], record); };
exports.cleanRecord = cleanRecord;
function viewId(source) {
    return ramda_1.view(ramda_1.lensProp('id'), source);
}
exports.viewId = viewId;
function viewType(source) {
    return ramda_1.view(ramda_1.lensProp('type'), source);
}
exports.viewType = viewType;
exports.updateAttribute = ramda_1.curry(
// @ts-ignore
function (attribute, value, source) { return (update_1.update(ramda_1.lensPath(['attributes', attribute]), value, source)); });
exports.viewAttribute = ramda_1.curry(function (attribute, source) { var _a; return (_a = source.attributes) === null || _a === void 0 ? void 0 : _a[attribute]; });
exports.viewRelationship = ramda_1.curry(function (relationship, source) { var _a, _b; return (_b = (_a = source === null || source === void 0 ? void 0 : source.relationships) === null || _a === void 0 ? void 0 : _a[relationship]) === null || _b === void 0 ? void 0 : _b.data; });
exports.updateRelationship = ramda_1.curry(function (relationship, value, source) { return update_1.update(
// assign relationship to get rid of links or other properties
ramda_1.lensPath(['relationships', relationship]), { data: value }, source); });
