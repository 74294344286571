var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePurpose } from '../Theme';
var StyledSpinner = styled.svg(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  width: 40px;\n  animation: rotate 2s linear infinite;\n  z-index: 11;\n  \n  circle {\n    stroke: ", ";\n    stroke-linecap: round;\n    animation: dash 1.5s ease-in-out infinite;\n  }\n  \n  @keyframes rotate {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  \n  @keyframes dash {\n    0% {\n      stroke-dasharray: 1, 150;\n      stroke-dashoffset: 0;\n    }\n    50% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -35;\n    }\n    100% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -124;\n    }\n  }\n"], ["\n  height: 40px;\n  width: 40px;\n  animation: rotate 2s linear infinite;\n  z-index: 11;\n  \n  circle {\n    stroke: ", ";\n    stroke-linecap: round;\n    animation: dash 1.5s ease-in-out infinite;\n  }\n  \n  @keyframes rotate {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  \n  @keyframes dash {\n    0% {\n      stroke-dasharray: 1, 150;\n      stroke-dashoffset: 0;\n    }\n    50% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -35;\n    }\n    100% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -124;\n    }\n  }\n"])), themePurpose('primary'));
export function Spinner(_a) {
    var className = _a.className;
    return (_jsx(StyledSpinner, __assign({ className: className, xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 50 50' }, { children: _jsx("circle", { cx: '25', cy: '25', r: '20', fill: 'none', strokeWidth: '2' }, void 0) }), void 0));
}
var templateObject_1;
