var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Block } from '../Block';
import { FlexItem } from './Item';
export var Flex = styled(Block)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  display: flex;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (props) { return props.direction && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["flex-direction: ", ";"], ["flex-direction: ", ";"])), props.direction); }, function (props) { return props.wrap && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["flex-wrap: ", ";"], ["flex-wrap: ", ";"])), props.wrap); }, function (props) { return props.justifyContent && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["justify-content: ", ";"], ["justify-content: ", ";"])), props.justifyContent); }, function (props) { return props.alignItems && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["align-items: ", ";"], ["align-items: ", ";"])), props.alignItems); }, function (props) { return props.alignContent && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["align-content: ", ";"], ["align-content: ", ";"])), props.alignContent); });
Flex.Item = FlexItem;
Flex.displayName = 'Flex';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
