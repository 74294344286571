var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fromTheme } from '../Theme';
export var TreeViewNode = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n"], ["\n  flex-grow: 1;\n"])));
export var TreeView = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  --spacer: 1px solid rgb(214, 212, 212);\n  --nesting: calc(", " + ", "px);\n\n  & & {\n    margin-left: var(--nesting);\n  }\n\n  & & & {\n    margin-left: calc(var(--nesting) * 2);\n  }\n\n  position: relative;\n\n  border-left: var(--spacer);\n\n  display: flex;\n  flex-direction: column;\n   \n  &:last-child {\n    border-left:none;\n  }\n  \n  \n  & &:before {\n    position:absolute;\n    top: 0;\n    height: 50%;\n    width: var(--nesting);\n    content:\"\";\n  }\n\n  & & ", " {\n    position: relative;\n    margin-left: var(--nesting);\n  }\n\n  & & ", ":before {\n    position: absolute;\n    border-bottom: var(--spacer);\n    width: var(--nesting);\n    height: 50%;\n    left: calc(var(--nesting) * -1);\n    content: \"\";\n  }\n\n  & &:last-child > ", ":before  {\n    border-left: var(--spacer);\n  }\n"], ["\n  --spacer: 1px solid rgb(214, 212, 212);\n  --nesting: calc(", " + ", "px);\n\n  & & {\n    margin-left: var(--nesting);\n  }\n\n  & & & {\n    margin-left: calc(var(--nesting) * 2);\n  }\n\n  position: relative;\n\n  border-left: var(--spacer);\n\n  display: flex;\n  flex-direction: column;\n   \n  &:last-child {\n    border-left:none;\n  }\n  \n  \n  & &:before {\n    position:absolute;\n    top: 0;\n    height: 50%;\n    width: var(--nesting);\n    content:\"\";\n  }\n\n  & & ", " {\n    position: relative;\n    margin-left: var(--nesting);\n  }\n\n  & & ", ":before {\n    position: absolute;\n    border-bottom: var(--spacer);\n    width: var(--nesting);\n    height: 50%;\n    left: calc(var(--nesting) * -1);\n    content: \"\";\n  }\n\n  & &:last-child > ", ":before  {\n    border-left: var(--spacer);\n  }\n"])), fromTheme(function (theme) { return theme.space[2]; }), function (_a) {
    var _b = _a.offset, offset = _b === void 0 ? 0 : _b;
    return offset;
}, TreeViewNode, TreeViewNode, TreeViewNode);
var templateObject_1, templateObject_2;
