import React, { useCallback } from 'react'
import { queries, useCacheQuery, Resources } from '@exivity/data-layer'
import { Button, ResourceList, OrderOptions, ColumnOptions } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useParams } from '@exivity/routing'

import { ScheduleColumn, StatusColumn } from './columns'

const COLUMNS: ColumnOptions<Resources['workflow']>[] = [
  ResourceList.createSearchableColumn({
    placeholder: () => translate('Name'),
    valueAccessor: workflow => workflow.attributes.name,
    width: 250
  }),
  {
    header: () => translate('Schedule'),
    renderColumn: workflow => <ScheduleColumn workflow={workflow} />,
    width: 200
  },
  {
    renderColumn: workflow => <StatusColumn workflow={workflow} />,
    width: 50
  }
]

const ORDER_OPTIONS: OrderOptions<Resources['workflow']> = {
  by: workflow => workflow.attributes.name
}

export function WorkflowList () {
  const [params, setParam] = useParams()
  const workflows = useCacheQuery(queries.findAll('workflow'))

  const isActive = useCallback((workflow: Resources['workflow']) => (
    workflow.id === params.id), [params.id]
  )

  const onClick = useCallback((workflow: Resources['workflow']) => (
    setParam('id', workflow.id)
  ), [setParam])

  return (
    <ResourceList
      data={workflows}
      actions={
        <Button.Create success small onClick={() => setParam('id', 'new')}>
          {translate('Create workflow')}
        </Button.Create>
      }
      columns={COLUMNS}
      order={ORDER_OPTIONS}
      isActive={isActive}
      onClick={onClick} />
  )
}
