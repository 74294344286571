import { useState, useCallback } from 'react';
import { useWindowListener } from '../useWindowListener';
export function useOutsideClickListener(onOutsideClick) {
    var _a = useState(null), ref = _a[0], setRef = _a[1];
    var handleOutsideClick = useCallback(function (event) {
        var target = event.target;
        if (!target) {
            onOutsideClick(event);
        }
        else if (ref && !ref.contains(target)) {
            onOutsideClick(event);
        }
    }, [onOutsideClick, ref]);
    useWindowListener('click', handleOutsideClick, true);
    return setRef;
}
