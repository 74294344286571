import { useMemo } from 'react'

import { RDFStatus } from '../../../../data/types'
import { useDateFormatter, Formats } from '../../../../components/hooks/useDateFormatter'

const NO_RDFS = [] as RDFStatus[]

export function useRDFStatuses (rdfs: RDFStatus[] = NO_RDFS, deleteRDF: (rdf: RDFStatus) => void) {
  const formatter = useDateFormatter()

  const statuses = useMemo(() => (
    rdfs
      .map((rdf) => ({
        date: rdf.date,
        day: formatter.convert(Formats.IsoDate, Formats.GuiDate, rdf.date),
        lastModified: formatter.convert(Formats.IsoDateTime, Formats.GuiDate, rdf.updated),
        delete: () => { deleteRDF(rdf) }
      })
      )
  ), [rdfs])

  return statuses
}
