import React from 'react'
import { CrudRecord } from 'react-crud-hook'
import { MetaTable } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { Link } from '@exivity/routing'

import { ZonedDateTime } from '../../../../utils/date'
import { ServiceModel } from '../../../../data/types'

const formatDate = (date?: number | string) => ZonedDateTime.fromTimestamp(date).format()

interface FormHeaderProps {
  service: CrudRecord<ServiceModel>
  ratesCount: number
}

export default function FormHeader ({ service, ratesCount }: FormHeaderProps) {
  return (
    <MetaTable breakSize={2}>
      <MetaTable.Item
        label={translate('Key')}
        value={service.attributes.key} />
      <MetaTable.Item
        label={translate('Associated rates')}
        value={
          <Link to={`../../services/rates/${service.id}/global`}>
            {`${ratesCount} ${ratesCount === 1 ? 'rate' : 'rates'}`}
          </Link>} />
      <MetaTable.Item
        label={translate('Created at')}
        value={formatDate(service.attributes.created_at)} />
      <MetaTable.Item
        label={translate('Last updated at')}
        value={formatDate(service.attributes.updated_at)} />
    </MetaTable>
  )
}
