import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Input, fromTheme, BlockProps } from '@exivity/ui'
import { useSelector } from 'react-redux'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

export interface CurrencyAdornmentProps extends BlockProps {
  children: ReactElement<any>

  right?: ReactElement<any>
  inset?: number
}

export const StyledCurrency = styled.span`
  font-size: 14px;
  color: ${fromTheme(theme => theme.colors.gray)};
`

export function CurrencyAdornment ({ children, ...rest }: CurrencyAdornmentProps) {
  const currency = useSelector(configurationSelectors.getCurrency)

  return (
    <Input.Adornment left={<StyledCurrency>{currency}</StyledCurrency>} {...rest}>
      {children}
    </Input.Adornment>
  )
}
