var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
var StyledBrowser = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .browser {\n    position: relative;\n\n    .prev, .next {\n      user-select: none;\n      cursor: pointer;\n      position: absolute;\n    }\n\n    .prev {\n      top: 0;\n      left: -", "px;\n    }\n\n    .next {\n      top: 0;\n      right: -", "px;\n    }\n  }\n"], ["\n  .browser {\n    position: relative;\n\n    .prev, .next {\n      user-select: none;\n      cursor: pointer;\n      position: absolute;\n    }\n\n    .prev {\n      top: 0;\n      left: -", "px;\n    }\n\n    .next {\n      top: 0;\n      right: -", "px;\n    }\n  }\n"])), function (props) { return props.offset; }, function (props) { return props.offset; });
export function Browser(_a) {
    var prev = _a.prev, next = _a.next, children = _a.children, _b = _a.offset, offset = _b === void 0 ? 0 : _b;
    return (_jsx(StyledBrowser, __assign({ offset: offset }, { children: _jsxs("div", __assign({ "data-testid": 'browser', className: 'browser' }, { children: [_jsx("div", __assign({ className: 'prev' }, { children: prev }), void 0), _jsx("div", __assign({ className: 'main' }, { children: children }), void 0), _jsx("div", __assign({ className: 'next' }, { children: next }), void 0)] }), void 0) }), void 0));
}
var templateObject_1;
