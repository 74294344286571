import { useCallback, useMemo, useReducer } from 'react';
import { constrainRangeToBounds, isEmptyRange, isPartialRange, isCompleteRange } from '../DateRange';
export function useDateRange(value, reducer, options) {
    var rangeReducer = useCallback(function (range, action) {
        var newRange = reducer(range, action, options);
        return constrainRangeToBounds(newRange, options.bounds);
    }, [options, reducer]);
    var _a = useReducer(rangeReducer, value || [null, null]), range = _a[0], dispatch = _a[1];
    return useMemo(function () {
        return [
            range,
            {
                selectDateAsRange: function (date) { return dispatch({ type: 'SELECT_SINGLE_UNIT_AS_RANGE', date: date }); },
                selectDate: function (date) {
                    if (isEmptyRange(range))
                        dispatch({ type: 'SELECT_START', date: date });
                    if (isPartialRange(range))
                        dispatch({ type: 'SELECT_END', date: date });
                    if (isCompleteRange(range))
                        dispatch({ type: 'SELECT_START', date: date });
                },
                resetRange: function () { return dispatch({ type: 'RESET_RANGE' }); }
            }
        ];
    }, [range]);
}
