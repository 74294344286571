import React from 'react'
import PropTypes from 'prop-types'

import { width as widthUtil } from '../../../utils/uikit'

/**
 * @type {React.ComponentType<any>}
 */
const Column = ({ width, children, className, ...rest }) => (
  <div {...rest} className={`${widthUtil.toClass(width)} ${className || ''}`}>
    {children}
  </div>
)

Column.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  width: widthUtil.propType
}

Column.defaultProps = {
  width: 'expand',
  className: null
}

export default Column
