import React, { useMemo } from 'react'
import { isBefore } from 'date-fns'
import { Alert } from '@exivity/ui'
import { singleLine, translate } from '@exivity/translations'

import {
  RateModel,
  ServiceModel,
  ServiceChargeType,
  ServiceCogsType
} from '../../../../../../data/types'
import { useDateFormatter, Formats } from '../../../../../../components/hooks/useDateFormatter'
import { TierTable } from '../../../TierTable'

import { Rate } from './Rate'
import { Cogs } from './Cogs'

export function useHasActiveRate () {
  const dateFormatter = useDateFormatter()
  const parse = dateFormatter.parse(Formats.IsoDate)

  return (rates: RateModel[]) => rates.some(rate => isBefore(
    parse(rate.attributes.effective_date),
    new Date()
  ))
}

function useActiveRate (rates: RateModel[]) {
  const dateFormatter = useDateFormatter()

  return useMemo(() => {
    const parse = dateFormatter.parse(Formats.IsoDate)

    const today = new Date()

    // We sort the dates from furthest in the future to furthest in the past
    // so we can assert that the first item that is not in the future is the current active rate
    return rates.sort((rateA, rateB) => {
      const dateA = parse(rateA.attributes.effective_date)
      const dateB = parse(rateB.attributes.effective_date)

      return isBefore(dateA, dateB) ? 1 : -1
    }).find((rate) => isBefore(parse(rate.attributes.effective_date), today))
  }, [dateFormatter, rates])
}

interface RateCogsProps {
  service: ServiceModel | null
  rates: RateModel[]
  rate: number | null
  cogs: number | null
  onChangeRate: (rate: number) => void
  onChangeCogs: (cogs: number) => void
}

export function RateCogs ({
  service,
  rates,
  rate,
  cogs,
  onChangeRate,
  onChangeCogs
}: RateCogsProps) {
  const activeRate = useActiveRate(rates)

  const isTieredRate = service?.attributes.charge_type === ServiceChargeType.ManualTieredInherited
    || service?.attributes.charge_type === ServiceChargeType.ManualTieredStandard

  const isTieredCogs = service?.attributes.cogs_type === ServiceCogsType.ManualTieredInherited
    || service?.attributes.cogs_type === ServiceCogsType.ManualTieredStandard

  if (!activeRate && service) {
    return (
      <Alert warning title={translate('No active rate revision')}>
        {singleLine(
          translate(`This account does not have an associated active rate for ${service?.attributes.description}.`),
          translate('Subscriptions only work when there is an active rate.')
        )}
      </Alert>
    )
  }

  if (!activeRate) return null

  return (
    <>
      {!isTieredRate && (
        <Rate
          service={service}
          rate={rate}
          activeRate={activeRate}
          onChangeRate={onChangeRate} />
      )}

      {(isTieredRate || isTieredCogs) && <TierTable rate={activeRate} />}

      {!isTieredCogs && (
        <Cogs
          service={service}
          cogs={cogs}
          activeRate={activeRate}
          onChangeCogs={onChangeCogs} />
      ) }
    </>
  )
}
