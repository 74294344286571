import { passedValueArg } from '../Object/setProperty';
export class SetHasOne {
    setHasOne(relationship, value) {
        const update = (value) => {
            this.updateFn((object) => {
                object.relationships = { [relationship]: { data: value } };
            });
            return this;
        };
        if (passedValueArg(arguments.length, value)) {
            return update(value);
        }
        else {
            return update;
        }
    }
}
