import PropTypes from 'prop-types'

export default {
  pure: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      parent: PropTypes.any,
      attributes: PropTypes.object,
      meta: PropTypes.object
    })
  ).isRequired,
  selected: PropTypes.any,
  checked: PropTypes.array,
  autoExpandSelected: PropTypes.bool,
  editGroupHeaders: PropTypes.bool,
  onSaveGroupHeader: PropTypes.func,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,

  /* DO NOT USE! */
  onChange: PropTypes.func,

  onChecked: PropTypes.func,
  sortable: PropTypes.bool,

  height: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 'exact']),
    PropTypes.number
  ]),
  maxHeight: PropTypes.number,
  expandHeight: PropTypes.bool,

  header: PropTypes.node,
  toolbar: PropTypes.node,
  noHeaders: PropTypes.bool,

  highlightSearches: PropTypes.bool,
  filterSearches: PropTypes.bool,
  groupBy: PropTypes.string,
  checkable: PropTypes.bool,
  autoCheckChildren: PropTypes.bool,
  useOnClickToCheck: PropTypes.bool,
  expanded: PropTypes.bool,
  onNormalize: PropTypes.func,

  noDataText: PropTypes.string,
  noSearchResultText: PropTypes.string
}
