"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pipeAsync = void 0;
var pipeAsync = function () {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return function (args) { return fns.reduce(function (v, f) { return v.then(f); }, Promise.resolve(args)); };
};
exports.pipeAsync = pipeAsync;
