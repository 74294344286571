import { translate } from '@exivity/translations'

import { pages } from '../../domains'

export const login = {
  path: '/login',
  meta: {
    title: () => translate('Login'),
    excludeFromSearchIndex: true
  },
  component: pages.auth.Login,
  requiresAuth: false
}
