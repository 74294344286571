import React from 'react'
import { queries, useCacheQuery, Resources } from '@exivity/data-layer'

import { renderRunStatus } from '../../shared/renderRunStatus'

interface StatusColumnProps {
  workflow: Resources['workflow']
}

export function StatusColumn ({ workflow }: StatusColumnProps) {
  const [latestRun] = useCacheQuery(
    queries
      .relationshipOf('workflow', workflow, 'runs')
      .sortByAttribute('end_date', 'descending')
  )

  if (latestRun) {
    return <>{renderRunStatus(latestRun)}</>
  }

  return null
}
