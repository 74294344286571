import { lensPath, set, curry, compose } from '@exivity/fp';
import { isBrightColor } from '../utils/isBrightColor';
import { getPalette, hexToNumber } from '../utils/colorUtils';
var makeThemeLens = function (path) { return lensPath(path); };
var themeBrandPaletteLens = makeThemeLens(['colors', 'brand']);
var themeInverseBrandLens = makeThemeLens(['global', 'inverseBrandTextColor']);
var setThemeBrandAndInverse = curry(function (brand) {
    var brandNumber = hexToNumber(brand);
    var brandPalette = getPalette(brandNumber);
    return compose(set(themeInverseBrandLens, isBrightColor(brand) ? 'black' : 'white'), set(themeBrandPaletteLens, brandPalette));
});
export { setThemeBrandAndInverse };
