var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { height, size, space, width } from 'styled-system';
export var Image = styled.img.attrs(function (props) { return ({
    as: props.background ? 'div' : 'img'
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  ", "\n\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  box-sizing: border-box;\n  ", "\n\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (props) { return props.background && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-image: url('", "');\n    background-size: ", ";\n    background-position: ", ";\n    background-repeat: ", ";\n  "], ["\n    background-image: url('", "');\n    background-size: ", ";\n    background-position: ", ";\n    background-repeat: ", ";\n  "])), props.src, props.background, props.position && props.position !== 'center'
    ? props.position
    : '50% 50%', props.repeat ? 'repeat' : 'no-repeat'); }, space, size, width, height);
Image.displayName = 'Image';
var templateObject_1, templateObject_2;
