import React, { useState, useEffect } from 'react'
import { useSyncListener } from '@exivity/data-layer'
import { translate } from '@exivity/translations'
import { Loader, FillContentVerticalSpace } from '@exivity/ui'

import { sync } from '../../../utils/sync'

interface SynResourcesProps {
  children: any
}

export function SyncResources ({ children }: SynResourcesProps) {
  const [isSynced, setIsSynced] = useState(false)

  useEffect(() => {
    sync()
  }, [])

  useSyncListener(() => {
    setIsSynced(true)
  })

  return isSynced
    ? children
    : (
      <FillContentVerticalSpace>
        <Loader timeout={30000}
          timeoutMessage={translate('Loading is taking a bit longer than expected... Try refreshing the page.')}>
          {translate('Syncing data...')}
        </Loader>
      </FillContentVerticalSpace>
    )
}
