import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const paddingTop = (position) => {
  if (position === 'viewport') {
    return '30vh'
  }

  return position
}

const Overlay = ({ position, children, className }) => (
  <div
    className={'ex-overlay uk-position-cover uk-overlay uk-overlay-default uk-flex'
    + ' uk-flex-center '
    + `${position === 'center' ? 'uk-flex-middle' : 'uk-flex-top'} ` + (className || '')}
    style={position !== 'center' ? { paddingTop: paddingTop(position) } : {}}>
    {children}
  </div>
)

Overlay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['center', 'viewport'])
  ])
}

Overlay.defaultProps = { position: 'center' }

export default Overlay
