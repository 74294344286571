import { isBefore, isAfter, min, max } from 'date-fns';
export var isEmptyRange = function (range) { return (!range[0] && !range[1]); };
export var isPartialRange = function (range) { return !!(range[0] && !range[1]); };
export var isCompleteRange = function (range) { return !!(range[0] && range[1]); };
export var orderRange = function (range) { return [min(range), max(range)]; };
export function constrainRangeToBounds(range, bounds) {
    return range.map(function (date) {
        if (!date)
            return null;
        if (bounds.min && isBefore(date, bounds.min)) {
            return bounds.min;
        }
        if (bounds.max && isAfter(date, bounds.max)) {
            return bounds.max;
        }
        return date;
    });
}
