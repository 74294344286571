import { useState, useCallback } from 'react';
export function useClientRect() {
    var _a = useState(null), node = _a[0], setNode = _a[1];
    var ref = useCallback(function (node) {
        if (node !== null) {
            setNode(node);
        }
    }, []);
    var rect = node
        ? node.getBoundingClientRect()
        : null;
    return [rect, ref, node];
}
