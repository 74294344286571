import config from '../application/config'
import { del, get } from '../API/index'

export type ThirdPartyLicensesDict = {
  [key: string]: string
}

export function getThirdPartyLicenses () {
  return get('/system/third-party-licenses') as Promise<ThirdPartyLicensesDict>
}

export const getServerCache = (onSuccess?: () => void) => (
  get('/system/cache').then(onSuccess)
)

export const purgeServerCache = (component: unknown, onSuccess?: () => void) => (
  del('/system/cache', { component }).then(onSuccess)
)

export const clearUserState = () => del('/users/me/state', { app: config.app.name })
