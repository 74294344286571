import debounce from 'lodash/debounce'

import { patch } from '../API'
import config from '../application/config'

import { getUserSessionStorage } from './storage'

// In seconds
const persistThrottle = 5

export const persistUserState = (state) => {
  const token = getUserSessionStorage.fromEitherStorage('session')?.token

  if (token) {
    patch('/users/me/state', { app: config.app.name }, { state }, {
      background: true,
      workStatusMessage: 'Syncing'
    })
  }
}

export const debouncedPersistUserState = debounce(persistUserState, persistThrottle * 1000, {
  leading: false,
  trailing: true
})
