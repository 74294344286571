import { updateAttribute, updateRelationship } from '@exivity/fp';
export function empty(type) {
    return () => ({ type, attributes: {}, relationships: {} });
}
export function withAttribute(attribute, value) {
    return function (resource) {
        // @ts-ignore
        return updateAttribute(attribute, value, resource);
    };
}
export function withOptionalAttribute(attribute, value) {
    return function (resource) {
        // @ts-ignore
        return updateAttribute(attribute, value, resource);
    };
}
export function withRelationship(relation, value) {
    return function (resource) {
        // @ts-ignore
        return updateRelationship(relation, value, resource);
    };
}
