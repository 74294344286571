import React from 'react'
import { Tabs } from '@exivity/ui'
import { findIndex, compose, equals, map } from '@exivity/fp'
import {
  Route,
  useRoute,
  getTitle,
  getPath,
  getPathname,
  useNavigate,
  getChildRoutes,
  useChildRouteSwitch
} from '@exivity/routing'

const getActiveIndex = (exactRoute: Route, routes: Route[]) => findIndex(
  equals(getPath(exactRoute)),
  map(getPath, routes)
)

const getToRoute = (routes: Route[]) => (index: number) => routes[index] || routes[0]

export function NavigationTabs ({ children }: { children: React.ReactNode }) {
  const { route, exactRoute } = useRoute()
  const navigate = useNavigate()
  const routes = getChildRoutes(route)

  useChildRouteSwitch()

  const navigateTo = compose(
    navigate,
    getPathname,
    getToRoute(routes)
  )

  return (
    <Tabs disableAnimation
      activeIndex={getActiveIndex(exactRoute, routes)}
      onActiveIndexChange={navigateTo}>
      <Tabs.TabList>
        {getChildRoutes(route).map(child => {
          const title = getTitle(child)
          return (
            <Tabs.Tab key={getPath(child)} data-testid={'tab-' + title}>
              {title}
            </Tabs.Tab>
          )
        })}
      </Tabs.TabList>
      {children}
    </Tabs>
  )
}
