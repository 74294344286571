import QueryBuilder from '@orbit/data/dist/types/query-builder'
import React, { useCallback } from 'react'
import { Group, Label, Select, Input, Field, Radio } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { withData, MapRecordsToPropsFn } from 'react-orbitjs'
import { CrudRecord } from 'react-crud-hook'
import { prop } from '@exivity/fp'

import { memoizedGetDsetColumns, memoizedListDsets } from '../../../../selectors/dsets'
import { DsetModel, ServiceModel, ServiceType } from '../../../../data/types'

import { groupIcon } from './groupIcon'

interface Column {
  label: string
  value: string
}

interface FieldProps {
  service: CrudRecord<ServiceModel>
  disabled: boolean
}

type Columns = {
  columns: Column[]
}

interface DataSourceProps {
  dsets: DsetModel[]
}

function DataSource ({ service, dsets, disabled }: DataSourceProps & FieldProps) {
  const listedDsets = memoizedListDsets(dsets)
  const onChange = useCallback((dset: string) => {
    service.setAttribute('dset', dset)
    service.addHasOne('dset', { type: 'dset', id: dset })
    service.resetAttributes(['usage_col', 'consumption_col', 'instance_col'])
  }, [service])

  return (
    <Field data-testid='service-data-source'>
      <Label>
        {translate('Data source')}
      </Label>
      <Select
        required
        labelAccessor={prop('label')}
        valueAccessor={prop('value')}
        placeholder='Choose data source'
        disabled={disabled}
        value={service.attributes.dset}
        data={listedDsets}
        onChange={onChange} />
    </Field>
  )
}

function ServiceNameSource ({ service, disabled }: FieldProps) {
  return (
    <Field data-testid='service-name-source'>
      <Label>
        {translate('Service name source')}
      </Label>
      <Radio.Group
        value={service.attributes.type}
        onChange={service.setAttribute('type')}>
        <Radio
          disabled={disabled}
          label={translate('In header, consumption in data')}
          value={ServiceType.ServiceNameInHeader} />
        <Radio
          disabled={disabled}
          label={translate('In data, consumption in separate column')}
          value={ServiceType.ServiceNameInData} />
      </Radio.Group>
    </Field>
  )
}

function SourceColumn ({ service, columns, disabled }: FieldProps & Columns) {
  return (
    <Field data-testid='service-source-column'>
      <Label>
        {translate('Source column')}
      </Label>
      <Select
        required
        searchable
        labelAccessor={prop('label')}
        valueAccessor={prop('value')}
        placeholder='Choose column'
        disabled={disabled}
        value={service.attributes.usage_col}
        data={columns}
        onChange={service.setAttribute('usage_col')} />
    </Field>
  )
}

function ConsumptionColumn ({ service, columns, disabled }: FieldProps & Columns) {
  if (service.attributes.type !== ServiceType.ServiceNameInData) {
    return null
  }

  return (
    <Field data-testid='consumption-column'>
      <Label>
        {translate('Consumption column')}
      </Label>
      <Select
        searchable
        labelAccessor={prop('label')}
        valueAccessor={prop('value')}
        placeholder='Choose column'
        disabled={disabled}
        value={service.attributes.consumption_col}
        data={columns}
        onChange={service.setAttribute('consumption_col')} />
    </Field>
  )
}

function InstanceColumn ({ service, columns, disabled }: FieldProps & Columns) {
  return (
    <Field data-testid='service-instance-column'>
      <Label>
        {translate('Instance column')}
      </Label>
      <Select
        required
        searchable
        labelAccessor={prop('label')}
        valueAccessor={prop('value')}
        placeholder='Choose column'
        disabled={disabled}
        value={service.attributes.instance_col}
        data={columns}
        onChange={service.setAttribute('instance_col')} />
    </Field>
  )
}

function UnitLabel ({ service, disabled }: FieldProps) {
  return (
    <Field data-testid='service-unit-label'>
      <Label>
        {translate('Unit label')}
      </Label>
      <Input
        required
        disabled={disabled}
        value={service.attributes.unit_label || ''}
        placeholder={translate('Label')}
        onChange={service.setAttribute('unit_label')} />
    </Field>
  )
}

interface SourceGroupProps {
  editing?: boolean
  service: CrudRecord<ServiceModel>
}

export function SourceGroup ({
  dsets = [],
  service,
  editing = true
}: SourceGroupProps & DataSourceProps) {
  const columns = memoizedGetDsetColumns({
    dsetId: service.attributes.dset,
    dsets
  })

  return (
    <Group title={translate('Source')} Icon={groupIcon(editing)}>
      <Field.Container>
        <DataSource service={service} dsets={dsets} disabled={!editing} />
        <ServiceNameSource service={service} disabled={!editing} />
        <SourceColumn service={service} columns={columns} disabled={!editing} />
        <ConsumptionColumn service={service} columns={columns} disabled={!editing} />
        <InstanceColumn service={service} columns={columns} disabled={!editing} />
        <UnitLabel service={service} disabled={!editing} />
      </Field.Container>
    </Group>
  )
}

const mapDsetsToProps: MapRecordsToPropsFn<DataSourceProps, SourceGroupProps> = () => ({
  dsets: (q: QueryBuilder) => q.findRecords('dset')
})

export default withData(mapDsetsToProps)(SourceGroup)
