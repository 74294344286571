import { translate } from '@exivity/translations'

import { createAsyncThunk } from '../../../store/utils'
import { prepare } from '../../../API/requests/reports/prepareReport'
import { defaultHeaders } from '../../../API/headers'
import { configurationSelectors } from '../../../domains/administration/state/configuration'
import { workThunks } from '../../work/thunks'
import dataStore from '../../../data/store'
import { sync } from '../../../utils/sync'

import { reportsActions } from '.'

const invalidateAllReportData = createAsyncThunk(
  'reports/invalidateAllData',
  (_, { dispatch }) => {
    dispatch(reportsActions.dimensions.invalidateData('accounts'))
    dispatch(reportsActions.dimensions.invalidateData('services'))
    dispatch(reportsActions.dimensions.invalidateData('instances'))
    dispatch(reportsActions.dimensions.invalidateData('summaryServices'))
    dispatch(reportsActions.dimensions.invalidateData('summaryInstances'))
    dispatch(reportsActions.dimensions.invalidateData('resources'))
  })

function syncReportResources (reportId: string) {
  dataStore.cache.reset()

  sync({ config: false })
    .then(() => {
      dataStore.query(q => q.findRecords('dset'))
      dataStore.query(q => q.findRecord({ type: 'report', id: reportId }))
    })
}

type PrepareArgs = {
  reportId: string
  start: string
  end: string
}

/**
 * @description Recalculates with all resources related to a single report resource.
 * On completion all report data is invalidated and removed.
 * All resource caches needs to be cleared and refetched as there might
 * be new resources of most types.
 */
const prepareReport = createAsyncThunk(
  'reports/prepare',
  ({ reportId, start, end }: PrepareArgs, { dispatch, signal, getState }) => {
    const state = getState()
    const headers = defaultHeaders.auth()

    const endpoint = (endpoint: string) => configurationSelectors.makeUrl(endpoint)(state)

    dispatch(workThunks.showSuccessMessage(translate('Preparing reports')))

    return prepare(
      endpoint(`/reports/${reportId}/prepare`),
      { signal, headers, method: 'PATCH' },
      { state, dispatch, start, end },
      { isPrepare: true }
    ).then((result) => {
      dispatch(workThunks.showSuccessMessage(translate('Reports preparation finished.')))
      dispatch(invalidateAllReportData())

      syncReportResources(reportId)

      return result
    })
  }
)

export enum PreparableResource {
  Service = 'services',
  Subscription = 'servicesubscriptions',
  Rate = 'rates',
  Adjustment = 'adjustments'
}

/**
 * @description Recalculates selected resource for all reports associated through the same DSET.
 * On completion all report data is invalidated and removed. we do NOT need to resync or invalidate
 * the selected resource.
 */
const prepareAffectedReports = createAsyncThunk(
  'reports/prepareAffected',
  (
    { resource, id }: { resource: PreparableResource; id: string },
    { dispatch, signal, getState }
  ) => {
    const state = getState()
    const headers = defaultHeaders.auth()

    const endpoint = (endpoint: string) => configurationSelectors.makeUrl(endpoint)(state)

    dispatch(workThunks.showSuccessMessage(translate('Preparing reports')))

    return prepare(
      endpoint(`/${resource}/${id}/prepareAffectedReports`),
      { signal, headers, method: 'PATCH' },
      { state, dispatch }
    ).then(result => {
      dispatch(workThunks.showSuccessMessage(translate('Reports preparation finished.')))
      dispatch(invalidateAllReportData())

      return result
    })
  })

export const thunks = {
  prepareAffectedReports,
  prepareReport
}
