var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { useToggle } from '@exivity/hooks';
import { find, compose, equals, identity } from '@exivity/fp';
import { MdMenu, MdKeyboardArrowDown } from 'react-icons/md';
import { Dropdown } from '../Dropdown';
import { themeSpace, themeColor, themePriority, themePalette } from '../Theme';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { SelectList } from '../SelectList';
import { NavIcon } from './NavIcon';
export var NavSelectDropdown = styled(Dropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  transform: translateX(-45px);\n  \n  .dropdown__drop {\n    width: 245px;\n    padding: ", " 0;\n    background-color: #444;\n    margin-top: 1px;\n    border-radius: 3px;\n\n    color: ", ";\n    \n    ", " {\n      font-size: 0.8125rem;\n    }\n    \n    li {\n      &:hover {\n        background-color: ", ";\n      }\n      \n      &:focus {\n        background-color: ", ";\n      }\n    }\n  }\n"], ["\n  width: 100%;\n  transform: translateX(-45px);\n  \n  .dropdown__drop {\n    width: 245px;\n    padding: ", " 0;\n    background-color: #444;\n    margin-top: 1px;\n    border-radius: 3px;\n\n    color: ", ";\n    \n    ", " {\n      font-size: 0.8125rem;\n    }\n    \n    li {\n      &:hover {\n        background-color: ", ";\n      }\n      \n      &:focus {\n        background-color: ", ";\n      }\n    }\n  }\n"])), themeSpace(1), themePalette('neutral', 300), Text, themePalette('primary', 800), themePalette('primary', 800));
var StyledLi = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n  overflow: visible;\n  z-index: ", ";\n  \n  padding: 0 ", ";\n  padding-bottom: 10px;\n  \n  .nav__icon {\n    pointer-events: none;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n  overflow: visible;\n  z-index: ", ";\n  \n  padding: 0 ", ";\n  padding-bottom: 10px;\n  \n  .nav__icon {\n    pointer-events: none;\n  }\n"])), themePalette('neutral', 900), themePriority(3), themeSpace(2));
var Opacity = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  height: 40px;\n  width: 245px;\n  background-color: ", ";\n  opacity: 0.15;\n  transform: scaleX(0.2);\n  transform-origin: left;\n  transition-property: opacity, transform;\n  transition-duration: 200ms, 200ms;\n  transition-timing-function: ease-in-out;\n  border-radius: 3px;\n"], ["\n  position: absolute;\n  height: 40px;\n  width: 245px;\n  background-color: ", ";\n  opacity: 0.15;\n  transform: scaleX(0.2);\n  transform-origin: left;\n  transition-property: opacity, transform;\n  transition-duration: 200ms, 200ms;\n  transition-timing-function: ease-in-out;\n  border-radius: 3px;\n"])), themeColor('white'));
var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  height: 40px;\n  width: 245px;\n  border-radius: 3px;\n    \n  &:hover {\n    text-decoration: underline;\n    text-decoration-color: ", ";\n    \n    ", " {\n      opacity: 0.30;\n      transition: opacity 200ms ease-in-out;\n    }\n  }\n  \n  .nav-select__input {\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    transform: translateX(40px);\n    color: ", ";\n    \n    input {\n      color: ", ";\n      background-color: transparent;\n      border: none;\n      outline: none;\n      pointer-events: none;\n    }\n    \n    span {\n      font-size: 20px;\n      padding: 0 5px;\n    }\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  height: 40px;\n  width: 245px;\n  border-radius: 3px;\n    \n  &:hover {\n    text-decoration: underline;\n    text-decoration-color: ", ";\n    \n    ", " {\n      opacity: 0.30;\n      transition: opacity 200ms ease-in-out;\n    }\n  }\n  \n  .nav-select__input {\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    transform: translateX(40px);\n    color: ", ";\n    \n    input {\n      color: ", ";\n      background-color: transparent;\n      border: none;\n      outline: none;\n      pointer-events: none;\n    }\n    \n    span {\n      font-size: 20px;\n      padding: 0 5px;\n    }\n  }\n"])), themeColor('white'), Opacity, themeColor('white'), themeColor('white'));
export function NavFilter(_a) {
    var value = _a.value, className = _a.className, _b = _a.data, data = _b === void 0 ? [] : _b, onChange = _a.onChange, _c = _a.placeholder, placeholder = _c === void 0 ? translate('Choose an option') : _c, _d = _a.labelAccessor, labelAccessor = _d === void 0 ? identity : _d, _e = _a.valueAccessor, valueAccessor = _e === void 0 ? identity : _e, restSelectListProps = __rest(_a, ["value", "className", "data", "onChange", "placeholder", "labelAccessor", "valueAccessor"]);
    var _f = useToggle(false), isOpen = _f[0], toggleOpen = _f[1];
    var activeItem = value
        ? find(compose(equals(value), valueAccessor), data)
        : undefined;
    return (_jsxs(StyledLi, __assign({ tabIndex: -1, className: className }, { children: [_jsx(NavIcon, { children: _jsx(MdMenu, {}, void 0) }, void 0), _jsx(NavSelectDropdown, __assign({ placement: 'bottom-start', reference: _jsx(_Fragment, { children: _jsxs(Container, __assign({ tabIndex: -1, className: 'nav-select__trigger' }, { children: [_jsx(Opacity, { className: 'nav-select__opacity' }, void 0), _jsxs("span", __assign({ className: 'nav-select__input' }, { children: [_jsx("input", { disabled: true, placeholder: placeholder, value: activeItem
                                            ? labelAccessor(activeItem)
                                            : '' }, void 0), _jsx(Icon, { children: _jsx(MdKeyboardArrowDown, {}, void 0) }, void 0)] }), void 0)] }), void 0) }, void 0), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: _jsx(SelectList, __assign({}, restSelectListProps, { value: value, data: data, valueAccessor: valueAccessor, labelAccessor: labelAccessor, onChange: function (value) {
                        onChange && onChange(value);
                        toggleOpen();
                    } }), void 0) }), void 0)] }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
