import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'

import { Report, SubTypeFilter } from './'

export const updateColumns = {
  prepare: (report: Report, columns: string[]) => ({
    payload: {
      report,
      columns
    }
  }),
  reducer (
    filters: ReportFilterState,
    action: PayloadAction<{ report: Report; columns: string[] }>
  ) {
    filters[action.payload.report].columns = action.payload.columns
  }
}

export const updateColumn = {
  prepare: <T extends Report> (report: T, column: SubTypeFilter<T, 'column'>) => ({
    payload: {
      report,
      column
    }
  }),
  reducer <T extends Report> (
    filters: ReportFilterState,
    action: PayloadAction<{ report: T; column: SubTypeFilter<T, 'column'> }>
  ) {
    filters[action.payload.report].column = action.payload.column
  }
}
