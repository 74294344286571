import React from 'react'
import { useSelector } from 'react-redux'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

import './index.css'

const Footer = () => {
  const appName = useSelector(configurationSelectors.getAppName)

  return (
    <footer className='ex-footer uk-margin'>
      <div className='ex-footer__copyright uk-text-center
      uk-text-small uk-text-muted uk-text-truncate'>
        <span>
          &copy; {new Date().getFullYear()} {appName}
        </span>
      </div>
    </footer>
  )
}

export default Footer
