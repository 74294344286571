import React from 'react'
import compose from 'lodash/fp/compose'
import { Group, Field, Label, Text, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'

interface UsageGroupProps {
  quantity: number
  identifier: string
  unitLabel?: string
  onChangeQuantity: (quantity: number) => void
  onChangeIdentifier: (identifier: string) => void
}

export function UsageGroup ({
  quantity,
  identifier,
  unitLabel,
  onChangeQuantity,
  onChangeIdentifier
}: UsageGroupProps) {
  return (
    <Group title={translate('Usage')}>
      <Field.Container>
        <Field data-testid='quantity'>
          <Label>{translate('Quantity')}</Label>
          <Field.Group>
            <Input
              placeholder={translate('Number of service units')}
              type='number'
              value={quantity}
              onChange={compose(onChangeQuantity, Number)} />
            <Text>{unitLabel}</Text>
          </Field.Group>
        </Field>
        <Field data-testid='identifier'>
          <Label.Group>
            <Label>{translate('Identifier')}</Label>
            <Label.Sub>{translate('Defaults to the name of the subscription')}</Label.Sub>
          </Label.Group>
          <Input
            placeholder={translate('Instance identifier')}
            value={identifier}
            onChange={onChangeIdentifier} />
        </Field>
      </Field.Container>
    </Group>
  )
}
