"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compose1 = void 0;
var _1 = require("./");
function compose1() {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    // @ts-ignore
    return function (arg) { return _1.compose.apply(void 0, fns)(arg); };
}
exports.compose1 = compose1;
