import { makeCreateModel } from '../../utils/model'

export enum ChannelType {
  mail = 'mail',
  slack = 'slack',
  nexmo = 'nexmo',
  webhook = 'webhook'
}

const CHANNEL_TYPE = 'notificationchannel'

export const USER_EMAIL = 'user.email'

type NotificationChannelInfo = {
  [ChannelType.slack]: {
    recipient: string
  }
  [ChannelType.nexmo]: {
    recipient: string
  }
  [ChannelType.mail]: {
    recipient: string|typeof USER_EMAIL
  }
  [ChannelType.webhook]: {
    recipient: string
    headers?: Record<string, string>
    tls_verification: boolean
    include_attachments?: boolean
  }
}

type UnionToIntersection<U> = (U extends any
  ? (k: U) => void
  : never) extends ((k: infer I) => void)
  ? I
  : never

export type ChannelInfo<S extends ChannelType|undefined> = S extends ChannelType
  ? NotificationChannelInfo[S]
  : Partial<UnionToIntersection<NotificationChannelInfo[ChannelType]>>

export interface NotificationChannelModel<C extends ChannelType|undefined = undefined> {
  id: string
  type: typeof CHANNEL_TYPE
  attributes: {
    name: string
    type: ChannelType|undefined
    info?: ChannelInfo<C>
  }
}

export const DEFAULT_CHANNEL: NotificationChannelModel = {
  id: '',
  type: CHANNEL_TYPE,
  attributes: {
    name: '',
    type: undefined,
    info: {
      recipient: ''
    }
  }
}

export const createChannel = makeCreateModel<NotificationChannelModel>(CHANNEL_TYPE,
  () => ({
    name: '',
    type: undefined,
    info: {
      recipient: ''
    }
  })
)
