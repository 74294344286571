import { combineReducers } from '@reduxjs/toolkit'

import * as api from './api'
import * as gui from './gui'
import * as filters from './filters'
import * as dimensions from './dimensions'
import * as prepare from './prepare'
import { selectors } from './selectors'

export const reports = combineReducers({
  gui: gui.reducer,
  filters: filters.reducer,
  dimensions: dimensions.reducer,
  api: api.reducer
})

export const reportsActions = {
  gui: gui.actions,
  filters: filters.actions,
  dimensions: dimensions.actions,
  api: api.actions
}

export const reportsThunks = {
  dimensions: dimensions.thunks,
  prepare: prepare.thunks
}

export const reportSelectors = selectors

export { PreparableResource } from './prepare'
export { ChartType } from './gui'
