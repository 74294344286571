import { PARENT, CHILDREN } from './data';
export function traverseParents(item, callback) {
    var parent = item[PARENT];
    if (parent) {
        callback(parent);
        traverseParents(parent, callback);
    }
}
export function traverseChildren(item, callback) {
    var children = item[CHILDREN];
    if (children) {
        children.forEach(function (child) {
            callback(child);
            traverseChildren(child, callback);
        });
    }
}
export function makeGetSubtree(traverseFn) {
    return function (node) {
        var items = [];
        traverseFn(node, function (relatedItem) { return items.push(relatedItem); });
        return items;
    };
}
export var getParents = makeGetSubtree(traverseParents);
export var getChildren = makeGetSubtree(traverseChildren);
