import React from 'react'
import { useSelector } from 'react-redux'

import {
  alphaEnabled,
  FeatureFlags,
  FeatureFlagValue,
  featureFlags,
  FeatureNames
} from '../../../application/config/features'
import { configurationSelectors } from '../../../domains/administration/state/configuration'

import { canRenderFeature } from './helpers'

export function useFeatureFlag () {
  const betaEnabled = useSelector(configurationSelectors.isBetaEnabled)

  return (flag?: FeatureFlagValue) => {
    if (flag === 'alpha' && alphaEnabled) {
      return true
    }

    if (flag === 'beta' && betaEnabled) {
      return true
    }

    if (flag === 'released') {
      return true
    }

    return true
    // throw new Error('feature has not been flagged.')
  }
}

export function useCanRenderFeature () {
  const betaEnabled = useSelector(configurationSelectors.isBetaEnabled)
  // @todo make a dev global or test global
  if (process.env.NODE_ENV === 'test') return () => true

  return (feature: FeatureNames) => {
    return canRenderFeature(feature, {
      flags: featureFlags,
      alpha: alphaEnabled,
      beta: betaEnabled
    })
  }
}

type ExtensibleFeatureNames = FeatureNames

type Props = {
  name: ExtensibleFeatureNames
  flags?: FeatureFlags<ExtensibleFeatureNames>
  alpha?: boolean
  beta?: boolean
  allowUnknown?: boolean
  children: React.ReactElement<any>
}

const Feature = ({
  name,
  flags = featureFlags,
  alpha = alphaEnabled,
  beta = false,
  allowUnknown = false,
  children
}: Props) => {
  return canRenderFeature(name, { flags, allowUnknown, alpha, beta })
    ? children
    : null
}

export default Feature
