import React from 'react'

export function wrapSubstr (
  str: string,
  substr: string,
  wrapper: (occurence: string) => React.ReactNode
) {
  const [start, end] = str.split(substr)
  return [
    start,
    wrapper(substr),
    end
  ]
}
