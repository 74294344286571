import { translate } from '@exivity/translations'

import { configFetch, get, patch } from '../../../../API'
import { createAsyncThunk } from '../../../../store/utils'
import { fetchSystemData } from '../../../../actions/system'

import { removeTrailingSlash } from './utils'
import { configurationSelectors } from './selector'

const getOptions = {
  background: true,
  workStatusMessage: translate('Syncing configuration')
}

const fetchConfig = createAsyncThunk<any, void>(
  'config/configuration',
  async () => {
    try {
      const response = await get('/configuration', {}, getOptions)
      return response.configuration
    } catch {}
  }
)

const bootstrapAppConfig = createAsyncThunk<any, void>(
  'config/bootstrapConfiguration',
  async (_, { dispatch, getState, signal }
  ) => {
    const state = getState()

    try {
      const url = `${self.location.protocol}//${self.location.host}/config.json`
      const response = await configFetch(url, { signal })

      const staticConfig = response.json()

      dispatch(fetchSystemData())
      dispatch(fetchConfig())

      return {
        // staticConfig can be overruled
        APP_WHITE_LABEL: staticConfig.whiteLabel ?? configurationSelectors.isWhiteLabeled(state),
        API_ROOT: staticConfig.apiHost
          ? removeTrailingSlash(staticConfig.apiHost)
          : configurationSelectors.getApiRoot(state)
      }
    } catch {}
  }
)

const persistConfig = createAsyncThunk<any, void>(
  'config/persistConfiguration',
  (_, { getState }
  ) => {
    return patch('/configuration', {}, { configuration: getState().configuration })
  }
)

export const configurationThunks = {
  fetchConfig,
  persistConfig,
  bootstrapAppConfig
}
