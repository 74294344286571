export * from './order';
export * from './search';
/** Combine the arrays into one array while removing the duplicates. */
function symmetricDifference() {
    var arrays = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arrays[_i] = arguments[_i];
    }
    return arrays
        .map(function (array, i) {
        return array.filter(function (element) { return !arrays.some(function (a, j) { return i !== j && a.includes(element); }); });
    })
        .flat();
}
export function toggleValue(array, value) {
    return symmetricDifference(array, [value]);
}
