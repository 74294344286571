import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { SelectFilter } from '@exivity/ui'
import { queries, Resources, useCacheQueryFn, useCacheQuery } from '@exivity/data-layer'
import { exists } from '@exivity/fp'

import { reportsActions, reportSelectors } from '../../state'
import { stringSortRecordsBy } from '../../../../utils/array'

export const useReportServiceCategories = () => {
  const categories = useSelector(reportSelectors.getReportServiceCategoryIds)
  const queryFn = useCacheQueryFn()

  return useMemo(() => {
    const queryCategory = (id: string) => queryFn(queries.find('servicecategory', id))

    return categories
      .map(queryCategory)
      .filter(exists)
      .sort(stringSortRecordsBy('name')) as Resources['servicecategory'][]
  }, [categories, queryFn])
}

const getCategoryId = (category: Resources['servicecategory']) => category.id
const getCategoryName = (category: Resources['servicecategory']) => category.attributes.name

export function ServiceCategoryFilter () {
  const selectedCategoryId = useSelector(reportSelectors.getSelectedServiceCategoryId)
  const selectedCategory = useCacheQuery(queries.find('servicecategory', selectedCategoryId))
  const categories = useReportServiceCategories()
  const dispatch = useDispatch()

  return (
    <SelectFilter
      searchable
      placeholder={translate('Category')}
      data={categories}
      valueAccessor={getCategoryId}
      labelAccessor={getCategoryName}
      inputValue={selectedCategory?.attributes?.name || undefined}
      value={selectedCategoryId || undefined}
      clearText={translate('Clear filter')}
      onChange={(id) => {
        dispatch(reportsActions.filters.updateCategory(id))
      }}
      onClear={
        selectedCategoryId
          ? () => dispatch(reportsActions.filters.updateCategory(null))
          : undefined
      } />
  )
}
