import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setLanguage, selectedLanguage } from '@exivity/translations'

import { configurationSelectors } from '../../../domains/administration/state/configuration'
import { useUserPreferences } from '../../myProfile/hooks'

export function useTranslation () {
  const [language, setLang] = useState(selectedLanguage)
  const appLanguage = useSelector(configurationSelectors.getAppLanguage)
  const isConfigHydrated = useSelector(configurationSelectors.isHydrated)

  const [userPreference] = useUserPreferences(preferences => preferences.language)
  const chosenLanguage = userPreference || (isConfigHydrated ? appLanguage : undefined)

  useEffect(() => {
    if (chosenLanguage) {
      setLanguage(chosenLanguage)
      setLang(chosenLanguage)
    }
  }, [chosenLanguage])

  return language
}
