const createInstanceFinder = (reportItem) => {
  return (instance) => (
    String(reportItem.instance_value) === String(instance.key) && instance.key !== 'other'
  )
}

export default (report, topInstances, breakDownBy = 'day', valueType) => {
  if (!report) {
    return null
  }

  // To get right xAxis rendered, with monthly granularity take middle of month
  const monthlyGranularitySelected = breakDownBy === 'month' ? '15' : ''

  const data = {}
  // Loop over all top accounts, as to include
  // only the top series in the timeline limited by MAX_GRAPHS.
  report.forEach((reportItem) => {
    // If we didn't see this time-unit before, create an entry in the data list
    if (!data[reportItem[breakDownBy]]) {
      data[reportItem[breakDownBy]] = {
        date: reportItem[breakDownBy] + monthlyGranularitySelected,
        other: 0
      }
    }

    if (topInstances.find(createInstanceFinder(reportItem))) {
      // Sum instances valueType -> Also when same instance belongs
      // to same day but different service.
      // Detailstable shows instances per service
      if (data[reportItem[breakDownBy]][reportItem.instance_value]) {
        data[reportItem[breakDownBy]][reportItem.instance_value] += reportItem[valueType]
      } else {
        data[reportItem[breakDownBy]][reportItem.instance_value] = reportItem[valueType]
      }
    } else {
      data[reportItem[breakDownBy]].other += reportItem[valueType]
    }
  })

  return {
    series: topInstances.map(item => ({ ref: item.key, title: item.title })),
    granularity: breakDownBy,
    data: Object.values(data)
  }
}
