import React from 'react'
import { Group, Field, Label, Radio } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { UserGroupPermission } from '../../../../../data/types'
import Permissions from '../../Permissions'

interface PermissionsTabProps {
  permissions: UserGroupPermission[]
  onChangePermissions: (permissions: UserGroupPermission[]) => void
}

export function PermissionsTab ({ permissions, onChangePermissions }: PermissionsTabProps) {
  return (
    <>
      <Group title={translate('Permissions')}>
        <Field.Container>
          <Field data-testid='type'>
            <Label>{translate('Permissions type')}</Label>
            <Radio.Group
              value={permissions.includes(UserGroupPermission.All) ? 'all' : 'custom'}
              onChange={(type) => {
                if (type === 'all') {
                  onChangePermissions([UserGroupPermission.All])
                } else {
                  onChangePermissions([])
                }
              }}>
              <Radio
                label={translate('Grant all permissions')}
                value='all' />
              <Radio
                label={translate('Grant only specific permissions')}
                value='custom' />
            </Radio.Group>
          </Field>
        </Field.Container>
      </Group>

      <Permissions
        value={permissions}
        onChange={onChangePermissions} />
    </>
  )
}
