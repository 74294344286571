import cloneDeep from 'lodash/cloneDeep'

import { clean, stringSort, stringSortRecordsBy } from '../../../utils/array'
import { randomChars } from '../../../utils/string'
import config from '../../../application/config'

import { warn } from './../../../utils/log'

const AS_ROOT = 'asRoot'
const THROW = 'throw'
const IGNORE = 'ignore'

function isPlaceholder (data) {
  return data.length === 1 && data[0].placeholder
}

function sanitize (data, expanded) {
  const defaultMeta = {
    depth: 0,
    expanded: expanded,
    checked: false
  }
  data.forEach(item => {
    // Ensure existence of a key prop
    if (typeof item.key === 'undefined') {
      warn('Each element in the AdvancedSelect data should contain at least a key property.')
      item.key = randomChars()
    }

    // Ensure existence of a parent prop
    if (typeof item.parent === 'undefined') {
      item.parent = null
    }

    // Ensure existence of a children prop
    if (typeof item.children === 'undefined') {
      item.children = []
    }

    // Ensure existence of a attributes prop
    if (typeof item.attributes === 'undefined') {
      item.attributes = {}
    }

    // Ensure existence of a meta prop
    if (typeof item.meta === 'undefined') {
      item.meta = cloneDeep(defaultMeta)
    } else {
      item.meta = Object.assign({}, cloneDeep(defaultMeta), item.meta)
    }
  })
}

function setParentGroup (next, group) {
  return next.parent
    ? next
    : {
      ...next,
      parent: group
    }
}

function group (data, groupBy, sortBy) {
  // Group items in object under groupBy keys and assign items without parent their group header as
  // parent
  const groupedItems = data.reduce((acc, next) => {
    const group = next.attributes && next.attributes[groupBy]
    if (group) {
      if (!acc[group]) {
        acc[group] = []
      }
      acc[group].push(setParentGroup(next, group))
    }
    return acc
  }, {})

  // Sort arrays and flatten object
  return Object.keys(groupedItems).sort(stringSort).reduce((acc, next) => {
    groupedItems[next].sort(stringSortRecordsBy(sortBy))
    const group = {
      key: next,
      group: next,
      meta: { expanded: true }
    }
    return acc
      .concat([group])
      .concat(groupedItems[next])
  }, [])
}

function sort (data, sortBy) {
  return data.sort(stringSortRecordsBy(sortBy))
}

function createMap (data) {
  const map = {}

  data.forEach((item) => {
    if (typeof (map[item.key]) !== 'undefined') {
      warn('Each element in the AdvancedSelect data should have a unique key property.')
    } else {
      map[item.key] = item
    }
  })

  return map
}

/**
 * A really simple algorithm to create a hierarchical data structure from a flat one.
 * With some help from https://stackoverflow.com/a/18018037/938297.
 *
 * @param {Array} data
 * @param {string} invalidParentMode Either "asRoot", "throw", "ignore"
 * @returns {Array}
 */
function buildTree (data, invalidParentMode = AS_ROOT) {
  const map = createMap(data)
  const roots = []

  data.forEach(item => {
    const mapItem = map[item.key]
    if (mapItem.parent && typeof (map[mapItem.parent]) === 'undefined') {
      // We have found a child node, but the parent is not available
      if (invalidParentMode === THROW) {
        throw new Error('Parent doesn\'t exist, please check your AdvancedSelect input data.')
      } else if (invalidParentMode === IGNORE) {
        // Ignore this line. It's here only so we don't get any warnings about 'assigned a value
        // but never used'.
      } else if (invalidParentMode === AS_ROOT) {
        // Remove parent so it can't cause any conflicts later on and add it to the tree at the
        // root level
        mapItem.parent = null
        roots.push(mapItem)
      }
    } else if (mapItem.parent) {
      // Parent is available
      map[mapItem.parent].children.push(mapItem)
      mapItem.parent = map[item.parent]
    } else {
      // We have found a root node, add it to the tree at the root level
      roots.push(mapItem)
    }
  })

  return roots
}

function calculateDepths (data) {
  data.forEach(item => {
    if (item.parent) {
      if (!item.parent.meta) {
        warn('Parent has no meta object. Hold on, that\'s going to cause serious trouble')
      }
      item.meta.depth = item.parent.meta.depth + 1
    }
    calculateDepths(item.children)
  })
}

function flattenTree (data) {
  const sorted = []

  data.forEach(item => {
    sorted.push(item)
    if (item.children.length) {
      flattenTree(item.children).forEach(item => sorted.push(item))
    }
  })

  return sorted
}

/**
 * Transforms an input in the form of:
 *
 * data = [{
 *   key: 1,
 *   attributes: {
 *     name: "Foo"
 *   }
 * }, {
 *   key: 2,
 *   parent: 1,
 *   attributes: {
 *     name: "Bar"
 *   }
 * }]
 *
 * to:
 *
 * return [{
 *   key: 1,
 *   parent: null,
 *   children: [{
 *     key: 2,
 *     parent: @CIRCULAR_REFERENCE,
 *     attributes: {
 *       name: "Bar"
 *     },
 *     meta: {}
 *   }],
 *   attributes: {
 *     name: "Foo"
 *   },
 *   meta: {}
 * }, {
 *   key: 2,
 *   parent: {
 *     key: 1,
 *     parent: null,
 *     children: [@CIRCULAR_REFERENCE],
 *     attributes: {
 *       name: "Foo"
 *     },
 *     meta: {}
 *   },
 *   children: [],
 *   attributes: {
 *     name: "Bar"
 *   },
 *   meta: {}
 * }]
 *
 * Parents and child reference eachother, so there's only a single entry for each item in memory.
 *
 * @param {Array} data
 * @param {string} groupBy Optional
 * @param {string} sortBy Optional
 * @param {Boolean} expanded
 * @param {string} invalidParentMode Either "asRoot", "throw", "ignore"
 * @returns {Array}
 */
export default function normalize (data, groupBy, sortBy, expanded, invalidParentMode = AS_ROOT) {
  config.app.profiler && console.log('normalize input', cloneDeep(data))

  data = clean(data)

  if (!isPlaceholder(data)) {
    if (groupBy) {
      data = group(data, groupBy, sortBy)
      config.app.profiler && console.log('grouped', cloneDeep(data))
    }

    if (!groupBy && sortBy) {
      data = sort(data, sortBy)
      config.app.profiler && console.log('sorted', cloneDeep(data))
    }
  }

  sanitize(data, expanded)
  config.app.profiler && console.log('sanitized', cloneDeep(data))

  data = buildTree(data, invalidParentMode)
  config.app.profiler && console.log('tree', cloneDeep(data))

  calculateDepths(data)
  config.app.profiler && console.log('calculated depths', cloneDeep(data))

  data = flattenTree(data)
  config.app.profiler && console.log('flattened tree', cloneDeep(data))

  return data
}
