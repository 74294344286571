import React from 'react'
import { useCrud } from 'react-crud-hook'
import { Tabs, useModal } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useResetCache } from '@exivity/data-layer'

import { renderServiceTabs } from '../ServiceForm/ServiceTabs'
import SourceGroup from '../ServiceForm/SourceGroup'
import IntervalGroup from '../ServiceForm/IntervalGroup'
import BillingGroup, { useDidBillingTypesChangeFn } from '../ServiceForm/BillingGroup'
import ServiceForm from '../ServiceForm/ServiceForm'
import Metadata from '../Metadata'
import { DEFAULT_SERVICE, ServiceModel } from '../../../../data/types'
import { useMetadata } from '../../../metadata/components/MetadataForm/useMetadata'
import { reportsThunks, PreparableResource } from '../../../reports/state'
import ServiceGroup from '../ServiceForm/ServiceGroup'
import { removeRatesRelationshipBeforeSave } from '../removeRatesRelationshipBeforeSave'

import Toolbar from './Toolbar'

import { ServiceProps } from './'

export function Service ({
  service = DEFAULT_SERVICE,
  rates = [],
  useEditToggle
}: ServiceProps) {
  const serviceRecord = useCrud<ServiceModel>(service)
  const [editing, toggleEdit] = useEditToggle
  const [metadataRecord, onMetadataRecord] = useMetadata()
  const confirm = useModal().confirm

  const didBillingTypeChange = useDidBillingTypesChangeFn()
  const resetCache = useResetCache()

  return (
    <ServiceForm
      service={serviceRecord}
      ratesCount={rates.length}
      editable={editing}>
      <Tabs>
        {renderServiceTabs()}
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <ServiceGroup service={serviceRecord} editing={editing} />

            <SourceGroup
              service={serviceRecord}
              editing={editing} />

            <IntervalGroup
              service={serviceRecord}
              editing={editing} />

            <BillingGroup
              service={serviceRecord}
              editing={editing} />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Metadata service={serviceRecord} onRecord={onMetadataRecord} />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>

      <Toolbar
        locked={!editing}
        showLockButton={!editing}
        enableDelete={editing}
        toggleLock={() => {
          if (!editing) {
            confirm({
              body: translate('Are you sure you want to enable edit mode? After making changes, all reports using this service must be re-prepared')
            }).then(toggleEdit)
          } else {
            toggleEdit()
          }
        }}
        remove={() => serviceRecord.delete({
          beforeDelete (_: any, { confirm }: any) {
            return confirm(translate('Are you sure you want to delete this service?'))
              .then(() => true)
          },
          onDelete (record: any, { router }: any) {
            router.push('services/overview')

            if (didBillingTypeChange(serviceRecord)) {
              resetCache('rate')
              resetCache('ratetier')
            }
          }
        })}
        update={() => {
          serviceRecord.save({
            beforeUpdate: removeRatesRelationshipBeforeSave as any,
            onUpdate: () => {
              if (metadataRecord) {
                metadataRecord.save()
              }

              if (didBillingTypeChange(serviceRecord)) {
                resetCache('rate')
                resetCache('ratetier')
              }
            }
          })
        }}
        updateAndPrepare={() => {
          serviceRecord.save({
            beforeUpdate: removeRatesRelationshipBeforeSave as any,
            onUpdate: (record: any, { dispatch }: any) => {
              if (metadataRecord) {
                metadataRecord.save()
              }

              if (didBillingTypeChange(serviceRecord)) {
                resetCache('rate')
                resetCache('ratetier')
              }

              dispatch(
                reportsThunks
                  .prepare
                  .prepareAffectedReports({ resource: PreparableResource.Service, id: record.id })
              )
            }
          })
        }} />
    </ServiceForm>
  )
}
