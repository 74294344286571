var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
var StyledList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"], ["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"])));
function _ListItem(_a) {
    var index = _a.index, style = _a.style, data = _a.data;
    return (_jsx("span", __assign({ style: style }, { children: data.renderItem(index) }), void 0));
}
var ListItem = React.memo(_ListItem, areEqual);
export function List(_a) {
    var itemCount = _a.itemCount, renderItem = _a.renderItem, itemHeight = _a.itemHeight, overscanCount = _a.overscanCount;
    return (_jsx(AutoSizer, __assign({ style: { height: '100%', width: '100%' } }, { children: function (_a) {
            var autoHeight = _a.height;
            return (_jsx(FixedSizeList, __assign({ itemSize: itemHeight, itemCount: itemCount, height: autoHeight, itemData: { renderItem: renderItem }, width: '100%', overscanCount: overscanCount, innerElementType: StyledList }, { children: ListItem }), void 0));
        } }), void 0));
}
var templateObject_1;
