import React from 'react'
import PropTypes from 'prop-types'

import { classNames } from '../../../utils/misc'

import './index.css'
/**
 * @type {React.ComponentType<any>}
 */
const Widget = ({
  children,
  title, subTitle,
  sticky, bringToTop, noPadding, extraPadding, expandHeight, expandHeightOffset, fullscreen,
  muted, className, style
}) => (
  <div key={fullscreen ? 'widget-fs-active' : 'widget-fs-inactive'}
    className={classNames({
      'ex-widget': true,
      'ex-widget--sticky': sticky,
      'ex-widget--sticky--fullscreen': sticky && fullscreen,
      'ex-widget--on-top': bringToTop,
      'ex-widget--expand-height': expandHeight,
      'ex-widget--no-padding': noPadding,
      'ex-widget--extra-padding': extraPadding,
      'ex-widget--muted': muted
    }, className)}
    data-uk-height-viewport={
      expandHeight ? `offset-bottom: ${fullscreen ? 40 : expandHeightOffset}px` : undefined
    }
    style={style}>
    {title && (
      <h4 className='ex-widget__title'>
        {title}
        {subTitle && <span className='ex-widget__title__sub'>{subTitle}</span>}
      </h4>
    )}
    {children}
  </div>
)

Widget.propTypes = {
  bringToTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  expandHeight: PropTypes.bool,
  expandHeightOffset: PropTypes.number,
  extraPadding: PropTypes.bool,
  fullscreen: PropTypes.bool,
  muted: PropTypes.bool,
  noPadding: PropTypes.bool,
  sticky: PropTypes.bool,
  style: PropTypes.object,
  subTitle: PropTypes.node,
  title: PropTypes.string
}

Widget.defaultProps = {
  title: null,
  subTitle: null,
  className: null,
  style: {},
  sticky: false,
  bringToTop: false,
  extraPadding: false,
  noPadding: false,
  expandHeight: false,
  expandHeightOffset: 100,
  fullscreen: false,
  muted: false
}

export default Widget
