var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '../../Button';
import { Modal } from '../Modal';
import { useModalActions } from '../useModal';
export function ConfirmModal(_a) {
    var _b = _a.title, title = _b === void 0 ? 'Please confirm' : _b, _c = _a.body, body = _c === void 0 ? 'Would you like to proceed?' : _c, _d = _a.acceptLabel, acceptLabel = _d === void 0 ? 'ok' : _d, _e = _a.declineLabel, declineLabel = _e === void 0 ? 'cancel' : _e;
    var _f = useModalActions(), resolve = _f.resolve, reject = _f.reject;
    return (_jsx(Modal, __assign({ title: title, buttons: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ secondary: true, onClick: reject }, { children: declineLabel }), void 0), _jsx(Button, __assign({ success: true, "data-testid": 'modal-accept', onClick: resolve }, { children: acceptLabel }), void 0)] }, void 0) }, { children: typeof body === 'function'
            ? body(resolve, reject)
            : body }), void 0));
}
