import React, { useState } from 'react'
import { Resources, enums } from '@exivity/data-layer'
import { translate } from '@exivity/translations'
import { Updater } from '@exivity/use-data-structure'
import {
  Group,
  GroupBox,
  Label,
  Field,
  Select,
  Input,
  Text,
  Button,
  Alert,
  Link,
  CalendarMode,
  DatePicker
} from '@exivity/ui'
import { getDate, getHours, getMinutes, getMonth, getYear, isValid, set } from 'date-fns'
import { compose } from 'ramda'
import { MdDelete } from 'react-icons/md'

import { Formats, useDateFormatter } from '../../../../components/hooks/useDateFormatter'

import { timezones } from './timezones'

const { WorkflowScheduleFrequency } = enums

interface ScheduleProps {
  schedule: Resources['workflowschedule']
  updater: Updater<Resources['workflowschedule']>
  onDelete: () => void
}

export function Schedule ({ schedule, updater, onDelete }: ScheduleProps) {
  const formatter = useDateFormatter()

  const startTime = schedule.attributes.start_time
    ? formatter.parse(Formats.IsoDateTime, schedule.attributes.start_time)
    : undefined

  const [skipMonthWarningDismissed, setSkipMonthWarningDismissed] = useState(false)
  const [gmtWarningDismissed, setGmtWarningDismissed] = useState(false)

  return (
    <GroupBox initialCollapsed={!!schedule.id}>
      <GroupBox.Header>
        <GroupBox.Title subTitle={
          startTime
            ? translate('Starting on {start_date} at {time}', formatter.format(Formats.GuiDate, startTime), formatter.format(Formats.GuiTime, startTime))
            : undefined
        }>
          {(schedule.attributes.frequency_modifier > 1
            ? {
              [WorkflowScheduleFrequency.Hourly]: translate('Every {modifier} hours', schedule.attributes.frequency_modifier),
              [WorkflowScheduleFrequency.Daily]: translate('Every {modifier} days', schedule.attributes.frequency_modifier),
              [WorkflowScheduleFrequency.Monthly]: translate('Every {modifier} months', schedule.attributes.frequency_modifier)
            }
            : {
              [WorkflowScheduleFrequency.Hourly]: translate('Every hour'),
              [WorkflowScheduleFrequency.Daily]: translate('Every day'),
              [WorkflowScheduleFrequency.Monthly]: translate('Every month')
            })[schedule.attributes.frequency]
          }
        </GroupBox.Title>
        <GroupBox.Join>
          <GroupBox.Actions>
            <Button icon={<MdDelete />} small round transparent onClick={onDelete} />
          </GroupBox.Actions>
          <GroupBox.Collapser />
        </GroupBox.Join>
      </GroupBox.Header>
      <GroupBox.Content>

        <Group title={translate('Frequency')}>
          <Field.Container>
            <Field>
              <Label>{translate('Type')}</Label>
              <Select
                required
                data={Object.values(WorkflowScheduleFrequency)}
                labelAccessor={frequency => {
                  return {
                    [WorkflowScheduleFrequency.Hourly]: translate('Hourly'),
                    [WorkflowScheduleFrequency.Daily]: translate('Daily'),
                    [WorkflowScheduleFrequency.Monthly]: translate('Monthly')
                  }[frequency]
                }}
                value={schedule.attributes.frequency}
                onChange={updater.setAttribute('frequency')} />
            </Field>

            <Field>
              <Label>{translate('Run every')}</Label>
              <Field.Group>
                <Input
                  type='number'
                  required
                  min={1}
                  value={schedule.attributes.frequency_modifier}
                  onChange={compose(updater.setAttribute('frequency_modifier'), Number)} />
                <Text>
                  {{
                    [WorkflowScheduleFrequency.Hourly]: translate('Hours'),
                    [WorkflowScheduleFrequency.Daily]: translate('Days'),
                    [WorkflowScheduleFrequency.Monthly]: translate('Months')
                  }[schedule.attributes.frequency]}
                </Text>
              </Field.Group>
            </Field>
          </Field.Container>
        </Group>

        <Group title={translate('Start of schedule')}>
          {(
            !skipMonthWarningDismissed
            && schedule.attributes.frequency === WorkflowScheduleFrequency.Monthly
            && startTime && getDate(startTime) > 28
          ) && (
            <Alert warning onClosed={() => setSkipMonthWarningDismissed(true)}>
              {translate('Workflow may not run every month of the year.')}
            </Alert>
          )}
          <Field.Container>
            <Field>
              <Label>{translate('Effective from')}</Label>
              <DatePicker
                required
                mode={CalendarMode.Days}
                value={startTime || undefined}
                onChange={compose(
                  updater.setAttribute('start_time'),
                  formatter.formatUsingTimeZone(Formats.IsoDateTime, 'UTC')
                )} />
            </Field>

            {(schedule.attributes.frequency === WorkflowScheduleFrequency.Hourly
              && schedule.attributes.frequency_modifier === 1)
              ? (
                <Field>
                  <Label>{translate('Start minute')}</Label>
                  <Input
                    type='number'
                    required
                    value={
                      startTime
                        ? formatter.formatUsingTimeZone(
                          Formats.GuiMinutes,
                          schedule.attributes.timezone,
                          startTime
                        )
                        : ''
                    }
                    min={0}
                    max={59}
                    onChange={(minutes) => {
                      const date = set(startTime || new Date(), { minutes: Number(minutes) })
                      const isoDate = formatter
                        .formatUsingTimeZone(Formats.IsoDateTime, 'UTC', date)

                      updater.setAttribute('start_time', isoDate)
                    }} />
                </Field>
              )
              : (
                <Field>
                  <Label>{translate('Start time')}</Label>
                  <Input
                    type='time'
                    required
                    value={
                      startTime
                        ? formatter.formatUsingTimeZone(
                          Formats.GuiTime,
                          schedule.attributes.timezone,
                          startTime
                        )
                        : ''
                    }
                    onChange={(time) => {
                      const baseDate = startTime || new Date()

                      // Use date-fns to get/set hours and minutes, creating a zoned Date
                      const newTime = formatter.parse(Formats.GuiTime, time)
                      const zonedDate = set(baseDate, {
                        hours: getHours(newTime),
                        minutes: getMinutes(newTime)
                      })

                      if (!isValid(zonedDate)) return

                      // Convert zoned Date back to UTC and reset date
                      // (or we could end up in next/previous day)
                      const utcDate = set(
                        formatter.zonedTimeToUtc(schedule.attributes.timezone, zonedDate),
                        {
                          year: getYear(baseDate),
                          month: getMonth(baseDate),
                          date: getDate(baseDate)
                        }
                      )

                      const isoDate = formatter
                        .formatUsingTimeZone(Formats.IsoDateTime, 'UTC', utcDate)

                      updater.setAttribute('start_time', isoDate)
                    }} />
                </Field>
              )
            }
            {!gmtWarningDismissed && schedule.attributes.timezone.includes('GMT') && (
              <Alert onClosed={() => setGmtWarningDismissed(true)}>
                {schedule.attributes.timezone.includes('GMT-')
                  ? translate('The minus sign in the timezone means east of GMT.')
                  : translate('The plus sign in the timezone means west of GMT.')}
                {' '}
                <Link
                  href='https://en.wikipedia.org/wiki/Tz_database#Area'
                  target='_blank' rel='noopener noreferrer'>
                  Click here for more information.
                </Link>
              </Alert>
            )}
            <Field>
              <Label>{translate('Time zone')}</Label>
              <Select
                required
                searchable
                data={timezones}
                value={schedule.attributes.timezone}
                onChange={updater.setAttribute('timezone')} />
            </Field>
          </Field.Container>
        </Group>
      </GroupBox.Content>
    </GroupBox>
  )
}
