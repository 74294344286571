import moment from 'moment'
import fetch from 'isomorphic-fetch'
import fileSaver from 'file-saver'

import store from '../../../../../store'
import { getUserSessionStorage } from '../../../../../store/storage'
import { Dispatch } from '../../../../../store/utils'
import { getApiUrl } from '../../../../../API/fetch'
import { logError } from '../../../../../utils/log'
import { toQueryString } from '../../../../../API'
import { reportSelectors } from '../../'
import { SummaryGroupInstancesBy } from '../../filters/filterTypes'
import { workActions } from '../../../../work/state'
import { workThunks } from '../../../../work/thunks'
import config from '../../../../../application/config'

type Format = 'json' | 'csv' | 'pdf/summary'

interface Params {
  start: string
  end: string
  format: Format
  include: string
  summary_options: string
  dimension: string
  timeline: 'none'
  depth: number
  'filter[parent_account_id]'?: string
  'filter[account_id]'?: string
}

const download = (format: Format, accountId: string|null) => {
  const state = store.getState()
  const start = moment(state.reports.filters.dateRange[0]).format('YYYYMMDD')
  const end = moment(state.reports.filters.dateRange[1]).format('YYYYMMDD')
  const include = [
    'account_key',
    'account_name',
    'service_key',
    'service_description',
    'servicecategory_name',
    'start_date',
    'end_date'
  ].join(',')

  const showServices = reportSelectors.isIncludedInSummary('services')(state)
  const showInstances = reportSelectors.isIncludedInSummary('instances')(state)
  const showAccounts = reportSelectors.isIncludedInSummary('accounts')(state)
  const consolidated = reportSelectors.shouldConsolidateSummary(state)
  const account = reportSelectors.getSelectedAccount(state)
  const accountDepth = reportSelectors.getAccountDepth(state)

  const groupBy = reportSelectors.getInstanceGroupBy(state) === SummaryGroupInstancesBy.Instances
    ? 'instances_by_instance'
    : 'instances_by_service'

  const summary_options = []

  showServices && summary_options.push('services')
  showInstances && summary_options.push(groupBy)
  showAccounts && summary_options.push('accounts')
  consolidated && summary_options.push('consolidated')

  const parameters: Params = {
    start,
    end,
    format,
    include,
    summary_options: summary_options.join(','),
    dimension: showInstances
      ? 'accounts,services,instances'
      : 'accounts,services',
    timeline: 'none',
    depth: Math.max(account
      ? Math.min(accountDepth + 1, config.reports.maxLevel)
      : accountDepth,
    1),
    ...account && { 'filter[parent_account_id]': account },
    ...accountId && !consolidated && { 'filter[account_id]': accountId }
  }

  const definition = state.reports.filters.report
  const url = `/reports/${definition}/run?${toQueryString(parameters)}`
  let accept = ''
  switch (format) {
    case 'csv':
      accept = 'text/csv,application/json'
      break
    case 'pdf/summary':
      accept = 'application/pdf,application/json'
      break
    default:
  }

  const fetchOptions: RequestInit = {
    method: 'GET',
    headers: {
      Accept: accept,
      Authorization: `Bearer ${getUserSessionStorage.fromEitherStorage('session').token || ''}`
    }
  }

  const dispatch: Dispatch = store.dispatch

  dispatch(workActions.addThread('foreground'))
  fetch(getApiUrl(url), fetchOptions)
    .then(response => {
      if (response.ok) {
        let filename = 'invoice'
        const contentDispositionHeader = response.headers.get('Content-Disposition')
        if (contentDispositionHeader) {
          const matched = /filename\s*=\s*["|'](.*)["|']/.exec(contentDispositionHeader)
          if (matched) {
            filename = matched[1]
          }
        }
        response.blob().then((blob: any) => { fileSaver.saveAs(blob, filename) })
      } else {
        logError(`API returned ${response.status}`, response.statusText)
        dispatch(workThunks.showDefaultApiErrorMessage())
      }
      dispatch(workActions.removeThread('foreground'))
    })
    .catch(error => {
      logError(error)
      dispatch(workThunks.showDefaultApiErrorMessage())
      dispatch(workActions.removeThread('foreground'))
    })
}

export function downloadCsv (accountId: string|null) {
  download('csv', accountId)
}

export function downloadPdf (accountId: string|null) {
  download('pdf/summary', accountId)
}
