import React from 'react'
import { Tabs } from '@exivity/ui'

export function renderServiceTabs () {
  return (
    <Tabs.TabList>
      <Tabs.Tab>Details</Tabs.Tab>
      <Tabs.Tab>Metadata</Tabs.Tab>
    </Tabs.TabList>
  )
}
