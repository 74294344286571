var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { themeSpace } from '../Theme';
import { Label } from '../Label';
import { Header, Content, Actions, Title, Join } from './components';
function defaultHeader(_a) {
    var collapsible = _a.collapsible, actions = _a.actions, title = _a.title, subTitle = _a.subTitle;
    return (_jsxs(Header, { children: [_jsxs(Label.Group, { children: [_jsx(Label, { children: title }, void 0), subTitle && _jsx(Label.Sub, { children: subTitle }, void 0)] }, void 0), _jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Actions, __assign({ onClick: function (e) { return e.stopPropagation(); } }, { children: actions }), void 0), collapsible && _jsx(CollapsibleComponent.Collapse, {}, void 0)] }), void 0)] }, void 0));
}
var StyledGroupBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  margin: ", " 0;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  margin: ", " 0;\n"])), themeSpace(1));
function GroupBox(_a) {
    var children = _a.children, title = _a.title, subTitle = _a.subTitle, actions = _a.actions, className = _a.className, initialCollapsed = _a.initialCollapsed, collapsed = _a.collapsed, onCollapseChange = _a.onCollapseChange;
    var collapsible = initialCollapsed !== undefined || collapsed !== undefined;
    var child = (_jsx(StyledGroupBox, __assign({ className: className }, { children: title
            ? (_jsxs(_Fragment, { children: [defaultHeader({
                        collapsible: collapsible,
                        title: title,
                        subTitle: subTitle,
                        actions: actions
                    }), _jsx(Content, { children: children }, void 0)] }, void 0))
            : children }), void 0));
    return collapsible
        ? (_jsx(CollapsibleComponent, __assign({ initialCollapsed: initialCollapsed, collapsed: collapsed, onCollapseChange: onCollapseChange }, { children: child }), void 0))
        : child;
}
GroupBox.Header = Header;
GroupBox.Title = Title;
GroupBox.Collapser = CollapsibleComponent.Collapse;
GroupBox.Actions = Actions;
GroupBox.Content = Content;
GroupBox.Join = Join;
export { GroupBox };
var templateObject_1;
