import { combineReducers } from 'redux'

import { reports } from '../domains/reports/state'
import { authReducer as auth, authActions } from '../domains/auth/state'
import { profileReducer as profile } from '../domains/myProfile/state'
import { saml } from '../domains/saml/state'
import { audit, license } from '../domains/administration/state'
import { workReducer as work } from '../domains/work/state'
import {
  configurationReducer as configuration,
  configurationSelectors
} from '../domains/administration/state/configuration'

import system, { SystemState } from './system'
import gui, { GUIState } from './gui'
import logs, { LogsState } from './logs'
import data, { DataState } from './data'
import datasources, { DataSourcesState } from './datasources'

export interface RootState {
  auth: ReturnType<typeof auth>
  reports: ReturnType<typeof reports>
  work: ReturnType<typeof work>
  gui: GUIState
  data: DataState
  datasources: DataSourcesState
  profile: ReturnType<typeof profile>

  // administration
  license: ReturnType<typeof license>
  audit: ReturnType<typeof audit>
  saml: ReturnType<typeof saml>
  system: SystemState
  configuration: ReturnType<typeof configuration>
  logs: LogsState
}

const appReducer = combineReducers<Partial<RootState>>({
  auth,
  reports,
  system,
  configuration,
  work,
  gui,
  audit,
  logs,
  data,
  datasources,
  profile,
  saml,
  license
} as any)

const rootReducer = (
  state: RootState,
  action: any
) => {
  if (action.type === authActions.updateIsAuthenticated.type) {
    if (action.payload === false) {
      // Reset configuration, but keep some settings

      (state as any) = {
        configuration: configurationSelectors.getPublicConfiguration(state),
        work: state.work
      }
    }
  }

  return appReducer(state, action)
}

export default rootReducer
