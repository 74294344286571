var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function listToMap(data, keyAccessor) {
    var map = {};
    data.forEach(function (item) {
        map[keyAccessor(item)] = __assign({}, item);
    });
    return map;
}
