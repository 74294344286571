import React, { useRef } from 'react'
import { Calendar, DatePicker, Field, Group, Input, Label, Radio, Select } from '@exivity/ui'
import { translate } from '@exivity/translations'
import compose from 'lodash/fp/compose'
import { ServiceSubscriptionAltInterval } from '@exivity/data-layer'

import { CHARGE_DAY_RANGE, ServiceInterval, SubscriptionType } from '../../../../../data/types'
import { Formats, useDateFormatter } from '../../../../../components/hooks/useDateFormatter'
import { SERVICE_INTERVAL_LABELS } from '../../../../services/components/intervalLabels'

interface IntervalGroupProps {
  type: SubscriptionType
  startDate: string
  endDate: string | null
  chargeDay: string | null
  altInterval: ServiceSubscriptionAltInterval | null
  serviceInterval?: ServiceInterval
  onChangeType: (type: SubscriptionType) => void
  onChangeStartDate: (startDate: string) => void
  onChangeEndDate: (endDate: string | null) => void
  onChangeChargeDay: (chargeDay: string | null) => void
  onChangeAltInterval: (interval: ServiceSubscriptionAltInterval | null) => void
}

function formatChargeDayToIsoMonthDay (chargeDay = '1', chargeMonth = '0') {
  function prefixZero (val: string) {
    if (Number(val) < 10) {
      return `0${Number(val)}`
    }

    return val
  }

  return `--${prefixZero(chargeMonth)}-${prefixZero(chargeDay)}`
}

function removePrefixZero (day: string) {
  return String(Number(day))
}

// returns [chargeMonth, chargeDay]
function parseChargeDay (chargeDay: string | null) {
  if (chargeDay && chargeDay.length < 3) {
    return [undefined, chargeDay] as const
  }

  if (chargeDay) {
    return [
      removePrefixZero(chargeDay.substr(2, 2)),
      removePrefixZero(chargeDay.substr(5, 2))
    ] as const
  }

  return [undefined, undefined] as const
}

const ALT_INTERVAL: Record<
  ServiceSubscriptionAltInterval|'inherit',
  (serviceInterval?: ServiceInterval) => string
> = {
  inherit: (serviceInterval?: ServiceInterval) => {
    const interval = serviceInterval ? SERVICE_INTERVAL_LABELS[serviceInterval]() : ''
    return translate('Inherit from service ({serviceInterval})', interval)
  },
  [ServiceSubscriptionAltInterval.Individually]: () => translate('Individually'),
  [ServiceSubscriptionAltInterval.Day]: () => translate('Daily'),
  [ServiceSubscriptionAltInterval.Month]: () => translate('Monthly'),
  [ServiceSubscriptionAltInterval.Year]: () => translate('Yearly')
}

export function isOfIntervalType (
  type: ServiceInterval,
  interval: ServiceInterval | null,
  serviceInterval?: ServiceInterval
) {
  return interval === type
    || (interval === null && serviceInterval === type)
}

export function IntervalGroup ({
  type,
  startDate,
  endDate,
  chargeDay,
  altInterval,
  serviceInterval,
  onChangeType,
  onChangeStartDate,
  onChangeEndDate,
  onChangeChargeDay,
  onChangeAltInterval
}: IntervalGroupProps) {
  const formatter = useDateFormatter()
  const chargeMonthRef = useRef<HTMLInputElement>(null)

  const [chargeMonthValue, chargeDayValue] = parseChargeDay(chargeDay)

  const isYearlyInterval = altInterval === ServiceSubscriptionAltInterval.Year
  const isMonthlyInterval = altInterval === ServiceSubscriptionAltInterval.Month
    || serviceInterval === ServiceInterval.Month

  return (
    <Group title={translate('Interval')}>
      <Field.Container>
        <Field data-testid='type'>
          <Label.Group>
            <Label>{translate('Type')}</Label>
            <Label.Sub>{translate('Choose between recurring or one-off')}</Label.Sub>
          </Label.Group>
          <Radio.Group value={type} onChange={onChangeType}>
            <Radio
              label={translate('Recurring subscription')}
              value={SubscriptionType.Recurring} />
            <Radio
              label={translate('One-off transaction')}
              value={SubscriptionType.OneOff} />
          </Radio.Group>
        </Field>

        {type === SubscriptionType.Recurring && (
          <>
            <Field data-testid='interval'>
              <Label.Group>
                <Label>{translate('Interval')}</Label>
                <Label.Sub>
                  {translate('Defaults to the interval of the selected service')}
                </Label.Sub>
              </Label.Group>

              <Select
                placeholder={translate('Select an interval')}
                data={Object.keys(ALT_INTERVAL) as (ServiceSubscriptionAltInterval|'inherit')[]}
                value={altInterval === null ? 'inherit' as const : altInterval}
                labelAccessor={(value) => ALT_INTERVAL[value](serviceInterval)}
                onChange={e => {
                  onChangeAltInterval(
                    e === 'inherit'
                    ? null
                    : e
                  )
                }} />
            </Field>

            <Field data-testid='start-date'>
              <Label>{translate('Start date')}</Label>
              <DatePicker
                required
                value={formatter.parse(Formats.IsoDate, startDate)}
                onChange={compose(
                  onChangeStartDate,
                  formatter.format(Formats.IsoDate),
                  Calendar.Date.startOfRange
                )} />

            </Field>
            <Field data-testid='end-date'>
              <Label.Group>
                <Label>{translate('End date')}</Label>
                <Label.Sub>
                  {translate('Optional')}
                </Label.Sub>
              </Label.Group>
              <DatePicker
                value={endDate ? formatter.parse(Formats.IsoDate, endDate) : undefined}
                onChange={(date, mode) => {
                  if (date) {
                    compose(
                      onChangeEndDate,
                      formatter.format(Formats.IsoDate),
                      Calendar.Date.endOfRange
                    )(date, mode)
                  } else {
                    onChangeEndDate(null)
                  }
                }} />
            </Field>

            {
              isYearlyInterval
              && (
                <Field data-testid='charge-month'>
                  <Label.Group>
                    <Label>
                      {translate('Charge month')}
                    </Label>
                    <Label.Sub>
                      {translate('Month of the year that this subscription will be charged. Number between 1 and 12.')}
                    </Label.Sub>
                  </Label.Group>
                  <Input
                    ref={chargeMonthRef}
                    type='number'
                    placeholder='1-12'
                    min={1}
                    max={12}
                    value={chargeMonthValue || ''}
                    onChange={(month) => {
                      onChangeChargeDay(
                        formatChargeDayToIsoMonthDay(
                          chargeDayValue || '1',
                          month
                        )
                      )
                    }} />
                </Field>
              )}

            {
              (isYearlyInterval || isMonthlyInterval)
              && (
                <Field data-testid='charge-day'>
                  <Label.Group>
                    <Label>{translate('Charge day')}</Label>
                    <Label.Sub>
                      {translate('Day of the month that this subscription will be charged. Number between 1 and 28.')}
                    </Label.Sub>
                  </Label.Group>
                  <Input
                    type='number'
                    placeholder={`${CHARGE_DAY_RANGE.min}-${CHARGE_DAY_RANGE.max}`}
                    min={CHARGE_DAY_RANGE.min}
                    max={CHARGE_DAY_RANGE.max}
                    value={chargeDayValue || ''}
                    onChange={(chargeDay) => {
                      if (chargeMonthRef.current !== null && isYearlyInterval) {
                        onChangeChargeDay(
                          formatChargeDayToIsoMonthDay(
                            chargeDay,
                            chargeMonthRef.current.value
                              ? chargeMonthRef.current.value
                              : '1' // default month
                          )
                        )
                      } else {
                        onChangeChargeDay(chargeDay)
                      }
                    }} />
                </Field>
              )}
          </>
        )}

        {type === SubscriptionType.OneOff && (
          <Field data-testid='charge-date'>
            <Label>{translate('Charge date')}</Label>
            <DatePicker
              format={formatter.getFormat(Formats.GuiDate)}
              required
              value={formatter.parse(Formats.IsoDate, startDate)}
              onChange={compose(onChangeStartDate, formatter.format(Formats.IsoDate))} />
          </Field>
        )}
      </Field.Container>
    </Group>
  )
}
