import { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { extendUIProps } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { workThunks } from '../../../domains/work/thunks'

function hasValidityState (e: any): e is ChangeEvent<HTMLInputElement> {
  return e.target?.validity instanceof ValidityState
}

export const formElementExtensions = extendUIProps({
  Form: (props) => {
    const dispatch = useDispatch()

    return {
      onSubmit: (e) => {
        e.preventDefault()
        props.onSubmit && props.onSubmit(e)
      },
      onInvalid: (e) => {
        e.preventDefault()

        const id = (e.target as HTMLInputElement).id

        const label = id
          ? document
            .querySelector<HTMLLabelElement>(`[for=${CSS.escape(id)}]`)
            ?.innerText
            .toLowerCase() ?? (e.target as HTMLInputElement)?.placeholder.toLowerCase() ?? ''
          : (e.target as HTMLInputElement)?.placeholder.toLowerCase() ?? ''

        if (hasValidityState(e) && e.target.validity.valueMissing && !e.target.validity.badInput) {
          dispatch(workThunks.showErrorMessage(translate('The {field_name} is required.', label)))
          e.target.focus && e.target.focus()
        }

        if (hasValidityState(e) && e.target.validity.rangeOverflow && !e.target.validity.badInput) {
          dispatch(workThunks.showErrorMessage(translate(`The maximum for {field_name} is ${e.target.max}.`, label)))
          e.target.focus && e.target.focus()
        }

        if (hasValidityState(e) && e.target.validity.stepMismatch && !e.target.validity.badInput) {
          dispatch(workThunks.showErrorMessage(translate(`The {field_name} should be divisible by ${e.target.step}.`, label)))
          e.target.focus && e.target.focus()
        }

        if (
          hasValidityState(e)
          && e.target.validity.rangeUnderflow
          && !e.target.validity.badInput
        ) {
          dispatch(workThunks.showErrorMessage(translate(`The minimum for {field_name} is ${e.target.min}.`, label)))
          e.target.focus && e.target.focus()
        }

        if (
          hasValidityState(e)
          && e.target.validity.typeMismatch
          && !e.target.validity.badInput
        ) {
          dispatch(workThunks.showErrorMessage(translate('Invalid format for {field_name}.', label)))
          e.target.focus && e.target.focus()
        }

        props.onInvalid && props.onInvalid(e)
      }
    }
  }
})
