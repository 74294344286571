var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { themeGlobal } from '../Theme';
import { Adornment } from '../Adornment';
import { Icon } from '../Icon';
import { Input } from './Input';
var StyledSelectInput = styled(Input)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return !props.disabled && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    cursor: pointer;\n    /**\n     * These two rules are to hide the caret without having to make the SelectInput readonly.\n     * This handy because readonly inputs ignore the required attribute and we want to use\n     * the required functionality to tell a form that the Select does not have a value yet.\n     */\n    color: transparent;\n    text-shadow: 0 0 0 ", ";\n    \n    box-shadow: none;\n  "], ["\n    cursor: pointer;\n    /**\n     * These two rules are to hide the caret without having to make the SelectInput readonly.\n     * This handy because readonly inputs ignore the required attribute and we want to use\n     * the required functionality to tell a form that the Select does not have a value yet.\n     */\n    color: transparent;\n    text-shadow: 0 0 0 ", ";\n    \n    box-shadow: none;\n  "])), themeGlobal('textColor')); });
export var SelectInput = function (props) { return (_jsx(Adornment, __assign({ className: 'selectInput__adornment', right: _jsx(Icon, { children: _jsx(MdKeyboardArrowDown, {}, void 0) }, void 0) }, { children: _jsx(StyledSelectInput, __assign({ "data-testid": 'select-input', 
        // To prevent auto complete that we dont want for selects.
        autoComplete: 'off' }, props), void 0) }), void 0)); };
var templateObject_1, templateObject_2;
