import React from 'react'
import { Button, Placeholder, Layout } from '@exivity/ui'
import { translate } from '@exivity/translations'

import AdvancedSelect, {
  Column as AdvancedSelectColumn
} from '../../../../components/molecules/AdvancedSelect/index.js'
import { listItems } from '../../../../utils/array'

import MetadataView from './MetadataView'

const NEW = 'new'

interface MetadataProps {
  isLoading: boolean
  definitions: any
  params: { id?: string }
  router: any
}

export function Metadata ({ definitions, params, router }: MetadataProps) {
  const isNew = params.id === NEW

  const selectDefinition = (id: string) => {
    router.push(`data-pipelines/metadata/${id}`)
  }

  const createNew = () => selectDefinition(NEW)

  const renderToolbar = () => (
    <Button small success data-test='create-metadata' onClick={createNew}>
      {translate('Create metadata')}
    </Button>
  )

  return (
    <Layout.MasterDetail>
      <AdvancedSelect
        expandHeight
        data={listItems(definitions)}
        toolbar={renderToolbar()}
        selected={isNew ? undefined : params.id}
        sortBy='name'
        onClick={(item: any) => selectDefinition(item.key)}>
        <AdvancedSelectColumn field='name' label='Name' width={300} />
      </AdvancedSelect>
      {params.id !== undefined
        ? <MetadataView key={params.id} definitionId={isNew ? undefined : params.id} />
        : <Placeholder>{translate('Select metadata')}</Placeholder>
      }
    </Layout.MasterDetail>
  )
}
