import React from 'react'

type Callback = (child: any) => React.ReactNode

export const mapRecursive = (
  children: React.ReactNode[],
  callback: Callback
): React.ReactNode[] => {
  return React.Children.map<React.ReactNode, any>(
    children,
    (child: any) => {
      if (child && child.props && child.props.children
        && typeof child.props.children !== 'function') {
        const newChild: any = callback(child)
        return React.cloneElement(newChild, {
          ...child.props,
          children: mapRecursive(child.props.children, callback)
        }
        )
      }

      return callback(child)
    }
  )
}
