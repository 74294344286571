import { PartialResources } from '@exivity/data-layer'

import { post } from '../../API'
import { workThunks } from '../work/thunks'
import { warn } from '../../utils/log'
import { createAsyncThunk } from '../../store/utils'

type Payload = {
  workflow: PartialResources['workflow']
  date: string
}

const runWorkflow = createAsyncThunk(
  'runWorkflow',
  ({ workflow, date }: Payload,
    {
      extra: { translate },
      dispatch
    }) => {
    post(
      `/workflows/${workflow.id}/run`,
      { date: date },
      {},
      {
        background: true,
        workStatusMessage: translate('Running {name}', workflow.attributes.name || ''),
        handleRejections: false,
        flashMessage: false
      }
    )
      .then(() => dispatch(workThunks.showSuccessMessage(translate('Workflow {name} is executing.', workflow.attributes.name || ''))))
      .catch(error => {
        warn(error)
        dispatch(workThunks.showErrorMessage(translate('Running workflow failed.')))
      })
  }
)

export const workflowThunks = {
  runWorkflow
}
