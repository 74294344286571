import Papa from 'papaparse'

export const fromDoubleArraytoBlob = table => (
  new Blob([Papa.unparse(table, { quotes: true })], { type: 'text/plain' })
)

export const fromTextToDoubleArray = text => (
  Papa.parse(text, {
    header: false,
    skipEmptyLines: false,
    delimiter: (data) => data.split('\n').some(row => row.length > 2) ? ',' : ''
  })
)
