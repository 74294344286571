import { curry, compose, unless, equals } from '@exivity/fp';
import { useNavigate } from '../useNavigate';
import { useLocation } from '../useLocation';
import { getPathname } from '../helpers';
export function useRedirectRoute() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (to, referrer) => compose(unless(equals(pathname), x => navigate(x, { replace: true, state: { referrer } })), getPathname)(to);
}
export function useRedirect() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (to, referrer) => unless(equals(pathname), x => navigate(x, { replace: true, state: { referrer } }))(to);
}
export function useRedirectFrom() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return curry((from, to) => {
        if (equals(pathname, from)) {
            navigate(to, { replace: true });
        }
    });
}
