var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import Color from 'color';
export var hexOpacity = function (hex, opacity) {
    var num = Math.floor(opacity * 255);
    return "" + hex + num.toString(16);
};
// return 9 color palette given a color (100 - 900 as keys)
export var getPalette = function (colorNumber) {
    var darkColors = getColorsList(4, 50, 'black', 0, 14, colorNumber);
    var lightColors = getColorsList(4, 80, 'white', 0, 20, colorNumber).reverse();
    var midColor = numberToHex(colorNumber);
    var colors = __spreadArray(__spreadArray(__spreadArray([], lightColors), [midColor]), darkColors);
    var palette = colors.map(function (color, index) {
        var i = (index + 1) * 100;
        return [i, color];
    });
    return Object.fromEntries(palette);
};
export var getColorsList = function (colorsAmount, colorsShiftAmount, mixColor, rotate, saturation, mainColor) {
    var givenColor = isValidHex(numberToHex(mainColor)) ? numberToHex(mainColor) : errorColor;
    return Array(colorsAmount).fill(0).map(function (_, index) { return Color(givenColor)
        .rotate((index + 1) / colorsAmount * -rotate)
        .saturate((index + 1) / colorsAmount * (saturation / 100))
        .mix(Color(mixColor), (colorsShiftAmount / 100) * (index + 1) / colorsAmount)
        .hex(); });
};
export var numberToHex = function (number) { return '#' + number; };
export var hexToNumber = function (number) { return number.substr(1, number.length); };
export var errorColor = 'transparent';
export var isValidHex = function (color) {
    if (color.substring(0, 1) === '#')
        color = color.substring(1);
    switch (color.length) {
        case 3: return /^[0-9A-F]{3}$/i.test(color);
        case 6: return /^[0-9A-F]{6}$/i.test(color);
        case 8: return /^[0-9A-F]{8}$/i.test(color);
        default: return false;
    }
};
