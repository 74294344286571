import React from 'react'

import { classNames } from '../../../utils/misc'

interface RowProps {
  className?: string
  noBorder?: boolean
}

export const Row: React.FC<RowProps> = ({ children, className, noBorder }) => (
  <tr className={classNames({
    'ex-expandable-table__row--noborder': noBorder
  },
  // @ts-ignore
  className)}>{children}
  </tr>
)

Row.displayName = 'Row'

export default Row
