import React from 'react'
import { Icon } from '@exivity/ui'
import { Resources, enums } from '@exivity/data-layer'
import { MdCheck, MdSchedule, MdWarning } from 'react-icons/md'

const { WorkflowRunStatus, WorkflowStepLogStatus } = enums

export function renderRunStatus (
  resource: Resources['workflowrun'] | Resources['workflowsteplog']
) {
  switch (resource.attributes.status) {
    case WorkflowRunStatus.Failed:
    case WorkflowRunStatus.InternalError:
    case WorkflowRunStatus.Invalid:
    case WorkflowRunStatus.TimedOut:
    case WorkflowStepLogStatus.Failed:
    case WorkflowStepLogStatus.InternalError:
    case WorkflowStepLogStatus.Invalid:
    case WorkflowStepLogStatus.TimedOut:
      return <Icon danger><MdWarning /></Icon>
    case WorkflowRunStatus.Started:
    case WorkflowStepLogStatus.Started:
      return <Icon primary><MdSchedule /></Icon>
    case WorkflowRunStatus.Success:
    case WorkflowStepLogStatus.Success:
      return <Icon success><MdCheck /></Icon>
    default:
      console.warn('Invalid status')
  }
}
