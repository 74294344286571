import React from 'react'
import { Button, ButtonProps, Icon } from '@exivity/ui'
import { MdAdd, MdCheck, MdDelete } from 'react-icons/md'

export const Create = (props: ButtonProps) => (
  <Button success {...props}><Icon><MdAdd /></Icon>create</Button>
)

export const Update = (props: ButtonProps) => (
  <Button primary {...props}><Icon><MdCheck /></Icon>update</Button>
)

export const Remove = (props: ButtonProps) => (
  <span style={{ marginRight: 10 }}>
    <Button danger {...props}><Icon><MdDelete /></Icon>delete</Button>
  </span>
)
