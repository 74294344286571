var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import isEqual from 'lodash/isEqual';
import { ListItem } from '../../ListItem';
import { useSearchTerm } from '../../SearchableList';
export function DefaultItem(_a) {
    var data = _a.data, index = _a.index, style = _a.style;
    var items = data.items, value = data.value, onChange = data.onChange, labelAccessor = data.labelAccessor, valueAccessor = data.valueAccessor, noDataText = data.noDataText;
    var searchTerm = useSearchTerm();
    var item = items[index];
    var active = typeof item === 'object'
        ? isEqual(valueAccessor(item), value)
        : item === value;
    var label = labelAccessor(item);
    var hasData = label !== noDataText;
    var handleOnClick = useCallback(function (e) {
        if (onChange && hasData) {
            var value_1 = typeof item === 'object'
                ? valueAccessor(item)
                : item;
            onChange(value_1, e);
        }
    }, [hasData, valueAccessor, onChange, item]);
    return (_jsx(ListItem, __assign({ role: hasData ? 'listitem' : '', style: style, tabIndex: index + 1, active: active, noDataPlaceholder: !hasData, onClick: handleOnClick }, { children: _jsx(Highlighter, { autoEscape: true, searchWords: [searchTerm], textToHighlight: label }, void 0) }), void 0));
}
