import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'

import { WorkflowStatusWidget } from './Widget'

const mapRecordsToProps = () => ({
  workflows: (q: QueryBuilder) => q.findRecords('workflow')
})

const WithData = withData(mapRecordsToProps)(WorkflowStatusWidget)

export default WithData
