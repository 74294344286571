import React from 'react'
import { useSelector } from 'react-redux'
import { AppBar as AppBarUI, renderIcon } from '@exivity/ui'
import { prop } from '@exivity/fp'
import { useNavigate, useRoute } from '@exivity/routing'

import { workSelectors } from '../../work/state'
import { useApplicationSearch } from '../useApplicationSearch'

import { usePageTitle } from './usePageTitle'
import { useIcon } from './useIcon'
import { useLogo } from './useLogo'
import { AdministrationMenu } from './AdministrationMenu'
import { UserMenu } from './UserMenu'
import { DocumentationLink } from './DocumentationLink'

export function AppBar () {
  const workMessage = useSelector(workSelectors.getUserMessage)
  const progress = useSelector(workSelectors.getForegroundProgress)
  const isLoading = useSelector(workSelectors.isForegroundBusy)
  const AppIcon = useIcon()
  const AppLogo = useLogo()
  const appSearchData = useApplicationSearch()
  const navigate = useNavigate()
  const title = usePageTitle()
  const { exactRoute } = useRoute()

  return (
    <AppBarUI
      message={workMessage}
      icon={<AppIcon />}
      logo={<AppLogo />}
      title={title}
      isLoading={isLoading}
      progress={progress}>
      {exactRoute.requiresAuth
      && (
        <>
          <AppBarUI.Search
            labelAccessor={prop('title')}
            renderIcon={renderIcon.badge(prop('type'))}
            data={appSearchData}
            onChange={({ url }) => navigate(url)} />
          <DocumentationLink />
          <AdministrationMenu />
          <UserMenu />
        </>
      )}
    </AppBarUI>
  )
}
