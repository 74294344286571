import { useMemo } from 'react';
import { useStyledTheme } from '../hooks';
export function useSizeProp(_a) {
    var tiny = _a.tiny, small = _a.small, large = _a.large, huge = _a.huge;
    var sizesProps = { tiny: tiny, small: small, large: large, huge: huge };
    var sizes = useStyledTheme(function (theme) { return theme.global.sizes; });
    return useMemo(function () {
        var selectedSizes = Object.keys(sizes)
            .filter(function (size) { return sizesProps[size]; });
        if (selectedSizes.length > 1) {
            throw new Error('More than one size prop is provided. Please provide only one.');
        }
        return selectedSizes[0];
    }, [tiny, small, large, huge]);
}
