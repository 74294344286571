import { useCallback } from 'react';
import { omit } from 'ramda';
import { useDataStore } from '../useDataStore';
import { sortRecords } from './sortRecords';
const omitSort = omit(['sort']);
const array = [];
export const useCacheQueryFn = () => {
    const store = useDataStore();
    return useCallback((queryModifier) => {
        const queryExpression = queryModifier.toQuery();
        try {
            const result = store.cache.query(omitSort(queryExpression));
            return (Array.isArray(result)
                ? sortRecords(result, queryExpression.sort)
                : result);
        }
        catch (_a) {
            // When query fails return array for collections and null for record
            return (queryExpression.op === 'findRecords'
                || queryExpression.op === 'findRelatedRecords'
                ? array
                : null);
        }
    }, [store]);
};
