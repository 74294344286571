import React, { DetailedHTMLProps, TdHTMLAttributes } from 'react'

type CellAttr = DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableDataCellElement>

type CellType = Omit<CellAttr, 'defaultValue'>

interface CellProps extends CellType {
  index?: boolean
  className?: string
}

export const Cell = ({ children, className, ...rest }: CellProps) => (
  <td className={className} {...rest}>{children}</td>
)

Cell.displayName = 'Cell'

export default Cell
