var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';
import { Text } from '../Text';
import { themeColor, themeSpace } from '../Theme';
var StyledLoader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  \n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  \n  svg {\n    margin-bottom: ", ";\n  }\n  \n  span {\n    color: ", ";\n  }\n"], ["\n  height: 100%;\n  width: 100%;\n  \n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  \n  svg {\n    margin-bottom: ", ";\n  }\n  \n  span {\n    color: ", ";\n  }\n"])), themeSpace(3), themeColor('gray'));
export function Loader(_a) {
    var timeout = _a.timeout, _b = _a.timeoutMessage, timeoutMessage = _b === void 0 ? '' : _b, className = _a.className, _c = _a.children, children = _c === void 0 ? 'Loading...' : _c;
    var _d = useState(false), isTimedOut = _d[0], setIsTimedOut = _d[1];
    useEffect(function () {
        var clock;
        if (typeof timeout === 'number') {
            clock = setTimeout(function () {
                setIsTimedOut(true);
            }, timeout);
        }
        return function () {
            if (clock) {
                clearTimeout(clock);
            }
        };
    }, [timeout]);
    return (_jsxs(StyledLoader, __assign({ className: className }, { children: [_jsx(Spinner, {}, void 0), _jsx(Text, { children: isTimedOut
                    ? timeoutMessage
                    : children }, void 0)] }), void 0));
}
var templateObject_1;
