import { map } from 'ramda';
import { useDataStore } from './useDataStore';
import { useCacheQueryFn } from './useCacheQuery/useCacheQueryFn';
import { queries } from './queries';
export const useResetCache = () => {
    const memory = useDataStore();
    const queryFn = useCacheQueryFn();
    return (type) => {
        type
            ? memory.update((t) => map(t.removeRecord, queryFn(queries.findAll(type))))
            : memory.cache.reset();
    };
};
