import { createRoutes } from '@exivity/routing'

import { Shell } from '../domains/shell/components/Shell'

import { login, logout, resetPassword } from './auth'
import { forbidden } from './forbidden'
import { home } from './home'
import { reports } from './reports'
import { services } from './services'
import { accounts } from './accounts'
import { datapipelines } from './datapipelines'
import { administration } from './administration'
import { notfound } from './notfound'
import { profile } from './myProfile'
import { myNotifications } from './myNotifications'
import { about } from './about'

export const app = createRoutes({
  path: '/',
  component: Shell,
  requiresAuth: false,
  routes: {
    // unprotected
    login,
    logout,
    resetPassword,

    // protected
    home,
    reports,
    services,
    accounts,
    datapipelines,
    administration,
    profile,
    myNotifications,
    about,
    forbidden,
    notfound
  }
})
