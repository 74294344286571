var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { lt, set, init, curry, append, compose, lensPath, intersperse, reduceWhile, lensProp, length, reduce, chain, isNil, over, view, not } from '@exivity/fp';
export var createListNode = function (path, getChildNodes, data) { return ({
    data: data,
    match: false,
    childMatch: false,
    path: path,
    expand: false,
    children: getChildNodes(data)
        .map(function (item, index) { return createListNode(append(index, path), getChildNodes, item); })
}); };
export var createNodeList = curry(function (getChildNodes, data) { return (data.map(function (item, index) { return createListNode([index], getChildNodes, item); })); });
var mapNodeList = curry(function (fn, list) {
    return list.map(function (node) {
        var item = fn(node);
        return __assign(__assign({}, item), { children: mapNodeList(fn, node.children) });
    });
});
export var expandAll = mapNodeList(set(lensProp('expand'), true));
export var intersperseChildren = intersperse('children');
var nodeDataLens = compose(lensPath, append('data'), intersperseChildren);
var nodeMatchLens = compose(lensPath, append('match'), intersperseChildren);
var nodeChildMatchLens = compose(lensPath, append('childMatch'), intersperseChildren);
var nodeExpandLens = compose(lensPath, append('expand'), intersperseChildren);
export var hasParent = compose(lt(1), length);
var setNodeChildIsAMatch = function (path, node) { return set(nodeChildMatchLens(path), true, node); };
var setNodeIsAMatch = function (path, node) { return set(nodeMatchLens(path), true, node); };
export var accumulateFromChildren = curry(function (fn, node) { return (append(fn(node), chain(accumulateFromChildren(fn), node.children || []))); });
/**
 * @param predicate
 * @param nodes
 *
 * Returns the paths of all the nodes that matched the predicate
 */
export var getSearchedPaths = function (predicate, nodes) {
    var findPaths = function (nodes) { return (chain(function (node) { return predicate(node.data)
        ? append(node.path, findPaths(node.children))
        : findPaths(node.children); }, nodes)); };
    return findPaths(nodes);
};
export var updateChildIsMatch = curry(function (path, nodes) {
    var updatePaths = function (newNodes, path) { return (hasParent(path)
        ? updatePaths(setNodeChildIsAMatch(path, newNodes), init(path))
        : setNodeChildIsAMatch(path, newNodes)); };
    return updatePaths(nodes, path);
});
export var updateNodeMatch = curry(function (predicate, nodes) {
    var updatePaths = function (newNodes, path) {
        if (hasParent(path)) {
            return setNodeIsAMatch(path, updateChildIsMatch(init(path), newNodes));
        }
        return setNodeIsAMatch(path, newNodes);
    };
    return reduce(updatePaths, nodes, getSearchedPaths(predicate, nodes));
});
var includeChildren = function (node) { return [node]
    .flatMap(function (x) { return __spreadArray([
    x
], node.children.flatMap(includeChildren)); }); };
var includeExpandedChildren = function (node) { return [node]
    .flatMap(function (x) { return __spreadArray([
    x
], node.expand
    ? node.children.flatMap(includeExpandedChildren)
    : []); }); };
var includeMatchedNodes = function (node) { return [node]
    .flatMap(function (x) { return node.match
    ? __spreadArray([x], node.children.flatMap(includeChildren)) : node.childMatch
    ? __spreadArray([x], node.children.flatMap(includeMatchedNodes)) : []; }); };
export var getExpandedList = function (nodes) { return nodes.flatMap(includeExpandedChildren); };
export var getSearchedList = function (nodes) { return nodes.flatMap(includeMatchedNodes); };
export var findActiveNode = curry(function (getChildNodes, predicate, nodes) {
    var find = function (result, node) { return predicate(node)
        ? node
        : reduceWhile(isNil, find, undefined, getChildNodes(node)); };
    return reduceWhile(isNil, find, undefined, nodes);
});
export var toggleNodeExpand = curry(function (path, nodes) { return over(nodeExpandLens(path), not, nodes); });
export var someParents = curry(function (predicate, nodes, path) {
    return hasParent(path) && predicate(view(nodeDataLens(init(path)), nodes))
        ? true
        : hasParent(path)
            ? someParents(predicate, nodes, init(path))
            : false;
});
