var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Link } from '@exivity/routing';
import { injectIf } from '../utils';
import { themeColor, themeSpace, themePurpose } from '../Theme';
import { NavIcon } from './NavIcon';
import { Title } from './Title';
var sharedLinkStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  text-transform: uppercase;\n  text-decoration: none;\n"], ["\n  display: flex;\n  align-items: center;\n  text-transform: uppercase;\n  text-decoration: none;\n"])));
var dontForwardActive = {
    shouldForwardProp: function (prop) { return prop !== 'active'; }
};
var BaseLink = styled(Link).withConfig(dontForwardActive);
var StyledLink = BaseLink(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  height: 64px;\n  padding: 0 ", ";\n  color: ", ";\n  \n  &:hover {\n    text-decoration: none;\n    background-color: ", ";\n    color: ", ";\n  }\n  \n   ", "\n"], ["\n  ", ";\n  height: 64px;\n  padding: 0 ", ";\n  color: ", ";\n  \n  &:hover {\n    text-decoration: none;\n    background-color: ", ";\n    color: ", ";\n  }\n  \n   ", "\n"])), sharedLinkStyle, themeSpace(2), themeColor('white'), themeColor('lightDark'), themeColor('white'), injectIf('active', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: ", ";\n      ", " {\n        color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      ", " {\n        color: ", ";\n      }\n    "])), themeColor('lightDark'), NavIcon, themePurpose('brand'))));
var StyledSubLink = BaseLink(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  padding: 0 45px;\n  height: 29px;\n  color: ", ";\n\n  ", " {\n    font-size: .8125rem;\n    \n    ", "\n  }\n  \n  &:hover {\n    text-decoration: underline;\n    background-color: initial;\n    color: ", ";\n    \n    ", " {\n      color: ", ";\n    }\n  }\n"], ["\n  ", ";\n  padding: 0 45px;\n  height: 29px;\n  color: ", ";\n\n  ", " {\n    font-size: .8125rem;\n    \n    ", "\n  }\n  \n  &:hover {\n    text-decoration: underline;\n    background-color: initial;\n    color: ", ";\n    \n    ", " {\n      color: ", ";\n    }\n  }\n"])), sharedLinkStyle, themeColor('gray'), Title, injectIf('active', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        text-decoration: underline;\n        color: ", ";\n      "], ["\n        text-decoration: underline;\n        color: ", ";\n      "])), themeColor('white'))), themeColor('white'), Title, themeColor('white'));
export function NavLink(_a) {
    var className = _a.className, _b = _a.to, to = _b === void 0 ? '' : _b, icon = _a.icon, _c = _a.active, active = _c === void 0 ? false : _c, isSubLink = _a.isSubLink, _d = _a.onClick, onClick = _d === void 0 ? function () { } : _d, children = _a.children;
    var Link = isSubLink
        ? StyledSubLink
        : StyledLink;
    return (_jsx("li", __assign({ tabIndex: -1, onClick: onClick }, { children: _jsxs(Link, __assign({ className: className, to: to, active: active }, { children: [_jsx(NavIcon, { children: icon }, void 0), _jsx(Title, { children: children }, void 0)] }), void 0) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
