import { pick } from 'ramda'

import { createSelector, createSelectors } from '../../../../store/utils'
import staticConfig from '../../../../application/config'
import { ReportFormatterOptions } from '../../../../utils/formatters/ReportFormatter'

import { PUBLIC_CONFIG_KEYS, SsoLoginMethod } from './utils'

const config = createSelector((state) => state.configuration)

export const curriedSelectors = {
  makeUrl: (endpoint: string) => config(config => (
    `${config.API_ROOT}/v${staticConfig.app.apiVersion}${endpoint}`
  ))
}

export const selectors = createSelectors(
  (state) => state.configuration,
  {
    isHydrated: config => config.hydrated,

    getAppName: config => config.APP_NAME || '',
    getAppLogo: config => config.APP_LOGO,
    getAppIcon: config => config.APP_ICON,
    getAppFavicon: config => config.APP_FAVICON,
    getAppColour: config => config.APP_COLOUR || '',
    shouldShowDocumentation: config => !!(config.APP_DOCUMENTATION && !config.APP_WHITE_LABEL),
    getAppLanguage: config => config.APP_LANGUAGE || 'en',
    isWhiteLabeled: config => config.APP_WHITE_LABEL ?? false,
    getDateFormat: config => config.DATE_FORMAT || 'dd-MM-yyyy',
    getAppCss: config => config.APP_CSS,

    isAnalyticsEnabled: config => config.ANALYTICS,
    getExtraAnalyticsProperty: config => config.ANALYTICS_EXTRA_PROPERTY,
    isErrorTrackingEnabled: config => config.ERROR_TRACKING,
    isBetaEnabled: config => process.env.NODE_ENV === 'test' || (config.BETA_FEATURES ?? false),
    isDebugMode: config => config.APP_DEBUG ?? false,

    getCurrency: config => config.CURRENCY,
    getCurrencyFormat: config => config.CURRENCY_FORMAT,
    getDecimalSeparator: config => config.DECIMAL_SEPARATOR,
    getThousandsSeparator: config => config.THOUSAND_SEPARATOR,
    getCSVDelimiter: config => config.CSV_DELIMITER,
    getCSVDecimalSeparator: config => config.CSV_DECIMAL_SEPARATOR,

    getReportFormatterOptions: (config): ReportFormatterOptions => ({
      precisions: {
        quantity: config.QUANTITY_PRECISION ?? undefined,
        rate: config.RATE_PRECISION ?? undefined,
        report: config.REPORT_PRECISION ?? undefined,
        summary: config.SUMMARY_PRECISION ?? undefined,
        percentage: config.PERCENTAGE_PRECISION ?? undefined
      },
      symbols: {
        decimal: config.DECIMAL_SEPARATOR ?? undefined,
        thousands: config.THOUSAND_SEPARATOR ?? undefined,
        currency: config.CURRENCY_FORMAT || ''
      }
    }),

    getSummaryConfig: config => ({
      title: config.SUMMARY_TITLE,
      address: config.SUMMARY_ADDRESS,
      image: config.SUMMARY_IMAGE,
      extra: config.SUMMARY_EXTRA,
      minCommitMessage: config.SUMMARY_MIN_COMMIT
    }),

    getMinimumCommitMessage: config => config.SUMMARY_MIN_COMMIT,

    getSummaryExtra: config => config.SUMMARY_EXTRA,

    // @todo refactor fiscal offset to be 0 - 11 instead of 1 - 12 and subtracting one
    getFiscalOffset: config => (config.REPORT_START_MONTH ?? 1) - 1,

    loginMethodIsSAMLOnly: config => config.SSO_LOGIN_METHOD === SsoLoginMethod.SamlOnly,
    isSamlEnabled: config => (
      config.SSO_LOGIN_METHOD === SsoLoginMethod.LocalOrSaml
      || config.SSO_LOGIN_METHOD === SsoLoginMethod.SamlOnly
      || config.SSO_LOGIN_METHOD === SsoLoginMethod.LocalOrLdapOrSaml
    ),
    isLocalLoginEnabled: config => (
      config.SSO_LOGIN_METHOD === SsoLoginMethod.LocalOrSaml
      || config.SSO_LOGIN_METHOD === SsoLoginMethod.LocalOrLdap
      || config.SSO_LOGIN_METHOD === SsoLoginMethod.LocalOnly
      || config.SSO_LOGIN_METHOD === SsoLoginMethod.LocalOrLdapOrSaml
    ),
    getApiRoot: config => config.API_ROOT,
    getTokenTTL: config => config.TOKEN_TTL,
    arePersistentTokensAllowed: config => config.ALLOW_PERSISTENT_TOKENS ?? false,

    getImportWorkerConfiguration: (config) => {
      return {
        debugMode: config.APP_DEBUG ?? false,
        apiRoot: config.API_ROOT
      }
    },
    getPublicConfiguration: (configuration) => {
      return pick(PUBLIC_CONFIG_KEYS, configuration)
    },
    isDisclaimerEnabled (configuration) {
      return configuration.DISCLAIMER_ENABLED ?? false
    },
    getDisclaimer (configuration) {
      return {
        title: configuration.DISCLAIMER_TITLE,
        text: configuration.DISCLAIMER_TEXT,
        agreeButtonText: configuration.DISCLAIMER_AGREE_BUTTON_TEXT
      }
    },
    getConfiguration (configuration) {
      return configuration
    },
    getPublicRoot (configuration) {
      return configuration.PUBLIC_ROOT || configuration.API_ROOT
    }
  }
)

export const configurationSelectors = {
  ...selectors,
  ...curriedSelectors
}
