import { useState, useEffect } from 'react';
import { useMediaQuery } from '../useMediaQuery';
import { useEventListener } from '../useEventListener';
export function useIsPrinting(cb) {
    // For some reason mediaQuery print doesnt shoot an event when switching
    // to NOT printing. Therefore we use afterprint listener.
    var printQueryMatch = useMediaQuery('print');
    var _a = useState(printQueryMatch), isPrinting = _a[0], setPrintingStatus = _a[1];
    useEffect(function () {
        setPrintingStatus(function (state) {
            if (state !== printQueryMatch) {
                cb && cb(printQueryMatch);
            }
            return printQueryMatch;
        });
    }, [cb, printQueryMatch]);
    useEventListener('afterprint', function () {
        cb && cb(false);
        setPrintingStatus(false);
    });
    return isPrinting;
}
