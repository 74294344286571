import { useCallback, useRef, useEffect, useState } from 'react';
export function useFileReader(_a) {
    var _b = _a === void 0 ? { method: 'readAsDataURL' } : _a, method = _b.method;
    var _c = useState(0), progress = _c[0], setProgress = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), error = _e[0], setError = _e[1];
    var onResultRef = useRef(function () { return null; });
    var readerRef = useRef(new FileReader());
    useEffect(function () {
        var onProgress = function (_a) {
            var loaded = _a.loaded, total = _a.total;
            setProgress(Math.round((loaded / total) * 100));
        };
        var onLoadStart = function () {
            setLoading(true);
            setProgress(0);
            setError(false);
        };
        var onLoadEnd = function () {
            // @ts-ignore
            onResultRef.current(readerRef.current.result);
            setLoading(false);
        };
        var onError = function () {
            setLoading(false);
            setError(true);
        };
        readerRef.current.addEventListener('progress', onProgress);
        readerRef.current.addEventListener('loadstart', onLoadStart);
        readerRef.current.addEventListener('loadend', onLoadEnd);
        readerRef.current.addEventListener('error', onError);
        return function () {
            readerRef.current.removeEventListener('progress', onProgress);
            readerRef.current.removeEventListener('loadstart', onLoadStart);
            readerRef.current.removeEventListener('loadend', onLoadEnd);
            readerRef.current.removeEventListener('error', onError);
        };
    }, []);
    var onChange = useCallback(function (event) {
        var files = event.target.files;
        if (!files || !files.length)
            return;
        readerRef.current[method](files[0]);
    }, [method]);
    var onChangeFile = useCallback(function (file) {
        readerRef.current[method](file);
    }, [method]);
    var onResult = useCallback(function (fn) {
        onResultRef.current = fn;
    }, []);
    return {
        reader: readerRef.current,
        onChange: onChange,
        onChangeFile: onChangeFile,
        onResult: onResult,
        loading: loading,
        progress: progress,
        error: error
    };
}
