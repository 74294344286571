import React, { useMemo } from 'react'
import { useCrud } from 'react-crud-hook'
import {
  Heading,
  Group,
  Select,
  Field,
  Label,
  Block,
  MetaTable,
  Placeholder,
  Form,
  Button,
  useModal
} from '@exivity/ui'
import { translate } from '@exivity/translations'

import orbitStore from '../../../../data/store'
import { DsetModel, MetadataDefinitionModel } from '../../../../data/types'
import { getRelationship } from '../../../../utils/records'
import { StatusTable } from '../StatusTable'
import { del } from '../../../../API'

import { useRDFStatuses } from './useRDFStatuses'

export const MAX_WIDTH_FIELD = 380
export const MIN_WIDTH_FIELD = 240

export const fetchDset = (id: string) => {
  orbitStore.query(q => q.findRecord({ type: 'dset', id }))
}

function useGetRelatedDefinition (
  dataset: DsetModel,
  metadataDefinitions: MetadataDefinitionModel[]
) {
  return useMemo(() => {
    const relation = getRelationship(dataset, 'metadatadefinition', null)

    return relation
      ? metadataDefinitions.find(({ id }) => id === relation.id)
      : undefined
  }, [dataset, metadataDefinitions])
}

interface DatasetProps {
  dataset: DsetModel
  metadataDefinitions: MetadataDefinitionModel[]
}

export function Dataset ({ dataset, metadataDefinitions }: DatasetProps) {
  const { warning } = useModal()
  const crudDataset = useCrud<DsetModel>(dataset)
  const metadataDefinition = useGetRelatedDefinition(crudDataset, metadataDefinitions)

  const reportsCount = getRelationship(dataset, 'reports', []).length

  const statuses = useRDFStatuses(
    crudDataset.attributes.rdf_detail,
    function deleteRDF (rdf) {
      del(`/dsets/${crudDataset.id}`, { start: rdf.date, end: rdf.date })
      crudDataset.setAttribute(
        'rdf_detail',
        (crudDataset.attributes.rdf_detail || []).filter(item => item.date !== rdf.date)
      )
    }
  )

  const setDefinition = crudDataset.setRelationship('metadatadefinition')

  return (
    <Form className='uk-height-1-1'>

      <Heading>{dataset.id}</Heading>
      <MetaTable>
        <MetaTable.Item
          label={translate('REPORTS')}
          value={`${reportsCount} ${
            reportsCount === 1
              ? translate('report uses this dataset')
              : translate('reports use this dataset')
          }`} />
      </MetaTable>

      <Group title={translate('Settings')}>
        <Field.Container>
          <Field>
            <Label htmlFor='metadata_definition'>
              {translate('Service metadata definition')}
            </Label>
            <Select
              value={metadataDefinition}
              data={metadataDefinitions}
              labelAccessor={(metadataDefinition) => metadataDefinition.attributes.name}
              placeholder={translate('Select a metadata definition')}
              onChange={setDefinition}
              onClear={
                metadataDefinition
                  ? () => setDefinition(null)
                  : undefined
              } />
          </Field>
        </Field.Container>
      </Group>

      <Group title={translate('Statuses')}>

        {statuses.length > 0
          ? (
            <StatusTable
              statuses={statuses}
              onDelete={(status) => (
                warning({ body: `Are you sure you want to delete all data for ${status.day}?` })
                  .then(() => status.delete())
              )} />
          )
          : <Placeholder>{translate('No data')}</Placeholder>}
      </Group>

      <Block height={40} />

      <Form.Toolbar>
        <Button.Delete onClick={() => {
          crudDataset.delete({
            beforeDelete: (record: any, { confirm }: any) => (
              confirm(translate('Are you sure you want to delete this dataset?'))
            ),
            onDelete: (record: any, { successMessage }: any) => {
              successMessage(translate('Dataset deleted.'))
            }
          })
        }} />
        <Button.Update onClick={() => {
          crudDataset.save({
            onUpdate: (_: any, { successMessage }: any) => {
              successMessage(translate('Dataset updated.'))
              fetchDset(crudDataset.id)
            }
          })
        }} />
      </Form.Toolbar>

    </Form>
  )
}
