import React from 'react'
import { Layout, Button, Placeholder } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { NEW_PARAM, useParams } from '@exivity/routing'

import { UserModel } from '../../../../data/types'
import AdvancedSelect, {
  Column as AdvancedSelectColumn
} from '../../../../components/molecules/AdvancedSelect'
import { listUsers } from '../../../../selectors/users'
import { User } from '../../components'
import { usePopulateNotifications } from '../../../myNotifications/pages/MyNotifications'

interface UserProps {
  users: UserModel[]
}

export function Users ({ users }: UserProps) {
  usePopulateNotifications()
  const [{ id }, setParams] = useParams()

  return (
    <Layout.MasterDetail>
      <AdvancedSelect
        expandHeight
        data={listUsers(users)}
        groupBy='usergroup'
        toolbar={
          <Button.Create small onClick={() => setParams({ id: NEW_PARAM, entity: 'profile' })}>
            {translate('Create user')}
          </Button.Create>
        }
        sortBy='name'
        selected={id}
        higlightSearches
        onClick={({ id }: any) => setParams({ id, entity: 'profile' })}>
        <AdvancedSelectColumn
          field='username'
          label={translate('Username')}
          width={300}
          searchable />
        <AdvancedSelectColumn
          field='source'
          label={translate('Source')}
          width={100} />
      </AdvancedSelect>
      {id
        ? <User id={id !== NEW_PARAM ? id : undefined} />
        : <Placeholder>{translate('Select a user')}</Placeholder>
      }
    </Layout.MasterDetail>
  )
}
