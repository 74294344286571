import { insertIf } from '../../../utils/misc'
import { NOT_AVAILABLE_CHARACTER } from '../.'
import auth from '../../../utils/auth'
import { UserGroupPermission } from '../../../data/types'

export default (report, includeCogsAndNet) => {
  if (!report) {
    return []
  }

  // For testing purposes in if statement
  if (includeCogsAndNet === undefined) {
    includeCogsAndNet = auth.can(UserGroupPermission.ViewCogs)
  }

  const instances = report.reduce((record, next) => {
    const key = next.service_id + '-' + next.instance_value
    let perUnitCharges, perIntervalCharges
    let avgUnitBasedRate
    // let avgIntervalBasedRate

    switch (next.service_charge_type) {
      case 'manual':
      case 'automatic':
      case 'manual_tiered_standard':
      case 'manual_tiered_inherited':
        if (record[key]) {
          perUnitCharges = record[key].perUnitCharges + next.unit_based_subtotal_charge
          perIntervalCharges = record[key].perIntervalCharges + next.interval_based_subtotal_charge
          avgUnitBasedRate = next.avg_unit_based_rate
          // avgIntervalBasedRate = calcAvg(
          //   record[key].numberOfProcessedDays,
          //   record[key].avgIntervalBasedRate,
          //   next.avg_interval_based_rate,
          // )
        } else {
          avgUnitBasedRate = next.avg_unit_based_rate
          // avgIntervalBasedRate = next.avg_interval_based_rate
          perUnitCharges = next.unit_based_subtotal_charge
          perIntervalCharges = next.interval_based_subtotal_charge
        }
        break

      case 'unit':
        if (record[key]) {
          perUnitCharges = record[key].perUnitCharges + next.unit_based_subtotal_charge
          avgUnitBasedRate = next.avg_unit_based_rate
        } else {
          perUnitCharges = next.unit_based_subtotal_charge
          avgUnitBasedRate = next.avg_unit_based_rate
        }

        perIntervalCharges = NOT_AVAILABLE_CHARACTER
        // avgIntervalBasedRate = NOT_AVAILABLE_CHARACTER
        break

      case 'interval':
        if (record[key]) {
          perIntervalCharges = record[key].perIntervalCharges + next.interval_based_subtotal_charge
          // avgIntervalBasedRate = calcAvg(
          //   record[key].numberOfProcessedDays,
          //   record[key].avgIntervalBasedRate,
          //   next.avg_interval_based_rate,
          // )
        } else {
          perIntervalCharges = next.interval_based_subtotal_charge
          // avgIntervalBasedRate = next.avg_interval_based_rate
        }

        perUnitCharges = NOT_AVAILABLE_CHARACTER
        avgUnitBasedRate = NOT_AVAILABLE_CHARACTER

        break

      case 'none':
        perUnitCharges = NOT_AVAILABLE_CHARACTER
        perIntervalCharges = NOT_AVAILABLE_CHARACTER
        avgUnitBasedRate = NOT_AVAILABLE_CHARACTER
        // avgIntervalBasedRate = NOT_AVAILABLE_CHARACTER
        break

      default:
        break
    }

    if (record[key]) {
      if (includeCogsAndNet) {
        record[key].cogs = record[key].cogs + next.total_cogs
        record[key].net = record[key].net + next.total_net
      }
      record[key].quantity = record[key].quantity + next.total_quantity
      record[key].charge = record[key].charge + next.total_charge
      record[key].perUnitCharges = perUnitCharges
      record[key].perIntervalCharges = perIntervalCharges
      // sum(charge) / sum(quantity)
      record[key].avgUnitBasedRate = record[key].charge / record[key].quantity
      record[key].numberOfProcessedDays++
      return record
    }

    const cogsAndNet = {
      cogs: next.total_cogs,
      net: next.total_net
    }

    record[key] = {
      id: next.originalValue || next.instance_value,
      key: next.key,
      type: 'instance',
      service: next.service_description,
      chargeType: next.service_charge_type,
      cogsType: next.service_cogs_type,
      prorationType: next.proration_type,
      interval: next.interval,
      instance: next.instance_value,
      quantity: next.total_quantity,
      unit: next.service_unit_label,
      charge: next.total_charge,
      perUnitCharges,
      perIntervalCharges,
      avgUnitBasedRate,
      // avgIntervalBasedRate,
      numberOfProcessedDays: 1,
      usageCol: next.breakdown,
      ...insertIf(includeCogsAndNet, cogsAndNet)
    }

    return record
  }, {})

  // Calculate rateAvg and then return
  return Object.values(instances)
}
