import { RootState } from '../reducers'
import store from '../store'
import { timeout as ms, waitFor } from '../utils/misc'
import { workSelectors } from '../domains/work/state'

const workFinished = () => {
  const state = store.getState()
  return workSelectors.isAnyChannelBusy(state)
}

const networkIdle = async (waitBefore = 100, waitAfter = 100, timeout = 60000) => {
  await ms(waitBefore)
  await waitFor(() => workFinished(), { timeout, description: 'network idle' })
  return ms(waitAfter)
}

const state = (callback: (state: RootState) => unknown | unknown[], timeout = 60000) => {
  return waitFor(() => {
    const state = store.getState()
    let stateValues

    try {
      stateValues = callback(state)
    } catch (err) {
      return false
    }

    if (!stateValues) {
      return false
    }

    if (!Array.isArray(stateValues)) {
      stateValues = [stateValues]
    }

    return stateValues.every(item => !!item)
  }, { timeout, description: `state callback: ${callback.toString()}` })
}

const selector = (selector: string, timeout = 60000) => {
  return waitFor(
    () => document.querySelector(selector),
    { timeout, description: `selector: ${selector}` }
  )
}

export default {
  // Public
  ms,
  networkIdle,

  // Private
  state,
  selector
}
