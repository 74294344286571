import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { configurationSelectors } from '../../../../domains/administration/state/configuration'
import { SsoRequest } from '../../components/LoginForm/SsoRequest'
import { useAppState } from '../../components/useAppState'
import { useDomainActions } from '../../components/useDomainActions'
import { authThunks } from '../../state'

interface SsoProps {
  loginWithPasswordButton: React.ReactElement|null
}

export function Sso ({ loginWithPasswordButton }: SsoProps) {
  const dispatch = useDispatch()
  const { apiRoot, isLoading } = useAppState()
  const loginMethodIsSAMLOnly = useSelector(configurationSelectors.loginMethodIsSAMLOnly)
  const { onDomainChanged, updateDomain } = useDomainActions()

  const onLoginAttempt = useCallback((apiRoot: string) => {
    updateDomain(apiRoot)
    dispatch(authThunks.loginSAML())
  }, [])

  return (
    <SsoRequest apiRoot={apiRoot}
      loading={isLoading}
      samlOnly={loginMethodIsSAMLOnly}
      loginWithPasswordButton={loginWithPasswordButton}
      onSubmit={onLoginAttempt}
      onDomainChanged={onDomainChanged} />
  )
}
