import React, { useCallback, useState, useEffect } from 'react'
import { Form, Heading, Button, useModal } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useCrud } from 'react-crud-hook'
import { Resources, ServiceSubscriptionAltInterval } from '@exivity/data-layer'

import {
  SubscriptionModel,
  ServiceModel,
  createSubscription,
  ServiceInterval,
  SubscriptionType,
  ServiceCategoryModel
} from '../../../../data/types'
import { reportsThunks, PreparableResource } from '../../../reports/state'

import { ServiceGroup, IntervalGroup, UsageGroup } from './groups'

const defaultSubscription = createSubscription()

interface SubscriptionProps {
  subscription?: SubscriptionModel
  service?: ServiceModel
  account: Resources['account']
  serviceCategory?: ServiceCategoryModel
}

function convertToString (chargeDay: number | null) {
  return chargeDay ? String(chargeDay) : null
}

export function Subscription ({
  subscription = defaultSubscription,
  account,
  service,
  serviceCategory
}: SubscriptionProps) {
  const { warning } = useModal()

  const crudSubscription = useCrud<SubscriptionModel>(subscription)
  const [selectedServiceCategory, setServiceCategory] = useState(serviceCategory || null)
  const [selectedService, setService] = useState(service || null)

  useEffect(() => {
    setServiceCategory(serviceCategory || null)
    setService(service || null)
  }, [subscription, service, serviceCategory])

  const onSave = useCallback(({ prepare = false }) => {
    if (!crudSubscription.id) {
      crudSubscription.addHasOne('account', { type: 'account', id: account.id })
    }

    crudSubscription.save({
      beforeCreate: (record: any) => {
        if (!record.attributes.instance) {
          return {
            ...record,
            attributes: {
              ...record.attributes,
              instance: record.attributes.name
            }
          }
        }

        return true
      },

      onCreate: (record: any, { router, dispatch }: any) => {
        router.push(`services/subscriptions/${account.id}/${record.id}`)

        if (prepare) {
          dispatch(
            reportsThunks.prepare.prepareAffectedReports({
              resource: PreparableResource.Subscription,
              id: record.id as string
            })
          )
        }
      },

      onUpdate: (record: any, { dispatch }: any) => {
        if (prepare) {
          dispatch(
            reportsThunks.prepare.prepareAffectedReports({
              resource: PreparableResource.Subscription,
              id: record.id as string
            })
          )
        }
      }
    })
  }, [crudSubscription, account])

  const onDelete = useCallback(() => {
    warning({ body: translate('Are you sure you want to delete this subscription?') }).then(() => {
      crudSubscription.delete({
        onDelete: (_: any, { router }: any) => {
          router.push(`services/subscriptions/${account.id}`)
        }
      })
    })
  }, [crudSubscription, account])

  const isYearlyInterval = crudSubscription
    .attributes.alt_interval === ServiceSubscriptionAltInterval.Year

  return (
    <Form onSubmit={onSave}>

      <Heading.Group>
        <span data-testid='name'>
          <Heading.Input
            label={translate('label')}
            placeholder={translate('Subscription name')}
            value={crudSubscription.attributes.name}
            autoFocus={crudSubscription.attributes.name === undefined}
            onChange={crudSubscription.setAttribute('name')} />
        </span>
        <Heading.Sub data-testid='account-name'>
          {account?.attributes?.name}
        </Heading.Sub>
      </Heading.Group>

      <ServiceGroup
        serviceCategory={selectedServiceCategory}
        service={selectedService}
        account={account}
        rate={crudSubscription.attributes.rate}
        cogs={crudSubscription.attributes.cogs}
        onChangeServiceCategory={setServiceCategory}
        onChangeService={(service) => {
          if (service) {
            crudSubscription.addHasOne('service', service)
          } else {
            crudSubscription.setRelationship('service', undefined)
          }
          setService(service)
        }}
        onChangeRate={crudSubscription.setAttribute('rate')}
        onChangeCogs={crudSubscription.setAttribute('cogs')} />

      <IntervalGroup
        type={crudSubscription.attributes.type}
        startDate={crudSubscription.attributes.start_date}
        endDate={crudSubscription.attributes.end_date}
        chargeDay={convertToString(crudSubscription.attributes.charge_day)}
        altInterval={crudSubscription.attributes.alt_interval}
        serviceInterval={selectedService?.attributes?.interval}
        onChangeType={(type) => {
          if (type === SubscriptionType.OneOff) {
            crudSubscription
              .setAttribute('interval', null)
              .setAttribute('end_date', null)
          }

          crudSubscription.setAttribute('type', type)
        }}
        onChangeStartDate={crudSubscription.setAttribute('start_date')}
        onChangeEndDate={crudSubscription.setAttribute('end_date')}
        onChangeChargeDay={(chargeDay) => {
          crudSubscription.setAttribute(
            'charge_day',
            isYearlyInterval ? chargeDay : Number(chargeDay)
          )
        }}
        onChangeAltInterval={(altInterval) => {
          if (altInterval !== ServiceSubscriptionAltInterval.Month) {
            crudSubscription.setAttribute('charge_day', null)
          }

          crudSubscription.setAttribute(
            'alt_interval',
            altInterval
          )
        }} />

      <UsageGroup
        quantity={crudSubscription.attributes.quantity}
        identifier={crudSubscription.attributes.instance}
        unitLabel={selectedService?.attributes?.unit_label}
        onChangeQuantity={crudSubscription.setAttribute('quantity')}
        onChangeIdentifier={crudSubscription.setAttribute('instance')} />

      <Form.Toolbar>
        {crudSubscription.id && <Button.Delete onClick={onDelete} />}
        <Button.Group success={!crudSubscription.id}>
          {crudSubscription.id ? <Button.Update /> : <Button.Create />}
          <Button.Select>
            <Button.SelectOption onClick={() => onSave({ prepare: true })}>
              {translate('With preparing')}
            </Button.SelectOption>
          </Button.Select>
        </Button.Group>
      </Form.Toolbar>

    </Form>
  )
}
