"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.flatten = void 0;
var flatten = function (tree) { return [tree.data]
    .flatMap(function (x) { return __spreadArray([x], tree.children.flatMap(exports.flatten)); }); };
exports.flatten = flatten;
