const base = 36

export const randomChars = (length = 32) => {
  return [...Array(length)]
    .map(() => (~~(Math.random() * base)).toString(base))
    .join('')
}

/**
 * @param {string} str
 */
export const capitalize = (str) => str[0].toUpperCase() + str.slice(1)
