var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { fromTheme, themeColor, themeSpace } from '../Theme';
import { Icon } from '../Icon';
import { Label } from '../Label';
export var StyledHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  box-sizing: border-box;\n  background-color: ", ";\n  height: 60px;\n  align-items: center;\n  padding: 0 ", ";\n  border: 2px solid ", ";\n  border-radius: 3px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  box-sizing: border-box;\n  background-color: ", ";\n  height: 60px;\n  align-items: center;\n  padding: 0 ", ";\n  border: 2px solid ", ";\n  border-radius: 3px;\n"])), themeColor('lightGray'), themeSpace(2), themeColor('lightGray'));
export var Header = function (_a) {
    var children = _a.children;
    var toggleCollapsed = CollapsibleComponent.useCollapsible().toggleCollapsed;
    return (_jsx(StyledHeader, __assign({ onClick: toggleCollapsed }, { children: children }), void 0));
};
export var Actions = styled.div.attrs({
    onClick: function (e) { return e.stopPropagation(); }
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin: 0 ", ";\n\n  align-items: center;\n\n  ", " {\n    color: black;\n  }\n\n  > *:not(:last-child) {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin: 0 ", ";\n\n  align-items: center;\n\n  ", " {\n    color: black;\n  }\n\n  > *:not(:last-child) {\n    margin: 0 10px;\n  }\n"])), themeSpace(1), Icon);
var StyledContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", "em;\n  border: 2px solid ", ";\n  margin-top: -2px;\n"], ["\n  padding: ", "em;\n  border: 2px solid ", ";\n  margin-top: -2px;\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), themeColor('lightGray'));
export var Content = function (_a) {
    var children = _a.children;
    return (_jsx(CollapsibleComponent.Content, { children: _jsx(StyledContent, { children: children }, void 0) }, void 0));
};
export var Title = function (_a) {
    var children = _a.children, subTitle = _a.subTitle;
    return (_jsxs(Label.Group, { children: [_jsx(Label, { children: children }, void 0), subTitle && _jsx(Label.Sub, { children: subTitle }, void 0)] }, void 0));
};
export var Join = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  ", "\n"])), function (_a) {
    var gap = _a.gap;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["gap: ", "px;"], ["gap: ", "px;"])), gap);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
