import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import jquery from 'jquery'
import { Placeholder } from '@exivity/ui'

import { changeComponent, changeFilter, changeLogfile } from '../../../actions/logs'
import { fetchLogs } from '../../../actions/data/logs/logs'
import { Column, Row } from '../../structural/Layout'
import Scrollable from '../../hocs/Scrollable'
import Widget from '../../structural/Widget'
import { Toolbar } from '../../structural/Toolbar/Toolbar'
import { RefreshButton } from '../../structural/Toolbar/RefreshButton'
import { DropdownWithSelect } from '../../molecules/Dropdown'

import List from './LogViewer/List'
import Lines from './LogViewer/Lines'
import * as componentKeys from './componentKeys'

import './LogViewer/index.css'

const components = [
  { key: componentKeys.CORE, value: 'API' },
  { key: componentKeys.BUDGETS, value: 'Budgets' },
  { key: componentKeys.EXTRACTORS, value: 'Extractors' },
  { key: componentKeys.JOB_MANAGER, value: 'Job manager' },
  { key: componentKeys.NODE_MANAGER, value: 'Node manager' },
  { key: componentKeys.NOTIFICATIONS, value: 'Notifications' },
  { key: componentKeys.REPORTS, value: 'Reports' },
  { key: componentKeys.SCHEDULER, value: 'Scheduler' },
  { key: componentKeys.TRANSFORMERS, value: 'Transformers' }
]

export const filters = {
  use: [
    { key: 0, value: 'DEBUGX', class: 'muted' },
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'muted' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' },
    { key: 6, value: 'INTERNAL', class: 'default' },
    { key: 7, value: 'USER', class: 'default' },
    { key: 8, value: 'SYSTEM', class: 'success' }
  ],
  transcript: [
    { key: 0, value: 'DEBUGX', class: 'muted' },
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'muted' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' },
    { key: 6, value: 'INTERNAL', class: 'default' },
    { key: 7, value: 'USER', class: 'default' },
    { key: 8, value: 'SYSTEM', class: 'success' }
  ],
  horizon: [
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'default' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' }
  ],
  chronos: [
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'default' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' }
  ],
  merlin: [
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'default' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' }
  ],
  griffon: [
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'default' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' }
  ],
  edify: [
    { key: 0, value: 'DEBUGX', class: 'muted' },
    { key: 1, value: 'DEBUG', class: 'muted' },
    { key: 2, value: 'INFO', class: 'muted' },
    { key: 3, value: 'WARN', class: 'warning' },
    { key: 4, value: 'ERROR', class: 'danger' },
    { key: 5, value: 'FATAL', class: 'danger' },
    { key: 6, value: 'INTERNAL', class: 'default' },
    { key: 7, value: 'SYSTEM', class: 'success' }
  ],
  proximity: [
    { key: 100, value: 'DEBUG', class: 'muted' },
    { key: 200, value: 'INFO', class: 'default' },
    { key: 250, value: 'NOTICE', class: 'default' },
    { key: 300, value: 'WARNING', class: 'warning' },
    { key: 400, value: 'ERROR', class: 'danger' },
    { key: 500, value: 'CRITICAL', class: 'danger' },
    { key: 550, value: 'ALERT', class: 'danger' },
    { key: 600, value: 'EMERGENCY', class: 'danger' }
  ],
  pigeon: [
    { key: 100, value: 'DEBUG', class: 'muted' },
    { key: 200, value: 'INFO', class: 'default' },
    { key: 250, value: 'NOTICE', class: 'default' },
    { key: 300, value: 'WARNING', class: 'warning' },
    { key: 400, value: 'ERROR', class: 'danger' },
    { key: 500, value: 'CRITICAL', class: 'danger' },
    { key: 550, value: 'ALERT', class: 'danger' },
    { key: 600, value: 'EMERGENCY', class: 'danger' }
  ]
}

class LogViewer extends Component {
  componentDidMount () {
    if (this.props.selectedComponent) {
      this.refresh()
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.selectedComponent !== this.props.selectedComponent) {
      this.refresh()
    }

    // Fix bug with panel heights
    setTimeout(() => jquery(window).trigger('resize'), 1000)
  }

  refresh = () => {
    this.props.dispatch(fetchLogs())
  }

  render () {
    return (
      <div className='ex-logviewer'>
        <Toolbar>
          <Toolbar.Left>
            <DropdownWithSelect label='Component'
              items={components}
              placeholder='Choose'
              initialSelected={this.props.selectedComponent}
              onChange={this.props.onComponentChange} />
            {this.props.selectedComponent && (
              <DropdownWithSelect label='Severity'
                items={filters[this.props.selectedComponent].slice(0)}
                placeholder='Choose'
                selected={this.props.selectedFilter}
                clearable
                controlled
                onChange={this.props.onFilterChange} />
            )}
          </Toolbar.Left>
          <Toolbar.Right>
            <RefreshButton onClick={this.refresh} />
          </Toolbar.Right>
        </Toolbar>
        <Row>
          <Column width='auto'>
            <Widget title='Files' sticky expandHeight expandHeightOffset={152}>
              <Scrollable>
                {this.props.files.length
                  ? (
                    <List files={this.props.files.map(item => item.filename)}
                      selected={this.props.selectedLogfileIndex}
                      onSelectFile={this.props.onLogfileChange} />
                  )
                  : <Placeholder>Select component</Placeholder>}
              </Scrollable>
            </Widget>
          </Column>
          <Column>
            <Widget title='Lines'>
              {this.props.selectedFile.lines
                ? (
                  <Lines lines={this.props.selectedFile.lines}
                    filter={this.props.selectedFilter}
                    severityLabels={filters[this.props.selectedComponent]} />
                )
                : <Placeholder>Select file</Placeholder>}
            </Widget>
          </Column>
        </Row>
      </div>
    )
  }
}

LogViewer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  onComponentChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onLogfileChange: PropTypes.func.isRequired,
  selectedComponent: PropTypes.string,
  selectedFile: PropTypes.object,
  selectedFilter: PropTypes.number,
  selectedLogfileIndex: PropTypes.number
}

const mapStateToProps = (state) => {
  const getFile = () => (
    (state.logs.selectedComponent
      && state.data.logs.logs[state.logs.selectedComponent]
      && state.logs.selectedLogfile !== null
      && state.data.logs.logs[state.logs.selectedComponent][state.logs.selectedLogfile])
      || {}
  )
  return {
    files: state.data.logs.logs[state.logs.selectedComponent] || [],
    selectedFile: getFile(),
    selectedComponent: state.logs.selectedComponent,
    selectedLogfileIndex: state.logs.selectedLogfile,
    selectedFilter: state.logs.selectedFilter
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onComponentChange: (selected) => {
    dispatch(changeComponent(selected ? selected.key : null))
    dispatch(changeFilter(filters[selected.key][0].key))
  },
  onFilterChange: (selected) => {
    dispatch(changeFilter(selected ? selected.key : null))
  },
  onLogfileChange: (selected) => {
    dispatch(changeLogfile(selected))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogViewer)
