import React, { useEffect } from 'react'
import Store from '@orbit/memory'
import styled, { css } from 'styled-components'
import { Resources } from '@exivity/data-layer'
import { Placeholder, Widget, Heading } from '@exivity/ui'
import { translate } from '@exivity/translations'

import WidgetContainer from '../Container'

import { fetchWorkflows } from './fetch'
import WorkflowStatus from './Status'

interface StyledWidgetProps {
  scroll: boolean
}

const ScrollableWidget = styled(Widget)`
  height: 100%;
  
  ${({ scroll }: StyledWidgetProps) => css`
    overflow-y: ${scroll ? 'scroll' : 'visible'};
  `}
`

interface WorkflowStatusWidgetProps {
  queryStore: Store['query']
  workflows: Resources['workflow'][]
}

function renderWorkflowStatus (workflows: Resources['workflow'][]) {
  return workflows.map(workflow => <WorkflowStatus key={workflow.id} workflow={workflow} />)
}

export function WorkflowStatusWidget ({ queryStore, workflows = [] }: WorkflowStatusWidgetProps) {
  useEffect(() => {
    fetchWorkflows(queryStore)
  }, [])

  const hasWorkflows = workflows.length > 0

  return (
    <WidgetContainer>
      <ScrollableWidget scroll={hasWorkflows}>
        <Heading>
          {translate('Workflow status')}
        </Heading>
        {
          hasWorkflows
            ? renderWorkflowStatus(workflows)
            : <Placeholder>{translate('No workflows found')}</Placeholder>
        }
      </ScrollableWidget>
    </WidgetContainer>
  )
}
