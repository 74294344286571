import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { width as widthUtil } from '../../../utils/uikit'

import Element from './Element'

class TextInput extends Component {
  input

  constructor (props) {
    super(props)

    this.state = { value: props.children || props.value || '' }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ value: nextProps.children || nextProps.value || '' })
  }

  onChange = event => {
    this.setState({ value: event.target.value })

    // Trigger component cb
    this.props.onChange && this.props.onChange(event.target.value)
  }

  onFocus = event => {
    this.props.onFocus && this.props.onFocus(event.target.value)
  }

  onBlur = event => {
    this.props.onBlur && this.props.onBlur(event.target.value)
  }

  render () {
    const {
      label, name, className,
      size, width, forceFullWidth, forceInline,
      placeholder, autoComplete, masked,
      icon, iconClassName, locked, readonly, required,
      number, min, max,
      date, time, url, email, hidden,
      controlled, onClick,
      suffix, prefix, autoFocus
    } = this.props
    const { value } = this.state
    let type
    let step

    if (masked) {
      type = 'password'
    } else if (number) {
      type = 'number'
      step = 'any'
    } else if (date) {
      type = 'date'
    } else if (time) {
      type = 'time'
    } else if (url) {
      type = 'url'
    } else if (email) {
      type = 'email'
    } else if (hidden) {
      type = 'hidden'
    } else {
      type = 'text'
    }

    return (
      <Element label={label} forceFullWidth={forceFullWidth} forceInline={forceInline}>
        <div className='uk-flex uk-flex-middle'>
          {prefix && <span className='ex-form__prefix uk-margin-right'>{prefix}</span>}
          <div className={`uk-inline ${widthUtil.toClass(width)}`} onClick={onClick}>
            {icon && (
              <span
                className={`uk-form-icon ${iconClassName || ''}`}
                data-uk-icon={`icon: ${icon}`} />
            )}
            <input ref={ref => (this.input = ref)}
              data-testid={this.props.testid}
              className={`uk-input ${className || ''} uk-form-${size}`}
              placeholder={placeholder}
              required={required}
              name={name}
              autoComplete={autoComplete}
              disabled={locked}
              readOnly={readonly}
              type={type}
              value={controlled ? value : undefined}
              min={min}
              max={max}
              step={step}
              autoFocus={autoFocus}
              defaultValue={controlled ? undefined : value}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur} />
          </div>
          {suffix && <span className='ex-form__prefix uk-margin-left'>{suffix}</span>}
        </div>
      </Element>
    )
  }
}

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  controlled: PropTypes.bool,
  date: PropTypes.any,
  email: PropTypes.bool,
  forceFullWidth: PropTypes.bool,
  forceInline: PropTypes.bool,
  hidden: PropTypes.any,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  label: PropTypes.any,
  locked: PropTypes.bool,
  masked: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  number: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.any,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  suffix: PropTypes.any,
  testid: PropTypes.string,
  time: PropTypes.bool,
  url: PropTypes.bool,
  value: PropTypes.any,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

TextInput.defaultProps = {
  masked: false,
  number: false,
  required: false,
  size: 'normal',
  time: false,
  width: 'expand'
}

export default TextInput
