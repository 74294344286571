import React from 'react'
import { translate } from '@exivity/translations'
import { Button, Form, useModal } from '@exivity/ui'
import { CrudRecord } from 'react-crud-hook'

import { UserModel } from '../../../../data/types'

import { AccessLevelGroup, AccountAccessGroup, InfoGroup } from './groups'

interface ProfileProps {
  user: CrudRecord<UserModel>
  currentUser?: UserModel
}

export function Profile ({ user: _user, currentUser }: ProfileProps) {
  const { warning } = useModal()

  function saveUser () {
    _user.save({
      onCreate: (record: any, { router }: any) => {
        router.push(`administration/user-management/users/${record.id}/profile`)
      }
    })
  }

  function deleteUser () {
    warning({ body: translate('Are you sure you want to delete this user?') }).then(() => {
      _user.delete({
        onDelete: (_: any, { router }: any) => {
          router.push('administration/user-management/users')
        }
      })
    })
  }

  return (
    <Form onSubmit={saveUser}>
      <InfoGroup
        // Password does not update when navigating from user to user
        // when this key is not given
        key={_user.id}
        sourceType={_user.attributes.source}
        email={_user.attributes.email_address}
        displayName={_user.attributes.display_name}
        password={_user.attributes.password || ''}
        onChangeEmail={_user.setAttribute('email_address')}
        onChangeDisplayName={_user.setAttribute('display_name')}
        onChangePassword={_user.setAttribute('password')} />

      <AccessLevelGroup
        userGroup={_user.relationships?.usergroup?.data || null}
        onChangeUserGroup={_user.setRelationship('usergroup')} />

      <AccountAccessGroup
        isCurrentUser={_user.id === currentUser?.id}
        type={_user.attributes.account_access_type}
        accounts={_user.relationships?.accounts?.data || []}
        onChangeType={_user.setAttribute('account_access_type')}
        onChangeAccounts={_user.setRelationship('accounts')} />

      <Form.Toolbar>
        {!_user.id
          ? <Button.Create />
          : (
            <>
              <Button.Delete onClick={deleteUser} />
              <Button.Update />
            </>
          )}
      </Form.Toolbar>
    </Form>
  )
}
