import React from 'react'
import PropTypes from 'prop-types'

import { width as widthUtil } from '../../../utils/uikit'

import Element from './Element'

/**
 * @type {React.ComponentType<any>}
 */
const TextArea = ({
  label,
  name,
  className,
  size,
  width,
  forceFullWidth,
  forceInline,
  placeholder,
  children,
  onChange,
  rows,
  controlled,
  value
}) => (
  <Element label={label} forceFullWidth={forceFullWidth} forceInline={forceInline}>
    <div className={`uk-inline ${widthUtil.toClass(width)}`}>
      <textarea className={`uk-textarea ${className || ''} uk-form-${size}`}
        rows={rows}
        placeholder={placeholder}
        name={name}
        value={controlled ? value : undefined}
        defaultValue={controlled ? undefined : children}
        onChange={event => onChange && onChange(event.target.value)} />
    </div>
  </Element>
)

TextArea.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  controlled: PropTypes.bool,
  forceFullWidth: PropTypes.bool,
  forceInline: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  value: PropTypes.string,
  width: widthUtil.propType
}

TextArea.defaultProps = {
  size: 'normal',
  width: 'auto',
  rows: 3
}

export default TextArea
