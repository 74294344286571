import { withData } from 'react-orbitjs'
import { CrudRecord } from 'react-crud-hook'
import { QueryBuilder } from '@orbit/data'

import { DsetModel, MetadataModel, ServiceModel } from '../../../../data/types'

import { Metadata } from './Metadata'

type OwnProps = {
  service: CrudRecord<ServiceModel>
  onRecord: (metadataRecord: CrudRecord<MetadataModel>) => void
}

type RecordProps = {
  dataset: DsetModel
}

export type MetadataProps = OwnProps & RecordProps

function mapRecordToProps ({ service }: OwnProps) {
  return {
    dataset: (q: QueryBuilder) => q.findRecord({ type: 'dset', id: service.attributes.dset })
  }
}

export default withData(mapRecordToProps)(Metadata)
