import React from 'react'
import { translate } from '@exivity/translations'
import { Field, Label, Input, Checkbox, Markdown, TextArea } from '@exivity/ui'
import { CrudRecord } from 'react-crud-hook/'

import { ChannelType, NotificationChannelModel } from '../../../../data/types'

import { useChannelInfo } from './useChannelInfo'

import { ChannelConfigurationProps } from './'

function serializeHeaders (headers: Record<string, string>) {
  return Object.entries(headers).reduce((text, [key, value]) => {
    return text + key + ': ' + value + '\n'
  }, '')
}

function parseHeaders (headers: string) {
  const pairs = headers.split('\n')

  return pairs.reduce((object, pair) => {
    const [key, value] = pair.split(':')

    if (key) {
      object[key.trim()] = value?.trim()
    }

    return object
  }, {} as Record<string, string>)
}

const INIT_INFO = {
  recipient: '',
  headers: {},
  tls_verification: true,
  include_attachments: false
}

export function WebhookConfiguration ({ channel }: ChannelConfigurationProps) {
  const [info, setInfoField] = useChannelInfo<ChannelType.webhook>(
    channel as CrudRecord<NotificationChannelModel<ChannelType.webhook>>,
    channel.id ? (channel.attributes.info || {}) : INIT_INFO
  )

  return (
    <>
      <Field>
        <Label.Group>
          <Label>
            {translate('Webhook URL')}
          </Label>
          <Label.Sub>
            {translate('Needs to be a valid URL.')}
          </Label.Sub>
        </Label.Group>
        <Input
          placeholder={translate('Webhook URL')}
          required
          value={info?.recipient}
          onChange={setInfoField('recipient')} />
      </Field>
      <Field>
        <Label.Group>
          <Label>
            {translate('Headers')}
          </Label>
          <Label.Sub>
            <Markdown>
              {translate('Optional. Each `Header: Value` pair on a new line.')}
            </Markdown>
          </Label.Sub>
        </Label.Group>
        <TextArea
          placeholder={translate('Headers')}
          defaultValue={serializeHeaders(info?.headers || {}) || ''}
          onBlur={(e) => {
            const headers = e.target.value
            setInfoField('headers', headers.length
              ? parseHeaders(headers)
              : {}
            )
          }} />
      </Field>
      <Field>
        <Label.Group>
          <Label>
            {translate('TLS verification')}
          </Label>
          <Label.Sub>
            {translate('Verify TLS certificates when delivering payloads. We don\'t recommend to disable this.')}
          </Label.Sub>
        </Label.Group>
        <Checkbox
          label={translate('Enabled')}
          checked={info?.tls_verification ?? false}
          onChange={setInfoField('tls_verification')} />
      </Field>
      <Field id='include-attachments'>
        <Label.Group>
          <Label>
            {translate('Include attachments')}
          </Label>
        </Label.Group>
        <Checkbox
          label={translate('Enabled')}
          checked={info?.include_attachments ?? false}
          onChange={setInfoField('include_attachments')} />
      </Field>
    </>
  )
}
