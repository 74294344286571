"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pipe = void 0;
/**
 * @description (((a, b, …, n) → o), (o → p), …, (x → y), (y → z)) → ((a, b, …, n) → z
 */
function pipe() {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return function (x) { return fns.reduce(function (v, f) { return f(v); }, x); };
}
exports.pipe = pipe;
