import React, { PureComponent } from 'react'

import Icon from '../Icon'

import './index.css'

interface CollapserProps {
  initialValue?: boolean
  value?: boolean
  onChange?(collapsed: boolean): void
  children?: any
}

interface CollapserState {
  value: boolean
}

export class Collapser extends PureComponent<CollapserProps, CollapserState> {
  constructor (props: CollapserProps) {
    super(props)

    this.state = {
      value: props.initialValue || true
    }
  }

  handleCollapse = (): void => {
    const { onChange } = this.props

    this.isControlled()
      ? onChange && onChange(!this.props.value)
      : this.setState({ value: !this.state.value },
        () => onChange && onChange(this.state.value))
  }

  isControlled = (): boolean => this.props.value !== undefined

  renderChildren = (value: boolean) => {
    if (typeof this.props.children === 'function') {
      return this.props.children(value)
    }

    return this.props.children
  }

  render () {
    const value = this.isControlled()
      ? this.props.value
      : this.state.value

    return (
      <button className='ex-collapser'
        onClick={this.handleCollapse}>
        {this.renderChildren(!!value)}
        <Icon material>
          { value
            ? 'keyboard_arrow_down'
            : 'keyboard_arrow_up'}
        </Icon>
      </button>
    )
  }
}

export default Collapser
