var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { format as dateFormat } from 'date-fns';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { translate } from '@exivity/translations';
import styled from 'styled-components';
import { SelectInput } from '../Input/SelectInput';
import { ModePicker } from '../Calendar/ModePicker';
import { DateRangeCalendarV2 } from '../Calendar/DateRangeCalendarV2/DateRangeCalendarV2';
import { CalendarMode } from '../Calendar/CalendarModes';
import { useFieldContext } from '../Field/context';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
import { withExtendableProps } from '../ExtendProps';
var DatePickerDropDown = styled(Dropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .dropdown__drop {\n    box-sizing: border-box;\n    padding: ", ";\n    width: 340px;\n  }\n"], ["\n  .dropdown__drop {\n    box-sizing: border-box;\n    padding: ", ";\n    width: 340px;\n  }\n"])), themeSpace(2));
function formatRange(range, format) {
    return range.map(function (date) { return dateFormat(date, format); }).join(' - ');
}
export function _DateRangeFilter(_a) {
    var name = _a.name, _b = _a.format, format = _b === void 0 ? 'yyyyMMdd' : _b, _c = _a.placeholder, placeholder = _c === void 0 ? translate('Choose date') : _c, required = _a.required, disabled = _a.disabled, _d = _a.placement, placement = _d === void 0 ? 'bottom-start' : _d, min = _a.min, max = _a.max, _e = _a.mode, mode = _e === void 0 ? CalendarMode.Months : _e, value = _a.value, onModeChange = _a.onModeChange, _f = _a.onChange, onChange = _f === void 0 ? identity : _f, onClear = _a.onClear, offset = _a.offset;
    var _g = useToggle(false), isOpen = _g[0], toggleOpen = _g[1];
    var id = useFieldContext().id;
    var calendar = (_jsx(DateRangeCalendarV2, { mode: mode, value: value, min: min, max: max, offset: offset, onChange: function (date) {
            onChange(date);
            if (date[0] && date[1]) {
                toggleOpen();
            }
        }, onClear: onClear
            ? juxt([onClear, toggleOpen])
            : undefined }, void 0));
    return (_jsx(DatePickerDropDown, __assign({ placement: placement, disabled: disabled, reference: _jsx(SelectInput, { id: id, name: name, value: value
                ? formatRange(value, format)
                : '', outlined: true, secondary: true, placeholder: placeholder, required: required, disabled: disabled }, void 0), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: onModeChange
            ? (_jsx(ModePicker, __assign({ value: mode, onChange: onModeChange }, { children: calendar }), void 0))
            : calendar }), void 0));
}
export var DateRangeFilter = withExtendableProps('DateRangeFilter')(_DateRangeFilter);
var templateObject_1;
