import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { ReportFormatter } from '../../../utils/formatters/ReportFormatter'
import { configurationSelectors } from '../../../domains/administration/state/configuration'

export function useReportFormatter () {
  const formatterOptions = useSelector(
    configurationSelectors.getReportFormatterOptions,
    shallowEqual
  )

  return useMemo(() => new ReportFormatter(formatterOptions), [formatterOptions])
}
