import {
  Record,
  RecordHasManyRelationship,
  RecordRelationship,
  RecordHasOneRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

export const USER_TYPE = 'user'

export interface UserModel extends Record {
  type: typeof USER_TYPE
  attributes: UserAttributes
  relationships?: UserRelationships
}

export enum UserAccountAccessType {
  All = 'all',
  Custom = 'custom'
}

export enum UserSource {
  LOCAL = 'local',
  LDAP = 'ldap',
  SAML = 'saml'
}

export interface UserAttributes {
  username: string
  email_address: string
  account_access_type: UserAccountAccessType
  source: UserSource
  display_name: string
  /* Only defined when source is Local */
  password?: string
  /* Required when a user updates themselves as a final auth check. */
  current_password?: string
}

export interface UserRelationships extends Dict<RecordRelationship> {
  usergroup: RecordHasOneRelationship
  accounts: RecordHasManyRelationship
  subscriptions: RecordHasManyRelationship
  channels: RecordHasManyRelationship
}

export const DEFAULT_USER: UserModel = {
  id: '',
  type: USER_TYPE,
  attributes: {
    username: '',
    current_password: '',
    password: '',
    email_address: '',
    account_access_type: UserAccountAccessType.Custom,
    source: UserSource.LOCAL,
    display_name: ''
  }
}

export const createUser = makeCreateModel<UserModel>(USER_TYPE, DEFAULT_USER.attributes)
