import { get } from '../API'
import { ActionDispatcher } from '../store/utils'

import { updateFlagsData } from './system/flags'
import { updateReleaseData } from './system/release'

export function fetchSystemData (): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    get(
      '/system',
      {},
      {
        background: true,
        workStatusMessage: translate('Updating system information')
      }
    ).then(data => {
      dispatch(updateReleaseData(data.version))
      dispatch(updateFlagsData(data.flags))
    })
  }
}
