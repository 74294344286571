import { useCallback, useEffect } from 'react'
import { CrudRecord } from 'react-crud-hook'

import {
  NotificationChannelModel,
  ChannelType,
  ChannelInfo
} from '../../../../data/types'

interface SetChannelInfo<T extends ChannelType> {
  <K extends keyof ChannelInfo<T>>(key: K, value: ChannelInfo<T>[K]): void
  <K extends keyof ChannelInfo<T>>(key: K): (value: ChannelInfo<T>[K]) => void
}

export function useChannelInfo<T extends ChannelType> (
  channel: CrudRecord<NotificationChannelModel<T>>,
  initValue: Partial<ChannelInfo<T>>
) {
  useEffect(() => {
    channel.setAttribute('info', initValue)
  }, [channel.attributes.type, initValue])

  const setInfoField = useCallback(((key: string, value?: any) => {
    function _setInfoField (key: string, value: any) {
      channel.setAttribute('info', { ...channel.attributes.info || {}, [key]: value })
    }

    return value !== undefined
      ? _setInfoField(key, value)
      : (value: any) => _setInfoField(key, value)
  }) as SetChannelInfo<T>, [channel.attributes.info])

  return [channel.attributes.info, setInfoField] as const
}
