import React from 'react'
import { Group, Field, Label, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { UserSource } from '../../../../../data/types'

interface InfoGroupProps {
  sourceType: UserSource
  email: string
  displayName: string
  password: string
  onChangeEmail: (email: string) => void
  onChangeDisplayName: (displayName: string) => void
  onChangePassword: (password: string) => void
}

export function InfoGroup ({
  sourceType,
  email,
  displayName,
  password,
  onChangeEmail,
  onChangeDisplayName,
  onChangePassword
}: InfoGroupProps) {
  return (
    <Group title={translate('User account')}>
      <Field.Container>
        <Field data-testid='email'>
          <Label>{translate('E-mail')}</Label>
          <Input
            required
            placeholder={translate('E-mail')}
            type='email'
            disabled={sourceType === UserSource.SAML}
            value={email}
            onChange={onChangeEmail} />
        </Field>

        <Field data-testid='display_name'>
          <Label>{translate('Display name')}</Label>
          <Input
            placeholder={translate('Display name')}
            disabled={sourceType === UserSource.SAML}
            value={displayName}
            onChange={onChangeDisplayName} />
        </Field>

        {sourceType !== UserSource.SAML && (
          <Field data-testid='password'>
            <Label>{translate('Password')}</Label>
            <Input type='password'
              placeholder={translate('Password')}
              value={password}
              onChange={onChangePassword} />
          </Field>
        )}
      </Field.Container>
    </Group>
  )
}
