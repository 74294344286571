var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useLayoutEffect, useState, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled, { css } from 'styled-components';
import isFuture from 'date-fns/isFuture';
import formatDate from 'date-fns/format';
import compareAsc from 'date-fns/compareAsc';
import { composeAsync, getBoundingClientRect, querySelectorAll, animateElement, arrayFrom, subtract, compose, equals, zipWith, prop, map, any } from '@exivity/fp';
import { themeColor, themePurpose } from '../Theme';
import { injectIf } from '../utils';
var onEnterSelect = function (event) {
    var _a;
    switch (event.key) {
        case 'Enter':
            (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.click();
            break;
        default:
            break;
    }
};
var Line = styled(List)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  white-space: nowrap;\n\n  overflow-y: hidden !important;\n      \n  ::-webkit-scrollbar {\n    height: 8px;\n  }\n    \n  ::-webkit-scrollbar-thumb {\n    background: #bdbdbd;\n    border-radius: 20px;\n    &:hover {\n      background: #808080;\n    }\n  }\n  \n  ::-webkit-scrollbar-track-piece {\n    background: #fff;\n    box-sizing: border-box;\n    border-radius: 20px;\n  \n"], ["\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  white-space: nowrap;\n\n  overflow-y: hidden !important;\n      \n  ::-webkit-scrollbar {\n    height: 8px;\n  }\n    \n  ::-webkit-scrollbar-thumb {\n    background: #bdbdbd;\n    border-radius: 20px;\n    &:hover {\n      background: #808080;\n    }\n  }\n  \n  ::-webkit-scrollbar-track-piece {\n    background: #fff;\n    box-sizing: border-box;\n    border-radius: 20px;\n  \n"])));
var selectedDot = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 80px;\n  background: ", ";\n  color: ", ";\n  transform: scale(1.1);\n"], ["\n  margin: 0 80px;\n  background: ", ";\n  color: ", ";\n  transform: scale(1.1);\n"])), themePurpose('brand'), themePurpose('brand'));
var futureDot = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #757575;\n  color: #757575;\n"], ["\n  background: #757575;\n  color: #757575;\n"])));
var Dot = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  color: #bdbdbd;\n  background: #bdbdbd;\n  text-align: center;\n  line-height: 1.2;\n  position: relative;\n  border-radius: 50%;\n  margin: 0 20px;\n  transition: transform 150ms ease-in-out;\n\n  ", "\n  ", "\n\n  &:hover {\n    transform: scale(1.1);\n  }\n\n  &:focus {\n    background: ", ";\n    color: ", ";\n    outline: none;\n    transform: scale(1.1);\n  }\n  \n  &:before {\n    font-family: Fira Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 20px;\n  \n    display: inline-block;\n    content: attr(data-year);\n    width: 100px;\n    position: absolute;\n    top: -35px;\n    transform: translateX(-50%);\n  }\n"], ["\n  display: inline-block;\n  color: #bdbdbd;\n  background: #bdbdbd;\n  text-align: center;\n  line-height: 1.2;\n  position: relative;\n  border-radius: 50%;\n  margin: 0 20px;\n  transition: transform 150ms ease-in-out;\n\n  ", "\n  ", "\n\n  &:hover {\n    transform: scale(1.1);\n  }\n\n  &:focus {\n    background: ", ";\n    color: ", ";\n    outline: none;\n    transform: scale(1.1);\n  }\n  \n  &:before {\n    font-family: Fira Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 20px;\n  \n    display: inline-block;\n    content: attr(data-year);\n    width: 100px;\n    position: absolute;\n    top: -35px;\n    transform: translateX(-50%);\n  }\n"])), injectIf('future', futureDot), injectIf('active', selectedDot), themePurpose('brand'), themePurpose('brand'));
var WrappedDot = function (_a) {
    var style = _a.style, index = _a.index, data = _a.data;
    var activeIndex = data.activeIndex, isFutureDate = data.isFutureDate, valueAccessor = data.valueAccessor, dateAccessor = data.dateAccessor, timelineData = data.timelineData, onChange = data.onChange, format = data.format;
    var item = timelineData[index];
    var date = formatDate(dateAccessor(item), format);
    return (_jsx(Dot, { title: date, tabIndex: 0, style: getItemStyles(style, index, activeIndex), active: index === activeIndex, future: isFutureDate(item), "data-active": String(index === activeIndex), "data-year": date, onClick: function () { return onChange(valueAccessor(item)); }, onKeyDown: onEnterSelect }, String(index)));
};
var GradientWindow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  \n  background: linear-gradient(\n    to bottom,\n    rgba(255,255,255,0) 0%,\n    rgba(255,255,255,0) 52%,\n    ", " 53%,\n    rgba(255,255,255,0) 54%,\n    rgba(255,255,255,0) 100%);\n"], ["\n  height: 100%;\n  width: 100%;\n  \n  background: linear-gradient(\n    to bottom,\n    rgba(255,255,255,0) 0%,\n    rgba(255,255,255,0) 52%,\n    ", " 53%,\n    rgba(255,255,255,0) 54%,\n    rgba(255,255,255,0) 100%);\n"])), themeColor('gray'));
var getDotsFromList = compose(arrayFrom, querySelectorAll('li'));
var getDotPositions = compose(map(prop('left')), map(getBoundingClientRect), getDotsFromList);
var zipWithSubtract = zipWith(subtract);
function getTranslateX(element) {
    var style = window.getComputedStyle(element);
    var matrix = new DOMMatrixReadOnly(style.transform);
    return matrix.m41;
}
export var getTranslatesXDots = compose(map(getTranslateX), getDotsFromList);
var anyNotZero = any(function (x) { return x !== 0; });
var animate = function (_a) {
    var el = _a[0], from = _a[1];
    var scale = el.getAttribute('data-active') === 'true'
        ? 'scale(1.1)'
        : 'scale(1)';
    return animateElement({
        duration: 300,
        easing: 'ease-in-out'
    }, [
        { transform: "translateX(" + from + "px) " + scale },
        { transform: "translateX(0px) " + scale }
    ], el);
};
var getItemStyles = function (style, index, activeIndex) {
    var _a;
    var left = Number((_a = style === null || style === void 0 ? void 0 : style.left) !== null && _a !== void 0 ? _a : 0);
    return __assign(__assign({}, style), { top: 35, height: 24, width: 24, left: activeIndex !== -1 && (index > activeIndex)
            ? left + 160
            : left + 40 });
};
var autoSizerStyle = { height: 90, width: '100%' };
export function Timeline(_a) {
    var value = _a.value, format = _a.format, dateAccessor = _a.dateAccessor, valueAccessor = _a.valueAccessor, data = _a.data, onChange = _a.onChange;
    var _b = useState(null), ref = _b[0], setRef = _b[1];
    var cache = useRef([]);
    useLayoutEffect(function () {
        if (ref) {
            var translatesX = getTranslatesXDots(ref);
            var from = anyNotZero(translatesX)
                ? translatesX
                : zipWithSubtract(cache.current, getDotPositions(ref));
            cache.current = getDotPositions(ref);
            if (anyNotZero(from)) {
                var elementWithFrom = zipWith(function (el, pos) { return [el, pos]; }, getDotsFromList(ref), from);
                var animateFrom = composeAsync(function () { cache.current = getDotPositions(ref); }, Promise.all.bind(Promise), map(prop('finished')), map(animate));
                animateFrom(elementWithFrom);
            }
        }
    });
    var isFutureDate = compose(isFuture, dateAccessor);
    var _c = useMemo(function () {
        var sortedData = data.sort(function (a, b) { return compareAsc(dateAccessor(a), dateAccessor(b)); });
        return [
            sortedData,
            sortedData.findIndex(function (el) { return equals(valueAccessor(el), value); })
        ];
    }, [value, data, dateAccessor, valueAccessor]), timelineData = _c[0], activeIndex = _c[1];
    return (_jsx(AutoSizer, __assign({ style: autoSizerStyle }, { children: function (_a) {
            var width = _a.width;
            return (_jsx("div", __assign({ style: { height: autoSizerStyle.height, width: width } }, { children: _jsx(GradientWindow, { children: _jsx(Line, __assign({ height: autoSizerStyle.height, width: width, innerElementType: 'ul', layout: 'horizontal', innerRef: setRef, itemData: {
                            activeIndex: activeIndex,
                            onChange: onChange,
                            dateAccessor: dateAccessor,
                            valueAccessor: valueAccessor,
                            timelineData: timelineData,
                            isFutureDate: isFutureDate,
                            format: format
                        }, itemCount: data.length, itemSize: 84 }, { children: WrappedDot }), void 0) }, void 0) }), void 0));
        } }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
