var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Block } from '../Block';
import { GridItem } from './Item';
export var Grid = styled(Block)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  ", "\n  ", "\n"], ["\n  display: grid;\n  ", "\n  ", "\n"])), function (props) { return props.template && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["grid-template: ", ";"], ["grid-template: ", ";"])), props.template); }, function (props) { return props.gap && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["grid-gap: ", ";"], ["grid-gap: ", ";"])), props.gap); });
Grid.Item = GridItem;
Grid.displayName = 'Grid';
var templateObject_1, templateObject_2, templateObject_3;
