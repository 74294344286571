"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tree = void 0;
var atIndexPath_1 = require("./atIndexPath");
var flatten_1 = require("./flatten");
var fromRoot_1 = require("./fromRoot");
var isLeaf_1 = require("./isLeaf");
var map_1 = require("./map");
var mapAccum_1 = require("./mapAccum");
var of_1 = require("./of");
var reduce_1 = require("./reduce");
var removeAtPath_1 = require("./removeAtPath");
var updateAtPath_1 = require("./updateAtPath");
var updateChildren_1 = require("./updateChildren");
var updateData_1 = require("./updateData");
var viewChildren_1 = require("./viewChildren");
var viewData_1 = require("./viewData");
exports.Tree = {
    atIndexPath: atIndexPath_1.atIndexPath,
    flatten: flatten_1.flatten,
    fromRoot: fromRoot_1.fromRoot,
    isLeaf: isLeaf_1.isLeaf,
    map: map_1.map,
    mapAccum: mapAccum_1.mapAccum,
    of: of_1.of,
    reduce: reduce_1.reduce,
    removeAtPath: removeAtPath_1.removeAtPath,
    updateAtPath: updateAtPath_1.updateAtPath,
    updateChildren: updateChildren_1.updateChildren,
    updateData: updateData_1.updateData,
    viewChildren: viewChildren_1.viewChildren,
    viewData: viewData_1.viewData
};
