import { lazy } from 'react'
import { translate } from '@exivity/translations'

const About = lazy(() => import(
  /* webpackChunkName: 'about' */ '../components/pages/About'))

export const about = {
  path: '/about',
  meta: {
    title: () => translate('About')
  },
  component: About
}
