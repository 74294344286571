var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useExpandable } from '../useExpandable';
import { ListFocus } from '../ListFocus';
import { StyledList } from '../SelectLegacy/SelectList/SelectList';
import { DefaultItem } from './DefaultItem';
var ITEM_HEIGHT = 30;
var StyledListFocus = styled(ListFocus)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: auto;\n"], ["\n  overflow-y: auto;\n"])));
/** @deprecated */
export function LegacyTreeList(_a) {
    var data = _a.data, parentKeyAccessor = _a.parentKeyAccessor, expandedKeys = _a.expandedKeys, children = _a.children, keyAccessor = _a.keyAccessor, labelAccessor = _a.labelAccessor, _b = _a.itemHeight, itemHeight = _b === void 0 ? ITEM_HEIGHT : _b, maxItemsRendered = _a.maxItemsRendered, onChange = _a.onChange, value = _a.value, _c = _a.innerElementType, innerElementType = _c === void 0 ? 'ul' : _c, _d = _a.width, width = _d === void 0 ? '100%' : _d, blockProps = __rest(_a, ["data", "parentKeyAccessor", "expandedKeys", "children", "keyAccessor", "labelAccessor", "itemHeight", "maxItemsRendered", "onChange", "value", "innerElementType", "width"]);
    var expandableData = useExpandable(data, keyAccessor, parentKeyAccessor, expandedKeys)[0];
    var handleChange = useCallback(onChange || (function () { return null; }), [onChange]);
    var itemData = useMemo(function () { return ({
        items: expandableData,
        onChange: handleChange,
        selectedItem: value,
        labelAccessor: labelAccessor || (function (item) { return item.value; })
    }); }, [expandableData, value, handleChange, labelAccessor]);
    var height = maxItemsRendered
        ? Math.min(maxItemsRendered, expandableData.length) * itemHeight
        : expandableData.length * itemHeight;
    return (_jsx(StyledListFocus, __assign({}, blockProps, { height: height, maxHeight: '100%', width: width }, { children: _jsx(AutoSizer, { children: function (_a) {
                var height = _a.height, width = _a.width;
                return (_jsx(StyledList, __assign({ height: height, itemData: itemData, itemCount: expandableData.length, itemSize: itemHeight, innerElementType: innerElementType, width: width }, { children: children || DefaultItem }), void 0));
            } }, void 0) }), void 0));
}
export { ToggleExpandedButton } from './DefaultItem';
var templateObject_1;
