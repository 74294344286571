import { Resources } from '@exivity/data-layer'

/** When switching from to or from a tiered service, all the associated rates will
 * be deleted by the API. If we would send them along in the relationships (when updating), the
 * API would therefor throw an error that it cannot find them anymore.
*/
export function removeRatesRelationshipBeforeSave (service: Resources['service']) {
  const _service: any = {
    ...service,
    attributes: {
      ...service.attributes
    },
    relationships: {
      ...service.relationships
    }
  }

  delete _service.relationships.rates

  return _service
}
