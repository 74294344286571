import React, { useMemo } from 'react'
import { Layout, Placeholder, Button, fromTheme } from '@exivity/ui'
import { translate } from '@exivity/translations'
import styled, { css } from 'styled-components'
import { Resources } from '@exivity/data-layer'

import Subscription from '../../components/Subscription'
import AdvancedSelect, {
  Column as AdvancedSelectColumn
} from '../../../../components/molecules/AdvancedSelect'
import {
  ReportModel,
  SubscriptionModel,
  SUBSCRIPTION_TYPE
} from '../../../../data/types'
import { listAccounts } from '../../../../selectors/accounts'
import { useFetchAllRecords } from '../../../../components/hooks/useFetchAllRecords'
import { normalized } from '../../../../components/molecules/AdvancedSelect/addSymbols'

import { listSubscriptions } from './helpers'

interface SubscriptionsDetailProps {
  placeholder: string
  subscriptionId?: string
  accountId?: string
}

function SubscriptionsDetail ({
  placeholder,
  subscriptionId,
  accountId
}: SubscriptionsDetailProps) {
  if (subscriptionId && accountId) {
    return (
      <Subscription
        accountId={accountId}
        subscriptionId={subscriptionId === 'new' ? undefined : subscriptionId} />
    )
  }

  return (
    <div>
      <Placeholder>
        {placeholder}
      </Placeholder>
    </div>
  )
}

const Cell = styled.div<{ leaf: boolean }>`
  ${({ leaf, ...rest }) => !leaf && css`
    color: ${fromTheme(theme => theme.colors.gray)(rest)};
  `}
`

const PATH = 'services/subscriptions/'

interface SubscriptionsProps {
  accounts?: Resources['account'][]
  report: ReportModel
  allSubscriptions?: SubscriptionModel[]
  router: any
  params: Record<string, string>
}

const INCLUDES_SUBSCRIPTION = {
  sources: {
    server: {
      include: ['account', 'service']
    }
  }
}

const INCLUDES_TIERS = {
  sources: {
    server: {
      include: ['ratetiers', 'account', 'service']
    }
  }
}

export function Subscriptions ({
  accounts = [],
  allSubscriptions = [],
  router,
  report,
  params: { accountId, subscriptionId }
}: SubscriptionsProps) {
  useFetchAllRecords(SUBSCRIPTION_TYPE, INCLUDES_SUBSCRIPTION)
  useFetchAllRecords('rate', INCLUDES_TIERS)

  const accountSubscriptions = useMemo(() => (
    allSubscriptions.filter(subscription => (
      subscription?.relationships?.account?.data?.id === accountId
    ))
  ), [allSubscriptions, accountId])

  return (
    <Layout.MasterMasterDetail>
      <AdvancedSelect
        data-testid='subscriptions-accounts-select'
        expandHeight
        data={listAccounts(accounts)}
        sortBy='name'
        selected={accountId}
        highlightSearches
        onClick={({ key, attributes }: any) => {
          if (report?.attributes?.depth === attributes.level) {
            router.push(PATH + key)
          }
        }}
        onNormalize={(accounts: any) => {
          normalized(
            accounts,
            allSubscriptions.map(item => {
              if (item?.relationships && item?.relationships?.account?.data) {
                return item.relationships.account.data.id
              }
              return null
            }))
        }}>
        <AdvancedSelectColumn
          field='name'
          expandable
          searchable
          width={50}
          cellRenderer={({ rowData: account }: any) => (
            <Cell leaf={report?.attributes?.depth === account.attributes.level}>
              {account.attributes.name}
            </Cell>
          )}
          label={translate('Accounts')} />
      </AdvancedSelect>
      <AdvancedSelect
        key={accountId}
        testId='subscriptions-select'
        data={listSubscriptions(accountSubscriptions)}
        selected={subscriptionId}
        toolbar={
          <Button.Create
            data-testid='subscriptions-create-button'
            small
            disabled={!accountId}
            onClick={() => router.push(PATH + accountId + '/new')} />
        }
        expandHeight
        onClick={({ id }: any) => router.push(PATH + accountId + '/' + id)}>
        <AdvancedSelectColumn
          field='name'
          width={50}
          label={translate('Subscriptions')} />
      </AdvancedSelect>
      <SubscriptionsDetail
        placeholder={!accountId
          ? translate('Select a leaf account')
          : translate('Select a subscription')
        }
        subscriptionId={subscriptionId}
        accountId={accountId} />
    </Layout.MasterMasterDetail>
  )
}
