import { translate } from '@exivity/translations'

import { ServiceInterval } from '../../../data/types'

const intervalLabels = [
  { value: ServiceInterval.Individually, label: 'Individually' },
  { value: ServiceInterval.Day, label: 'Daily' },
  { value: ServiceInterval.Month, label: 'Monthly' }
]

export const SERVICE_INTERVAL_LABELS = {
  [ServiceInterval.Individually]: () => translate('Individually'),
  [ServiceInterval.Day]: () => translate('Daily'),
  [ServiceInterval.Month]: () => translate('Monthly')
} as const

export default intervalLabels
