import { isEmpty, unfold } from '@exivity/fp';
var Parser = function (transformer) { return transformer; };
var match = function (regex, target) {
    try {
        return isEmpty(target)
            ? null
            : target.match(new RegExp(regex, 'i'));
    }
    catch (_a) {
        return null;
    }
};
export var replacer = function (regex, replacer) { return function (text) {
    var parse = Parser(function (state) {
        var target = state.target, count = state.count;
        var matches = match(regex, target);
        if (matches) {
            var index = target.indexOf(matches[0]);
            return {
                target: target.slice(index + matches[0].length),
                count: count + 1,
                result: index > 0
                    ? ([
                        target.slice(0, index),
                        replacer(matches[0], count)
                    ])
                    : [replacer(matches[0], count)]
            };
        }
        else {
            return {
                count: count,
                target: '',
                result: isEmpty(target)
                    ? null
                    : [target]
            };
        }
    });
    var initState = parse({
        target: text,
        count: 0,
        result: []
    });
    return unfold(function (state) {
        var nextState = parse(state);
        return state.result
            ? [state.result, nextState]
            : false;
    }, initState).flat();
}; };
