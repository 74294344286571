import PropTypes from 'prop-types'

export const width = {
  propType: PropTypes.oneOfType([
    PropTypes.oneOf([
      'auto', 'expand',
      'xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge',
      '1-1',
      '1-2',
      '1-3', '2-3',
      '1-4', '2-4', '3-4',
      '1-5', '2-5', '3-5', '4-5',
      '1-6', '2-6', '3-6', '4-6', '5-6'
    ]),
    PropTypes.string,
    PropTypes.array
  ]),

  toClass: (width) => {
    if (typeof width === 'string') {
      return 'uk-width-' + width
    }
    if (typeof width === 'object') {
      return width.map(item => 'uk-width-' + item).join(' ')
    }
  }
}
