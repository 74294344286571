import { Record, RecordRelationship, RecordHasOneRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

export interface WorkflowStepLogModel extends Record {
  type: 'workflowsteplog'
  attributes: WorkflowStepLogAttributes
  relationships?: WorkflowStepLogRelationships
}

export enum WorkflowStepLogStatus {
  Started = 'started',
  Success = 'success',
  Failed = 'failed',
  TimedOut = 'timed_out',
  Invalid = 'invalid',
  InternalError = 'internal_error'
}

export interface WorkflowStepLogAttributes {
  start_date: string
  end_date: string
  status: WorkflowStepLogStatus
  exit_code: number
  log: string
  output: string
  logfile: string
}

export interface WorkflowStepLogRelationships extends Dict<RecordRelationship> {
  run: RecordHasOneRelationship
  step: RecordHasOneRelationship
}
