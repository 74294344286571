import { PayloadAction } from '@reduxjs/toolkit'

import { ReportFilterState } from '../'

import { Report } from './'

export const includeInSummary = {
  prepare: (report: Report, include: boolean) => ({
    payload: {
      report,
      include
    }
  }),
  reducer (
    filters: ReportFilterState,
    action: PayloadAction<{ report: Report; include: boolean }>
  ) {
    filters[action.payload.report].includedInSummary = action.payload.include
  }
}

export const updateSummaryConsolidated = (
  filters: ReportFilterState,
  action: PayloadAction<boolean>
) => {
  filters.summary.consolidated = action.payload
}
