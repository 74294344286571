import React from 'react'
import { Field, Label, Placeholder, Select } from '@exivity/ui'
import { useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { Link, getPathname } from '@exivity/routing'

import { RootState } from '../../../../../../reducers'
import { app } from '../../../../../../routes'

import { Shared } from './shared'
import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

function RunTransformerHeader ({ options }: HeaderProps) {
  return <>{options.name}</>
}

function RunTransformerOptions ({
  options,
  updater
}: OptionsProps) {
  const transformers = useSelector((state: RootState) => state.datasources.transformers.data || [])

  if (!transformers.length) {
    return (
      <Placeholder>
        {translate("Unable to configure 'Run transformer' step. No transformers defined.")}{' '}
        <Link to={getPathname(app.routes.datapipelines.routes.transformers) + '/new'}>
          {translate('Create a new transformer.')}
        </Link>
      </Placeholder>
    )
  }

  return (
    <>
      <Field>
        <Label>{translate('Transformer')}</Label>
        <Select
          required
          searchable
          value={options.name}
          valueAccessor={transformer => transformer.name}
          labelAccessor={transformer => transformer.name}
          data={transformers}
          onChange={updater.setProperty('name')} />
      </Field>

      <Shared.Environment
        environmentId={options.environment_id}
        onChangeEnvironmentId={updater.setProperty('environment_id')} />

      <Shared.Offsets
        fromDate={options.from_date}
        toDate={options.to_date}
        onChangeFromDate={updater.setProperty('from_date')}
        onChangeToDate={updater.setProperty('to_date')} />
    </>
  )
}

export const RunTransformer = {
  Header: RunTransformerHeader,
  Options: RunTransformerOptions
} as WorkflowStepVariation
