import { useParams } from '@exivity/routing'
import {
  ServiceChargeType,
  ServiceCogsType,
  useCacheQuery,
  Resources,
  queries
} from '@exivity/data-layer'
import { translate } from '@exivity/translations'

function hasTieredChargeType (service: Resources['service']) {
  return service.attributes.charge_type === ServiceChargeType.ManualTieredInherited
    || service.attributes.charge_type === ServiceChargeType.ManualTieredStandard
}

function hasTieredCogsType (service: Resources['service']) {
  return service.attributes.cogs_type === ServiceCogsType.ManualTieredInherited
    || service.attributes.cogs_type === ServiceCogsType.ManualTieredStandard
}

function isServiceTiered (service: Resources['service']) {
  return hasTieredChargeType(service) || hasTieredCogsType(service)
}

function isServiceTieredChargeAndCogs (service: Resources['service']) {
  return hasTieredChargeType(service) && hasTieredCogsType(service)
}

function hasChargeModel (service: Resources['service']) {
  return service.attributes.charge_type !== ServiceChargeType.None
  && service.attributes.charge_type !== ServiceChargeType.Other
  && !hasTieredChargeType(service)
}

function hasCogsModel (service: Resources['service']) {
  return service.attributes.cogs_type !== ServiceCogsType.None
    && !hasTieredCogsType(service)
}

const chargeLabels = {
  [ServiceChargeType.None]: () => translate('None'),
  [ServiceChargeType.Manual]: () => translate('Manual'),
  [ServiceChargeType.Automatic]: () => translate('Automatic'),
  [ServiceChargeType.ManualTieredStandard]: () => translate('Tiered (standard)'),
  [ServiceChargeType.ManualTieredInherited]: () => translate('Tiered (inherited)'),
  [ServiceChargeType.Other]: () => translate('Other'),
  [ServiceChargeType.DeprecatedAutomatic]: () => translate(''),
  [ServiceChargeType.DeprecatedAutomaticPerInterval]: () => translate(''),
  [ServiceChargeType.DeprecatedManual]: () => translate(''),
  [ServiceChargeType.DeprecatedManualPerInterval]: () => translate('')
}

const cogsLabels = {
  [ServiceCogsType.None]: () => translate('None'),
  [ServiceCogsType.Manual]: () => translate('Manual'),
  [ServiceCogsType.Automatic]: () => translate('Automatic'),
  [ServiceCogsType.ManualTieredStandard]: () => translate('Tiered (standard)'),
  [ServiceCogsType.ManualTieredInherited]: () => translate('Tiered (inherited)'),
  [ServiceCogsType.DeprecatedManualPerInterval]: () => translate(''),
  [ServiceCogsType.DeprecatedAutomaticPerInterval]: () => translate('')
}

export function useSelectedService () {
  const [{ serviceId }] = useParams()
  return useCacheQuery(queries.find('service', serviceId))
}

export function useSelectedDset () {
  const [{ serviceId }] = useParams()
  return useCacheQuery(queries.relationshipOf('service', serviceId, 'dset'))
}

export const getServiceBusinessModels = (service: Resources['service']|null) => ({
  regular: {
    chargeOrCogs: service && (hasChargeModel(service) || hasCogsModel(service))
  },
  tiered: {
    charge: service && hasTieredChargeType(service),
    cogs: service && hasTieredCogsType(service),
    chargeOrCogs: service && isServiceTiered(service),
    chargeAndCogs: service && isServiceTieredChargeAndCogs(service)
  },
  chargeLabel: service
    ? chargeLabels[service.attributes.charge_type]()
    : '',
  cogsLabel: service
    ? cogsLabels[service.attributes.cogs_type]()
    : ''
})

export function useServiceBusinessModels () {
  const service = useSelectedService()

  return getServiceBusinessModels(service)
}
