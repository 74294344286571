import {
  Record,
  RecordRelationship,
  RecordHasManyRelationship,
  RecordHasOneRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

const DSET_TYPE = 'dset'

export interface DsetModel extends Record {
  type: typeof DSET_TYPE
  attributes: DsetAttributes
  relationships?: DsetRelationships
}

export type RDFStatus = {
  date: string
  created: string
  updated: string
}

export interface DsetAttributes {
  earliest_rdf: string
  latest_rdf: string
  ref_count: number
  rdf_detail?: RDFStatus[]
  columns?: string[]
}

export interface DsetRelationships extends Dict<RecordRelationship> {
  reports: RecordHasManyRelationship
  services: RecordHasManyRelationship
  metadatadefinition: RecordHasOneRelationship
}

export const DEFAULT_DSET: DsetModel = {
  id: '',
  type: DSET_TYPE,
  attributes: {
    earliest_rdf: '',
    latest_rdf: '',
    ref_count: 0

  }
}

export const createDset = makeCreateModel<DsetModel>(DSET_TYPE, DEFAULT_DSET.attributes)
