import { JSONAPISerializer } from '@orbit/jsonapi'

// Create our own JSONAPISerializer
class ExivityJSONAPISerializer extends JSONAPISerializer {
  // Override methods because we don't want any transformations to happen,
  // to keep processing overhead to a minimum.
  recordType (resourceType) {
    return resourceType
  }

  recordAttribute (type, resourceAttribute) {
    return resourceAttribute
  }

  recordRelationship (type, resourceRelationship) {
    return resourceRelationship
  }

  resourceType (type) {
    return type
  }

  resourceAttribute (type, attr) {
    return attr
  }

  resourceRelationship (type, relationship) {
    return relationship
  }
}

export default ExivityJSONAPISerializer
