import { createListChain } from './List/ListChain';
import { createObjectChain } from './Object/ObjectChain';
import { createJsonApiObjectChain } from './JsonApiObject/JsonApiObjectChain';
export function createUpdater(data, updateFn) {
    if (Array.isArray(data)) {
        return createListChain(data, updateFn);
    }
    else if ('attributes' in data) {
        return createJsonApiObjectChain(data, updateFn);
    }
    else {
        return createObjectChain(data, updateFn);
    }
}
