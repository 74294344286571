import { FC } from 'react'
import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'
import compose from 'lodash/fp/compose'

import { UserGroups } from './UserGroups'

const mapRecordsToProps = {
  userGroups: (q: QueryBuilder) => q.findRecords('usergroup')
}

export default compose(
  withData(mapRecordsToProps)
)(UserGroups) as unknown as FC
