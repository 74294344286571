import React, { useState } from 'react'
import { Layout as OrigLayout, Responsive, ResponsiveProps, WidthProvider } from 'react-grid-layout'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { Button, Toolbar } from '@exivity/ui'
import { filter, propSatisfies, flip, includes, evolve } from '@exivity/fp'
import { UserGroupPermission, useUserHasPermission } from '@exivity/data-layer'

import { profileActions, profileSelectors } from '../../../myProfile/state'

import WorkflowStatus from './Widgets/WorkflowStatusWidget'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

export const WIDGET_GRID_ROW_HEIGHT = 120

const widgetMap = {
  WorkflowStatus: { Widget: WorkflowStatus }
}

type Widgets = keyof typeof widgetMap

export type Layout = Omit<OrigLayout, 'i'> & {
  i: Widgets
}

export type Layouts = {
  sm: Layout[]
  md: Layout[]
  lg: Layout[]
}

type WidgetResponsiveProps = Omit<
  ResponsiveProps,
  'onLayoutChange'|'onBreakpointChange'
  > & {
  onLayoutChange?: (layout: Layout[], layouts: Layouts) => void
  onBreakpointChange?: (breakpoint: Breakpoint, newCols: number) => void
}

const ResponsiveGridLayout = WidthProvider<WidgetResponsiveProps>(Responsive)

const widgetNames = Object.keys(widgetMap) as Widgets[]

export const validateUserLayoutState = (
  layouts: Layouts,
  widgets: Widgets[] = widgetNames
) => {
  const validateWidgets = filter(propSatisfies(flip(includes)(widgets), 'i'))

  const transformations = {
    sm: validateWidgets,
    md: validateWidgets,
    lg: validateWidgets
  } as const

  return evolve(transformations, layouts) as Layouts
}

const renderWidget = (
  layout: Layout
) => {
  const { Widget } = widgetMap[layout.i]

  return (
    <div key={layout.i}>
      <Widget />
    </div>
  )
}

export type Breakpoint = 'lg' | 'md' | 'sm'

export const WidgetGrid = () => {
  const [edit, setEdit] = useState(false)
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('lg')

  const layouts = useSelector(profileSelectors.getDashboardWidgetLayout)
  const dispatch = useDispatch()

  const toggleEdit = () => setEdit(!edit)

  // Right now we only have Workflow widget and as such when no permission
  // we can just not render entire component. When we add widgets we will have to permission check
  // layouts first
  if (!useUserHasPermission([UserGroupPermission.ManageWorkflows])) return null

  return (
    <div>
      <Toolbar>
        <Toolbar.Left />
        <Toolbar.Right>
          <Button outlined transparent tiny secondary={!edit} success={edit} onClick={toggleEdit}>
            {edit
              ? translate('Save layout')
              : translate('Edit layout')
            }
          </Button>
        </Toolbar.Right>
      </Toolbar>
      <ResponsiveGridLayout
        breakpoints={{
          lg: 1500,
          md: 750,
          sm: 0
        }}
        cols={{
          lg: 4,
          md: 2,
          sm: 1
        }}
        rowHeight={WIDGET_GRID_ROW_HEIGHT}
        isDraggable={edit}
        isResizable={edit}
        layouts={layouts}
        margin={[20, 20]}
        containerPadding={[0, 0]}
        onLayoutChange={(_, layouts) => {
          dispatch(profileActions.updateWidgetLayout(layouts))
        }}
        onBreakpointChange={setBreakpoint}>
        {layouts[breakpoint].map(renderWidget)}
      </ResponsiveGridLayout>
    </div>
  )
}
