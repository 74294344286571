import { jsx as _jsx } from "react/jsx-runtime";
import { MdMoreHoriz } from 'react-icons/md';
import { Badge } from '../Badge';
export var renderIcon = {
    hasActiveChildren: function (_a) {
        var hasActiveChildren = _a.hasActiveChildren;
        return hasActiveChildren
            ? _jsx(MdMoreHoriz, { "data-testid": 'has-active-children' }, void 0)
            : null;
    },
    badge: function (cb) {
        return function (_a) {
            var item = _a.item;
            return _jsx(Badge, { children: cb(item) }, void 0);
        };
    }
};
