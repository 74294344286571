import { useMemo, useState, useEffect } from 'react';
import { listToTreeList } from '../utils/tree';
import { createHelpers, enrichTreeItems, getVisibleItems } from './helpers';
export function useExpandable(data, keyAccessor, parentKeyAccessor, initialExpandedKeys) {
    var _a = useState(initialExpandedKeys || []), expanded = _a[0], setExpanded = _a[1];
    useEffect(function () {
        initialExpandedKeys && setExpanded(initialExpandedKeys);
    }, []);
    var treeList = useMemo(function () { return listToTreeList(data, keyAccessor, parentKeyAccessor); }, [data]);
    return useMemo(function () {
        var expandableList = enrichTreeItems(treeList, keyAccessor, expanded, setExpanded);
        return [
            getVisibleItems(expandableList, keyAccessor, expanded),
            createHelpers(keyAccessor, expanded, setExpanded)
        ];
    }, [treeList, expanded]);
}
