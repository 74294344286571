import { schema } from '@exivity/data-layer'
import { browserHistory } from '@exivity/routing'

import { addRecord, updateRecord, removeRecord } from '../../utils/data'
import store from '../../store'
import { workThunks } from '../../domains/work/thunks'
import { modal } from '../../utils/gui'

export const confirm = (text) => new Promise((resolve) => {
  modal(text)
    .then(() => resolve(true))
    .catch(() => resolve(false))
})

export const extensions = {
  router: browserHistory,
  dispatch: (...args) => store.dispatch(...args),
  errorMessage: (message) => store.dispatch(workThunks.showErrorMessage(message)),
  successMessage: (message) => store.dispatch(workThunks.showSuccessMessage(message)),
  confirm
}

export const crudFunctions = {
  createRecord: ({ type, attributes, relationships = {} }, options) => (
    addRecord(
      type,
      schema.removeReadOnlyAttributes(type, attributes),
      relationships,
      options
    )
  ),
  updateRecord: ({ type, id, attributes, relationships = {} }, options) => (
    updateRecord(
      type,
      id,
      schema.removeReadOnlyAttributes(type, attributes),
      relationships,
      options)
  ),
  deleteRecord: ({ type, id }, options) => (
    removeRecord(type, id, options)
  ),
  extensions
}

export default crudFunctions
