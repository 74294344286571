import Orbit, { buildTransform } from '@orbit/data'
import JSONAPISource from '@orbit/jsonapi'

import reduxStore from '../../store/index'
import { workThunks } from '../../domains/work/thunks'
import { JSONAPIResources } from '../../API/requests/JSONAPIResources'

// Assign fetch to Orbit.fetch
Orbit.fetch = (url, settings) => {
  return JSONAPIResources(
    url,
    settings
  )
}

// Create our own JSONAPISource
class ExivityJSONAPISource extends JSONAPISource {
  // A helper method for adding records
  addRecord (record) {
    const request = {
      op: 'addRecord',
      record: record
    }

    const { serializer, urlBuilder } = this.requestProcessor

    const { relationships = {} } = record
    const requestDoc = serializer.serialize({ data: record })
    const settings = this.requestProcessor
      .buildFetchSettings(request, { method: 'POST', json: requestDoc })

    return this.requestProcessor.fetch(urlBuilder.resourceURL(record.type), settings)
      .then((raw) => {
        const responseDoc = serializer.deserialize(raw)
        const addedRecord = responseDoc.data
        // Put back the relationships for this record
        addedRecord.relationships = relationships

        const operations = [this.transformBuilder.addRecord(addedRecord)]

        return [buildTransform(operations)]
      })
      .then(transforms => this.transformed(transforms))
  }

  handleFetchError (e) {
    reduxStore.dispatch(workThunks.showErrorMessage('Error fetching data.'))

    return super.handleFetchError(e)
  }
}

export default ExivityJSONAPISource
