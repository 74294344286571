import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { translate } from '@exivity/translations'
import {
  Text,
  Button,
  Searchbar,
  useWindowScroller,
  WindowScroller,
  Collections
} from '@exivity/ui'
import {
  sort as sortFn,
  ascend,
  descend,
  prop,
  split,
  join,
  reverse,
  pipe
} from '@exivity/fp'

import { caseInsensitiveIncludes } from '../../../../utils/misc'

const { Headers, Columns } = Collections

export interface Status {
  date: string
  day: string
  lastModified?: string
}

interface StatusTableProps<T> {
  statuses: T[]
  onDelete: (item: T) => void
}

type StatusRowProps = {
  data: {
    items: Status[]
    daySearch: string
    lastModifiedSearch: string
    onDelete: (status: Status) => void
  }
} & ListChildComponentProps

const styles = {
  columns: {
    maxWidth: 750,
    info: '45%',
    action: '10%'
  },
  list: { height: '100%important!' }
}

const MissingBadge = styled(Text).attrs({
  color: 'orange',
  fontSize: 1,
  children: 'Missing'
})``

const StatusRow = ({ index, data, style }: StatusRowProps) => {
  const status = data.items[index]

  return (
    <Columns style={style} maxWidth={styles.columns.maxWidth}>
      <Columns.Column
        width={styles.columns.info}
        searchTerm={[data.daySearch]}>
        {status.day}
      </Columns.Column>
      <Columns.Column
        width={styles.columns.info}
        searchTerm={[data.lastModifiedSearch]}>
        {status.lastModified ? status.lastModified : <MissingBadge />}
      </Columns.Column>
      <Columns.Column width={styles.columns.action}>
        <Button.Delete tiny round onClick={() => data.onDelete(status)} />
      </Columns.Column>
    </Columns>
  )
}

type SortState = {
  by: 'day'|'lastModified'
  asc: boolean
}

export function StatusTable<T extends Status> ({ statuses, onDelete }: StatusTableProps<T>) {
  const [onList, onScroller, onScroll] = useWindowScroller()
  const [daySearch, setDaySearch] = useState('')
  const [lastModifiedSearch, setLastModifiedSearch] = useState('')
  const [sort, setSort] = useState<SortState>({ by: 'day', asc: true })

  const itemData = useMemo(() => {
    const direction = sort.asc
      ? ascend
      : descend

    const compare = pipe(
      prop<keyof Status, string>(sort.by),
      split('-'),
      reverse,
      join('')
    )

    const sortByLastModified = sortFn(direction(compare))

    const searched = statuses
      .filter((item) => (
        caseInsensitiveIncludes(item.day, daySearch)
        && item.lastModified
        && caseInsensitiveIncludes(item.lastModified, lastModifiedSearch)
      )) as Required<Status>[]

    return ({
      items: sortByLastModified(searched),
      onDelete,
      daySearch,
      lastModifiedSearch
    })
  }, [statuses, daySearch, lastModifiedSearch, sort, onDelete])

  return (
    <div>
      <Headers maxWidth={styles.columns.maxWidth}>
        <Headers.Header width={styles.columns.info}>
          <Searchbar placeholder={translate('day')}
            value={daySearch}
            onChange={setDaySearch} />
          <Headers.Header.SortButton name='day' sort={sort} onClick={setSort} />
        </Headers.Header>
        <Headers.Header width={styles.columns.info}>
          <Searchbar placeholder={translate('last modified')}
            value={lastModifiedSearch}
            onChange={setLastModifiedSearch} />
          <Headers.Header.SortButton name='lastModified' sort={sort} onClick={setSort} />
        </Headers.Header>
        <Headers.Header width={styles.columns.action} />
      </Headers>
      <WindowScroller ref={onScroller} onScroll={onScroll}>{(p) => (
        <FixedSizeList
          ref={onList}
          itemSize={30}
          height={p.height}
          width='100%'
          itemData={itemData}
          style={styles.list}
          itemCount={itemData.items.length}>
          {StatusRow}
        </FixedSizeList>
      )}
      </WindowScroller>
    </div>
  )
}
