import React from 'react'
import { CrudRecord } from 'react-crud-hook'
import { Group, Checkbox, Radio, Field, Label, Alert } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { queries, useCacheQueryFn } from '@exivity/data-layer'

import {
  ServiceModel,
  ServiceChargeType,
  ServiceProrationType,
  isServiceTiered, ServiceCogsType
} from '../../../../data/types'
import {
  DeprecatePerIntervalCogsTypeWarning,
  DeprecatePerIntervalRateTypeWarning
} from '../../../../deprecations/DeprecatePerIntervalRateAndCogsTypeWarning'
import { useCanRenderFeature } from '../../../../components/organisms/Feature/Feature'

import { groupIcon } from './groupIcon'

interface FieldProps {
  service: CrudRecord<ServiceModel>
  disabled: boolean
}

function UseProration ({ service, disabled, ...rest }: FieldProps) {
  return (
    <Field {...rest}>
      <Label>
        {translate('Use proration')}
      </Label>
      <Checkbox
        label={translate('Enabled')}
        disabled={disabled}
        checked={service.attributes.proration_type === ServiceProrationType.Full}
        onClick={() => service.setAttribute(
          'proration_type',
          (service.attributes.proration_type === ServiceProrationType.Full)
            ? ServiceProrationType.None
            : ServiceProrationType.Full
        )} />
    </Field>
  )
}

interface BillingGroupProps {
  service: CrudRecord<ServiceModel>
  editing?: boolean
}

export function useDidBillingTypesChangeFn () {
  const query = useCacheQueryFn()

  return (service: CrudRecord<ServiceModel>) => {
    const oldService = query(queries.find('service', service))
    return !!oldService
    && (
      // @ts-ignore
      oldService.attributes.charge_type === service.attributes.charge_type
      // @ts-ignore
      && oldService.attributes.cogs_type === service.attributes.cogs_type
    )
  }
}

function BillingWarning ({ service }: { service: CrudRecord<ServiceModel> }) {
  const billingTypeChanged = useDidBillingTypesChangeFn()

  if (billingTypeChanged(service)) {
    return null
  }

  return (
    <Alert title={translate('When changing the charge/COGS type, keep the following in mind:')} warning>
      <ul>
        <li>{translate('All related rates will be removed')}</li>
        <li>{translate('Adjustments are not supported for tiered services')}</li>
      </ul>
    </Alert>
  )
}

export default function BillingGroup ({ service, editing = true }: BillingGroupProps) {
  const canRenderFeature = useCanRenderFeature()

  const cogsTypeFollowChargeType = service
    .attributes.charge_type === ServiceChargeType.ManualTieredStandard
    || service.attributes.charge_type === ServiceChargeType.ManualTieredInherited

  return (
    <Group title={translate('Billing')} Icon={groupIcon(editing)}>
      <Field.Container>
        <BillingWarning service={service} />
        <DeprecatePerIntervalRateTypeWarning chargeType={service.attributes.charge_type} />
        <Field>
          <Label>
            {translate('Charge type')}
          </Label>
          {canRenderFeature('rateTiering')
            ? (
              <Radio.Group
                disabled={!editing}
                value={service.attributes.charge_type}
                onChange={(chargeType) => {
                  service.setAttribute('charge_type', chargeType)

                  if (service.attributes.cogs_type === ServiceCogsType.None) return null

                  if (chargeType === ServiceChargeType.ManualTieredStandard) {
                    service.setAttribute('cogs_type', ServiceCogsType.ManualTieredStandard)
                  } else if (chargeType === ServiceChargeType.ManualTieredInherited) {
                    service.setAttribute('cogs_type', ServiceCogsType.ManualTieredInherited)
                  } else if (cogsTypeFollowChargeType) {
                    service.setAttribute('cogs_type', ServiceCogsType.Automatic)
                  }
                }}>
                <Radio
                  label={translate('Manual')}
                  value={ServiceChargeType.Manual} />

                <Radio
                  label={translate('Automatic')}
                  value={ServiceChargeType.Automatic} />

                <Radio
                  label={translate('Tiered (standard)')}
                  value={ServiceChargeType.ManualTieredStandard} />

                <Radio
                  label={translate('Tiered (inherited)')}
                  value={ServiceChargeType.ManualTieredInherited} />

                <Radio
                  label={translate('Other')}
                  value={ServiceChargeType.Other}
                  disabled />

              </Radio.Group>
            )
            : (
              <Radio.Group
                disabled={!editing}
                value={service.attributes.charge_type}
                onChange={service.setAttribute('charge_type')}>
                <Radio
                  label={translate('Manual')}
                  value={ServiceChargeType.Manual} />

                <Radio
                  label={translate('Automatic')}
                  value={ServiceChargeType.Automatic} />

                <Radio
                  label={translate('Other')}
                  value={ServiceChargeType.Other}
                  disabled />

              </Radio.Group>
            )}
        </Field>
        {!isServiceTiered(service) && <UseProration service={service} disabled={!editing} />}
        <DeprecatePerIntervalCogsTypeWarning cogsType={service.attributes.cogs_type} />
        <Field data-testid='service-cogs-type'>
          <Label>
            {translate('COGS type')}
          </Label>
          {canRenderFeature('rateTiering')
            ? (
              <Radio.Group
                disabled={!editing}
                value={service.attributes.cogs_type}
                onChange={service.setAttribute('cogs_type')}>
                <Radio
                  label={translate('Disabled')}
                  value={ServiceCogsType.None} />

                <Radio
                  disabled={cogsTypeFollowChargeType}
                  label={translate('Manual')}
                  value={ServiceCogsType.Manual} />

                <Radio
                  disabled={cogsTypeFollowChargeType}
                  label={translate('Automatic')}
                  value={ServiceCogsType.Automatic} />

                <Radio
                  disabled={service
                    .attributes.charge_type !== ServiceChargeType.ManualTieredStandard}
                  label={translate('Tiered (standard)')}
                  value={ServiceCogsType.ManualTieredStandard} />

                <Radio
                  disabled={service
                    .attributes.charge_type !== ServiceChargeType.ManualTieredInherited}
                  label={translate('Tiered (inherited)')}
                  value={ServiceCogsType.ManualTieredInherited} />
              </Radio.Group>
            )
            : (
              <Radio.Group
                disabled={!editing}
                value={service.attributes.cogs_type}
                onChange={service.setAttribute('cogs_type')}>
                <Radio
                  label={translate('Disabled')}
                  value={ServiceCogsType.None} />

                <Radio
                  label={translate('Manual')}
                  value={ServiceCogsType.Manual} />

                <Radio
                  label={translate('Automatic')}
                  value={ServiceCogsType.Automatic} />
              </Radio.Group>
            )
          }
        </Field>
      </Field.Container>
    </Group>
  )
}
