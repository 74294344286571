import {
  Record,
  RecordRelationship,
  RecordHasOneRelationship,
  RecordHasManyRelationship
} from '@orbit/data'
import { Dict } from '@orbit/utils'

export const METADATA_TYPE = 'metadata'

export interface MetadataModel extends Record {
  type: typeof METADATA_TYPE
  attributes: MetadataAttributes
  relationships?: MetadataRelationships
}

export type MetadataValue = {
  [name: string]: string
}

export interface MetadataAttributes {
  values: MetadataValue
}

export interface MetadataRelationships extends Dict<RecordRelationship> {
  definition: RecordHasOneRelationship
  accounts: RecordHasManyRelationship
}

export const DEFAULT_METADATA: MetadataModel = {
  id: '',
  type: METADATA_TYPE,
  attributes: {
    values: {}
  }
}
