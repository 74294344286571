import { get, patch } from '../../API'
import ClassAction, { createClassAction } from '../../store/middleware/ClassAction'
import { ServicesState } from '../../reducers/system/services'
import { ActionDispatcher } from '../../store/utils'

export class UpdateConfiguration extends ClassAction {
  static readonly type = 'SYSTEM_SERVICES_CHANGE_CONFIGURATION'
  readonly type = UpdateConfiguration.type
  constructor (public configuration: ServicesState) {
    super()
  }
}

export const updateConfiguration = createClassAction(UpdateConfiguration)

export function fetchSystemConfiguration (): ActionDispatcher {
  return dispatch => {
    get('/system/services').then(data => dispatch(updateConfiguration(data.configuration)))
  }
}

export function configurationPersisted (
  response: {configuration: ServicesState}
): ActionDispatcher {
  return dispatch => {
    dispatch(updateConfiguration(response.configuration))
  }
}

export function persistConfiguration (): ActionDispatcher {
  return (dispatch, getState) => {
    patch(
      '/system/services',
      {},
      { configuration: getState().system.services }
    )
      .then(data => dispatch(configurationPersisted(data)))
  }
}
