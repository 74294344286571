import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'
import { MdGroup } from 'react-icons/md'

import { featureFlags } from '../application/config/features'

const Overview = lazy(() => import(
  /* webpackChunkName: 'accounts.overview' */ '../components/pages/Accounts/Overview'))

const Budgets = lazy(() => import(
  /* webpackChunkName: 'accounts.budgets' */ '../domains/budget/pages'))

export const accounts = {
  path: '/accounts',
  requiresPermissions: [UserGroupPermission.ManageAccounts],
  meta: {
    title: () => translate('Accounts'),
    icon: MdGroup
  },
  routes: {
    overview: {
      path: '/overview/:id?',
      meta: {
        title: () => translate('Overview')
      },
      component: Overview
    },
    budgets: {
      path: '/budgets/:id?/:revisionId?',
      meta: {
        title: () => translate('Budgets'),
        documentation: 'https://docs.exivity.com/accounts/budgets',
        feature: featureFlags.budgets
      },
      component: Budgets
    }
  }
}
