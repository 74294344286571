import React, { useState } from 'react'
import { Delay } from '@exivity/ui'
import { useNavigate, getPathname } from '@exivity/routing'

import { isDevEnvironment } from '../../../../utils/system'
import LoadingOverlay from '../../../../components/molecules/LoadingOverlay'
import Form, { TextInput, Checkbox } from '../../../../components/molecules/Form'
import Button from '../../../../components/atoms/Button'
import { DomainChanger } from '../DomainChanger/DomainChanger'
import { app } from '../../../../routes'

import './Forms.css'

interface UsernamePasswordProps {
  apiRoot: string
  isLoading: boolean
  persistentAllowed?: boolean
  persistenceInterval: string | null
  setPersistentSession: (persistent: boolean) => void
  resetPasswordSupported?: boolean
  onDomainChanged: (apiRoot: string) => void
  onSubmit: (username: string, password: string, apiRoot: string) => void
  backToSSOButton: React.ReactElement|null
  onCancelLogin?: () => void
}

export function UsernamePassword ({
  apiRoot,
  persistentAllowed = false,
  persistenceInterval = '',
  setPersistentSession,
  resetPasswordSupported = false,
  onDomainChanged,
  onSubmit,
  isLoading,
  backToSSOButton,
  onCancelLogin
}: UsernamePasswordProps) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showDomain, setShowDomain] = useState(false)
  const navigate = useNavigate()

  return (
    <Form className='ex-login-form uk-background-default uk-position-relative uk-form-stacked'
      onSubmit={() => onSubmit(username, password, apiRoot)}>
      {isLoading && (
        <LoadingOverlay>
          {onCancelLogin && (
            <Delay wait={3000}>
              <Button className='ex-login-form__cancel' onClick={onCancelLogin}>
                Cancel
              </Button>
            </Delay>
          )}
        </LoadingOverlay>
      )}
      <TextInput required
        autoComplete='username'
        label='Username'
        size='large'
        name='username'
        placeholder='Username'
        testid='username'
        controlled
        onChange={(name: string) => setUsername(name)}>
        {username}
      </TextInput>
      <TextInput required
        autoComplete='current-password'
        label='Password'
        size='large'
        name='password'
        placeholder='Password'
        testid='password'
        controlled
        masked
        onChange={(p: string) => setPassword(p)}>
        {password}
      </TextInput>
      {persistentAllowed && (
        <div className='uk-margin'>
          <Checkbox
            name='persist'
            label={`Keep me logged in for ${persistenceInterval}`}
            onChange={event => setPersistentSession(event.target.checked)} />
        </div>
      )}
      <DomainChanger showDomain={showDomain} apiRoot={apiRoot}
        onDomainChanged={onDomainChanged} />
      <div>
        <Button submit icon='chevron-right' iconPosition='right'
          variation='secondary'>Login
        </Button>
        <div className='ex-login-form__links uk-float-right'>
          <Button
            variation='text'
            className='uk-float-right'
            onClick={() => setShowDomain(!showDomain)}>
            Change domain
          </Button>
          {resetPasswordSupported && (
            <Button
              variation='text'
              className='uk-float-right'
              onClick={() => navigate(getPathname(app.routes.resetPassword))}>
              Reset password
            </Button>
          )}
        </div>
      </div>

      {backToSSOButton}

      {isDevEnvironment() && (
        <div className='uk-margin-top'>
          <Button size='small'
            variation='muted'
            onClick={() => onSubmit('admin', 'exivity', apiRoot)}>Quick login
          </Button> <span className='uk-text-muted'>— dev only</span>
        </div>
      )}
    </Form>
  )
}
