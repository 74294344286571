var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { themePriority, themeGlobal, themePurpose, themeFontSize, themePalette, matchColorFromPurpose } from '../Theme';
import { injectIf } from '../utils';
import { Spinner } from '../Spinner';
import { Progress } from '../Progress';
import { AppBarButton } from './AppBarButton';
import { AppBarDropdown } from './AppBarDropdown';
import { AppBarLink } from './AppBarLink';
import { AppBarAvatar, Separator } from './AppBarAvatar';
import { AppBarSearch } from './AppBarSearch';
var Bar = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: ", "px;\n  z-index: ", ";\n  \n  font-family: ", ";\n  background-color: transparent;\n\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: ", "px;\n  z-index: ", ";\n  \n  font-family: ", ";\n  background-color: transparent;\n\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n"])), themeGlobal('headerHeight'), themePriority(5), themeGlobal('fontFamily'));
var AppIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-width: 70px;\n  max-width: 70px;\n  height: ", "px;\n\n  display: flex;\n  align-items: center;\n  justify-Content: center;\n  \n  circle {\n    stroke: white;\n  }\n"], ["\n  min-width: 70px;\n  max-width: 70px;\n  height: ", "px;\n\n  display: flex;\n  align-items: center;\n  justify-Content: center;\n  \n  circle {\n    stroke: white;\n  }\n"])), themeGlobal('headerHeight'));
var AppLogo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-Content: center;\n  \n  background-color: ", ";\n  height: ", "px;\n  min-width: 200px;\n  max-width: 200px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-Content: center;\n  \n  background-color: ", ";\n  height: ", "px;\n  min-width: 200px;\n  max-width: 200px;\n"])), themePalette('neutral', 900), themeGlobal('headerHeight'));
var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  margin: 0 ", "em;\n  overflow: hidden;\n  \n  ", "\n  \n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  font-size: ", ";\n  color: ", ";\n  margin: 0 ", "em;\n  overflow: hidden;\n  \n  ", "\n  \n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])), themeFontSize(3), themeGlobal('inverseBrandTextColor'), themeGlobal('baseSpacing'), injectIf('isMessage', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["font-size: ", ";"], ["font-size: ", ";"])), themeFontSize(2))));
var Options = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  color: ", ";\n  height: ", "px;\n"], ["\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  color: ", ";\n  height: ", "px;\n"])), themeGlobal('inverseBrandTextColor'), themeGlobal('headerHeight'));
var MessageBackground = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: ", "px;\n  z-index: ", ";\n  background-color: ", ";\n  transition: opacity 0.5s ease;\n  opacity: 0;\n  \n  &.appbar__message {\n    opacity: 1;\n    transition: opacity 0.5s ease;\n    \n    &--success {\n      /* background-color: ", "; */\n      background-color: ", ";\n    }\n    \n    &--danger {\n      background-color: ", ";\n    }\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: ", "px;\n  z-index: ", ";\n  background-color: ", ";\n  transition: opacity 0.5s ease;\n  opacity: 0;\n  \n  &.appbar__message {\n    opacity: 1;\n    transition: opacity 0.5s ease;\n    \n    &--success {\n      /* background-color: ", "; */\n      background-color: ", ";\n    }\n    \n    &--danger {\n      background-color: ", ";\n    }\n  }\n"])), themeGlobal('headerHeight'), themePriority(5), matchColorFromPurpose(500), themePurpose('success'), themePalette('success', 500), themePalette('danger', 500));
var Background = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: ", "px;\n  z-index: ", ";\n  \n  background-color: ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: ", "px;\n  z-index: ", ";\n  \n  background-color: ", ";\n"])), themeGlobal('headerHeight'), themePriority(5), themePalette('brand', 500));
// @todo Improve spinner animation -> is only 30fps
function AppBar(_a) {
    var className = _a.className, icon = _a.icon, logo = _a.logo, title = _a.title, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, _c = _a.progress, progress = _c === void 0 ? 0 : _c, message = _a.message, children = _a.children;
    var messageClassName = function (type) { return message && type === message.type
        ? "appbar__message appbar__message--" + message.type
        : ''; };
    var text = message
        ? message.text
        : title;
    return (_jsxs(_Fragment, { children: [_jsx(Background, {}, void 0), _jsx(MessageBackground, { success: true, className: messageClassName('success') }, void 0), _jsx(MessageBackground, { danger: true, className: messageClassName('danger') }, void 0), _jsxs(Bar, __assign({ className: className }, { children: [_jsx(AppIcon, { children: isLoading
                            ? _jsx(Spinner, {}, void 0)
                            : icon }, void 0), _jsx(AppLogo, { children: logo }, void 0), _jsx(Title, __assign({ title: text, isMessage: !!message }, { children: text }), void 0), _jsx(Options, { children: children }, void 0), _jsx(Progress, { active: isLoading, percentage: progress }, void 0)] }), void 0)] }, void 0));
}
AppBar.Button = AppBarButton;
AppBar.Dropdown = AppBarDropdown;
AppBar.Link = AppBarLink;
AppBar.Avatar = AppBarAvatar;
AppBar.Separator = Separator;
AppBar.Search = AppBarSearch;
export { AppBar };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
