var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import WebFont from 'webfontloader';
export function useWebFonts(fonts) {
    useEffect(function () {
        WebFont.load(__assign(__assign({}, fonts), { timeout: 0 }));
    }, [fonts]);
}
