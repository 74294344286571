import { mapValues } from 'lodash'

// import * as adjustments from './adjustments'
import * as auth from './auth'
import * as services from './services'
import * as dataset from './dataset'
import * as metadata from './metadata'
// import * as rates from './rates'
import * as subscriptions from './subscriptions'
import * as workflows from './workflows'
import * as users from './users'
import * as myProfile from './myProfile'

const allDomains = { auth, services, dataset, metadata, subscriptions, users, myProfile, workflows }

type DomainNames = keyof typeof allDomains

enum Parts {
  pages = 'pages',
  components = 'components'
}

export type Domain = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Parts.pages]?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Parts.components]?: any
}

type AllDomains = { [domainName in DomainNames]: Domain}

function combine<P extends Parts> (part: P, domains: AllDomains) {
  return mapValues<AllDomains, Domain[P]>(domains, (domain) => domain[part] || {})
}

export const pages = combine(Parts.pages, allDomains)
export const components = combine(Parts.components, allDomains)
