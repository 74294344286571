import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from '@exivity/translations'

import { workThunks } from '../../../work/thunks'
import { authSelectors, authThunks } from '../../state'
import { UserSource } from '../../../../data/types'

export function useCurrentUser () {
  const dispatch = useDispatch()
  const user = useSelector(authSelectors.getCurrentUser)

  const [username, setUsername] = useState(user?.attributes.username || '')
  const [display_name, setDisplayName] = useState(user?.attributes.display_name || '')
  const [email_address, setEmail] = useState(user?.attributes.email_address || '')
  const [password, setPassword] = useState('')
  const [current_password, setCurrentPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const userRef = useRef(user)
  const ref = useRef({
    username,
    display_name,
    email_address,
    current_password,
    password
  })

  // Put stuff in a reference so save function can be used in Modal at Profile/basic
  ref.current = {
    username,
    display_name,
    email_address,
    current_password,
    password
  }

  function save () {
    if (userRef.current && password === verifyPassword) {
      dispatch(
        authThunks.updateCurrentUser({
          type: 'user',
          id: userRef.current.id,
          attributes: {
            ...userRef.current.attributes,
            username: ref.current.username || undefined,
            display_name: ref.current.display_name || undefined,
            email_address: ref.current.email_address || undefined,
            current_password: ref.current.current_password,
            password: userRef.current.attributes.source === UserSource.LOCAL
              ? ref.current.password || undefined
              : undefined
          }
        })
      )
    } else {
      dispatch(workThunks.showErrorMessage(translate('Passwords do not match.')))
    }
  }

  if (!user) return null
  return {
    id: user.id,
    type: 'user',
    source: user.attributes.source,
    username,
    display_name,
    email_address,
    current_password,
    setCurrentPassword,
    setUsername,
    setDisplayName,
    setEmail,
    password,
    verifyPassword,
    setPassword,
    setVerifyPassword,
    save
  }
}
