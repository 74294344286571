import { QueryBuilder } from '@orbit/data'
import { withData } from 'react-orbitjs'
import { FC } from 'react'

import { insertIf } from '../../../../utils/misc'
import { USER_GROUP_TYPE } from '../../../../data/types'

import { UserGroup } from './UserGroup'

const mapRecordsToProps = ({ id }: { id?: string }) => ({
  ...insertIf(id, {
    userGroup: (q: QueryBuilder) => q.findRecord({ type: USER_GROUP_TYPE, id: id as string })
  })
})

export default withData(mapRecordsToProps)(UserGroup) as unknown as FC<{ id?: string }>
