import { useState } from 'react'

export function useToggle (initialValue: boolean | (() => boolean)) {
  const [value, setValue] = useState(initialValue)

  function toggleValue () {
    setValue((val) => !val)
  }

  return [value, toggleValue, setValue] as [boolean, () => void, (value: boolean) => void]
}
