import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { modal } from '../../../../utils/gui'
import { bulkDelete } from '../../state/actions'

import { ServiceWithCategory } from './listServices'

export function useBulkDelete (
  list: ServiceWithCategory[],
  router: any,
  params: Record<string, string>
) {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<string[]>([])

  const service = selected.length === 1
    ? 'service'
    : 'services'

  function deleteServices () {
    modal(`Are you sure you want to delete ${selected.length} ${service}?`)
      .then(() => {
        if (selected.includes(params.id)) {
          router.push('services/overview')
        }

        dispatch(bulkDelete(selected))
        setSelected([])
      })
  }

  return {
    selected,
    bulkDelete: deleteServices,
    setSelected (items: string[]) {
      setSelected(items)
    },
    selectAll () {
      setSelected(list.map((item: ServiceWithCategory) => item.key))
    },
    selectNone () {
      setSelected([])
    }
  }
}
