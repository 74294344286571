import { groupOther } from '../index'

import { makeBreadcrumb } from './helpers'

function accountBreadcrumb (parents = []) {
  return makeBreadcrumb(parents, { propAccessor: (item) => item.name, separator: ' < ' })
}

export default (report, maxSeries, valueType) => {
  if (!report) {
    return []
  }

  let accounts = {}

  report.forEach(item => {
    if (accounts[item.account_id]) {
      accounts[item.account_id] = {
        key: item.account_id,
        title: item.account,
        value: accounts[item.account_id].value + item[valueType],
        breadcrumb: accountBreadcrumb(item.parents)
      }
    } else {
      accounts[item.account_id] = {
        key: item.account_id,
        title: item.account,
        value: item[valueType],
        breadcrumb: accountBreadcrumb(item.parents)
      }
    }
  })

  accounts = Object.values(accounts)

  return groupOther(accounts, maxSeries)
}
