import { combineReducers } from 'redux'

import { reports } from './reports'
import logs, { LogsState } from './logs'

export interface DataState {
  reports: ReturnType<typeof reports>
  logs: LogsState
}

const reducer = combineReducers<DataState>({
  reports,
  logs
} as any)

export default reducer
