var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FixedSizeList } from 'react-window';
import { translate } from '@exivity/translations';
import { complement, isEmpty, length } from '@exivity/fp';
import AutoSizer from 'react-virtualized-auto-sizer';
import useResizeObserver from 'use-resize-observer';
import { Searchbar } from '../Searchbar';
import { useNodeList, ListContext } from './useNodeList';
import { StyledContainer } from './StyledContainer';
import { SelectOption } from './SelectOption';
import { Actions } from './Actions';
export var ITEM_HEIGHT = 30;
var isNotEmpty = complement(isEmpty);
export function isMultiple(args) {
    return Array.isArray(args);
}
var styles = {
    autoSizer: { minHeight: 300, height: '100%', width: '100%' },
    virtualList: { minHeight: 300 }
};
export function SelectList(_a) {
    var className = _a.className, actions = _a.actions, onClear = _a.onClear, _b = _a.clearText, clearText = _b === void 0 ? translate('Clear selection') : _b, _c = _a.noDataText, noDataText = _c === void 0 ? translate('No items in this list...') : _c, _d = _a.children, children = _d === void 0 ? SelectOption : _d, props = __rest(_a, ["className", "actions", "onClear", "clearText", "noDataText", "children"]);
    // @ts-ignore
    var list = useNodeList(props);
    // Because list is virtualized it doesnt respond well to resizing
    // In order to adjust when resizing we observe container and remount
    // the autoSizer when there is any change.
    // There is still room for improvement as it doesnt pick up on each resize.
    var _e = useResizeObserver(), ref = _e.ref, _f = _e.height, height = _f === void 0 ? 0 : _f;
    return (_jsxs(StyledContainer, __assign({ ref: ref, className: className }, { children: [isNotEmpty(props.data) && props.searchable
                && (_jsx("div", __assign({ className: 'select__searchbar' }, { children: _jsx(Searchbar, { "data-testid": 'select__search', dark: true, autoFocus: true, placeholder: translate('Search...'), value: list.searchTerm, onChange: list.setSearch }, void 0) }), void 0)), actions && (_jsx(Actions, { children: actions }, void 0)), onClear
                && (_jsx("ul", { children: _jsx("li", __assign({ "data-testid": 'select__clear', className: 'select__clearable', onClick: onClear }, { children: clearText }), void 0) }, void 0)), _jsx(ListContext.Provider, __assign({ value: list }, { children: isNotEmpty(list.nodeList) && isEmpty(list.data)
                    ? _jsx("ul", { children: _jsx("li", __assign({ className: 'select__clearable' }, { children: translate('Not found...') }), void 0) }, void 0)
                    : isEmpty(list.nodeList)
                        ? _jsx("ul", { children: _jsx("li", __assign({ className: 'select__clearable' }, { children: noDataText }), void 0) }, void 0)
                        : list.data.length <= 10
                            ? (_jsx("ul", { children: list
                                    .data
                                    .map(function (_, index) { return _jsx(SelectOption, { index: index }, index); }) }, void 0))
                            : (_jsx("div", __assign({ style: styles.autoSizer }, { children: _jsx(AutoSizer, __assign({ style: styles.autoSizer }, { children: function (_a) {
                                        var sizerHeight = _a.height;
                                        return (_jsx(FixedSizeList, __assign({ innerElementType: 'ul', style: styles.virtualList, itemSize: ITEM_HEIGHT, height: sizerHeight, itemCount: length(list.data), width: '100%' }, { children: children }), height));
                                    } }), height) }), void 0)) }), void 0)] }), void 0));
}
function withValueAccessor(props) {
    return props.valueAccessor !== undefined && !props.multiple;
}
function withoutValueAccessor(props) {
    return props.valueAccessor === undefined && !props.multiple;
}
function multipleWithValueAccessor(props) {
    return props.valueAccessor !== undefined && props.multiple;
}
export function renderSelectList(props) {
    if (withValueAccessor(props)) {
        return _jsx(SelectList, __assign({}, props), void 0);
    }
    else if (withoutValueAccessor(props)) {
        return _jsx(SelectList, __assign({}, props), void 0);
    }
    else if (multipleWithValueAccessor(props)) {
        return _jsx(SelectList, __assign({}, props), void 0);
    }
    else {
        return _jsx(SelectList, __assign({}, props), void 0);
    }
}
