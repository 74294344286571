var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Input } from '../Input/Input';
import { toRgbCss } from '../utils';
import { matchThemeProp } from '../Theme';
var StyledInput = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 5px;\n  padding-bottom: 5px;\n  margin-bottom: 20px;\n  color: ", "\n"], ["\n  padding-top: 5px;\n  padding-bottom: 5px;\n  margin-bottom: 20px;\n  color: ", "\n"])), matchThemeProp(function (theme) { return theme.global.purposes; }, { modifier: toRgbCss }));
export function HeadingInput(_a) {
    var label = _a.label, rest = __rest(_a, ["label"]);
    var id = useMemo(function () { return uuidv4(); }, []);
    return (_jsxs(_Fragment, { children: [_jsx(StyledInput, __assign({}, rest, { huge: true, id: id }), void 0), _jsx("label", __assign({ htmlFor: id, hidden: true }, { children: label }), void 0)] }, void 0));
}
var templateObject_1;
