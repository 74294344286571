var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { themeColor } from '../Theme';
import { Icon } from '../Icon';
export var NavIcon = styled(Icon).attrs({
    className: 'nav__icon'
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  margin-left: 3px;\n  margin-right: 10px;\n"], ["\n  color: ", ";\n  font-size: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  margin-left: 3px;\n  margin-right: 10px;\n"])), themeColor('white'));
var templateObject_1;
