var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import TextHighlighter from 'react-highlight-words';
import { globalFont, truncateText } from '../utils';
import { Searchbar } from '../Searchbar';
import { fromTheme } from '../Theme';
import { InternalList } from './InternalList';
import { useMultiSearchFilter } from './useMultiSearchFilter';
var StyledResourceList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  font-size: ", ";\n\n  .column-headers {\n    padding-left: ", "em;\n\n    display: flex;\n    align-items: center;\n \n    header {\n      padding-right: ", "em;\n      text-transform: uppercase;\n      color: ", ";\n\n      ", "\n\n      input {\n        font-size: 1em;\n\n        &:placeholder-shown {\n          text-transform: uppercase;\n        }\n      } \n    }\n\n    padding-bottom: ", ";\n    padding-top: ", ";\n\n    margin-bottom: ", ";\n\n    border-bottom: 1px solid ", ";\n  }\n\n  .actions {\n    padding-left: ", "em;\n    display: flex;\n    align-items: center;\n\n    > * {\n      margin-right: 10px;\n    }\n\n    margin-bottom: ", ";\n  }\n\n  mark {\n    background-color: ", ";\n  }\n"], ["\n  ", "\n\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  font-size: ", ";\n\n  .column-headers {\n    padding-left: ", "em;\n\n    display: flex;\n    align-items: center;\n \n    header {\n      padding-right: ", "em;\n      text-transform: uppercase;\n      color: ", ";\n\n      ", "\n\n      input {\n        font-size: 1em;\n\n        &:placeholder-shown {\n          text-transform: uppercase;\n        }\n      } \n    }\n\n    padding-bottom: ", ";\n    padding-top: ", ";\n\n    margin-bottom: ", ";\n\n    border-bottom: 1px solid ", ";\n  }\n\n  .actions {\n    padding-left: ", "em;\n    display: flex;\n    align-items: center;\n\n    > * {\n      margin-right: 10px;\n    }\n\n    margin-bottom: ", ";\n  }\n\n  mark {\n    background-color: ", ";\n  }\n"
    /**
     * A search filter will only be applied after it has at least this amount of characters.
     */
])), globalFont, fromTheme(function (theme) { return theme.fontSizes[1]; }), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.colors.gray; }), truncateText, fromTheme(function (theme) { return theme.space[1]; }), fromTheme(function (theme) { return theme.space[1]; }), fromTheme(function (theme) { return theme.space[2]; }), fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.space[2]; }), fromTheme(function (theme) { return theme.colors.yellow; }));
/**
 * A search filter will only be applied after it has at least this amount of characters.
 */
var SEARCH_CHARACTER_THRESHOLD = 2;
function _ResourceList(_a) {
    var data = _a.data, columns = _a.columns, onClick = _a.onClick, isActive = _a.isActive, categorization = _a.categorization, order = _a.order, sorting = _a.sorting, actions = _a.actions;
    var _b = useMultiSearchFilter(columns, function (column) { return ({
        by: column.filterBy || (function () { return ''; }),
        characterThreshold: SEARCH_CHARACTER_THRESHOLD,
        caseInsensitive: true
    }); }), getFilterState = _b[0], search = _b[1];
    var internalListColumns = useMemo(function () { return (columns.map(function (column, i) { return ({
        width: column.width,
        renderCell: function (item) { return column.renderColumn(item, getFilterState(i)); }
    }); })); }, [columns, getFilterState]);
    return (_jsxs(StyledResourceList, { children: [_jsx("div", __assign({ className: 'column-headers' }, { children: columns.map(function (column, i, arr) {
                    var _a;
                    var width = column.width || 100 / arr.length + "%";
                    return (_jsx("header", __assign({ style: { width: width } }, { children: (_a = column.header) === null || _a === void 0 ? void 0 : _a.call(column, getFilterState(i)) }), i));
                }) }), void 0), _jsx("div", __assign({ className: 'actions' }, { children: actions }), void 0), _jsx(InternalList, { data: search(data), columns: internalListColumns, isActive: isActive, categorization: categorization, order: order, sorting: sorting, onClick: onClick }, void 0)] }, void 0));
}
function HighlightedText(_a) {
    var children = _a.children;
    return _jsx("mark", __assign({ "data-testid": 'highlighted-text' }, { children: children }), void 0);
}
function createSearchableColumn(_a) {
    var valueAccessor = _a.valueAccessor, placeholder = _a.placeholder, width = _a.width;
    return {
        header: function (context) { return (_jsx(Searchbar, { animated: true, placeholder: placeholder === null || placeholder === void 0 ? void 0 : placeholder(), value: context.searchTerm, onChange: context.setSearchTerm }, void 0)); },
        renderColumn: function (item, context) {
            return (_jsx(TextHighlighter, { highlightTag: HighlightedText, searchWords: [context.searchTerm], textToHighlight: valueAccessor(item) }, void 0));
        },
        filterBy: valueAccessor,
        width: width
    };
}
/**
 * @description ResourceList displays a collection of objects of the same type,
 * like services or accounts.
 * The main job of a resource list is to help users find an object
 * and navigate to a full-page representation of it.
 * It has built in solutions for searching, categorizing, ordering,
 * sorting and rendering multiple columns.
 */
export var ResourceList = React.memo(_ResourceList);
ResourceList.useSortable = InternalList.useSortable;
ResourceList.createSearchableColumn = createSearchableColumn;
var templateObject_1;
