import { passedValueArg } from '../Object/setProperty';
export class SetAttribute {
    setAttribute(property, value) {
        const update = (value) => {
            this.updateFn((object) => {
                object.attributes[property] = value;
            });
            return this;
        };
        if (passedValueArg(arguments.length, value)) {
            return update(value);
        }
        else {
            return update;
        }
    }
}
