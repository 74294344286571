import { Record, RecordRelationship, RecordHasManyRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

export const METADATA_TYPES = {
  TEXT: 'string',
  NUMERIC: 'numeric',
  DATE: 'date',
  LIST: 'list'
} as const

export type MetadataFieldType = typeof METADATA_TYPES[keyof typeof METADATA_TYPES]

export interface MetadataDefinitionModel extends Record {
  type: 'metadatadefinition'
  attributes: MetadataDefinitionAttributes
  relationships?: MetadataDefinitionRelationships
}

export interface MetadataDefinitionAttributes {
  name: string
  fields: MetadataDefinitionField[]
}

export type MetadataDefinitionField =
  | MetadataTypeList
  | MetadataTypeString
  | MetadataTypeDate
  | MetadataTypeNumeric

export interface MetadataTypeString {
  name: string
  type: 'string'
  validate: string
}

export interface MetadataTypeDate {
  name: string
  type: 'date'
}

export interface MetadataTypeNumeric {
  name: string
  type: 'numeric'
}

export interface MetadataTypeList {
  name: string
  type: 'list'
  list: string[]
}

export interface MetadataDefinitionRelationships extends Dict<RecordRelationship> {
  metadata: RecordHasManyRelationship
  datasets: RecordHasManyRelationship
}

export const DEFAULT_METADATA_DEFINITION: MetadataDefinitionModel = {
  id: '',
  type: 'metadatadefinition',
  attributes: {
    name: '',
    fields: []
  }
}

export const createMetadataDefinition = makeCreateModel<MetadataDefinitionModel>(
  'metadatadefinition',
  DEFAULT_METADATA_DEFINITION.attributes
)
