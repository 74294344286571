import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { orderRange } from '../../DateRange/helpers';
export var updateDayRange = function (range, action) {
    switch (action.type) {
        case 'SELECT_START':
            return [action.date, null];
        case 'SELECT_END':
            if (!range[0])
                return range;
            if (isSameDay(range[0], action.date))
                return [null, null];
            return orderRange([range[0], action.date]);
        case 'RESET_RANGE':
            return [null, null];
        case 'SELECT_SINGLE_UNIT_AS_RANGE':
            return [action.date, action.date];
    }
};
function fitPartialDayRange(_a) {
    var start = _a[0], end = _a[1];
    return [
        start ? startOfDay(start) : null,
        end ? endOfDay(end) : null
    ];
}
export var dayRangeReducer = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return fitPartialDayRange(updateDayRange.apply(void 0, args));
};
