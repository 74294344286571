import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { classNames } from '../../../utils/misc'

import Icon from './../Icon'

import './index.css'

// eslint-disable-next-line react/prop-types
const create = ({ children = 'create', ...props }) => (
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <Button
    variation='secondary'
    submit
    icon='add'
    hotkey='save'
    material
    {...props}>
    {children}
  </Button>
)

// eslint-disable-next-line react/prop-types
const update = ({ children = 'Update', ...props }) => (
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <Button
    variation='primary'
    submit
    icon='check'
    hotkey='save'
    material
    {...props}>
    {children}
  </Button>
)

// eslint-disable-next-line react/prop-types
const del = ({ children = 'Delete', ...props }) => (
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <Button
    variation='danger'
    icon='delete'
    hotkey='delete'
    material
    {...props}>
    {children}
  </Button>
)

class Button extends PureComponent {
  static Create = create
  static Update = update
  static Delete = del

  render () {
    let {
      children, title, submit, variation, size, dropdown, tooltip, material,
      className, style, icon, iconPosition, onClick, toggle, disabled,
      hotkey, test
    } = this.props

    const classes = classNames({ 'ex-button__dropdown': dropdown },
      `ex-button uk-button-${variation} uk-button-${size}`,
      className)

    if (dropdown) {
      icon = 'triangle-down'
    }

    if (material && icon) {
      icon = icon.replace(/ /g, '_')
    }

    // Do we have a round button?
    if (icon && typeof title !== 'undefined' && !children) {
      return material
        ? (
          <button className={`${classes} uk-icon-button`}
            data-hotkey={hotkey}
            disabled={disabled}
            style={style}
            title={title}
            type={submit
              ? 'submit'
              : 'button'
            }
            data-test={test}
            data-uk-toggle={toggle}
            data-uk-tooltip={tooltip
              ? `pos: ${tooltip}`
              : undefined
            }
            onClick={onClick}>
            <Icon material>{icon}</Icon>
          </button>
        )
        : (
          <button className={`${classes} uk-icon-button`}
            disabled={disabled}
            style={style}
            title={title}
            type={submit ? 'submit' : 'button'}
            data-test={test}
            data-uk-icon={`icon: ${icon}`}
            data-uk-toggle={toggle}
            data-uk-tooltip={tooltip ? `pos: ${tooltip}` : undefined}
            onClick={onClick} />
        )
    }

    return (
      <button className={`${classes} uk-button`}
        data-hotkey={hotkey}
        disabled={disabled}
        style={style}
        title={title}
        type={submit ? 'submit' : 'button'}
        data-test={test}
        data-uk-toggle={toggle}
        data-uk-tooltip={tooltip ? `pos: ${tooltip}` : undefined}
        onClick={onClick}>
        {icon && iconPosition === 'left'
          ? <Icon className='ex-button__icon--left' material={material}>{icon}</Icon>
          : null}
        {children}
        {icon && iconPosition === 'right'
          ? <Icon className='ex-button__icon--right' material={material}>{icon}</Icon>
          : null}
      </button>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  dropdown: PropTypes.bool,
  hotkey: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  material: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['tiny', 'small', 'normal', 'large']),
  style: PropTypes.object,
  submit: PropTypes.bool,
  test: PropTypes.any,
  title: PropTypes.string,
  toggle: PropTypes.string,
  tooltip: PropTypes.oneOf([false, 'left', 'right', 'top', 'bottom']),
  variation: PropTypes.oneOf([
    'default',
    'muted',
    'primary',
    'secondary',
    'tertiary',
    'danger',
    'text',
    'link',
    ''
  ])
}

Button.defaultProps = {
  size: 'normal',
  submit: false,
  disabled: false,
  variation: 'default',
  iconPosition: 'left'
}

export default Button
