"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleLine = void 0;
function singleLine() {
    var lines = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        lines[_i] = arguments[_i];
    }
    return lines.reduce(function (line, nextLine) { return line + nextLine + ' '; }, '').trim();
}
exports.singleLine = singleLine;
