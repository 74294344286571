import React from 'react'
import { translate } from '@exivity/translations'
import styled from 'styled-components'

import { proceedToUnsupportedBrowser } from '../../../store/storage'

const Center = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

export function UnsupportedBrowser () {
  return (
    <Center>
      <h1>{translate('Unsupported browser')}</h1>
      <p>
        {translate('Unfortunately, we don\'t support your browser at the moment.')}
        <br />
        {translate('Please contact your system administrator to obtain a list of currently supported browsers.')}
      </p>
      <a onClick={() => {
        proceedToUnsupportedBrowser()
        location.reload()
      }}>
        {translate('Proceed to website anyway')}
      </a>
    </Center>
  )
}
