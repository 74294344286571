import { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
export function useObserveResize(cb) {
    var ref = useRef(null);
    useEffect(function () {
        if (ref.current) {
            var observer_1 = new ResizeObserver(function () { return cb(ref.current); });
            observer_1.observe(ref.current);
            return function () { return observer_1.disconnect(); };
        }
    }, [ref.current, cb]);
    return function (node) { return (ref.current = node); };
}
