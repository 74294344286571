import { useContext, useEffect } from 'react';
import { DataLayerContext } from '../Provider';
export function useStoreListener(event, listener, deps) {
    const store = useContext(DataLayerContext);
    useEffect(() => {
        const listenerFn = (transform) => listener(transform);
        store.on(event, listenerFn);
        return () => {
            store.off(event, listenerFn);
        };
    }, deps);
}
