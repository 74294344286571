import { useMemo } from 'react';
import { useStyledTheme } from '../hooks';
export function usePurposeProp(_a) {
    var _b = _a === void 0 ? {} : _a, primary = _b.primary, secondary = _b.secondary, success = _b.success, warning = _b.warning, danger = _b.danger, brand = _b.brand;
    var purposesProps = { primary: primary, secondary: secondary, success: success, warning: warning, danger: danger, brand: brand };
    var purposes = useStyledTheme(function (theme) { return theme.global.purposes; });
    return useMemo(function () {
        var selectedPurposes = Object.keys(purposes)
            .filter(function (purpose) { return purposesProps[purpose]; });
        if (selectedPurposes.length > 1) {
            throw new Error('More than one purpose prop is provided. Please provide only one.');
        }
        return selectedPurposes[0] || 'primary';
    }, [primary, secondary, success, warning, danger]);
}
