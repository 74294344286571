import React from 'react'
import { Field, Label, Input } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

function ExecuteHeader ({ options }: HeaderProps) {
  return <>{options.command}</>
}

function ExecuteOptions ({ options, updater }: OptionsProps) {
  return (
    <Field>
      <Label>{translate('Command')}</Label>
      <Input
        required
        placeholder={translate('Command')}
        value={options.command || ''}
        onChange={updater.setProperty('command')} />
    </Field>
  )
}

export const Execute = {
  Header: ExecuteHeader,
  Options: ExecuteOptions
} as WorkflowStepVariation
