import React, { useCallback } from 'react'
import { useCrud } from 'react-crud-hook'
import { MdClose } from 'react-icons/md'
import { Tabs, Form, Button } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { browserHistory } from '@exivity/routing'

import { ServiceModel, DEFAULT_SERVICE } from '../../../data/types'
import { useMetadata } from '../../metadata/components/MetadataForm/useMetadata'
import { reportsThunks, PreparableResource } from '../../reports/state'

import ServiceForm from './ServiceForm/ServiceForm'
import { renderServiceTabs } from './ServiceForm/ServiceTabs'
import SourceGroup from './ServiceForm/SourceGroup'
import IntervalGroup from './ServiceForm/IntervalGroup'
import BillingGroup from './ServiceForm/BillingGroup'
import ServiceGroup from './ServiceForm/ServiceGroup'
import Metadata from './Metadata'

export default function CreateService () {
  const serviceRecord = useCrud<ServiceModel>(DEFAULT_SERVICE)
  const [metadataRecord, onMetadataRecord] = useMetadata()

  const onCreateAndPrepare = useCallback(() => {
    serviceRecord.save({
      onCreate: (record: any, { router, dispatch }: any) => {
        router.push(`services/overview/${record.id}`)

        if (metadataRecord) {
          metadataRecord.save()
        }

        dispatch(
          reportsThunks.prepare.prepareAffectedReports({
            resource: PreparableResource.Service,
            id: record.id as string
          })
        )
      }
    })
  }, [serviceRecord, metadataRecord])

  const onCreate = useCallback(() => {
    serviceRecord.save({
      onCreate: (record: any, { router }: any) => {
        router.push(`services/overview/${record.id}`)
      }
    })
  }, [serviceRecord])

  return (
    <ServiceForm editable service={serviceRecord} onSubmit={onCreate}>
      <Tabs>
        {renderServiceTabs()}
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <ServiceGroup service={serviceRecord} />
            <SourceGroup service={serviceRecord} />
            <IntervalGroup service={serviceRecord} />
            <BillingGroup service={serviceRecord} />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Metadata service={serviceRecord} onRecord={onMetadataRecord} />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>

      <Form.Toolbar>
        <Button outlined icon={<MdClose />}
          onClick={() => browserHistory.push('/services/overview')}>
          {translate('cancel')}
        </Button>
        <Button.Group success>
          <Button.Create />
          <Button.Select success>
            <Button.SelectOption onClick={onCreateAndPrepare}>
              {translate('With preparing')}
            </Button.SelectOption>
          </Button.Select>
        </Button.Group>
      </Form.Toolbar>
    </ServiceForm>
  )
}
