"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translator = void 0;
var makeTranslator_1 = require("./makeTranslator");
var translations_1 = require("./translations");
var translate_1 = require("./translate");
function translator(language, allTranslations, languages) {
    if (allTranslations === void 0) { allTranslations = translations_1.translations; }
    if (languages === void 0) { languages = translate_1.supportedLanguages; }
    var translation = languages
        .find(function (translation) { return allTranslations[translation].language === language; });
    return makeTranslator_1.makeTranslator(translation
        ? allTranslations[translation]
        : undefined);
}
exports.translator = translator;
