var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { fromTheme, themePriority } from '../Theme';
var MenuItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: flex-row;\n  align-items: center;\n\n  margin-right: ", ";\n  z-index: ", ";\n  \n label {\n    margin-right: ", ";\n    white-space: nowrap;\n  }\n"], ["\n  display: flex;\n  flex-direction: flex-row;\n  align-items: center;\n\n  margin-right: ", ";\n  z-index: ", ";\n  \n label {\n    margin-right: ", ";\n    white-space: nowrap;\n  }\n"])), fromTheme(function (theme) { return theme.space[1]; }), themePriority(2), fromTheme(function (theme) { return theme.space[1]; }));
var StyledMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: 50px;\n  \n  .container {\n    float: right;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    height: 100%;\n    background-color: ", ";\n    cursor: pointer;\n\n    border-radius: 0.4rem;\n    padding: 0 ", ";\n    \n    & > div {\n      margin-right: ", ";\n    }\n  \n    & div:last-child {\n      margin-right: 0;\n    }\n  }\n"], ["\n  position: relative;\n  height: 50px;\n  \n  .container {\n    float: right;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    height: 100%;\n    background-color: ", ";\n    cursor: pointer;\n\n    border-radius: 0.4rem;\n    padding: 0 ", ";\n    \n    & > div {\n      margin-right: ", ";\n    }\n  \n    & div:last-child {\n      margin-right: 0;\n    }\n  }\n"])), fromTheme(function (theme) { return theme.colors.veryLightGray; }), fromTheme(function (theme) { return theme.space[2]; }), fromTheme(function (theme) { return theme.space[1]; }));
export function Menu(_a) {
    var children = _a.children, className = _a.className;
    return (_jsx(StyledMenu, __assign({ className: className }, { children: _jsx("div", __assign({ className: 'container' }, { children: children }), void 0) }), void 0));
}
Menu.Item = MenuItem;
var templateObject_1, templateObject_2;
