import produce from 'immer';
import { useState, useMemo, useEffect } from 'react';
import { copy } from './utils/copy';
import { copyArray } from './utils/copyArray';
export function useData(data) {
    const [state, setState] = useState(data);
    useEffect(() => { setState(data); }, [data]);
    return useMemo(() => {
        function update(updateFn) {
            setState((state) => (produce((draft) => {
                const result = updateFn(draft);
                if (Array.isArray(result)) {
                    copyArray(draft, result);
                }
                else if (result) {
                    copy(draft, result);
                }
            })(state)));
        }
        return [state, update];
    }, [state]);
}
