import React from 'react'
import styled from 'styled-components'
import { SelectFilter } from '@exivity/ui'
import { prop } from '@exivity/fp'

import { KeyColumnSelectorOptionType } from './useKeyColumnSelector'

const Container = styled.div`
  width: 150px;
  z-index: 12;
  margin-left: 10px;

  input {
    height: 32px;
  }
`

interface KeyColumnSelectorProps {
  options: KeyColumnSelectorOptionType[]
  selectedOption: string
  selectOption: (o: string) => void
  visible?: boolean
}

export function KeyColumnSelector ({
  options,
  selectedOption,
  selectOption,
  visible = false
}: KeyColumnSelectorProps) {
  if (!visible) {
    return null
  }
  return (
    <Container>
      <SelectFilter
        small
        inputValue={options.find(({ value }) => value === selectedOption)?.label}
        labelAccessor={prop('label')}
        valueAccessor={prop('value')}
        data={options}
        value={selectedOption}
        onChange={selectOption} />
    </Container>
  )
}
