var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
export var _Headers = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: ", "em;\n  display: flex;\n  ", ";\n  \n\n  ", "\n  \n  ", "\n  \n  ", "\n  \n  header {\n    display: flex;\n    align-items: center;\n    text-transform: uppercase;\n    color: ", ";\n  \n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  \n    input {\n      font-size: 1em;\n  \n      &:placeholder-shown {\n        text-transform: uppercase;\n      }\n    } \n  }\n  \n  padding-bottom: ", ";\n  padding-top: ", ";\n  \n  margin-bottom: ", ";\n  \n  border-bottom: 1px solid ", ";\n"], ["\n  padding-left: ", "em;\n  display: flex;\n  ", ";\n  \n\n  ", "\n  \n  ", "\n  \n  ", "\n  \n  header {\n    display: flex;\n    align-items: center;\n    text-transform: uppercase;\n    color: ", ";\n  \n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  \n    input {\n      font-size: 1em;\n  \n      &:placeholder-shown {\n        text-transform: uppercase;\n      }\n    } \n  }\n  \n  padding-bottom: ", ";\n  padding-top: ", ";\n  \n  margin-bottom: ", ";\n  \n  border-bottom: 1px solid ", ";\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), globalFont, function (_a) {
    var maxWidth = _a.maxWidth;
    return maxWidth && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: ", "px;\n "], ["\n    max-width: ", "px;\n "])), maxWidth);
}, function (_a) {
    var flex = _a.flex;
    return flex && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    > * {\n       flex: ", ";\n    }\n  "], ["\n    > * {\n       flex: ", ";\n    }\n  "])), flex);
}, function (_a) {
    var width = _a.width;
    return width && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    > * {\n       width: ", ";\n    }\n  "], ["\n    > * {\n       width: ", ";\n    }\n  "])), typeof width === 'number' ? width + "px" : width);
}, fromTheme(function (theme) { return theme.colors.gray; }), fromTheme(function (theme) { return theme.space[1]; }), fromTheme(function (theme) { return theme.space[1]; }), fromTheme(function (theme) { return theme.space[2]; }), fromTheme(function (theme) { return theme.colors.lightGray; }));
export var _Header = styled.header(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  \n  padding-right: ", "em;\n  text-transform: uppercase;\n  color: ", ";\n  \n  ", "\n  ", "\n  \n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  \n  input {\n    font-size: 1em;\n  \n    &:placeholder-shown {\n      text-transform: uppercase;\n    }\n  } \n"], ["\n  display: flex;\n  justify-content: space-between;\n  \n  padding-right: ", "em;\n  text-transform: uppercase;\n  color: ", ";\n  \n  ", "\n  ", "\n  \n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  \n  input {\n    font-size: 1em;\n  \n    &:placeholder-shown {\n      text-transform: uppercase;\n    }\n  } \n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.colors.gray; }), function (_a) {
    var flex = _a.flex;
    return flex && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["flex: ", ";"], ["flex: ", ";"])), flex);
}, function (_a) {
    var width = _a.width;
    return width && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["width: ", ";"], ["width: ", ";"])), typeof width === 'number' ? width + "px" : width);
});
var StyledDiv = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  \n  height: 30px;\n  width: 30px;\n  padding-left: 5px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  \n  height: 30px;\n  width: 30px;\n  padding-left: 5px;\n"])));
function SortButton(_a) {
    var name = _a.name, sort = _a.sort, onClick = _a.onClick, rest = __rest(_a, ["name", "sort", "onClick"]);
    var Icon = name !== sort.by
        ? TiArrowUnsorted
        : sort.asc
            ? TiArrowSortedUp
            : TiArrowSortedDown;
    return (_jsx(StyledDiv, __assign({}, rest, { onClick: function () {
            var _a;
            return onClick({
                by: name,
                asc: (_a = !(sort === null || sort === void 0 ? void 0 : sort.asc)) !== null && _a !== void 0 ? _a : false
            });
        } }, { children: _jsx(Icon, { size: 22 }, void 0) }), void 0));
}
var Header = _Header;
Header.SortButton = SortButton;
var Headers = _Headers;
Headers.Header = Header;
export { Headers };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
