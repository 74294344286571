import {
  Record,
  RecordHasOneRelationship,
  RecordHasManyRelationship
} from '@orbit/data'

import { makeCreateModel } from '../../utils/model'

export const NOTIFICATION_SUBSCRIPTION_TYPE = 'notificationsubscription'

export enum NotificationSubscriptionType {
  BudgetEvaluated = 'budget_evaluated',
  ReportPublished = 'report_published',
  WorkflowEnded = 'workflow_ended'
}

export type NotificationSubscriptionInfo = {
  [NotificationSubscriptionType.BudgetEvaluated]: {
    budget_id: string
    account_ids: string[] | 'any'
    threshold_percentage: number
    only_once: boolean
  }
  [NotificationSubscriptionType.ReportPublished]: {
    report_id: string
    account_depth: number
    report_type: 'separate'|'consolidated'
    dimension?: ('services'|'instances'|'accounts')[]
    group_by: 'instances_by_instance'|'instances_by_service'
    account_filter: string | null
    date_settings: 'previous_month'|'month_to_date'
    format: 'csv'|'pdf/summary'
  }
  [NotificationSubscriptionType.WorkflowEnded]: {
    follow: string[]
    only_status: ('successful' | 'failed')[]
    files?: {
      filename: string[]
      edited_since_workflows_started: boolean
      compress_attachments: boolean
    }
  }
}

type UnionToIntersection<U> = (U extends any
  ? (k: U) => void
  : never) extends ((k: infer I) => void)
  ? I
  : never

type Info<S extends NotificationSubscriptionType|undefined> = S extends NotificationSubscriptionType
  ? NotificationSubscriptionInfo[S]
  : Partial<UnionToIntersection<NotificationSubscriptionInfo[NotificationSubscriptionType]>>

export interface NotificationSubscriptionModel<
  T extends NotificationSubscriptionType|undefined = undefined
> extends Record {
  type: typeof NOTIFICATION_SUBSCRIPTION_TYPE
  attributes: {
    name: string
    title: string|null
    description: string|null
    enabled: boolean
    type: NotificationSubscriptionType|undefined
    info: Info<T>
  }
  relationships?: {
    user: RecordHasOneRelationship
    channels: RecordHasManyRelationship
  }
}

export const DEFAULT_NOTIFICATION_SUBSCRIPTION: NotificationSubscriptionModel = {
  id: '',
  type: 'notificationsubscription',
  attributes: {
    name: '',
    title: null,
    description: null,
    enabled: true,
    type: undefined,
    info: {}
  }
}

export const createNotificationSubscription = makeCreateModel<NotificationSubscriptionModel>(
  NOTIFICATION_SUBSCRIPTION_TYPE,
  DEFAULT_NOTIFICATION_SUBSCRIPTION.attributes
)
