import React, { Fragment, createContext } from 'react';
import { match } from 'path-to-regexp';
import { find, curry } from '@exivity/fp';
import { useLocation } from '../useLocation';
import { findActiveRoute, getActiveRoutes } from '../';
export const ROUTE_CONTEXT = createContext({
    params: {},
    route: {
        pathname: ''
    },
    exactRoute: {},
    activeRoutes: [],
    search: ''
});
const isMatch = curry((pathname, routePathname) => (!!match(routePathname)(pathname)));
const flattenRoute = (route) => [route]
    .flatMap(x => [
    x,
    ...Object
        .values(x.routes || {})
        .flatMap(flattenRoute)
]);
const isExactRoute = (pathname) => {
    return (route) => {
        return isMatch(pathname, route.pathname);
    };
};
export const findExactRouteByPathname = (pathname, route) => (find(isExactRoute(pathname), flattenRoute(route)));
export function RenderRoutes({ routes }) {
    const { pathname, search } = useLocation();
    const findRoute = findActiveRoute(pathname);
    const activeRoot = findRoute(routes);
    if (!activeRoot) {
        console.warn('Did not match a route. Advise to include a catch all route like not found.');
        return null;
    }
    const exactRoute = findExactRouteByPathname(pathname, activeRoot);
    if (!exactRoute) {
        console.warn('Did not match a exact route. Advise to include a catch all route like not found.');
        return null;
    }
    function createRoute(route, exactRoute, parentParams) {
        const Parent = route.component || Fragment;
        const child = findRoute(route.routes || {});
        const result = match(route.pathname)(pathname);
        const params = Object.assign(Object.assign({}, parentParams), (result ? result.params : {}));
        return (React.createElement(ROUTE_CONTEXT.Provider, { value: {
                search,
                params,
                route,
                exactRoute,
                activeRoutes: getActiveRoutes(pathname, routes)
            } },
            React.createElement(Parent, { children: child
                    ? createRoute(child, exactRoute, params)
                    : undefined })));
    }
    return createRoute(activeRoot, exactRoute);
}
