import { extendUIProps } from '@exivity/ui'
import { useSelector } from 'react-redux'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

export const dateCalendarSelect = extendUIProps({
  DatePicker: (props) => {
    const dateFormat = useSelector(configurationSelectors.getDateFormat)

    return {
      format: props.format || dateFormat
    }
  },
  DateRangePicker: (props) => {
    const dateFormat = useSelector(configurationSelectors.getDateFormat)

    return {
      format: props.format || dateFormat
    }
  },
  DateFilter: (props) => {
    const dateFormat = useSelector(configurationSelectors.getDateFormat)

    return {
      format: props.format || dateFormat
    }
  },
  DateRangeFilter: (props) => {
    const dateFormat = useSelector(configurationSelectors.getDateFormat)

    return {
      format: props.format || dateFormat
    }
  }
})
