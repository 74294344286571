import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from '@exivity/routing'
import { UserGroupPermission, useUserHasPermission } from '@exivity/data-layer'
import { Alert } from '@exivity/ui'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

export function DebugWarning () {
  const canSeeDebugWarning = useUserHasPermission([UserGroupPermission.ManageSettings])
  const showDebugWarning = useSelector(configurationSelectors.isDebugMode)

  if (!showDebugWarning || !canSeeDebugWarning) return null

  return (
    <Alert warning>
      Debug mode is enabled, which can affect performance.&nbsp;
      <span>
        If you&apos;re done debugging, disable in&nbsp;
        <Link title='Settings' to='administration/settings/system'>
          Settings &raquo; System
        </Link> &raquo; Development.
      </span>
    </Alert>
  )
}
