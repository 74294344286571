import { JSONAPIURLBuilder } from '@orbit/jsonapi'
import pluralize from 'pluralize'

export class ExivityJSONAPIURLBuilder extends JSONAPIURLBuilder {
  // Overwrite resourcePath so we can pluralize the path here
  // (since we removed it from the resourceType method).
  resourcePath (type: string, id?: string): string {
    const path = [pluralize.plural(this.serializer.resourceType(type))]
    if (id) {
      const resourceId = this.serializer.resourceId(type, id)
      if (resourceId) {
        path.push(resourceId)
      }
    }
    return path.join('/')
  }
}
