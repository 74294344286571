import { Record, RecordRelationship, RecordHasOneRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

export const WORKFLOW_SCHEDULE_TYPE = 'workflowschedule'

export interface WorkflowScheduleModel extends Record {
  type: typeof WORKFLOW_SCHEDULE_TYPE
  attributes: WorkflowScheduleAttributes
  relationships: WorkflowsScheduleRelationships
}

export enum WorkflowScheduleFrequency {
  Hourly = 'hourly',
  Daily = 'daily',
  Monthly = 'monthly'
}

export interface WorkflowScheduleAttributes {
  start_time: string
  timezone: string
  adjust_for_dst: boolean
  frequency: WorkflowScheduleFrequency
  frequency_modifier: number
  readonly next_run?: string
}

export interface WorkflowsScheduleRelationships extends Dict<RecordRelationship> {
  workflow: RecordHasOneRelationship
}

export const DEFAULT_WORKFLOW_SCHEDULE: WorkflowScheduleModel = {
  id: '',
  type: WORKFLOW_SCHEDULE_TYPE,
  attributes: {
    start_time: '',
    timezone: 'UTC',
    adjust_for_dst: false,
    frequency: WorkflowScheduleFrequency.Monthly,
    frequency_modifier: 1
  },
  relationships: {
    workflow: { data: null }
  }
}

export function createWorkflowSchedule (
  id?: string,
  attributes: Partial<WorkflowScheduleAttributes> = {},
  relationships: Partial<WorkflowsScheduleRelationships> = {}
) {
  return {
    type: WORKFLOW_SCHEDULE_TYPE,
    id,
    attributes: {
      ...DEFAULT_WORKFLOW_SCHEDULE.attributes,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...attributes
    },
    relationships
  } as WorkflowScheduleModel
}
