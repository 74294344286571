import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const select = event => event.target.select()

const Filter = ({
  controlled,
  onFilterChange,
  onChange,
  placeholder,
  className,
  autoFocus,
  value,
  label
}) => (
  <div className={`ex-filter uk-inline uk-width-1-1 ${className} uk-flex uk-flex-middle`}>
    <span className='uk-form-icon uk-form-icon-flip' data-uk-icon='icon: search' />
    <input autoFocus={autoFocus}
      className='uk-input uk-form-blank'
      type='text'
      defaultValue={controlled ? undefined : value}
      value={controlled ? value : undefined}
      placeholder={placeholder}
      onKeyUp={onFilterChange}
      onChange={onChange}
      onClick={select} />
    {
      typeof label === 'string'
        ? <span className='uk-label ex-filter__label'>{label}</span>
        : label && React.cloneElement(
          label,
          { className: 'ex-filter__label' }
        )

    }
  </div>
)

Filter.propTypes = {
  /** Can be a string to use standard label or a custom component */
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  controlled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string
}

Filter.defaultProps = { placeholder: 'search...' }

export default Filter
