import { applyMixins } from '../utils/applyMixins';
import { Select } from '../ObjectShared/select';
import { SetAttribute } from './setAttribute';
import { SetHasOne } from './setHasOne';
class JsonApiObjectChainImpl {
    constructor(data, updateFn) {
        this.data = data;
        this.updateFn = updateFn;
    }
}
applyMixins(JsonApiObjectChainImpl, [Select, SetAttribute, SetHasOne]);
export function createJsonApiObjectChain(data, updateFn) {
    return new JsonApiObjectChainImpl(data, updateFn);
}
