import session from './session'
import navigate from './navigate'
import wait from './wait'
import report from './report'

export const inject = () => {
  window.Exivity = {
    session,
    navigate,
    wait,
    report
  }
}
