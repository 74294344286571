import packages from '../../../package.json'

const app = {
  name: 'glass' as const,
  version: packages.version,
  profiler: false,
  timezone: 'UTC',
  apiVersion: '1',
  defaultTitle: 'App',
  showWarningWhenLicenseExpireInDays: 14
}

const reports = {
  maxLevel: 5
}

const analytics = {
  defaultProperty: 'UA-88768517-1'
}

const sentry = {
  key: '2790107ecc3e4f13b951abd5c58ef4ad',
  appId: '120998',
  enabled: process.env.NODE_ENV === 'production',
  enableAutoUserFeedback: false,
  captureWarnings: false
}

export const config = {
  app,
  reports,
  analytics,
  sentry
}

export default config
