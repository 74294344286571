var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { map, flip, compose, join, concat, replace } from '@exivity/fp';
import { injectIf } from '../utils';
import { replacer } from './replacer';
import { themePurpose } from '../Theme';
var flippedConcat = flip(concat);
var parenthesize = compose(concat('('), flippedConcat(')'));
var combinedRegex = compose(join('|'), map(parenthesize));
var escapeRegExpFn = replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
var escapedCombinedRegex = compose(combinedRegex, map(escapeRegExpFn));
var subtleHighlight = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["background-color: #f8f8f8;"], ["background-color: #f8f8f8;"])));
export var StyledHighlight = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #fff68c;\n  padding: 2px 0;\n  \n  ", "\n  ", "\n"], ["\n  background-color: #fff68c;\n  padding: 2px 0;\n  \n  ", "\n  ", "\n"])), injectIf('subtle', subtleHighlight), injectIf('active', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), themePurpose('brand'))));
var Span = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), injectIf('active', css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), themePurpose('brand'))));
export var makeHighlighter = function (textToMatch, options) {
    if (options === void 0) { options = { subtle: false, autoEscape: false, active: false }; }
    return (replacer(options.autoEscape
        ? escapedCombinedRegex(textToMatch)
        : combinedRegex(textToMatch), function (match, count) { return (_jsx(StyledHighlight, __assign({ subtle: options.subtle, active: options.active }, { children: match }), count)); }));
};
export function Highlight(_a) {
    var className = _a.className, _b = _a.autoEscape, autoEscape = _b === void 0 ? true : _b, textToMatch = _a.textToMatch, children = _a.children, rest = __rest(_a, ["className", "autoEscape", "textToMatch", "children"]);
    if (textToMatch === null || textToMatch === void 0 ? void 0 : textToMatch.some(Boolean)) {
        var highlightText = makeHighlighter(textToMatch, { autoEscape: autoEscape, subtle: !!rest.subtle, active: !!rest.active });
        return (_jsx(Span, __assign({ className: className }, rest, { children: highlightText(children) }), void 0));
    }
    if (textToMatch)
        return _jsx(Span, __assign({}, rest, { children: children }), void 0);
    return (_jsx(StyledHighlight, __assign({ className: className }, rest, { children: children }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
