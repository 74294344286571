import { splitFileName } from '../helpers'

export interface FileEntry {
  type: 'file' | 'folder'
  path: string
  name: string
  children?: FileEntry[]
}

export const formatLookup = (lookup: FileEntry) => {
  const { baseName, extension } = splitFileName(lookup.name)
  return {
    ...lookup,
    baseName,
    extension
  }
}

export const formatListLookups = (list: FileEntry[]) => list.map(formatLookup)

export const withExtension = (baseName: string) => (
  `${baseName}.csv`
)
