var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { translate } from '@exivity/translations';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { SelectInput } from '../Input';
import { useFieldContext } from '../Field/context';
import { accumulateFromChildren } from '../SelectList/utils';
import { renderSelectList } from '../SelectList';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
var StyledDropdown = styled(Dropdown)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .dropdown__drop {\n    max-width: 500px;\n    \n    ", "\n \n    padding: ", " 0;\n    \n    .select__searchbar {\n      margin-top: 0;\n    }\n  }\n \n  ", "\n"], ["\n  .dropdown__drop {\n    max-width: 500px;\n    \n    ", "\n \n    padding: ", " 0;\n    \n    .select__searchbar {\n      margin-top: 0;\n    }\n  }\n \n  ", "\n"])), function (_a) {
    var width = _a.width;
    return width
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["width: ", "px;"], ["width: ", "px;"])), width) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["width: 100%;"], ["width: 100%;"])));
}, themeSpace(1), function (_a) {
    var width = _a.width;
    return !width && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n  "], ["\n    position: relative;\n  "])));
});
function getTextWidth(font) {
    var _a;
    var canvas = document.createElement('canvas');
    if (!canvas.getContext('2d'))
        return function () { return 200; };
    var ctx = (_a = canvas.getContext('2d')) !== null && _a !== void 0 ? _a : {};
    ctx.font = font;
    return function (text) { return ctx.measureText(text).width; };
}
function useGetMaxWidthRows(_a) {
    var data = _a.data, labelAccessor = _a.labelAccessor;
    return useMemo(function () {
        var measure = getTextWidth('14pt Fira Sans');
        var width = Math.max.apply(Math, data.flatMap(accumulateFromChildren(function (node) { return measure(labelAccessor(node)); }))) + 40;
        return width > 250 ? width : undefined;
    }, [data, labelAccessor]);
}
export function SelectFilter(_a) {
    var name = _a.name, value = _a.value, inputValue = _a.inputValue, className = _a.className, _b = _a.data, data = _b === void 0 ? [] : _b, onChange = _a.onChange, required = _a.required, disabled = _a.disabled, _c = _a.placement, placement = _c === void 0 ? 'bottom-start' : _c, _d = _a.multiple, multiple = _d === void 0 ? false : _d, _e = _a.searchable, searchable = _e === void 0 ? false : _e, categorized = _a.categorized, _f = _a.placeholder, placeholder = _f === void 0 ? translate('Choose an option') : _f, _g = _a.labelAccessor, labelAccessor = _g === void 0 ? identity : _g, _h = _a.valueAccessor, valueAccessor = _h === void 0 ? identity : _h, getChildNodes = _a.getChildNodes, _j = _a.normalize, normalize = _j === void 0 ? identity : _j, renderIcon = _a.renderIcon, actions = _a.actions, onClear = _a.onClear, clearText = _a.clearText, purposes = __rest(_a, ["name", "value", "inputValue", "className", "data", "onChange", "required", "disabled", "placement", "multiple", "searchable", "categorized", "placeholder", "labelAccessor", "valueAccessor", "getChildNodes", "normalize", "renderIcon", "actions", "onClear", "clearText"]);
    var _k = useToggle(false), isOpen = _k[0], toggleOpen = _k[1];
    var id = useFieldContext().id;
    return (_jsx(StyledDropdown, __assign({ className: className, width: useGetMaxWidthRows({
            data: data,
            labelAccessor: labelAccessor
        }), disabled: disabled, placement: placement, reference: _jsx(SelectInput, __assign({ id: id, title: inputValue, required: required, name: name, outlined: true, secondary: true, value: inputValue, placeholder: placeholder, disabled: disabled }, purposes), void 0), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: renderSelectList({
            actions: actions,
            multiple: multiple,
            searchable: searchable,
            categorized: categorized,
            value: value,
            data: data,
            valueAccessor: valueAccessor,
            labelAccessor: labelAccessor,
            getChildNodes: getChildNodes,
            renderIcon: renderIcon,
            normalize: normalize,
            clearText: clearText,
            onChange: function (value) {
                onChange && onChange(value);
                !multiple && toggleOpen();
            },
            onClear: onClear
                ? juxt([onClear, toggleOpen])
                : undefined
        }) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
