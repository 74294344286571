import React from 'react'
import { Field, Label, Placeholder, Select } from '@exivity/ui'
import { queries, useCacheQuery } from '@exivity/data-layer'
import { translate } from '@exivity/translations'
import { Link, getPathname } from '@exivity/routing'

import { app } from '../../../../../../routes'

import { Shared } from './shared'
import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

function PrepareReportHeader ({ options }: HeaderProps) {
  const report = useCacheQuery(queries.find('report', options.report_id))

  return <>{report?.attributes.name}</>
}

function PrepareReportOptions ({ options, updater }: OptionsProps) {
  const reports = useCacheQuery(
    queries
      .findAll('report')
      .sortByAttribute('name', 'ascending')
  )

  if (!reports.length) {
    return (
      <Placeholder>
        {translate("Unable to configure 'Prepare report' step. No reports defined.")}{' '}
        <Link to={getPathname(app.routes.datapipelines.routes.reportDefinitions) + '/new'}>
          {translate('Create a new report.')}
        </Link>
      </Placeholder>
    )
  }

  return (
    <>
      <Field>
        <Label>{translate('Report')}</Label>
        <Select
          required
          searchable
          data={reports}
          value={options.report_id}
          valueAccessor={report => report.id}
          labelAccessor={report => report.attributes.name}
          onChange={updater.setProperty('report_id')} />
      </Field>

      <Shared.Offsets
        fromDate={options.from_date}
        toDate={options.to_date}
        onChangeFromDate={updater.setProperty('from_date')}
        onChangeToDate={updater.setProperty('to_date')} />
    </>
  )
}

export const PrepareReport = {
  Header: PrepareReportHeader,
  Options: PrepareReportOptions
} as WorkflowStepVariation
