import React from 'react'
import { translate } from '@exivity/translations'
import { Field, Label, Input } from '@exivity/ui'

import { ChannelConfigurationProps } from './'

export function NexmoConfiguration ({ channel }: ChannelConfigurationProps) {
  return (
    <>
      <Field>
        <Label.Group>
          <Label>
            {translate('Phone number')}
          </Label>
          <Label.Sub>
            {translate('A text message can be sent to this phone number. Country prefix is required.')}
          </Label.Sub>
        </Label.Group>
        <Input
          placeholder={translate('Phone number')}
          required
          value={channel.attributes.info?.recipient || ''}
          onChange={(recipient) => channel.setAttribute('info', { recipient })} />
      </Field>
    </>
  )
}
