import React from 'react'
import { CrudRecord } from 'react-crud-hook'
import { Heading, Form } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { Row, Column } from '../../../../components/structural/Layout'
import { ServiceModel } from '../../../../data/types'

import FormHeader from './FormHeader'

interface ServiceFormProps {
  service: CrudRecord<ServiceModel>
  children: JSX.Element | JSX.Element[]
  editable?: boolean
  ratesCount?: number
  onSubmit?: () => void
}

export default function ServiceForm ({
  service,
  editable = false,
  ratesCount = 0,
  children,
  onSubmit
}: ServiceFormProps) {
  return (
    <div className='ex-catalogue-services__service'>
      <Form key={service.id} onSubmit={onSubmit}>
        {editable
          ? (
            <Heading.Input
              autoFocus
              required
              label={translate('Description')}
              value={service.attributes.description}
              data-testid='service-name'
              placeholder={translate('Service description')}
              onChange={service.setAttribute('description')} />
          )
          : <Heading>{service.attributes.description}</Heading>}
        {service.id && <FormHeader service={service} ratesCount={ratesCount} />}
        <Row>
          <Column>
            {children}
          </Column>
        </Row>
      </Form>
    </div>
  )
}
