import moment from 'moment-timezone'

import config from '../application/config'
import { configurationSelectors } from '../domains/administration/state/configuration'
import store from '../store/index'

export const formats = {
  longDateTime: 'MMMM Do YYYY HH:mm',
  shortDateTime: 'MMM D YYYY HH:mm',
  isoDateTime: 'YYYY-MM-DDTHH:mm:ssZ',
  localDateTime: 'YYYY-MM-DD HH:mm',

  longDate: 'MMMM Do YYYY',
  shortDate: 'MMM D YYYY',
  isoDate: 'YYYY-MM-DD',

  longYearMonth: 'MMMM YYYY',
  shortYearMonth: 'MMM YYYY',
  isoYearMonth: 'YYYY-MM',

  monthDay: 'MMM D',
  numericMonthDay: 'MM-DD',

  month: 'MMM',

  time: 'HH:mm',

  unixTimestamp: 'X',
  unixTimestampMs: 'x'
}

export class BaseDate {
  moment

  /**
  * @param {any} [date]
  * @param {string} [format]
  */
  constructor (date, format) {
    if (date) {
      this.parse(date, format)
    } else {
      this.moment = moment()
    }
  }
}

export class LocalDate extends BaseDate {
  /**
   * @returns {LocalDate}
   */
  static fromYmd (ymd) {
    return new LocalDate(String(ymd), formats.isoDate)
  }

  /**
   * @returns {LocalDate}
   */
  static fromTimestamp (timestamp) {
    return typeof timestamp === 'string'
      ? new LocalDate(timestamp, formats.isoDateTime)
      : new LocalDate(timestamp * 1000)
  }

  /**
   * @returns {LocalDate}
   */
  static now () {
    return new LocalDate(moment())
  }

  parse (date, format) {
    this.moment = moment(date, format)
  }

  format (format) {
    if (!format) {
      format = configurationSelectors.getDateFormat(store.getState())
        .replace(/y/g, 'Y')
        .replace(/d/g, 'D')
    }
    if (!format) {
      format = formats.shortDate
    }
    return this.moment.format(format)
  }

  ago () {
    return this.moment.fromNow()
  }

  toDate () {
    return this.moment.toDate()
  }

  toISO () {
    return this.format(formats.isoDate)
  }
}

export class ZonedDateTime extends BaseDate {
  /**
   * @returns {ZonedDateTime}
   */
  static fromMilliseconds (milliseconds) {
    return new ZonedDateTime(Number(milliseconds))
  }

  /**
   * @returns {ZonedDateTime}
   */
  static fromTimestamp (timestamp, tz = config.app.timezone) {
    const date = typeof timestamp === 'string'
      ? new ZonedDateTime(timestamp, formats.isoDateTime)
      : new ZonedDateTime(timestamp * 1000)

    date.tz(tz)

    return date
  }

  /**
   * @returns {ZonedDateTime}
   */
  static fromLocalDateTimeString (localDateTime, tz) {
    const date = new ZonedDateTime()
    date.moment = moment.tz(localDateTime, formats.localDateTime, tz)

    return date
  }

  /**
   * @returns {ZonedDateTime}
   */
  static fromISO (isoString) {
    return new ZonedDateTime(isoString, formats.isoDateTime)
  }

  /**
   * @returns {ZonedDateTime}
   */
  static now () {
    return new ZonedDateTime(moment())
  }

  parse (date, format) {
    this.moment = moment(date, format).tz(config.app.timezone)
  }

  /**
   * @returns {string}
   */
  format (format = formats.shortDateTime, guess = true) {
    if (guess) {
      this.guessTz()
    }
    return this.moment.format(format)
  }

  guessTz () {
    this.tz(moment.tz.guess())
  }

  tz (tz) {
    this.moment.tz(tz)
  }

  /**
   * @returns {string}
   */
  ago () {
    return this.moment.tz(moment.tz.guess()).fromNow()
  }

  toISODateTime (guess = true) {
    return this.format(formats.isoDateTime, guess)
  }

  toTimestamp () {
    return this.moment.utc().unix()
  }
}
