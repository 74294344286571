import React, { useEffect, useState } from 'react'
import {
  queries,
  useAPIQuery,
  useCacheQuery,
  Resources,
  enums,
  WithPopulatedRelationships
} from '@exivity/data-layer'
import { Button, Field, Group, GroupBox, Label, MetaTable, Placeholder, Select } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { reduce, concat, map, prop, compose } from '@exivity/fp'
import { MdRefresh } from 'react-icons/md'

import { Formats, useDateFormatter } from '../../../../components/hooks/useDateFormatter'
import { get } from '../../../../API'
import { filters } from '../../../../components/pages/Administration/LogViewer'
import Lines from '../../../../components/pages/Administration/LogViewer/Lines'
import { renderRunStatus } from '../shared/renderRunStatus'

import { renderStepHeader } from './Step/Step'

const { WorkflowRunStatus } = enums

interface StepLogProps {
  step: Resources['workflowstep']
  run: Resources['workflowrun']
}

type Log = {
  date: string
  level: number
  message: string
  metadata: Record<string, any>
}

const mergeLogLines = compose(
  reduce(concat, [] as Log[]),
  map(prop('lines'))
)

function StepLog ({ run, step }: StepLogProps) {
  const formatTimestamp = useDateFormatter().convert(Formats.IsoDateTime, Formats.GuiDateTime)

  const [log] = useCacheQuery(
    queries
      .relationshipOf('workflowrun', run, 'steplogs')
      .filterByRelationship('step', 'equal', step)
  )

  const [lines, setLines] = useState<any[]|null>(null)

  useEffect(() => {
    if (log?.attributes?.logfile) {
      const options = {
        component: step.attributes.type,
        filter: log.attributes.logfile
      }

      get('/log', options)
        .then(data => {
          if (data.logfiles) {
            setLines(mergeLogLines(data.logfiles))
          }
        })
    }
  }, [step.attributes.type, log])

  if (!log) return null

  return (
    <GroupBox initialCollapsed>
      <GroupBox.Header>
        <GroupBox.Join gap={10}>
          {renderRunStatus(log)}
          {renderStepHeader(step)}
        </GroupBox.Join>
      </GroupBox.Header>
      <GroupBox.Content>

        <MetaTable>
          <MetaTable.Item
            label={translate('Start time')}
            value={formatTimestamp(log.attributes.start_date)} />
          <MetaTable.Item
            label={translate('End time')}
            value={log.attributes.end_date ? formatTimestamp(log.attributes.end_date) : ''} />
          <MetaTable.Item
            label={translate('Status')}
            value={`${log.attributes.status} with exit code ${log.attributes.exit_code}`} />
          <MetaTable.Item
            label={translate('Command')}
            value={<pre>{JSON.stringify(JSON.parse(log.attributes.log), null, 2)}</pre>} />

        </MetaTable>

        <Group title={translate('Output')} initialCollapsed={false}>
          <pre>
            {log.attributes.output || translate('No output')}
          </pre>
        </Group>

        <Group title={translate('Log')} initialCollapsed={false}>
          {Array.isArray(lines) && (step.attributes.type && (filters as any)[step.attributes.type])
            ? <Lines lines={lines} severityLabels={(filters as any)[step.attributes.type]} />
            : <Placeholder>{translate('No logfile found')}</Placeholder>}
        </Group>
      </GroupBox.Content>
    </GroupBox>
  )
}

interface StatusProps {
  workflow: WithPopulatedRelationships<'workflow', 'steps'|'schedules'>
}

export function Status ({ workflow }: StatusProps) {
  const formatTimestamp = useDateFormatter().convert(Formats.IsoDateTime, Formats.GuiDateTime)

  const runs = useCacheQuery(
    queries
      .relationshipOf('workflow', workflow, 'runs')
      .sortByAttribute('start_date', 'descending')
  )

  const queryAPI = useAPIQuery()
  const fetchRuns = () => queryAPI(queries.find('workflow', workflow), {
    sources: {
      server: {
        include: ['runs'],
        settings: {
          params: {
            'related[runs][limit]': 500,
            'related[runs][sort]': '-start_ts'
          }
        }
      }
    }
  })

  const [activeRun, setActiveRun] = useState<Resources['workflowrun']|null>(null)

  useEffect(() => {
    if (workflow.id) {
      fetchRuns()
    }
    setActiveRun(null)
  }, [workflow.id])

  useEffect(() => {
    if (activeRun) {
      queryAPI(queries.find('workflowrun', activeRun), {
        sources: {
          server: {
            include: ['steplogs', 'steplogs.step']
          }
        }
      })
    }
  }, [activeRun])

  if (!runs.length) {
    return <Placeholder>{translate('This workflow has never ran yet')}</Placeholder>
  }

  return (
    <div>
      <Group title='Run'>
        <Field.Container>
          <Field>
            <Label>{translate('Run')}</Label>
            <Field.Group>
              <Select
                searchable
                placeholder={translate('Select a date')}
                data={runs}
                value={activeRun?.id || undefined}
                valueAccessor={run => run.id}
                labelAccessor={(run) => (
                  formatTimestamp(run.attributes.start_date) + ' '
                  + (run.attributes.status === WorkflowRunStatus.Success
                    ? '✔'
                    : run.attributes.status === WorkflowRunStatus.Started
                      ? '◷'
                      : '⚠')
                )}
                onChange={(id) => setActiveRun(runs.find(run => run.id === id) || null)} />
              <Button round outlined small secondary icon={<MdRefresh />} onClick={fetchRuns} />
            </Field.Group>
          </Field>
        </Field.Container>
      </Group>

      <Group title={translate('Step logs')}>
        {activeRun
          ? (
            workflow.relationships.steps.data
              .map(step => <StepLog key={step.id} step={step} run={activeRun} />)
          )
          : <Placeholder>{translate('Select a run')}</Placeholder>}
      </Group>
    </div>
  )
}
