import { createSelector } from 'reselect'

import store from '../data/store'

export const listUsers = createSelector(
  (users) => users,
  (users) => users.map(user => {
    const usergroupName = store.cache.query(
      q => q.findRecord(user.relationships.usergroup.data)
    ).attributes.name

    return {
      ...user,
      key: user.id,
      attributes: {
        ...user.attributes,
        usergroup: usergroupName
      }
    }
  })
)

export const getAccountUsersTable = createSelector(
  (users) => users,
  (users) => users.map((user) => ({
    ...user.attributes,
    key: user.id
  }))
)
