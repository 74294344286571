import { chainFrom, range } from 'transducist'
import { queries, useCacheQuery } from '@exivity/data-layer'

const lvlLabels: Record<
number,
'lvl1_label'|'lvl2_label'|'lvl3_label'|'lvl4_label'|'lvl5_label'
> = {
  1: 'lvl1_label',
  2: 'lvl2_label',
  3: 'lvl3_label',
  4: 'lvl4_label',
  5: 'lvl5_label'
}

const lvlNameCol: Record<
number,
'lvl1_name_col'|'lvl2_name_col'|'lvl3_name_col'|'lvl4_name_col'|'lvl5_name_col'
> = {
  1: 'lvl1_name_col',
  2: 'lvl2_name_col',
  3: 'lvl3_name_col',
  4: 'lvl4_name_col',
  5: 'lvl5_name_col'
}

// @todo Ask whether we need to check min account level?
export function useReportLevelLabels (reportId: string|null) {
  const report = useCacheQuery(queries.find('report', reportId))

  if (!report) return []

  return chainFrom(range(1, report.attributes.depth + 1)).map((level) => {
    return [
      level,
      report.attributes[lvlLabels[level]] || report.attributes[lvlNameCol[level]]
    ] as [number, string]
  }).toArray()
}
