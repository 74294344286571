import { disableSearch, enableSearch } from '../actions/gui'
import { workActions } from '../domains/work/state'

import { debug } from './../utils/log'
import store from './../store'
import importSyncer from './syncer/import'

// Variable containing the thread we're working on
let thread

export default function syncer (options) {
  if (thread !== undefined) {
    return Promise.reject(new Error('Syncer already running'))
  }

  // Start our background work
  store.dispatch(workActions.addThread('background'))

  // Disable search
  store.dispatch(disableSearch())

  return new Promise((resolve, reject) => {
    Promise.resolve()
      .then(() => importSyncer(thread, options))
      .then(resolve)
      .catch(reject)
      .then(() => {
        // Clear the thread
        debug('Syncer is now completed')
        store.dispatch(workActions.removeThread('background'))
        thread = undefined

        // Re-enable search
        store.dispatch(enableSearch())
      })
  })
}
