"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectedLanguage = exports.supportedLanguages = exports.setLanguage = exports.translate = exports.translator = exports.singleLine = void 0;
var singleLine_1 = require("./singleLine");
Object.defineProperty(exports, "singleLine", { enumerable: true, get: function () { return singleLine_1.singleLine; } });
var translators_1 = require("./translators");
Object.defineProperty(exports, "translator", { enumerable: true, get: function () { return translators_1.translator; } });
var translate_1 = require("./translate");
Object.defineProperty(exports, "translate", { enumerable: true, get: function () { return translate_1.translate; } });
Object.defineProperty(exports, "setLanguage", { enumerable: true, get: function () { return translate_1.setLanguage; } });
Object.defineProperty(exports, "supportedLanguages", { enumerable: true, get: function () { return translate_1.supportedLanguages; } });
Object.defineProperty(exports, "selectedLanguage", { enumerable: true, get: function () { return translate_1.selectedLanguage; } });
