import React from 'react'
import PropTypes from 'prop-types'

import { classNames } from '../../../utils/misc'
/**
 * @type {React.ComponentType<any>}
 */
const Row = ({ children, divider, noMatchHeight, className, sticky }) => (
  <div
    className={classNames({
      'uk-grid-divider': divider,
      'uk-grid-match': !noMatchHeight,
      'ex-layout__row--sticky': sticky,
      'ex-layout__row--sticky-top': sticky === true || sticky === 'top',
      'ex-layout__row--sticky-bottom': sticky === 'bottom'
    }, className, 'uk-grid-medium uk-child-width-expand@s')}
    data-uk-grid>
    {children}
  </div>
)

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  divider: PropTypes.bool,
  noMatchHeight: PropTypes.bool,
  sticky: PropTypes.oneOf([true, false, 'top', 'bottom'])
}

Row.defaultProps = {
  divider: false,
  noMatchHeight: false,
  className: null,
  sticky: false
}

export default Row
