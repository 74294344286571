"use strict";
/**
 * @description () -> a
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.id = void 0;
var id = function () { return function (data) {
    return data;
}; };
exports.id = id;
