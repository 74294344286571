import React from 'react'
import { format } from 'date-fns'
import compose from 'lodash/fp/compose'
import { translate } from '@exivity/translations'
import { queries, useCacheQuery, Resources, enums } from '@exivity/data-layer'

import { WrapIf } from '../../../../../components/atoms/WrapIf'
import { useDateFormatter, Formats } from '../../../../../components/hooks/useDateFormatter'

const { WorkflowScheduleFrequency } = enums

interface PlainScheduleColumnProps {
  workflow: Resources['workflow']
}

export function ScheduleColumn ({ workflow }: PlainScheduleColumnProps) {
  const formatter = useDateFormatter()

  const [activeSchedule] = useCacheQuery(
    queries
      .relationshipOf('workflow', workflow, 'schedules')
      // Filter out schedules that have yet to become active
      .filterByAttribute('start_time', 'lt', formatter.format(Formats.IsoDateTime, Date.now()))
      .sortByAttribute('start_time', 'descending')
  )

  if (!activeSchedule) {
    return (
      <span className='uk-text-muted'>
        {translate('Not scheduled')}
      </span>
    )
  }

  const timeFormat = activeSchedule.attributes.frequency === WorkflowScheduleFrequency.Hourly
    ? Formats.GuiMinutes
    : Formats.GuiTime

  const time = compose(
    formatter.formatUsingTimeZone(timeFormat, activeSchedule.attributes.timezone),
    formatter.parse(Formats.IsoDateTime)
  )(activeSchedule.attributes.start_time)

  const frequency = activeSchedule.attributes.frequency === WorkflowScheduleFrequency.Monthly
    ? translate('monthly ({day_of_month})', format(formatter.parse(Formats.IsoDateTime, activeSchedule.attributes.start_time), 'do'))
    : activeSchedule.attributes.frequency

  return (
    <WrapIf
      condition={String(activeSchedule.attributes.frequency_modifier) !== '1'}
      wrapper={node => {
        return (
          <abbr title={
            {
              [WorkflowScheduleFrequency.Hourly]: translate('Every {modifier} hours', activeSchedule.attributes.frequency_modifier),
              [WorkflowScheduleFrequency.Daily]: translate('Every {modifier} days', activeSchedule.attributes.frequency_modifier),
              [WorkflowScheduleFrequency.Monthly]: translate('Every {modifier} months', activeSchedule.attributes.frequency_modifier)
            }[activeSchedule.attributes.frequency]
          }>
            {node}
          </abbr>
        )
      }}>
      {frequency} {time}
    </WrapIf>
  )
}
