import fileSaver from 'file-saver'
import Papa from 'papaparse'
import XLSX from 'xlsx'
import mapValues from 'lodash/fp/mapValues'

import store from '../store'
import { configurationSelectors } from '../domains/administration/state/configuration'

import { warn } from './log'

export const print = () => {
  window.print()
}

export const chartToPng = (chart) => {
  try {
    chart.export.capture({}, function () {
      this.toPNG({ multiplier: 3 }, function (base64) {
        this.toBlob({
          data: base64,
          type: this.defaults.formats.PNG.mimeType
        }, function (blob) {
          fileSaver.saveAs(blob, 'chart.png')
        })
      })
    })
  } catch (e) {
    warn('Can\'t export graph.')
  }
}

export const chartToSvg = (chart) => {
  try {
    chart.export.capture({}, function () {
      this.toSVG({}, function (base64) {
        this.toBlob({
          data: base64,
          type: this.defaults.formats.SVG.mimeType
        }, function (blob) {
          fileSaver.saveAs(blob, 'chart.svg')
        })
      })
    })
  } catch (e) {
    warn('Can\'t export graph.')
  }
}

function replaceDecimalSeparator (entry) {
  return String(entry).replace('.', configurationSelectors.getCSVDecimalSeparator(store.getState()))
}

/**
 * @param {string|null} entry
 */
function escapeDangerousCharacters (entry) {
  const CSV_INJECTION_ENABLERS = ['=', '-', '+', '@']
  const ESCAPE_CHARACTER = "'"

  // Workaround for security flaws in excel
  // https://exivity.atlassian.net/browse/EXVT-3376
  return CSV_INJECTION_ENABLERS.some(char => entry?.startsWith(char))
    ? ESCAPE_CHARACTER + entry
    : entry
}

function mapCells (table, fn) {
  return table.map(mapValues(fn))
}

export const tableToCsv = (dirtyTable, name = 'table') => {
  const cleanTable = mapCells(dirtyTable, (cell) => (
    typeof cell === 'number'
      ? replaceDecimalSeparator(cell)
      : escapeDangerousCharacters(cell)
  ))

  const parsed = Papa.unparse(cleanTable, {
    delimiter: configurationSelectors.getCSVDelimiter(store.getState())
  })

  const blob = new Blob([parsed], { type: 'text/csv;charset=utf-8' })
  fileSaver.saveAs(blob, `${name}.csv`)
}

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
  return buf
}

export const tableToXlsx = (data, name = 'table') => {
  const wb = XLSX.utils.book_new()
  wb.SheetNames.push('Sheet')
  const ws = XLSX.utils.aoa_to_sheet(data)
  wb.Sheets.Sheet = ws
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  fileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${name}.xlsx`)
}

export const tableToXls = (data, name = 'table') => {
  const wb = XLSX.utils.book_new()
  wb.SheetNames.push('Sheet')
  const ws = XLSX.utils.aoa_to_sheet(data)
  wb.Sheets.Sheet = ws
  const wbout = XLSX.write(wb, { bookType: 'xls', type: 'binary' })
  fileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${name}.xls`)
}

export const chartTo = (type, chart) => {
  switch (type) {
    case 'png':
      chartToPng(chart)
      break

    case 'svg':
      chartToSvg(chart)
      break

    default:
  }
}

export const tableTo = (type, table, name) => {
  switch (type) {
    case 'csv':
      tableToCsv(table, name)
      break
    case 'xlsx':
      tableToXlsx(table, name)
      break
    case 'xls':
      tableToXls(table, name)
      break

    default:
  }
}
