import { createSlice } from '@reduxjs/toolkit'
import { addMonths, format, startOfMonth } from 'date-fns'

import { ToggleSummaryKeys } from '../../../../components/pages/Reports/shared/toggleSummaryKeys'
import migrator from '../../../../store/enhancers/migrator'

import {
  updateDateRange,
  updateGranularity,
  updateLimit,
  updateInstanceGroupBy,
  updateReport,
  updateDepth,
  updateMinimumAccessibleDepth,
  updateAccount,
  updateParentAccount,
  updateCategory,
  updateService,
  drillDown
} from './global'
import {
  updateSearchFilter,
  updateSearchTerm,
  updateColumns,
  updateColumn,
  updateSummarizeBy,
  pin,
  unpin,
  resetPins,
  includeInSummary,
  updateSummaryConsolidated
} from './reports'
import {
  SummaryGroupInstancesBy,
  Granularity
} from './filterTypes'

export const FORMAT = 'yyyyMMdd'

const initialState = {
  report: null as string | null,
  depth: 1,
  minimumAccessibleDepth: 1,
  parentAccount: null as string | null,
  account: null as string | null,
  service: null as string | null,
  category: null as string | null,

  dateRange: [
    format(startOfMonth(new Date()), FORMAT),
    format(startOfMonth(addMonths(new Date(), 1)), FORMAT)
  ],
  granularity: Granularity.Day,
  limit: 10,

  accounts: {
    search: { by: 'lvl1_name', term: '' },
    columns: ['charge', 'cogs', 'net'],
    column: 'charge' as 'charge' | 'cogs' | 'net',
    pinned: [] as string[],
    summarizeBy: 'all' as ToggleSummaryKeys,
    includedInSummary: false
  },

  services: {
    search: { by: 'service', term: '' },
    columns: ['charge', 'cogs', 'net', 'quantity', 'unit'],
    column: 'charge' as 'charge' | 'cogs' | 'net' | 'quantity',
    pinned: [] as string[],
    summarizeBy: 'all' as ToggleSummaryKeys,
    includedInSummary: true
  },

  instances: {
    search: { by: 'instance', term: '' },
    columns: ['charge', 'cogs', 'net', 'quantity', 'unit'],
    column: 'charge' as 'charge' | 'cogs' | 'net' | 'quantity',
    pinned: [] as string[],
    summarizeBy: 'all' as ToggleSummaryKeys,
    groupBy: SummaryGroupInstancesBy.Instances as SummaryGroupInstancesBy,
    includedInSummary: false
  },

  summary: {
    consolidated: false
  }
}

export type ReportFilterState = typeof initialState

export const { actions, reducer } = createSlice({
  name: 'report/filters',
  initialState,
  reducers: {
    updateDateRange,
    updateGranularity,
    updateLimit,
    updateReport,
    updateDepth,
    updateMinimumAccessibleDepth,
    updateAccount,
    updateParentAccount,
    updateCategory,
    updateService,
    drillDown,
    updateSearchFilter,
    updateSearchTerm,
    updateColumns,
    updateColumn,
    updateSummarizeBy,
    updateInstanceGroupBy,
    pin,
    unpin,
    resetPins,
    includeInSummary,
    updateSummaryConsolidated,
    loadSlice: (state, action) => (
      migrator({
        currentState: state,
        incomingState: action.payload
      })
    )
  }
})
