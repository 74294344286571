import { withData } from 'react-orbitjs'

import { MetadataView } from './MetadataView'

const mapRecordsToProps = ({ definitionId }) => {
  if (definitionId) {
    return {
      definition: (q) => q.findRecord({ type: 'metadatadefinition', id: definitionId }),
      metadata: (q) => q.findRelatedRecords({
        type: 'metadatadefinition',
        id: definitionId
      }, 'metadata'),
      reports: (q) => q.findRecords('report'),
      datasets: (q) => q.findRecords('dset')
    }
  }

  return {}
}

export default withData(mapRecordsToProps)(MetadataView)
