import { DateCalendar } from './DateCalendar';
import { ModePicker } from './ModePicker';
import { DateRangeCalendar } from './DateRangeCalendar';
import { StyledYears, StyledQuarters, StyledMonths, StyledDays } from './CalendarModes';
export { CalendarMode } from './CalendarModes';
export var Calendar = {
    // Types of calendar components
    Date: DateCalendar,
    DateRange: DateRangeCalendar,
    // Wrappers
    ModePicker: ModePicker,
    // CSS selectors for styled-components
    Years: StyledYears,
    Quarters: StyledQuarters,
    Months: StyledMonths,
    Days: StyledDays
};
