import { fetchWithHooks } from '../../fetchWithHooks/fetchWithHooks'
import {
  handleResponseErrors,
  handleJsonResponse,
  handleWorkerThreads,
  iff,
  parseJson,
  showErrorToUser
} from '../../hooks'
import { fetchWithTimeout } from '../../fetch/fetchWithTimeout'

import {
  groupResultByAccount,
  addRunReportParams,
  checkRunReportResult,
  enrichResult
} from './reportHooks'

export type Report = 'accounts'|'services'|'instances'|'summaryServices'|'summaryInstances'

export const runReport = (
  report: Report,
  dimension: string,
  endpoint: string,
  fetchOptions: RequestInit,
  otherOptions: any
) => fetchWithHooks({
  before: [
    handleWorkerThreads('foreground'),
    addRunReportParams(report, dimension)
  ],
  after: [
    handleResponseErrors,
    handleJsonResponse,
    parseJson,
    checkRunReportResult,
    enrichResult(report),
    iff(() => report === 'summaryServices' || report === 'summaryInstances',
      groupResultByAccount
    ),
    handleWorkerThreads('foreground')
  ],
  error: [
    handleWorkerThreads('foreground'),
    handleResponseErrors,
    showErrorToUser
  ]
}, fetchWithTimeout)(endpoint, fetchOptions, otherOptions)
