import React from 'react'
import { Form, Heading, Button, useModal, Tabs } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useCrud } from 'react-crud-hook'

import { UserGroupModel, createUserGroup } from '../../../../data/types'
import { useCanRenderFeature } from '../../../../components/organisms/Feature/Feature'

import { SamlProvisioningTab } from './Tabs/SamlProvisioningTab'
import { PermissionsTab } from './Tabs/PermissionsTab'

const defaultUserGroup = createUserGroup()

interface UserGroupProps {
  userGroup?: UserGroupModel
}

export function UserGroup ({ userGroup }: UserGroupProps) {
  const { warning } = useModal()

  const canRenderFeature = useCanRenderFeature()

  const crudUserGroup = useCrud<UserGroupModel>(userGroup || defaultUserGroup)

  function saveUserGroup () {
    crudUserGroup.save({
      onCreate: (record: any, { router }: any) => {
        router.push('administration/user-management/usergroups/' + record.id)
      }
    })
  }

  function deleteUserGroup () {
    warning({ body: translate('Are you sure you want to delete this usergroup?') }).then(() => {
      crudUserGroup.delete({
        onDelete: (_: any, { router }: any) => {
          router.push('administration/user-management/usergroups')
        }
      })
    })
  }

  return (
    <Form onSubmit={saveUserGroup}>
      <Heading.Input
        placeholder={translate('Name')}
        label={translate('Name')}
        required
        autoFocus={!crudUserGroup.id}
        value={crudUserGroup.attributes.name}
        onChange={crudUserGroup.setAttribute('name')} />
      {canRenderFeature('claimsBasedAccessProvisioning')
        ? (
          <Tabs>
            <Tabs.TabList>
              <Tabs.Tab data-testid='tab-permissions'>{translate('Permissions')}</Tabs.Tab>
              <Tabs.Tab data-testid='tab-provisioning'>{translate('Provisioning')}</Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanels>
              <Tabs.TabPanel>
                <PermissionsTab
                  permissions={crudUserGroup.attributes.permissions}
                  onChangePermissions={crudUserGroup.setAttribute('permissions')} />
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                <SamlProvisioningTab
                  provisioning={crudUserGroup.attributes.saml_provisioning}
                  onChangeProvisioning={crudUserGroup.setAttribute('saml_provisioning')} />
              </Tabs.TabPanel>
            </Tabs.TabPanels>
          </Tabs>
        )
        : (
          <PermissionsTab
            permissions={crudUserGroup.attributes.permissions}
            onChangePermissions={crudUserGroup.setAttribute('permissions')} />
        )
      }

      <Form.Toolbar>
        {!crudUserGroup.id
          ? <Button.Create />
          : (
            <>
              <Button.Delete onClick={deleteUserGroup} />
              <Button.Update />
            </>
          ) }
      </Form.Toolbar>
    </Form>
  )
}
