import React from 'react'
import styled, { css } from 'styled-components'
import { fromTheme, Icon } from '@exivity/ui'
import { MdHourglassEmpty } from 'react-icons/md'

import { formats, ZonedDateTime } from '../../../../../../utils/date'
import { WorkflowRunStatus, WorkflowRunModel } from '../../../../../../data/types/workflowrun'

import { getStatusColor } from './helpers'

const StyledRunStatuses = styled.div<{ statusAmount: number }>`
  --status-spacing: ${fromTheme(theme => theme.space[4])};
  --status-diameter: ${fromTheme(theme => theme.global.sizes.huge)}em;

  --margin-right: ${fromTheme(theme => theme.space[2])};
  --margin-top: ${fromTheme(theme => theme.space[3])};

  margin-right: var(--margin-right);
  margin-top: var(--margin-top);
  
  display: flex;
  flex-direction: row-reverse;
  min-width: 400px;
  width: 100%;

  position: relative;

  &:before {
    --line-length: (var(--status-spacing) + var(--status-diameter));

    position: absolute;
    width: ${(props) => `calc(var(--line-length) * ${props.statusAmount - 1})`};
    height: 1px;
    background-color: #aaa;
    content: '';
    top: 32px;
    right: calc(var(--margin-right) + var(--status-spacing));
  }
`

interface StatusProps {
  status: WorkflowRunStatus | 0
}

const StyledStatus = styled.div<StatusProps>`
  margin-right: var(--status-spacing);

  width: var(--status-diameter);
  height: var(--status-diameter);

  .date {
    width: 20px;
    height: 10px;
    transform: rotateZ(-35deg);
    margin-bottom: 10px;
    margin-left: 12px;
    white-space: nowrap;
    font-size: 12px;

    .time {
      margin-left: 5px;
    }

    &.start .time {
      color: ${fromTheme(theme => theme.colors.blue)};
    }

    &.end .time {
      color: ${fromTheme(theme => theme.colors.gray)};
    }

  }

  .status-icon {
    width: var(--status-diameter);
    height: var(--status-diameter);
    border-radius: 999px;

    position: absolute;
    background-color: ${(props) => getStatusColor(props.status)};

    display: flex;
    align-items: center;
    justify-content: center;

    ${Icon} {
      color: white;
    }
    
    ${(props) => props.status === null && css`
      border: 0.5px ${fromTheme(theme => theme.colors.black)} solid;
      background-color: white;
    `}  
  }
`

interface WorkflowRunProps {
  runs: WorkflowRunModel[]
}

export function WorkflowRuns ({ runs }: WorkflowRunProps) {
  return (
    <StyledRunStatuses statusAmount={runs.length}>
      {runs.map(run => {
        const { end_date, start_date, status } = run.attributes

        const time = new ZonedDateTime(end_date || start_date).format(formats.time)
        const date = new ZonedDateTime(end_date || start_date).format(formats.numericMonthDay)

        return (
          <StyledStatus key={run.id} status={status}>
            <div className={`date ${status === 'started' ? 'start' : 'end'}`}>
              {date}
              <span className='time'>{time}</span>
            </div>

            <div className='status-icon'>
              {status === 'started' && (
                <Icon><MdHourglassEmpty /></Icon>
              )}
            </div>

          </StyledStatus>
        )
      })}
    </StyledRunStatuses>
  )
}
