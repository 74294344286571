import { Record, RecordRelationship, RecordHasOneRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

export interface WorkflowRunModel extends Record {
  type: 'workflowrun'
  attributes: WorkflowRunAttributes
  relationships?: WorkflowRunRelationships
}

export enum WorkflowRunStatus {
  Started = 'started',
  Success = 'success',
  Failed = 'failed',
  TimedOut = 'timed_out',
  Invalid = 'invalid',
  InternalError = 'internal_error'
}

export interface WorkflowRunAttributes {
  start_date: string
  end_date: string
  status: WorkflowRunStatus
}

export interface WorkflowRunRelationships extends Dict<RecordRelationship> {
  workflow: RecordHasOneRelationship
  steplog: RecordHasOneRelationship
}
