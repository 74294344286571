import { Record, RecordRelationship, RecordHasManyRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

export interface EnvironmentAttributes {
  name: string
  default_flag: boolean
}

export interface EnvironmentRelationships extends Dict<RecordRelationship> {
  variables: RecordHasManyRelationship
}

export interface EnvironmentModel extends Record {
  type: 'environment'
  attributes: EnvironmentAttributes
  relationships?: EnvironmentRelationships
}

export const DEFAULT_ENVIRONMENT: EnvironmentModel = {
  id: '',
  type: 'environment',
  attributes: {
    name: '',
    default_flag: false
  }
}
