var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themeColor, themeSpace } from '../Theme';
// @ts-ignore
import avatar from './avatar.jpg';
var Avatar = styled.img.attrs({ src: avatar, alt: 'Avatar' })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n"], ["\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n"])));
export var Separator = styled.hr(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: content-box;\n  height: 0;\n  overflow: visible;\n  text-align: inherit;\n  margin: 0 0 20px;\n  border: 0;\n  border-top: 1px solid #e5e5e5;\n"], ["\n  box-sizing: content-box;\n  height: 0;\n  overflow: visible;\n  text-align: inherit;\n  margin: 0 0 20px;\n  border: 0;\n  border-top: 1px solid #e5e5e5;\n"])));
var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  min-width: 250px;\n  align-items: center;\n  \n  h4 {\n    color: #333;\n    font-size: 1.25rem;\n    line-height: 1.4;\n    font-weight: 400;\n    margin: 0;\n  }\n  \n  p {\n    color: ", ";\n    font-size: .875rem;\n    line-height: 1.4;\n    margin: 0;\n  }\n  \n  & > *:not(:last-child) {\n    margin-right: ", ";\n  }\n"], ["\n  display: flex;\n  min-width: 250px;\n  align-items: center;\n  \n  h4 {\n    color: #333;\n    font-size: 1.25rem;\n    line-height: 1.4;\n    font-weight: 400;\n    margin: 0;\n  }\n  \n  p {\n    color: ", ";\n    font-size: .875rem;\n    line-height: 1.4;\n    margin: 0;\n  }\n  \n  & > *:not(:last-child) {\n    margin-right: ", ";\n  }\n"])), themeColor('gray'), themeSpace(1));
export function AppBarAvatar(_a) {
    var name = _a.name, email = _a.email;
    return (_jsxs(Container, { children: [_jsx(Avatar, {}, void 0), _jsxs("div", { children: [_jsx("h4", { children: name }, void 0), _jsx("p", { children: email }, void 0)] }, void 0)] }, void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
