import { Record } from '@orbit/data'

export function getRelationship (item: any, property: string, defaultValue: any) {
  return (item
    && item.relationships
    && item.relationships[property]
    && item.relationships?.[property]?.data) || defaultValue
}

export function updateAttribute<
  T extends Record,
  K extends keyof T['attributes']> (item: T, property: K, value: T['attributes'][K]) {
  return {
    ...item,
    attributes: {
      ...(item.attributes || {}),
      [property]: value
    }
  }
}
