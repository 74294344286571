var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { createListStrategy } from '../../List';
import { orderBy, classNames } from '../../utils';
import { fromTheme } from '../../Theme';
var StyledItem = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:hover, &:focus {\n    background-color: ", ";\n  }\n\n  &.active {\n    color: ", ";\n  }\n\n  .sortable & {  \n    &:active {\n      cursor: grabbing;\n    }\n\n    .columns > :first-child {\n      padding-left: ", "em;\n    }\n  }\n"], ["\n  cursor: pointer;\n  position: relative;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:hover, &:focus {\n    background-color: ", ";\n  }\n\n  &.active {\n    color: ", ";\n  }\n\n  .sortable & {  \n    &:active {\n      cursor: grabbing;\n    }\n\n    .columns > :first-child {\n      padding-left: ", "em;\n    }\n  }\n"])), fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.global.purposes.primary; }), fromTheme(function (theme) { return theme.global.baseSpacing * 1.5; }));
export function createFlatStrategy(data, _a) {
    var order = _a.order, isActive = _a.isActive, sortable = _a.sortable, onClick = _a.onClick, renderColumns = _a.renderColumns;
    return createListStrategy(data, {
        prepareData: function (data) {
            return order
                ? orderBy(order, data)
                : data;
        },
        renderItem: function (_a) {
            var item = _a.item;
            var active = (isActive === null || isActive === void 0 ? void 0 : isActive(item)) || false;
            return sortable(_jsx(StyledItem, __assign({ tabIndex: 0, "data-active": active, className: classNames({ active: active }), onClick: function () { return onClick === null || onClick === void 0 ? void 0 : onClick(item); } }, { children: renderColumns(item) }), void 0), { item: item, slot: 'listitem' });
        }
    });
}
var templateObject_1;
