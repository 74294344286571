import { parse, parseISO, formatISO, format } from 'date-fns'
import { format as formatTz, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import curry from 'lodash/curry'

type Formats = { [key: string]: string }

function convertUtcToZonedTime (timeZone: string, date: Date|number|string) {
  return utcToZonedTime(date, timeZone)
}

function convertZonedTimeToUtc (timeZone: string, date: Date|number|string) {
  return zonedTimeToUtc(date, timeZone)
}

// if you need prototype inheritance then convert functions to methods and bind
export class DateFormatter<T extends Formats> {
  constructor (private formats: T) {}

  private parseToDate = (formatType: keyof T, date: string) => (
    parse(date, this.formats[formatType], new Date())
  )

  private formatToString = (formatType: keyof T, date: Date | number) => (
    format(date, this.formats[formatType])
  )

  private formatToStringUsingTimeZone = (
    formatType: keyof T,
    timeZone: string,
    date: Date | number
  ) => (
    formatTz(convertUtcToZonedTime(timeZone, date), this.formats[formatType], { timeZone })
  )

  private formatRangeToString = (formatType: keyof T, range: [Date, Date]) => {
    const start = format(range[0], this.formats[formatType])
    const end = format(range[1], this.formats[formatType])

    if (start === end) {
      return start
    }
    return `${start} - ${end}`
  }

  private convertFormat = (
    from: keyof T,
    to: keyof T,
    formattedDate: string
  ): string => format(
    parse(formattedDate, this.formats[from], new Date()),
    this.formats[to]
  )

  public getFormat = (formatType: keyof T) => this.formats[formatType]

  public parse = curry(this.parseToDate)

  public parseISO = parseISO

  /**
   * Always returns a date string in the systems timezone.
   */
  public format = curry(this.formatToString)

  public formatISODate = (date: Date) => formatISO(date, { representation: 'date' })

  /**
   * Converts the passed in date to the target timeZone before formatting.
   */
  public formatUsingTimeZone = curry(this.formatToStringUsingTimeZone)

  public formatRange = curry(this.formatRangeToString)

  public convert = curry(this.convertFormat)

  public utcToZonedTime = curry(convertUtcToZonedTime)

  public zonedTimeToUtc = curry(convertZonedTimeToUtc)
}
