import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@exivity/ui'
import { translate } from '@exivity/translations'

import Footer from '../../../../components/structural/Footer'
import LoadingOverlay from '../../../../components/molecules/LoadingOverlay'
import { authSelectors } from '../../state'
import { workSelectors } from '../../../work/state'
import { Center } from '../Login/Login'
import { useLogout } from '../../hooks'

import './Logout.css'

function Logout () {
  const logout = useLogout()
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const busySigningOut = useSelector(authSelectors.isSigningOut)

  const isLoading = useSelector(workSelectors.isAnyChannelBusy)

  return (
    <Center>
      <div style={{ width: 420 }} className='uk-margin-top'>
        <div className='ex-logout uk-background-default uk-position-relative'>
          {isAuthenticated && !busySigningOut
            ? (
              <>
                <h3>{translate('Are you sure you want to sign out?')}</h3>
                <Button
                  secondary
                  onClick={() => logout()}>
                  {translate('Sign out')}
                </Button>
              </>
            )
            : (
              <>
                {isLoading && <LoadingOverlay />}
                <h3>{translate('Logging out..')}</h3>
              </>
            )
          }
        </div>
      </div>
      <Footer />
    </Center>
  )
}

export default Logout
