var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement } from 'react';
import styled from 'styled-components';
import { useClientRect } from '../useClientRect';
import { matchThemeProp } from '../Theme';
var StyledAdornment = styled.i(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  position: absolute;\n  height: 100%;\n  top: 0;\n\n  font-size: ", "px;\n  \n  pointer-events: none;\n"], ["\n  display: flex;\n  align-items: center;\n  position: absolute;\n  height: 100%;\n  top: 0;\n\n  font-size: ", "px;\n  \n  pointer-events: none;\n"])), matchThemeProp(function (theme) { return theme.global.sizes; }, { modifier: function (em) { return em * 20; } }));
var AdornmentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  \n  .adornment {\n     &__left {\n       left: ", "px;\n     }\n     \n      &__right {\n       right: ", "px;\n     }\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  \n  .adornment {\n     &__left {\n       left: ", "px;\n     }\n     \n      &__right {\n       right: ", "px;\n     }\n  }\n"])), function (props) { return props.inset; }, function (props) { return props.inset; });
export var Adornment = function (_a) {
    var left = _a.left, right = _a.right, children = _a.children, _b = _a.inset, inset = _b === void 0 ? 10 : _b, _c = _a.test, test = _c === void 0 ? 'adornment' : _c, className = _a.className;
    var _d = useClientRect(), leftRect = _d[0], leftRef = _d[1];
    var _e = useClientRect(), rightRect = _e[0], rightRef = _e[1];
    var paddingLeft = leftRect ? (leftRect.width + inset) : 0;
    var paddingRight = rightRect ? (rightRect.width + inset) : 0;
    var newProps = __assign(__assign({}, children.props), { style: __assign({ paddingRight: paddingRight, paddingLeft: paddingLeft, width: '100%' }, children.props.style || {}) });
    return (_jsxs(AdornmentWrapper, __assign({ className: className, inset: inset, "data-test": test + "-wrapper" }, { children: [cloneElement(children, newProps), _jsx(StyledAdornment, __assign({ ref: leftRef, className: 'adornment__left', "data-test": "left-" + test }, { children: left }), void 0), _jsx(StyledAdornment, __assign({ ref: rightRef, className: 'adornment__right', "data-test": "right-" + test }, { children: right }), void 0)] }), void 0));
};
var templateObject_1, templateObject_2;
