import React from 'react'
import { Button } from '@exivity/ui'
import { MdAdd, MdRemove } from 'react-icons/md'

import BasicTable from '../Table/BasicTable'
import { mapRecursive } from '../../../utils/recursive'
import { pureSplice } from '../../../utils/array'

import Header from './Header'
import Row from './Row'
import ValuesCallback from './ValuesCallback'
import Cell from './Cell'

import './index.css'

type Child = React.ReactComponentElement<any>

export type ValueRow = {
  [inputName: string]: any
}

interface ExpandableTableProps {
  columnWidths: string[]
  valueRows: ValueRow[]
  onChange?(valueRows: ValueRow[]): void
  rowLimit?: number
  onAdd: () => void
  onRemove: (lineItem: number) => void
  children: Child[]
}

export class ExpandableTable extends React.PureComponent<ExpandableTableProps> {
  tableRows: Child[]
  maxCellsPerLineItem: Child[]

  constructor (props: ExpandableTableProps) {
    super(props)

    this.tableRows = []
    this.getRowsAndCallbacks()

    this.maxCellsPerLineItem = []
    this.getMaxCellsPerLineItem()
  }

  getRowsAndCallbacks = () => {
    React.Children.map(this.props.children, (child: Child): void => {
      if (typeof child.type !== 'string') {
        if (child.type
          && (child.type.displayName === Row.displayName
            || child.type.displayName === ValuesCallback.displayName
          )) {
          this.tableRows.push(child)
        }
      }
    })
  }

  // TODO Could also just count headers
  getMaxCellsPerLineItem = () => {
    React.Children.map(this.tableRows, (row): void => {
      const count = React.Children.count(row.props.children)

      if (count > React.Children.count(this.maxCellsPerLineItem)) {
        this.maxCellsPerLineItem = row.props.children
      }
    })
  }

  renderCol = (colWidth: string, index: number): Child => <col key={index} width={colWidth} />

  renderHeaders = () => React.Children.map(this.props.children,
    (child: Child): Child | null => {
      if (typeof child.type !== 'string') {
        if (child.type.displayName === Header.displayName) {
          return child
        }
      }

      return null
    })

  renderEmptyCells = () => React.Children.map(this.maxCellsPerLineItem,
    (child: Child, index: number): Child | null => {
      if (typeof child.type !== 'string') {
        if (child.type.displayName === Cell.displayName
          || child.type.displayName === ValuesCallback.displayName) {
          return <td key={index} />
        }
      }

      return null
    })

  renderLineItem = (valueRow: ValueRow, lineItem: number) => {
    return React.Children.map(this.tableRows, (tableRow, index): Child => {
      if (typeof tableRow.type !== 'string') {
        if (tableRow.type.displayName === ValuesCallback.displayName) {
          return tableRow.props.children(lineItem, valueRow)
        }
      }

      const cells = React.Children.map(tableRow.props.children, (cell: Child): Child => {
        if (typeof cell.type !== 'string') {
          if (cell.type.displayName === ValuesCallback.displayName) {
            return cell.props.children(lineItem, valueRow)
          }

          if (cell.type.displayName === Cell.displayName) {
            if (cell.props && cell.props.children) {
              return React.cloneElement(cell, {
                ...cell.props,
                children: mapRecursive(cell.props.children,
                  (component: any) => this.injectComponent(lineItem, component, valueRow))
              })
            }
          }
        }

        if (!cell.props) {
          return cell
        }

        if (cell.props.index) {
          return <td>{lineItem + 1}.</td>
        }

        return cell
      })

      const rowChildren = (
        <>
          {cells}
          {index === 0
            ? (
              <td className='ex-expandable-table__minus'>
                <Button title='Remove column'
                  type='button'
                  round
                  small
                  danger
                  onClick={() => this.props.onRemove(lineItem)}>
                  <MdRemove />
                </Button>
              </td>
            )
            : <td />
          }
        </>
      )

      return React.cloneElement(tableRow, {
        ...tableRow.props,
        value: this.getValue(valueRow, tableRow)
      }, rowChildren)
    })
  }

  getValue = (valueRow: ValueRow, component: Child): any => {
    const value = valueRow
      ? valueRow[component.props.name]
      : undefined

    return this.formatValue(value)
  }

  formatValue = (value: any): any => {
    if (typeof value === 'object'
      && Object.prototype.hasOwnProperty.call(value, 'value')) {
      return value.value
    } else {
      return value
    }
  }

  sendValues = (value: any, lineItem: number, componentName: string) => {
    const newRow = {
      ...this.props.valueRows[lineItem],
      [componentName]: this.formatValue(value)
    }

    const newValues = pureSplice(this.props.valueRows, lineItem, 1, newRow)
    this.props.onChange && this.props.onChange(newValues)
  }

  // Injecting component with onChange handler and value
  injectComponent = (lineItem: number, component: Child, valueRow: ValueRow): Child => {
    if (!component.props || !component.props.name) return component
    const componentName = component.props.name

    return React.cloneElement(component, {
      ...component.props,
      value: this.getValue(valueRow, component),
      onChange: componentName
        ? (value: any) => this.sendValues(value, lineItem, componentName)
        : undefined
    })
  }

  render () {
    const { valueRows, columnWidths, rowLimit } = this.props

    return (
      <BasicTable divider size='small' noOuterPadding>
        <colgroup>
          {columnWidths.map(this.renderCol)}
          <col width='5%' />
        </colgroup>
        <thead>
          <tr>
            {this.renderHeaders()}
            <td />
          </tr>
        </thead>
        <tbody>
          {valueRows.map(this.renderLineItem)}
          <tr>
            {this.renderEmptyCells()}
            <td>
              {valueRows.length !== rowLimit
                && (
                  <Button
                    title='Add column'
                    type='button'
                    small
                    round
                    success
                    data-test='expandable-add-row'
                    onClick={this.props.onAdd}>
                    <MdAdd />
                  </Button>
                )}
            </td>
          </tr>
        </tbody>
      </BasicTable>
    )
  }
}

export { Header, Row, ValuesCallback, Cell }
export default ExpandableTable
