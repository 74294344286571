import React from 'react'
import { Flex, Label as BaseLabel, Block, BlockProps, fromTheme } from '@exivity/ui'
import styled from 'styled-components'

import { useIsFullscreen } from '../../../domains/shell/components/useFullscreen'

interface ItemProps extends BlockProps {
  label?: string
  children: React.ReactNode
}

const Label = styled(BaseLabel)`
  margin-right: ${fromTheme(theme => theme.space[1])};
  white-space: nowrap;
`

const ItemWrapper = styled(Flex).attrs<ItemProps>(() => ({
  alignItems: 'center'
}))``

function Item ({ label, children, ...rest }: ItemProps) {
  return (
    <ItemWrapper {...rest}>
      {label && <Label>{label}</Label>}
      {children}
    </ItemWrapper>
  )
}

const Left = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${ItemWrapper} {
    &:not(:first-child) {
      margin-left: ${fromTheme(theme => theme.space[2])};
    }
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  ${ItemWrapper} {
    &:not(:last-child) {
      margin-right: ${fromTheme(theme => theme.space[2])};
    }
  }
`

const Container = styled(Block)`
  position: relative;
  height: 40px;
  background-color: #F4F4F4;
  z-index: 25;
`

interface ToolbarProps extends BlockProps {
  sticky?: boolean
  children: React.ReactNode
}

function Toolbar ({ children, sticky = true, ...rest }: ToolbarProps) {
  const fullscreenActive = useIsFullscreen()

  const stickyProps = sticky
    ? {
      'data-uk-sticky': `
          offset: ${fullscreenActive ? '5' : '60'};
          animation: uk-animation-slide-top; show-on-up: true;
        `
    }
    : {}

  return (
    <Container mb={10} {...rest} {...stickyProps}>
      {children}
    </Container>
  )
}

Toolbar.Left = Left
Toolbar.Right = Right
Toolbar.Item = Item

const ConnectedToolbar = Toolbar as unknown as React.FC<ToolbarProps> & {
  Left: typeof Left
  Right: typeof Right
  Item: typeof Item
}

export { ConnectedToolbar as Toolbar }
