import { useContext, useCallback } from 'react';
import { compose, complement } from '@exivity/fp';
import { getResourcePermissions, useUserHasPermissionFn } from './permissions/useUserHasPermission';
import { DataLayerContext } from './Provider';
import { getQueryType } from './queries/utils';
export function useAPIQuery() {
    const store = useContext(DataLayerContext);
    const userHasPermission = useUserHasPermissionFn();
    return useCallback((queryBuilder, options) => {
        const queryExpression = queryBuilder.toQuery();
        const resourceNotAllowed = compose(complement(userHasPermission), getResourcePermissions, getQueryType);
        if (resourceNotAllowed(queryExpression))
            return;
        try {
            if (queryExpression.op === 'findRecords' || queryExpression.op === 'findRelatedRecords') {
                queryExpression.page = { offset: 0, limit: -1, kind: 'offsetLimit' };
            }
            // Do not return result - Async code is not handled in components
            store.query(queryExpression, options);
        }
        catch (_a) {
            // Errors and logging is handled by fetch hooks
        }
    }, [userHasPermission, store]);
}
