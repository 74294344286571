import { Record, RecordRelationship, RecordHasOneRelationship } from '@orbit/data'
import { Dict } from '@orbit/utils'

import { makeCreateModel } from '../../utils/model'

export const RATE_TIER_TYPE = 'ratetier'

export interface RateTierModel extends Record {
  type: typeof RATE_TIER_TYPE
  attributes: RateTierAttributes
  relationships?: RateTierRelationships
}

export interface RateTierAttributes {
  rate: number | null
  cogs: number | null
  threshold: number
}

export interface RateTierRelationships extends Dict<RecordRelationship> {
  rate: RecordHasOneRelationship
}

const DEFAULT_RATE_TIER: RateTierModel = {
  id: '',
  type: RATE_TIER_TYPE,
  attributes: {
    rate: null,
    cogs: null,
    threshold: 0
  }
}

export const createRateTier = makeCreateModel<RateTierModel>(
  RATE_TIER_TYPE,
  DEFAULT_RATE_TIER.attributes
)
