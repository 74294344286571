import { applyMixins } from '../utils/applyMixins';
import { Add } from './add';
import { Each } from './each';
import { Move } from './move';
import { RemoveByIndex } from './removeByIndex';
import { Select } from './select';
import { SelectByIndex } from './selectByIndex';
class ListChainImpl {
    constructor(data, updateFn) {
        this.data = data;
        this.updateFn = updateFn;
    }
}
applyMixins(ListChainImpl, [Add, Each, Move, RemoveByIndex, Select, SelectByIndex]);
export function createListChain(data, updateFn) {
    return new ListChainImpl(data, updateFn);
}
