import React from 'react'
import PropTypes from 'prop-types'

import uk from '../../../styles/uikit'
import AdvancedSelect from '../../molecules/AdvancedSelect'
import SortableAdvancedSelect from '../../molecules/AdvancedSelect/SortableAdvancedSelect'

import Dropdown from './index'

const DropdownWithAdvancedSelect = ({
  placeholder,
  value,
  className,
  label,
  widthContainer,
  widthPrimary,
  widthSecondary,
  primary,
  secondary
}) => {
  let dropdown

  function onClick (item) {
    const drop = uk().drop(dropdown.refs.drop)

    primary.props.children
    && !primary.props.children.props.checkable
    && drop.toggle.$el.click()

    secondary
    && secondary.props.children
    && !secondary.props.children.props.checkable
    && drop.toggle.$el.click()

    primary.props.onClick
    && primary.props.onClick(item)

    secondary
    && secondary.props.onClick
    && secondary.props.onClick(item)
  }

  const ASelectPrimary = primary.props.sortable ? SortableAdvancedSelect : AdvancedSelect
  const ASelectSecondary = secondary && secondary.props.sortable
    ? SortableAdvancedSelect
    : AdvancedSelect

  return (
    <Dropdown ref={(element) => { dropdown = element }}
      label={label}
      placeholder={placeholder}
      value={value}
      className={className}
      noPadding>
      <div className={`uk-flex ${'uk-width-' + widthContainer}`}>
        <div className={`
                ${secondary ? '' : 'ex-withadvancedselect__single'}
                ex-withadvancedselect
                ex-withadvancedselect__left
                ${'uk-width-' + widthPrimary}
                `}>
          <ASelectPrimary {...primary.props}
            onClick={item => onClick(item)} />
        </div>
        {secondary && (
          <div className={`
                ex-withadvancedselect
                ex-withadvancedselect__right
                ${'uk-width-' + widthSecondary}
                `}>
            <ASelectSecondary {...secondary.props}
              onClick={item => onClick(item)} />
          </div>
        )}
      </div>
    </Dropdown>
  )
}

DropdownWithAdvancedSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  primary: PropTypes.object,
  secondary: PropTypes.object,
  value: PropTypes.string,
  widthContainer: PropTypes.string,
  widthPrimary: PropTypes.string,
  widthSecondary: PropTypes.string
}

export default DropdownWithAdvancedSelect
