import React, { useState } from 'react'
import { Field, Group, Label, Form, Select, Button } from '@exivity/ui'
import { translate, supportedLanguages } from '@exivity/translations'
import { useSelector } from 'react-redux'

import { useUserPreferences } from '../hooks/useUserPreferences'
import { configurationSelectors } from '../../../domains/administration/state/configuration'

export function Preferences () {
  const appLanguage = useSelector(configurationSelectors.getAppLanguage)
  const [language, setPreference] = useUserPreferences(({ language }) => language)
  const [selectedLanguage, setSelectedLanguage] = useState(language || appLanguage)

  return (
    <Form onSubmit={() => setPreference('language', selectedLanguage)}>
      <Group title={translate('Interface')}>
        <Field.Container>
          <Field id='language'>
            <Label.Group>
              <Label>{translate('Language')}</Label>
            </Label.Group>
            <Select
              value={selectedLanguage}
              data={supportedLanguages}
              onChange={setSelectedLanguage} />
          </Field>
        </Field.Container>
      </Group>
      <Form.Toolbar>
        <Button.Update />
      </Form.Toolbar>
    </Form>
  )
}
