import { useDispatch } from 'react-redux'

import { updateCategory } from '../../state/actions'

import { ServiceWithCategory } from './listServices'

function nameChanged (name: string, children: ServiceWithCategory[]) {
  return children.length
    && children[0].attributes.serviceCategory
    && children[0].attributes.serviceCategoryName
    && children[0].attributes.serviceCategoryName !== name
}

export function useUpdateCategoryName () {
  const dispatch = useDispatch()

  return function updateCategoryName (
    name: string,
    { children }: { children: ServiceWithCategory[] }
  ) {
    if (
      nameChanged(name, children)
      && children[0].attributes.serviceCategory
    ) {
      const { id, type } = children[0].attributes.serviceCategory

      dispatch(
        updateCategory({
          id,
          type,
          attributes: { name }
        })
      )
    }
  }
}
