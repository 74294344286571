import { useEffect } from 'react';
import { getChildRoutes, getPathname, useLocation, useNavigate, useRoute } from '../';
export function useChildRouteSwitch() {
    const { route } = useRoute();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const routes = getChildRoutes(route);
    const routePathname = getPathname(route);
    useEffect(() => {
        if (routePathname === pathname) {
            navigate(getPathname(routes[0]), { replace: true });
        }
    }, [pathname, routePathname, navigate, routes]);
}
