import React, { useMemo } from 'react'
import { Field, Label, Group, Checkbox } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { UserGroupPermission } from '../../../../data/types'

type PermissionField = {
  value: UserGroupPermission
  label: string
  help: string
}

function usePermissionFields () {
  return useMemo(() => ({
    view: [
      {
        value: UserGroupPermission.ViewReports,
        label: translate('Reports'),
        help: translate('Provides access to all elements of the Reports menu.')
      },
      {
        value: UserGroupPermission.ViewCogs,
        label: translate('Cogs'),
        help: translate('Provides access to the Cost of Goods rates and charges.')
      },
      {
        value: UserGroupPermission.ViewBudgets,
        label: translate('Budgets'),
        help: translate('Provides access to the Budget report.')
      },
      {
        value: UserGroupPermission.ViewLogs,
        label: translate('Logs'),
        help: translate('Provides access to the Administration > Log Viewer.')
      },
      {
        value: UserGroupPermission.ViewAudit,
        label: translate('Audit'),
        help: translate('Provides access to the Administration > Audit Trail.')
      }] as PermissionField[],
    manage: [
      {
        value: UserGroupPermission.ManageReports,
        label: translate('Reports'),
        help: translate('Allows the creation and deletion of Reports.')
      },
      {
        value: UserGroupPermission.ManageAccounts,
        label: translate('Accounts'),
        help: translate('Provides access to the Accounts menu section and provides the ability to create, edit and delete Metadata.')
      },
      {
        value: UserGroupPermission.ManageServices,
        label: translate('Services'),
        help: translate('Enables read and write access to the entire services menu. This includes adding and changing of Rates and Adjustments.')
      },
      {
        value: UserGroupPermission.ManageDataSources,
        label: translate('Data sources'),
        help: translate('Allows to create, edit and delete Extractors and Transformers.')
      },
      {
        value: UserGroupPermission.ManageUsers,
        label: translate('Users'),
        help: translate('Allow group to add, remove or edit users.')
      },
      {
        value: UserGroupPermission.ManageSettings,
        label: translate('Settings'),
        help: translate('Allows editing of all elements in the Administration > Settings menu.')
      },
      {
        value: UserGroupPermission.ManageWorkflows,
        label: translate('Workflows'),
        help: translate('Allows the creating and updating of Workflows.')
      },
      {
        value: UserGroupPermission.ManageFiles,
        label: translate('Files'),
        help: translate('Allows to upload usage and lookup data through the Exivity API (see api.exivity.com).')
      },
      {
        value: UserGroupPermission.ManageDatasets,
        label: translate('Dsets'),
        help: translate('Provides access to Data Pipelines > Datasets.')
      },
      {
        value: UserGroupPermission.ManageMetadataDefinitions,
        label: translate('Metadata definitions'),
        help: translate('Allows the creating and updating of Metadata definitions.')
      }
    ] as PermissionField[]
  }), [])
}

interface PermissionsProps {
  value: UserGroupPermission[]
  onChange: (value: UserGroupPermission[]) => void
}

export function Permissions ({ value, onChange }: PermissionsProps) {
  const fields = usePermissionFields()
  function renderPermissionField (field: PermissionField) {
    return (
      <Field key={field.label} data-testid={field.value}>
        <Label.Group>
          <Label>{field.label}</Label>
          <Label.Sub>{field.help}</Label.Sub>
        </Label.Group>
        <Checkbox
          key={field.value}
          checked={value.includes(field.value) || value.includes(UserGroupPermission.All) || false}
          label={translate('Grant permission')}
          onChange={(checked) => {
            if (checked) {
              onChange(value.concat(field.value))
            } else if (!checked && value.includes(UserGroupPermission.All)) {
              onChange(
                Object.values(UserGroupPermission)
                  .filter(permission => (
                    permission !== UserGroupPermission.All && permission !== field.value
                  ))
              )
            } else {
              onChange(value.filter(p => p !== field.value))
            }
          }} />
      </Field>
    )
  }

  return (
    <>
      <Group
        title={translate('View permissions')}>
        <Field.Container>
          {fields.view.map(renderPermissionField)}
        </Field.Container>
      </Group>
      <Group
        title={translate('Manage permissions')}>
        <Field.Container>
          {fields.manage.map(renderPermissionField)}
        </Field.Container>
      </Group>
    </>
  )
}
