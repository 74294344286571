import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation } from '@exivity/routing'
import { translate } from '@exivity/translations'

import { head } from '../../../../API'
import { createAsyncThunk } from '../../../../store/utils'
import { workThunks } from '../../../work/thunks'
import config from '../../../../application/config'
import { authThunks } from '../../state'
import { configurationSelectors } from '../../../administration/state/configuration'
import { getUserSessionStorage } from '../../../../store/storage'

/**
 SAML auth cycle
 -----------------
 1). Ping auth/saml/login to see if service is alive
 2). Redirect to SAML login url to login with SAML
 3). Api does its thing and redirects client back to login page with token query. We process query.
 4). Logout -> call saml logout url
 */

// response.ok is useless when response type is opaqueredirect according to
// https://developer.mozilla.org/en-US/docs/Web/API/Response/type
const responseIsOpaqueRedirect = (
  response: { status: number; type: string }
) => response.status === 0 && response.type === 'opaqueredirect'

export const loginSAML = createAsyncThunk('auth/loginSAML', (_, { dispatch, getState }) => {
  head('/auth/saml/login', {}, { raw: true, redirect: 'manual' })
    .then((response: { status: number; type: string }) => {
      if (responseIsOpaqueRedirect(response)) {
        // This means we've had a successful request to /saml/login and are going to
        // be redirected. This is good!
      } else if (response.status < 200 || response.status > 399) {
        // The API responded with an error. This is not so good!
        return dispatch(
          workThunks.showErrorMessage(
            translate('Couldn\'t redirect to login page, try changing the domain or log in with username and password instead.')
          ))
      }

      const apiRoot = configurationSelectors.getApiRoot(getState())
      window.location.href = `${apiRoot}/v${config.app.apiVersion}/auth/saml/login`
    })
})

export function useSAML () {
  const { search } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const query = queryString.parse(search)

    if (query.token && typeof query.token === 'string') {
      // Login with token if ?token=xxx query is found
      dispatch(authThunks.loginWithToken(query.token))
    }
  }, [dispatch, search])
}

export const logoutSAML = createAsyncThunk('auth/logoutSAML', (_, { getState }) => {
  const apiRoot = configurationSelectors.getApiRoot(getState())
  const token = getUserSessionStorage.fromEitherStorage('session')?.token ?? ''
  window.location.href = `${apiRoot}/v${config.app.apiVersion}/auth/saml/logout?token=${token}`
  return Promise.resolve()
})
