/**
 * Gets the dimensions of the element, accounting for API differences between
 * `window` and other DOM elements.
 */
var isWindow = function (element) { return element === window; };
var getBoundingBox = function (element) { return element.getBoundingClientRect(); };
export function getDimensions(scrollElement, props) {
    if (!scrollElement) {
        return {
            height: props.serverHeight,
            width: props.serverWidth
        };
    }
    else if (isWindow(scrollElement)) {
        var innerHeight_1 = window.innerHeight, innerWidth_1 = window.innerWidth;
        return {
            height: typeof innerHeight_1 === 'number' ? innerHeight_1 : 0,
            width: typeof innerWidth_1 === 'number' ? innerWidth_1 : 0
        };
    }
    else {
        return getBoundingBox(scrollElement);
    }
}
/**
 * Gets the vertical and horizontal scroll amount of the element, accounting for IE compatibility
 * and API differences between `window` and other DOM elements.
 */
export function getScrollOffset(element) {
    if (isWindow(element) && document.documentElement) {
        return {
            top: 'scrollY' in window
                ? window.scrollY
                : document.documentElement.scrollTop,
            left: 'scrollX' in window
                ? window.scrollX
                : document.documentElement.scrollLeft
        };
    }
    else {
        return {
            top: element.scrollTop,
            left: element.scrollLeft
        };
    }
}
/**
 * Gets the vertical and horizontal position of an element within its scroll container.
 * Elements that have been “scrolled past” return negative values.
 * Handles edge-case where a user is navigating back (history) from an already-scrolled page.
 * In this case the body’s top or left position will be a negative number and this element’s
 * top or left will be increased (by that amount).
 */
export function getPositionOffset(element, container) {
    if (isWindow(container) && document.documentElement) {
        var containerElement = document.documentElement;
        var elementRect = getBoundingBox(element);
        var containerRect = getBoundingBox(containerElement);
        return {
            top: elementRect.top - containerRect.top,
            left: elementRect.left - containerRect.left
        };
    }
    else {
        var scrollOffset = getScrollOffset(container);
        var elementRect = getBoundingBox(element);
        var containerRect = getBoundingBox(container);
        return {
            top: elementRect.top + scrollOffset.top - containerRect.top,
            left: elementRect.left + scrollOffset.left - containerRect.left
        };
    }
}
