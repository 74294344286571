import React from 'react'
import { Checkbox, Field, Label, Select } from '@exivity/ui'
import { useCacheQuery, queries } from '@exivity/data-layer'
import { translate } from '@exivity/translations'

import { WORKFLOW_STEP_ALL_BUDGETS_TOKEN } from '../../../../../../data/types'

import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

function EvaluateBudgetHeader ({ options }: HeaderProps) {
  const budget = useCacheQuery(queries.find('budget', options.budget_id))

  return (
    <>
      {options.budget_id === WORKFLOW_STEP_ALL_BUDGETS_TOKEN
        ? translate('All budgets')
        : budget?.attributes.description}
    </>
  )
}

function EvaluateBudgetOptions ({
  options,
  updater
}: OptionsProps) {
  const budgets = useCacheQuery(
    queries
      .findAll('budget')
      .sortByAttribute('description', 'ascending')
  )

  return (
    <>
      <Field>
        <Label.Group>
          <Label>
            {translate('All budgets')}
          </Label>
          <Label.Sub>
            {translate('Evaluate all budgets.')}
          </Label.Sub>
        </Label.Group>
        <Checkbox
          checked={options.budget_id === WORKFLOW_STEP_ALL_BUDGETS_TOKEN}
          label={translate('Enabled')}
          onChange={(enabled) => {
            updater.setProperty('budget_id', enabled ? WORKFLOW_STEP_ALL_BUDGETS_TOKEN : undefined)
          }} />
      </Field>

      {options.budget_id !== WORKFLOW_STEP_ALL_BUDGETS_TOKEN && (
        <Field>
          <Label>{translate('Budget')}</Label>
          <Select
            searchable
            required
            data={budgets}
            value={options.budget_id}
            valueAccessor={budget => budget.id}
            labelAccessor={budget => budget.attributes.description}
            onChange={updater.setProperty('budget_id')} />
        </Field>
      )}
    </>
  )
}

export const EvaluateBudget = {
  Header: EvaluateBudgetHeader,
  Options: EvaluateBudgetOptions
} as WorkflowStepVariation
