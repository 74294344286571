import { useContext, useCallback } from 'react';
import { allPass, compose, curry, equals, includes, isEmpty, map, reject } from '@exivity/fp';
import { PermissionContext } from '../Provider';
import { UserGroupPermission } from '../resources/resources.generated';
const includesAll = includes(UserGroupPermission.All);
export const allPermissionsExceptAll = reject(equals(UserGroupPermission.All), Object.values(UserGroupPermission));
const comparePermissions = compose(allPass, map(includes));
const hasAllRights = comparePermissions(allPermissionsExceptAll);
export const userHasPermission = curry((userPermissions, requiredPermissions) => {
    const hasRequiredRights = comparePermissions(requiredPermissions);
    return includesAll(requiredPermissions)
        ? hasAllRights(userPermissions)
        : (isEmpty(requiredPermissions)
            || hasRequiredRights(userPermissions)
            || includesAll(userPermissions));
});
export function getResourcePermissions(type) {
    const noPermissions = [];
    return {
        user: [UserGroupPermission.ManageUsers],
        usergroup: [UserGroupPermission.ManageUsers],
        dset: [UserGroupPermission.ManageDatasets],
        report: [UserGroupPermission.ManageReports],
        account: [UserGroupPermission.ManageAccounts],
        metadatadefinition: [UserGroupPermission.ManageMetadataDefinitions],
        metadata: [UserGroupPermission.ManageAccounts],
        service: [UserGroupPermission.ManageServices],
        servicecategory: [UserGroupPermission.ManageServices],
        rate: [UserGroupPermission.ManageServices],
        ratetier: [UserGroupPermission.ManageServices],
        adjustment: [UserGroupPermission.ManageServices],
        workflow: [UserGroupPermission.ManageWorkflows],
        workflowrun: [UserGroupPermission.ManageWorkflows],
        workflowsteplog: [UserGroupPermission.ManageWorkflows],
        workflowstep: [UserGroupPermission.ManageWorkflows],
        workflowschedule: [UserGroupPermission.ManageWorkflows],
        notificationsubscription: noPermissions,
        notificationchannel: noPermissions,
        budget: [UserGroupPermission.ManageAccounts],
        budgetrevision: [UserGroupPermission.ManageAccounts],
        budgetitem: [UserGroupPermission.ManageAccounts],
        environment: [UserGroupPermission.ManageSettings],
        variable: [UserGroupPermission.ManageSettings],
        servicesubscription: [UserGroupPermission.ManageServices]
    }[type];
}
export const useUserHasPermissionFn = () => {
    const userPermissions = useContext(PermissionContext);
    return useCallback((requiredPermissions) => userHasPermission(userPermissions, requiredPermissions), [userPermissions]);
};
export const useUserHasPermission = (requiredPermissions) => {
    const userPermissions = useContext(PermissionContext);
    return userHasPermission(userPermissions, requiredPermissions);
};
