// taken from https://gist.github.com/jiggzson/b5f489af9ad931e3d186
export function scientificToDecimal(num) {
    var numb = num.toString();
    // If the number is not in scientific notation return it as it is.
    if (!/\d+\.?\d*e[+-]*\d+/i.test(numb)) {
        return numb;
    }
    // Remove the sign.
    var numberSign = Math.sign(Number(numb));
    numb = Math.abs(Number(numb)).toString();
    // Parse into coefficient and exponent.
    var _a = numb.toLowerCase().split('e'), coefficient = _a[0], exponent = _a[1];
    var zeros = Math.abs(Number(exponent));
    var exponentSign = Math.sign(Number(exponent));
    var _b = (coefficient.indexOf('.') !== -1
        ? coefficient
        : coefficient + ".").split('.'), integer = _b[0], decimals = _b[1];
    if (exponentSign === -1) {
        zeros -= integer.length;
        numb = zeros < 0
            ? integer.slice(0, zeros) + '.' + integer.slice(zeros) + decimals
            : '0.' + '0'.repeat(zeros) + integer + decimals;
    }
    else {
        if (decimals)
            zeros -= decimals.length;
        numb = zeros < 0
            ? integer + decimals.slice(0, zeros) + '.' + decimals.slice(zeros)
            : integer + decimals + '0'.repeat(zeros);
    }
    return numberSign < 0 ? '-' + numb : numb;
}
