import Store from '@orbit/memory'

export const RUNS_LIMIT = 6

export function fetchWorkflows (queryStore: Store['query']) {
  return queryStore(
    q => q.findRecords('workflow').page({ offset: 0, limit: -1 }),
    {
      sources: {
        server: {
          include: ['steps', 'schedules', 'runs'],
          settings: {
            params: {
              'related[runs][limit]': 10,
              'related[runs][sort]': '-start_ts'
            }
          }
        }
      }
    })
}
