import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import {
  queries,
  useCacheQuery,
  WithPopulatedRelationships,
  useOperationListener
} from '@exivity/data-layer'

import {
  Form,
  Heading,
  Placeholder,
  Tabs,
  Button,
  DateFilter,
  useModal,
  CalendarMode
} from '@exivity/ui'
import { useDataStructure } from '@exivity/use-data-structure'
import { NEW_PARAM, useParams } from '@exivity/routing'
import { MdPlayArrow } from 'react-icons/md'

import { useSave } from '../../../../data/operationHooks/useSave'
import { useDelete } from '../../../../data/operationHooks/useDelete'
import { workflowThunks } from '../../state'
import { Formats, useDateFormatter } from '../../../../components/hooks/useDateFormatter'
import LoadingOverlay from '../../../../components/molecules/LoadingOverlay'
import { workSelectors } from '../../../work/state'

import { Configuration } from './Configuration'
import { Status } from './Status'

function useWorkflow (
  id: string|null
): WithPopulatedRelationships<'workflow', 'steps'|'schedules'> {
  const workflow = useCacheQuery(queries.find('workflow', id))
  const steps = useCacheQuery(
    queries
      .relationshipOf('workflow', workflow, 'steps')
      .sortByAttribute('sort', 'ascending')
  )
  const schedules = useCacheQuery(queries.relationshipOf('workflow', workflow, 'schedules'))

  return useMemo(() => ({
    id: workflow?.id || '',
    type: 'workflow',
    attributes: {
      name: '',
      description: '',
      ...(workflow?.attributes || {})
    },
    relationships: {
      steps: { data: steps }, schedules: { data: schedules }
    }
  }), [workflow, steps, schedules])
}

export function Workflow () {
  const dispatch = useDispatch()
  const isLoading = useSelector(workSelectors.isForegroundBusy)

  const [params, setParam] = useParams()

  useOperationListener('workflow', {
    addRecord: (workflow) => setParam('id', workflow.id),
    removeRecord: () => setParam('id', null)
  })

  const saveRecord = useSave({
    cascadeModels: {
      workflow: ['schedules', 'steps']
    }
  })
  const deleteRecord = useDelete()

  const [workflow, workflowUpdater] = useDataStructure(
    useWorkflow(params.id === NEW_PARAM ? null : params.id)
  )

  const [runNowDate, setRunNowDate] = useState(new Date())
  const formatter = useDateFormatter()

  const modals = useModal()

  if (!params.id) {
    return <Placeholder>{translate('Select a workflow')}</Placeholder>
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Form onSubmit={() => saveRecord(workflow)}>
        <Heading.Input
          required
          label={translate('name')}
          autoFocus={!workflow.id}
          name={translate('Name')}
          placeholder={translate('Name')}
          value={workflow.attributes.name}
          onChange={workflowUpdater.setAttribute('name')} />
        <Tabs>
          <Tabs.TabList>
            <Tabs.Tab>{translate('Configuration')}</Tabs.Tab>
            <Tabs.Tab disabled={!workflow.id}>
              {translate('Status')}
            </Tabs.Tab>
          </Tabs.TabList>
          <Tabs.TabPanels>
            <Configuration workflow={workflow} updater={workflowUpdater} />
            <Status workflow={workflow} />
          </Tabs.TabPanels>
        </Tabs>
        <Form.Toolbar>
          {workflow.id && (
            <>
              <DateFilter
                mode={CalendarMode.Days}
                value={runNowDate}
                onChange={setRunNowDate} />
              <Button
                icon={<MdPlayArrow />}
                onClick={() => dispatch(
                  workflowThunks.runWorkflow({
                    workflow,
                    date: formatter.format(Formats.IsoDate, runNowDate)
                  })
                )}>
                {translate('Run now')}
              </Button>
              <Button.Delete onClick={() => {
                modals.warning({ body: translate('Are you sure you want delete this workflow?') })
                  .then(() => deleteRecord(workflow))
              }
              } />
            </>
          )}
          {workflow.id ? <Button.Update /> : <Button.Create />}
        </Form.Toolbar>
      </Form>
    </>
  )
}
