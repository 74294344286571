import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'
import { FC } from 'react'
import compose from 'lodash/fp/compose'

import { insertIf } from '../../../../utils/misc'
import { SUBSCRIPTION_TYPE, ServiceModel } from '../../../../data/types'

import { Subscription } from './Subscription'

interface SubscriptionProps {
  accountId: string
  subscriptionId?: string
}

const mapRecordsToProps = ({ accountId, subscriptionId }: SubscriptionProps) => ({
  account: (q: QueryBuilder) => q.findRecord({ type: 'account', id: accountId }),
  ...insertIf(subscriptionId, {
    subscription: (q: QueryBuilder) => q.findRecord({
      type: SUBSCRIPTION_TYPE,
      id: subscriptionId as string
    })
  }),
  ...insertIf(subscriptionId, {
    service: (q: QueryBuilder) => q.findRelatedRecord({
      type: SUBSCRIPTION_TYPE,
      id: subscriptionId as string
    }, 'service')
  })
})

const mapServiceCategoryToProps = ({ service }: { service?: ServiceModel}) => ({
  ...insertIf(service, {
    serviceCategory: (q: QueryBuilder) => (
      q.findRelatedRecord(service as ServiceModel, 'servicecategory')
    )
  })
})

export default compose(
  withData(mapRecordsToProps),
  withData(mapServiceCategoryToProps)
)(Subscription) as unknown as FC<Omit<SubscriptionProps, 'account'>>
