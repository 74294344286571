"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.translate = exports.setLanguage = exports.supportedLanguages = exports.selectedLanguage = void 0;
var translations_1 = require("./translations");
var translators_1 = require("./translators");
exports.selectedLanguage = 'en';
exports.supportedLanguages = Object.keys(translations_1.translations);
function setLanguage(language) {
    exports.selectedLanguage = language;
}
exports.setLanguage = setLanguage;
function translate(text) {
    var variables = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        variables[_i - 1] = arguments[_i];
    }
    return translators_1.translator(exports.selectedLanguage).apply(void 0, __spreadArray([text], variables));
}
exports.translate = translate;
