import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserGroupPermission } from '@exivity/data-layer'

import { getUserSessionStorage } from '../../../store/storage'
import { loginSAML, logoutSAML } from '../SingleSignOn/SAML/authCycle'
import { createUser, UserModel } from '../../../data/types'
import { FetchStatus } from '../../../API/types'
import { createSelectors } from '../../../store/utils'

import { thunks } from './thunks'

const initialState = {
  persistent: false,
  isAuthenticated: false,
  currentUserId: null as string|null,
  currentUser: createUser(),
  permissions: [] as UserGroupPermission[],
  signingOut: FetchStatus.Idle as FetchStatus,
  signingOutWithSaml: false
}

export const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updatePersistent (state, action) {
      state.persistent = action.payload
    },
    updateCurrentUserId (state, action) {
      state.currentUserId = action.payload
    },
    updateCurrentUser (state, action: PayloadAction<UserModel>) {
      state.currentUser = action.payload
    },
    updateToken (state, action) {
      const session = getUserSessionStorage.getStorage(state.persistent)
      session.setItem('session', { token: action.payload })
    },
    updateIsAuthenticated (state, action) {
      if (!action.payload) {
        const session = getUserSessionStorage.getStorage(state.persistent)
        session.clear()
      }

      state.isAuthenticated = action.payload
    },
    updatePermissions (state, action) {
      state.permissions = action.payload
    },
    updateSigningOutWithSaml (state, action: PayloadAction<boolean>) {
      state.signingOutWithSaml = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.updateCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload
      })
      .addCase(thunks.logout.pending, (state) => {
        state.signingOut = FetchStatus.Loading
      })
      .addCase(thunks.logout.fulfilled, (state) => {
        state.signingOut = FetchStatus.Succeeded
      })
      .addCase(thunks.logout.rejected, (state) => {
        state.signingOut = FetchStatus.Failed
      })
  }
})

export const authReducer = reducer

export const authActions = actions

export const authSelectors = createSelectors(state => state.auth, {
  getToken: auth => {
    const session = getUserSessionStorage.getStorage(auth.persistent)
    return session.getItem('session')?.token ?? null
  },
  getUserPermissions: auth => auth.permissions,
  isAuthenticated: auth => auth.isAuthenticated,
  getCurrentUser: auth => auth.currentUser,
  isSigningOut: auth => auth.signingOut === FetchStatus.Loading
})

export const authThunks = {
  loginSAML,
  logoutSAML,
  ...thunks
}
