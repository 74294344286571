var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useState, useMemo } from 'react';
import { searchBy } from '../utils/array';
export function useMultiSearchFilter(sources, getSearchOptions) {
    var _a = useState(function () { return sources.map(function () { return ''; }); }), filters = _a[0], setFilters = _a[1];
    function setFilter(index, value) {
        setFilters(__spreadArray(__spreadArray(__spreadArray([], filters.slice(0, index)), [
            value
        ]), filters.slice(index + 1)));
    }
    function getFilterState(index) {
        return {
            searchTerm: filters[index],
            setSearchTerm: function (value) { return setFilter(index, value); }
        };
    }
    function search(data) {
        return sources.reduce(function (data, source, i) { return (searchBy(getSearchOptions(source), filters[i], data)); }, data);
    }
    return useMemo(function () {
        return [getFilterState, search];
    }, [filters, sources]);
}
