var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { registerScrollListener, unregisterScrollListener } from './utils/onScroll';
import { getDimensions, getPositionOffset, getScrollOffset } from './utils/dimensions';
import createDetectElementResize from './utils/detectElementResize';
/**
 * Specifies the number of miliseconds during which to disable
 * pointer events while a scroll is in progress.
 * This improves performance and makes scrolling smoother.
 */
export var IS_SCROLLING_TIMEOUT = 150;
var getWindow = function () { return (typeof window !== 'undefined' ? window : undefined); };
export function useWindowScroller() {
    var ref = useRef(null);
    var onList = useCallback(function (node) {
        ref.current = node;
    }, []);
    var win = getWindow();
    var onScroller = useCallback(function (node) {
        if (node && win) {
            node && node.updatePosition();
        }
    }, []);
    var onScroll = useCallback(function (node) {
        if (node && win) {
            ref.current && ref.current.scrollTo(node.scrollTop);
        }
    }, []);
    return [onList, onScroller, onScroll];
}
var WindowScroller = /** @class */ (function (_super) {
    __extends(WindowScroller, _super);
    function WindowScroller() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._window = getWindow();
        _this._isMounted = false;
        _this._positionFromTop = 0;
        _this._positionFromLeft = 0;
        _this.state = __assign(__assign({}, getDimensions(_this.props.scrollElement, _this.props)), { isScrolling: false, scrollLeft: 0, scrollTop: 0 });
        _this._registerChild = function (element) {
            if (element && !(element instanceof Element)) {
                console.warn('WindowScroller registerChild expects to be passed Element or null');
            }
            _this._child = element;
            _this.updatePosition();
        };
        _this._onChildScroll = function (_a) {
            var scrollTop = _a.scrollTop;
            if (_this.state.scrollTop === scrollTop) {
                return;
            }
            var scrollElement = _this.props.scrollElement;
            if (scrollElement) {
                if (typeof scrollElement.scrollTo === 'function') {
                    scrollElement.scrollTo(0, scrollTop + _this._positionFromTop);
                }
                else {
                    scrollElement.scrollTop = scrollTop + _this._positionFromTop;
                }
            }
        };
        _this._registerResizeListener = function (element) {
            if (element === window) {
                window.addEventListener('resize', _this._onResize, false);
            }
            else {
                _this._detectElementResize.addResizeListener(element, _this._onResize);
            }
        };
        _this._unregisterResizeListener = function (element) {
            if (element === window) {
                window.removeEventListener('resize', _this._onResize, false);
            }
            else if (element) {
                _this._detectElementResize.removeResizeListener(element, _this._onResize);
            }
        };
        _this._onResize = function () {
            _this.updatePosition();
        };
        // Referenced by utils/onScroll
        _this.__handleWindowScrollEvent = function () {
            if (!_this._isMounted) {
                return;
            }
            var onScroll = _this.props.onScroll;
            var scrollElement = _this.props.scrollElement;
            if (scrollElement) {
                var scrollOffset = getScrollOffset(scrollElement);
                var scrollLeft = Math.max(0, scrollOffset.left - _this._positionFromLeft);
                var scrollTop = Math.max(0, scrollOffset.top - _this._positionFromTop);
                _this.setState({
                    isScrolling: true,
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop
                });
                onScroll({
                    scrollLeft: scrollLeft,
                    scrollTop: scrollTop
                });
            }
        };
        // Referenced by utils/onScroll
        _this.__resetIsScrolling = function () {
            _this.setState({ isScrolling: false });
        };
        return _this;
    }
    WindowScroller.prototype.updatePosition = function (scrollElement) {
        if (scrollElement === void 0) { scrollElement = this.props.scrollElement; }
        var onResize = this.props.onResize;
        var _a = this.state, height = _a.height, width = _a.width;
        var thisNode = this._child || ReactDOM.findDOMNode(this);
        if (thisNode instanceof Element && scrollElement) {
            var offset = getPositionOffset(thisNode, scrollElement);
            this._positionFromTop = offset.top;
            this._positionFromLeft = offset.left;
        }
        var dimensions = getDimensions(scrollElement, this.props);
        if (height !== dimensions.height || width !== dimensions.width) {
            this.setState({
                height: dimensions.height,
                width: dimensions.width
            });
            onResize({
                height: dimensions.height,
                width: dimensions.width
            });
        }
    };
    WindowScroller.prototype.componentDidMount = function () {
        var scrollElement = this.props.scrollElement;
        this._detectElementResize = createDetectElementResize();
        this.updatePosition(scrollElement);
        if (scrollElement) {
            registerScrollListener(this, scrollElement);
            this._registerResizeListener(scrollElement);
        }
        this._isMounted = true;
    };
    WindowScroller.prototype.componentDidUpdate = function (prevProps) {
        var scrollElement = this.props.scrollElement;
        var prevScrollElement = prevProps.scrollElement;
        if (prevScrollElement !== scrollElement
            && prevScrollElement != null
            && scrollElement != null) {
            this.updatePosition(scrollElement);
            unregisterScrollListener(this, prevScrollElement);
            registerScrollListener(this, scrollElement);
            this._unregisterResizeListener(prevScrollElement);
            this._registerResizeListener(scrollElement);
        }
    };
    WindowScroller.prototype.componentWillUnmount = function () {
        var scrollElement = this.props.scrollElement;
        if (scrollElement) {
            unregisterScrollListener(this, scrollElement);
            this._unregisterResizeListener(scrollElement);
        }
        this._isMounted = false;
    };
    WindowScroller.prototype.render = function () {
        var children = this.props.children;
        var _a = this.state, isScrolling = _a.isScrolling, scrollTop = _a.scrollTop, scrollLeft = _a.scrollLeft, height = _a.height, width = _a.width;
        return children({
            onChildScroll: this._onChildScroll,
            registerChild: this._registerChild,
            height: height,
            isScrolling: isScrolling,
            scrollLeft: scrollLeft,
            scrollTop: scrollTop,
            width: width
        });
    };
    WindowScroller.defaultProps = {
        onResize: function () { return null; },
        onScroll: function () { return null; },
        scrollingResetTimeInterval: IS_SCROLLING_TIMEOUT,
        scrollElement: getWindow(),
        serverHeight: 0,
        serverWidth: 0
    };
    return WindowScroller;
}(React.PureComponent));
export { WindowScroller };
