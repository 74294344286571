import { combineReducers } from 'redux'

import extractors, { ExtractorsState } from './extractors'
import transformers, { TransformersState } from './transformers'
import templates, { TemplatesState } from './templates'
import lookups, { LookupsState } from './lookups'

export interface DataSourcesState {
  extractors: ExtractorsState
  transformers: TransformersState
  templates: TemplatesState
  lookups: LookupsState
}

const reducer = combineReducers<DataSourcesState>({
  extractors,
  transformers,
  templates,
  lookups
} as any)

export default reducer
