var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect } from 'react';
export function useWindowListener() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    useEffect(function () {
        window.addEventListener.apply(window, args);
        return function () { return window.removeEventListener.apply(window, args); };
    }, __spreadArray([], args));
}
