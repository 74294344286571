import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { querySelector, unlessIsNil } from '@exivity/fp'

import { configurationSelectors } from '../../../domains/administration/state/configuration'

export function useFavicon () {
  const appFavicon = useSelector(configurationSelectors.getAppFavicon)
  const appIcon = useSelector(configurationSelectors.getAppIcon)

  useEffect(() => {
    unlessIsNil(
      (el: HTMLLinkElement) => {
        el.href = appFavicon || appIcon || ''
      },
      querySelector<Document, HTMLLinkElement>('#favicon', document)
    )
  }, [appFavicon, appIcon])
}
