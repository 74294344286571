var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { any, includes, isEmpty, prop } from '@exivity/fp';
import { MdAdd, MdRemove } from 'react-icons/md';
import { StyledCheckbox } from '../Checkbox';
import { themeSpace, themeColor } from '../Theme';
import { Text } from '../Text';
import { Icon as IconWrapper } from '../Icon';
import { useListContext } from './useNodeList';
import { accumulateFromChildren, toggleNodeExpand } from './utils';
var focusNextElement = function (event) {
    var _a, _b, _c;
    switch (event.key) {
        case 'Down':
        case 'ArrowDown':
            // eslint-disable-next-line no-unused-expressions
            (_a = event.currentTarget.nextElementSibling) === null || _a === void 0 ? void 0 : _a.focus();
            break;
        case 'Up':
        case 'ArrowUp':
            // eslint-disable-next-line no-unused-expressions
            (_b = event.currentTarget.previousElementSibling) === null || _b === void 0 ? void 0 : _b.focus();
            break;
        case 'Enter':
            // eslint-disable-next-line no-unused-expressions
            (_c = event.currentTarget) === null || _c === void 0 ? void 0 : _c.click();
            break;
        default:
            break;
    }
};
var Category = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), themeColor('gray'));
var StyledLi = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: calc(", " * ", ") !important;\n  \n  &.select {\n    &__li {    \n      & > *:not(.select__highlighter) {\n        margin-right: 10px;\n      }\n      \n      &:hover, :focus {\n        background-color: ", ";\n      }\n    }\n  }\n  \n  .select__checkbox {\n    flex-shrink: 0;\n  }\n"], ["\n  padding-left: calc(", " * ", ") !important;\n  \n  &.select {\n    &__li {    \n      & > *:not(.select__highlighter) {\n        margin-right: 10px;\n      }\n      \n      &:hover, :focus {\n        background-color: ", ";\n      }\n    }\n  }\n  \n  .select__checkbox {\n    flex-shrink: 0;\n  }\n"])), themeSpace(2), prop('depth'), themeColor('lightGray'));
var StyledButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  visibility: ", ";\n  \n  width: 20px;\n  height: 20px;\n  border-radius: 0;\n  border: none;\n  outline: none;\n  flex-shrink: 0;\n  \n  padding: 0;\n  cursor: pointer;\n  \n  svg {\n    width: 100%;\n    height: 100%;\n    color: ", ";\n  }\n"], ["\n  visibility: ", ";\n  \n  width: 20px;\n  height: 20px;\n  border-radius: 0;\n  border: none;\n  outline: none;\n  flex-shrink: 0;\n  \n  padding: 0;\n  cursor: pointer;\n  \n  svg {\n    width: 100%;\n    height: 100%;\n    color: ", ";\n  }\n"])), function (_a) {
    var leaf = _a.leaf;
    return leaf ? 'hidden' : 'visible';
}, themeColor('gray'));
function ExpandButton(_a) {
    var index = _a.index;
    var _b = useListContext(index), node = _b.node, updateNodeList = _b.updateNodeList, categorized = _b.categorized, getChildNodes = _b.getChildNodes;
    if (categorized || !getChildNodes)
        return null;
    return (_jsx(StyledButton, __assign({ leaf: isEmpty(node.children), type: 'button', onClick: function (e) {
            e.stopPropagation();
            updateNodeList(toggleNodeExpand(node.path));
        } }, { children: _jsx(IconWrapper, { children: node.expand
                ? _jsx(MdRemove, {}, void 0)
                : _jsx(MdAdd, {}, void 0) }, void 0) }), void 0));
}
function CheckBox(_a) {
    var index = _a.index;
    var _b = useListContext(index), node = _b.node, active = _b.active, isParentActive = _b.isParentActive, multiple = _b.multiple;
    if (!multiple)
        return null;
    var parentIsActive = isParentActive(node.path);
    return (_jsx("div", { children: _jsxs(StyledCheckbox, { children: [_jsx("input", { type: 'checkbox', readOnly: true, checked: !!(active || parentIsActive), disabled: parentIsActive }, void 0), _jsx("div", { className: 'custom-checkbox' }, void 0)] }, void 0) }, void 0));
}
export function Icon(_a) {
    var index = _a.index;
    var _b = useListContext(index), node = _b.node, active = _b.active, value = _b.value, getNodeValue = _b.getNodeValue, multiple = _b.multiple, renderIcon = _b.renderIcon;
    if (!renderIcon)
        return null;
    var accumulatedValuesChildren = accumulateFromChildren(getNodeValue, node);
    return (_jsx("div", { children: _jsx(IconWrapper, { children: renderIcon({
                hasActiveChildren: active
                    ? false
                    : multiple
                        ? any(function (val) { return includes(val, accumulatedValuesChildren); }, value)
                        : includes(value, accumulatedValuesChildren),
                item: node.data
            }) }, void 0) }, void 0));
}
export var SelectOption = function (_a) {
    var style = _a.style, index = _a.index;
    var _b = useListContext(index), node = _b.node, active = _b.active, categorized = _b.categorized, searchTerm = _b.searchTerm, labelAccessor = _b.labelAccessor, onChange = _b.onChange;
    var depth = node.path.length;
    var label = labelAccessor(node.data);
    if (categorized && depth === 1) {
        return (_jsx(Category, __assign({ "data-testid": 'select__li--category', style: style, title: label }, { children: _jsx(Text.Highlight, __assign({ className: 'select__highlighter', textToMatch: [searchTerm] }, { children: label }), void 0) }), void 0));
    }
    return (_jsxs(StyledLi, __assign({ "data-testid": 'select__li', className: 'select__li', style: style, tabIndex: -1, depth: depth, title: label, onClick: onChange, onKeyDown: focusNextElement }, { children: [_jsx(ExpandButton, { index: index }, void 0), _jsx(CheckBox, { index: index }, void 0), _jsx(Icon, { index: index }, void 0), _jsx(Text.Highlight, __assign({ className: 'select__highlighter', active: active, textToMatch: [searchTerm] }, { children: label }), void 0)] }), void 0));
};
var templateObject_1, templateObject_2, templateObject_3;
