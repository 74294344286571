import { endOfMonth, isSameMonth, startOfMonth } from 'date-fns';
import { orderRange } from '../../DateRange/helpers';
var updateMonthRange = function (range, action) {
    switch (action.type) {
        case 'SELECT_START':
            return [action.date, null];
        case 'SELECT_END':
            if (!range[0])
                return range;
            if (isSameMonth(range[0], action.date))
                return [null, null];
            return orderRange([range[0], action.date]);
        case 'RESET_RANGE':
            return [null, null];
        case 'SELECT_SINGLE_UNIT_AS_RANGE':
            return [action.date, action.date];
    }
};
function fitPartialMonthRange(_a) {
    var start = _a[0], end = _a[1];
    return [
        start ? startOfMonth(start) : null,
        end ? endOfMonth(end) : null
    ];
}
export var monthRangeReducer = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return fitPartialMonthRange(updateMonthRange.apply(void 0, args));
};
