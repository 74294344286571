import React from 'react'

import TextInput from '../../../../components/molecules/Form/TextInput'

import { Container, ContainerProps } from './Container'

type DomainChangerProps = Omit<ContainerProps, 'children'>

export function DomainChanger (props: DomainChangerProps) {
  return (
    <Container {...props}>
      {({
        isSecureDomain,
        handleDomainChange,
        handleDomainFocus,
        handleDomainBlur
      }) => (
        <TextInput
          required
          url
          label='Domain'
          name='apiRoot'
          placeholder='API root'
          icon={isSecureDomain ? 'lock' : 'link'}
          iconClassName={isSecureDomain ? 'uk-icon uk-text-success' : ''}
          test='domain'
          autoFocus
          onChange={handleDomainChange}
          onFocus={handleDomainFocus}
          onBlur={handleDomainBlur}>
          {props.apiRoot}
        </TextInput>
      )}
    </Container>
  )
}
