import { CrudRecord } from 'react-crud-hook'

import { MetadataModel } from '../../../../data/types'

export const mergeValues = (
  attribute: string,
  value: string | null,
  metadataRecord: CrudRecord<MetadataModel>
) => {
  const oldValues = metadataRecord.attributes.values
  metadataRecord.setAttribute('values', {
    ...oldValues,
    [attribute]: value
  })
}
