import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { always, append, compose, equals, includes, isEmpty, prop, reject, toLower, identity, difference } from '@exivity/fp';
import { createNodeList, expandAll, getExpandedList, getSearchedList, updateNodeMatch, accumulateFromChildren, someParents } from './utils';
export function useMemoCtx(ctx) {
    return useMemo(function () { return ctx; }, Object.values(ctx));
}
var defaultGetterChildren = always([]);
function initializeList(data, normalize, getChildNodes) {
    var initialize = compose(normalize, createNodeList(getChildNodes || defaultGetterChildren));
    return initialize(data);
}
function useNodeListState(data, normalize, categorized, getChildNodes) {
    var _a = useState(function () {
        var nodes = initializeList(data, normalize, getChildNodes);
        return (categorized
            ? expandAll(nodes)
            : nodes);
    }), nodeList = _a[0], updateNodeList = _a[1];
    useEffect(function () {
        var nodes = initializeList(data, normalize, getChildNodes);
        updateNodeList(categorized
            ? expandAll(nodes)
            : nodes);
    }, [data, normalize, getChildNodes, categorized]);
    return [nodeList, updateNodeList];
}
export function useNodeList(_a) {
    var value = _a.value, data = _a.data, _b = _a.normalize, normalize = _b === void 0 ? identity : _b, multiple = _a.multiple, _c = _a.searchTerm, controlledSearchTerm = _c === void 0 ? '' : _c, _d = _a.searchable, searchable = _d === void 0 ? false : _d, categorized = _a.categorized, onChange = _a.onChange, _e = _a.labelAccessor, labelAccessor = _e === void 0 ? identity : _e, _f = _a.valueAccessor, valueAccessor = _f === void 0 ? identity : _f, renderIcon = _a.renderIcon, getChildNodes = _a.getChildNodes;
    var _g = useState(''), uncontrolledSearchTerm = _g[0], setSearch = _g[1];
    var _h = useNodeListState(data, normalize, categorized, getChildNodes), nodeList = _h[0], updateNodeList = _h[1];
    var searchTerm = searchable
        ? uncontrolledSearchTerm
        : controlledSearchTerm;
    var searchList = compose(getSearchedList, updateNodeMatch(compose(includes(toLower(searchTerm)), toLower, labelAccessor)));
    return useMemoCtx({
        searchTerm: searchTerm,
        setSearch: setSearch,
        value: value,
        data: isEmpty(searchTerm)
            ? getExpandedList(normalize(nodeList))
            : searchList(nodeList),
        nodeList: nodeList,
        updateNodeList: updateNodeList,
        renderIcon: renderIcon,
        labelAccessor: labelAccessor,
        valueAccessor: valueAccessor,
        multiple: multiple,
        categorized: categorized,
        getChildNodes: getChildNodes,
        onChange: onChange
    });
}
export var ListContext = createContext({});
export function isMultiple(args) {
    return Array.isArray(args);
}
export function useListContext(index) {
    var _a = useContext(ListContext), data = _a.data, nodeList = _a.nodeList, updateNodeList = _a.updateNodeList, value = _a.value, multiple = _a.multiple, categorized = _a.categorized, onChange = _a.onChange, searchTerm = _a.searchTerm, renderIcon = _a.renderIcon, labelAccessor = _a.labelAccessor, valueAccessor = _a.valueAccessor, getChildNodes = _a.getChildNodes;
    var node = data[index];
    var getNodeValue = compose(valueAccessor, prop('data'));
    var active = multiple && Array.isArray(value)
        ? includes(getNodeValue(node), value)
        : equals(getNodeValue(node), value);
    var isParentActive = someParents(function (node) {
        return Array.isArray(value)
            ? includes(valueAccessor(node), value)
            : false;
    }, nodeList);
    return {
        node: node,
        getNodeValue: getNodeValue,
        nodeList: nodeList,
        updateNodeList: updateNodeList,
        active: active,
        isParentActive: isParentActive,
        searchTerm: searchTerm,
        renderIcon: renderIcon,
        valueAccessor: valueAccessor,
        labelAccessor: labelAccessor,
        getChildNodes: getChildNodes,
        categorized: categorized,
        multiple: multiple,
        value: value,
        onChange: function () {
            if (multiple && !isParentActive(node.path)) {
                onChange(active
                    ? reject(equals(getNodeValue(node)), value)
                    : append(getNodeValue(node), difference(value, accumulateFromChildren(getNodeValue, node))));
            }
            else if (!multiple) {
                onChange(getNodeValue(node));
            }
        }
    };
}
