import { useState } from 'react'
import {
  queries,
  useCacheQueryFn,
  Resources,
  useStoreListener,
  transformSelectors
} from '@exivity/data-layer'
import {
  any,
  map,
  chain,
  concat,
  pipe,
  reject,
  includes,
  isEmpty,
  complement
} from '@exivity/fp'

const anyContainsItem = any(complement(isEmpty))

const includedResources = [
  'report',
  'account',
  'service',
  'user',
  'usergroup'
] as const

type SearchableResources = typeof includedResources[number]

type SearchIndex = {
  key: string
  type: SearchableResources
  title: string
  document: string
  url: string
}

const createKey = ({ type, id }: { type: string; id: string }) => `${type}-${id}`

const transformToSearchable = map((record: Resources[SearchableResources]) => {
  switch (record.type) {
    case 'report':
      return {
        key: createKey(record),
        type: record.type,
        title: record.attributes.name,
        document: record.attributes.name,
        url: `data-pipelines/reports/${record.id}`
      }

    case 'account':
      return {
        key: createKey(record),
        type: record.type,
        title: record.attributes.name,
        document: record.attributes.name,
        url: `accounts/overview/${record.id}`
      }

    case 'service':
      return {
        key: createKey(record),
        type: record.type,
        title: record.attributes.description,
        document: record.attributes.description,
        url: `services/overview/${record.id}`
      }

    case 'user':
      return {
        key: createKey(record),
        type: record.type,
        title: record.attributes.username,
        document: `${record.attributes.username} ${record.attributes.email_address}`,
        url: `administration/user-management/users/${record.id}/profile`
      }

    case 'usergroup':
      return {
        key: createKey(record),
        type: record.type,
        title: record.attributes.name,
        document: record.attributes.name,
        url: `administration/user-management/usergroups/${record.id}`
      }
  }
})

const getRecordsToAdd = pipe(
  transformSelectors.getOperationsProp,
  transformSelectors.filterOperations('addRecord'),
  transformSelectors.filterResources(...includedResources),
  transformSelectors.getRecordProp,
  transformToSearchable
)

const getRecordsToRemove = pipe(
  transformSelectors.getOperationsProp,
  transformSelectors.filterOperations('removeRecord'),
  transformSelectors.filterResources(...includedResources),
  transformSelectors.getRecordProp,
  map(createKey)
)

export const useApplicationSearch = () => {
  const queryFn = useCacheQueryFn()

  const [state, setState] = useState<SearchIndex[]>(() => {
    const allResources = chain(
      queryFn,
      map(
        queries.findAll.bind(queries) as typeof queries.findAll,
        includedResources
      )
    )

    return transformToSearchable(allResources)
  })

  useStoreListener(
    'transform',
    (transform) => {
      const add = getRecordsToAdd(transform)
      const remove = getRecordsToRemove(transform)

      if (anyContainsItem([add, remove])) {
        setState(
          pipe(
            reject((item: SearchIndex) => includes(item.key, remove)),
            concat(add)
          )
        )
      }
    }
  )

  return state
}
