import {
  ExchangeLookup,
  UpdateLookupItems,
  UpdateSelectedLookupData
} from '../../actions/datasources/lookups'
import { FileEntry } from '../../utils/formatters/lookups'
import { splitFileName } from '../../utils/helpers'

export interface LookupData extends FileEntry {
  baseName: string
  extension?: string
}

export interface LookupsState {
  items: LookupData[]
  selectedData: any[] | null
}

const initialState: LookupsState = {
  items: [],
  selectedData: null
}

type LookupsAction =
  | UpdateLookupItems
  | UpdateSelectedLookupData
  | ExchangeLookup

function updateItems (state: any, action: any) {
  const { baseName } = splitFileName(action.newName)
  const index = state.items.findIndex((value: any) => value.name === action.oldName)
  const updatedItems = [...state.items]
  updatedItems[index] = {
    ...updatedItems[index],
    name: action.newName,
    baseName
  }

  return {
    ...state,
    items: updatedItems
  }
}

const lookups = (state = initialState, action: LookupsAction): LookupsState => {
  switch (action.type) {
    case UpdateLookupItems.type:
      return {
        ...state,
        items: action.items
      }

    case UpdateSelectedLookupData.type:
      return {
        ...state,
        selectedData: action.data
      }

    case ExchangeLookup.type:
      return updateItems(state, action)

    default:
      return state
  }
}

export default lookups
