import React from 'react'

import Icon from '../../atoms/Icon'

import { iterateAllChildren, iterateAllParents } from './VirtualTable'

export function normalized (
  normalizedList,
  relationshipIds,
  cb,
  marker = 'pencil',
  indicator = 'more'
) {
  const hasRelationship = (item) => relationshipIds.includes(item.key)

  const addSymbol = (item, icon, subIcon) => (
    <span>
      <Icon children={icon}
        className={`${!subIcon && 'ex-advanced-select-symbols__icon'}`}
        subIcon={subIcon} />
      <span className='ex-advanced-select-symbols__icon--margin'>{item.meta.origValue}</span>
    </span>
  )

  normalizedList.forEach(item => {
    if (item.parent === null) {
      item.meta.origValue = item.attributes.name
      item.meta.marked = hasRelationship(item)
      item.meta.markedChildren = false
      if (item.meta.marked && marker) {
        item.attributes.name = addSymbol(item, marker, cb && cb(item))
      }

      iterateAllChildren(item, child => {
        child.meta.origValue = child.attributes.name
        child.meta.marked = hasRelationship(child)

        if (child.meta.marked) {
          child.meta.inheritance = true
          item.meta.markedChildren = true

          if (marker) {
            child.attributes.name = addSymbol(child, marker, cb && cb(child))
          }

          if (indicator) {
            item.attributes.name = hasRelationship(item)
              ? addSymbol(item, marker, indicator)
              : addSymbol(item, null, indicator)
          }
          iterateAllParents(child, parent => {
            parent.meta.markedChildren = true
            if (indicator) {
              parent.attributes.name = hasRelationship(parent)
                ? addSymbol(parent, marker, indicator)
                : addSymbol(parent, null, indicator)
            }
          })
        }
      })
    }
  })
}
