import { applyMixins } from '../utils/applyMixins';
import { Select } from '../ObjectShared/select';
import { SetProperty } from './setProperty';
class ObjectChainImpl {
    constructor(data, updateFn) {
        this.data = data;
        this.updateFn = updateFn;
    }
}
applyMixins(ObjectChainImpl, [Select, SetProperty]);
export function createObjectChain(data, updateFn) {
    return new ObjectChainImpl(data, updateFn);
}
