var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light';
// @ts-ignore
import solarizedlight from 'react-syntax-highlighter/dist/cjs/styles/prism/solarizedlight';
import styled, { css } from 'styled-components';
import { preciseEm } from '../utils';
import { fromTheme } from '../Theme';
var useDynamicLanguage = function (language) {
    // Set initial state to a boolean indicating whether the language is text
    // (in which case we can skip the dynamic import effect)
    var _a = useState('text'), current = _a[0], setCurrent = _a[1];
    useEffect(function () {
        if (language !== 'text') {
            import("react-syntax-highlighter/dist/cjs/languages/prism/" + language)
                .then(function (definition) {
                SyntaxHighlighter.registerLanguage(language, definition.default);
                setCurrent(language);
            })
                .catch(function () {
                console.error("Language '" + language + "' could not be found, falling back to plaintext");
                setCurrent('text');
            });
        }
    }, [language]);
    return current;
};
var StyledSyntaxHighlighter = styled(SyntaxHighlighter)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  // Override globalBlockSpacing because we need !important\n  margin: ", "rem 0 !important;\n\n  &:first-child {\n    margin-top: 0 !important;\n  }\n\n  &:last-child {\n    margin-bottom: 0 !important;\n  }\n\n  & {\n    background-color: ", " !important;\n    &, code, pre {\n      font-family: ", " !important;\n    }\n\n    padding: ", "em !important;\n  }\n"], ["\n  // Override globalBlockSpacing because we need !important\n  margin: ", "rem 0 !important;\n\n  &:first-child {\n    margin-top: 0 !important;\n  }\n\n  &:last-child {\n    margin-bottom: 0 !important;\n  }\n\n  & {\n    background-color: ", " !important;\n    &, code, pre {\n      font-family: ", " !important;\n    }\n\n    padding: ", "em !important;\n  }\n"])), function (props) { return props.noMargin ? 0 : preciseEm(1); }, fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.global.fontFamilyMonospace; }), function (props) { return props.PreTag === 'span'
    ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", ""], ["", ""])), fromTheme(function (theme) { return theme.global.baseSpacing / 4; })) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", ""], ["", ""])), fromTheme(function (theme) { return theme.global.baseSpacing; })); });
export function Code(_a) {
    var children = _a.children, block = _a.block, showLineNumbers = _a.showLineNumbers, _b = _a.language, language = _b === void 0 ? 'text' : _b;
    var highlighterLanguage = useDynamicLanguage(language);
    return (_jsx(StyledSyntaxHighlighter, __assign({ PreTag: block ? 'pre' : 'span', style: solarizedlight, language: highlighterLanguage, showLineNumbers: showLineNumbers }, { children: children }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
