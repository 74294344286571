export function createListStrategy(data, _a) {
    var prepareData = _a.prepareData, renderItem = _a.renderItem;
    var preparedData = prepareData(data);
    return {
        itemCount: preparedData.length,
        renderItem: function (index) {
            var item = preparedData[index];
            return renderItem({ items: preparedData, item: item, index: index });
        }
    };
}
