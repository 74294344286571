import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { queries, useCacheQuery } from '@exivity/data-layer'
import { reduce, min, map, compose, viewAttribute } from '@exivity/fp'

import { reportsActions, reportSelectors } from '../state'
import config from '../../../application/config'

export function useMinimumAccessibleDepth () {
  const reportId = useSelector(reportSelectors.getSelectedReportId)
  const dispatch = useDispatch()

  const accounts = useCacheQuery(
    queries
      .relationshipOf('report', reportId, 'accounts')
  )

  useEffect(() => {
    const minimumAccessibleDepth = compose(
      reduce(min, config.reports.maxLevel),
      map(viewAttribute('level'))
    )

    dispatch(
      reportsActions
        .filters
        .updateMinimumAccessibleDepth(
          minimumAccessibleDepth(accounts)
        )
    )
  }, [dispatch, accounts])
}
