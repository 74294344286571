import React from 'react'
import { translate } from '@exivity/translations'
import { Checkbox, GroupBox } from '@exivity/ui'
import { CrudRecord } from 'react-crud-hook'
import { queries, Resources, useCacheQuery } from '@exivity/data-layer'

import { UserModel } from '../../../../data/types'

interface ChannelsProps {
  notification: CrudRecord<Resources['notificationsubscription']>
  user: UserModel
}

const style = {
  marginBottom: 10,
  cursor: 'pointer'
}

export function Channels ({ notification, user }: ChannelsProps) {
  const channels = useCacheQuery(
    queries
      .relationshipOf('user', user, 'channels')
      .sortByAttribute('name', 'ascending')
  )

  if (!channels.length) {
    return (
      <GroupBox>
        <GroupBox.Header>
          <GroupBox.Title>{translate('No channels to choose from.')}</GroupBox.Title>
        </GroupBox.Header>
      </GroupBox>
    )
  }

  const relatedIds = notification.relationships?.channels?.data?.map((channel) => channel.id) || []

  const toggleChannel = (channel: Resources['notificationchannel']) => {
    if (!relatedIds.includes(channel.id)) {
      notification.addHasMany('channels', channel)
    } else {
      notification.removeRelationship('channels', channel.id)
    }
  }

  return (
    <>
      {channels.map((channel) => (
        <div key={channel.id} style={style} onClick={() => toggleChannel(channel)}>
          <GroupBox>
            <GroupBox.Header>
              <Checkbox
                checked={!!relatedIds.includes(channel.id)}
                label={
                  // e.preventDefault here because clicking the label generates an extra event
                  // that causes toggle to be called twice which results in no change.
                  <label onClick={e => e.preventDefault()}>
                    {channel.attributes.name}
                  </label>
                } />
            </GroupBox.Header>
          </GroupBox>
        </div>
      ))}
    </>
  )
}
