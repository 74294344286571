import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import clone from 'lodash/clone'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import Table from 'react-virtualized/dist/commonjs/Table/Table'
import SortDirection from 'react-virtualized/dist/commonjs/Table/SortDirection'
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller'
import isEqual from 'lodash/isEqual'

import { classNames } from '../../../utils/misc'
import { tableTo } from '../../../utils/export'
import Export from '../Export'

const heights = {
  tiny: 26,
  small: 44,
  normal: 56
}

class DataTable extends Component {
  windowScroller
  table
  items

  constructor (props) {
    super(props)

    this.state = {
      sortBy: props.initialSortBy,
      sortDirection: props.initialSortDirection
    }

    this.state.list = this.setupList(props)
    this.state.filtered = this.state.list
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { children, items } = this.props
    const columnsChanged = Children.count(children) !== Children.count(nextProps.children)
    if (!isEqual(nextProps.items, items) || columnsChanged) {
      const list = this.setupList(nextProps)
      this.setState({
        list,
        filtered: list
      })
    }
  }

  filterAllowedDataKeys = (items, children) => {
    const allowedKeys = Children.map(children, child => child && child.props.dataKey)

    return items.map(item => item.group
      ? item
      : allowedKeys.reduce((obj, key) => ({
        ...obj,
        id: item.id,
        type: item.type,
        [key]: item[key]
      }
      ), {}))
  }

  exportData = (list) => this.props.onExport && this.props.onExport(list)

  setupList = (props) => {
    let list

    const groupBy = props.groupBy
    const { sortBy, sortDirection } = this.state

    this.items = clone(props.items)

    if (props.sortable && sortBy) {
      list = this.sortList({
        items: this.items,
        sortBy,
        sortDirection
      })
    } else {
      list = this.items
    }

    this.exportData(this.filterAllowedDataKeys(list, props.children))

    if (groupBy && sortBy === groupBy) {
      list = this.group(list, groupBy)
    }

    return list
  }

  sort = ({ sortBy, sortDirection }) => {
    const { groupBy } = this.props
    let list = this.sortList({
      items: this.items,
      sortBy,
      sortDirection
    })

    if (sortBy === groupBy) {
      list = this.group(list, groupBy)
    }

    this.setState({
      sortBy,
      sortDirection,
      filtered: list
    })
  }

  sortList = ({ items, sortBy, sortDirection }) => {
    items.sort((a, b) => {
      const A = a[sortBy]
      const B = b[sortBy]

      if (A < B) {
        return -1
      }

      if (A > B) {
        return 1
      }

      return 0
    })

    if (sortDirection === SortDirection.DESC) {
      items.reverse()
    }

    return items
  }

  groupSort = (a, b) => {
    if (a[a.type] > b[b.type]) {
      return 1
    }
    if (a[a.type] < b[b.type]) {
      return -1
    }
    return 0
  }

  group = (items, groupBy) => {
    // Group items in object under groupBy keys
    const groupedItems = items.reduce((acc, next) => {
      acc[next[groupBy]]
        ? (acc[next[groupBy]] = acc[next[groupBy]].concat(next))
        : (acc[next[groupBy]] = [next])
      return acc
    }, {})

    // Sort arrays and flatten object
    return Object.keys(groupedItems).reduce((acc, next) => {
      groupedItems[next].sort(this.groupSort)
      return acc.concat([{
        group: next,
        items: groupedItems[next]
      }].concat(groupedItems[next]))
    }, [])
  }

  download = (type) => {
    tableTo(type.key, this.props.items)
  }

  rowRenderer = ({
    className,
    columns,
    index,
    isScrolling,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style
  }) => {
    if (this.props.scrollDummy && isScrolling) {
      return (
        <div key={key} className={className} role='row' style={style}>
          <div role='gridcell' className='ReactVirtualized__Table__rowColumn'>
            <span className='ex-table--data__scroll-dummy' />
          </div>
        </div>
      )
    }

    const a11yProps = {}

    if (
      onRowClick
      || onRowDoubleClick
      || onRowMouseOut
      || onRowMouseOver
      || onRowRightClick
    ) {
      a11yProps['aria-label'] = 'row'
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick
          = event => onRowClick({
            event,
            index,
            rowData
          })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = event => onRowDoubleClick({
          event,
          index,
          rowData
        })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut
          = event => onRowMouseOut({
            event,
            index,
            rowData
          })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = event => onRowMouseOver({
          event,
          index,
          rowData
        })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = event => onRowRightClick({
          event,
          index,
          rowData
        })
      }
    }

    const isGroup = (Object.keys(rowData).length === 2 && rowData.group && rowData.items)
    const isPlaceholder = (Object.keys(rowData).length === 1 && rowData.placeholder)

    let output
    if (isGroup) {
      const groupData = this.props.groupRenderer
        ? this.props.groupRenderer({
          cellData: rowData.group,
          rowData
        })
        : rowData.group
      output = <div role='gridcell' className='ReactVirtualized__Table__rowColumn'>{groupData}</div>
    } else if (isPlaceholder) {
      output = (
        <div role='gridcell' className='ReactVirtualized__Table__rowColumn'>
          {rowData.placeholder}
        </div>
      )
    } else {
      output = columns
    }

    return (
      <div
        {...a11yProps}
        key={key}
        className={classNames({
          'ex-table--data__group': isGroup,
          'ex-table--data__placeholder': isPlaceholder
        }, className, this.props.rowClassName
          && this.props.rowClassName({
            index,
            rowData
          }))}
        role='row'
        style={style}>{output}
      </div>
    )
  }

  render () {
    const {
      children,
      className,
      sortable,
      expand,
      size,
      hover,
      divider,
      noOuterPadding,
      sticky,
      exportable
    } = this.props

    const {
      sortBy,
      sortDirection,
      filtered
    } = this.state

    const classes = classNames({
      'ex-table--hover': hover,
      'ex-table--divider': divider,
      'ex-table--no-outer-padding': noOuterPadding,
      'ex-table--sticky': sticky
    }, 'ex-table', 'ex-table--data', `ex-table--${size}`, className)

    const rowRenderer = this.rowRenderer
    const sorter = sortable ? this.sort : null
    const exporter = exportable ? <Export onClick={this.download}>{['csv']}</Export> : null
    let table

    // Can't have a windowScroller when running tests in jsdom
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV !== 'test' && expand) {
      // Fix position not updated bug
      setTimeout(() => this.windowScroller && this.windowScroller.updatePosition(), 100)

      table = (
        <WindowScroller ref={ref => (this.windowScroller = ref)}>
          {({ height, isScrolling, scrollTop, registerChild }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div ref={registerChild}>
                  <Table
                    ref={table => (this.table = table)}
                    autoHeight
                    className={classes}
                    headerHeight={heights[size]}
                    rowHeight={heights[size]}
                    height={height}
                    width={width}
                    rowCount={filtered.length}
                    rowGetter={({ index }) => filtered[index]}
                    sort={sorter}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    rowRenderer={rowRenderer}
                    overscanRowCount={10}>
                    {children}
                  </Table>
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )
    } else {
      table = (
        <AutoSizer
          disableHeight={this.props.height !== 'auto'}
          disableWidth={this.props.width !== 'auto'}>
          {({ height, width }) => (
            <Table
              ref={table => (this.table = table)}
              className={classes}
              headerHeight={heights[size]}
              rowHeight={heights[size]}
              height={this.props.height === 'auto' ? height : this.props.height}
              width={this.props.width === 'auto' ? width : this.props.width}
              rowCount={filtered.length}
              rowGetter={({ index }) => filtered[index]}
              sort={sorter}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowRenderer={rowRenderer}>
              {children}
            </Table>
          )}
        </AutoSizer>
      )
    }

    return (
      <div className='ex-table__wrapper'>
        {exporter}
        {table}
      </div>
    )
  }
}

DataTable.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  divider: PropTypes.bool,
  expand: PropTypes.bool,
  exportable: PropTypes.bool,
  groupBy: PropTypes.string,
  groupRenderer: PropTypes.func,
  height: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number
  ]).isRequired,
  hover: PropTypes.bool,
  initialSortBy: PropTypes.string,
  initialSortDirection: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  noDataText: PropTypes.string,
  noOuterPadding: PropTypes.bool,
  onExport: PropTypes.func,
  rowClassName: PropTypes.func,
  scrollDummy: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small', 'tiny']).isRequired,
  sortable: PropTypes.bool,
  sticky: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number
  ])
}

DataTable.defaultProps = {
  noDataText: 'No items in this list',
  initialSortDirection: SortDirection.ASC,
  size: 'normal',
  height: 'auto',
  width: 'auto'
}

export default DataTable
