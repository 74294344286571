"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduce = void 0;
var ramda_1 = require("ramda");
exports.reduce = ramda_1.curry(function (f, init, tree) {
    function go(acc, node, indexPath) {
        return node.children.reduce(function (acc, child, i) { return go(acc, child, __spreadArray(__spreadArray([], indexPath), [i])); }, f(acc, node, indexPath, tree));
    }
    return go(init, tree, []);
});
