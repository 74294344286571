import React, { Component, ErrorInfo } from 'react'
import { Alert } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { logError } from '../../../../utils/log'

import { ErrorMessage } from './ErrorMessage'

interface ErrorBoundaryProps {
  displayErrors?: boolean
  children: React.ReactNode
}

interface ErrorBoundaryState {
  error: Error|null
  info: ErrorInfo|null
  prevChildren: React.ReactNode
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
    info: null,
    prevChildren: this.props.children
  }

  componentDidCatch (error: Error, info: ErrorInfo) {
    this.setState({ error, info })

    logError(error, info)
  }

  static getDerivedStateFromProps (
    props: ErrorBoundaryProps,
    state: ErrorBoundaryState
  ) {
    if (props.children !== state.prevChildren) {
      return {
        prevChildren: props.children,
        error: null,
        errorInfo: null
      }
    }

    return null
  }

  render () {
    const { error, info } = this.state
    const { displayErrors } = this.props

    if (error && displayErrors === false) return null

    if (error && info) {
      return (
        <Alert danger>
          <h4>{translate('A problem occurred while displaying this page.')}</h4>
          <ErrorMessage error={error} info={info} />
        </Alert>
      )
    }

    return this.props.children
  }
}
