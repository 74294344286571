var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { useToggle } from '@exivity/hooks';
import { when, always } from '@exivity/fp';
import { useOutsideClickListener } from '../useOutsideClickListener';
import { themeColor, themePriority } from '../Theme';
export var Drop = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  box-shadow: 0 5px 15px rgba(0,0,0,0.08);\n  z-index: ", ";\n"], ["\n  background-color: ", ";\n  box-shadow: 0 5px 15px rgba(0,0,0,0.08);\n  z-index: ", ";\n"])), themeColor('white'), themePriority(4));
export function Dropdown(_a) {
    var style = _a.style, _b = _a.className, className = _b === void 0 ? '' : _b, reference = _a.reference, placement = _a.placement, modifiers = _a.modifiers, _c = _a.closeOnClick, closeOnClick = _c === void 0 ? true : _c, disabled = _a.disabled, isOpen = _a.isOpen, onToggleOpen = _a.onToggleOpen, children = _a.children, divProps = __rest(_a, ["style", "className", "reference", "placement", "modifiers", "closeOnClick", "disabled", "isOpen", "onToggleOpen", "children"]);
    var _d = useState(null), referenceElement = _d[0], setReferenceElement = _d[1];
    var _e = useState(null), popperElement = _e[0], setPopperElement = _e[1];
    var _f = useToggle(false), open = _f[0], toggleOpen = _f[1];
    var _g = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: modifiers
    }), styles = _g.styles, attributes = _g.attributes;
    var show = isOpen || open;
    var toggleDropdown = disabled
        ? function () { }
        : onToggleOpen || toggleOpen;
    var outsideClickRef = useOutsideClickListener(function () { return show && toggleDropdown(); });
    return (_jsxs("div", __assign({ ref: outsideClickRef, style: style, className: className }, divProps, { children: [_jsx("div", __assign({ ref: setReferenceElement, "data-testid": 'dropdown__reference', className: 'dropdown__reference', onClick: toggleDropdown }, { children: reference }), void 0), show && (_jsx(Drop, __assign({ ref: setPopperElement, className: 'dropdown__drop', style: styles.popper, onClick: when(always(closeOnClick), toggleDropdown) }, attributes.popper, { children: children }), void 0))] }), void 0));
}
var templateObject_1;
