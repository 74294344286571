import { combineReducers } from 'redux'

import flags, { FlagsState } from './flags'
import release, { ReleaseState } from './release'
import services, { ServicesState } from './services'

export interface SystemState {
  flags: FlagsState
  release: ReleaseState
  services: ServicesState
}

const reducer = combineReducers<SystemState>({
  flags,
  release,
  services
} as any)

export default reducer
