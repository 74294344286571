import { useEffect, useRef } from 'react';
export function useEventListener(eventName, handler, element) {
    if (element === void 0) { element = window; }
    var savedHandler = useRef();
    useEffect(function () {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(function () {
        var isSupported = element && element.addEventListener;
        if (!isSupported)
            return;
        var eventListener = function (event) { return (savedHandler.current && savedHandler.current(event)); };
        element.addEventListener(eventName, eventListener);
        return function () {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}
