import { translate } from '@exivity/translations'

import { RequestMethod } from '../../API/fetchWithHooks/FetchTypes'

const ALL_METHODS = [
  RequestMethod.POST,
  RequestMethod.PATCH,
  RequestMethod.PUT,
  RequestMethod.DELETE
]

export const resources = {
  adjustments: {
    name: translate('adjustment'),
    messageUserOn: ALL_METHODS
  },
  services: {
    name: translate('service'),
    messageUserOn: ALL_METHODS
  },
  servicesubscriptions: {
    name: translate('subscription'),
    messageUserOn: ALL_METHODS
  },
  users: {
    name: translate('user'),
    messageUserOn: ALL_METHODS
  },
  usergroups: {
    name: translate('usergroup'),
    messageUserOn: ALL_METHODS
  },
  budgets: {
    name: translate('budget'),
    messageUserOn: [RequestMethod.DELETE]
  },
  budgetrevisions: {
    name: translate('revision'),
    messageUserOn: [RequestMethod.DELETE]
  },
  notificationchannels: {
    name: translate('notification channel'),
    messageUserOn: ALL_METHODS
  },
  notificationsubscriptions: {
    name: translate('notification'),
    messageUserOn: ALL_METHODS
  },
  workflows: {
    name: translate('workflow'),
    messageUserOn: ALL_METHODS
  },
  rates: {
    name: translate('rate'),
    messageUserOn: ALL_METHODS
  }
}
