import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import LoadingOverlay from '../../../../components/molecules/LoadingOverlay'
import Form, { TextInput } from '../../../../components/molecules/Form'
import Button from '../../../../components/atoms/Button'
import { DomainChanger } from '../DomainChanger/DomainChanger'
import { authThunks } from '../../state'

interface RequestResetFormProps {
  apiRoot: string
  loading?: boolean
  onDomainChanged: (apiRoot: string) => void
  onRedirectToLoginPage: () => void
}

export function RequestResetForm ({
  apiRoot,
  loading,
  onDomainChanged,
  onRedirectToLoginPage
}: RequestResetFormProps) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [showDomain, setShowDomain] = useState(false)

  return (
    <Form className='ex-reset-form uk-background-default uk-position-relative uk-form-stacked'
      onSubmit={() => dispatch(authThunks.requestResetPassword(email))}>
      {loading && <LoadingOverlay />}
      <TextInput required
        autoComplete='email'
        label='Email'
        size='large'
        name='email'
        placeholder='Email'
        controlled
        onChange={(e: string) => setEmail(e)}>
        {email}
      </TextInput>
      <DomainChanger showDomain={showDomain} apiRoot={apiRoot}
        onDomainChanged={onDomainChanged} />
      <div>
        <Button submit icon='chevron-right' iconPosition='right'
          variation='secondary'>Reset
        </Button>
        <div className='ex-reset-form__links uk-float-right'>
          <Button variation='text' className='uk-float-right'
            onClick={() => setShowDomain(!showDomain)}>
            Change domain
          </Button>
          <Button variation='text' className='uk-float-right'
            onClick={onRedirectToLoginPage}>
            Login
          </Button>
        </div>
      </div>
    </Form>
  )
}
