import { actions } from '../dimensions'

export const invalidateAllReportData = [
  actions.invalidateData('accounts'),
  actions.invalidateData('services'),
  actions.invalidateData('instances'),
  actions.invalidateData('summaryServices'),
  actions.invalidateData('summaryInstances'),
  actions.invalidateData('resources')
]
