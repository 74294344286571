import { WorkflowStepType } from '../../../../../../data/types'

import { EvaluateBudget } from './EvaluateBudget'
import { Execute } from './Execute'
import { PrepareReport } from './PrepareReport'
import { PublishReport } from './PublishReport'
import { RunExtractor } from './RunExtractor'
import { RunTransformer } from './RunTransformer'
import { Core } from './Core'

export const STEP_VARIATIONS = {
  [WorkflowStepType.Core]: Core,
  [WorkflowStepType.RunExtractor]: RunExtractor,
  [WorkflowStepType.RunTransformer]: RunTransformer,
  [WorkflowStepType.PrepareReport]: PrepareReport,
  [WorkflowStepType.PublishReport]: PublishReport,
  [WorkflowStepType.EvaluateBudget]: EvaluateBudget,
  [WorkflowStepType.Execute]: Execute
}
