import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const Scrollable = ({ children, style }) => (
  <div className='ex-scrollable' style={style}>
    <div>
      {children}
    </div>
  </div>
)

Scrollable.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
}

export default Scrollable
