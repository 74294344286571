import Coordinator, {
  RequestStrategy,
  SyncStrategy
} from '@orbit/coordinator'

export function createSyncCoordinator (server, memory) {
  // Create sync coordinator
  const syncCoordinator = new Coordinator({ sources: [memory, server] })

  // Add strategy for live queries
  syncCoordinator.addStrategy(new RequestStrategy({
    name: 'pull-from-server',

    source: 'memory',
    on: 'beforeQuery',

    target: 'server',
    action: 'pull',

    blocking: true,

    catch () {
      // handler(error, 'Could not fetch data from API at this time.')
      this.source.requestQueue.skip()
      this.target.requestQueue.skip()
    }
  }))

  // Add strategy to sync server to memory
  syncCoordinator.addStrategy(new SyncStrategy({
    name: 'sync-server-to-memory',

    source: 'server',
    target: 'memory',

    blocking: true
  }))

  return syncCoordinator
}
