import { useState, useMemo } from 'react'

const KEY = 'key'
const ALT = 'alt'

export interface KeyColumnSelectorOptionType { value: string; label: string }

export const useKeyColumnSelector = (altColumn: string) => {
  const [selectedOption, selectOption] = useState(ALT)
  const options = useMemo(() => [
    { value: ALT, label: altColumn },
    { value: KEY, label: 'Key' }
  ], [altColumn])

  return {
    selectOption,
    selectedOption,
    options,
    isKey: useMemo(() => selectedOption === KEY, [selectedOption])
  }
}
