import React from 'react'
import { CrudProvider as NativeCrud, CrudManager } from 'react-crud-hook'

import { crudFunctions } from '../../../data/CrudUtils/crud'

const manager = new CrudManager(crudFunctions)

// eslint-disable-next-line react/prop-types
export function CrudProvider ({ children }) {
  return (
    <NativeCrud manager={manager}>
      {children}
    </NativeCrud>
  )
}
