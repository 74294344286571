import React from 'react'
import { translate } from '@exivity/translations'
import { Field, Label, Input, Link } from '@exivity/ui'

import { wrapSubstr } from '../../../../utils/react'

import { ChannelConfigurationProps } from './'

const SLACK_WEBHOOK_LINK = 'https://api.slack.com/incoming-webhooks'

export function SlackConfiguration ({ channel }: ChannelConfigurationProps) {
  return (
    <>
      <Field>
        <Label.Group>
          <Label>
            {translate('Webhook URL')}
          </Label>
          <Label.Sub>
            {wrapSubstr(
              translate('More on {link}.', SLACK_WEBHOOK_LINK),
              SLACK_WEBHOOK_LINK,
              (link) => <Link key={link} href={link}>{link}</Link>
            )}
          </Label.Sub>
        </Label.Group>
        <Input
          placeholder={translate('Webhook URL')}
          required
          value={channel.attributes.info?.recipient || ''}
          onChange={(recipient) => channel.setAttribute('info', { recipient })} />
      </Field>
    </>
  )
}
