import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'

import { RateModel, ServiceModel } from '../../../../data/types'

import { Service } from './Service'

export type ServiceProps = OwnProps & RecordProps

type OwnProps = {
  serviceId?: string
  useEditToggle: [boolean, () => void, (toggle: boolean) => void]
}

type RecordProps = {
  service: ServiceModel
  rates: RateModel[]
}

const mapRecordsToProps = ({ serviceId }: OwnProps) => {
  if (serviceId) {
    return {
      service: (q: QueryBuilder) => q.findRecord({ type: 'service', id: serviceId }),
      rates: (q: QueryBuilder) => q.findRelatedRecords({ type: 'service', id: serviceId }, 'rates')
    }
  }

  return {}
}

export default withData(mapRecordsToProps)(Service)
