import styled from 'styled-components'

export default styled.div`
  height: 100%;
  position: relative;
  z-index: 1;
  
  + .react-resizable-handle {
    z-index: 10;
    bottom: -10px !important;
    right: -10px !important;
  }
`
