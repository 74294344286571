import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from '@exivity/routing'

import { SUBSCRIPTION_TYPE } from '../../../../data/types'
import { insertIf } from '../../../../utils/misc'
import { RootState } from '../../../../reducers'

import { Subscriptions } from './Subscriptions'

const mapStateToProps = (state: RootState) => ({
  selectedReport: state.reports.filters.report
})

const mapRecordsToProps = ({
  selectedReport
}: { selectedReport: string } & { router: any; params?: Record<string, string> }) => ({
  ...insertIf(selectedReport, {
    accounts: (q: QueryBuilder) => q.findRelatedRecords(
      { type: 'report', id: selectedReport },
      'accounts'
    )
  }),
  report: (q: QueryBuilder) => q.findRecord({ type: 'report', id: selectedReport }),
  allSubscriptions: (q: QueryBuilder) => q.findRecords(SUBSCRIPTION_TYPE)
})

export default compose(
  connect(mapStateToProps),
  withData(mapRecordsToProps),
  withRouter
)(Subscriptions)
