var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ActionList } from '../ActionList';
import { Button } from '../Button';
export function _CrudList(_a) {
    var data = _a.data, onCreate = _a.onCreate, onDelete = _a.onDelete, keyExtractor = _a.keyExtractor, min = _a.min, max = _a.max, children = _a.children;
    var minimumMatched = !!min && (min >= data.length);
    var maximumMatched = !!max && max <= data.length;
    return (_jsx(ActionList, __assign({ keyExtractor: keyExtractor, data: data, itemActions: function (_a) {
            var item = _a.item, index = _a.index;
            return (_jsx(Button.Delete, { type: 'button', disabled: minimumMatched, round: true, small: true, onClick: function () { return onDelete({ index: index, item: item, data: data }); } }, void 0));
        }, listActions: function () { return (_jsx(Button.Add, { type: 'button', disabled: maximumMatched, round: true, small: true, onClick: function () { return onCreate({ data: data }); } }, void 0)); } }, { children: children }), void 0));
}
export var CrudList = React.memo(_CrudList);
