import React from 'react'
import { MdDelete } from 'react-icons/md'
import { translate } from '@exivity/translations'
import { Resources, WorkflowStepType } from '@exivity/data-layer'
import { Updater } from '@exivity/use-data-structure'
import { prop, invoker } from '@exivity/fp'
import styled from 'styled-components'
import {
  Group,
  GroupBox,
  Field,
  Select,
  Label,
  Checkbox,
  Button,
  Placeholder
} from '@exivity/ui'

import { STEP_VARIATIONS } from './variations'

const WORKFLOW_STEP_NAMES = {
  [WorkflowStepType.Core]: () => translate('Core'),
  [WorkflowStepType.RunExtractor]: () => translate('Run extractor'),
  [WorkflowStepType.RunTransformer]: () => translate('Run transformer'),
  [WorkflowStepType.PrepareReport]: () => translate('Prepare report'),
  [WorkflowStepType.PublishReport]: () => translate('Publish report'),
  [WorkflowStepType.EvaluateBudget]: () => translate('Evaluate budget'),
  [WorkflowStepType.Execute]: () => translate('Execute')
}

export function renderStepHeader (step: Resources['workflowstep']) {
  const Variation = STEP_VARIATIONS[step.attributes.type]

  return step.attributes.type
    ? (
      <Label.Group>
        <Label>{WORKFLOW_STEP_NAMES[step.attributes.type]()}</Label>
        <Label.Sub>{<Variation.Header options={step.attributes.options} />}</Label.Sub>
      </Label.Group>
    )
    : null
}

const MINUTE = 60
const HOUR = 60 * MINUTE

const TIMEOUTS = [
  1 * MINUTE,
  10 * MINUTE,
  30 * MINUTE,
  1 * HOUR,
  2 * HOUR,
  3 * HOUR,
  6 * HOUR,
  12 * HOUR,
  24 * HOUR
].map(seconds => {
  return {
    value: seconds,
    label: () => seconds < HOUR
      ? translate('{amount} minutes', Math.round(seconds / MINUTE))
      : translate('{amount} hours', Math.round(seconds / HOUR))
  }
})

const StyledWorkflowStep = styled.div`
  :not(:last-child) .step-box {
    margin-bottom: 0;
  }
  
  &:not(.wait) .step-box {
    margin-top: 1px;
  }
`

interface StepProps {
  step: Resources['workflowstep']
  updater: Updater<Resources['workflowstep']>
  onDelete: () => void
  renderDragHandle: () => React.ReactNode
}

const workflowstepTypesWithoutCore = Object
  .values(WorkflowStepType)
  .filter(item => item !== WorkflowStepType.Core) as Exclude<
  WorkflowStepType,
  WorkflowStepType.Core
>[]

export function Step ({ step, updater, onDelete, renderDragHandle }: StepProps) {
  const Variation = step.attributes.type && STEP_VARIATIONS[step.attributes.type]

  return (
    <StyledWorkflowStep className={step.attributes.wait ? 'wait' : ''}>
      <GroupBox className='step-box' initialCollapsed={!!step.id}>
        <GroupBox.Header>
          <GroupBox.Join gap={15}>
            {renderDragHandle()}
            {renderStepHeader(step)}
          </GroupBox.Join>
          <GroupBox.Join>
            <GroupBox.Actions>
              <Button icon={<MdDelete />} small round transparent onClick={onDelete} />
            </GroupBox.Actions>
            <GroupBox.Collapser />
          </GroupBox.Join>
        </GroupBox.Header>

        <GroupBox.Content>
          {step.attributes.type === WorkflowStepType.Core
            ? null
            : (
              <Field.Container>
                <Field>
                  <Label>{translate('Type')}</Label>
                  <Select
                    required
                    data={workflowstepTypesWithoutCore}
                    labelAccessor={(type) => WORKFLOW_STEP_NAMES[type]()}
                    value={step.attributes.type}
                    onChange={(type) => (
                      updater
                        .setAttribute('type', type)
                        .setAttribute('options', {})
                    )} />
                </Field>
              </Field.Container>
            )}

          <Group title={translate('Configuration')}>
            {Variation
              ? (
                <Field.Container>
                  <Variation.Options
                    options={step.attributes.options}
                    updater={updater.select(step => step.attributes.options)} />
                </Field.Container>
              )
              : <Placeholder>{translate('Select a step type.')}</Placeholder>}
          </Group>

          <Group title={translate('General')}>
            <Field.Container>
              <Field>
                <Label.Group>
                  <Label>{translate('Wait')}</Label>
                  <Label.Sub>{translate('If checked, this step will only execute after all previous steps have ran successfully.')}</Label.Sub>
                </Label.Group>
                <Checkbox
                  disabled={step.attributes.sort === 0}
                  label={translate('Enabled')}
                  checked={step.attributes.wait}
                  onChange={updater.setAttribute('wait')} />
              </Field>

              <Field>
                <Label>{translate('Timeout')}</Label>
                <Select
                  valueAccessor={prop('value')}
                  labelAccessor={invoker(0, 'label')}
                  data={TIMEOUTS}
                  value={step.attributes.timeout}
                  onChange={updater.setAttribute('timeout')} />
              </Field>
            </Field.Container>
          </Group>

        </GroupBox.Content>
      </GroupBox>
    </StyledWorkflowStep>
  )
}
