import React from 'react'
import { Field, Label, Select, Input, Placeholder } from '@exivity/ui'
import { useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { Link, getPathname } from '@exivity/routing'

import { RootState } from '../../../../../../reducers'
import { app } from '../../../../../../routes'

import { Shared } from './shared'
import { HeaderProps, OptionsProps, WorkflowStepVariation } from './interface'

function RunExtractorHeader ({ options }: HeaderProps) {
  return <>{options.name}</>
}

function RunExtractorOptions ({ options, updater }: OptionsProps) {
  const extractors = useSelector((state: RootState) => state.datasources.extractors.data || [])

  if (!extractors.length) {
    return (
      <Placeholder>
        {translate("Unable to configure 'Run extractor' step. No extractors defined.")}{' '}
        <Link to={getPathname(app.routes.datapipelines.routes.extractors) + '/new'}>
          {translate('Create a new extractor.')}
        </Link>
      </Placeholder>
    )
  }

  return (
    <>
      <Field>
        <Label>{translate('Extractor')}</Label>
        <Select
          required
          searchable
          value={options.name}
          valueAccessor={extractor => extractor.name}
          labelAccessor={extractor => extractor.name}
          data={extractors}
          onChange={updater.setProperty('name')} />
      </Field>

      <Shared.Environment
        environmentId={options.environment_id}
        onChangeEnvironmentId={updater.setProperty('environment_id')} />

      <Field>
        <Label>{translate('Arguments')}</Label>
        <Input
          placeholder={translate('Arguments')}
          value={options.arguments || ''}
          onChange={updater.setProperty('arguments')} />
      </Field>

      <Shared.Offsets
        fromDate={options.from_date}
        toDate={options.to_date}
        onChangeFromDate={updater.setProperty('from_date')}
        onChangeToDate={updater.setProperty('to_date')} />

    </>
  )
}

export const RunExtractor = {
  Header: RunExtractorHeader,
  Options: RunExtractorOptions
} as WorkflowStepVariation
