"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTranslator = void 0;
var lodash_1 = require("lodash");
var fp_1 = require("lodash/fp");
// We match anything inbetween curly braces.
var placeholderRegex = /({+.*?}+)/gm;
var getPlaceholders = function (text) { return text.match(placeholderRegex) || []; };
// If we find more than one enclosing set of curly braces it means it is cancelled.
var canceledPlaceholderRegex = /^[{]{2,}.*[}]{2,}$/;
var isCanceledPlaceholder = function (placeholder) { return canceledPlaceholderRegex.test(placeholder); };
var uncancelPlaceholder = function (text, placeholder) {
    // We uncancel canceled placeholders by removing one of te pairs of curly braces.
    return text.replace(placeholder, placeholder.slice(1, -1));
};
var uncancelPlaceholders = function (placeholders) {
    return function (text) { return placeholders.reduce(uncancelPlaceholder, text); };
};
var replacePlaceholders = function (placeholders, values) {
    return function (text) { return (placeholders.reduce(function (text, param, index) { return text.replace(param, String(values[index])); }, text)); };
};
// If we dont do this -> markdown used in translations wont work anymore
function convertTemplateLiteralToNormalString(str) {
    return (' ' + str).trim();
}
function makeTranslator(translation) {
    return function translate(text) {
        var _a;
        var values = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            values[_i - 1] = arguments[_i];
        }
        var _b = lodash_1.partition(getPlaceholders(text), isCanceledPlaceholder), canceledPlaceholders = _b[0], placeholders = _b[1];
        return fp_1.compose(convertTemplateLiteralToNormalString, replacePlaceholders(placeholders, values), uncancelPlaceholders(canceledPlaceholders))(((_a = translation === null || translation === void 0 ? void 0 : translation.translations) === null || _a === void 0 ? void 0 : _a[text]) || text);
    };
}
exports.makeTranslator = makeTranslator;
