import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Alert } from '@exivity/ui'

import config from '../../../application/config'
import { licenseSelectors } from '../state'

export function LicenseWarning () {
  const license = useSelector(licenseSelectors.getLicense)

  const expiresInXDays = (
    moment.tz(license?.expiresAfter, 'UTC').diff(moment().startOf('day'), 'days') + 1
  )

  if (license?.message && (license?.status !== 'valid' || expiresInXDays < 1)) {
    return <Alert danger title='Please check your license'>{license?.message}</Alert>
  }

  if (license?.expiresAfter && expiresInXDays <= config.app.showWarningWhenLicenseExpireInDays) {
    return (
      <Alert title='License expires soon'>
        Your license expires in {expiresInXDays} day{expiresInXDays === 1 ? '' : 's'}
      </Alert>
    )
  }

  return null
}
