import resourceSchema from '../schema/schema.json';
function compareOperation(condition) {
    return (operations) => {
        return operations.some(condition);
    };
}
export function createOperationObservers(schema) {
    const findRecord = (query) => compareOperation((op) => (op.record.type === query.record.type
        && op.record.id === query.record.id));
    const findRecords = (query) => compareOperation((op) => (op.record.type === query.type));
    const findRelatedRecord = (query) => {
        var _a;
        const { record, relationship } = query;
        const relatedType = (_a = schema.models[record.type].relationships) === null || _a === void 0 ? void 0 : _a[relationship].model;
        return compareOperation((op) => (op.record.type === relatedType
            || (op.record.id === query.record.id && op.record.type === query.record.type)));
    };
    const findRelatedRecords = (query) => {
        var _a;
        const { record, relationship } = query;
        const relatedType = (_a = schema.models[record.type].relationships) === null || _a === void 0 ? void 0 : _a[relationship].model;
        return compareOperation((op) => (op.record.type === relatedType
            || (op.record.id === query.record.id && op.record.type === query.record.type)));
    };
    return {
        findRecord,
        findRecords,
        findRelatedRecord,
        findRelatedRecords
    };
}
// @todo make type ok
export const operationObservers = createOperationObservers(resourceSchema);
