var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fromTheme } from '../../Theme';
import { StyledButton } from '../../Button/Button';
export var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    width: 600px;\n    background-color: ", ";\n    left: calc((100vw - 600px) / 2);\n    top: 50px;\n    "], ["\n    position: absolute;\n    width: 600px;\n    background-color: ", ";\n    left: calc((100vw - 600px) / 2);\n    top: 50px;\n    "])), fromTheme(function (theme) { return theme.colors.white; }));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: ", "em ", "em;\n"], ["\n    padding: ", "em ", "em;\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.global.baseSpacing * 1.5; }));
export var Body = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: ", "em;\n    max-height: 400px;\n    border-top: solid 1px ", ";\n    border-bottom: solid 1px ", ";\n    overflow-y: auto;\n"], ["\n    padding: ", "em;\n    max-height: 400px;\n    border-top: solid 1px ", ";\n    border-bottom: solid 1px ", ";\n    overflow-y: auto;\n"])), fromTheme(function (theme) { return theme.global.baseSpacing * 1.5; }), fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.colors.lightGray; }));
export var Footer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: ", "em ", "em;\n    color: ", ";\n    display: flex;\n    flex-direction: row-reverse;\n    ", " {\n        margin-left: 20px;\n    }\n"], ["\n    padding: ", "em ", "em;\n    color: ", ";\n    display: flex;\n    flex-direction: row-reverse;\n    ", " {\n        margin-left: 20px;\n    }\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.global.baseSpacing * 2; }), fromTheme(function (theme) { return theme.colors.gray; }), StyledButton);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
