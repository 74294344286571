var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { CirclePicker } from 'react-color';
// @ts-ignore
import material from 'material-colors';
import { Dropdown } from '../Dropdown';
import { SelectInput } from '../Input';
import { isBrightColor } from '../utils';
import { themeSpace } from '../Theme';
import { useFieldContext } from '../Field/context';
export var StyledDropdown = styled(Dropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  \n  .dropdown__drop {\n    width: 100%;\n    padding: ", " 0;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  \n  .dropdown__drop {\n    width: 100%;\n    padding: ", " 0;\n  }\n"])), themeSpace(1));
var StyledInput = styled(SelectInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n"])), function (props) { return props.value; }, function (props) { return props.color; });
var Center = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: ", " 0;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: ", " 0;\n"])), themeSpace(1));
export var DEFAULT_COLORS = [
    material.red['500'],
    material.pink['500'],
    material.purple['500'],
    material.deepPurple['500'],
    material.indigo['500'],
    material.blue['500'],
    '#00a8d8',
    material.cyan['500'],
    material.teal['500'],
    material.green['500'],
    material.lightGreen['500'],
    material.lime['500'],
    material.yellow['500'],
    material.amber['500'],
    material.orange['500'],
    material.deepOrange['500'],
    material.brown['500'],
    material.blueGrey['500']
];
export function ColorPicker(_a) {
    var name = _a.name, value = _a.value, onChange = _a.onChange, className = _a.className, placeholder = _a.placeholder, required = _a.required, disabled = _a.disabled, _b = _a.colors, colors = _b === void 0 ? DEFAULT_COLORS : _b;
    var id = useFieldContext().id;
    return (_jsx(StyledDropdown, __assign({ className: className, reference: _jsx(StyledInput, { id: id, name: name, required: required, disabled: disabled, placeholder: placeholder, "data-testid": 'colorpicker__input', value: value, color: !value
                ? 'inherit'
                : isBrightColor(value)
                    ? '#000'
                    : '#fff' }, void 0) }, { children: _jsx(Center, { children: _jsx(CirclePicker, { color: value, colors: colors, onChange: function (_a) {
                    var hex = _a.hex;
                    return onChange && onChange(hex);
                } }, void 0) }, void 0) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
