import React from 'react'
import { MdLockOutline, MdLockOpen } from 'react-icons/md'
import { Button, Form } from '@exivity/ui'
import { translate } from '@exivity/translations'

interface LockButtonProps {
  showLockButton: boolean
  locked: boolean
  toggleLock: () => void
}

function LockButton ({ showLockButton, locked, toggleLock }: LockButtonProps) {
  if (!showLockButton) {
    return null
  }

  return (
    <Button
      outlined
      icon={locked ? <MdLockOpen /> : <MdLockOutline />}
      onClick={toggleLock}>
      {locked
        ? translate('Unlock')
        : translate('Lock')}
    </Button>
  )
}

interface ToolbarProps {
  showLockButton: boolean
  locked: boolean
  enableDelete: boolean
  remove: () => void
  update: () => void
  updateAndPrepare: () => void
  toggleLock: () => void
}

export default function Toolbar ({
  showLockButton,
  locked,
  remove,
  enableDelete,
  update,
  updateAndPrepare,
  toggleLock
}: ToolbarProps) {
  return (
    <Form.Toolbar>
      <LockButton showLockButton={showLockButton} locked={locked} toggleLock={toggleLock} />
      <Button.Delete disabled={!enableDelete} onClick={remove} />
      <Button.Group>
        <Button.Update onClick={update} />
        <Button.Select>
          <Button.SelectOption onClick={updateAndPrepare}>
            {translate('With preparing')}
          </Button.SelectOption>
        </Button.Select>
      </Button.Group>
    </Form.Toolbar>
  )
}
