import { FilterByAttribute } from './filterByAttribute';
import { FilterByRelationship } from './filterByRelationship';
import { SortByAttribute } from './sortByAttribute';
import { applyMixins } from './utils';
class QueryModifierImpl {
    constructor(schema, queryExpression) {
        this.schema = schema;
        this.queryExpression = queryExpression;
    }
    toQuery() {
        return this.queryExpression;
    }
}
applyMixins(QueryModifierImpl, [FilterByAttribute, FilterByRelationship, SortByAttribute]);
export function createQueryModifier(schema, queryExpression) {
    return new QueryModifierImpl(schema, queryExpression);
}
