import { PayloadAction } from '@reduxjs/toolkit'

import { SummaryGroupInstancesBy } from '../filterTypes'
import { ReportFilterState } from '../'

export const updateInstanceGroupBy = (
  filters: ReportFilterState,
  action: PayloadAction<SummaryGroupInstancesBy>
) => {
  filters.instances.groupBy = action.payload
}
