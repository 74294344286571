import Papa from 'papaparse'

import { get, post } from '../../API'
import { workThunks } from '../../domains/work/thunks'
import { fetchShortLog } from '../data/logs/logs'
import ClassAction, { createClassAction } from '../../store/middleware/ClassAction'
import {
  PreviewColumnWidths,
  PreviewData,
  PreviewError,
  transformersSelectors
} from '../../reducers/datasources/transformers'
import { TransformerModel } from '../../data/types/transformer'
import { insertDatasource, updateDatasource, deleteDatasource } from '../../API/datasources'
import { insertIf } from '../../utils/misc'
import { ActionDispatcher } from '../../store/utils'

export class SetPreviewLimit extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_SET_PREVIEW_LIMIT'
  readonly type = SetPreviewLimit.type
  constructor (public limit: number) {
    super()
  }
}

export class UpdateTransformersData extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_UPDATE_DATA'
  readonly type = UpdateTransformersData.type
  constructor (public data: TransformerModel[]) {
    super()
  }
}

export class SetRunStatus extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_SET_RUN_STATUS'
  readonly type = SetRunStatus.type
  constructor (public status: string) {
    super()
  }
}

export class UpdateTransformersPreview extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_UPDATE_PREVIEW'
  readonly type = UpdateTransformersPreview.type
  constructor (
    public previewColumns: string[],
    public previewData: PreviewData,
    public columnWidths: PreviewColumnWidths
  ) {
    super()
  }
}

export class UpdateTransformerPreviewerDate extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_UPDATE_PREVIEWER_DATE'
  readonly type = UpdateTransformerPreviewerDate.type
  constructor (public date: string) {
    super()
  }
}
export class UpdateRunTransformerDateRange extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_UPDATE_RUN_TRANSFORMER_DATE_RANGE'
  readonly type = UpdateRunTransformerDateRange.type
  constructor (public range: [string, string]) {
    super()
  }
}

export class SetPreviewError extends ClassAction {
  static readonly type = 'DATASOURCES_TRANSFORMERS_SET_PREVIEW_ERROR'
  readonly type = SetPreviewError.type
  constructor (public previewError: PreviewError | null) {
    super()
  }
}

export const setPreviewLimit = createClassAction(SetPreviewLimit)
export const updateTransformersData = createClassAction(UpdateTransformersData)
export const setRunStatus = createClassAction(SetRunStatus)
export const updateTransformersPreview = createClassAction(UpdateTransformersPreview)
export const updatePreviewerDate = createClassAction(UpdateTransformerPreviewerDate)
export const updateRunTransformerDateRange = createClassAction(UpdateRunTransformerDateRange)
export const setPreviewError = createClassAction(SetPreviewError)
export const clearPreviewError = () => setPreviewError(null)

export function fetchTransformers (): ActionDispatcher {
  return dispatch => {
    get('/transformers', {})
      .then(data => dispatch(updateTransformersData(data)))
  }
}

function refreshTransformers (dispatch: any, message: string|null = null, onFinished?: () => void) {
  dispatch(fetchTransformers())
  if (message) {
    dispatch(workThunks.showSuccessMessage(message))
  }
  onFinished && onFinished()
}

export function insertTransformer (
  name: string,
  contents: string,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    insertDatasource('transformers', { name, contents })
      .then(() => refreshTransformers(dispatch, translate('Transformer inserted.'), onFinished))
  }
}

export function updateTransformer (
  name: string,
  contents: string,
  silent = false,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    updateDatasource('transformers', name, { contents })
      .then(() => refreshTransformers(
        dispatch,
        silent ? null : translate('Transformer updated.'),
        onFinished
      ))
  }
}

export function deleteTransformer (name: string, onFinished?: () => void): ActionDispatcher {
  return (dispatch, _, { translate }) => (
    deleteDatasource('transformers', name)
      .then(() => refreshTransformers(dispatch, translate('Transformer deleted.'), onFinished))
  )
}

interface RunTransformerOptions {
  dateRange: [string, string] | null
  environmentId: string | null
}

export function runTransformer (name: string, options: RunTransformerOptions): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    if (!options.dateRange) {
      return dispatch(workThunks.showErrorMessage(translate('No date range selected.')))
    }

    post(
      `/transformers/${name}/run`,
      {
        ...insertIf(options.environmentId, {
          environment_id: options.environmentId
        }),
        start_date: options.dateRange[0],
        end_date: options.dateRange[1]
      },
      {},
      {
        background: true,
        handleRejections: false,
        flashMessage: true
      }
    )
      .then((data: string) => {
        dispatch(setRunStatus(data))
        dispatch(fetchShortLog('transcript', { filter: name }))
        dispatch(workThunks.showSuccessMessage(translate('Transformer executed.')))
      })
      .catch(error => {
        dispatch(setRunStatus(error.message || translate('Some error occured.')))
        dispatch(workThunks.showErrorMessage(translate('Running Transformer failed.')))
      })
      .then(() => dispatch(fetchShortLog('transcript', { filter: name })))
  }
}

function getColumnLength (rows: any[]) {
  return rows.reduce((acc, row) => {
    const columns = Object.keys(row)

    columns.forEach(column => {
      if (Object.prototype.hasOwnProperty.call(acc, column)) {
        const rowColumnIsLonger = row[column].length > acc[column]

        if (rowColumnIsLonger) {
          acc[column] = row[column].length
        }
      } else {
        acc[column] = row[column].length
      }
    })

    return acc
  }, {})
}

interface FormatFields {
  [key: string]: string
}

export function fetchTransformerPreview (
  name: string,
  break_at: number,
  dset = '',
  escapeChar = '"'
): ActionDispatcher {
  return (dispatch, getState, { translate }) => {
    const limit = getState().datasources.transformers.previewLimit
    const date = transformersSelectors.getPreviewerDate(getState())

    if (!date) {
      return dispatch(workThunks.showErrorMessage(translate('No preview date selected')))
    }

    const params = {
      preview: 1,
      name,
      limit,
      break_at,
      date,
      snapshot_dset: dset
    }

    dispatch(clearPreviewError())
    post(
      `/transformers/${name}/run`,
      params,
      {},
      {
        background: true,
        flashMessage: true
      }
    )
      .then(data => {
        const parsedCsv = Papa.parse(data.content, {
          header: true,
          skipEmptyLines: true,
          escapeChar
        })

        const previewColumns = parsedCsv.meta.fields || []
        const previewData = parsedCsv.data as PreviewData
        const formatFields = previewColumns.reduce(
          (acc, column) => {
            acc[column] = column
            return acc
          },
          {} as FormatFields)

        const fieldsAndData = previewData.concat([formatFields as any])
        const columnWidths = getColumnLength(fieldsAndData)

        dispatch(updateTransformersPreview(previewColumns, previewData, columnWidths))
        dispatch(workThunks.showSuccessMessage(translate('Preview successful.')))
      })
  }
}
