import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Shell as UIShell, Loader, themeGlobal } from '@exivity/ui'
import { singleLine, translate } from '@exivity/translations'
import styled from 'styled-components'

import { useDisclaimer } from '../../myProfile/hooks'
import { useCurrentUser } from '../../auth/hooks'
import { authSelectors } from '../../auth/state'
import { workSelectors } from '../../work/state'
import { configurationSelectors } from '../../../domains/administration/state/configuration'
import { profileSelectors } from '../../myProfile/state'
import { LicenseWarning } from '../../administration/components/LicenseWarning'
import { TokenExpirationWarning } from '../../auth/components'

import { useLoadNewVersion } from './ServiceWorkers/ServiceWorkers'
import { useTranslation } from './useTranslation'
import { ErrorBoundary } from './ErrorBoundary'
import { Navigation } from './NavBar'
import { AppBar } from './AppBar'
import { SyncResources } from './SyncResources'
import { DebugWarning } from './DebugWarning'
import { useFavicon } from './useFavicon'

import './container.css'
import '../../../styles/font.css'

interface ShellProps {
  children: React.ReactElement
}

const CenteredLoader = styled(Loader)`
  height: ${themeGlobal('contentHeight')};
`

export function Shell ({ children }: ShellProps) {
  useFavicon()
  useDisclaimer()
  useLoadNewVersion()

  // Whenever we update state -> we change the key so we can rerender entire react tree
  const language = useTranslation() || ''
  const user = useCurrentUser()

  const isForegroundBusy = useSelector(workSelectors.isForegroundBusy)
  const appCss = useSelector(configurationSelectors.getAppCss)
  const collapsed = useSelector(profileSelectors.isNavCollapsed)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)

  const fallback = (
    <CenteredLoader
      timeout={10000}
      timeoutMessage={singleLine(
        translate('Loading is taking a bit longer than expected...'),
        translate('Try refreshing the page.')
      )}>
      {translate('Loading page...')}
    </CenteredLoader>
  )

  return (
    <UIShell
      className={`ex-app ${isForegroundBusy ? 'ex-app--foreground-loading' : ''}`}
      navCollapsed={collapsed}
      appBar={<AppBar key={language + user?.id} />}
      navBar={
        isAuthenticated
          ? <Navigation key={language} />
          : undefined
      }
      appCss={appCss ?? undefined}>
      <ErrorBoundary>
        {
          isAuthenticated
          ? (
            <SyncResources>
              <Suspense fallback={fallback}>
                <TokenExpirationWarning />
                <LicenseWarning />
                <DebugWarning />
                {children}
              </Suspense>
            </SyncResources>
          )
         : (
           <Suspense fallback={fallback}>
             {children}
           </Suspense>
        )}
      </ErrorBoundary>
    </UIShell>
  )
}
