var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Manager, Reference, Popper as ReactPopper } from 'react-popper';
import styled from 'styled-components';
import { OutsideClickListener } from '../OutsideClickListener';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"
    /**
     * Placement options for Popper
     * @see https://popper.js.org/popper-documentation.html#Popper.placements
     */
])));
/**
 * Placement options for Popper
 * @see https://popper.js.org/popper-documentation.html#Popper.placements
 */
export var Placement;
(function (Placement) {
    Placement["RIGHT"] = "right";
    Placement["RIGHT_START"] = "right-start";
    Placement["RIGHT_END"] = "right-end";
    Placement["LEFT"] = "left";
    Placement["LEFT_START"] = "left-start";
    Placement["LEFT_END"] = "left-end";
    Placement["TOP"] = "top";
    Placement["TOP_START"] = "top-start";
    Placement["TOP_END"] = "top-end";
    Placement["BOTTOM"] = "bottom";
    Placement["BOTTOM_START"] = "bottom-start";
    Placement["BOTTOM_END"] = "bottom-end";
    Placement["AUTO"] = "auto";
    Placement["AUTO_START"] = "auto-start";
    Placement["AUTO_END"] = "auto-end";
})(Placement || (Placement = {}));
export var Popper = function (_a) {
    var renderTrigger = _a.renderTrigger, open = _a.open, children = _a.children, onOutsideClick = _a.onOutsideClick, _b = _a.placement, placement = _b === void 0 ? Placement.BOTTOM_START : _b, _c = _a.offset, offset = _c === void 0 ? 0 : _c;
    return (_jsx(Manager, { children: _jsx(Container, { children: _jsxs(OutsideClickListener, __assign({ onOutsideClick: onOutsideClick }, { children: [_jsx(Reference, { children: renderTrigger }, void 0), open
                        ? (_jsx(ReactPopper, __assign({ placement: placement, modifiers: [
                                {
                                    name: 'offset',
                                    enabled: offset !== 0,
                                    options: {
                                        offset: [offset, offset]
                                    }
                                }
                            ] }, { children: function (_a) {
                                var ref = _a.ref, style = _a.style, currentPlacement = _a.placement, arrowProps = _a.arrowProps, update = _a.update;
                                return (children({
                                    ref: ref,
                                    style: style,
                                    placement: currentPlacement,
                                    arrowProps: arrowProps,
                                    update: update
                                }));
                            } }), void 0))
                        : null] }), void 0) }, void 0) }, void 0));
};
var templateObject_1;
