var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { MdAdd, MdClear, MdEdit, MdCheck, MdDelete } from 'react-icons/md';
import { Button } from '../Button';
import { useModal } from '../Modal';
import { Icon } from '../Icon';
var HidableBtn = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  visibility: ", ";\n"], ["\n  visibility: ", ";\n"])), function (_a) {
    var visible = _a.visible;
    return visible ? 'visible' : 'hidden';
});
export var Toolbar = function (_a) {
    var onConfirm = _a.onConfirm, onCreate = _a.onCreate, onUpdate = _a.onUpdate, onDelete = _a.onDelete, onCancel = _a.onCancel, confirmDeleteText = _a.confirmDeleteText, actionState = _a.actionState;
    var confirm = useModal().confirm;
    var isConfirming = actionState !== 'none';
    var isActiveState = function (option) { return actionState === 'none' || actionState === option; };
    return (_jsxs(_Fragment, { children: [_jsx(HidableBtn, __assign({ small: true, round: true, type: 'submit', success: actionState === 'create', visible: isConfirming, onClick: function () {
                    onConfirm && onConfirm();
                } }, { children: _jsx(Icon, { children: _jsx(MdCheck, {}, void 0) }, void 0) }), void 0), _jsx(HidableBtn, __assign({ small: true, round: true, success: true, outlined: isConfirming, visible: !!onCreate && isActiveState('create'), onClick: function () {
                    if (isConfirming) {
                        onCancel && onCancel();
                    }
                    else {
                        onCreate && onCreate();
                    }
                } }, { children: _jsx(Icon, { children: isConfirming
                        ? _jsx(MdClear, {}, void 0)
                        : _jsx(MdAdd, {}, void 0) }, void 0) }), void 0), _jsx(HidableBtn, __assign({ small: true, round: true, outlined: isConfirming, visible: !!onUpdate && isActiveState('edit'), onClick: function () {
                    if (isConfirming) {
                        onCancel && onCancel();
                    }
                    else {
                        onUpdate && onUpdate();
                    }
                } }, { children: _jsx(Icon, { children: isConfirming
                        ? _jsx(MdClear, {}, void 0)
                        : _jsx(MdEdit, {}, void 0) }, void 0) }), void 0), _jsx(HidableBtn, __assign({ small: true, round: true, danger: true, visible: !!onDelete && !isConfirming, onClick: function () {
                    confirm({ body: confirmDeleteText })
                        .then(function () {
                        onDelete && onDelete();
                    });
                } }, { children: _jsx(Icon, { children: _jsx(MdDelete, {}, void 0) }, void 0) }), void 0)] }, void 0));
};
var templateObject_1;
