import { createContext, useContext } from 'react';
export const AtomicContext = createContext((() => {
    console.warn('No AtomicContext implemented.');
}));
export const toAtomicDoc = (...sequence) => (sequence
    .reduce((v, f) => f(v), []));
export const useAtomic = (...fns) => {
    const post = useContext(AtomicContext);
    return (...fnsAlternative) => {
        const sequence = fns.length > 0
            ? fns
            : fnsAlternative;
        // @ts-ignore
        return post(toAtomicDoc(...sequence));
    };
};
