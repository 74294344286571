import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import { translate } from '@exivity/translations'

import { ZonedDateTime, formats } from '../../../../utils/date'
import { BasicTable } from '../../../molecules/Table/index'

function nl2br (str) {
  const newlineRegex = /(\r\n|\r|\n)/g

  if (typeof str !== 'string') {
    return str
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return createElement('br', { key: index })
    }

    return line
  })
}

const makeList = (metadata) => (
  <ul data-uk-accordion>
    {Object.entries(metadata).map(([key, value]) => {
      if (['own', 'cumulative'].indexOf(key) !== -1 || !value) {
        return null
      }

      if (typeof value === 'object') {
        return (
          <li key={key}>
            <div className='uk-accordion-title'>{key}</div>
            <div className='uk-accordion-content'>{makeList(value)}</div>
          </li>
        )
      } else if (typeof value === 'string') {
        return (
          <li key={key}>
            <div className='uk-accordion-title'>{key}</div>
            <div className='uk-accordion-content'>{nl2br(value)}</div>
          </li>
        )
      } else {
        return null
      }
    })}
  </ul>
)

const Lines = ({ lines, filter, severityLabels }) => {
  const severityLookup = {}
  for (let i = 0, len = severityLabels.length; i < len; i++) {
    severityLookup[severityLabels[i].key] = severityLabels[i]
  }

  // Check for timing metadata
  let hasTimingData = false
  if (lines.length && lines[0].metadata && typeof lines[0].metadata.own !== 'undefined') {
    hasTimingData = true
  }

  return (
    <BasicTable size='tiny' hover divider>
      <thead>
        <tr>
          <th>{translate('Date')}</th>
          {hasTimingData && <th colSpan='2'>{translate('Time')}</th>}
          <th>{translate('Level')}</th>
          <th>{translate('Message')}</th>
        </tr>
      </thead>
      <tbody>
        {lines.map((line, index) => {
          if (filter && filter > line.level) {
            return null
          }
          return (
            <tr key={`${line.date}_${index}`}
              className={`ex-logviewer__line ex-logviewer__line--severity-${line.level}`}>
              <td className='uk-table-shrink uk-text-nowrap'>
                {ZonedDateTime.fromTimestamp(line.date).format(formats.shortDateTime)}
              </td>
              {hasTimingData && (
                <td className='uk-table-shrink ex-logviewer__line--timing uk-text-meta'>
                  <small className='uk-text-meta' title={translate('Own time')} data-uk-tooltip>⧗</small>
                  &nbsp;{line.metadata.own}<span>ms</span>
                </td>
              )}
              {hasTimingData && (
                <td className='uk-table-shrink ex-logviewer__line--timing uk-text-meta'>
                  <small className='uk-text-meta' title={translate('Cumulative time')} data-uk-tooltip>Σ</small>
                  &nbsp;{line.metadata.cumulative}<span>ms</span>
                </td>
              )}
              <td className='uk-table-shrink'>
                <span className={`uk-label uk-label-${severityLookup[line.level].class}`}>
                  {severityLookup[line.level].value}
                </span>
              </td>
              <td>
                {line.message}
                {line.metadata && (
                  makeList(line.metadata)
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </BasicTable>
  )
}

Lines.propTypes = {
  filter: PropTypes.number,
  lines: PropTypes.array.isRequired,
  severityLabels: PropTypes.arrayOf(PropTypes.object)
}

export default Lines
