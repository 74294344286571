import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'
import { FC } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'

import { insertIf } from '../../../../utils/misc'
import { USER_TYPE } from '../../../../data/types'
import { RootState } from '../../../../reducers'
import { authSelectors } from '../../../auth/state'

import { User } from './User'

const mapStateToProps = (state: RootState) => ({
  currentUser: authSelectors.getCurrentUser(state)
})

const mapRecordsToProps = ({ id }: { id?: string }) => ({
  ...insertIf(id, {
    user: (q: QueryBuilder) => q.findRecord({ type: USER_TYPE, id: id as string })
  })
})

export default compose(
  connect(mapStateToProps),
  withData(mapRecordsToProps)
)(User) as unknown as FC<{ id?: string }>
