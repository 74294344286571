import { get, post } from '../../API/index'
import { workThunks } from '../../domains/work/thunks'
import { fetchShortLog } from '../data/logs/logs'
import ClassAction, { createClassAction } from '../../store/middleware/ClassAction'
import { ActionDispatcher } from '../../store/utils'
import { insertDatasource, updateDatasource, deleteDatasource } from '../../API/datasources'
import { Extractor, RunStatus } from '../../reducers/datasources/extractors'
import { insertIf } from '../../utils/misc'

export class UpdateExtractorsData extends ClassAction {
  static readonly type = 'DATASOURCES_EXTRACTORS_UPDATE_DATA'
  readonly type = UpdateExtractorsData.type
  constructor (public data: Extractor[]) {
    super()
  }
}

export class SetRunStatus extends ClassAction {
  static readonly type = 'DATASOURCES_EXTRACTORS_SET_RUN_STATUS'
  readonly type = SetRunStatus.type
  constructor (public data: RunStatus) {
    super()
  }
}

const updateExtractorsData = createClassAction(UpdateExtractorsData)
const setRunStatus = createClassAction(SetRunStatus)

export function fetchExtractors (): ActionDispatcher {
  return dispatch => {
    get('/extractors', {}).then(data => dispatch(updateExtractorsData(data)))
  }
}

function refreshExtractors (dispatch: any, message: string, onFinished?: () => void) {
  dispatch(fetchExtractors())
  dispatch(workThunks.showSuccessMessage(message))
  onFinished && onFinished()
}

export function insertExtractor (
  name: string,
  contents: string,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    insertDatasource('extractors', { name, contents })
      .then(() => refreshExtractors(dispatch, translate('Extractor inserted.'), onFinished))
  }
}

export function updateExtractor (
  name: string,
  contents: string,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    updateDatasource('extractors', name, { contents })
      .then(() => refreshExtractors(dispatch, translate('Extractor updated.'), onFinished))
  }
}

export function updateExtractorVariables (
  name: string,
  variables: any,
  onFinished?: () => void
): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    updateDatasource('extractors', name, { variables })
      .then(() => refreshExtractors(dispatch, translate('Variables updated.'), onFinished))
  }
}

export function deleteExtractor (name: string, onFinished?: () => void): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    deleteDatasource('extractors', name)
      .then(() => refreshExtractors(dispatch, translate('Extractor deleted.'), onFinished))
  }
}

interface RunExtractorOptions {
  args: string
  environmentId: string | null
}

export function runExtractor (name: string, options: RunExtractorOptions): ActionDispatcher {
  return (dispatch, _, { translate }) => {
    post(
      `/extractors/${name}/run`,
      {
        arguments: options.args,
        ...insertIf(options.environmentId, {
          environment_id: options.environmentId
        })
      },
      {},
      {
        background: true,
        workStatusMessage: `Running ${name}`,
        handleRejections: false,
        flashMessage: true
      }
    )
      .then(data => {
        dispatch(setRunStatus(data))
        dispatch(fetchShortLog('use', { filter: name } as any))
        dispatch(workThunks.showSuccessMessage(translate('Extractor executed.')))
      })
      .catch(error => {
        dispatch(setRunStatus(error.message || translate('Some error occurred.')))
        dispatch(workThunks.showErrorMessage(translate('Running extractor failed.')))
      })
      .then(() => dispatch(fetchShortLog('use', { filter: name } as any)))
  }
}
