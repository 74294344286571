import React from 'react'
import { withData } from 'react-orbitjs'
import { QueryBuilder, Record } from '@orbit/data'
import { CrudRecord } from 'react-crud-hook'

import { getRelationship } from '../../../../utils/records'
import {
  MetadataDefinitionModel,
  MetadataModel
} from '../../../../data/types'

import { MetadataForm as Form } from './MetadataForm'

type OwnProps = {
  definitionId: string
  model: Record
  onRecord?: (metadataRecord: CrudRecord<MetadataModel>) => void
  placeholder: React.ReactNode
}

type RecordProps = {
  metadata: MetadataModel
  definition: MetadataDefinitionModel
}

export type MetadataFormProps = OwnProps & RecordProps

export function getMetadataId ([first]: MetadataModel[]) {
  return first && first.id
}

function mapRecordToProps ({ definitionId, model }: OwnProps) {
  if (!definitionId) return {}

  const metadataId = getMetadataId(
    getRelationship(model, 'metadata', [])
  )

  if (metadataId) {
    return {
      metadata: (q: QueryBuilder) => q.findRecord({ type: 'metadata', id: metadataId }),
      definition: (q: QueryBuilder) => q.findRecord({
        type: 'metadatadefinition',
        id: definitionId
      })
    }
  }

  return {
    definition: (q: QueryBuilder) => q.findRecord({
      type: 'metadatadefinition',
      id: definitionId
    })
  }
}

const MetadataForm = withData(mapRecordToProps)(Form as React.FC<OwnProps>)

export { MetadataForm }
