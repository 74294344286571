export const defaultTimeout = 1000 * 60 * 10 // Set to 10m, which emulates Proximity and NGINX.

export const fetchWithTimeout = (
  url: RequestInfo,
  options?: RequestInit
) => new Promise<Response>((resolve, reject) => {
  const timeout = setTimeout(() => {
    reject(new Error('Request timed out.'))
  }, defaultTimeout)

  fetch(url, options)
    .then((data) => {
      clearTimeout(timeout)
      resolve(data)
    })
    .catch(error => {
      clearTimeout(timeout)
      reject(error)
    })
})
