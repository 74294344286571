import { useEffect, useState, useCallback } from 'react'
import screenfull from 'screenfull'

export function isFullscreen () {
  return screenfull.isEnabled && screenfull.isFullscreen
}

export function setFullscreen () {
  if (screenfull.isEnabled && !screenfull.isFullscreen) {
    return screenfull.request(document.documentElement)
  }
  return Promise.resolve()
}

export function exitFullscreen () {
  if (screenfull.isEnabled && screenfull.isFullscreen) {
    return screenfull.exit()
  }
  return Promise.resolve()
}

export function useIsFullscreen (onChange?: (isFullscreen: boolean) => void) {
  const [active, setActive] = useState(isFullscreen())

  const handleChange = useCallback(() => {
    const isActive = isFullscreen()
    setActive(isActive)
    onChange && onChange(isActive)
  }, [onChange])

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', handleChange)
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', handleChange)
      }
    }
  }, [handleChange])

  return active
}
